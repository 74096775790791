import Vue from 'vue'
import VueI18n from 'vue-i18n'
import messages from '@/assets/js/i18n'

Vue.use(VueI18n)

const locale = localStorage.getItem('lang') || 'cn'
export default new VueI18n({
  locale, // set locale
  messages // set locale messages
})
