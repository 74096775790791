<template>
  <div
    v-if="!checkEmpty"
    class="content indexware-basepopup"
  >
    <div class="title__group">
      <div class="header">
        <div class="icon">
          <img
            v-if="countryIconList.includes(oneItem.lotteryCode)"
            :src="getCountryicons(oneItem.countryCode)"
            alt=""
          >
          <img
            v-else
            :src="getSicons(oneItem.lotteryCode)"
            alt=""
          >
        </div>
        <div class="title">
          {{ stockResMsg(`STOCK_${oneItem.lotteryCode}`,oneItem.lotteryName) }}
        </div>
      </div>
      <div
        class="value recordcode-set"
        :class="[getStateText[3]]"
      >
        <span v-if="getStateText[3] ==='rise'">
          + {{ getStateText[2] }}
        </span>
        <span v-else>
          {{ getStateText[0] }}
        </span>
      </div>
    </div>
    <div class="item-content">
      <div class="item">
        <div class="name">
          {{ $t('INVEST_PERIOD_NUMBER') }}
        </div>
        <div class="value">
          {{ oneItem.issue }}
        </div>
      </div>
      <div class="item">
        <div class="name">
          {{ $t('INVEST_PLAN') }}
        </div>
        <div class="value">
          {{ filterOption(playType) }}
        </div>
      </div>
      <div class="item">
        <div class="name">
          {{ $t('INVEST_TIME') }}
        </div>
        <div class="value">
          {{ oneItem.gmtCreated }}
        </div>
      </div>
      <div class="item">
        <div class="name">
          {{ $t('TRADE_CHAMPION') }}
        </div>
        <div class="value">
          {{ filterOption(champioAndRunner[0]) || '-' }}
        </div>
      </div>
      <div class="item">
        <div class="name">
          {{ $t('WAREHOUSE_RECORDCENTER_CHASENUMBER_C_ORDER_TITLE') }}
        </div>
        <div
          class="value"
        >
          {{ oneItem.recordCode }}
        </div>
      </div>
      <div class="item">
        <div class="name">
          {{ $t('TRADE_RUNNER') }}
        </div>
        <div class="value">
          {{ filterOption(champioAndRunner[1]) || '-' }}
        </div>
      </div>
      <div class="item">
        <div class="name">
          {{ $t('WAREHOUSE_RECORDCENTER_CHASENUMBER_BET_TITLE') }}
        </div>
        <div class="value">
          {{ oneItem.betMoney }}
        </div>
      </div>
      <div class="item">
        <div class="name">
          {{ $t('DISTRIBUTE_PROFIT') }}
        </div>
        <div
          class="value"
          :class="[getStateText[3]]"
        >
          <span v-if="getStateText[3]==='rise'">+</span>
          <span>{{ getStateText[2] }}</span>
        </div>
      </div>
      <div class="item">
        <div class="name">
          {{ $t('PC_WAREHOUSE_NUMBER') }}
        </div>
        <div
          class="value"
          :class="[getStateText[3]]"
        >
          {{ getStateText[1] }}
        </div>
      </div>
    </div>
    <div class="rovoke__btns">
      <div
        class="btn cancel"
        :class="$i18n.locale"
        @click="cancelHandler"
      >
        {{ $t('CLOSE') }}
      </div>
      <div
        v-if="cancelOrderMode !== 2 && revokeState"
        class="btn revoke"
        @click="revokeHandler"
      >
        {{ $t('PC_CANCEL_ORDER') }}
      </div>
    </div>
  </div>
</template>

<script>
import {
  getCountryicons,
  getSicons,
  countryIconList,
  specialIcons,
  stockResMsg
} from '@/utils'
import { mapState } from 'vuex'
export default {
  props: {
    oneItem: {
      type: Object,
      required: true
    },
    revokeId: {
      type: [Number, String],
      required: true
    },
    revokeState: {
      type: Boolean,
      required: true
    },
    cancelOrderMode: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      countryIconList,
      specialIcons
    }
  },
  computed: {
    ...mapState(['betOrderList']),
    filterOneItemData () {
      if (this.revokeId && this.betOrderList[this.revokeId]) {
        return {
          ...this.oneItem,
          ...this.betOrderList[this.revokeId]
        }
      }
      return this.oneItem
    },
    checkEmpty () {
      const empty = Object.keys(this.oneItem).length === 0
      return this.oneItem && empty
    },
    playType () {
      const playType = this.oneItem.betInfoList && this.oneItem.betInfoList[0]
        ? this.oneItem.betInfoList[0].playName
        : ''
      return playType
      // return this.filterOption(playType)
    },
    champioAndRunner () {
      return this.oneItem.betInfoList && this.oneItem.betInfoList[0]
        ? this.oneItem.betInfoList[0].betNum.split(',')
        : [0, 0]
    },
    getStateText () {
      // 1.注單狀態 2.出倉號碼 3.營利派送
      switch (this.oneItem.state) {
        case 3:
        case 5:
          return [this.$t('INVEST_WAITING_RELEASED'), '-- -- --', '-- --', 'def']
        case '等待盈利':
        case 0:
          return [this.$t('INVEST_WAITING_RELEASED'), '-- -- --', '-- --', 'pending']
        case '等待开奖':
          return [this.$t('INVEST_WAITING_RELEASED'), '-- -- --', '-- --', 'pending']
        case '未中奖':
        case '未盈利':
        case 1:
          return [this.$t('INVEST_UNPROFITABLE'), this.filterOneItemData.openNum, '0.00', 'fall']
        case '已盈利':
        case 2:
        case 4:
          return [this.$t('INVEST_PROFITABLE'), this.filterOneItemData.openNum, this.filterOneItemData.bonus, 'rise']
        case '已中奖':
          return [this.$t('INVEST_PROFITABLE'), this.filterOneItemData.openNum, this.filterOneItemData.bonus, 'rise']
        case '已撤单':
          return [this.$t('INVEST_ORDER_CANCELLED'), '-- -- --', '-- --', 'fall']
        default:
          return [this.$t('INVEST_WAITING_RELEASED'), '-- -- --', '-- --', 'def']
      }
    }
  },
  methods: {
    stockResMsg,
    getSicons,
    getCountryicons,
    filterOption (val) {
      const str = val.replace('小数,', '')
      const num = isNaN(parseInt(str))
      const arr = str.split(' ')
      let optionTable
      if (num) {
        optionTable = arr.map(item => {
          return {
            涨: this.$t('TRADE_UP'),
            跌: this.$t('TRADE_DOWN'),
            单股: this.$t('TRADE_SINGLE_STRAND'),
            双股: this.$t('TRADE_DOUBLE_STRAND'),
            牛: this.$t('TRADE_BULL_MARKET'),
            熊: this.$t('TRADE_BEAR_MARKET'),
            定位胆: this.$t('TRADE_POSITIONING'),
            双面盘: this.$t('TRADE_DOUBLE_SIDE')
          }[item]
        })
        return optionTable.join(' ')
      } else {
        return str
      }
    },
    revokeHandler () {
      this.$emit('revokeHandler')
    },
    cancelHandler () {
      this.$emit('cancelState', false)
    }
  }
}
</script>

<style lang="scss" scoped>
.title__group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 24px;
  padding-left: 24px;
  margin-bottom: 15px;

  .icon {
    display: flex;
    justify-content: center;
    margin-right: 8px;
    width: 20px;
    height: 20px;

    >img {
      object-fit: fill;
    }
  }

  .title {
    font-size: 16px;
    color: var(--popupTitleColor);
    line-height: 22px;
  }

  .header {
    display: flex;
  }
}

.recordcode-set {
  color: var(--popupNameColor);
  -webkit-text-stroke: 0.3px;

  &.rise {
    color: var(--popupValueRecordCodeRiseColor);
  }

  &.pending {
    color: var(--popupValueRecordCodePendingColor);
  }

  &.fall {
    color: var(--popupValueRecordCodeFallColor);
  }
}

.item-content {
  display: flex;
  margin-bottom: 12px;
  justify-content: space-between;
  padding-right: 24px;
  padding-left: 24px;
  flex-wrap: wrap;

  >.item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 48%;
    height: 24px;
    font-size: 12px;
    line-height: 17px;

    .name {
      color: var(--popupNameColor);
    }

    .value {
      color: var(--popupValueColor);

      &.pending {
        color: var(--popupValuePendingColor);
      }

      &.rise {
        color: var(--popupValueColor);
      }

      &.fall{

      }

    }
  }
}

.rovoke__btns {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 24px;
  padding-left: 24px;
  height: 60px;
  border-top: 1px solid #253C5A;

  >.btn {
    width: 72px;
    height: 24px;
    font-size: 14px;
    text-align: center;
    border-radius: 4px;
    line-height: 22px;

    &.ja {
      width: 84px;
    }

    &:hover {
      cursor: pointer;
    }

    &.cancel {
      @include confirm_btn_4;

      color: var(--popupRevokeBtnColor);
      border: var(--popupRevokeBtnBorder);
    }

    &.revoke {
      @include confirm_btn_3;

      margin-left: 8px;
      color: var(--popupRevokeBtnRevokeColor);
    }
  }
}

.indexware-basepopup {
}

</style>
