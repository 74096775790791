<template>
  <v-app
    class="app__wrap"
    :class="[{'is-home':$route.meta.navbar},updateVersion,$i18n.locale]"
  >
    <!-- navbar -->
    <NavBar :class="themeWord" />
    <!-- 右 => 主題切換 -->
    <ThemeNavigation v-show="$route.meta.navbar" />
    <v-main
      class="content__view"
      :class="userTheme"
    >
      <v-fade-transition mode="out-in">
        <router-view />
      </v-fade-transition>
    </v-main>
    <transition name="fade">
      <Notify v-if="hasNotify.show && !$route.meta.notify" />
    </transition>
    <div
      class="gloibal-wrap"
      :class="{top: $store.state.loading}"
    >
      <transition name="fade">
        <GlobalLoading v-if="$store.state.loading" />
      </transition>
    </div>
  </v-app>
</template>

<script>
import { mapState } from 'vuex'
import theme from '@/assets/js/theme'
// import Navigation from '@/components/Menu/Navigation.vue'
import userApi from '@/api/users'
import ThemeNavigation from '@/components/Menu/Theme.vue'
import NavBar from '@/components/Layout/NavBar.vue'
// import Socket from '@/assets/js/socket'
import Notify from '@/components/notify/pc'
import GlobalLoading from '@/components/loader/GlobalLoading'
import lotteryApi from '@/api/lottery'
import manageApi from '@/api/manage'
import { checkCatchTime } from '@/utils'
// import { imgHost } from '@/filters/index'

function setTimeStr (arr) {
  if (arr && arr.length) {
    const list = []
    arr.forEach(v => {
      v.startTime && (v.startTime = v.startTime.split(' ')[1])
      v.endTime && (v.endTime = v.endTime.split(' ')[1])
      list.push(v)
    })
    return list
  }
  return []
}

export default {
  name: 'App',
  components: {
    NavBar,
    Notify,
    GlobalLoading,
    // Navigation,
    ThemeNavigation
  },
  // mixins: [Socket, theme],
  mixins: [theme],
  data () {
    return {
      updateVersion: process.env.VUE_APP_updateVersion,
      winWidth: 0
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo,
      userTheme: state => state.user.userInfo?.theme,
      hasNotify: state => state.hasNotify,
      copyHasNotify: state => state.copyNotify
    })
  },
  watch: {
    '$store.state.themeWord': function () { // plan1.0
      console.log('app watch')
      this.setTheme()
    }
  },
  created () {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
    this.initialization()
    this.setTheme()

    const version = localStorage.getItem('version')

    if (!version || this.updateVersion !== version) {
      localStorage.setItem('version', this.updateVersion)
      manageApi.getFrontCacheUpdatedAt().then((res) => {
        if (res.code === 1) {
          checkCatchTime(res.data.pageUpdateTimeMobile)
          localStorage.setItem('cacheTime', JSON.stringify(res.data.tenantConfig))
          this.update()
        }
      })
    }
  },
  mounted () {
    document.addEventListener('keydown', (objEvent) => {
      if (objEvent.keyCode === 9) { // tab pressed
        objEvent.preventDefault() // stops its action
      }
    })
  },
  destroyed () {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    handleResize () {
      this.winWidth = window.innerWidth
      if (window.innerWidth <= 1200) {
        // this.$store.commit('closeState')
      } else {
        // this.$store.commit('openState')
      }
    },
    update () {
      lotteryApi.lotteryConfig().then(res => {
        if (res.code === 1) {
          const obj = res.data
          const LList = setTimeStr(obj.lotteryList)
          const LConfig = setTimeStr(obj.lotteryConfig)
          this.$store.dispatch('actionsSetNewLotteryList', LList)
          this.$store.dispatch('actionsSetNewLotteryConfig', LConfig)
          localStorage.setItem('newLotteryList', JSON.stringify(LList))
          localStorage.setItem('newLotteryConfig', JSON.stringify(LConfig))
        }
      })
    },
    initialization () {
      let LotteryConfig = localStorage.getItem('newLotteryConfig') // this.$store.state.LotteryConfig
      let LotteryList = localStorage.getItem('newLotteryList')// this.$store.state.LotteryList
      LotteryConfig = LotteryConfig && JSON.parse(LotteryConfig)
      LotteryList = LotteryList && JSON.parse(LotteryList)
      if (LotteryConfig && LotteryConfig.length && LotteryList && LotteryList.length) {
        this.$store.dispatch('actionsSetNewLotteryList', LotteryList)
        this.$store.dispatch('actionsSetNewLotteryConfig', LotteryConfig)
      } else {
        lotteryApi.lotteryConfig().then(ref => {
          if (ref.code === 1) {
            const obj = ref.data
            const LList = setTimeStr(obj.lotteryList)
            const LConfig = setTimeStr(obj.lotteryConfig)
            this.$store.dispatch('actionsSetNewLotteryList', LList)
            this.$store.dispatch('actionsSetNewLotteryConfig', LConfig)
            localStorage.setItem('newLotteryList', JSON.stringify(LList))
            localStorage.setItem('newLotteryConfig', JSON.stringify(LConfig))
          }
        })
      }
      userApi.getTenantConfig().then((res) => {
        if (res.code === 1) {
          // document.querySelector("link[rel*='icon']").href = imgHost(res.data.logoPc)
          window.isApp = res.data.sourceId
          this.$store.state.sourceId = res.data.sourceId
          this.$store.dispatch('setSiteConfig', res.data)
          const cacheTime = JSON.parse(localStorage.getItem('cacheTime'))
          const obj = {}
          obj[cacheTime] = res.data
          localStorage.setItem('siteConfig', JSON.stringify(obj))
          if (res.data.sourceId === '2') {
            let lastTouchEnd = 0
            document.documentElement.addEventListener(
              'touchend',
              event => {
                if (this.gameRouteName) return
                const now = Date.now()
                if (now - lastTouchEnd <= 200) {
                  event.preventDefault()
                }
                lastTouchEnd = now
              },
              false
            )
          }
        }
      })
    },
    setTheme () {
      const cssStyleVar = { ...this.styleVar }
      for (const item in cssStyleVar) {
        document.body.style.setProperty(item, cssStyleVar[item])
      }
    }
  }
}
</script>

<style lang="scss">
.content__view {
  background: var(--contentBgc) !important;

  /* background-color: var(--appContentViewBgc) !important; */

  /* &.classic {
    background: var(--contentBgc) !important;
  } */
}

html {
  overflow: hidden;
}

*:not(input, textarea) {
  user-select: none;
}

.gloibal-wrap {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: -1;

  &.top {
    z-index: 10;
  }
}

.v-application--wrap {
  overflow: auto;
  overflow-x: overlay;
  background: var(--vApplicationBgc);

  &::-webkit-scrollbar-track {
    background-color: var(--scrollBarTrackBgc);
  }

  &::-webkit-scrollbar {
    height: 5px;
  }
}

.app__wrap {
  .v-main {
    overflow: hidden;
  }

  .content__view {
    padding: 62px 0 0 !important;
  }

  .v-main__wrap {
    overflow: auto;
    overflow: overlay;
    height: calc(100vh - 60px);

  }

  &.is-home {
    .v-main {
      overflow: visible;
    }

    .content__view {
      padding: 62px 38px 0 0 !important;
    }

    .v-main__wrap {
      overflow: visible;
      height: auto;
      @media screen and (max-height: 767px) {
        overflow: auto;
        overflow: overlay;
        height: calc(100vh - 62px);

        &::-webkit-scrollbar-track {
          background-color: transparent;
          border-radius: 3px;

        }

        &::-webkit-scrollbar {
          height: 6px;
          background-color: transparent;
          border-radius: 3px;
        }

        &::-webkit-scrollbar-thumb {
          background-color: transparent;
          border-radius: 3px;
        }
      }
    }

    .home__wrap {
      @media screen and (max-height: 767px) {
        height: 767px;
      }
    }
  }

  &.vie {
    .pc-notice {
      .van-dialog__header {
        min-height: 136px;
        font-size: 17px;
      }

      .van-dialog__content {
        padding-top: 10px;
      }
    }
  }
}

// 取消input type number 箭頭

/* Chrome, Safari, Edge, Opera */
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

div {
  // firefox
  scrollbar-width: thin;
  scrollbar-color: var(--fireFoxScrollBar) transparent;

  &::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 3px;
    -webkit-box-shadow: var(--fireScrollBarTrackShadow);
  }

  &::-webkit-scrollbar {
    width: 6px;
    background-color: transparent;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--fireScrollbarThumbBgc);
    border-radius: 3px;
  }
}

.van-dialog {
  //原本alert樣式
  &.notice,
  &.alert {
    width: 408px;
    // height: 120px;
    background: var(--appVanDialogBgc);
    border-radius: 0;
    z-index: 200;
    box-shadow: var(--appVanDialogShadow);
  }

  &.alert-big,
  &.notice-big {
    padding: 23px 24px 16px;
    width: 520px;
    // height: 120px;
    background: var(--appVanAlertDialogBgc);
    border-radius: 0;
    border-radius: 6px;
    z-index: 200;
    box-shadow: var(--appVanDialogShadow);

    .van-dialog__header {
      display: block;
      padding: 0;
      text-align: left;
      color: var(--appVanAlertHeaderColor);
    }

    .van-dialog__message.van-dialog__message--has-title {
      overflow: hidden;
      margin-top: 12px;
      padding: 0;
      text-align: left;
      color: var(--appDialogHeaderColor);
      margin-bottom: 32px;
    }

    .van-dialog__footer {
      justify-content: flex-end;
      padding: 0;
      border-top: 0;
    }

    .van-dialog__cancel,
    .van-dialog__confirm {
      height: 28px;
      border-radius: 4px;
      flex-grow: 0;
      flex-basis: 96px;
    }

    .van-dialog__confirm {
      @include confirm_btn_5;

      color: var(--popupRevokeBtnRevokeColor);
    }

    .van-dialog__cancel {
      @include confirm_btn_4;

      margin-right: 12px;
      color: var(--popupRevokeBtnColor) !important;
      background-color: transparent;
    }
  }

  &.alert-big {
    .van-dialog__confirm {
      @include confirm_btn_3;

      color: var(--popupRevokeBtnRevokeColor);
    }
  }

  &.pc-notice {
    padding-top: 60px;
    color: var(--appPcNoticeColor);

    .van-dialog__message {
      position: relative;
      overflow: hidden;
      padding: 8px;
    }

    .van-dialog__message--has-title {
      padding-top: 0 !important;
      color: var(--appPcNoticeHasTitleColor);
    }

    .van-dialog__footer {
      justify-content: space-between;
    }

    .van-dialog__confirm {
      width: 47%;
      flex: 0 0 47%;

    }

    .van-dialog__cancel {
      color: var(--appPcNoticeCancelColor) !important;
      border-radius: 4px;
      flex: 0 0 47%;
    }

    .van-dialog__header {
      position: relative;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      padding: 0 26px;
      width: 100%;
      min-height: 130px;
      font-size: 20px;
      color: var(--appPcNoticeHeaderColor);
      background-position: top center;
      background-size: 84px;
      opacity: 1;
      letter-spacing: 1.44px;
      line-height: 29px;

      &::after {
        position: absolute;
        top: 0;
        left: 50%;
        width: 84px;
        height: 84px;
        background-position: center center;
        border: var(--appPcNoticeHeaderAfterBorder);
        border-radius: 50%;
        content: '';
        background-image: url('/public/static/images/Layout/dialog-ok.svg');
        transform: translateX(-50%);
      }
    }

    .van-dialog__content {
      padding-top: 16px;
      min-height: 68px;
    }
  }

  &.error-alert {
    //錯誤訊息使用
    padding-top: 60px;
    color: var(--appErrorAlertColor);

    .van-dialog__message {
      position: relative;
      overflow: hidden;
      padding: 8px;
    }

    .van-dialog__message--has-title {
      padding-top: 0 !important;
      color: var(--appErrorAlertTitleColor);
    }

    .van-dialog__header {
      position: relative;
      display: flex;
      justify-content: center;
      padding-top: 0;
      width: 100%;
      min-height: 130px;
      font-size: 20px;
      color: var(--appErrorAlertHeaderColor);
      background-position: top center;
      background-size: 84px;
      opacity: 1;
      flex-direction: column-reverse;
      letter-spacing: 1.44px;
      line-height: 29px;

      &::after {
        width: 84px;
        height: 84px;
        background-position: center center;
        border: var(--appErrorAlertHeaderAfterBorder);
        border-radius: 50%;
        // position: absolute;
        // top: 0;
        // left: 50%;
        margin-bottom: 16px;
        content: '';
        background-image: url('/public/static/images/Layout/dialog-error.svg');
        // transform: translateX(-50%);
      }
    }

    .van-dialog__content {
      padding-top: 16px;
      min-height: 68px;
    }
  }

  .van-button--default {
    background: var(--vantButtonDefaultBgc);
  }

  &.chase-notice {
    >.van-dialog__header {
      padding-top: 24px;
      padding-right: 20px;
      padding-bottom: 16px;
      padding-left: 20px;
    }

    .van-dialog__message {
      padding-top: 0 !important;
      text-align: left;
    }

  }

  .van-dialog__header {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 26px;
    // min-height: 300px;
    color: var(--appDialogHeaderColor);
    letter-spacing: 1.44px;
  }

  .van-dialog__message {
    padding: 8px 25px 24px;
    letter-spacing: 1.08px;
    line-height: 17px;

    &.van-dialog__message--has-title {
      padding-top: 8px;
    }
  }

  .van-dialog__footer {
    display: flex;
    border-top: var(--appDialogFooterBorder);
    padding: 20px 26px;
  }

  .van-dialog__confirm {
    // margin-left: 8px;
    height: 40px;
    color: var(--appDialogConfirmColor);
    background-color: var(--appDialogConfirmBgc);
    border-radius: 4px;

    &:active {
      color: var(--popupRevokeBtnRevokeColor);
    }
  }

  .van-dialog__cancel {
    // margin-right: 8px;
    height: 40px;
    color: var(--vantDialogCancel) !important;
    color: var(--appDialogCancelColor);
    border: var(--appDialogCancelBorder);
  }

  .van-hairline--top::after,
  .van-hairline--left::after {
    border: 0;
  }
}

.theme--dark.v-picker__body {
  background-color: var(--appVpickerBgc) !important;
}
// 日曆套件
.v-date-picker-table th {
  color: var(--appDatePickerTableColor) !important;

}

.v-date-picker-table thead {
  position: relative;

  &::before {
    position: absolute;
    bottom: 0;
    width: 100%;
    content: '';
    border-bottom: var(--appVDataBeforeBorder);
  }
}

.v-date-picker-table tbody tr:first-child td {
  padding-top: 10px;
}

.v-date-picker-years.v-date-picker-years {
  &::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 3px;
    -webkit-box-shadow: var(--appScrollTrackShadow);
  }

  &::-webkit-scrollbar {
    width: 6px;
    background-color: transparent;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--appScrollThumb);
    border-radius: 3px;
  }
}

.v-date-picker-table {
  height: 260px !important;
}

.v-btn__content .mdi-chevron-right {
  background: url('/public/static/images/memberCenter/right-1.svg') no-repeat center center;
}

.v-btn__content .mdi-chevron-left {
  background: url('/public/static/images/memberCenter/left-2.svg') no-repeat center center;
}

.v-date-picker-years li {
  color: var(--appVDatePickerColor);
}

.v-date-picker-years li.active {
  color: var(--appVDatePickerActiveColor) !important;
}

// 分頁樣式
.app__wrap .v-main__wrap .v-pagination {
  .v-pagination__item {
    margin: 0 2px;
    width: 32px;
    height: 32px;
    color: #879AAF;
    background: transparent;
    box-shadow: none;
  }

  .v-pagination__item--active {
    color: #FAFAFA;
  }

  .v-pagination__navigation {
    background: transparent;
    box-shadow: none;
  }
}

.app__wrap .v-main__wrap {
  .v-pagination__navigation {
    position: relative;
    margin: 0;
  }

  .mdi-chevron-right,
  .mdi-chevron-left {
    position: absolute;
    width: 12px;
    height: 12px;
    background-origin: center;
    background-repeat: no-repeat;
  }

  .mdi-chevron-right {
    background-image: url('/public/static/images/assetsMange/arrow-right.svg');
  }

  .mdi-chevron-left {
    background-image: url('/public/static/images/assetsMange/arrow-left.svg');
  }

  &.v-pagination__navigation--disabled {
    .mdi-chevron-left {
      background-image: url('/public/static/images/assetsMange/arrow-left-disable.svg');
    }

    .mdi-chevron-right {
      background-image: url('/public/static/images/assetsMange/arrow-right-disable.svg');
    }
  }
}

.van-popover {
  width: 280px;
  // border: 1px solid #F00;
  .van-popover__arrow {
    color: transparent !important;;
  }

  .van-popover__content {
    // position: relative;
    overflow: visible;
    font-size: 12px;
    background-color: transparent !important;
    line-height: 17px;

    .qa-playinto {
      position: relative;
      padding: 10px 12px;
      // right: -15px;
      color: #879AAF;
      background-color: #17253D !important;
      border: 1px solid #253C5A !important;
      border-radius: 1px;
      z-index: 2;
      box-shadow: 0 1px 2px #00000026 !important;
      transform: translateY(6px) translateX(calc(50% - 16px));

      &.close-side {
        // border: 1px solid #F00 !important;
        transform: translateY(6px) translateX(5%);
      }
    }

    &::before {
      position: absolute;
      top: 6px;
      right: -2px;
      width: 20px;
      height: 1px;
      background-color: #17253D;
      content: '';
      z-index: 3;
    }

    &::after {
      position: absolute;
      top: 6px;
      right: 0%;
      width: 16px;
      height: 16px;
      background-color: #17253D;
      border: 1px solid #253C5A;
      z-index: -1;
      content: '';
      transform: translateY(-55%) rotate(45deg);
    }
  }

  // .qa-title {
  //   transition: 0.5s;

  //   &:hover {
  //     color: #0589D7;
  //   }
  // }
}
</style>
