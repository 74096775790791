import Vue from 'vue'
import { stockConfig } from '@/assets/js/lottery/stock'
import { bus, DAYTIME, GMTDIF, PERBET, BaseBet, deleteCompress, isShowBox, getStockRebate } from '@/assets/js/lottery/lotteryFun'
import api from '@/api/lottery'
import manageApi from '@/api/manage'
import pubsub from 'pubsub-js'
import { apiResMsg2 } from '@/utils'
import { mapState } from 'vuex'

// 处理多余的无效时间值
function TimeItem (interval, timeBegin, timeEnd, SerTime) {
  this.interval = interval
  this.timeBegin = timeBegin
  this.timeEnd = timeEnd
  this.SerTime = SerTime
}
// 滚动置顶
function scrollTop () {
  document.body.scrollTop = 0
  document.documentElement.scrollTop = 0
}
function setTimeStr (arr) {
  if (arr && arr.length) {
    const list = []
    arr.forEach(v => {
      v.startTime && (v.startTime = v.startTime.split(' ')[1])
      v.endTime && (v.endTime = v.endTime.split(' ')[1])
      list.push(v)
    })
    return list
  }
  return []
}

// 处理是否刷新
function checkCatchTime (pageUpdateTimeMobile, fun) {
  // console.log(pageUpdateTimeMobile)
  const pageUpdateTime = localStorage.getItem('pageUpdateTime')
  if (pageUpdateTime !== pageUpdateTimeMobile) {
    localStorage.setItem('pageUpdateTime', pageUpdateTimeMobile)
    localStorage.setItem('updated', true)
    fun && fun()
  }
}
// 获取返点的获取函数
export default {
  name: 'LotteryIndex',
  data () {
    return {
      // timeBar
      previousIssue: '',
      currentIssue: '',
      nextIssue: '',
      currentBettingEndTime: '',
      nextBettingEndTime: '',
      unitTime: 5,
      hasUpdating: false,
      lType: '', // SSC
      // lCode: '1801', // 彩种code
      StopTxt: '暂停销售',
      LotteryList: [],
      LotteryConfig: [],
      openTimeBack: null, // 超出600豪秒 开奖结果loading倒计时
      isPost: false, // 是否发起请求
      isInvesting: false, // 是否發起投資
      nums: 1,
      lotteryType: 'STOCK',
      getResultTimes: 0 // 指数专用
    }
  },
  computed: {
    ...mapState(['newLotteryList']),
    currentStockData () {
      const data = this.newLotteryList
      return data ? data.filter(item => item.lotteryCode === this.lCode)[0] : {}
    },
    filterPreviousIssue () {
      if (this.$store.state.hasTemporaryNext) {
        return this.currentIssue
      }

      // 出去前綴（年份）
      return this.previousIssue
    },
    filterCurrentIssue () {
      if (this.$store.state.hasTemporaryNext) {
        return this.nextIssue
      }

      // 出去前綴（年份）
      return this.currentIssue
    },
    filterEndTime () {
      if (this.$store.state.hasTemporaryNext) {
        return this.nextBettingEndTime
      }

      return this.currentBettingEndTime
    },
    IsStop () {
      // 判断是否不可提交订单,并弹出警告
      const bool = ('0123456789预休出等'.search(this.$store.state.lt.TimeBar[0]) === -1)
      bool && this.msgAlertBig(this.$store.state.lt.TimeBar, '确认')
      return bool
    },
    lCode () {
      return this.$store.state.lt ? this.$store.state.lt.lCode : this.$store.state.defaultStock // 彩种code
    },
    mode () {
      return this.$store.state.lt && this.$store.state.lt.mode.mode
    },
    lottery () {
      return this.$store.state.lt.lottery && this.$store.state.lt.lottery.lotteryType
    },
    oneMode () {
      return isShowBox.indexOf(this.lottery + this.mode) > -1
    },
    LotteryName () {
      return (this.$store.state.lt && this.$store.state.lt.lottery.lotteryName) || 'STOCK'
    },
    userInfo () {
      return this.$store.state.user && this.$store.state.user.userInfo
    }
  },
  methods: {
    getLatestStockIssue () {
      this.hasUpdating = true
      this.$store.state.hasTemporaryNext = true
      api.lotteryOpenData({ lotteryCode: this.lCode }).then((i) => {
        if (i.code === 1) {
          this.$store.state.hasTemporaryNext = false
          this.previousIssue = i.data.previousIssue
          this.currentIssue = i.data.nowIssue
          this.$store.dispatch('actionUpdateIssue', i.data.nowIssue)
          this.$store.dispatch('actionUpdateNextIssue', i.data.nextIssue)
          this.nextIssue = i.data.nextIssue
          this.currentBettingEndTime = i.data.nowBettingEndTime
          this.nextBettingEndTime = i.data.nextBettingEndTime
          this.unitTime = i.data.unitTime || 5 // 預設是5，避免整個爆錯
          this.$store.dispatch('actionSetStockIssueData', {
            previousIssue: i.data.previousIssue,
            currentIssue: i.data.nowIssue,
            nextIssue: i.data.nextIssue,
            currentBettingEndTime: i.data.nowBettingEndTime,
            nextBettingEndTime: i.data.nextBettingEndTime,
            unitTime: i.data.unitTime || 5
          })
          this.hasUpdating = false
        }
      })
    },
    init () {
      this.resetOut()
      // 页面配置信息
      const pageConfig = {
        STOCK: stockConfig
      }

      // 处理返点---除了时时彩其余彩种都用同一个处理函数
      const awardSetter = {
        STOCK: getStockRebate
      }

      let lt
      const that = this
      if (!this.$store.state.lt) {
        lt = {
          state: {
            lCode: that.$store.state.defaultStock, // 彩种code
            hasTemporaryNext: false, // 是否在过渡期
            bet: {
              bettingNumber: '', // 投注号码字符串
              bettingCount: 0, // 投注注数
              bettingAmount: 0, // 投注金额
              bettingUnit: 1, // 单位(1,0.1,0.01)
              graduationCount: 1, // 倍数
              compress: '' // 压缩后的字符串
            },
            tmp: {}, // 即时的投注号码情况
            basket: [], // 号码篮
            isChase: false, // 是否追号
            // 追号配置
            hasMultiple: false,
            chaseConf: {
              bettingCount: 0,
              isStopAfterWinning: true,
              autoBuyIfStopAfterWinning: false,
              bettingUnit: 1, // 单位(1,0.1,0.01)
              chaseEach: 2, // 每多少期翻倍
              chaseEachMultiple: 2, // 翻倍倍率
              chaseCount: 10, // 追多少期
              power: '' // 每期多少倍(普通追号)
            },
            scheme: [],
            mode: {
              name: '', // 直选复式
              mode: '', // H11
              group: '', // 五星
              subGroup: '', // 直选
              tag: '' // 五星直选复式
            },
            // 当前彩种的详情
            lottery: {
              lotteryExplain: '', // 全天120期
              lotteryName: '', // 重庆时时彩
              lotteryType: 'STOCK', // SSC
              lotteryCode: that.$store.state.defaultStock, // 1000
              startTime: '',
              startIssue: ''
            },
            stockScheme: [], // 投資計畫
            Rebate: {}, // 返点,根据type而不同
            Odds: {}, // 奖金,根据type而不同
            award: '', // 当前彩种、当前玩法以元为单位的奖金
            config: {}, // 在各种彩种页面,
            LotteryResults: {}, // 各彩种开奖结果的缓存（包含不同彩种）
            BetRecord: [], // 投注记录
            ChaseRecord: [], // 追号记录
            TimeBar: '00:00:00', // 倒计时内容
            OldIssue: '', // 上一期
            displayResults: false, // false显示等待开奖的动画， true显示开奖结果
            tipDisplayFlag: false, // 是否省略玩法
            perbet: 1,
            hasResultUpdating: false,
            officialStocks: ['1811', '1812', '1813', '1814', '1815', '1816', '1817', '1818', '1819', '1821', '1822', '1823', '1824', '1825', '1826', '1827', '1828', '1829', '1831', '1832', '1833', '1834', '1835', '1836', '1837', '1838', '1839', '1840', '1861', '1862', '1863'], // 十分鐘一期
            betRecordRefresh: 1, // 是否请求我的投注
            ChaseRecordRefresh: 1 // 是否请求我的追号
          },
          getters: {
            currentStockScheme (state) {
              return state.stockScheme
                .filter((item, index) => index < Number(state.chaseConf.chaseCount))
                .map((i, index) => ({
                  bettingIssue: i.bettingIssue,
                  graduationCount: 1,
                  bettingAmount: Math.round((state.hasMultiple
                    ? state.chaseConf.bettingCount * state.chaseConf.bettingUnit * state.chaseConf.power * Math.pow(state.chaseConf.chaseEachMultiple, Math.floor(index / state.chaseConf.chaseEach))
                    : state.chaseConf.bettingCount * state.chaseConf.bettingUnit * state.chaseConf.power) * 100) / 100
                }))
            },
            currentBettingIssue (state) {
              return state.hasTemporaryNext ? that.$store.state.NextIssue : that.$store.state.NowIssue
            }
          },
          mutations: {
            // set lCode
            setlCode (state, val) {
              state.lCode = val
            },
            // set autoBuyIfStopAfterWinning
            SetautoBuyIfStopAfterWin: (state, bool) => {
              state.chaseConf.autoBuyIfStopAfterWinning = bool
            },
            // set lt_setHasMultiple
            lt_setHasMultiple: (state, val) => {
              state.hasMultiple = val
            },
            // set chaseEachMultiple
            lt_setChaseEachMultiple: (state, val) => {
              state.chaseConf.chaseEachMultiple = val
            },
            // set chaseEach
            lt_setChaseEach: (state, val) => {
              state.chaseConf.chaseEach = val
            },
            // set bettingUnit
            lt_setChaseBettingUnit: (state, val) => {
              state.chaseConf.bettingUnit = val
            },
            // setChaseEachInfo
            setChaseEachInfo: (state, val) => { state.chaseConf.eachInfo = val },
            // setChaseBettingCount
            setChaseBettingCount: (state, val) => { state.chaseConf.bettingCount = val },
            // set stockScheme
            lt_setStockScheme: (state, scheme) => { state.stockScheme = scheme },
            // set HasResultUpdateing
            lt_setHasResultUpdateing: (state, val) => {
              state.hasResultUpdating = val
            },
            lt_setLotteryResult: (state, { code, results }) => { // 设置某一彩种的开奖结果
              Vue.set(state.LotteryResults, code, results)
            },
            lt_stopSell: (state, type) => {
              this.$store.commit('lt_updateTimeBar', ['期号有误', '暂停销售', '当期封单', '等待开局', '等待开奖'][type]) // 暂停销售
              return false
            },
            lt_displayResults: (state, bool) => { // 展示开奖结果或开奖动画
              state.displayResults = bool
            },
            lt_showFullTip: (state, bool) => {
              state.tipDisplayFlag = bool
            },
            // 倒计时的内容
            lt_updateTimeBar: (state, text) => {
              if (state.TimeBar !== text) {
                if (text === '等待开局') {
                  state.betRecordRefresh = 1
                }
                this.StopTxt = text
                state.TimeBar = text
              }
            },
            lt_setBetRecord: (state, BetRecord) => { state.BetRecord = BetRecord }, // 投注记录
            lt_setChaseRecord: (state, ChaseRecord) => { state.ChaseRecord = ChaseRecord }, // 我的追号
            /** 通用 **/
            // 变更弹出框
            // 变更玩法
            lt_changeMode: (state, mode) => {
              const type = 'STOCK' // 彩种类型 SSC、K3 state.lottery.lotteryType
              const Odds = state.Odds[type] || []

              // bus.$emit('clearNoteStr') // 清空文本框文字
              this.$store.commit('lt_clearBet')
              this.$store.state.lt.mode.mode = mode
              // 更改玩法时，对应玩法的奖金也跟着变
              state.award = awardSetter[type](mode, Odds)
              // 更换玩法，bet清空
              // 隐藏过长的玩法说明
              this.$store.commit('lt_showFullTip', false)
            },
            // 变更彩种
            lt_changeLottery: (state, code) => {
              state.lottery = this.getLotteryCode(code)
              // this.$router.push(code) // 更改路由
            },
            // 变更配置（进入各具体彩种页时，设置）
            lt_initConfig: (state) => { state.config = pageConfig[state.lottery.lotteryType] },
            // 设置返点
            lt_setRebate: (state, { Rebate, LotteryType }) => {
              // console.log(Rebate, LotteryType)
              const mode = state.mode.mode
              state.award = awardSetter[LotteryType](mode, Rebate.odds)
              Vue.set(state.Rebate, LotteryType, Rebate.rebate)
              Vue.set(state.Odds, LotteryType, Rebate.odds)
            },
            // 设置每注单价
            lt_setPerbet: (state, price) => { state.perbet = price },
            // 即时投注号码的更改
            lt_updateTmp: (state, { alias, arr }) => {
              Vue.set(state.tmp, alias, arr)
            },
            // 设置倍数
            lt_setPower: (state, power) => {
              state.bet.graduationCount = power
            },
            // 清空bet
            lt_clearBet: (state) => {
              for (const item in state.tmp) {
                state.tmp[item] = []
              }
              bus.$emit('clearNoteStr')
            },
            /** 投注-号码篮 **/
            // 添加bet到plan中
            lt_addBet: (state) => {
              const fun = new BaseBet(this.$store.state)
              this.$store.commit('lt_addToBasket', fun)
              this.$store.commit('lt_clearBet')
            },
            lt_addRandomBet: (state, randomBet) => {
              this.$store.commit('lt_addToBasket', randomBet)
            },
            // 将注单push到basket里
            lt_addToBasket: (state, bet) => {
              // 不清追号配置
              // state.chaseConf.chaseCount = 1
              // state.chaseConf.power = 1
              // 去掉重复的，合并加倍
              let equalIndex
              let isEqual = false
              state.basket.forEach((_bet, index) => {
                let allPropEqual = true
                for (const prop in _bet) {
                  if (typeof _bet[prop] === 'string' || (typeof _bet[prop] === 'number' && prop !== 'graduationCount' && prop !== 'bettingAmount')) {
                    if (_bet[prop] !== bet[prop]) {
                      allPropEqual = false
                    }
                  }
                }
                if (allPropEqual) {
                  isEqual = true
                  equalIndex = index
                }
              })

              if (state.basket.length && isEqual) {
                const prevPower = state.basket[equalIndex].graduationCount
                state.basket[equalIndex].setPower(prevPower + bet.graduationCount, this.$store.state)
              } else {
                state.basket.push(bet)
              }
            },
            lt_clearBasket: (state) => {
              state.basket = []
              // this.$store.commit('lt_setScheme', [])
            },
            // 从号码篮中删除一注
            lt_deleteBet: (state, index) => {
              state.basket.splice(index, 1)
              // this.$store.dispatch('actionOrdinaryChase')
            },
            /** 投注-追号 **/
            lt_isStopAfterWin: (state, bool) => { state.chaseConf.isStopAfterWinning = bool },
            lt_setChaseIssue: (state, chaseIssue) => { state.chaseConf.chaseCount = chaseIssue },
            lt_setChasePower: (state, chasePower) => { state.chaseConf.power = chasePower },
            // lt_setScheme: (state, scheme) => { state.scheme = scheme },
            // 将号码篮中的倍数清为1
            lt_basketPowerTo1: (state) => {
              state.basket.forEach(bet => {
                bet.graduationCount = 1
              })
            },
            // 进入和退出追号
            lt_setIsChase: (state, bool) => {
              state.isChase = bool
              if (bool) {
              // 追号了，选号区的倍数就清了
                state.bet.graduationCount = 1
                this.$store.commit('lt_basketPowerTo1')
                // this.$store.dispatch('actionOrdinaryChase')
              }
            }
          },
          actions: {
            // 变更彩种的异步操作
            actionUpdateLottery: ({ state, rootState, commit, dispatch }, code) => {
              scrollTop()
              commit('setlCode', code)
              console.log('update lottery', 'You know I love you')
              // 进入每个彩种，先把开奖结果初始化，如果不存在的话
              if (!state.LotteryResults[code]) {
                commit({
                  type: 'lt_setLotteryResult',
                  code,
                  results: []
                })
              }
              commit('lt_changeLottery', code) // 变更彩种
              commit('lt_setHasResultUpdateing', true) // 正在更新開獎結果
              dispatch('actionGetResults', code) // 获得开奖结果
              // 更新计划
            },
            // lt_changeMode
            actionSetMode ({ commit }, val) {
              commit('lt_changeMode', val)
            },
            // actionUpdateHasMultiple
            actionUpdateHasMultiple: ({ commit }, val) => {
              commit('lt_setHasMultiple', val)
            },
            // set chaseEachMultiple
            actionUpdateChaseEachMultiple: ({ commit }, val) => {
              commit('lt_setChaseEachMultiple', val)
            },
            actionUpdateChaseEach: ({ commit }, val) => {
              commit('lt_setChaseEach', val)
            },
            actionUpdateChaseBettingUnit: ({ commit }, val) => {
              commit('lt_setChaseBettingUnit', val)
            },
            actionGetChaseScheme ({ commit }, code) {
              return api.nextIssueOpenData({ lotteryCode: code }).then((e) => {
                if (e.code === 1) {
                  commit('lt_setStockScheme', e.data.issueDetailData || [])
                }
              })
            },
            actionInitConfig: ({ commit }) => {
              commit('lt_initConfig')
            },
            actionGetResults: ({ state, rootState, commit, dispatch }, code) => {
              clearTimeout(this.openTimeBack)
              this.isPost = true
              // 获取开奖结果
              this.openTimeBack = setTimeout(() => {
                if (this.isPost) {
                  // this.msgLoading('正在加载')
                  // this.$store.dispatch('setInterfaceLoading', true)
                }
              }, 700)
              api.getLotteryOpen({ lotteryCode: code, dataNum: 30 }).then(ref => {
                this.isPost = false
                if (ref.code === 1) {
                  commit('lt_setHasResultUpdateing', false)

                  commit({
                    type: 'lt_setLotteryResult',
                    code,
                    results: ref.data
                  })
                }
              }).catch(e => {
                clearTimeout(this.openTimeBack)
                this.isPost = false
              })
            },
            // refresh
            actionRefresh: ({ state, rootState, commit, dispatch }) => {
              const SerTime = (new Date().getTime() - rootState.Difftime - GMTDIF) % DAYTIME // 折合成当日的时间
              if (SerTime < 1000) {
                // console.log('新的一天')
                if (this.nums && SerTime < 1000) {
                  this.$dialog.close()
                  this.nums--
                  this.init()
                }
              } else {
                this.nums = 1
              }
            },
            // 获取我的投注
            actionUpdateBetRecord: ({ state, commit }) => {
              const BetRecord = state.BetRecord
              let needRefresh = 0
              for (let i = 0; i < BetRecord.length; i++) {
                if ((BetRecord[i].returnAmount === '等待开奖' || BetRecord[i].returnAmount === '等待出仓') && (this.$store.state.NowIssue !== BetRecord[i].issue)) {
                  needRefresh = 1
                  break
                }
              }
              if (needRefresh || state.betRecordRefresh) {
                api.getBetting().then((ref) => {
                  if (ref.code === 1) {
                    const betting = ref.data
                    commit('lt_setBetRecord', betting)
                    state.betRecordRefresh = 0
                  }
                })
              }
            },
            // 获取追号记录
            actionUpdateChaseRecord: ({ state, commit }) => {
              const ChaseRecord = state.ChaseRecord
              let needRefresh = 0
              for (let i = 0; i < ChaseRecord.length; i++) {
                if ((ChaseRecord[i].state === '未开始') && (this.$store.state.NowIssue !== ChaseRecord[i].issue)) {
                  needRefresh = 1
                  break
                }
              }
              if (needRefresh || state.ChaseRecordRefresh) {
                api.getChaseBetting().then((ref) => {
                  if (ref.code === 1) {
                    const chaseRecord = ref.data
                    commit('lt_setChaseRecord', chaseRecord)
                    state.ChaseRecordRefresh = 0
                  }
                })
              }
            },
            // 获得返点
            actionGetRebate: ({ state, rootState, commit }, notUseLocal) => {
              const type = 'STOCK'
              let rebate = localStorage.getItem(`newRebate${type}`)
              // let rebate = this.$store.state[`Rebate${type}`]// localStorage.getItem(`Rebate${type}`)
              rebate = rebate ? JSON.parse(rebate) : null
              // let rebate = rootState['Rebate' + type]

              // 不使用本地返点
              if (notUseLocal) {
                rebate = null
              }
              // 没有返点就重新拉取返点数据
              if (!rebate) {
                api.getBetRebate({ lotteryType: type }).then((ref) => {
                  if (ref.code === 1) {
                  // commit('SaveInitData', {['Rebate' + type]:ref.data})
                    rootState['newRebate' + type] = ref.data
                    commit({
                      type: 'lt_setRebate',
                      Rebate: ref.data,
                      LotteryType: type
                    })
                    localStorage.setItem(`newRebate${type}`, JSON.stringify(ref.data))
                    // 更新号码篮里的返点信息
                    state.basket.forEach(bet => {
                      bet.setRebate(ref.data.rebate, rootState)
                    })
                  } else {
                    this.$store.state['newRebate' + type] = ''
                    localStorage.removeItem(`newRebate${type}`)
                    if (ref.code === 0 || ref.msg.indexOf('用户未登录') > -1) {
                      return
                    }
                    this.$store.dispatch('actionGetRebate', true)
                  }
                })
              } else {
                commit({
                  type: 'lt_setRebate',
                  Rebate: rebate,
                  LotteryType: type
                })
              }
            },
            // 投注
            actionConfirmBet: ({ state, commit, dispatch }, obj) => {
              let xBasket = deleteCompress(state.basket)
              if (this.IsStop) {
                return this.msgAlertBig(this.StopTxt)
              }
              if (obj) {
                xBasket = deleteCompress(obj.basket)
              }
              if (xBasket[0].betDetail) {
                delete xBasket[0].betDetail
              }
              xBasket = xBasket.map(v => {
                v.bettingPoint = v.bettingPoint.split('-')[1]
                return v
              })
              if (this.lType === 'STOCK') {
                xBasket = xBasket.map(i => ({ ...i, graduationCount: 1, bettingUnit: 1 }))
              }

              const Data = {
                bettingData: JSON.stringify(xBasket)
              }
              scrollTop()
              // this.msgLoading('正在投资')
              api.addBetting(Data).then(ref => {
                this.$toast.clear()
                if (ref.code === 1) {
                  dispatch('actionSetUserBalance')
                  // 清除号码栏
                  commit('lt_clearBet')
                  commit('lt_clearBasket')
                  obj && obj.success && obj.success()
                  // 投注后自己添记录到“我的投注里”
                  // let issue = xBasket[0].bettingIssue // 期号
                  // let _betRecord = state.BetRecord.slice(0) // 我的投注副本
                  // let record = {issue: issue, bettingAmount: totalMoney.toFixed(2), lotteryName: this.LotteryName, returnAmount: '等待开奖', url: '/betRecord'}
                  // _betRecord.unshift(record)
                  // if (_betRecord.length > 5) {
                  //   _betRecord.length = 5
                  // }
                  // commit('lt_setBetRecord', _betRecord)
                  state.betRecordRefresh = 1
                  this.msgLotteryType({
                    title: this.$t('ACTING_TIPS'),
                    message: `<div class="text-center"><span class="van-bet-money">${this.$t('TRADE_STOCK_POPUP_SUCCESSFUL_TEXT1')}</span>，${this.$t('TRADE_STOCK_RIGHT_TEXT')}</div>`,
                    btn: [this.$t('TRADE_CONTINUE_INVEST'), this.$t('TRADE_SHARE_ORDER')],
                    // btn: [`继续投资`, '查看注单'],
                    fun: ref => {
                      // 打开 我的投注
                      dispatch('lt_updateBetRecord')
                    }
                  })
                } else if (ref.code === -9) {
                // 清除rebate
                  this.reRebate()
                  this.$store.dispatch('actionGetRebate', true)
                } else if (ref.code === -33) {
                  return false
                } else {
                  this.msgConfirm(apiResMsg2(ref), '确认').then(ref => {
                    this.reSetLottery()
                  }).catch(() => {})
                  if ((ref.msg).indexOf('余额不足') === -1) {
                  // 清除号码栏
                    this.$store.commit('lt_clearBet')
                    this.$store.commit('lt_clearBasket')
                    // 关闭号码栏
                    this.reRebate()
                    this.$store.dispatch('actionGetRebate', true)
                  } else if ((ref.msg).indexOf('余额不足') > -1) {
                    this.msgAlertBig(apiResMsg2(ref), this.$t('DEPOSIT'), this.$t('CANCEL')).then(() => {
                      this.$router.push('/recharge?id=RechargePage')
                    }).catch(() => {
                    })
                  } else if ((ref.msg).indexOf('投资失败') > -1) {
                    this.msgConfirm(this.$t('TRADE_STOCK_CONFIRMBET_UNIT_ERROR_REFRESH'), this.$t('CONFIRM')).then(() => {
                      location.reload()
                    }).catch(() => {
                    })
                  }
                }
              })
            },
            // 追号投注
            actionChase: ({ state, rootState, commit, dispatch, getters }, { isStopAfterWin, startIssue, bettingCount, lotteryName, selectTypeShowText, playDetailCode, autoBuyIfStopAfterWinning, lotteryCode, initializationData1 }) => {
              if (this.IsStop) {
                return this.msgInfo(this.StopTxt)
              }

              // let xBasket = deleteCompress(state.basket, true)
              // xBasket = xBasket.map(v => {
              //   v.bettingPoint = v.bettingPoint.split('-')[1]
              //   return v
              // })
              // 追号总的金额
              const schemeTotal = () => {
                const s = getters.currentStockScheme.reduce((prev, curr) => prev + +curr.bettingAmount, 0)
                return s.toFixed(2)
              }
              const bettingAllAmount = +schemeTotal()
              // let newbasket = JSON.parse(JSON.stringify(xBasket))
              // newbasket = newbasket.map(v => {
              // // 删除不需要的数据
              //   delete v.lotteryCode
              //   delete v.bettingPoint
              //   delete v.bettingUnit
              //   delete v.playDetailCode
              //   delete v.bettingAmount
              //   return v
              // })

              const chaseCount = getters.currentStockScheme.length
              const chaseModel = {
                isStopAfterWinning: +isStopAfterWin,
                autoBuyIfStopAfterWinning: +autoBuyIfStopAfterWinning,
                startIssue: startIssue, // 开始的期号
                lotteryCode, // 玩法类型
                bettingPoint: this.$store.state.lt.Rebate[this.lType],
                playDetailCode,
                bettingUnit: state.chaseConf.bettingUnit,
                bettingAllAmount,
                chaseCount, // 追多少期
                eachInfo: state.chaseConf.eachInfo, // 追号
                eachOrder: getters.currentStockScheme
              }

              const chaseData = Object.assign({}, chaseModel)
              const Data = {
                chaseBettingData: JSON.stringify(chaseData)
              }
              // this.msgLoading('正在投资')
              this.isInvesting = true
              api.addChaseBetting(Data).then(ref => {
                this.$toast.clear()
                this.isInvesting = false
                if (ref.code === 1) {
                  commit('lt_clearBet') // 清空bet
                  commit('lt_clearBasket') // 清空basket
                  scrollTop()
                  // commit('lt_setScheme', []) // 清空scheme
                  // commit('lt_setChasePower', 1) // 清空追号配置
                  // commit('lt_setChaseIssue', 1)
                  // 关闭号码栏
                  commit('lt_setIsChase', false) // 追号成功后，变成普通投注
                  // state.ChaseRecordRefresh = 1
                  // this.msgLotteryType({
                  //   title: '温馨提示',
                  //   message: '<div class="text-center"><span class="van-bet-money">投资成功</span>，点击右上角向左箭头可查看投资记录</div>',
                  //   btn: ['继续投资', '查看注单'],
                  //   fun: ref => {
                  //     // 我的追号
                  //     dispatch('lt_updateChaseRecord')
                  //     bus.$emit('bettingRecordShow', { show: true, key: 1 })
                  //   }
                  // })
                  // state.notifyData = {
                  //   name: lotteryName,
                  //   issue: startIssue.slice(4),
                  //   playDetail: `${selectTypeShowText} ${bettingCount} 注 ${state.chaseConf.power}倍 追${chaseCount}期`,
                  //   amount: bettingAllAmount
                  // }
                  this.$store.dispatch('actionSetUserBalance')
                  this.$store.dispatch('actionUpdateNotifyData', {
                    name: lotteryName,
                    issue: startIssue.slice(4),
                    playDetail: this.$t('TRADE_STOCK_NOTIFY_TEXT', { type: selectTypeShowText, bet: bettingCount, power: state.chaseConf.power, chase: chaseCount, bettingAllAmount }),
                    amount: bettingAllAmount
                  })
                  this.$store.dispatch('actionSetHasNotify', { show: true, type: 'notice' })

                  /// 廣播
                  pubsub.publish('confirm-bet', 'chasetype')
                  initializationData1()
                } else if (ref.code === -9) {
                // 清除rebate
                  this.reRebate()
                  this.$store.dispatch('actionGetRebate', true)
                  this.$store.dispatch('actionSetHasNotify', { show: true, type: 'error', msg: apiResMsg2(ref) })
                } else if (ref.code === -33) {
                  return false
                } else {
                  this.$store.dispatch('actionSetHasNotify', { show: true, type: 'error', msg: apiResMsg2(ref) })
                  if ((ref.msg).indexOf('余额不足') > -1) {
                    this.msgAlertBig(apiResMsg2(ref), this.$t('DEPOSIT'), this.$t('CANCEL')).then(() => {
                      this.$router.push('/recharge?id=RechargePage')
                    }).catch(() => {
                    })
                  }
                  if ((ref.msg).indexOf('投资失败') > -1) {
                    this.msgAlertBig(this.$t('TRADE_STOCK_CONFIRMBET_TYPE_ERROR_REFRESH'), this.$t('CONFIRM')).then(ref => {
                      location.reload()
                    }).catch(() => { })
                  } else {
                    const data = ref.data
                    // 国庆期间暂停销倍可追期设置
                    if (data && ref.msg.indexOf('最多可追号') > -1) {
                      this.msgConfirm(apiResMsg2(ref), this.$t('CONFIRM')).then(res => {
                        this.$store.state.lt.chaseConf.chaseCount = ref.data
                        this.$store.commit('lt_setChaseIssue', ref.data)
                        // this.$store.dispatch('actionOrdinaryChase')
                      }).catch(() => {})
                      return false
                    }

                    this.$store.dispatch('actionUpdateNotifyData', {
                      msg: apiResMsg2(ref)
                    })
                    this.$store.commit('switchNotify', { show: true, type: 'investError' })
                    if ((ref.msg).indexOf('余额不足') === -1) {
                      // 清除号码栏
                      this.$store.commit('lt_clearBet')
                      this.$store.commit('lt_clearBasket')
                      // 关闭号码栏
                      this.reRebate()
                      this.$store.dispatch('actionGetRebate', true)
                    }
                  }
                }
              })
            }
          }
        }
      }
      // 定义结束
      /**
       * 彩种模块执行入口
       */
      const type = this.lType
      const setConfig = () => {
        const list = this.getLotteryCode(this.$route.params.id)
        const LType = (list && list.lotteryType) || 'STOCK'
        if (type !== LType) {
        // 当前彩种无此编码时跳到首页
          // this.msgInfo('当前彩种不存在')
          return false
        }
        const fun = () => {
          if (this.$store.state.Difftime === 0) {
            this.getServerTime()
            console.log('更新服务器时间')
          }
          // 注册彩种模块 --lt
          this.$store.state.lt || this.$store.registerModule('lt', lt)
          // 切换彩种
          this.$store.dispatch('actionUpdateLottery', this.lCode)
          // 设置页面配置
          this.$store.dispatch('actionInitConfig')
          // 设置默认的玩法
          this.setDefaultMode()
          this.$store.dispatch('actionGetRebate', true)
        }
        fun()
      }
      const getLotteryConfig = () => {
        let LotteryConfig = localStorage.getItem('newLotteryConfig') // this.$store.state.LotteryConfig
        let LotteryList = localStorage.getItem('newLotteryList')// this.$store.state.LotteryList
        LotteryConfig = LotteryConfig && JSON.parse(LotteryConfig)
        LotteryList = LotteryList && JSON.parse(LotteryList)
        if (LotteryConfig && LotteryConfig.length && LotteryList && LotteryList.length) {
          this.$store.dispatch('actionsSetNewLotteryList', LotteryList)
          this.$store.dispatch('actionsSetNewLotteryConfig', LotteryConfig)
          this.LotteryList = LotteryList
          this.LotteryConfig = LotteryConfig
          setConfig()
        } else {
          api.lotteryConfig().then(ref => {
            if (ref.code === 1) {
              const obj = ref.data
              const LList = setTimeStr(obj.lotteryList)
              const LConfig = setTimeStr(obj.lotteryConfig)
              this.$store.dispatch('actionsSetNewLotteryList', LList)
              this.$store.dispatch('actionsSetNewLotteryConfig', LConfig)
              localStorage.setItem('newLotteryList', JSON.stringify(LList))
              localStorage.setItem('newLotteryConfig', JSON.stringify(LConfig))
              this.LotteryList = LList
              this.LotteryConfig = LConfig
              setConfig()
            } else {
              console.log('股指获取失败')
              this.$router.push('/index')
            }
          })
        }
      }
      const Difftime = localStorage.getItem('Difftime')
      if (Difftime === 'NaN' || !Difftime) {
        this.getServerTime(ref => {
          getLotteryConfig()
        })
      } else {
        getLotteryConfig()
      }
    },
    reSetLottery () {
      localStorage.removeItem('lotteryConfig')
      localStorage.removeItem('newLotteryConfig')
      localStorage.removeItem('newLotteryList')
      api.lotteryConfig().then(ref => {
        const lotteryArr = ref.data
        const LotteryConfig = setTimeStr(lotteryArr.lotteryConfig)
        const LotteryList = setTimeStr(lotteryArr.lotteryList)
        this.$store.dispatch('actionsSetNewLotteryList', LotteryList)
        this.$store.dispatch('actionsSetNewLotteryConfig', LotteryConfig)
        this.LotteryList = LotteryList
        this.LotteryConfig = LotteryConfig
        localStorage.setItem('newLotteryConfig', JSON.stringify(LotteryConfig))
        localStorage.setItem('newLotteryList', JSON.stringify(LotteryList))
      })
    },
    reRebate () {
      const type = this.lType
      this.$store.state['newRebate' + type] = ''
      localStorage.removeItem(`newRebate${type}`)
      const userInfo = this.userInfo && this.userInfo.userName
      localStorage.removeItem(`rebateAll${userInfo}`)
    },
    getLotteryCode (code) {
      return this.newLotteryList && this.newLotteryList.filter(v => v.lotteryCode === code)[0]
    },
    getServerTime: (() => {
      let cantGetTime = 0
      let timeItemList = []
      return function (fun) {
        const timeBegin = new Date().getTime()
        api.getServerTime().then((ref) => {
          const timeEnd = new Date().getTime()
          const interval = timeEnd - timeBegin
          const timeReg = /^\d{13}$/
          // code>-1 且 返回的数据为13数字
          if (ref.code > -1 && timeReg.test(ref.data)) {
            timeItemList.push(new TimeItem(interval, timeEnd, timeBegin, ref.data))
          }
          if (cantGetTime > 4) {
            const noTimeGeted = timeItemList.every(timeItem => !timeItem.SerTime) // 一次都没获取到数据
            if (noTimeGeted) {
              cantGetTime = 0
              // let Difftime = 0
              // Difftime = (new Date().getTimezoneOffset() + 480) * 60
              this.msgLottery({
                title: this.$t('TRADE_TIPS'),
                message: this.$t('BASELOTTERY_NET_ERROR'),
                btn: [this.$t('CONFIRM')],
                fun: () => {
                  this.$router.push('/index')
                }
              })
              timeItemList = []
              fun && fun()
            } else {
              // 有一些获取到了数据，但是超时了。从获取到的再择优
              this.$store.commit('setDifftime', timeItemList)
              cantGetTime = 0
              timeItemList = []
              fun && fun()
            }
          } else {
            if (interval > 1000) {
              cantGetTime++
              this.getServerTime(fun)
            } else {
              if (ref.code === 1) {
                if (!ref.data) {
                  cantGetTime++
                  this.getServerTime(fun)
                } else {
                  this.$store.commit('setDifftime', timeItemList)
                  cantGetTime = 0
                  timeItemList = []
                  fun && fun()
                }
              } else {
                cantGetTime++
                this.getServerTime(fun)
              }
            }
          }
        })
      }
    })(),
    setDefaultMode () {
      // 玩法配置
      const defaultMode = {
        STOCK: ['双面盘', '普通玩法']
      }

      const group = defaultMode[this.lType][0] // 默认的玩法群
      const subGroup = defaultMode[this.lType][1] // 默认的玩法组
      // console.log('setDefaultMode', this.$store.state.lt)
      this.$store.commit('lt_changeMode', this.$store.state.lt.config[group][subGroup][0].mode)
    },
    resetOut () {
      // 离开页面前将每股金额重设为 PERBET (2元)
      if (this.$store.state.lt) {
        this.$store.commit('lt_setPower', 1)
        this.$store.commit('lt_setPerbet', PERBET)
        this.$store.commit('lt_clearBet')
        this.$store.commit('lt_clearBasket')
        // this.$store.commit('lt_setScheme', [])
        this.$store.commit('lt_setChasePower', '') // 清空追号配置
        this.$store.commit('lt_setChaseIssue', 10)
      }
      clearTimeout(this.openTimeBack)
    }
  },
  watch: {
    $route (val) {
      this.lType = 'STOCK'
      // this.lCode = this.$route.params.id
      this.$store.commit('lt_setPerbet', PERBET)
      this.$store.commit('lt_clearBet')
      this.$store.commit('lt_clearBasket')
      // this.$store.commit('lt_setScheme', [])
      this.$store.commit('lt_setChasePower', '') // 清空追号配置
      this.$store.commit('lt_setChaseIssue', 10)
    }
  },
  created () {
    scrollTop()
    this.lType = 'STOCK'
    // if (this.$route.params.id) {
    //   this.lCode = this.$route.params.id
    // }
    this.init()
  },
  mounted () {
    this.$store.dispatch('setInterfaceLoading', false)
    this.getLatestStockIssue()
  },
  beforeRouteEnter (to, from, next) {
    scrollTop()
    let user = localStorage.getItem('userInfo')
    function setRebateAll (obj, fun) {
      for (const n in obj) {
        if (obj[n]) {
          localStorage.setItem(`newRebate${n}`, JSON.stringify(obj[n]))
        }
      }
      fun()
    }
    const funtime = () => {
      const lotteryAllConfig = localStorage.getItem('lotteryConfig')
      let LConfig = null
      let LList = null
      if (lotteryAllConfig) {
        const config = Object.values(JSON.parse(lotteryAllConfig))[0]
        LConfig = config.lotteryConfig
        LList = config.lotteryList
      }
      let lotteryConfig = []
      let isOdd = false
      if (!LConfig || !LList) {
        isOdd = false
        lotteryConfig = api.lotteryConfig()
      } else {
        isOdd = true
        lotteryConfig = new Promise((resolve, reject) => {
          resolve({ data: { lotteryConfig: LConfig, lotteryList: LList } })
        })
      }
      const type = 'STOCK' // to.name
      const storageName = `newRebate${type}`
      function getResole () {
        let Rebate = localStorage.getItem(storageName)
        Rebate = Rebate && JSON.parse(Rebate)
        if (Rebate) {
          return new Promise(resolve => {
            resolve({ data: Rebate })
          })
        } else {
          return api.getBetRebate({ lotteryType: type })
        }
      }
      const getBetRebate = getResole()
      Promise.all([lotteryConfig, getBetRebate]).then((ref) => {
        const lotteryArr = ref[0].data
        const LotteryConfig = isOdd ? lotteryArr.lotteryConfig : setTimeStr(lotteryArr.lotteryConfig)
        const LotteryList = isOdd ? lotteryArr.lotteryList : setTimeStr(lotteryArr.lotteryList)
        const BetRebate = ref[1].data
        localStorage.setItem('newLotteryConfig', JSON.stringify(LotteryConfig))
        localStorage.setItem('newLotteryList', JSON.stringify(LotteryList))
        if (BetRebate) {
          localStorage.setItem(storageName, JSON.stringify(BetRebate))
        }
        next(vm => {
          // console.log('BetRebate', BetRebate)
          console.log('进入..')
        })
      })
    }
    if (user) {
      user = JSON.parse(user)
      let rebateAll = localStorage.getItem('rebateAll' + user.userName)
      if (rebateAll) {
        rebateAll = Object.values(JSON.parse(rebateAll))[0].allRebateData
        setRebateAll(rebateAll, funtime)
      } else {
        funtime()
      }
      // 判断page更新时间
      manageApi.getFrontCacheUpdatedAt().then((res) => {
        if (res.code === 1) {
          checkCatchTime(res.data.pageUpdateTimeMobile, () => {
            document.location.href = document.location.href + '?' + Math.floor(Math.random() * 100)
          })
          localStorage.setItem('pageUpdateTime', res.data.pageUpdateTimeMobile)
        }
      })
    }
  },
  beforeRouteLeave (to, from, next) {
    this.resetOut()
    next()
  },
  beforeDestroy () {
    this.$dialog.close()
    this.isPost = false
  }
}
