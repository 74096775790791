<template>
  <div class="wallet__dropdownwrap mr-4">
    <van-collapse
      :value="activeUserAccount"
      accordion
      class="wallet__dropdown"
    >
      <van-collapse-item
        class="wallet__vancollapseitem"
        name="0"
        :title="`${$t('PC_WALLET')}`"
      >
        <div class="wallet__showwrap">
          <div class="show__wallet">
            <div
              v-if="showTybe"
              class="typea type-set"
            >
              <div class="wallet__info">
                <div class="wallet__title">
                  {{ $t('LAYOUT_ACCOUNT_OVERAGE') }}
                </div>
                <div class="wallet__info">
                  {{ userBalance === '---' ? userBalance : calcPriceHandler(curBalnace) }}
                </div>
              </div>
              <div
                class="wallet__icon"
                @click="changeHandler"
              >
                <div class="img" />
              </div>
            </div>
            <div
              v-else
              class="typeb type-set"
            >
              <div class="wallet__info">
                <div class="wallet__title">
                  {{ $t('ACCOUNT_VIRTUAL_WALLET') }}
                </div>
                <div class="wallet__info">
                  {{ virtualBalance === '---' ? virtualBalance : calcPriceHandler(virtualBalance) }}
                </div>
              </div>
              <div
                class="wallet__icon"
                @click="changeHandler"
              >
                <div class="img" />
              </div>
            </div>
          </div>
          <div class="link__item">
            <router-link
              v-for="link in linkItem"
              :key="link.name"
              v-slot="{ navigate,href}"
              class="item"
              :class="{active:selectActive===link.pageId}"
              :to="{ name: `${link.route}`,query: { id: link.pageId }}"
              custom
            >
              <button
                :href="href"
                @click="navigate"
              >
                {{ link.name }}
              </button>
            </router-link>
          </div>
        </div>
      </van-collapse-item>
    </van-collapse>
  </div>
</template>

<script>
import apiTrade from '@/api/trades'
import { mapGetters } from 'vuex'
export default {
  props: {
    activeUserAccount: {
      type: String,
      default: '',
      required: true
    }
  },
  data () {
    return {
      dayValue: 'wallet',
      selectType: 0,
      userInfo: null,
      // activeUserAccount: '',
      showTybe: true,
      virtualBalance: '---'
    }
  },
  computed: {
    ...mapGetters(['updatedUserBalance']),
    curBalnace () {
      return Number(this.userBalance) + Number(this.updatedUserBalance)
    },
    linkItem () {
      return [

        {
          name: this.$t('LAYOUT_ASSETS_MANAGE'),
          route: 'Recharge',
          pageId: 'AssetsPage'
        },
        {
          name: this.$t('DEPOSIT'),
          route: 'Recharge',
          pageId: 'RechargePage'
        },
        // {
        //   name: this.$t('WITHDRAWAL'),
        // },
        {
          name: this.$t('ACTING_TRANSACTION_DETAILS'),
          route: 'Recharge',
          pageId: 'TradePage'
        }
      ]
    },
    option1 () {
      return [
        { text: this.$t('PC_WALLET'), value: 'wallet', icon: '' }
      ]
    },
    userBalance () {
      return this.$store.state.userBalance
    },
    selectActive () {
      const queryValue = this.$route.query
      const checkEmpty = Object.keys(queryValue).length === 0
      if (checkEmpty) {
        return null
      } else {
        return queryValue.id
      }

      // if (this.$route.name === 'Recharge') {
      //   return this.$route.query.pageid
      // } else {
      //   return null
      // }
    }
  },
  mounted () {
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
    this.$store.dispatch('actionSetUserBalance')
    this.getUserVirtual()
  },
  methods: {
    changeHandler () {
      this.showTybe = !this.showTybe
    },
    getUserVirtual () {
      apiTrade.virtualWalletBalance().then((res) => {
        if (res.code === 1) {
          this.virtualBalance = res.data
        }
      }).finally(() => {

      })
    },
    calcPriceHandler (price) {
      const parseNumber = +price
      return parseNumber.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
    }
  }
}

</script>

<style lang="scss" scoped>
.wallet {
  &__dropdownwrap {
    &::v-deep {
      .van-hairline--top-bottom {
        &::after {
          display: none;
        }
      }
    }
  }

  &__vancollapseitem {
    position: relative;
    // border: 1px solid #F00;

    &::v-deep {
      .van-collapse-item__wrapper {
        position: absolute;
        right: 0;
        border-radius: 4px;
        z-index: 20;
        box-shadow: var(--dropdownBoxShadow);
        transform: translateY(10px);
        transition-duration: 0.1s;
      }

      .van-cell {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        color: var(--dropdownVancellColor);
        background-color: transparent;

        &::after {
          display: none;
        }
      }

      .van-cell__title {
        flex: 0 0 auto;
      }

      .van-collapse-item__content {
        padding: 0;
        width: 280px;
        background-color: var(--dropdownContentBgc);
        border: var(--dropdownBorder);
        border-radius: 4px;
      }

      .van-icon {
        position: relative;
        width: 12px;
        height: 12px;

        &::before {
          display: none;
        }

        &::after {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          content: '';
          background-image: url('/public/static/images/Layout/triangle.svg');
        }
      }

      .van-collapse-item__title--expanded {
        color: var(--layoutRouterHover);

        .van-icon {
          &::after {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            content: '';
            background-image: url('/public/static/images/Layout/triangle-active.svg');
          }
        }

      }
    }
  }
}

.show__wallet {
  display: flex;
  align-items: center;
  padding-right: 16px;
  padding-left: 16px;
  width: 100%;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  height: 60px;
  border-bottom: var(--dropdownTitleBorder);

  .type-set {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  &:hover {
    background-color: var(--dropdownHoverItemColor);
  }
}

.wallet {
  &__title {
    font-size: 12px;
    line-height: 17px;
    color: var(--dropdownInfoColor);
  }

  &__info {
    font-size: 16px;
    line-height: 22px;
    color: var(--dropdownItemColor);
  }

  &__icon {
    cursor: pointer;

    .img {
      width: 16px;
      height: 16px;
      background-image: url('/public/static/images/Layout/change-type.svg');
      background-repeat: no-repeat;
      background-size: cover;

      &:hover {
        background-image: url('/public/static/images/Layout/change-type-hover.svg');
      }
    }
  }
}

.link__item {
  .item {
    display: flex;
    padding-left: 16px;
    width: 100%;
    height: 36px;
    font-size: 14px;
    color: var(--dropdownInfoColor);
    line-height: 20px;
    line-height: 36px;
    cursor: pointer;
    flex-direction: column;

    &:last-child {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    &.active,
    &:hover {
      color: var(--dropdownItemColor);
    }

    &:hover {
      background-color: var(--dropdownHoverItemColor);
    }

    &.active {
      cursor: default;
    }
  }
}
</style>
