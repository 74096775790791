import * as types from '../types'
export const live = {
  state: {
    // 直播信息
    url: 'https://images.imags-google.com/',
    Anchor: '',
    GameConfig: '',
    barrageList: '',
    broadCastData: [],
    RandomBarrage: '',
    activeGift: '',
    giftList: '' || ((localStorage.getItem('gifts') && JSON.parse(localStorage.getItem('gifts'))) || ''),
    bigGiftList: [],
    smallGiftList: []
  },
  getters: {
    Anchor: state => state.Anchor,
    GameConfig: state => state.GameConfig,
    barrageList: state => state.barrageList,
    broadCastData: state => state.broadCastData,
    RandomBarrage: state => state.RandomBarrage,
    activeGift: state => state.activeGift
  },
  actions: {
    /**
     * 直播信息
     */
    setAnchorInfo ({ commit }, res) {
      commit(types.SET_ANCHOR_INFO, res)
    },
    /**
     * 系统配置
     */
    setGameConfig ({ commit }, res) {
      commit(types.SET_GAME_CONFIG, res)
    },
    /**
     * 弹幕
     */
    setBarrageList ({ commit }, res) {
      commit(types.SET_BARRAGE_LIST, res)
    },
    /**
     * 广播
     */
    setBroadCastData ({ commit }, res) {
      commit(types.SET_BROAD_CAST_DATA, res)
    },
    /**
     * 礼物
     */
    setActiveGift ({ commit }, res) {
      commit(types.SET_ACTIVE_GIFT, res)
    },
    setRandomBarrage ({ commit }, res) {
      commit(types.SET_RANDOM_BARRAGE, res)
    }
  },
  mutations: {
    [types.SET_ANCHOR_INFO] (state, res) {
      state.Anchor = res
    },

    [types.SET_GAME_CONFIG] (state, res) {
      state.GameConfig = res
    },

    [types.SET_BARRAGE_LIST] (state, res) {
      state.barrageList = res
    },

    [types.SET_ACTIVE_GIFT] (state, res) {
      state.activeGift = res
    },

    [types.SET_BROAD_CAST_DATA] (state, res) {
      state.broadCastData = res
    },

    [types.SET_RANDOM_BARRAGE] (state, res) {
      state.RandomBarrage = res
    }
  }
}
