<template>
  <div class="panel">
    <div
      class="panel__header"
      :style="`--width:${width}`"
    >
      <button
        v-for="num of btnNum"
        :key="num"
        class="panel__btn"
        :class="[{pointer:pointer},{active:active[num-1]},$i18n.locale]"
        @click="$emit('btnClick',num)"
      >
        <slot :name="`btn-${num}`" />
      </button>
    </div>
    <div class="panel__body mb-12">
      <slot name="body" />
    </div>
  </div>
</template>
<script>
export default {
  name: 'BasePanpel',
  props: {
    width: {
      type: String,
      default: ''
    },
    btnNum: {
      type: Number,
      default: 0
    },
    pointer: {
      type: Boolean,
      default: false
    },
    active: {
      type: Array,
      default: () => [true]
    }
  }
}
</script>
<style lang="scss" scoped>
.panel {
  font-size: 14px;
  line-height: 20px;

  &__header {
    position: relative;
    padding-left: 0;

    &::after {
      position: absolute;
      top: 0;
      right: 0;
      width: calc(100% - var(--width));
      height: 100%;
      background-color: var(--basePanelBgc);
      content: '';
      opacity: 0.2;
    }
  }

  &__body {
    position: relative;

    &::after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: var(--basePanelBgc);
      opacity: 0.35;
      content: '';
    }
  }

  &__btn {
    position: relative;
    padding: 12px 0;
    width: 96px;
    text-align: center;
    color: var(--basePanelColor);
    z-index: 2;
    cursor: default;

    // &.en {
    //   width: 110px;
    // }

    &.pointer {
      cursor: pointer;
    }

    &:hover {
      color: var(--basePanelHoverColor);
    }

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: var(--basePanelBgc);
      opacity: 0.2;
      z-index: -1;
      content: '';
    }

    &.active {
      color: var(--basePanelActiveColor);

      &::before {
        opacity: 0.35;
      }
    }
  }
}
</style>
