<template>
  <div
    ref="announcement__sliderwrap"
    class="announcement__wrap"
  >
    <!-- <transition>
      <div
        v-show="loadState"
        class="loading__wrap"
      >
        <GlobalLoading />
      </div>
    </transition> -->
    <swiper
      ref="mySwiper"
      class="announcement__swiper"
      :options="swiperOption"
    >
      <swiper-slide
        v-for="(slide,index) in announcementLists"
        :key="index"
        class="announcement__swipershow"
      >
        <div class="announcement__mainshow">
          <div class="announcement__title">
            {{ slide.title }}
          </div>
          <div class="announcement__time">
            {{ slide.publishTime }}
          </div>
          <!-- eslint-disable vue/no-v-html -->
          <div
            class="announcement__info"
            v-html="announcementDetail.content"
          />
          <!--eslint-enable-->
        </div>
      </swiper-slide>
    </swiper>
    <div class="line" />
    <!-- {{ announcementDetail }} -->
    <div class="announcement__btns">
      <div class="btn__group">
        <div
          slot="button-prev"
          class="announcement__prev btn-style"
          :class="{ disable: localIndex === 0 }"
          @click="prevHandler"
        >
          {{ $t('PC_ANNOUNCEMENT_PREV') }}
        </div>
        <div
          slot="button-next"
          class="announcement__next btn-style"
          :class="{ disable: localIndex === (announcementLists.length - 1) }"
          @click="nextHandler"
        >
          {{ $t('PC_ANNOUNCEMENT_NEXT') }}
        </div>
      </div>
      <div class="showtotal">
        <div class="showtotal__text">
          {{ $t('PC_IN_TOTAL',{num: announcementLists.length}) }}
        </div>
        <div
          class="close btn-style btn-cancel"
          @click="closeHandler"
        >
          {{ $t('CLOSE') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import apiManage from '@/api/manage'
import { swiperInit } from '@/utils/swiper'
// import GlobalLoading from '@/components/loader/GlobalLoading'
swiperInit()
export default {
  directives: {
    swiper: directive
  },
  components: {
    Swiper,
    SwiperSlide
    // GlobalLoading
  },
  props: {
    announcementLists: {
      type: Array,
      required: true,
      default: () => ([])
    }
  },
  data () {
    return {
      swiperOption: {
        effect: 'fade',
        fadeEffect: {
          crossFade: true
        },
        slidesPerView: 1,
        slidesPerGroup: 1,
        speed: 500,
        navigation: {
          nextEl: '.announcement__next',
          prevEl: '.announcement__prev'
        }
      },
      announcementDetail: {},
      localIndex: 0,
      loadState: false
    }
  },
  computed: {
    swiper () {
      return this.$refs.mySwiper.$swiper
    }
  },
  mounted () {
    this.getAnnouncementDetail(this.localIndex)
  },
  methods: {
    prevHandler () {
      this.apiStates = false
      if (this.localIndex <= 0) {
        this.localIndex = 0
      } else {
        this.localIndex = this.localIndex - 1
      }
      this.getAnnouncementDetail(this.localIndex)
    },
    nextHandler () {
      this.apiStates = false
      if (this.localIndex >= this.announcementLists.length - 1) {
        this.localIndex = this.announcementLists.length - 1
      } else {
        this.localIndex = this.localIndex + 1
      }
      this.getAnnouncementDetail(this.localIndex)
    },
    getAnnouncementDetail (value) {
      // API第一筆id
      const announLength = this.announcementLists.length// 線上公告資料
      // console.log(notice)
      this.loadState = true
      if (announLength > 0) {
        // 有公告資料
        const params = {
          id: this.announcementLists[this.localIndex].id
        }
        apiManage.announcementContent(params).then((res) => {
          if (res.code === 1 && res.data) {
            const apiResult = res?.data
            this.announcementDetail = apiResult
            // setTimeout(() => {
            //   this.loadState = false
            // }, 500)
            localStorage.setItem('announcement', JSON.stringify(apiResult))
          }
        })
      } else {
        // 無公告資料
        // 開啟預設value
        // this.announcementDef = true
      }
    },
    closeHandler () {
      this.$emit('clocePopup')
    }
  }
}
</script>

<style lang="scss" scoped>
.announcement {
  &__swipershow {
    overflow-y: scroll;
    height: 400px;
    font-size: 14px;
    color: var(--announcementSwiperColor);
    transform: translate3d(0, 0, 0);
    //border: 1px solid #F00;
  }

  &__wrap {
    height: 100%;
    // border: 1px solid #F00;
  }

  &__btns {
    position: relative;
    top: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // margin-top: 10px;
    padding-right: 20px;
    padding-left: 20px;
    height: 60px;
    font-size: 12px;
    color: var(--announcementBtnsColor);
    // border: 1px solid #F00;

    &::before {
      position: absolute;
      top: 1px;
      left: 50%;
      width: 92.14%;
      height: 1px;
      background-color: #253C5A;
      content: '';
      transform: translateX(-50%);
    }
  }

  &__btn {
    // display: flex;
    // align-items: center;
    // justify-content: center;
    // width: 40px;
    // height: 40px;
    // cursor: pointer;

    &:hover {
      color: #FAFAFA;
      background-color: #2E3E57;
    }

    &:first-child {
      margin-right: 12px;
    }

    &.hide {
      position: absolute;
      opacity: 0;
      z-index: -1;
    }
  }

  &__mainshow {
    position: relative;
    padding: 20px;
    min-height: 330px;
    transform: translate3d(0, 0, 0);
  }

  &__title {
    font-size: 16px;
    line-height: 22px;
    color: var(--announcementTitleColor);
    margin-bottom: 4px;
  }

  &__time {
    font-size: 12px;
    line-height: 17px;
    margin-bottom: 24px;
  }

  &__info {
    transform: translate3d(0, 0, 0);
  }
}

.btn {
  &__group {
    display: flex;
    justify-content: space-between;
    width: 204px;
  }

  &-style {
    @include confirm_btn_4;

    width: 96px;
    height: 28px;
    font-size: 14px;
    text-align: center;
    color: #879AAF;
    border: 1px solid #879AAF;
    border-radius: 4px;
    line-height: 26px;

    &.disable {
      display: none;
      border: 1px solid #000;
    }
  }

  &-cancel {
    @include confirm_btn_5;

    color: var(--popupRevokeBtnRevokeColor);
    border: 0;
  }
}

.loading__wrap {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100% - 40px);
  background-color: var(--PopupBgc);
  z-index: 2;
}

.showtotal {
  display: flex;
  align-items: center;
  // border: 1px solid #F00;

  &__text {
    margin-right: 12px;
  }
}

.v-leave {
  opacity: 1;
}

.v-leave-active {
  transition: opacity 0.65s;
}

.v-leave-to {
  opacity: 0;
}

.v-enter {
  opacity: 0;
}

.v-enter-active {
  transition: opacity 0.65s;
}

.v-enter-to {
  opacity: 1;
}
</style>
