const state = {
  themeWord: undefined
}
const mutations = {
  SET__THEME (state, data) {
    state.themeWord = data
  }
}
export default {
  namespaced: true,
  state,
  mutations
}
