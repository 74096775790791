<template>
  <div clsss="bet__wrapsetting">
    <div class="bet__title">
      {{ $t('TRADE_STOCK_TRADEMAIN_BUY_BTN') }}
    </div>
    <div class="bet__maintabwrap">
      <div class="bet__stockwrap">
        <div class="stockname">
          <!-- {{ currentStockData.lotteryName }} -->
          {{ stockResMsg(`STOCK_${currentStockData.lotteryCode}`,currentStockData.lotteryName) }}
        </div>
        <div
          class="qa"
          @mouseenter="showPopover=true"
          @mouseleave="showPopover=false"
        >
          <van-popover
            v-model="showPopover"
            placement="bottom-end"
            class="play-type-popover"
            get-container="#app"
          >
            <div
              class="qa-playinto"
              :class="sideClose"
            >
              {{ playType }}
            </div>
            <template #reference>
              <div class="qa-title">
                <span class="qatitle-text">
                  {{ $t('PC_PLAY_EXPLAN') }}
                </span>
                <span>
                  <img
                    v-if="!showPopover"
                    src="/static/images/stockIcon/Info-Circle.svg"
                    alt=""
                  >
                  <img
                    v-else
                    src="/static/images/stockIcon/Info-Circle-active.svg"
                    alt=""
                  >
                </span>
              </div>
            </template>
          </van-popover>
        </div>
      </div>
      <div class="tabs__wrap">
        <div class="swiper__btn">
          <div
            class="btn"
            :class="{active:selcetIndex===0}"
            @click="sliderChangeHandler(0,'D11')"
          >
            <div class="btn-text">
              {{ $t('TRADE_DOUBLE_SIDE') }}
            </div>
          </div>
          <div
            class="btn"
            :class="{active:selcetIndex===1}"
            @click="sliderChangeHandler(1,'A11')"
          >
            <div class="btn-text">
              {{ $t('TRADE_POSITIONING') }}
            </div>
          </div>
          <span
            class="moveline"
            :style="{left:moveTab}"
          />
        </div>
        <div class="swiper__wrap">
          <swiper
            ref="mySwiper"
            :options="swiperOptions"
            class="fromslider__slider swiper-no-swiping"
          >
            <swiper-slide class="fromslider__sidewrap ">
              <DoublueTab :active-index="selcetIndex" />
            </swiper-slide>
            <swiper-slide class="fromslider__sidewrap">
              <PostionTab :active-index="selcetIndex" />
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { stockResMsg } from '@/utils'
import { stockConfig } from '@/assets/js/lottery/stock'
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import { swiperInit } from '@/utils/swiper'
import DoublueTab from './Double.vue'
import PostionTab from './Postion.vue'
import { mapState } from 'vuex'
const stock = {
  0: '双面盘',
  1: '定位胆'
}
swiperInit()
export default {
  directives: {
    swiper: directive
  },
  components: {
    Swiper,
    SwiperSlide,
    DoublueTab,
    PostionTab
  },
  data () {
    return {
      stockConfig,
      active: 'D11',
      swiperOptions: {
        noSwiping: true,
        // speed: 100,
        pagination: {
          el: '.swiper-pagination'
        }
      },
      selcetIndex: 0,
      showPopover: false
    }
  },
  computed: {
    ...mapState(['optionSides']),
    currentStockData () {
      const lCode = this.$store.state.lt ? this.$store.state.lt.lCode : this.$store.state.defaultStock
      const data = localStorage.getItem('newLotteryList') && JSON.parse(localStorage.getItem('newLotteryList'))
      return data ? data.filter(item => item.lotteryCode === lCode)[0] : {}
    },
    swiper () {
      return this.$refs.mySwiper.$swiper
    },
    moveTab () {
      return ` ${50 * this.selcetIndex}% `
    },
    playType () {
      const key = stock[this.selcetIndex]
      return this.stockConfig[key]['普通玩法'][0] ? this.$t(this.stockConfig[key]['普通玩法'][0].tip) : ''
    },
    sideClose () {
      return !this.optionSides.trendSide ? 'close-side' : ''
    }
  },
  methods: {
    stockResMsg,
    switchTab (e) {
      this.$store.dispatch('actionSetMode', e)
    },
    sliderChangeHandler (value, mode) {
      this.$store.dispatch('actionSetMode', mode)
      this.selcetIndex = value
      this.swiper.slideTo(value, 300, false)
    },
    getContainer () {
      return document.querySelector('.qa')
    }
  }
}
</script>

<style lang="scss" scoped>
.bet {
  &__title {
    position: relative;
    padding-left: 16px;
    height: 44px;
    font-size: 14px;
    color: var(--indexBetTabTitleColor);
    line-height: 44px;
    border-bottom: var(--indexBetTabTitleBorder);

    // &::after {
    //   position: absolute;
    //   width: 100%;
    //   height: 100%;
    //   background: #D6D6D6 0% 0% no-repeat padding-box;
    //   mix-blend-mode: multiply;
    //   content: '';
    //   z-index: -1;
    // }

    // &::before {
    //   position: absolute;
    //   width: 100%;
    //   height: 100%;
    //   background: #1B2A43;
    //   mix-blend-mode: multiply;
    //   content: '';
    //   z-index: -2;
    // }
  }

  &__stock {
    display: flex;
  }

  &__maintabwrap {
    display: flex;
    // border: 1px solid #F00;
    // padding-left: 16px;
    overflow-y: scroll;
    // overflow-x: hidden;
    // overflow-y: auto;
    padding-top: 23px;
    // padding-right: 16px;
    padding-bottom: 12px;
    width: calc(100% - 6px);
    flex: 1;
    flex-direction: column;

    &::v-deep {
      .tabs__wrap {
        display: flex;
        width: 100%;
        flex: 1;
        flex-direction: column;
        // border: 1px solid #F00;
        // padding-right: 16px;
        // padding-left: 16px;
      }
    }
  }

  &__stockwrap {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 16px;
    padding-left: 16px;
    font-size: 14px;
    z-index: 2;
    margin-bottom: 30px;

    >.qa {
      font-size: 12px;
      color: var(--indexBetStockWrapQaColor);
      line-height: 17px;

      img {
        display: inline-block;
        transform: translateY(1px);
        vertical-align: initial;
      }
    }
  }

  &__tabswrap {
    position: relative;
    display: flex;
    height: 100%;
    flex-direction: column;

    &.D11 {
      &::after {
        display: none;
      }
    }

    // &::after {
    //   position: absolute;
    //   top: 56px;
    //   left: 50%;
    //   width: calc(100% + 32px);
    //   height: 36px;
    //   background-color: #17253D;
    //   content: '';
    //   transform: translateX(-50%);
    //   z-index: 1;
    // }

    &::v-deep {
      .van-tabs__content {
        width: 100%;
        // border: 1px solid #0F0;
        // flex: 1;
      }

      .van-tabs__wrap {
        position: relative;
        height: 36px;
        // border-bottom: 4px solid #FAFAFA;
        z-index: 2;

      }

      .van-tabs__nav {
        background-color: var(--indexBetTabNavColor);
      }

      .van-tab {
        font-size: 12px;
        color: var(--indexBetVanTabColor);
        line-height: 17px;
      }

      .van-tab--active {
        color: var(--indexBetVanTabActiveColor);
        //background-color: var(--indexBetVanTabActiveBgc);
      }
    }
  }

}

.side2.cancel {
  .bet__title {
    background-color: transparent;
    transition: 1s;
  }
}

.swiper__btn {
  position: relative;
  display: flex;
  margin: 0 auto;
  width: calc(100% - 32px);

  >.btn {
    position: relative;
    height: 32px;
    font-size: 12px;
    text-align: center;
    background-color: var(--indexBetSwiperBtnBgc);
    flex: 1;
    line-height: 32px;
    cursor: pointer;

    &:hover {
      .btn-text {
        color: var(--indexBetHoverBtnColor);
      }
    }

    &.active {
      cursor: default;

      .btn-text {
        color: var(--indexBetActiveColor);
      }
    }

  }

  .btn-text {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    color: var(--indexBetBtnColor);
    z-index: 1;
    transition: 0.5s;
    transform: translate(-50%, -50%);

  }

  .moveline {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 50%;
    height: 32px;
    background-color: var(--indexBetMoveLineBgc);
    transition: 0.5s;
  }
}

.swiper__wrap {
  flex: 1;

  &::v-deep {
    .swiper-container {
      height: 100%;
    }

    .fromslider__sidewrap {
      // border: 1px solid #F00;
    }
  }
}

.qa {
  // border: 1px solid #F00;

  .qa-title {
    display: flex;
    align-items: center;
    transition: 0.3s;

    &:hover {
      color: #0589D7;
    }

    .qatitle-text {
      margin-right: 3.75px;
    }
  }

  &::v-deep {
    .van-popover{

    }

    .van-popover__content {
      position: relative;
      padding: 10px 12px;
      width: 189px;
      color: #879AAF;
      background-color: #17253D;
      border: 1px solid #253C5A;
      z-index: 4;
      box-shadow: 0 1px 2px #00000026;

    }
  }
}
</style>
