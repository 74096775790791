<template>
  <div class="tab__wrap">
    <Graph
      :code="code"
      :name="name"
    />
  </div>
</template>

<script>
import Graph from '@/components/Stock/Graph'
export default {
  name: 'Tab03',
  components: {
    Graph
  },
  props: {
    code: {
      type: [String, Number],
      required: true
    },
    name: {
      type: String,
      required: true
    }
  }
}
</script>

<style>

</style>
