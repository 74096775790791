// import types from '../types'

export const userInfo = function () {
  return localStorage.getItem('userInfo') && JSON.parse(localStorage.getItem('userInfo'))
}
export const dateType = function () {
  return this.$store.state.security.dateType || 'today'
}
export const inLoading = function () {
  return this.$store.state.loading
}

// localStorage
export const account = function () {
  const quicklyLoginList = localStorage.getItem('quicklyLoginList')
  return quicklyLoginList && JSON.parse(quicklyLoginList)
}

// 安全中心列表
export const securityList = function () {
  return this.$store.state.security.securityList
}
export const resetPassword = function () {
  // 用户登录账号记录
  const userInfo = JSON.parse(localStorage.getItem('userInfo'))
  const userName = userInfo && userInfo.userName
  const obj = {}
  obj[userName] = {
    password: localStorage.getItem('temporaryPass'),
    login: 1
  }
  const oldAccount = account() || {}
  const userAccount = Object.assign(oldAccount, obj)
  const resultUserInfo = Object.assign(userInfo, { login: 1 })
  localStorage.setItem('userInfo', JSON.stringify(resultUserInfo)) // 用来做是否长时间未登录校验,1 代表登录过
  localStorage.setItem('quicklyLoginList', JSON.stringify(userAccount))
}
