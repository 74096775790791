export const withdrawal = {
  state: {
    useData: {}
  },
  mutations: {
    pageBankData (state, data) {
      state.useData = data
    }
  },
  actions: {
  },
  modules: {
  }
}
