import i18n from '@/utils/i18n'

const url = process.env.NODE_ENV === 'development' ? 'test-image.qwqwea.com' : 'oiuhdfg-image.qwqwea.com'
// export function isTest () {
//   return process.env.NODE_ENV !== 'production' || `m.dfcdn28.com,m.dfcdn29.com,m.dfcdn38.com,m.caishen02.com,m.dafacloud-test.com,m.caishen03.com,m.app.dfcdn5.com`.includes(location.hostname)
// }
// // 基础路径用于项目是非根路径下
export function getUsername () {
  return JSON.parse(localStorage.getItem('userInfo')).userName.toLowerCase()
}
// export function getSourceId () {
//   const siteConfig = localStorage.getItem('siteConfig')
//   // console.log('getSourceId', siteConfig)
//   return siteConfig && siteConfig !== '0' ? Object.values(JSON.parse(localStorage.getItem('siteConfig')))[0].sourceId : 0 // 1 pc 2 mb 3 app
// }
export function getSiteConfig () {
  return (localStorage.getItem('siteConfig') && Object.values(JSON.parse(localStorage.getItem('siteConfig')))[0]) || {}
}

export function getStockTheme () {
  const userInfoTheme = (localStorage.getItem('userInfo') && JSON.parse(localStorage.getItem('userInfo')).theme) || 'classic'
  // console.log('userInfo', JSON.parse(localStorage.getItem('userInfo')))
  // console.log('utils:', userInfoTheme)
  localStorage.setItem('stockStyle', userInfoTheme)
  return userInfoTheme
  // const stockTheme = localStorage.getItem('stockStyle')
  // const apiInfo = JSON.parse(localStorage.getItem('userInfo')).theme
  // console.log('stock:', stockTheme, 'api:', apiInfo)
  // if (stockTheme !== apiInfo) {
  //   localStorage.setItem('stockStyle', apiInfo)
  //   return apiInfo
  // } else {
  //   return stockTheme && JSON.parse(JSON.stringify(localStorage.getItem('stockStyle')))
  // }
  // if (stockTheme) {
  //   return stockTheme && JSON.parse(JSON.stringify(localStorage.getItem('stockStyle')))
  // } else {
  //   localStorage.setItem('stockStyle', 'classic')
  //   return 'classic'
  // }
}

export const countryIconList = ['1841', '1842', '1843', '1844', '1845', '1846', '1847', '1848', '1849', '1864', '1865', '1866', '1867', '1868', '1891', '1892', '1893', '1894', '1895', '1901', '1902', '1903', '1904', '1905', '1906', '1907', '1908', '1909', '1910']
export const specialIcons = ['1801', '1803', '1804', '1811', '1812', '1813', '1814', '1815', '1816', '1817', '1818', '1819', '1821', '1822', '1823', '1824', '1825', '1826', '1827', '1828', '1829', '1831', '1832', '1833', '1834', '1835', '1836', '1837', '1838', '1839', '1840', '1841', '1844', '1845', '1846', '1847', '1848', '1849', '1864', '1865', '1866', '1867', '1868', '1861', '1862', '1863', '1851', '1852', '1842', '1843', '1871', '1872', '1891', '1892', '1893', '1894', '1881', '1895']

export const dynamicImgHost = `https://${url}`

export const messagePrompt = {
  msgInfo (msg, time) {
    // this.$toast({
    //   duration: time || 2000, // 持续展示 toast
    //   message: `<div class="van-toast-custom"><span>${msg}</span></div>`,
    //   mask: false,
    //   type: 'html'
    // })
    this.$toast.clear()
    return this.$dialog.alert({
      className: 'alert-big',
      getContainer: '#app',
      message: msg,
      title: i18n.tc('PRIVACY_HIT'),
      showConfirmButton: true,
      confirmButtonText: i18n.tc('CONFIRM')
    })
  },
  msgSuccess (msg, time) {
    this.$toast.success({
      duration: time || 600,
      message: msg || i18n.tc('PROCESS_DONE')
    })
  },
  msgLoading (mag, time) {
    this.$toast.loading({
      forbidClick: true,
      loadingType: 'spinner',
      duration: time || 0,
      message: mag || i18n.tc('WAIT_A_MINUTE')
    })
  },
  msgConfirm (msg, sureText, cancleText, title, fun) {
    this.$toast.clear()
    return this.$dialog.confirm({
      title: title || i18n.tc('PRIVACY_HIT'),
      className: 'notice-big',
      getContainer: '#app',
      message: msg,
      showCancelButton: !!cancleText,
      cancelButtonText: cancleText,
      showConfirmButton: !!sureText,
      confirmButtonText: sureText || i18n.tc('CONFIRM')
    }).then(() => {
      fun && fun()
    })
  },
  msgPcConfirm (msg, sureText, cancleText, title) {
    this.$toast.clear()
    return this.$dialog.confirm({
      title: title || msg,
      className: 'notice pc-notice',
      getContainer: '#app',
      message: title ? msg : '',
      showCancelButton: !!cancleText,
      cancelButtonText: cancleText,
      showConfirmButton: !!sureText,
      confirmButtonText: sureText || i18n.tc('CONFIRM')
    })
  },
  chaseConfirm (msg, sureText, cancleText, title) {
    this.$toast.clear()
    return this.$dialog.confirm({
      title: title || i18n.tc('PRIVACY_HIT'),
      className: 'notice-big chase-notice',
      getContainer: '#app',
      message: msg,
      showCancelButton: !!cancleText,
      cancelButtonText: cancleText,
      showConfirmButton: !!sureText,
      confirmButtonText: sureText || i18n.tc('CONFIRM')
    })
  },
  msgAlert (msg, sureText, cancleText, title) {
    this.$toast.clear()
    return this.$dialog.alert({
      title: title,
      className: 'alert',
      getContainer: '#app',
      message: msg,
      showCancelButton: !!cancleText,
      cancelButtonText: cancleText,
      showConfirmButton: !!sureText,
      confirmButtonText: sureText || i18n.tc('CONFIRM')
    })
  },
  msgAlertBig (msg, sureText, cancleText, title) {
    this.$toast.clear()
    // 若有標題則改顯示標題
    const newTitle = title || i18n.tc('PRIVACY_HIT')
    return this.$dialog.alert({
      title: newTitle,
      className: 'alert-big',
      getContainer: '#app',
      message: msg,
      showCancelButton: !!cancleText,
      cancelButtonText: cancleText,
      showConfirmButton: !!sureText,
      confirmButtonText: sureText || i18n.tc('CONFIRM')
    })
  },
  msgHtml (msg, sureText, cancleText) {
    this.$toast.clear()
    return this.$dialog.confirm({
      message: msg,
      title: i18n.tc('PRIVACY_HIT'),
      className: 'notice-big open-win',
      showCancelButton: !!cancleText,
      cancelButtonText: cancleText,
      showConfirmButton: !!sureText,
      confirmButtonText: sureText || i18n.tc('CONFIRM')
    })
  },
  // 投注专用弹窗
  msgLottery ({ title, message, btn, fun }) {
    this.$toast.clear()
    return this.$dialog.confirm({
      title,
      message,
      type: 'html',
      className: 'lottery-dialog notice-big',
      showCancelButton: !!(btn && btn.length > 1),
      cancelButtonText: btn && btn.length > 1 ? btn[1] : i18n.tc('CANCEL'),
      confirmButtonText: btn && btn.length ? btn[0] : i18n.tc('CONFIRM')
    }).then(() => {
      // 确认回调函数
      fun && fun()
    }).catch(function () { })
  },
  msgLotteryType ({ title, message, btn, fun }) {
    this.$toast.clear()
    return this.$dialog.confirm({
      title, // 第一为取消，第二为确认
      message,
      type: 'html',
      className: 'lottery-dialog lotteryType notice-big',
      showCancelButton: !!(btn && btn.length > 1),
      cancelButtonText: btn && btn.length > 1 ? btn[1] : i18n.tc('CANCEL'),
      confirmButtonText: btn && btn.length ? btn[0] : i18n.tc('CONFIRM')
    }).then(() => {
    }).catch(function () {
      // 确认回调函数
      fun && fun()
    })
  }
}
export default {
  install (Vue, options) {
    // 添加消息功能方法
    Object.assign(Vue.prototype, messagePrompt)
    // Object.assign(Vue.prototype, soundMessage)

    let uid = 0
    Vue.mixin({
      beforeCreate () {
        // 添加组件唯一标识
        this.$uid = uid.toString()
        uid += 1
      }
    })
  }
}
// export function dateFormat (time, format) { // time 为毫秒， format 为格式
//   var t = time
//   var tf = function (i) {
//     return (i < 10 ? '0' : '') + i
//   }
//   return format.replace(/yyyy|MM|dd|HH|mm|ss/g, function (a) {
//     switch (a) {
//       case 'yyyy':
//         return tf(t.getFullYear())
//         break
//       case 'MM':
//         return tf(t.getMonth() + 1)
//         break
//       case 'mm':
//         return tf(t.getMinutes())
//         break
//       case 'dd':
//         return tf(t.getDate())
//         break
//       case 'HH':
//         return tf(t.getHours())
//         break
//       case 'ss':
//         return tf(t.getSeconds())
//         break
//     }
//   })
// }
// export function errorGoLogin () {
//   window.document.location.href = '/login'
// }
export function camelToKebab (str) {
  return str.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase()
}
// // 若match返回不为null，则结果为true，输出match返回的数组(["test","test"])的长度
// export function strMatch (str, substr) {
//   var reg = new RegExp(substr, 'g')
//   return str.match(reg) ? str.match(reg).length : 0
// }
// export function bankList () {
//   let bankArr = {
//     '农业银行': 'ABC',
//     '交通银行': 'BCM',
//     '中国银行': 'BOC',
//     '建设银行': 'CCB',
//     '光大银行': 'CEB',
//     '兴业银行': 'CIB',
//     '中信银行': 'CITIC',
//     '招商银行': 'CMB',
//     '民生银行': 'CMBC',
//     '广发银行': 'GDB',
//     '华夏银行': 'HXBANK',
//     '工商银行': 'ICBC',
//     '邮政储蓄': 'PSBC',
//     '平安银行': 'SPABANK',
//     '浦发银行': 'SPDB',
//     '农商银行': 'CRCC',
//     '银座银行': 'YZB',
//     '其它银行': 'default'
//   }
//   return bankArr
// }
export function bankIcon () {
  const iconArr = {
    农业银行: 'icon-zhongguonongyeyinhang',
    交通银行: 'icon-jiaotongyinhang',
    中国银行: 'icon-zhongguoyinhang',
    建设银行: 'icon-zhongguojiansheyinhang',
    光大银行: 'icon-zhongguoguangdayinhang',
    兴业银行: 'icon-xingyeyinhang',
    中信银行: 'icon-zhongxinyinhang',
    招商银行: 'icon-zhaoshangyinhang',
    民生银行: 'icon-zhongguominshengyinhang',
    广发银行: 'icon-guangfayinhang',
    华夏银行: 'icon-huaxiayinhang',
    工商银行: 'icon-zhongguogongshangyinhang',
    邮政储蓄: 'icon-zhongguoyouzheng',
    平安银行: 'icon-pufayinhang',
    浦发银行: 'icon-yinhanglogo-',
    农商银行: 'icon-tianjinnongshangyinhang',
    银座银行: 'icon-yinhangqia',
    其它银行: 'icon-yinhangqia'
  }
  return iconArr
}

export const dictionName = {
  101: 'INTERNET_PAY', // 网银支付
  102: 'BANK_TRANSFER', // 银行转账
  103: 'ALIPAY', // 支付宝
  104: 'WECHAT_PAY', // 微信支付
  105: 'QQ_WALLET', // QQ钱包
  106: 'FOURTH_PAY', // 第四方支付
  107: 'UNION_PAY', // 银联支付
  108: 'CLOUD_PAY', // 云闪付
  109: 'JD_PAY', // 京东钱包
  112: 'CURRENCY_DIGITAL', // 數字貨幣
  201: 'WITHDRAE_REFUND', // 出金退回
  202: 'INVEST_ORDER_CANCEL', // 投资撤单
  203: 'CHASE_STOP', // 追号停止
  204: 'AGENT_REBATE', // 代理返点
  205: 'PROFIT_DISTRIBUTE', // 盈利派送
  206: 'DAILY_PRIZE', // 每日加奖
  207: 'LEVELUP_PRIZE', // 晋级奖励
  208: 'DEPOSIT_DISCOUNT', // 入金优惠
  209: 'PROFIT_RE_SEND', // 盈利补派
  210: 'SYSTEM_CANCEL_ORDER', // 系统撤单
  211: 'ENVELOPE_WITHDRAW', // 红包领取
  212: 'ENVELOPE_REFUND', // 红包退回
  213: 'TRANSFER_GET', // 转账收款
  214: 'TRANSFER_REFUND', // 转账退回
  215: 'DRAW_REFUND', // 和局退款
  216: 'ACTIVITY_ENVELOPE', // 活动红包
  217: 'DAILY_SIGN_IN', // 每日签到
  301: 'WITHDRAW_DEDUCTION', // 出金扣款
  302: 'DEAL_INVESTMENT_DEDUCTIONS', // 投资扣款
  303: 'TIPS_DEDUCTION', // 打赏扣款
  304: 'ENVELOPE_DEDUCTION', // 红包扣款
  305: 'TRANSFER_DEDUCTION', // 转账扣款
  309: 'THIRD_PARTY_WITHDRAW_DEDUCTION', // 代付出金扣款
  401: 'ARITIFICIAL_DEPOSIT', // 人工存款
  402: 'ARITIFICIAL_DEPOSIT_2', // 人工存入
  403: 'OTHER_DISCOUNT', // 其他优惠
  404: 'ERROR_WITHDRAW', // 误存提出
  405: 'ADMIN_WITHDRAW', // 行政提出
  406: 'WITHDRAW_REJECT', // 出金拒绝
  407: 'PROFIT_WITHDRAW', // 盈利提出
  408: 'WITHDRAW_HANDLE_FEE', // 出金手续费
  409: 'THIRD_PARTY_WITHDRAW_REJECT', // 代付出金拒绝
  410: 'HANDLE_FEE_REFUND', // 手续费退费
  501: 'ACTING_DEPOSIT_REBATE', // 入金返点
  502: 'ACTING_PROFIT_REBATE', // 盈利返点
  111: 'VIRTUAL_WALLET_PAY', // 虚拟钱包支付
  311: 'WITHDRAW_DEDUCTION', // 出金扣款
  411: 'REJECT_WITHDDRAW_TO_VIRTUAL_WALLET', // 拒絕提現至虚拟钱包
  421: 'VIRTUAL_WALLET_SETTLE_REFUND' // 虚拟钱包结清退款
}
export const CountryIcons = {
  BNB: 'BNB',
  BTC: 'bicoin',
  vETH: 'yitaibi',
  DOGE: 'DOGE',
  YFI: 'YFI',
  FIL: 'FIL',
  KSM: 'KSM',
  pDOTn: 'DOT',
  XRP: 'XRP',
  USDT: 'USDT',
  ADA: 'ADA',
  SHIB: 'SHIB',
  RUS: 'russia',
  AUS: 'australia',
  CAN: 'canada',
  CHN: 'zhongguo',
  LKA: 'sililanka',
  ISL: 'iceland',
  NOR: 'nuowei',
  THA: 'taiguo',
  KOR: 'hanguo',
  USA: 'usa',
  FRA: 'faguo',
  BEL: 'bilishi',
  MYS: 'malaixiya',
  SGP: 'singapore',
  TLV: 'telaweifu',
  THR: 'deheilan',
  KAZ: 'hasakesitan',
  TJK: 'tajikesitan',
  IST: 'yisitanbao',
  GBR: 'british',
  DEU: 'german'
}

export const playType = {
  0: '双面盘',
  1: '定位胆'
}

export const StockIcons = {
  1801: 'bicoin',
  1803: 'yitaibi',
  1804: 'yitaibi',
  1842: 'iceland',
  1843: 'nuowei',
  1871: 'faguo',
  1872: 'bilishi',
  1836: 'yili',
  1845: 'hanguo',
  1841: 'sililanka',
  1844: 'taiguo',
  1838: 'luzhou',
  1863: 'xiaoxiong',
  1862: 'beidou',
  1851: 'usa',
  1852: 'usa',
  1840: 'yanjin',
  1839: 'sanzhi',
  1837: 'liangpin',
  1833: 'guizhou',
  1822: 'shangzhen',
  1823: 'shangzhen',
  1824: 'shangzhen',
  1825: 'shangzhen',
  1826: 'shangzhen',
  1827: 'shangzhen',
  1828: 'shangzhen',
  1821: 'shangzhen',
  1881: 'microsoft',
  1961: 'jiuyang'
}

// access country icon
export function getCountryicons (val) {
  return `/static/images/stockIcon/${CountryIcons[val] || 'zhongguo'}.svg`
}

// access the Stock icon
export function getSicons (val) {
  return `/static/images/stockIcon/${StockIcons[val] || 'zhongguo'}.svg`
}

export function setTimeStr (arr) {
  if (arr && arr.length) {
    const list = []
    arr.forEach(v => {
      v.startTime && (v.startTime = v.startTime.split(' ')[1])
      v.endTime && (v.endTime = v.endTime.split(' ')[1])
      list.push(v)
    })
    return list
  }
  return []
}

export function checkCatchTime (pageUpdateTimeMobile) {
  // console.log(pageUpdateTimeMobile)
  const pageUpdateTime = localStorage.getItem('pageUpdateTime')
  if (pageUpdateTime !== pageUpdateTimeMobile) {
    localStorage.setItem('pageUpdateTime', pageUpdateTimeMobile)
    localStorage.setItem('updated', true)
  }
}

export function calcPriceHandler (price) {
  // console.log(pageUpdateTimeMobile)
  const parseNumber = +price
  return parseNumber ? parseNumber.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : '0.00' // default 0.00
}

export function calcPriceZeroHandler (price) {
  // console.log(pageUpdateTimeMobile)
  const parseNumber = +price
  return parseNumber ? parseNumber.toFixed(0).replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') : '0'
}

/**
 * AXIOS_STATE_OVER_TIME_ERROR 萬一res.data 也沒有防呆用
 */

export function apiResMsg (res, url) {
  // const msg = res.data.msg
  // eslint-disable-next-line prefer-const
  let msg = res.data && ((res.data.respMsg && i18n.tc(res.data.respMsg)) || res.data.msg)// i18用
  const CheckMsgJson = res.data?.msgJson// 填充用
  if (CheckMsgJson) {
    Object.keys(CheckMsgJson).forEach((key) => {
      msg = msg.replace(`%${key}`, CheckMsgJson[key])
    })
  }

  return msg || `Something wrong with ${url}`
}

export function apiResMsg2 (res) {
  // const msg = res.msg
  // eslint-disable-next-line prefer-const
  let msg = (res?.respMsg && i18n.tc(res.respMsg)) || res.msg// i18用
  const CheckMsgJson = res?.msgJson// 填充用
  if (CheckMsgJson) {
    Object.keys(CheckMsgJson).forEach((key) => {
      msg = msg.replace(`%${key}`, CheckMsgJson[key])
    })
  }

  return msg || 'No response Message'
}

export function stockResMsg (stock, res) {
  // eslint-disable-next-line prefer-const
  // console.log('stock:', stock, 'res:', res)
  const checkKeyState = i18n.te(stock)
  const checkStock = i18n.tc(stock)
  // console.log('state:', checkKeyState, 'stock:', checkStock)
  // console.log(checkStock.length)
  return checkKeyState ? checkStock : res
}

export const msgRequired = () => {
  const components = document.querySelectorAll('.rechargepage__wrap .rechargepage__components')
  components.forEach(el => {
    if (el.style.display !== 'none') {
      const input = el.querySelectorAll('.rechargeinput__realinputwrap > .rechargeinput__input')
      if (input.length > 0) input[0].focus()
    }
  })
}
