<template>
  <div
    class="tooltip"
    :style="`left:${left}; bottom:${bottom};`"
  >
    <div class="tooltip__triangle" />
    {{ text }}
  </div>
</template>
<script>
export default {
  name: 'BaseTooltip',
  props: {
    text: {
      type: String,
      default: ''
    },
    left: {
      type: String,
      default: 'auto'
    },
    bottom: {
      type: String,
      default: 'auto'
    }
  }
}
</script>
<style lang="scss" scoped>
.tooltip {
  position: absolute;
  display: inline-block;
  padding: 6px 12px;
  font-size: 12px;
  color: var(--baseTooltipColor);
  background-color: var(--baseTooltipBgc);
  border: 1px solid var(--baseTooltipBorderColor);
  opacity: 0;
  box-shadow: var(--baseTooltipShadow);
  line-height: 17px;
  transition: 0.3s;

  &__triangle {
    position: absolute;
    top: -9px;
    left: 50%;
    border-style: solid;
    border-width: 0 5px 8.7px;
    border-color: transparent transparent var(--baseTooltipBgc);
    transform: translateX(-50%);

    &::before ,
    &::after {
      position: absolute;
      width: 10px;
      height: 1px;
      background-color: var(--baseTooltipBorderColor);
      content: '';
    }

    &::before {
      transform: rotate(57deg) translate(3px, 4px);
    }

    &::after {
      transform: rotate(-57deg) translate(-7px, -4px);
    }
  }
}
</style>
