import { rest } from './index'
const Api = {
  bannerIndex: '/v1/management/content/imageBannerHomeMobileFront',
  activityList: '/v1/activity/getActivityList',
  activityDetail: '/v1/activity/getActivityContent',
  announcement: '/v1/users/announcementList',
  homeannouncement: '/v1/users/announcement',
  appInit: '/v1/users/appInit',
  announcementContent: '/v1/users/announcementContent',
  setAnnouncementRead: '/v1/users/setAnnouncementRead',
  // 活动中心-每日加奖 比例表
  getRewardRate: '/v1/activity/getRewardRate',
  // 活动中心-获取每日加奖状态
  getEveryDayBonus: '/v1/activity/getEveryDayBonus',
  // 活动中心-晋级奖励 等级表
  getUpgradeConfig: '/v1/activity/getUpgradeConfig',
  // 活动中心-获取晋级奖励状态
  getUserUpGradeBonus: '/v1/activity/getUserUpGradeBonus',
  // 活动中心-领取活动奖励和加奖
  receiveActivity: '/v1/activity/receiveActivity',
  // 获取关于我们列表
  getAboutList: '/v1/management/content/getIntroductionList',
  // 关于我们详情页
  getAboutInfo: '/v1/management/content/findByIdIntroduction',
  // 获取帮助指南列表
  getHelpList: '/v1/management/content/getAssistanceList',
  // 获取帮助指南详情
  getHelpInfo: '/v1/management/content/findByIdAssistance',
  // 获取前台缓存的最新时间
  getFrontCacheUpdatedAt: '/v1/management/tenant/getFrontCacheUpdatedAt',
  // 新年红包
  getActivityRedEnvelopeNumber: '/v1/activity/getActivityRedEnvelopeNumber',
  getActivityRedEnvelopeFrontList:
    '/v1/activity/getActivityRedEnvelopeFrontList',
  receiveRedEnvelope: '/v1/activity/receiveRedEnvelope',
  // 獲取前台開放彩種模式（1綜合版，2股票版）
  getTenantConfig: '/v1/management/tenant/getTenantConfig',
  getHomeNews: '/v1/news/getHomeNews',
  getNewsContent: '/v1/news/getNewsContent',
  // 登入頁介紹列表-前台
  loginIntroductionListFront: '/v1/users/loginIntroductionListFront'
}
export default {
  /**
   * 首页banner
   * */
  getBanner () {
    return rest.get(Api.bannerIndex)
  },
  appInit () {
    // 删除站点编码session
    return rest.get(Api.appInit)
  },
  /**
   * 获取网站公告
   * */
  announcement (params) {
    return rest.get(Api.announcement, params)
  },
  homeannouncement (params) {
    return rest.get(Api.homeannouncement, params)
  },
  // 获取公告内容
  announcementContent (params) {
    return rest.get(Api.announcementContent, params)
  },
  // 标记公告已读
  setAnnouncementRead (params) {
    return rest.post(Api.setAnnouncementRead, params)
  },
  /**
   * 活动
   * */
  activityList () {
    return rest.get(Api.activityList)
  },
  activityDetail (params) {
    return rest.get(Api.activityDetail, params)
  },
  getRewardRate () {
    // 每日加奖
    return rest.get(Api.getRewardRate)
  },
  getEveryDayBonus () {
    // 每日加奖状态
    return rest.get(Api.getEveryDayBonus)
  },
  getUpgradeConfig () {
    // 晋级奖励
    return rest.get(Api.getUpgradeConfig)
  },
  getUserUpGradeBonus () {
    // 晋级奖励状态
    return rest.get(Api.getUserUpGradeBonus)
  },
  receiveActivity (params) {
    // 领取奖励
    return rest.post(Api.receiveActivity, params)
  },
  /**
   * 获取帮助指南列表
   */
  getHelpList () {
    return rest.get(Api.getHelpList)
  },
  /**
   * 获取帮助指南详情
   */
  getHelpInfo (param) {
    return rest.get(Api.getHelpInfo, param)
  },
  /**
   * 获取关于我们列表
   */
  getAboutList () {
    return rest.get(Api.getAboutList)
  },
  getAboutInfo (param) {
    return rest.get(Api.getAboutInfo, param)
  },
  getFrontCacheUpdatedAt () {
    return rest.get(Api.getFrontCacheUpdatedAt)
  },
  /**
   * 活动红包
   */
  getActivityRedEnvelopeNumber () {
    return rest.get(Api.getActivityRedEnvelopeNumber)
  },
  getActivityRedEnvelopeFrontList (param) {
    return rest.get(Api.getActivityRedEnvelopeFrontList, param)
  },
  receiveRedEnvelope (params) {
    return rest.post(Api.receiveRedEnvelope, params)
  },
  getTenantConfig () {
    return rest.get(Api.getTenantConfig)
  },
  getHomeNews (params) {
    return rest.get(Api.getHomeNews, params)
  },
  getNewsContent (params) {
    return rest.get(Api.getNewsContent, params)
  },
  // 登入頁介紹列表-前台
  loginIntroductionListFront (params) {
    return rest.get(Api.loginIntroductionListFront, params)
  }
}
