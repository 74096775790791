<template>
  <div class="chart">
    <div
      v-if="isDrawing"
      class="chart-loading"
    >
      <GlobalLoading />
    </div>
    <v-chart
      v-if="chartData && !loading"
      :loading="loading"
      class="chart-box"
      :option="option"
      autoresize
    />
  </div>
</template>

<script>
import lotteryAPI from '@/api/lottery'
import { use } from 'echarts/core'
import { CanvasRenderer } from 'echarts/renderers'
import { PieChart } from 'echarts/charts'
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent
} from 'echarts/components'
import VChart, { THEME_KEY } from 'vue-echarts'
import moment from 'moment'
import theme from '@/assets/js/theme'
import GlobalLoading from '@/components/loader/GlobalLoading.vue'

use([
  CanvasRenderer,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent
])

const upColor = '#E1533B' // 漲色
const downColor = '#2BA66F' // 跌色

const volumUpColor = '#1D614F' // 漲色 for volumn
const volumDownColor = '#793735'
// 將資料分類
function splitData (rawData) {
  // [time, 收盤, 開盤, 最低, 最高, volumn]
  const categoryData = []
  const values = []
  const volumes = []
  for (let i = 0; i < rawData.length; i++) {
    categoryData.push(moment(rawData[i].splice(0, 1)[0]).format('MM/DD'))
    values.push(rawData[i])
    volumes.push([i, rawData[i][4], rawData[i][0] > rawData[i][1] ? 1 : -1])
  }

  return {
    categoryData: categoryData,
    values: values,
    volumes: volumes
  }
}

// 技術分析計算
function calculateMA (dayCount, data) {
  const result = []
  for (let i = 0, len = data.values.length; i < len; i++) {
    if (i < dayCount) {
      result.push('-')
      continue
    }
    let sum = 0
    for (let j = 0; j < dayCount; j++) {
      sum += data.values[i - j][1]
    }
    result.push(+(sum / dayCount).toFixed(3))
  }
  return result
}

export default {
  components: {
    GlobalLoading,
    VChart
  },
  mixins: [theme],
  provide: {
    [THEME_KEY]: 'dark' // 主題
  },
  props: {
    code: {
      type: [String, Number],
      required: true
    },
    name: {
      type: String,
      default: () => ''
      // required: true
    }
  },
  data () {
    return {
      currentType: 1,
      isDrawing: false,
      loading: false,
      hasChartKData: false
    }
  },
  computed: {
    // 最後收盤
    lastOneClose () {
      return this.$store.state.trendData[this.code] && this.$store.state.trendData[this.code].lastOneClose
    },
    // 最後開盤
    lastOneOpen () {
      return this.$store.state.trendData[this.code] && this.$store.state.trendData[this.code].lastOneOpen
    },
    graphType () {
      return this.$store.state.graphType
    },
    chartData () {
      return this.$store.state.trendData[this.code] && this.$store.state.trendData[this.code].list
    },
    option () {
      const lengdData = {
        kType: [this.name, 'Volume'],
        line: ['MA5', 'MA10', 'MA20', 'MA30', 'Volume'],
        mixed: [this.name, 'MA5', 'MA10', 'MA20', 'MA30', 'Volume']
      }
      const seriesData = {
        kType: [
          {
            name: this.name,
            type: 'candlestick',
            barMaxWidth: 20,
            data: this.chartData.values,
            itemStyle: {
              color: downColor,
              color0: upColor,
              borderColor: null,
              borderColor0: null
            },
            markLine: {
              symbol: ['none', 'none'],
              label: {
                backgroundColor: (this.lastOneClose - this.lastOneOpen) >= 0 ? upColor : downColor,
                color: '#FFF',
                height: 18,
                padding: [0, 3]
              },
              lineStyle: {
                color: (this.lastOneClose - this.lastOneOpen) >= 0 ? upColor : downColor
              },
              data: [
                {
                  name: 'min line on close',
                  yAxis: this.lastOneClose
                }
              ]
            },
            tooltip: {
              formatter: function (param) {
                param = param[0]
                return [
                  'Date: ' + param.name + '<hr size=1 style="margin: 3px 0">',
                  'Open: ' + param.data[0] + '<br/>',
                  'Close: ' + param.data[1] + '<br/>',
                  'Lowest: ' + param.data[2] + '<br/>',
                  'Highest: ' + param.data[3] + '<br/>'
                ].join('')
              }
            }
          },
          {
            name: 'Volume',
            barMaxWidth: 20,
            type: 'bar',
            xAxisIndex: 1,
            yAxisIndex: 1,
            data: this.chartData.volumes.map(i => ({ value: i[1], itemStyle: { color: i[2] === 1 ? volumDownColor : volumUpColor } }))
          }
        ],
        line: [
          {
            name: 'MA5',
            type: 'line',
            data: calculateMA(5, this.chartData),
            smooth: true,
            showSymbol: false,
            markLine: {
              symbol: ['none', 'none'],
              label: {
                backgroundColor: '#375E86',
                color: '#FFF',
                height: 18,
                padding: [0, 3]
              },
              lineStyle: {
                color: '#253C5A'
              },
              data: [
                {
                  name: 'min line on close',
                  yAxis: this.lastOneClose
                }
              ]
            },
            itemStyle: {
              color: '#407068'
            },
            lineStyle: {
              width: 1,
              color: '#407068'
            }
          },
          {
            name: 'MA10',
            type: 'line',
            data: calculateMA(10, this.chartData),
            smooth: true,
            showSymbol: false,
            itemStyle: {
              color: '#2C4D6A'
            },
            lineStyle: {
              width: 1,
              color: '#2C4D6A'
            }
          },
          {
            name: 'MA20',
            type: 'line',
            data: calculateMA(20, this.chartData),
            smooth: true,
            showSymbol: false,
            itemStyle: {
              color: '#7E674A'
            },
            lineStyle: {
              width: 1,
              color: '#7E674A'
            }
          },
          {
            name: 'MA30',
            type: 'line',
            data: calculateMA(30, this.chartData),
            smooth: true,
            showSymbol: false,
            itemStyle: {
              color: '#7E514A'
            },
            lineStyle: {
              width: 1,
              color: '#7E514A'
            }
          },
          {
            name: 'Volume',
            barMaxWidth: 20,
            type: 'bar',
            xAxisIndex: 1,
            yAxisIndex: 1,
            data: this.chartData.volumes.map(i => ({ value: i[1], itemStyle: { color: '#233E5C' } }))
          }
        ],
        mixed: [
          {
            name: this.name,
            type: 'candlestick',
            barMaxWidth: 20,
            data: this.chartData.values,
            itemStyle: {
              color: downColor,
              color0: upColor,
              borderColor: null,
              borderColor0: null
            },
            tooltip: {
              formatter: function (param) {
                param = param[0]
                return [
                  'Date: ' + param.name + '<hr size=1 style="margin: 3px 0">',
                  'Open: ' + param.data[0] + '<br/>',
                  'Close: ' + param.data[1] + '<br/>',
                  'Lowest: ' + param.data[2] + '<br/>',
                  'Highest: ' + param.data[3] + '<br/>'
                ].join('')
              }
            }
          },
          {
            name: 'MA5',
            type: 'line',
            data: calculateMA(5, this.chartData),
            smooth: true,
            showSymbol: false,
            itemStyle: {
              color: '#407068'
            },
            lineStyle: {
              width: 1,
              color: '#407068'
            }
          },
          {
            name: 'MA10',
            type: 'line',
            data: calculateMA(10, this.chartData),
            smooth: true,
            showSymbol: false,
            itemStyle: {
              color: '#2C4D6A'
            },
            lineStyle: {
              width: 1,
              color: '#2C4D6A'
            }
          },
          {
            name: 'MA20',
            type: 'line',
            data: calculateMA(20, this.chartData),
            smooth: true,
            showSymbol: false,
            itemStyle: {
              color: '#7E674A'
            },
            lineStyle: {
              width: 1,
              color: '#7E674A'
            }
          },
          {
            name: 'MA30',
            type: 'line',
            data: calculateMA(30, this.chartData),
            smooth: true,
            showSymbol: false,
            itemStyle: {
              color: '#7E514A'
            },
            lineStyle: {
              width: 1,
              color: '#7E514A'
            }
          },
          {
            name: 'Volume',
            barMaxWidth: 20,
            type: 'bar',
            xAxisIndex: 1,
            yAxisIndex: 1,
            data: this.chartData.volumes.map(i => ({ value: i[1], itemStyle: { color: i[2] === 1 ? volumDownColor : volumUpColor } }))
          }
        ]
      }
      return {
        backgroundColor: this.baseTheme.graphBgc,
        animation: true, // 動畫有無
        legend: {
          show: false,
          top: 15,
          left: 25,
          // left: 'center',
          data: lengdData[this.graphType],
          itemStyle: {
            color: 'transparent'
          }
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross'
          },
          borderWidth: 1,
          borderColor: '#1E2E48',
          backgroundColor: '#1E2E48',
          padding: 10,
          textStyle: {
            color: '#FAFAFA'
          },
          position: function (pos, params, el, elRect, size) {
            const obj = { top: 10 }
            obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 30
            return obj
          }
        // extraCssText: 'width: 170px'
        },
        axisPointer: [
          {
            link: { xAxisIndex: 'all' },
            label: {
              backgroundColor: '#0589d7',
              color: '#FFF',
              fontWeight: 'bold'
            }
          }
        ],
        toolbox: {
          show: false
          // right: 20,
          // feature: {
          //   dataZoom: {
          //     yAxisIndex: false,
          //     title: {
          //       zoom: this.$t('PC_ZOOM'),
          //       back: this.$t('PC_BACK_ZOOM')
          //     }
          //   },
          //   brush: {
          //     type: ['lineX', 'clear'],
          //     title: {
          //       lineX: this.$t('PC_OPEN_HORIZONTAL'),
          //       clear: this.$t('PC_CLEAR_CHECKBOX')
          //     }
          //   },
          //   saveAsImage: {
          //     type: 'png',
          //     name: 'stock',
          //     title: this.$t('PC_SAVE_IMG')
          //   }
          // }
        },
        brush: {
          xAxisIndex: 'all',
          brushLink: 'all',
          outOfBrush: {
            colorAlpha: 0.1
          }
        },
        visualMap: {
          show: false,
          seriesIndex: 5,
          dimension: 2,
          pieces: [{
            value: 1,
            color: volumDownColor
          }, {
            value: -1,
            color: volumUpColor
          }]
        },
        grid: [
          {
            left: '4%',
            right: '8%',
            bottom: '28%',
            height: '68%'
          },
          {
            left: '4%',
            right: '8%',
            bottom: '8%',
            height: '20%'
          }
        ],
        xAxis: [
          {
            type: 'category',
            data: this.chartData.categoryData,
            scale: true,
            boundaryGap: false,
            axisLine: { onZero: false, lineStyle: { color: '#879AAF' } },
            splitLine: { show: true, lineStyle: { color: '#253C5A' } },
            axisTick: { show: false, lineStyle: { color: '#253C5A' } },
            axisLabel: { show: false },
            min: 'dataMin',
            max: 'dataMax',
            axisPointer: {
              z: 100
            }
          },
          {
            type: 'category',
            gridIndex: 1,
            data: this.chartData.categoryData,
            scale: true,
            boundaryGap: false,
            axisLine: { onZero: false, lineStyle: { color: '#879AAF' } },
            axisTick: { show: true, lineStyle: { color: '#253C5A' } },
            splitLine: { show: true, lineStyle: { color: '#253C5A' } },
            axisLabel: { show: true, lineStyle: { color: '#879AAF' } },
            interval: 200,
            min: 'dataMin',
            max: 'dataMax'
          }
        ],
        yAxis: [
          {
            scale: true,
            splitArea: {
              show: true,
              areaStyle: {
                color: this.baseTheme.graphBgc
              }
            },
            axisLine: { onZero: false, lineStyle: { color: '#879AAF' } },
            splitLine: { show: true, lineStyle: { color: '#253C5A' } },
            axisLabel: {
              show: true,
              formatter: (value, index) => {
                if (index === 0) return ''
                return value
              }
            },
            offset: 5,
            lineStyle: {
              show: true,
              color: 'red'
            },
            position: 'right'
          },
          {
            scale: true,
            gridIndex: 1,
            splitNumber: 4,
            offset: 5,
            axisLabel: {
              show: true,
              showMaxLabel: false,
              formatter: (value, index) => {
                if (index === 0) return ''
                if (value > 1000000000) return (value / 1000000000).toFixed(1) + 'B'
                if (value > 1000000) return (value / 1000000).toFixed(1) + 'M'
                if (value > 1000) return (value / 1000).toFixed(1) + 'K'
                return value
              }
            },
            axisLine: { onZero: false, lineStyle: { color: '#879AAF' } },
            splitLine: { show: true, lineStyle: { color: '#253C5A' } },
            position: 'right'
          }
        ],
        dataZoom: [
          {
            type: 'inside',
            xAxisIndex: [0, 1],
            start: 0,
            end: 100
          }
          // {
          //   show: true,
          //   xAxisIndex: [0, 1],
          //   type: 'slider',
          //   top: '85%',
          //   start: 0,
          //   end: 100
          // }
        ],
        series: seriesData[this.graphType],
        media: [{
          query: {
            maxHeight: 610
          }
        }]
      }
    }
  },
  watch: {
    graphType () {
      this.loading = true
      this.isDrawing = true
      setTimeout(() => {
        this.loading = false
      }, 100)
      setTimeout(() => {
        this.isDrawing = false
      }, 1000)
    },
    code (nv, ov) {
      if (ov && nv !== ov) {
        this.loading = true
      }
      if (!this.chartData) {
        this.setKType(nv)
      } else {
        this.loading = false
        this.isDrawing = true
        setTimeout(() => {
          this.isDrawing = false
        }, 1000)
      }
    }
  },
  created () {
    this.setKType()
  },
  methods: {
    setKType (val = this.code) {
      if (this.chartData) return
      // this.hasSetKtype = false
      // if (val === this.currentType) {
      //   return
      // }
      // this.currentType = val
      this.loading = true
      this.isDrawing = true
      const params = {
        lotteryCode: val,
        dataCount: 120,
        type: 1 // 1:日線 2:週線 3：月線
      }

      lotteryAPI.stockTrendChart(params).then((e) => {
        const data = e.data.chartDataList || []
        this.hasChartKData = true

        const ohlc = []
        // var volume = []
        const dataLength = data.length

        let i = 0
        for (i; i < dataLength; i += 1) {
          ohlc.push([
            data[i].targetTime, // the date
            data[i].closePrice, // close
            data[i].openPrice, // open
            data[i].minPrice, // low
            data[i].maxPrice, // high
            data[i].volume
          ])
          // volume.push([
          //   data[i][0], // the date
          //   data[i][5] // the volume
          // ])
        }
        const chartData = splitData(ohlc)
        this.$store.dispatch('actionSetTrendData', { code: val, list: chartData, lastOneClose: ohlc[dataLength - 1][0], lastOneOpen: ohlc[dataLength - 1][1] })
        this.loading = false
        setTimeout(() => {
          this.isDrawing = false
        }, 1000)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.chart {
  // min-width: 850px;
  height: 100%;

  .chart-box {
    width: inherit;
    height: inherit;
  }

  .chart-loading {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
}
</style>
