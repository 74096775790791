<template>
  <div class="item__group">
    <span class="item__itemstock double__width">
      <!-- {{ itemData.lotteryName }} -->
      {{ stockResMsg(`STOCK_${itemData.lotteryCode}`,itemData.lotteryName) }}
    </span>
    <span class="item__itemorder double__width">{{ itemData.issue }}</span>
    <span class="item__itemtime double__width">{{ formatTime }}</span>
    <span class="item__itemmoney base__width">{{ itemData.betMoney }}</span>
    <span
      class="item__itemtbones base__width"
      :class="[getStateName[2]]"
    >{{ getStateName[0] }}</span>
    <span
      class="item__itemstate base__width"
      :class="[getStateName[2],$i18n.locale]"
    >{{ getStateName[1] }}
    </span>
  </div>
</template>

<script>
import { stockResMsg } from '@/utils'
import moment from 'moment'
export default {
  props: {
    itemData: {
      type: Object,
      required: true
    }
  },
  computed: {
    formatTime () {
      return moment(this.itemData.gmtCreated).format('YYYY-MM-DD hh:mm:ss')
    },
    getStateName () {
      // 0等待开奖 1未盈利 2已盈利 3已撤单 4出仓即停 5系统撤单
      switch (this.itemData.state) {
        case 0:
          return ['-', this.$t('WAREHOUSE_CHASE_NUMBER_ITEM_WAIT'), 'pending']
        case 1:
          return [`-${Number(this.itemData.betMoney).toFixed(2)}`, this.$t('WAREHOUSE_RECORDCENTER_CHASENUMBER_UNPROFITABLE'), 'def']
        case 2:
          return [`+${Number(this.itemData.winMoney).toFixed(2)}`, this.$t('INVEST_PROFITABLE'), 'rise']
        case 3:
          return ['-', this.$t('INVEST_ORDER_CANCELLED'), 'def']
        case 4:
          return [`+${Number(this.itemData.winMoney).toFixed(2)}`, this.$t('PC_STOP_WAREHOUSE'), 'def']
        case 5:
          return ['-', this.$t('SYSTEM_CANCEL_ORDER'), 'def']
        default:
          return ['-', this.$t('IN_PROCESS'), 'ing']
      }
    },
    showTypeClass () {
      // 0等待开奖 1未出仓 2已出仓 3已撤单 4出仓即停 5系统撤单
      switch (this.itemData.state) {
        case 3:
        case 5:
          return 'def'
        case '等待盈利':
        case 0:
          return 'pending'
        case '等待开奖':
          return 'pending'
        case '未盈利':
        case 1:
          return 'fall'
        case '已盈利':
        case 2:
        case 4:
          return 'rise'
        case '已中奖':
          return 'rise'
        default:
          return 'def'
      }
    }
  },
  methods: {
    stockResMsg
  }
}
</script>

<style lang="scss" scoped>
.item__group {
  display: flex;
  align-items: center;
  overflow: hidden;
  padding-right: 8px;
  padding-left: 16px;

  >.double__width {
    width: 20%;
  }

  >.base__width {
    width: 13.33%;
  }

  .item__itemtbones {
    &.rise {
      color: var(--accountingItemBonesRiseColor);
    }
  }

  .item__itemstate {
    &.ing {
      color: var(--accountingItemIngColor);
    }

    &.pending {
      color: var(--accountingItemPendingColor);
    }

    &.rise {
      color: var(--accountingItemRiseColor);
    }
  }
}

.item__itemstock,
.item__itemmoney {
  color: var(--accountingItemItemStockColor);
}

.item__itemmoney {
  position: relative;
  right: -3%;
}

.item__itemtbones {
  position: relative;
  right: -5%;
}

.item__itemstate {
  position: relative;
  right: -6.9%;

  &.vie {
    right: -5.8%;
  }
}
</style>
