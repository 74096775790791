// import { mul, betSum, STOCK } from './lotteryFun'
import { betSum, STOCK } from './lotteryFun'

const stockConfig = {
  // '冠亚军': {
  //   '普通玩法': [{
  //     'name': '冠亚和',
  //     'mode': 'B11',
  //     'tip': '冠亚和是出仓指数小数前两位和；0-8为跌，10-18为涨；为9时通杀。', // ；涨/跌/单股/双股 赔率计算至冠、亚、季军，其他为冠、亚军
  //     'group': '冠亚军',
  //     'subGroup': '1元模式',
  //     'tag': '冠亚和',
  //     'eg': ['选择03', '03 *'],
  //     'default': true
  //   }]
  // },
  // '二同号复选': {
  //   '普通玩法': [{
  //     'group': '二同号复选',
  //     'subGroup': '普通玩法',
  //     'tag': '二同号复选',
  //     'name': '二同号复选',
  //     'mode': 'C11',
  //     'tip': '从00-99中任选1个或多个号码，选号与奖号(包含11-66，不限顺序)相同，即为中奖。',
  //     'eg': [1, 1, 3],
  //     'default': true
  //   }]
  // },
  定位胆: {
    普通玩法: [{
      name: '定位胆',
      mode: 'A11',
      tip: 'PC_RULE_LOCATION',
      group: '定位胆',
      subGroup: '标准',
      selectType: 1, // 決定冠亞輸入選項
      tag: '定位胆',
      eg: ['冠选择01', '01 *'],
      default: true
    }]
  },
  双面盘: {
    普通玩法: [{
      name: '双面盘',
      mode: 'D11',
      tip: 'PC_RULE_DOUBLE',
      group: '双面盘',
      subGroup: '1元模式',
      selectType: 0,
      tag: '双面盘',
      eg: ['冠选择大', '大 *'],
      default: true
    }]
  }
}

const stockPlay = {
  A11: { render: ['10', '1'], alg: (order, tmp) => betSum(order, tmp).reduce(function (a, b) { return a + b }, 0) },
  D11: { render: ['stockTwoFaceFirst', 'stockTwoFaceSecond'], alg: (order, tmp) => betSum(order, tmp).reduce(function (a, b) { return a + b }, 0) }
  // C11: { render: ['10000', '1000'], alg: (order, tmp) => mul(2, betSum(order, tmp)) }
}

function stockOneStar () {
  const line = Math.floor(Math.random() * 2)
  const res = []
  for (let i = 0; i < 2; i++) {
    const lineRes = []
    if (line === i) {
      const feed = Math.floor(Math.random() * 10)
      lineRes.push(STOCK[feed])
    }
    res.push(lineRes)
  }
  return res
}

// 单式注单随机生成函数
const stockRandomNote = {}

// 复式注单随机生成函数
const stockRandom = {
  A11: () => stockOneStar() // 定位胆
  // A11: () => xRandom([1], false, STOCK)// 前一
}

// 特殊的，不能够注数为1的一些玩法
const stockSpecialMode = {}

export { stockConfig, stockPlay, stockRandomNote, stockRandom, stockSpecialMode }
