<template>
  <div
    class="wallet__dropdownwrap"
  >
    <van-collapse
      :value="activeUserAccount"
      accordion
      class="wallet__dropdown"
    >
      <van-collapse-item
        class="wallet__vancollapseitem"
        name="0"
        title=" "
      >
        <div class="qrcode__showwrap">
          <div class="qrcode__title">
            {{ $t('LAYOUT_APP_DOWNLOAD') }}
          </div>
          <div class="qrcode__linkurl">
            <slot name="qrcode" />
          </div>
          <div class="qrcode__info">
            {{ $t('LAYOUT_BROWSER_SUGGEST') }}
          </div>
        </div>
      </van-collapse-item>
    </van-collapse>
  </div>
</template>

<script>

export default {
  name: 'DropdownQrcode',
  props: {
    activeUserAccount: {
      type: String,
      default: '',
      required: true
    }
  },
  data () {
    return {
    }
  },
  computed: {
  },
  mounted () {
  }
}

</script>

<style lang="scss" scoped>
.wallet {
  &__dropdownwrap {
    width: 100%;
    height: 100%;
    // border: 1px solid #F00;

    &::v-deep {
      .van-hairline--top-bottom {
        &::after {
          display: none;
        }
      }
    }
  }

  &__dropdown {
    height: 100%;
  }

  &__vancollapseitem {
    position: relative;
    width: 100%;
    height: 100%;
    // display: none;
    // margin-right: 12px;
    // border: 1px solid #F00;

    &::v-deep {
      .van-collapse-item__wrapper {
        position: fixed;
        z-index: 20;
        box-shadow: var(--dropdownBoxShadow);
        transform: translateX(-82.5%) translateY(-1px);
        transition-duration: 0.1s;
      }

      .van-cell {
        display: flex;
        align-items: center;
        padding: 0;
        height: 100%;
        color: var(--dropdownVancellColor);
        background-color: transparent;

        &::after {
          display: none;
        }
      }

      .van-collapse-item__content {
        padding: 0;
        width: 280px;
        background-color: var(--dropdownContentBgc);
        border: var(--dropdownBorder);
        border-radius: 4px;
      }

      .van-icon {
        position: relative;
        display: none;
        width: 12px;
        height: 12px;

        // &::before {
        //   display: none;
        // }

        // &::after {
        //   position: absolute;
        //   top: 0;
        //   left: 0;
        //   width: 100%;
        //   height: 100%;
        //   content: '';
        //   background-image: url('/public/static/images/Layout/triangle.svg');
        // }
      }

      // .van-collapse-item__title--expanded {
      //   color: #0589D7;

      //   .van-icon {
      //     &::after {
      //       position: absolute;
      //       top: 0;
      //       left: 0;
      //       width: 100%;
      //       height: 100%;
      //       content: '';
      //       background-image: url('/public/static/images/Layout/triangle-active.svg');
      //     }
      //   }
      // }
    }
  }
}

.show__wallet {
  display: flex;
  align-items: center;
  padding-right: 16px;
  padding-left: 16px;
  width: 100%;
  height: 60px;
  border-bottom: var(--dropdownTitleBorder);

  .type-set {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
}

.wallet {
  &__title {
    font-size: 12px;
    line-height: 17px;
    color: var(--dropdownInfoColor);
  }

  &__info {
    font-size: 16px;
    line-height: 22px;
    color: var(--dropdownItemColor);
  }
}

.link__item {
  .item {
    padding-left: 16px;
    height: 36px;
    font-size: 14px;
    color: var(--dropdownInfoColor);
    line-height: 20px;
    line-height: 36px;

    &:hover {
      background-color: var(--dropdownHoverItemColor);
    }
  }
}

.qrcode {
  &__showwrap {
    padding: 20px 18px;
    color: var(--dropdownInfoColor);
  }

  &__title {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 12px;
  }

  &__linkurl {
    height: 244px;
    background-color: var(--dropdownMainTextColor);
    border: var(--dropdownMainQrcodeBorder);
    margin-bottom: 12px;

    &::v-deep {
      img {
        width: 242px;
        height: 244px;
      }
    }
  }

  &__info {
    font-size: 12px;
    line-height: 17px;
  }
}

</style>
