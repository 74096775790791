<template>
  <div class="tab__contentbtns">
    <div class="chase__typebtns">
      <span
        v-for="(btns,index) of selectListData"
        ref="tab"
        :key="btns.text"
        class="tab__btn"
        :class="[{active:selectIndexValue===index},$i18n.locale]"
        @click="selectTypeHandler(btns.value,index)"
      >
        {{ btns.text }}
      </span>
      <div
        v-if="$i18n.locale!=='en'"
        class="line"
        :style="[{width:lineWidth},{left:moveWidth}]"
      />
      <div
        v-else
        class="line"
        :style="[{width:englishLine},{left:englishMove}]"
      />
    </div>
    <!-- <div
      v-else
      class="chase__typebtns"
    >
      <span
        v-for="(btns,index) of chaseBtnData"
        :key="btns.text"
        class="tab__btn"
        :class="{active:btns.active}"
        @click="selectTypeHandler(btns.value,index,'chaseBtnData')"
      >
        {{ btns.text }}
      </span>
    </div> -->
    <div
      ref="daydropdown"
      class="day__dropdown unit"
      :class="$i18n.locale"
    >
      <van-collapse
        v-model="daysNames"
        accordion
      >
        <!-- :class="{'is-show':filterRunnerData}" -->
        <van-collapse-item
          :title="unitText"
          name="1"
        >
          <div class="runner__title">
            <div
              v-for="(item, index) in option1"
              :key="`champ-${index}`"
              :class="{'is-active':daysIndex===index}"
              @click="activeUnitBtn(index, item)"
            >
              {{ item.text }}
            </div>
          </div>
        </van-collapse-item>
      </van-collapse>
      <!-- <van-dropdown-menu
        class="tab__days"
        :overlay="false"
      >
        <van-dropdown-item
          v-model="dayValue"
          :options="option1"
          class="day__items"
          @change="selectDayHandler"
        />
      </van-dropdown-menu> -->
    </div>
  </div>
</template>

<script>
import pubsub from 'pubsub-js'
export default {
  props: {
    num: {
      type: Number,
      default: 1
    }
  },
  data () {
    return {
      dayValue: 'today',
      selectType: 1,
      checkUseType: 0, /// / 預設0追號1
      baseSelect: 0,
      ChaseSelect: 0,
      // 日期選單
      daysNames: '',
      // 日期index
      daysIndex: 0
    }
  },
  computed: {
    option1 () {
      return [
        { text: this.$t('PROFIT_TODAY'), value: 'today', icon: '' },
        { text: this.$t('REPORT_YESTERDAY'), value: 'yesterday', icon: '' },
        { text: this.$t('INVEST_SEVEN_DAYS'), value: 'sevenday', icon: '' }
      ]
    },
    basebtnData () {
      return [
        { text: this.$t('INVEST_ALL'), value: 1, icon: '', active: true },
        { text: this.$t('INVEST_PROFITABLE'), value: 2, icon: '', active: false },
        { text: this.$t('INVEST_UNPROFITABLE'), value: 3, icon: '', active: false },
        { text: this.$t('INVEST_WAITING_RELEASED'), value: 4, icon: '', active: false }
      ]
    },
    chaseBtnData () {
      return [
        { text: this.$t('INVEST_ALL'), value: 1, icon: '', active: true },
        { text: this.$t('UNSTART'), value: 2, icon: '', active: false },
        { text: this.$t('PROCESSING'), value: 3, icon: '', active: false },
        { text: this.$t('FINISHED'), value: 4, icon: '', active: false }
      ]
    },
    selectListData () {
      return this.num === 0 ? this.basebtnData : this.chaseBtnData
    },
    selectIndexValue: {
      get () {
        return this.num === 0 ? this.baseSelect : this.ChaseSelect
      }
    },
    lineWidth () {
      return `${100 / this.selectListData.length}%`
    },
    moveWidth () {
      return `${this.selectIndexValue * (100 / this.selectListData.length)}%`
    },
    englishLine () {
      const obj = {
        0: '32px',
        1: '80px',
        2: '80px',
        3: '60px'
      }
      return obj[this.selectIndexValue]
    },
    englishMove () {
      const obj = {
        0: '0px',
        1: '32px',
        2: '112px',
        3: '192px'
      }
      console.log(this.selectIndexValue)
      return obj[this.selectIndexValue]
    },
    unitText () {
      return this.option1[this.daysIndex].text
    }
  },
  created () {
    this.pubId = pubsub.subscribe('confirm-bet', (event, data) => {
      if (data === 'doubletype') {
        this.chaseTypeHandler(0)
      } else {
        this.chaseTypeHandler(1)
      }
    })
  },
  mounted () {
    document.addEventListener('mousedown', this.mousedownHandler)
  },
  beforeDestroy () {
    pubsub.unsubscribe(this.pubId)
    document.removeEventListener('mousedown', this.mousedownHandler)
  },
  methods: {
    mousedownHandler (e) {
      const clickedEl = e.target
      this.$nextTick(() => {
        const unit = this.$refs.daydropdown
        if (unit && !unit.contains(clickedEl)) {
          this.daysNames = ''
        }
      })
    },
    // 選項
    selectTypeHandler (value, index) {
      // // 判斷追號或一般
      this.num === 0 ? this.baseSelect = index : this.ChaseSelect = index
      if (this.num === 0) {
        this.basebtnData.forEach(item => {
          item.active = false
        })
        this.basebtnData[index].active = true
      }
      if (this.num === 1) {
        this.chaseBtnData.forEach(item => {
          item.active = false
        })
        this.chaseBtnData[index].active = true
      }
      // const type = name === 'basebtnData' ? 0 : 1
      // this.checkUseType = type
      // this.selectType = value
      // this[name].forEach(item => {
      //   item.active = false
      // })
      // this[name][index].active = true
      this.$emit('chaseSelectValue', this.num, value, this.dayValue)
    },
    selectDayHandler (value) {
      this.$emit('chaseSelectValue', this.num, this.selectType, this.dayValue)
    },
    chaseTypeHandler (index) {
      this.checkUseType = index// 選擇是否追號

      this.$emit('chaseSelectValue', this.checkUseType, this.selectType, this.dayValue, 'add')
    },
    activeUnitBtn (index, item) {
      this.daysNames = ''// 關閉選單
      this.daysIndex = index
      this.dayValue = item.value
      this.selectDayHandler()
    }
  }
}

</script>

<style lang="scss" scoped>
.tab {
  &__contentbtns {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;

    .line {
      position: absolute;
      bottom: -8px;
      height: 2px;
      background-color: var(--chaseNumberBtnsBtnActiveBgc);
      z-index: 2;
      transition: 0.5s;
      content: '';
    }

    &::after {
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 100%;
      height: 2px;
      background-color: var(--chaseNumberBtnsBtnsBgc);
      content: '';
      transform: translateX(-50%);
    }
  }

  &__btn {
    position: relative;
    // overflow: hidden;
    padding-top: 6px;
    // padding-right: 12px;
    padding-bottom: 6px;
    width: 62px;
    // padding-left: 12px;
    font-size: 12px;
    text-align: center;
    color: var(--chaseNumberBtnsBtnColor);
    // border: 1px solid #F00;
    border-radius: 5px;
    line-height: 20px;
    cursor: pointer;

    &.vie {
      width: 75px;
    }

    &.en {
      width: 80px;

      &:first-child {
        width: 32px;
      }

      &:nth-child(4) {
        width: 60px;
      }
    }

    //tab分類選像按鈕
    // border: 1px solid #000;
    &:hover {
      color: var(--chaseNumberBtnsBtnActiveColor);
    }

    &.active {
      color: var(--chaseNumberBtnsBtnActiveColor);
      transition: 0.5s;
      cursor: default;
      // background-color: #20385A;
      // &::after {
      //   position: absolute;
      //   bottom: -10px;
      //   left: 0;
      //   width: 100%;
      //   height: 2px;
      //   background-color: var(--chaseNumberBtnsBtnActiveBgc);
      //   content: '';
      //   z-index: 2;
      // }
    }

    &__days {
      font-size: 14px;
      color: var(--chaseNumberBtnsDaysColor);
      line-height: 20px;
    }
  }

  &__maintabs {
    margin-right: 20px;
    margin-left: auto;
    // border: 1px solid #F00;
  }
}
//日期夏拉
.day__dropdown {
  // margin-right: 6px;
  font-size: 14px;
  // border: 1px solid #F00;

  &::v-deep {
    .van-dropdown-menu__bar {
      font-size: 14px;
      background-color: transparent;
      box-shadow: none !important;
    }

    .van-dropdown-menu__title {
      font-size: 14px;
      color: var(--chaseNumberDropdownTitleColor);
    }

    .van-dropdown-menu__title--active {
      color: var(--chaseNumberDropdownTitleActiveColor);
    }

    .van-collapse-item__content {
      color: var(--chaseNumberBtnsBtnColor);
    }
  }
}

.day__items {
  &::v-deep {
    .van-dropdown-item {
      right: auto;
      left: auto;
      width: 100px;
      height: auto;
      // border: 1px solid #253C5A;
      border-radius: 4px;
      // height: 150px;
      transform: translateX(-47.5%) translateY(-7px);
    }

    .van-popup {
      background-color: transparent;
    }

    .van-dropdown-item__content {
      max-height: 100%;
      border: var(--chaseNumberDropdownItemContentBorder);
      border-radius: 4px;
    }

    .van-cell__value {
      display: none;
    }

    .van-dropdown-item__option--active {
      color: var(--chaseNumberDropdownItemActiveColor);
      background-color: var(--chaseNumberDropdownItemActiveBgc) !important;

      .van-cell__title {
        color: var(--chaseNumberDropdownVanCellTitleColor);
      }
    }

    .van-dropdown-menu__title::after {
      right: -13px;
    }

    .van-cell {
      padding: 0 16px;
      height: 28px;
      font-size: 14px;
      text-align: left;
      color: var(--chaseNumberDropdownVanCellColor);
      background-color: var(--chaseNumberDropdownVanCellBgc);

      &::after {
        display: none;
      }

      &:hover {
        color: var(--chaseNumberDropdownItemActiveColor);
        background-color: var(--chaseNumberDropdownItemActiveBgc);
      }
    }
  }
}

.chase__typebtns {
  position: relative;
  display: flex;
  margin-right: 36px;
}

.unit {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  // margin-right: 16px;

  // &::before {
  //   position: absolute;
  //   top: 50%;
  //   left: 0;
  //   width: 1px;
  //   height: 20px;
  //   background-color: var(--locationUnitBgc);
  //   content: '';
  //   transform: translateY(-50%);
  // }

  &::v-deep {
    .van-collapse {
    }

    .van-cell {
      padding: 0;
      color: #879AAF;
      background-color: transparent;

      .van-cell__right-icon {
        margin-left: 0;
      }

      .van-cell__title {
        text-align: center;
        color: #FAFAFA;
      }

      &::after {
        display: none;
      }

      .van-icon {
        width: 12px;
        // border: 1px solid #000;

        &::before {
          position: absolute;
          top: 40%;
          width: 12px;
          height: 6px;
          background-position: center center;
          content: '';
          background-image: url('/public/static/images/bet/triangle-w.svg');
          transform: rotate(0deg)  translateZ(0);
        }
      }

      &.van-collapse-item__title--expanded {
        .van-cell__title {
          color: var(--locationItemActiveColor);
        }

        .van-icon {
          &::before {
            transform: rotate(-180deg) translateZ(0);
            background-image: var(--locationItemIconArrowUrlActive);
          }
        }
      }
      // border: 1px solid #CCC;
    }

    .van-hairline--top-bottom {
      &::after {
        display: none;
      }
    }

    .van-collapse-item {
      width: 56px;
      // border: 1px solid #F00;
    }

    .van-collapse-item__wrapper {
      position: absolute;
      width: 80px;
      background-color: var(--locationItemWrapperBgc);
      border: var(--locationItemWrapperBorder);
      border-radius: 4px;
      z-index: 2;
      box-shadow: var(--dropDownShadow);
      transform: translateX(-24px) translateY(5px);
      transition-duration: 0.1s;
    }

    .van-collapse-item__content {
      padding: 0;
      background-color: transparent;
    }

    .runner__title {
      >div {
        padding-left: 12px;
        height: 32px;
        transition: 0.5s;
        line-height: 32px;

        &:hover {
          color: var(--locationItemHoverDivColor);
          background-color: var(--locationItemHoverDivBgc);
          cursor: pointer;
        }
      }

      .is-active {
        color: var(--locationItemHoverDivColor);
        background-color: var(--locationItemHoverDivBgc);
      }
    }
  }

  &.vie {
    &::v-deep {
      .van-collapse-item {
        width: 80px;
      }

      .van-collapse-item__wrapper {
        transform: translate(-1px, 5px);
      }
    }
  }

  &.en {
    &::v-deep {
      .van-collapse-item {
        width: 90px;
        // border: 1px solid #F00;
      }

      .van-collapse-item__wrapper {
        transform: translate(-1px, 5px);
        width: 90px;
      }
    }
  }
}

</style>
