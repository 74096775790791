<template>
  <van-popup
    v-model="popupShow"
    get-container="body"
    class="basepopup"
    :close-on-click-overlay="false"
  >
    <div class="content__wrap">
      <div class="header">
        <div class="title">
          {{ popupTitle }}
        </div>
        <div
          v-if="closeType"
          class="close"
          @click="closePopupHandler"
        >
          <img
            src="/static/images/Layout/close.svg"
            alt=""
          >
        </div>
      </div>
      <div class="content__content">
        <slot />
      </div>
    </div>
  </van-popup>
</template>

<script>
export default {
  props: {
    popupTitle: {
      type: String,
      required: true
    },
    closeType: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      popupShow: false
    }
  },
  methods: {
    openPopupHandler () {
      this.popupShow = true
    },
    closePopupHandler () {
      this.popupShow = false
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
.basepopup {
  overflow: hidden;
  width: 509px;
  background-color: var(--PopupBgc);
  border-radius: 6px;
  box-shadow: var(--PopupShadow);
}

.content__wrap {
  // border: 1px solid #000;
  display: flex;
  flex-direction: column;
  height: 509px;

  >.header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    font-size: 14px;
    color: var(--PopupHeaderColor);
    line-height: 20px;
    background-color: var(--PopupHeaderBgc);

    .close {
      position: absolute;
      top: 50%;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      transform: translateY(-50%);

      &:hover {
        cursor: pointer;
      }
    }
  }

  .content__content {
    flex: 1;
    padding: 16px 20px;
  }
}

</style>
