<template>
  <div class="title__group">
    <span class="tab__itemstock base__width">{{ $t('PC_STOCK_INDEX') }}</span>
    <span class="tab__itemorder base__width">{{ $t('WAREHOUSE_RECORDCENTER_CHASENUMBER_C_ISSUE_TITLE') }}</span>
    <span class="tab__itemtime  double__width ">{{ $t('WAREHOUSE_RECORDCENTER_CHASENUMBER_CHASE_TIME_TITLE') }}</span>
    <span class="tab__itemstep base__width ">{{ $t('WAREHOUSE_RECORDCENTER_CHASENUMBER_CHASE_SCHEDULE') }}</span>
    <span class="tab__itemtchasemoney base__width ">{{ $t('WAREHOUSE_RECORDCENTER_CHASENUMBER_CHASE_MONEY_TITLE') }}</span>
    <span class="tab__itemmoney base__width ">{{ $t('WAREHOUSE_RECORDCENTER_CHASENUMBER_BET_TITLE') }}</span>
    <span class="tab__itemtbones base__width ">{{ $t('PC_PROFTI_AMOUNT') }}</span>
    <span class="tab__itemstate small__width ">{{ $t('PC_STATE') }}</span>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.title__group {
  display: flex;
  align-items: center;
  overflow: hidden;
  padding-right: 16px;
  padding-left: 16px;
  width: 100%;
  height: 40px;
  font-size: 12px;
  color: var(--accountingTitleColor);
  // background-color: #1E2E48;
  line-height: 17px;

  .double__width {
    flex: 3;
  }

  .base__width {
    flex: 2;
  }

  .small__width {
    flex: 1;
  }

  >.tab__itemorder {
    // flex: 1.5;
  }

  >.tab__itemtime {
    // flex: 1.5;
  }
}
</style>
