<template>
  <van-popup
    :id="slideItem"
    v-model="popupShow"
    :overlay-style="{opacity: 0}"
    :duration="0"
    :overlay="hasOverlay"
    get-container="body"
    class="basepopup"
    :class="[slideItem]"
    :style="{
      left: (sleft !== null && `${sleft}px`) || '50%',
      top:(stop !== null && `${stop}px`) || '50%'
    }"
    @click-close-icon="closePopupHandler"
    @click-overlay="closePopupHandlerAll"
    @mousedown.native="move"
    @mouseup.native.stop="removeFun"
    @click="updateCurTag"
  >
    <div class="content__wrap">
      <div
        class="header slide-move"
        :class="{unFocus: currentTag !== slideItem}"
      >
        <div class="title">
          {{ popupTitle }}
        </div>
        <div
          v-if="closeType"
          class="close"
          :class="{unFocus: currentTag !== slideItem}"
          @click.stop="closePopupHandler"
        >
          <img
            v-if="currentTag !== slideItem"
            src="/static/images/Layout/close-gray.svg"
            alt=""
          >
          <img
            v-else
            src="/static/images/Layout/close.svg"
            alt=""
          >
        </div>
      </div>
      <div class="content__content">
        <slot />
      </div>
    </div>
  </van-popup>
</template>

<script>
const newtarget = 'slide-move'
export default {
  props: {
    popupTitle: {
      type: String,
      required: true
    },
    closeType: {
      type: Boolean,
      default: true
    },
    slideItem: {
      type: String,
      default: () => ''
    },
    hasOverlay: {
      type: Boolean,
      default: () => false
    },
    openChild: {
      type: Boolean,
      default: () => false
    }
  },
  data () {
    return {
      sleft: null,
      stop: null,
      popupShow: false
    }
  },
  computed: {
    currentTag () {
      return this.$store.state.currentTag
    }
  },
  methods: {
    updateCurTag (e) {
      if (this.slideItem === this.currentTag) return
      this.$store.dispatch('actionSetCurrentTag', this.slideItem)
      this.$nextTick(() => {
        const element = document.getElementById(this.slideItem)
        if (!element) return
        document.getElementById(this.slideItem).style.zIndex = Number(document.getElementById(this.slideItem).style.zIndex) + 2
      })
    },
    parentUpdate () {
      this.$nextTick(() => {
        const element = document.getElementById(this.slideItem)
        if (!element) return
        document.getElementById(this.slideItem).style.zIndex = Number(document.getElementById(this.slideItem).style.zIndex) + 2
      })
    },
    removeFun (e) {
      const newdom = e.target.className
      if (newdom.indexOf(newtarget) === -1 || this.slideItem === '') {
        return false
      }

      document.onmousemove = null
      document.onmouseup = null
    },
    move (e) {
      const newdom = e.target.className
      if (newdom.indexOf(newtarget) === -1 || this.slideItem === '') {
        return false
      }
      // 窗口宽509，高305
      const winW = window.innerWidth
      const winH = window.innerHeight
      const maxRight = winW - 250
      const maxDown = winH - 150
      const odiv = document.getElementsByClassName(this.slideItem)[0] // 获取目标元素
      // 算出鼠标相对元素的位置
      const disX = e.clientX - odiv.offsetLeft
      const disY = e.clientY - odiv.offsetTop

      document.onmousemove = e => {
        let left = e.clientX - disX
        let top = e.clientY - disY
        if (left <= 0) {
          left = 0
        } else if (left >= maxRight) {
          left = maxRight
        }
        if (top <= 20) {
          top = 20
        } else if (top >= maxDown) {
          top = maxDown
        }
        // 移动当前元素
        this.sleft = left
        this.stop = top
        return false
      }
      document.onmouseup = e => {
        document.onmousemove = null
        document.onmouseup = null
      }
      document.onmouseleave = e => {
        document.onmousemove = null
        document.onmouseup = null
      }
      return false
    },
    openPopupHandler () {
      this.popupShow = true
    },
    closePopupHandler () {
      if ((this.sleft || this.stop)) {
        this.sleft = null
        this.stop = null
      }

      this.popupShow = false
      if (this.slideItem === 'childpopup') {
        this.$store.dispatch('actionSetCurrentTag', 'parentpopup')
      }

      if (this.slideItem === 'parentpopup') {
        this.closePopupHandlerAll()
      }
    },
    closePopupHandlerAll () {
      if ((this.sleft || this.stop)) {
        this.sleft = null
        this.stop = null
      }
      this.popupShow = false
      if (!this.slideItem || this.slideItem === 'childpopup') return
      this.$emit('closeAll')
    }
  }
}
</script>

<style lang="scss" scoped>
.basepopup {
  overflow: hidden;
  width: 509px;
  background-color: var(--PopupBgc);
  border-radius: 6px;
  box-shadow: var(--PopupShadow);
}

.content__wrap {
  // border: 1px solid #000;
  display: flex;
  flex-direction: column;

  >.header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    font-size: 14px;
    color: var(--PopupHeaderColor);
    line-height: 20px;
    background-color: var(--PopupHeaderBgc);
    cursor: move;
    margin-bottom: 20px;

    .close {
      position: absolute;
      top: 50%;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      transform: translateY(-50%);

      >img {
        width: 12px;
        height: 12px;
      }

      &:hover {
        cursor: pointer;

        &::before {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 28px;
          height: 28px;
          background: var(--homeCloseBtnHoverBgc);
          z-index: -1;
          content: '';
        }
      }
    }

    &.unFocus {
      color: var(--PopupHeaderUnFocusColor);
    }
  }

  .content__content {
    flex: 1;
    // padding: 16px 20px;
  }
}

</style>
