<template>
  <div
    class="home__wrap"
  >
    <div
      class="side1"
      :class="[{maskcancel:stockMaskCancel}]"
      @click.self="noteBookSizeSwitchStockSide"
    >
      <!-- 股指/期号  -->
      <div
        class="side1-container"
        :class="[hideStockSide]"
      >
        <div class="bgc" />
        <TradeMain
          ref="trademain"
          class="stock__wrap"
        />
        <div
          class="side-close-btn"
          @click="switchStockSide"
        >
          <div
            class="switchtabbtn"
            :class="[{close:!optionSides.stockSide}]"
          >
            <img
              src="/static/images/stockIcon/side-btn.svg"
              alt=""
            >
          </div>
        </div>
      </div>
    </div>
    <div class="main">
      <div
        :class="[{empty:overSmallSizeStock}]"
        class="side1__empty"
      />
      <div class="main__viewwrap">
        <div
          ref="chartPagewrap"
          class="chart__pagewrap"
        >
          <div class="page__wrap">
            <div
              class="tabs__group"
              :class="$i18n.locale"
            >
              <div
                v-for="(tab, index) in chartTabData"
                :key="tab.name"
                class="tab__item"
                :class="[{active:currentTab===tab.code}]"
                :style="`max-width:${tabWidth}px`"
                @click="switchTab(tab.code, index)"
              >
                <span class="tab__name">
                  <!-- {{ tab.name || getTabName(tab.code) }} -->
                  <!-- old會有name  -->
                  {{ stockResMsg(`STOCK_${tab.code}`,getTabName(tab.code)) }}
                </span>
                <span
                  v-if="chartTabData.length > 1"
                  class="close-btn-wrap"
                >
                  <span
                    class="close-btn"
                    @click.stop="deleteTab(tab.code)"
                  />
                </span>
              </div>
              <div class="tabs__group--bgc" />
            </div>
            <transition>
              <component
                :is="currentTabComponent"
                :code="lCode"
                :name="currentStockData(lCode).lotteryName"
              />
            </transition>
            <div
              class="graph-type-switch"
              :class="$i18n.locale"
            >
              <div
                class="option"
                :class="[{active: $store.state.graphType === 'mixed'}, chartMargin]"
                @click="switchGraph('mixed')"
              >
                {{ $t('PC_MARKET_CHART') }}
              </div>
              <div
                class="option"
                :class="[{active: $store.state.graphType === 'line'},chartMargin]"
                @click="switchGraph('line')"
              >
                {{ $t('PC_TREND_CHART') }}
              </div>
              <div
                class="option"
                :class="[{active: $store.state.graphType === 'kType'}]"
                @click="switchGraph('kType')"
              >
                {{ $t('PC_KLINE_CHART') }}
              </div>
            </div>
          </div>
          <div class="border" />
          <!-- 投资记录&帐务记录 wrap -->
          <IndexTab />
        </div>
        <div class="side2">
          <div class="bgc right-bgc" />
          <div
            class="side-close-btn"
          />
          <div class="trade__tabwrap">
            <!--  建仓买入 wrap -->
            <IndexBetTab />
          </div>
        </div>
      </div>
      <div
        :class="[{empty:overSmallSizeTrend}]"
        class="side3__empty"
      />
    </div>
    <!-- 走勢 & 盈利榜  -->
    <div
      class="side3"
      :class="[{maskcancel:trendMaskCancel},{isstockopen:hideTrendkSide}]"
      @click.self="noteBookSizeSwitchTrendSide"
    >
      <div
        class="side3-container"
        :class="[hideTrendkSide]"
      >
        <div class="bgc right-bgc" />
        <div
          class="side-close-btn"
          @click="switchTrendSide"
        >
          <div
            class="switchtabbtn"
            :class="[{close:!optionSides.trendSide}]"
          >
            <img
              src="/static/images/stockIcon/side-btn.svg"
              alt=""
            >
          </div>
        </div>
        <TrandRank
          ref="stockrank"
        />
        <div class="side-close-btn" />
      </div>
    </div>
    <PopupBase
      ref="announcementpop"
      :popup-title="$t('PC_ANNOUNCE_NEW')"
      class="announcement__pop"
      @close="closeAnnouncement"
    >
      <AnnouncementSwiper
        :announcement-lists="announcementListData"
        @clocePopup="closePopupHandler"
      />
    </PopupBase>
  </div>
</template>

<script>
import { stockResMsg } from '@/utils'
import IndexTab from '@/components/IndexWareHouse/UserWare.vue'
import TradeMain from '@/components/home/trade/tradeMain.vue'
import TrandRank from '@/components/home/TrendRank.vue'
import IndexBetTab from '@/components/IndexBet/IndexBetTab.vue'
import Tab01 from '@/components/home/Tab/01.vue'
import Tab02 from '@/components/home/Tab/02.vue'
import Tab03 from '@/components/home/Tab/03.vue'
import Tab04 from '@/components/home/Tab/04.vue'
import apiManage from '@/api/manage'
import PopupBase from '@/components/Popup/overlay.vue'
import AnnouncementSwiper from '@/components/home/AnnouncementSwiper.vue'
import { mapState } from 'vuex'

export default {
  name: 'Home',

  components: {
    IndexTab,
    TradeMain,
    TrandRank,
    IndexBetTab,
    Tab01,
    Tab02,
    Tab03,
    Tab04,
    PopupBase,
    AnnouncementSwiper
  },
  data () {
    return {
      maxWindows: 4, // 最多視窗數
      currentIndex: 0,
      chartActive: 0,
      windowSize: 0,
      side: {
        stockSide: true,
        trendSide: true
      },
      announcementList: [],
      announcementListData: [], // 公告全次
      announcementDetail: {},
      hasAnnouncement: false,
      hasAnnouncementList: false,
      announcementCheck: false, // 是否查看,
      waitLoadIngAnnount: false, // 等待是否讀取結束
      announcementIndex: 0,
      tabWidth: 0
    }
  },
  computed: {
    ...mapState(['optionSides', 'chartTabData', 'currentTab', 'newLotteryList']),
    currentTabComponent () {
      return `Tab0${this.currentIndex + 1}`
    },
    chartMargin () {
      const margin = this.$i18n.locale === 'ja' ? 'mr-5' : 'mr-10'
      return margin
    },
    lCode () {
      return this.$store.state.lt ? this.$store.state.lt.lCode : this.$store.state.defaultStock
    },
    hideStockSide () {
      return this.optionSides.stockSide === true ? '' : 'hidestockside'
    },
    hideTrendkSide () {
      return this.optionSides.trendSide === true ? '' : 'hidetrendside'
    },
    trendMaskCancel () {
      return this.windowSize <= 1410 && this.optionSides.stockSide === false && this.optionSides.trendSide === true
    },
    stockMaskCancel () {
      return this.windowSize <= 1410 && this.optionSides.stockSide === true && this.optionSides.trendSide === false
    },
    overSmallSizeStock () {
      return this.windowSize >= 1410 ? this.optionSides.stockSide : false
    },
    overSmallSizeTrend () {
      return this.windowSize >= 1410 ? this.optionSides.trendSide : false
    }
  },
  watch: {
    lCode () {
      // 切換 Tab
      if (this.chartTabData.some(i => i.code === this.lCode)) {
        this.$store.dispatch('actionSetCurrentTab', this.lCode)
        this.currentIndex = this.chartTabData.findIndex(i => i.code === this.lCode)
      } else if (this.chartTabData.every(i => i.code !== this.lCode) && this.chartTabData.length >= this.maxWindows) {
        this.$store.dispatch('actionSetCurrentTab', this.lCode)
        this.$store.dispatch('actionSetChartTabData', this.chartTabData.map((item, index) => {
          if (index === this.currentIndex) {
            const { lotteryName } = this.currentStockData(this.lCode)
            return {
              name: lotteryName,
              code: this.lCode
            }
          }

          return item
        }))
      } else {
        const { lotteryName } = this.currentStockData(this.lCode)
        this.$store.dispatch('actionSetChartTabData', [
          ...this.chartTabData,
          {
            name: lotteryName,
            code: this.lCode
          }
        ])
        this.$store.dispatch('actionSetCurrentTab', this.lCode)
        this.currentIndex = this.chartTabData.length - 1
      }
    }
  },
  created () {
    // 拿公告
    this.getAnnouncement()
  },
  mounted () {
    window.addEventListener('resize', this.resizeHanlder)
    this.resizeHanlder()
  },
  methods: {
    stockResMsg,
    getTabName (code) {
      const data = this.newLotteryList
      return data ? data.filter(item => item.lotteryCode === code)[0].lotteryName : ''
    },
    switchTab (code, index) {
      this.$store.dispatch('actionUpdateLottery', code)
      this.$store.dispatch('actionSetCurrentTab', code)
      this.currentIndex = index
    },
    deleteTab (code, index) {
      const filterArr = this.chartTabData.filter(i => i.code !== code)
      this.$store.commit('setChartTabData', filterArr)
      const backUpIndex = this.chartTabData.length - 1
      const currentData = this.chartTabData[backUpIndex]

      this.$store.dispatch('actionUpdateLottery', currentData.code)
      this.$store.dispatch('actionSetCurrentTab', currentData.code)
      this.currentIndex = backUpIndex
    },
    switchGraph (val) {
      this.$store.dispatch('actionSetGraphType', val)
    },
    currentStockData (code) {
      const data = this.newLotteryList
      return data ? data.filter(item => item.lotteryCode === code)[0] : {}
    },
    deleteHandler (value) {
      if (this.chartTabData.length > 1) {
        this.$store.dispatch('actionSetChartTabData', this.chartTabData.filter((item, index) => index !== value))
      }
    },
    // RWD
    resizeHanlder () {
      const winWidth = window.innerWidth
      this.windowSize = winWidth
      // console.log(winWidth, '左', this.side.stockSide, '右', this.side.trendSide)
      if (winWidth <= 1919) {
        this.$store.dispatch('actionSetOptionSides', { ...this.optionSides, stockSide: true, trendSide: false })
        this.tabWidth = 256
      }
      if (winWidth <= 1410) {
        this.$store.dispatch('actionSetOptionSides', { ...this.optionSides, stockSide: false })
        this.tabWidth = 156
      }

      if (winWidth >= 1920) {
        this.$store.dispatch('actionSetOptionSides', { ...this.optionSides, trendSide: true, stockSide: true })
        this.tabWidth = 334
      }
    },
    noteBookSizeSwitchStockSide () {
      if (this.windowSize <= 1410) {
        // 小於1410允許點取空白處關閉
        this.$store.dispatch('actionSetOptionSides', { ...this.optionSides, stockSide: !this.optionSides.stockSide })
      }
    },
    noteBookSizeSwitchTrendSide () {
      // 小於1410允許點取空白處關閉
      if (this.windowSize <= 1410) {
        this.$store.dispatch('actionSetOptionSides', { ...this.optionSides, trendSide: !this.optionSides.trendSide })
      }
    },
    switchStockSide () {
      if (this.windowSize <= 1919) {
        // 小於1599確認另一個是否關閉
        this.optionSides.trendSide = false
      }
      this.$store.dispatch('actionSetOptionSides', { ...this.optionSides, stockSide: !this.optionSides.stockSide })
    },
    switchTrendSide () {
      console.log('trend-btn')
      if (this.windowSize <= 1919) {
        // 小於1599確認另一個是否關閉
        this.optionSides.stockSide = false
      }
      this.$store.dispatch('actionSetOptionSides', { ...this.optionSides, trendSide: !this.optionSides.trendSide })
    },
    async getAnnouncement () {
      if (this.hasAnnouncement) return
      const params = {
        pageSize: 9999,
        pageNum: 1
      }
      this.hasAnnouncement = true
      try {
        const apiData = await apiManage.homeannouncement(params).then((res) => res?.data)
        this.hasAnnouncement = false
        this.announcementListData = apiData.result
        this.getAnnouncementDetail()
        this.checkSeeAnnouncement()
      } catch (error) {
        console.log(error)
      }
    },
    checkSeeAnnouncement () {
      const dataLength = [...this.announcementListData]
      // console.log(dataLength, 'datalength')
      if (dataLength.length > 0) {
        // console.log(dataLength)
        const checkLocakValue = JSON.parse(localStorage.getItem('checkAnnouncement'))
        // local是否確認
        const checkOpenNotice = new Promise(
          function (resolve) {
            if (checkLocakValue) {
              // const info = 'have check'
              resolve(true)
            } else {
              // const error = 'no check'
              resolve(false)
            }
          }
        )
        //

        const _this = this
        checkOpenNotice.then(function (value) {
          console.log(value)
          // console.log(info)// 以確認關閉視窗
          if (value === true) {
            _this.closeAnnouncement()
          } else {
            _this.openAnnoucement()
          }
        })
      }
    },
    getAnnouncementDetail () {
      // API第一筆id
      const notice = JSON.parse(localStorage.getItem('announcement')) ? JSON.parse(localStorage.getItem('announcement')) : {}// 本地公告資料
      const announLength = this.announcementListData.length// 線上公告資料
      // console.log(notice)

      if (announLength > 0) {
        // 有公告資料
        const params = {
          id: this.announcementListData[0].id
        }
        apiManage.announcementContent(params).then((res) => {
          if (res.code === 1 && res.data) {
            const apiResult = res?.data
            this.announcementDetail = apiResult
            localStorage.setItem('announcement', JSON.stringify(apiResult))
            if (notice.id !== apiResult.id) {
              // 如果api跟本地id不相同再度開啟
              // console.log(notice.id, apiResult.id, 'id')
              setTimeout(() => {
                this.openAnnoucement()
              }, 500)
            }
          }
        })
      } else {
        // 無公告資料
        // 開啟預設value
        // this.announcementDef = true
      }
    },
    getNoticeList () {
      if (this.hasAnnouncementList) return
      const params = {
        pageSize: 9999,
        pageNum: 1
      }
      this.hasAnnouncementList = true
      apiManage.announcement(params).then(res => {
        this.hasAnnouncementList = false
        if (res.code === 1) {
          const result = (res.data && res.data.result) || []
          this.announcementList =
            result.length > 0
              ? result
              : [
                  {
                    title: `欢迎来到${this.siteConfig.tenantName}`,
                    self: true
                  }
                ]
          const object = {}
          object[this.cacheDate.announcement] = this.announcementList
          localStorage.setItem('noticeListNew', JSON.stringify(object))
        }
      })
    },
    openAnnoucement () {
      const open = this.$refs.announcementpop
      setTimeout(() => {
        open && open.openPopupHandler()
      }, 200)
    },
    closeAnnouncement () {
      // const close = this.$refs.announcementpop
      // close.closePopupHandler()
      // 存入local
      localStorage.setItem('checkAnnouncement', true)
      // this.$store.dispatch('actionSetOptionSides', { ...this.optionSides, trendSide: !this.optionSides.trendSide })
    },
    closePopupHandler () {
      // console.log('close---p')
      const close = this.$refs.announcementpop
      close.closePopupHandler()
      this.closeAnnouncement()
    }
  }
}
</script>
<style lang="scss" scoped>
@import './Home.scss';

.graph-type-switch {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 12px;
  width: 226px;
  height: 44px;
  font-size: 14px;
  white-space: nowrap;
  color: var(--homeVanTabColor);
  background-color: var(--homeTabBgc);
  z-index: 2;

  &.ja {
    width: 260px;
  }

  &.vie {
    width: 245px;
  }

  &.en {
    width: 300px;
  }

  .option {
    cursor: pointer;

    &.active {
      color: var(--homeTabHoverColor);
      cursor: default;
      pointer-events: none;
    }
  }
}

.border {
  position: relative;
  height: 6px;
  background-color: var(--trendRankBorderBgc);
  border: 0;
  z-index: 2;
}
</style>
