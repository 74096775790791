export default {
  SET_IS_APP: (state, isApp) => {
    state.isApp = isApp
  },
  SET_HAS_ERROR: (state, val) => {
    state.hasError = val
  },
  SET_ERROR_MESSAGE: (state, res) => {
    state.errorMessage = res
  },
  SET_UN_READER_SHOW: (state, flag) => {
    state.messageShow = flag
  },
  // SET_MESSAGE_SHOW: (state, num) => {
  //   state.messageNum = num
  // },
  SET_NOTICE_END: (state, securityNoticeEnd) => {
    state.securityNoticeEnd = securityNoticeEnd
  },
  SET_INTERFACE_LOADING: (state, val) => {
    state.loading = val
  },
  SET_VERSION: (state, res) => {
    state.version = res
  },
  SET_SITE_CONFIG: (state, res) => {
    state.siteConfig = res
  },
  // set userBalance
  setUserBalance: (state, res) => {
    state.userBalance = res
  },
  // set has upadteBalance
  setUpadteBalance: (state, res) => {
    state.upadteBalance = res
  },
  // 设置时差-彩种相关
  setDifftime: (state, timeItemList) => {
    let _shortest = timeItemList[0]
    let _index = 0
    timeItemList.forEach((item, index) => {
      if (item.interval < _shortest) {
        _shortest = item.interval
        _index = index
      }
    })
    const timeObj = timeItemList[_index]
    const timeBegin = timeObj.timeBegin
    const timeEnd = timeObj.timeEnd
    const SerTime = timeObj.SerTime
    const Difftime =
      timeBegin + Math.floor((timeEnd - timeBegin) / 2) - SerTime
    console.log(Difftime, timeObj)
    state.Difftime = Difftime
    localStorage.setItem('Difftime', Difftime)
  },
  // 设置是否展示快捷选号
  SET_ISFASTSHOW: (state, res) => {
    state.isFastShow = res
  },
  // SET_THEME_VALUE: (state, val) => {
  //   state.themeValue = val
  // },
  SET_HAS_NOTIFY: (state, val) => {
    state.hasNotify = val
    state.hasNotify.show && setTimeout(() => {
      state.hasNotify = {
        show: false
      }
    }, 2000)
  },
  SET_NOTIFY_ICON_NAME: (state, val) => {
    state.notifyIconName = val
  },
  SET_NOTIFY_CONTENT: (state, val) => {
    state.notifyContent = val
  },
  // 設定彩票開放模式
  // set stockIssueData
  setStockIssueData: (state, val) => {
    state.stockIssueData = val
  },
  // set OldIssue
  setOldIssue: (state, val) => {
    state.OldIssue = val
  },
  setCancelBtnColor: (state, val) => {
    state.cancelBtnColor = val
  },
  // set activityDetail
  setActivityDetail: (state, val) => {
    state.activityDetail = val
  },
  // set shareData
  setShareData: (state, val) => {
    state.shareData = val
  },
  // set notifyData
  lt_notifyData: (state, val) => {
    state.notifyData = val
  },
  // set hasMaintain
  setHasMaintain: (state, val) => {
    state.hasMaintain = val
  },
  // set hasShareRoom
  setHasShareRoom: (state, val) => {
    state.hasShareRoom = val
  },
  // set hasLimitLoading
  setHasLimitLoading: (state, val) => {
    state.hasLimitLoading = val
  },
  // set limitMessage
  setLimitMessage: (state, val) => {
    state.limitMessage = val
  },
  // set hasSearch
  setHasSearch: (state, val) => {
    state.hasSearch = val
  },
  // set isBgFixed
  setIsBgFixed: (state, val) => {
    state.isBgFixed = val
  },
  // set isBgMax
  setIsBgMax: (state, val) => {
    state.isBgMax = val
  },
  setHasProfixTable: (state, val) => {
    state.hasProfitTable = val
  },
  setHasProfixTableMore: (state, val) => {
    state.hasProfitTableMore = val
  },
  // set userProfit
  setUserProfit: (state, val) => {
    state.userProfit = val
  },
  // set stockDetailData
  setStockDetailData: (state, val) => {
    state.stockDetailData = val
  },
  // set tabActive
  setTabActive: (state, val) => {
    state.tabActive = val
  },
  // set theme
  setTheWord (state, val) {
    state.themeWord = val
  },
  // set graphType
  setGraphType (state, val) {
    state.graphType = val
  },
  // set trendData
  setTrendData (state, { code, list, lastOneClose, lastOneOpen }) {
    state.trendData = {
      ...state.trendData,
      [code]: {
        list,
        lastOneClose,
        lastOneOpen
      }
    }
  },
  setBetOrderList (state, val) {
    state.betOrderList = {
      ...state.betOrderList,
      [val.orderId]: val
    }
  },
  setLogout (state, val) {
    state.currentTab = val
    state.graphType = 'mixed'
    state.chartTabData = [
      { name: '', id: '01', code: val }
    ]
    state.optionSides = {
      stockSide: true,
      trendSide: true
    }
    state.lt.lCode = val
  },
  setDefaultCode (state, val) {
    state.currentTab = val
    state.chartTabData = [
      { name: '', id: '01', code: val }
    ]
    state.lt.lCode = val
  },
  // SET chartTabData
  setChartTabData (state, val) {
    state.chartTabData = val
  },
  // set currentTab
  setCurrentTab (state, val) {
    state.currentTab = val
  },
  // set chaseBetOrderList
  // chaseState -> 1 進行中,  2 已結束
  setChaseBetOrderList (state, val) {
    state.chaseBetOrderList = {
      ...state.chaseBetOrderList,
      [val.chaseId]: {
        state:
          val.chaseState === 1
            ? 'ing'
            : Number(val.totalWinMoney) > 0 ? val.totalWinMoney : 'unprofitable', // 有盈利放到 state
        lastOpenNumber: val.lastOpenNumber,
        completeCount: val.completeCount,
        completeMoney: val.completeMoney,
        allBonus: val.totalWinMoney,
        [val.orderId]: {
          bonus: val.bonus,
          state: val.state,
          openNum: val.openNum
        }
      }
    }
  },
  SET_RECORD_BTN_ACTIVE (state, val) {
    state.recordActive = val
  },
  // 更新期号时
  lt_updateIssue: (state, val) => {
    state.NowIssue = val
  },
  lt_updateNextIssue: (state, val) => {
    state.NextIssue = val
  },
  // set NewLotteryList
  lt_setNewLotteryList: (state, val) => {
    state.newLotteryList = val
  },
  // set newLotteryConfig
  lt_setNewLotteryConfig: (state, val) => {
    state.newLotteryConfig = val
  },
  // set optionSides
  setOptionSides (state, val) {
    state.optionSides = val
  },
  // set currentTag
  setCuurentTag (state, val) {
    state.currentTag = val
  },
  setSelectOpenState (state, val) {
    state.selectOpenState = val
  },
  setSelectOpenBaseState (state, val) {
    state.selectOpenBaseState = val
  },
  setTypeSelectDropDown (state, val) {
    state.typeSelectDropDown = val
  },
  setBaseSelectDropDown (state, val) {
    state.baseSelectDropDown = val
  },
  betStateUse (state, val) {
    state.betState = val
  }
}
