<template>
  <div class="trade-main-wrap">
    <div class="change-btnwrap">
      <div
        class="left-btn btn-setting"
        :class="[{hide:hideTradeLeftBtn}]"
        @click="changIndexHandler('l')"
      >
        <img
          v-if="hideTradeLeftBtn"
          class="hide-icon"
          src="/static/images/trade/hide.svg"
          alt=""
        >
        <img
          v-else
          class="active-icon"
          src="/static/images/trade/active.svg"
          alt=""
        >
      </div>
      <div
        class="right-btn btn-setting"
        :class="[{hide:hideTradeRightBtn}]"
        @click="changIndexHandler('r')"
      >
        <img
          v-if="hideTradeRightBtn"
          class="hide-icon"
          src="/static/images/trade/hide.svg"
          alt=""
        >
        <img
          v-else
          class="active-icon"
          src="/static/images/trade/active.svg"
          alt=""
        >
      </div>
    </div>
    <van-tabs
      v-model="active"
      class="trade__tabs"
      :swipe-threshold="4"
      @change="changeTab"
    >
      <van-tab
        v-for="(item,index) in countryList"
        :key="`country${index}`"
        :title="$t(item.name)"
        :name="item.area"
      >
        <div class="main__pctitle">
          <div class="stock">
            {{ $t('PC_STOCK_INDEX') }}/{{ $t('PC_ISSUE') }}
          </div>
          <div class="number">
            {{ $t('PC_OUTGOING_INDEX') }}/{{ $t('TRADE_PRICING2') }}
          </div>
        </div>
        <div class="main__content">
          <van-list
            v-model="isLoading"
            class="stock-list-wrap"
            :finished="isFinished"
            :offset="500"
            @load="onLoad"
          >
            <div
              v-for="(subItem) in tabUseData"
              :key="`stock-${subItem.lotteryCode}${active}`"
              class="main__item"
              :class="[{active:selectHeaderArea===subItem.lotteryCode}]"
              @click="getStockEmitHandler(subItem.lotteryCode)"
            >
              <!-- <div class="main__icon">
                <img
                  v-if="countryIconList.includes(subItem.lotteryCode)"
                  :src="getCountryicons(subItem.country)"
                  alt=""
                >
                <img
                  v-else
                  :src="getSicons(subItem.lotteryCode)"
                  alt=""
                >
              </div> -->
              <!-- {{ stockResMsg(`STOCk_${subItem.lotteryCode}`,subItem.lotteryName) }} -->
              <mainText
                ref="maintext"
                :issue="stockDetailData[subItem.lotteryCode] && stockDetailData[subItem.lotteryCode].lastIssue"
                :name="stockResMsg(`STOCK_${subItem.lotteryCode}`,subItem.lotteryName)"
                :open-number="stockDetailData[subItem.lotteryCode] && stockDetailData[subItem.lotteryCode].lastOpenNumber"
                :gain="stockDetailData[subItem.lotteryCode] && getLotteryData(subItem.lotteryCode).gain"
                :currency="stockDetailData[subItem.lotteryCode] && getLotteryData(subItem.lotteryCode).currency"
                :code="subItem.lotteryCode"
                :visible="!!(stockDetailData[subItem.lotteryCode])"
                :maindata="stockDetailData[subItem.lotteryCode]"
                :title="`${subItem.currency || 'USD'} ${dynamicGain(subItem.gain)}`"
              />
              <!-- <div class="currency">
                  {{ subItem.currency || 'USD' }}
                  {{ dynamicGain(subItem.gain) }}
                </div> -->
            </div>
            <span slot="loading" />
            <div
              v-if="isLoading"
              class="loading-box"
            >
              <GlobalLoading />
            </div>
          </van-list>
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import { getCountryicons, getSicons, countryIconList, specialIcons, checkCatchTime, setTimeStr, stockResMsg } from '@/utils'
import mainText from './mainText.vue'
import GlobalLoading from '@/components/loader/GlobalLoading.vue'
import lotteryAPI from '@/api/lottery'
import manageApi from '@/api/manage'
import 'vant/lib/tabs/index.css'
import 'vant/lib/tab/index.css'
import { mapState } from 'vuex'

const gainTable = (unit) => {
  return {
    1: unit.dollar,
    10: unit.dime,
    100: unit.cent,
    1000: '1/1K',
    10000: '1/10K',
    100000: '1/100K',
    1000000: '1/1M',
    10000000: '1/10M',
    100000000: '1/100M'
  }
}

export default {
  components: {
    GlobalLoading,
    mainText
  },
  data () {
    return {
      countryIconList,
      specialIcons,
      chineseNameTable: {
        Asia: 'TRADE_ASIA',
        Europe: 'TRADE_EUROPE',
        'North America': 'TRADE_NORTH_AMERICA',
        'South America': 'TRADE_SOUTH_AMERICA',
        Oceania: 'TRADE_OCEANIA',
        VirtualCurrency: 'TRADE_VIRTUALCURRENCY'
      },
      // active: 0,
      curHeight: window.innerHeight - 100,
      searchActive: 0,
      windowSize: 0,
      originalList: [],
      hotList: [],
      allList: [],
      countryList: [],
      asiaList: [],
      europeList: [],
      northAmericaList: [],
      southAmericaList: [],
      oceaniaList: [],
      virtualList: [],
      // stockDetailData: {},
      changeDateType: 0,
      pageSize: 10,
      pageNumber: 1,
      isLoading: false,
      isFinished: false,
      cacheDate: null,
      timeoutCache: null,
      // search-bar
      searchText: ''
    }
  },
  computed: {
    ...mapState(['hasSearch', 'isSafari', 'hasProfitTable', 'hasProfitTableMore', 'stockDetailData', 'tabActive', 'optionSides', 'hasHot']),
    hasSearchh: {
      get () {
        return this.hasSearch
      },
      set (val) {
        this.$store.commit('setHasSearch', val)
      }
    },
    active: {
      get () {
        return this.tabActive
      },
      set (val) {
        this.$store.dispatch('actionSetTabActive', val)
      }
    },
    tabUseData () {
      let currentList
      if (this.active === 'hot') {
        currentList = this.hotList
      } else if (this.active === 'all') {
        currentList = this.allList
      } else if (this.active === 'Asia') {
        currentList = this.asiaList
      } else if (this.active === 'Europe') {
        currentList = this.europeList
      } else if (this.active === 'North America') {
        currentList = this.northAmericaList
      } else if (this.active === 'South America') {
        currentList = this.southAmericaList
      } else if (this.active === 'Oceania') {
        currentList = this.oceaniaList
      } else if (this.active === 'VirtualCurrency') {
        currentList = this.virtualList
      } else {
        currentList = []
      }

      return currentList.slice(0, this.pageSize * this.pageNumber)
    },
    tabSearchData () {
      const regWord = new RegExp(this.searchText, 'gi')
      const search = this.searchText

      let currentList
      if (this.searchActive === 'hot') {
        currentList = this.hotList
      } else if (this.searchActive === 'all') {
        currentList = this.allList
      } else if (this.searchActive === 'Asia') {
        currentList = this.asiaList
      } else if (this.searchActive === 'Europe') {
        currentList = this.europeList
      } else if (this.searchActive === 'North America') {
        currentList = this.northAmericaList
      } else if (this.searchActive === 'South America') {
        currentList = this.southAmericaList
      } else if (this.active === 'Oceania') {
        currentList = this.oceaniaList
      } else if (this.active === 'VirtualCurrency') {
        currentList = this.virtualList
      } else {
        currentList = []
      }

      if (search) {
        return currentList.filter((value) => value.lotteryName.match(regWord))
      }

      return currentList
    },
    firstStockRank () {
      return this.hotList[0]
    },
    selectHeaderArea () {
      return this.$store.state.lt ? this.$store.state.lt.lCode : this.$store.state.defaultStock
    },
    hideTradeLeftBtn () {
      return this.active === this.countryList[0]?.area
    },
    hideTradeRightBtn () {
      return this.active === this.countryList[this.countryList.length - 1]?.area
    }
  },
  created () {
    const hotLottery = localStorage.getItem('hotLottery')
    if (hotLottery) {
      this.hotList = Object.values(JSON.parse(hotLottery))[0]
    }
    this.countryList = this.$store.state.countryList

    this.cacheDataUpdate()
    this.getList()
    const winWidth = window.innerWidth
    this.windowSize = winWidth
  },
  methods: {
    stockResMsg,
    getSicons,
    getCountryicons,
    dynamicGain (gain) {
      const unit = {
        dollar: this.$t('CHATCELL_DOLLAR'),
        dime: this.$t('TRADE_DIME'),
        cent: this.$t('TRADE_CENT')
      }
      // default 1
      return gainTable(unit)[gain || 1]
    },
    getLotteryData (code) {
      return (this.allList.filter(i => i.lotteryCode === code)[0] && this.allList.filter(i => i.lotteryCode === code)[0]) || {}
    },
    heghlight (text) {
      const regWord = new RegExp(this.searchText, 'gi')
      const titleWord = text.replace(regWord, `<span class="hilight">${this.searchText}</span>`)
      return this.searchText === ''
        ? `<span class="search">${text}</span>`
        : `<span class="search">${titleWord}</span>`
    },
    switchSearch (val) {
      this.$store.dispatch('actionSetHasSearch', val)
    },
    changeSearchTab () {
      this.searchText = ''
    },
    changeTab (e) {
      this.pageNumber = 1
      this.isFinished = false
      document.getElementsByClassName('main__content')[0].scrollTo(0, 0)
      // document.getElementsByClassName('van-tabs__content')[0].scrollTo(0, 0)
      // this.$store.dispatch('setInterfaceLoading', true)
      switch (e) {
        case 'hot':
          this.originalList = this.hotList
          break
        case 'all':
          this.originalList = this.allList
          break
        case 'Asia':
          this.originalList = this.asiaList
          break
        case 'Europe':
          this.originalList = this.europeList
          break
        case 'North America':
          this.originalList = this.northAmericaList
          break
        case 'South America':
          this.originalList = this.southAmericaList
          break
        case 'Oceania':
          this.originalList = this.oceaniaList
          break
        case 'VirtualCurrency':
          this.originalList = this.virtualList
          break
        default:
          this.originalList = []
          break
      }
      this.getList()
    },
    onLoad () {
      // console.log('loading')
      // 一開始先不用載入
      if (this.changeDateType || !this.tabUseData.length) return
      this.getList()
    },
    getList () {
      const lotteryCodes = this.tabUseData.length
        ? this.tabUseData
          .slice((this.pageNumber - 1) * this.pageSize, this.pageNumber * this.pageSize)
          .map(i => i.lotteryCode)
        : ''

      // if (!lotteryCodes || (lotteryCodes && lotteryCodes.every(code => this.stockDetailData[code]))) {
      //   this.pageNumber += 1
      //   return
      // }

      const params = lotteryCodes
      if (params.length) {
        this.isLoading = true
        lotteryAPI.lastOpenAndTrendData({ lotteryCodes: params.join() }).then((res) => {
          // this.stockDetailData = res.data)
          // this.$store.dispatch('setInterfaceLoading', false)
          if (res.data?.length) {
            const list = {}
            res.data.forEach((item) => {
              list[item.lotteryCode] = item
            })

            // this.stockDetailData = {
            //   ...this.stockDetailData,
            //   ...list
            // }

            this.$store.dispatch('actionSetStockDetailData', {
              ...this.stockDetailData,
              ...list
            })
          }

          this.isLoading = false
          this.pageNumber += 1

          if (res.data && res.data.length < this.pageSize) {
            this.isFinished = true
          } else {
            setTimeout(() => {
              this.isFinished = false
            }, 600)
          }
        })
      } else {
        this.isLoading = false
        this.isFinished = true
        this.timeoutCache && clearTimeout(this.timeoutCache)
        if (this.pageNumber === 1) {
          this.timeoutCache = setTimeout(() => {
            this.getList()
          }, 1000)
        }
      }
    },
    getHotListAPI () {
      return lotteryAPI.getHotLottery().then((res) => {
        // this.$store.dispatch('setInterfaceLoading', false)
        this.hotList = res.data
        const result = {}
        result[this.cacheDate.hotLottery] = this.hotList
        localStorage.setItem('hotLottery', JSON.stringify(result))
      })
    },
    getStockEmitHandler (value) {
      this.$store.dispatch('actionUpdateLottery', value)
      if (this.windowSize <= 1410) {
        // 小於1410允許點取空白處關閉
        this.$store.dispatch('actionSetOptionSides', { stockSide: true, trendSide: false })
      }
    },
    getLotteryConfig () {
      lotteryAPI.lotteryConfig().then(res => {
        // this.$store.dispatch('setInterfaceLoading', false)
        if (res.code === 1) {
          const LotteryList = setTimeStr(res.data.lotteryList)
          const LotteryConfig = setTimeStr(res.data.lotteryConfig)
          this.$store.dispatch('actionsSetNewLotteryList', LotteryList)
          this.$store.dispatch('actionsSetNewLotteryConfig', LotteryConfig)
          const areaConfig = []
          res.data.lotteryList.forEach(i => {
            if (areaConfig.includes(i.area)) {
              return
            }

            areaConfig.push(i.area)
          })

          const countryName = areaConfig.map((item) => ({ name: this.chineseNameTable[item], area: item }))
          const lotteryList = res.data.lotteryList || []
          // this.countryList = [{ name: 'TRADE_HOT', area: 'hot' }, { name: 'INVEST_ALL', area: 'all' }].concat(countryName)
          if (this.hasHot) {
            this.countryList = [{ name: 'TRADE_HOT', area: 'hot' }, { name: 'INVEST_ALL', area: 'all' }].concat(countryName)
          }
          this.allList = res.data.lotteryList || []
          this.asiaList = lotteryList.filter((item) => item.area === 'Asia')
          this.europeList = lotteryList.filter((item) => item.area === 'Europe')
          this.northAmericaList = lotteryList.filter((item) => item.area === 'North America')
          this.southAmericaList = lotteryList.filter((item) => item.area === 'South America')
          this.oceaniaList = lotteryList.filter((item) => item.area === 'Oceania')
          this.virtualList = lotteryList.filter((item) => item.area === 'VirtualCurrency')

          const obj = {}
          obj[this.cacheDate.allLotteryDataFront] = res.data
          localStorage.setItem('lotteryConfig', JSON.stringify(obj))
        }
      })
    },
    cacheDataUpdate (v) {
      // this.$store.dispatch('setInterfaceLoading', true)
      manageApi.getFrontCacheUpdatedAt().then((res) => {
        if (res.code === 1) {
          // let pageUpdateTime = localStorage.getItem('pageUpdateTime')
          // if (pageUpdateTime !== res.data.pageUpdateTimeMobile) {
          //   localStorage.setItem('pageUpdateTime', res.data.pageUpdateTimeMobile)
          //   return this.$router.go(0)
          // }
          checkCatchTime(res.data.pageUpdateTimeMobile)
          this.cacheDate = res.data
          localStorage.setItem('cacheTime', JSON.stringify(res.data.tenantConfig))

          const hotLottery = localStorage.getItem('hotLottery')
          if (!hotLottery || (hotLottery && this.cacheDate.hotLottery !== Object.keys(JSON.parse(hotLottery))[0])) {
            this.getHotListAPI()
          }
          // const lottery = localStorage.getItem('lotteryConfig')
          // if (this.cacheDate.allLotteryDataFront !== (lottery && Object.keys(JSON.parse(lottery))[0])) {
          //   this.getLotteryConfig()
          // } else {
          //   const lotteryConfig = Object.values(JSON.parse(lottery))[0]
          //   const areaConfig = []
          //   lotteryConfig.lotteryList.forEach(i => {
          //     if (areaConfig.includes(i.area)) {
          //       return
          //     }

          //     areaConfig.push(i.area)
          //   })

          //   const countryName = areaConfig.map((item) => ({ name: this.chineseNameTable[item], area: item }))
          //   const lotteryList = lotteryConfig.lotteryList || []
          //   if (this.hasHot) {
          //     this.countryList = [{ name: 'TRADE_HOT', area: 'hot' }, { name: 'INVEST_ALL', area: 'all' }].concat(countryName)
          //   }
          //   this.allList = lotteryConfig.lotteryList || []
          //   this.asiaList = lotteryList.filter((item) => item.area === 'Asia')
          //   this.europeList = lotteryList.filter((item) => item.area === 'Europe')
          //   this.northAmericaList = lotteryList.filter((item) => item.area === 'North America')
          //   this.southAmericaList = lotteryList.filter((item) => item.area === 'South America')
          //   this.oceaniaList = lotteryList.filter((item) => item.area === 'Oceania')
          //   this.virtualList = lotteryList.filter((item) => item.area === 'VirtualCurrency')
          // }
          this.getLotteryConfig()
        }
      })
    },
    changIndexHandler (value) {
      const list = Object.assign([], this.countryList)
      const listLength = list.length
      const carIndex = list.map(function (item) { return item.area }).indexOf(this.active)
      // 紀錄當前index
      if (value === 'r') {
        if (carIndex === listLength - 1) {
          return
        } else {
          this.active = `${list[carIndex + 1].area}`
        }
        this.changeTab()
      } else {
        if (carIndex === 0) {
          return
        } else {
          this.active = `${list[carIndex - 1].area}`
        }
        this.changeTab()
      }
    }

  }
  // beforeRouteLeave (to, from, next) {
  //   if (to.name !== 'tradeMainOrder') {
  //     this.$store.dispatch('actionSetTabActive', 0)
  //   }
  //   next()
  // }
}
</script>

<style lang="scss" scoped>
.trade-main-wrap {
  display: flex;
  overflow: hidden;
  width: calc(100% - 6px);
  height: 100%;
  // background-color: #162236;
  // z-index: 2;
  transition: all 0.3s;
  flex-direction: column;

  &.search_bar {
    z-index: 100;
  }

  .van-tab__pane {
    height: 100%;
  }

  &::v-deep {
    .van-tabs {
      position: relative;
      display: flex;
      overflow: hidden;
      background-color: transparent;
      box-shadow: transparent;
      flex: 1;
      flex-direction: column;
    }

    .van-tabs__content {
      overflow-y: auto;
      overflow-y: overlay;
      height: calc(100vh - 110px);
    }

    .van-tabs__line {
      bottom: 15px;
      width: 48px;
      height: 2px;
      background-color: var(--tradeMainTabLineBgc) !important;
      border-radius: 0 !important;
      z-index: 50;
    }

    .van-tabs__nav {
      padding: 0 0 15px;
      color: var(--tradeMainTabsNavColor);
      // background-color: #17253D;
      background-color: var(--tradeMainTabsNavBgc);
      scrollbar-width: none;
    }

    .van-tabs__wrap {
      padding-right: 20px;
      padding-left: 20px;
    }

    .van-tab {
      color: var(--tradeMainTabsNavColor);
      cursor: pointer;

      &:hover {
        color: var(--tradeMainTabsNavHoverColor);
      }
    }

    .van-tab--active {
      color: var(--tradeMainTabLineBgc) !important;
      cursor: default;
    }
  }
}

.loading-box {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.currency {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 1px 5px;
  font-size: 12px;
  color: #879AAF;
  background: #17253D 0% 0% no-repeat padding-box;
  border-radius: 2px;
  opacity: 0.8;
  z-index: 0;
  box-shadow: 0 1px 2px #00000026;
}

.main {
  /* height: 100%; */
  &__content {
    overflow-x: hidden;
    overflow-y: auto;
    overflow-y: scroll;
    height: calc(100% - 42px);
  }

  &__item {
    position: relative;
    display: flex;
    transition: 0.5s;

    &.active {
      background-color: var(--tradeMainItemActive);
      // opacity: 0.6;
    }

    &:hover {
      background-color: var(--tradeMainItemActive);
      cursor: pointer;
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    margin-top: 12px;
    margin-right: 12px;
    width: 40px;
    height: 40px;
    background-color: var(--tradeMainIconBgc);
    border-radius: 50%;
    flex: 0 0 40px;

    img {
      width: 36px;
      height: 36px;
    }
  }
}

.side1.cancel {
  .trade-main-wrap {
    &::v-deep {
      .van-tabs__nav {
        background-color: transparent;
        transition: 1s;
      }
    }
  }

  .main__item {
    &.active {
      background-color: transparent;
    }
  }
}
// search-bar

.trade__tabs {
  &::v-deep.van-tabs__nav {
    background-color: var(--tradeMainTabsNavColor2);
  }
}
//pc-set
.main__pctitle {
  display: flex;
  justify-content: space-between;
  padding-top: 16px;
  padding-right: 16px;
  padding-bottom: 8px;
  padding-left: 16px;
  font-size: 12px;
  color: var(--tradeMainPcTitleColor);
}

.change-btnwrap {
  position: absolute;
  left: 0;
  display: flex;
  justify-content: space-between;
  width: calc(100% - 6px);
  z-index: 11;
  pointer-events: none;
  border-bottom: var(--tradeMainPcBorderBottom);

  .btn-setting {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 22px;
    height: 44px;
    pointer-events: auto;

    &:hover {
      cursor: pointer;
    }
  }
}

.side1.cancel {
  .btn-setting {
    opacity: 0;
  }
}

.right-btn {
  .hide-icon {
    transform: rotate(-90deg);
  }

  .active-icon {
    transform: rotate(90deg);
  }

  &.hide {
    pointer-events: none;
  }
}

.left-btn {
  .hide-icon {
    transform: rotate(90deg);
  }

  .active-icon {
    transform: rotate(-90deg);
  }

  &.hide {
    pointer-events: none;
  }
}
</style>
