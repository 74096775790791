<template>
  <div class="title__group">
    <span class="tab__itemtitle double__width">{{ $t('PC_STOCK_INDEX') }}</span>
    <span class="tab__itemorder double__width">{{ $t('INVEST_PERIOD_NUMBER') }}</span>
    <span class="tab__itemtime double__width">{{ $t('INVEST_TIME') }}</span>
    <span class="tab__itemmoney base__width">{{ $t('INVEST_MONEY') }}</span>
    <span class="tab__itemtbones base__width">{{ $t('DISTRIBUTE_PROFIT') }}</span>
    <span
      class="tab__itemstate base__width"
      :class="$i18n.locale"
    >{{ $t('PC_STATE') }}</span>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.title__group {
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
  padding-right: 8px;
  padding-left: 16px;
  width: calc(100% - 6px);
  height: 40px;
  font-size: 12px;
  color: var(--accountingTitleColor);
  line-height: 17px;
  background-color: transparent;

  // &::after {
  //   position: absolute;
  //   width: 100%;
  //   height: 100%;
  //   background: #D6D6D6 0% 0% no-repeat padding-box;
  //   mix-blend-mode: multiply;
  //   content: '';
  //   z-index: -1;
  // }

  // &::before {
  //   position: absolute;
  //   width: 100%;
  //   height: 100%;
  //   background: #1B2A43;
  //   mix-blend-mode: multiply;
  //   content: '';
  //   z-index: -2;
  // }

  >.double__width {
    width: 20%;
  }

  >.base__width {
    width: 13.33%;
  }

}

.tab__itemmoney {
  position: relative;
  right: -3%;
}

.tab__itemtbones {
  position: relative;
  right: -5%;
}

.tab__itemstate {
  position: relative;
  right: -6.9%;

  &.vie {
    right: -5.8%;
  }
}
</style>
