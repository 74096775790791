<template>
  <div
    v-if="!checkEmpty"
    class="content"
  >
    <div class="title__group">
      <div class="header">
        <div class="icon">
          <img
            v-if="countryIconList.includes(oneItem.lotteryCode)"
            :src="getCountryicons(oneItem.countryCode)"
            alt=""
          >
          <img
            v-else
            :src="getSicons(oneItem.lotteryCode)"
            alt=""
          >
        </div>
        <div class="title">
          <!-- {{ oneItem.lotteryName }} -->
          {{ stockResMsg(`STOCK_${oneItem.lotteryCode}`,oneItem.lotteryName) }}
        </div>
      </div>
      <div
        class="value chase-state"
        :class="getState[1]"
      >
        <span v-if="getState[1]==='rise'">
          +  {{ deliveryProfit }}
        </span>
        <span v-else>
          {{ getState[0] }}
        </span>
      </div>
    </div>
    <div class="item-content">
      <div class="item">
        <div class="name">
          {{ $t('WAREHOUSE_RECORDCENTER_CHASENUMBER_CHASE_NUMBER_TITLE') }}
        </div>
        <div class="value">
          {{ $t('PC_START_DATE',{num:oneItem.startIssue}) }}
        </div>
      </div>
      <div class="item">
        <div class="name">
          {{ $t('WAREHOUSE_RECORDCENTER_CHASENUMBER_PLAY_TYPE') }}
        </div>
        <div class="value">
          {{ filterOption(playType) }}
        </div>
      </div>
      <div class="item">
        <div class="name">
          {{ $t('WAREHOUSE_RECORDCENTER_CHASENUMBER_CHASE_TIME_TITLE') }}
        </div>
        <div class="value">
          {{ oneItem.gmtCreated }}
        </div>
      </div>
      <div class="item">
        <div class="name">
          {{ $t('TRADE_CHAMPION') }}
        </div>
        <div class="value">
          {{ champioAndRunner[0] }}
        </div>
      </div>
      <div class="item">
        <div class="name">
          {{ $t('WAREHOUSE_RECORDCENTER_CHASENUMBER_CHASE_ORDER_TITLE') }}
        </div>
        <div class="value">
          {{ oneItem.recordCode }}
        </div>
      </div>
      <div class="item">
        <div class="name">
          {{ $t('TRADE_RUNNER') }}
        </div>
        <div class="value">
          {{ champioAndRunner[1] }}
        </div>
      </div>
      <div class="item">
        <div class="name">
          {{ $t('WAREHOUSE_RECORDCENTER_CHASENUMBER_CHASE_MONEY_TITLE') }}
        </div>
        <div class="value">
          {{ chaseMoney }}
        </div>
      </div>
      <div class="item">
        <div class="name">
          {{ $t('WAREHOUSE_RECORDCENTER_CHASENUMBER_BONUS_TITLE') }}
        </div>
        <div
          class="value sned-bones"
          :class="stateText[1]"
        >
          <span v-if="stateText[1]==='wait'">-</span>
          <span v-else>+ {{ deliveryProfit }}</span>
        </div>
      </div>
      <div class="item">
        <div class="name">
          {{ $t('WAREHOUSE_RECORDCENTER_CHASENUMBER_BET_TITLE') }}
        </div>
        <div class="value">
          {{ investmentAmount }}
        </div>
      </div>
    </div>
    <van-collapse
      v-model="activeNames"
      class="collapes__wrap"
    >
      <van-collapse-item name="1">
        <template#title>
          <div class="chase--stepwrap">
            <div
              class="step__info"
              :class="{show:activeNames.length===0}"
            >
              <div class="title">
                {{ $t('WAREHOUSE_RECORDCENTER_CHASENUMBER_CHASE_SCHEDULE') }}
              </div>
              <div class="order">
                {{ $t('WAREHOUSE_RECORDCENTER_CHASENUMBER_CHASE_END',{num:oneItem.completeCount}) }} / {{ $t('WAREHOUSE_RECORDCENTER_CHASENUMBER_CHASE_END_TEXT',{num:oneItem.total}) }}
              </div>
            </div>
          </div>
        </template#title>
        <div class="chase-step">
          <div
            class="chase-step__titlegroup"
            :class="$i18n.locale"
          >
            <div class="title order-set">
              {{ $t('PC_OUTGOING_INDEX') }}
            </div>
            <div class="title">
              {{ $t('WAREHOUSE_RECORDCENTER_CHASENUMBER_C_BET_TITLE') }}
            </div>
            <div class="title">
              {{ $t('PC_WAREHOUSE_NUMBER') }}
            </div>
            <div class="title">
              {{ $t('PC_PROFTI_AMOUNT') }}
            </div>
            <div class="title">
              {{ $t('PC_BET_STATUS') }}
            </div>
          </div>
          <div class="showScrollChaseNumber">
            <div
              v-for="(item) in oneItem.betOrderList"
              :key="item.id"
              class="scroll__item"
              :class="[{revoke:item.state===3},{complete:item.state===2||item.state===1}]"
              @click="childItemAction(item.id, $event)"
            >
              <ChildItem :child-item="item" />
            </div>
          </div>
        </div>
      </van-collapse-item>
    </van-collapse>
    <div class="radio__group">
      <div class="radio__item radio__item-set">
        <div class="item__title">
          {{ $t('TRADE_PROFIT_STOP') }}
        </div>
        <div class="item__info">
          <van-switch
            :value="oneItem.isStopAfterWinning"
            :disabled="!(oneItem.autoBuyIfStopAfterWinning && oneItem.state < 2)"
            @change="changeChaseMode"
          />
        </div>
      </div>
      <div class="radio__item">
        <div class="item__title">
          {{ $t('TRADE_PROFIT_CYCLE') }}
        </div>
        <div class="item__info">
          <van-switch
            :value="oneItem.autoBuyIfStopAfterWinning"
            disabled
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getCountryicons,
  getSicons,
  countryIconList,
  specialIcons,
  stockResMsg
} from '@/utils'
import lotteryAPI from '@/api/lottery'
import ChildItem from './ChaseChildItem.vue'
export default {
  components: {
    ChildItem
  },
  props: {
    oneItem: {
      type: Object,
      required: true
    },
    slideItem: {
      type: String,
      default: () => ''
    },
    // eslint-disable-next-line vue/require-default-prop
    revokeId: {
      type: [String, Number],
      required: true
    }
  },
  data () {
    return {
      countryIconList,
      specialIcons,
      activeNames: ['1']
    }
  },
  computed: {
    currentTag () {
      return this.$store.state.currentTag
    },
    checkEmpty () {
      const empty = Object.keys(this.oneItem).length === 0
      return empty
    },
    getState () {
      if (this.oneItem.completeCount === this.oneItem.total) {
        if (this.oneItem.allBonus > 0) {
          return [this.$t('INVEST_PROFITABLE'), 'rise']
        } else {
          return [this.$t('INVEST_UNPROFITABLE'), 'fall']
        }
      } else {
        if (this.oneItem.allBonus > 0) {
          return [this.$t('INVEST_STOP_PROFIT'), 'stop']
        } else if (this.oneItem.state === 0) {
          return [this.$t('INVEST_WAITING_RELEASED'), 'pending']
        } else {
          return [this.$t('PROCESSING'), 'ing']
        }
      }
    },
    playType () {
      const val = this.oneItem.chaseScheme[0]
        ? this.oneItem.chaseScheme[0].playName
        : ''
      return val
      // return this.filterOption(val)
    },
    // 投資金額
    investmentAmount () {
      return this.oneItem.chaseMoney.toFixed(2)
    },
    // 派送盈利
    deliveryProfit () {
      return this.oneItem.allBonus.toFixed(2)
    },
    chaseMoney () {
      return Number(this.oneItem.completeMoney).toFixed(2)
    },
    champioAndRunner () {
      return this.oneItem.chaseScheme[0]
        ? this.oneItem.chaseScheme[0].betNum.split(',')
        : [0, 0]
    },
    stateText () {
      switch (this.oneItem.state) {
        case 0:
          return [this.$t('WAREHOUSE_CHASE_NUMBER_ITEM_WAIT'), 'wait']
        case 1:
          return [this.$t('WAREHOUSE_RECORDCENTER_CHASENUMBER_UNPROFITABLE'), 'unprofitable']
        case 2:
          return [this.$t('INVEST_PROFITABLE'), 'profitable']
        case 3:
          return [this.$t('INVEST_ORDER_CANCELLED'), 'cancelled']
        case 4:
          return [this.$t('INVEST_STOP_PROFIT'), 'stop']
        case 5:
          return [this.$t('INVEST_ORDER_CANCELLED'), 'cancelled']
        default:
          return [this.$t('IN_PROCESS'), 'ing']
      }
    }
  },
  methods: {
    stockResMsg,
    getSicons,
    getCountryicons,
    filterOption (val) {
      let option = val.replace('小数,', '')
      const optionTable = {
        涨: this.$t('TRADE_UP'),
        跌: this.$t('TRADE_DOWN'),
        单股: this.$t('TRADE_SINGLE_STRAND'),
        双股: this.$t('TRADE_DOUBLE_STRAND'),
        牛: this.$t('TRADE_BULL_MARKET'),
        熊: this.$t('TRADE_BEAR_MARKET'),
        定位胆: this.$t('TRADE_POSITIONING'),
        双面盘: this.$t('TRADE_DOUBLE_SIDE')
      }

      Object.keys(optionTable).forEach((item) => {
        option = option.replace(item, optionTable[item])
      })
      return option
    },
    async childItemAction (idValue, event) {
      this.childAction = true
      if (this.slideItem !== this.currentTag) {
        event.stopPropagation()
      } else {
        this.$emit('updateChild')
      }
      await lotteryAPI.getBetDetail({ id: idValue }).then((res) => {
        if (res.code === 1) {
          const apiData = res.data
          // this.checkChildempty = Object.keys(apiData).length !== 0 // 子曾資料不等於0
          // this.childChampionArray = res.data.betInfoList[0].betNum.split(',')// 冠亚军
          this.$emit('openChild', apiData, idValue)
        }
      })
    },
    changeChaseMode () {
      this.msgAlertBig(this.$t('WAREHOUSE_RECORDCENTER_CHASENUMBER_CHANGE_PLAY_TYPE_MSG'), this.$t('CONFIRM'), this.$t('CANCEL')).then(() => {
        const param = { chaseOrderId: this.revokeId }
        // this.msgLoading('正在撤单...')
        lotteryAPI.cancelAutoChaseBetting(param).then(res => {
          if (res.code === 1) {
            this.$emit('changePlayType')
            // this.propsChaseNumberData.autoBuyIfStopAfterWinning = false
            // this.propsChaseNumberData.isStopAfterWinning = true
          }
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.title__group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 20px;
  padding-left: 20px;
  margin-bottom: 15px;

  .icon {
    display: flex;
    justify-content: center;
    margin-right: 8px;
    width: 20px;
    height: 20px;

    >img {
      object-fit: fill;
    }
  }

  .title {
    font-size: 16px;
    color: var(--popupTitleColor);
    line-height: 22px;
  }

  .header {
    display: flex;
  }
}

.item-content {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding-right: 20px;
  padding-left: 20px;
  flex-wrap: wrap;
  margin-bottom: 12px;

  >.item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 48%;
    height: 24px;
    font-size: 12px;
    line-height: 17px;

    .name {
      color: var(--popupNameColor);
    }

    .value {
      color: var(--popupValueColor);
    }
  }
}

.rovoke__btns {
  display: flex;
  justify-content: flex-end;

  >.btn {
    width: 72px;
    height: 24px;
    font-size: 14px;
    text-align: center;
    border-radius: 4px;
    line-height: 20px;

    &:hover {
      cursor: pointer;
    }

    &.cancel {
      color: var(--popupChaseRevokeBtnCancelColor);
      border: var(--popupChaseRevokeBtnCancelBorder);
    }

    &.revoke {
      margin-left: 8px;
      color: var(--popupChaseRevokeBtnRevokeColor);
      background-color: var(--popupChaseRevokeBtnRevokeBgc);
    }
  }
}

.collapes__wrap {
  &::after {
    display: none;
  }

  &::v-deep {
    .van-cell {
      padding: 10px 20px;
      font-size: 12px;
      color: var(--popupChaseCollapseVanCellColor);
      background-color: transparent;

      &::after {
        display: none;
      }
    }

    .van-collapse-item__content {
      padding: 0;
      font-size: 12px;
      color: var(--popupChaseCollapseVanItemContentColor);
      background-color: transparent;
    }

    .van-collapse-item__title {
      position: relative;
      // border: 1px solid #F00;

      &::before {
        position: absolute;
        top: 0;
        left: 50%;
        width: calc(100%);
        height: 1px;
        background-color: var(--popupChaseCollapseVanTitleContentBgc);
        content: '';
        transform: translateX(-50%);
      }
    }

    .van-collapse-item__wrapper {
      display: block !important;
      transition-duration: 0.1s;
    }
  }
}

.step__info {
  position: relative;
  display: flex;
  justify-content: space-between;
  color: var(--popupChaseStepInfoColor);
  // border: 1px solid #F00;

  // &.show {
  //   &::after {
  //     position: absolute;
  //     bottom: -6px;
  //     left: 0;
  //     width: calc(100% + 20px);
  //     height: 1px;
  //     background-color: var(--popupChaseStepInfoShowBgc);
  //     content: '';
  //   }
  // }
  // border: 1px solid #000;

}

.chase-step {
  background-color: var(--popupChaseChaseStepBgc);

  &__titlegroup {
    display: flex;
    align-items: center;
    overflow-x: hidden;
    padding-right: 20px;
    padding-left: 20px;
    height: 33px;
    font-size: 12px;

    >div {
      position: relative;
      right: -6%;
      // flex: 1;
      width: 18.75%;
    }

    .order-set {
      position: relative;
      right: 0%;
      width: 25%;
    }

    &.vie {
      >div {
        right: -4%;
      }

      .order-set {
        right: 0;
      }
    }
  }
}

.scroll__item {
  display: flex;
  align-items: center;
  height: 24px;
  cursor: pointer;

  &:hover {
    background-color: #1E2E48;
  }

  &.complete {
    .issue {
      color: var(--popupChaseIssueColor);
    }
  }

  .opennum {
    &.haveValue {
      color: var(--popupChaseHaveValueColor);
    }
  }

  >div {
    flex: 1;
  }
}

.showScrollChaseNumber {
  overflow-y: scroll;
  height: 145px;
  // border: 1px solid #000;
}

.chase-state {
  color: var(--popupNameColor);
  -webkit-text-stroke: 0.3px;

  &.pending {
    color: var(--popupChaseStatePendingColor) !important;
  }

  &.fall {
    color: var(--popupChaseStateFallColor) !important;
  }

  &.rise {
    color: var(--popupChaseStateRiseColor) !important;
  }

  &.ing {
    color: var(--popupChaseStateIngColor) !important;
  }
}

.sned-bones {
  color: var(--popupNameColor);

  &.wait {
    color: var(--popupNameColor) !important;
  }
}

.radio__group {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 20px;
  height: 48px;
  font-size: 12px;
  border-top: var(--popupRadioBorder);
}

.radio__item {
  display: flex;
  align-items: center;

  &-set {
    margin-right: 16px;
  }

  .item__title {
    margin-right: 8px;
    color: var(--popupChaseRadioItemTitleColor);
  }

  &::v-deep {
    .van-switch {
      font-size: 17px;
      background-color: var(--popupChaseRadioItemVanSwitchBgc);
    }

    .van-switch--on {
      background-color: var(--popupChaseRadioItemVanOnSwitchBgc);
    }

    .van-switch__node {
      top: 1px;
      // width: 0.9em;
      // height: 0.9em;
      width: 15px;
      height: 15px;;
    }
  }
}
</style>
