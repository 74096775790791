<template>
  <div
    v-if="propsShowHide"
    class="def__wrap pop__area"
  >
    <div
      class="close__btn"
      @click="closePop"
    >
      <img
        src="/static/images/inmoney/close-icon.svg"
        alt=""
      >
    </div>
    <div class="pop__titlearea">
      <h2 class="pop__title">
        {{ propsOneDefData.dictionName }}
      </h2>
      <p
        class="pop__info"
        :class="[useClassColor(propsOneDefData.inOut)]"
      >
        <span>{{ propsOneDefData.inOut ? '-' : '+' }}</span>
        <span>{{ propsOneDefData.amount }}</span>
      </p>
    </div>
    <div class="pop_wrap">
      <div class="pop__items">
        <div class="pop__item recordcode-set">
          <h3 class="item__title">
            {{ $t('DEAL_SERIAL_NUMBER') }}
          </h3>
          <input
            ref="defRecordCode"
            class="show show-set item__info"
            :value="propsOneDefData.recordCode"
            readonly
            autocomplete="off"
          >
          <button
            class="copy"
            @click="copyRecordCodeFn('defRecordCode')"
          >
            {{ $t('COPY') }}
          </button>
        </div>
        <div class="pop__item">
          <h3 class="item__title">
            {{ $t('DEAL_TIME') }}
          </h3>
          <p class="item__info">
            {{ propsOneDefData.gmtCreated }}
          </p>
        </div>
        <div class="pop__item">
          <h3 class="item__title">
            {{ $t('DEAL_CASH') }}
          </h3>
          <p
            class="item__info"
            :class="[useClassColor(propsOneDefData.inOut)]"
          >
            <span>{{ propsOneDefData.inOut ? '-' : '+' }}</span>
            <span>{{ propsOneDefData.amount }}</span>
          </p>
        </div>
        <div class="item-line" />
        <div class="pop__item">
          <h3 class="item__title">
            {{ $t('DEAL_BALANCE') }}
          </h3>
          <p class="item__info">
            {{ propsOneDefData.afterBalance }}
          </p>
        </div>
        <div class="pop__item">
          <h3 class="item__title">
            {{ $t('DEAL_REMARK') }}
          </h3>
          <p class="item__info">
            {{ propsOneDefData.remark }}
          </p>
        </div>
      </div>
    </div>
    <button
      class="close__popbtn"
      @click="closePop"
    >
      {{ $t('WAREHOUSE_ACCOUNT_WITHDRAW_CANCEL_BTN_TEXT') }}
    </button>
    <!-- <VantNotify :open-notify="vuexNotify" :message-type="vuexNotifyType" /> -->
  </div>
</template>

<script>
export default {
  components: {
  },
  props: {
    propsShowHide: {
      type: Boolean,
      required: true
    },
    propsOneDefData: {
      type: Object,
      default: function () {
        return {}
      }
    },
    fatherMethod: {
      type: Function,
      default: null
    }
  },
  methods: {
    closePop () {
      this.$emit('close-pop', false)
    },
    useClassColor (value) {
      return value === 0 ? 'rice' : value === 1 ? 'fall' : 'def'
    },
    copyRecordCodeFn (val) {
      this.$refs[val].select()
      document.execCommand('copy')
      this.$store.commit('switchNotify', { show: true, type: 'record' })
      this.closeNotify('record')
    }
  }
}
</script>

<style lang="scss" scoped>
.rice {
  color: var(--popupChaseDefRiseColor);
}

.fall {
  color: var(--popupChaseDefFallColor);
}

.def__wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pop_wrap {
  flex: 1;
  width: 100%;
}

.pop__items {
  .item__info {
    &.rice {
      color: var(--popupChaseDefRiseColor);
    }

    &.fall {
      color: var(--popupChaseDefFallColor);
    }
  }
}

.recordcode-set {
  > .item__info {
    text-align: right;
    background-color: transparent;
    border: 0;
    flex: 1;
  }

  .copy {
    margin-left: 4px;
    padding: 0;
    // color: var(--primaryColor);
    background-color: transparent;
    border: 0;
  }
}
</style>
