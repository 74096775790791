import { render, staticRenderFns } from "./ChaseNumber.vue?vue&type=template&id=2dd8c346&scoped=true"
import script from "./ChaseNumber.vue?vue&type=script&lang=js"
export * from "./ChaseNumber.vue?vue&type=script&lang=js"
import style0 from "./ChaseNumber.vue?vue&type=style&index=0&id=2dd8c346&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2dd8c346",
  null
  
)

export default component.exports