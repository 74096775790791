export default {
  ja: {
    DEPOSIT: '入金',
    IMMEDIATE_LOGIN: 'ログイン',
    SING_OUT: 'ログアウト',
    ACCOUNT_USER: 'ユーザー',
    WITHDRAWAL: '出金',
    CANCEL: 'キャンセル',
    FINISH: '完了',
    CONFIRM: '確認',
    CONFIRM2: '確 認',
    CONTINUE: '続行',
    CLOSE: '閉じる',
    SUBMIT: '送る',
    SEARCH: '検索',
    PROCESS_DONE: '操作が成功しました。',
    WAIT_A_MINUTE: '少々お待ちください',
    FORGOT_PASSWORD: 'パスワードを忘れる',
    COPY: 'コピー',
    PLEASE_AGAIN: 'もう一度お試しください',
    RE_ENTER: 'もう一度入力してください',
    YES: 'はい',
    NO: 'いいえ',
    SHOW_ALL: '全てのデータを表示しました。',
    NEW_MESSAGE: '新しいデータがこちらで表示',
    NO_RECORD: 'データなし',
    PASSWORD: 'パスワード',
    UNLOCK: 'ロックしていません',
    DISABLED: '禁止されています',
    LOCKED: 'ロック中',
    RESTRICTED: '制限されています',
    REVISE: '変更',
    REMARK: '備考',
    DELETE: '削除',
    PASSWORD_ERROR: 'パスワードが間違っています。',
    ACCOUNT_ANNOUNCEMENT: 'アナウンス',
    YOUR_TEAM_BANNED: 'あなたのグループはトーク禁止がされました',
    YOUR_TEAM_BANNED_FOREVER: 'トーク禁止が永久にされました',
    YOUR_TEAM_BANNED_ONEDAY: 'トーク禁止が1日されました',
    YOUR_TEAM_BANNED_ONEWEEK: 'トーク禁止が1週間されました',
    YOUR_TEAM_BANNED_ONEHOUR: 'トーク禁止が1時間されました',
    YOUR_TEAM_BANNED_HALFHOUR: 'トーク禁止は30分にられました',
    CONGRATULATION: 'おめでとう',
    FLLOW_DATE: '%{num}期を追跡した',
    INJECTION: 'つ',
    PERSON: '人',
    // 用戶
    ACCOUNT_PERSONAL_INFORMATION: 'プロフィール',
    ACCOUNT_PROFIT_LOSS_TODAY: '本日利益',
    ACCOUNT_AGENCY_CENTER: '代理センター',
    ACCOUNT_MESSAGE_CENTER: 'メッセージ',
    ACCOUNT_HELP_GUIDE: 'ヘルプセンター',
    ACCOUNT_GUIDE_MEMBER: 'アカウントガイド',
    ACCOUNT_INVESTMENT_GUIDE: '投資ガイド',
    ACCOUNT_ABOUT_US: '私達について',
    ACCOUNT_MY_ACCOUNT: '私のアカウント',
    ACCOUNT_OVERAGE: '残高',
    ACCOUNT_MANAGEMENT: 'セキュリティー',
    ACCOUNT_VIRTUAL_WALLET: 'ウォレット',
    ALERT_NETWORK_ERROR_TEXT: 'インターネットに接続されていないので、再読み込みください',
    ACCOUNT_NEW_MESSAGE: '新しいメッセージはこちらで表示',
    ACCOUNT_PRIVATE_LETTER: 'メッセージ',
    // 交易頁面用可能會有共用
    TRADE_HOT: '人気',
    TRADE_ASIA: 'アジア株式市場',
    TRADE_EUROPE: 'ヨーロッパ株式市場',
    TRADE_NORTH_AMERICA: '北米株式市場',
    TRADE_SOUTH_AMERICA: '南米株式市場',
    TRADE_OCEANIA: 'オセアニア株式市場',
    TRADE_VIRTUALCURRENCY: '仮想通貨',
    TRADE_STOP_TRADING: '売買停止',
    TRADE_EXPECT: '期',
    TRADE_UNIT: '単位',
    TRADE_NUMBERPERIODS: '期数',
    TRADE_DOUBLED: '倍増',
    TRADE_TIPS: 'リマインド',
    TRADE_TOTAl: '総計',
    TRADE_STOCK: '株',
    TRADE_DOLLAR: '円',
    TRADE_MAX_INCOME: '最高収益',
    TRADE_CONTINUE_INVEST: '投資を続ける',
    TRADE_SHARE_ORDER: 'シェア',
    TRADE_BUY: '取引',
    TRADE_OPEN: '当選',
    TRADE_SHIPPED: '発注中',
    TRADE_OPEN_TODAY: '現在値',
    TRADE_PRICING: '騰落率',
    TRADE_PRICING2: '騰落',
    TRADE_VOLUME: '出来高',
    TRADE_MAX: '高値',
    TRADE_MIN: '安値',
    TRADE_ODD: '奇',
    TRADE_DOUBLE: '偶',
    TRADE_CHAMPION: '優勝',
    TRADE_FALL: 'オッズ',
    TRADE_RUNNER: '準優勝',
    TRADE_PAR_VALUE: '1株の取引価格',
    TRADE_MULTIPLE: '倍数',
    TRADE_CHASE_NUMBER: '追跡',
    TRADE_PROFIT_STOP: '収益後止し',
    TRADE_PROFIT_CYCLE: '収益循環',
    TRADE_DOUBLE_SIDE: 'ダブルサイド',
    TRADE_POSITIONING: 'ポジショニング',
    TRADE_SINGLE_STRAND: '奇数株',
    TRADE_DOUBLE_STRAND: '偶数株',
    TRADE_BULL_MARKET: 'ブル',
    TRADE_BEAR_MARKET: 'ベア',
    TRADE_INVESTING: '投資しています',
    TRADE_INSUFFICIENT_BALANCE: '残高が不足しています',
    TRADE_TAKE_A_BEATING: '投資が失敗しました。',
    TRADE_I_SEE: '分りました',
    TRADE_ACTION_SHEET_PLAYTYPE_TEXT: 'チュートリアル',
    TRADE_UP: '上昇',
    TRADE_DOWN: '下落',
    TRADE_DALLAR: '円',
    TRADE_DIME: 'ダイム',
    TRADE_CENT: 'セント',
    TRADE_INPUTBASE: '単位 : 円',
    TRADE_UNCHANGED: 'タイ',
    TRADE_MOST: '最大',
    TRADE_TIMES: '倍まで',
    TRADE_LIST_TAB_TITLE_BASE: '一般履歴',
    TRADE_LIST_TAB_TITLE_CHASE: '追跡履歴',
    TRADE_MOST_EXPECT: '最多%{num}期',
    TRADE_CHASE_START_ISSUE: '追跡期号',
    TRADE_CHASE_COUNT: '追跡数量',
    PC_ACCOUNT_BANK_CARD: 'カード',
    PC_DATE_MULT: '%{chase} 期 %{mult} 倍',
    TRADE_CHASE_EXPECTED_PRIZE_TIME: '発注予定時間',
    TRADE_CHASE_CONFIRM: '追跡注文確認',
    //
    HOME_ICONBTN_BONUS: '損益',
    HOME_ICONBTN_GUIDE: 'ガイド',
    HOME_ICONBTN_RANK: 'ランキング',
    HOME_ICONBTN_HOT: 'おすすめ',
    HOME_ICONBTN_APP: 'アプリ',
    HOME_ICONBTN_SERVICE: 'お問い合わせ',
    HOME_NEWS_TITLE: '国際経済ニュース',
    HOME_NEWS_MOREBTN: 'もっとニュースを読む',
    HOME_NEWS_DEF_TITLE1: '股价遭低估！券商看好货柜船竞争力、日3大海运股齐飙',
    HOME_NEWS_DEF_SOURCE1: 'MoneyDJ新闻',
    HOME_NEWS_DEF_TITLE2: '股价遭低估！券商看好货柜船竞争力、日3大海运股齐飙',
    HOME_NEWS_DEF_SOURCE2: 'MoneyDJ新闻',
    HOME_NEWS_DEF_TITLE3: '股价遭低估！券商看好货柜船竞争力、日3大海运股齐飙',
    HOME_NEWS_DEF_SOURCE3: 'MoneyDJ新闻',
    HOME_STOCK_TITLE: 'おすすめの人気株',
    HOME_GETNAME_TEXT: '指数',
    HOME_GETUSERINFO_TEXT: '只今データが更新しました。今のアカウントは',
    HOME_GETNOTICELIST_TEXT: 'ようこそ%{msg}へ',
    /**
     *APP下載頁面
     */
    HOME_APPDOWNLOAD_HEADER_TITLE: 'アプリをダウンロード',
    HOME_APPDOWNLOAD_MAINTEXT: 'アプリをダウンロードしたら、URL入力することがいりません。',
    HOME_APPDOWNLOAD_ANDROID_BTN: 'Android APPダウンロード',
    HOME_APPDOWNLOAD_IOS_BTN: 'IOS APPダウンロード',
    HOME_APPDOWNLOAD_IOSDISKTOP_BTN: 'IOS パソコン ダウンロード',
    HOME_APPDOWNLOAD_PROMPT: '一部のブラウザがインストールができないかもしれないので、Safariでインストールする方がおすすめします。',
    HOME_APPDOWNLOAD_PROMPT_TEXT: 'クリークでインストール',
    HOME_APPDOWNLOAD_ACTION_IOSTITLE: 'IOSアプリ',
    HOME_APPDOWNLOAD_ACTION_ANDROIDTITLE: 'Androidアプリ',
    HOME_APPDOWNLOAD_ACTION_VERSION: 'バージョン',
    HOME_APPDOWNLOAD_TEXT: '又は',
    HOME_APPDOWNLOAD_IOS_STEP_TEXT: 'インストール完了',
    /**
     *排行版頁面
     */
    HOME_RANK_TITLE: '収益ランキング TOP10',
    HOME_RANK_TEXT1: '昨日の1日中で、最も高い利益ユーザーをカウント、今日',
    HOME_RANK_TEXT2: '05:00-06:00更新',
    HOME_RANK_USER_TITLE: 'タイトル',
    HOME_RANK_POPUP_CUMULATIVE_PROFIT: '累計利益',
    HOME_ANNOUNCEMENT_TEXT: '詳細',
    /**
     *交易頁面
     */
    TRADE_STOCK_INPUT_SEARCH: '銘柄名を入力してください',
    TRADE_STOCK_TRADEMAIN_RECORD_BTN: '取引履歴',
    TRADE_STOCK_TRADEMAIN_BUY_BTN: '注文',
    TRADE_STOCK_POPUP_SUCCESSFUL_TEXT1: ' 投資が成功しました。',
    TRADE_STOCK_POPUP_SUCCESSFUL_TEXT2: '注文をシェアする又は',
    TRADE_STOCK_CHART_TODAY_K_BTN: '日足',
    TRADE_STOCK_CHART_WEEK_K_BTN: '週足',
    TRADE_STOCK_CHART_MOON_K_BTN: '月足',
    TRADE_STOCK_TIMEBAR_STAES_CLOSED: '休業日',
    TRADE_STOCK_TIMEBAR_ORDER_ERROR: '期間番号が間違っています。',
    TRADE_STOCK_TIMEBAR_ORDER_ERROR1: '期間番号エラー',
    TRADE_STOCK_LATESTRESULT_NOTIMSG1: '注意：',
    TRADE_STOCK_LATESTRESULT_NOTIMSG2: '期号%{msg} が締め切りました。今の番号は',
    TRADE_STOCK_SETKTYPE_TITLE: '平安指数历史股价',
    TRADE_STOCK_CONFIRMBET_ERROR: '投資の種類が検証エラー',
    TRADE_STOCK_CONFIRMBET_TYPE_ERROR_REFRESH: '投資の種類に変更があり、投資に失敗しました。ページはまもなく更新されます。',
    TRADE_STOCK_CONFIRMBET_UNIT_ERROR_REFRESH: '投資に失敗しました。ページはまもなく更新されます。',
    TRADE_STOCK_ACTIVE_CHAMPION_STATE: 'すみませんが、優勝と準優勝はそれぞれ6個まで番号が選べます。',
    TRADE_STOCK_NUMERIC_KEYBOARD_ALERT_TEXT: '最大の投資額は',
    TRADE_STOCK_CONFIRMHANDLER_TYPE1_TEXT: '優勝者、準優勝者のポジションから最少1つの番号、最多各6つの番号を選び、選んだ番号は同じポジションで当選番号と一致しています。',
    TRADE_STOCK_CONFIRMHANDLER_TYPE1_TITLE: 'ポジショニングルール',
    TRADE_STOCK_CONFIRMHANDLER_TYPE0_TEXT: '取引ルール：市場指数の値の小数点以下2桁に基準して、1桁目は優勝番号、2桁目は準優勝番号です。4より大きい（4を含まない）場合は「上昇」、4より小さい（4を含む）場合は「下落 」、 奇数の場合は 「奇」、偶数の場合は「偶」となります。優勝が準優勝より大きい場合は「ブル」となり、小さい場合は「ベア」となり、優勝と準優勝が同じ数字の場合は「タイ」となり、資本金が返金されます。当期は、上昇/下降、奇/偶、ブル/ベアの両方と同時に投資することはできません。',
    TRADE_STOCK_CONFIRMHANDLER_TYPE0_TITLE: 'ダブルサイドルール',
    TRADE_STOCK_NOTIFY_TEXT: '%{type} %{bet} つ %{power}倍 %{chase}期を追跡した ¥{bettingAllAmount}',
    TRADE_STOCK_MAX_CHASE_NUMBER: '追跡できる最大数',
    TRADE_STOCK_RIGHT_TEXT: '右上の左向き矢印をクリック、投資履歴が表示されます',
    TRADE_STOCK_NO_LOGIN: 'ユーザーがログインしていません',
    TRADE_STOCK_STOP_SELL: '売買停止',
    TRADE_STOCK_IS_STOP: '0123456789预休出等',
    TRADE_STOCK_NOW_CLOSE_ORDER: '当期がクローズドされました',
    TRADE_STOCK_NOW_WAIT_OPEN_ORDER: '抽選を待つ',
    /**
     *銀行卡
     */
    NOBIND_SAFEPASSWORD_AND_BANKCARD: 'セキュリティコード設定とカード連携まだしていませんので、出金できません。セキュリティコードを設定にしませんか。',
    NOBIND_BANKCARD: 'カード連携をまだしていませんので、出金できません。カード連携にしませんか。',
    // 個人資料
    PERSONAL_RANK_TITLE: 'タイトル',
    PERSONAL_SAFE_FINISH: 'セキュリティー認証を完成しました。',
    PERSONAL_SAFTEY_NOT_COMPLETED: 'セキュリティー認証は未完成です。',
    PERSONAL_NICK_NAME: 'ニックネーム',
    PERSONAL_LOADING: '読み込み中',
    PERSONAL_ACCOUNT: 'ログインID',
    PERSONAL_PHONE: '携帯電話番号',
    PERSONAL_QQ: 'QQアカウント',
    PERSONAL_NO_DATA: 'データなし',
    PERSONAL_NO_BIND: '連携なし',
    PERSONAL_NO_SETING: '未設定',
    PERSONAL_SEX: '性別',
    PERSONAL_BIRTHDAY: '誕生日',
    PERSONAL_THEME: 'テーマ',
    LANGUAGE: '言語',
    JAPANESE: '日本語',
    CHINESE: '中国語',
    VIETNAMESE: 'ベトナム語',
    PERSONAL_SET_NICK_NAME: 'ニックネームを設定',
    PERSONAL_SET_QQ: 'QQアカウントを設定',
    PERSONAL_SET_PHONE: '携帯電話番号を設定',
    PERSONAL_NOT_SET: '設定しない',
    PERSONAL_MAN: '男',
    PERSONAL_WONAN: '女',
    PERSONAL_OTHER: '他',
    PERSONAL_DARK: 'ダークモード',
    PERSONAL_LIGHT: 'ライトモード',
    PERSONAL_SET_IMG: 'プロフィール写真を追加',
    PERSONAL_CHOESE_IMG: 'プロフィール写真を選択',
    PERSONAL_SAVE: '保存',
    SAFE_PROCESS: '認証の流れ',
    SAFE_IDENTITY_CONFIRM: 'ご本人の確認',
    SAFE_MESSAGE: 'AML／KYCポリシーに従って、資金の安全を確保し、引き出しをスピードアップするために、検証プロセスに認証する必要があります。',
    SAFE_LOGING_PASSWORD: 'ログインパスワードを設定',
    SAFE_PASSWORD: 'セキュリティコードを設定',
    SAFE_SECRET_SECURITY: 'セキュリティ保護問題を設定',
    SAFE_GO_SAFE_CENTER: 'セキュリティセンターへ',
    // 認證流程-action
    ACTION_NEW_PASSWORD: '新しいセキュリティコードをご設定ください',
    ACTION_MESSAGE_DOT: 'セキュリティコードは6桁で、出金、セキュリティの設定、カードの追加などの操作に使い、資金を保護することができます。',
    ACTION_PASSWORD_NOT_SIMPLE: 'セキュリティコードは単純すぎる文字列を設定してはいけません。123456、123123、654321、1111111などの単純なコードを設定してはいけません。',
    ACTION_ENTER_OLD: '現在のセキュリティコードを入力してください。',
    ACTION_NOT_SAME: 'セキュリティコードが一致しません。',
    ACTION_MESSAGE: 'セキュリティコードは6桁で、出金、セキュリティの設定、カードの追加などの操作に使い、資金を保護することができます。',
    ACTION_SIX_PASSWORD: '新しい6桁のパスワード',
    ACTION_OLD_PASSWORD: '旧6-16桁パスワード',
    ACTION_CONFIRM_PASSWORD: '操作 - セキュリティコードの認証',
    ACTION_SURE_UPDATE: 'セキュリティコードの変更を諦めますか。',
    ACTION_NOT_SET_PASSWORD: 'セキュリティコードはまだ設定していないので、まずセキュリティコードをご設定ください。',
    ACTION_AGAIN_CONFIRM: 'もう一度セキュリティコードを入力してください。',
    ACTION_VERIFY_ID: 'ご本人認証のために、セキュリティコードを入力してください。',
    // 等級頭銜
    RANK_GROWTH_POINTS: '成長点数',
    RANK_PROMOTION_INSTRUCTIONS: 'レベルアップ説明',
    RANK_CAPTION_BEFORE: '次のレベルアップ条件',
    RANK_CAPTION_AFTER: '点、1円で入金すると、1点になります。',
    RANK_GRADE: 'レベル',
    RANK_MECHANISM: 'レベルルール',
    // 福利活動
    LEVELUP_PRIZE: 'レベルアップボーナス',
    DAILY_PRIZE: '毎日ボーナス',
    SIGNIN_PRIZE: 'チェックインボーナス',
    PRIZE_GOTTEN: 'ボーナスを獲得しました。',
    AWARD_GOTTEN: 'ボーナスを獲得',
    TODAY_PRIZE: '今日のボーナス',
    IMMEDIATE_GET: '今すぐ受け取ります',
    CANT_GET: '受け取ることができません',
    ALREADY_GET: '受け取りました',
    YESTERDAY_INVESTMENT: '昨日の投資',
    CURRENT_GRADE: '今のレベル',
    ADDITIONAL_RATE: 'ボーナスプラス比例',
    GET_SUCCESSFULLY: '受取が成功しました。',
    ACTIVITY_NOTE: 'イベントの説明',
    DAILY_NOTE_1: '毎日ボーナスプラスは午前00:20以降に受取が可能です。',
    DAILY_NOTE_2: '会員のレベルと、昨日までの累積ベット額に応じて、ボーナスの一定比例が加算されます。',
    DAILY_NOTE_3: 'キャンセルやその他の無効なベットはカウントされません。',
    DAILY_NOTE_4: '注文取消やその他の無効なベットはカウントされません。',
    PROMOTION_RULES: 'レベルアップルール',
    PROMOTION_NOTE_1: 'レベルアップの報酬があります。',
    PROMOTION_NOTE_2: '成長点数イコールチャージの金額',
    ACCUMULATED_POINTS: '成長点数',
    PROMOTION_PRIZE: 'ノーマル賞',
    SKIP_PRIZE: 'スキップ賞',
    LEVELUP_PRIZE_NOTE: 'レベルアップの報酬があります。',
    DAILY_PRIZE_NOTE: '毎日ボーナスプラスは、メンバーが昨日ベット額に応じて支給され、ボーナスの上限がありません。',
    SIGNIN_PRIZE_NOTE: '毎日チェックインして、より多くの特典やオファーをお楽しみください',
    NOT_AVAILABLE_YET: '獲得条件はまだ達していません。',
    NOT_SIGNIN_YET: 'チェックインまだしていません。',
    ACTIVITY_OVER: 'イベント終了',
    ALREADY_SIGNIN: 'チェックインしました。',
    NOT_FINISHED_YET: 'まだ目標を達成していません。',
    IMMEDIATE_SIGNIN: '今すぐチェックイン',
    SIGNIN_TOTAL_DAYS: 'チェックイン日数',
    SIGNIN_PRIZE_REMINDER: '30日間連続でログイン、利益倍増になる機会がある',
    SIGNIN_RULES: 'チェックインルール',
    SIGNIN_DAYS: 'チェックイン日数',
    SIGNIN_BONUS: 'チェックインボーナス',
    SIGNIN_INVESTMENT_NEEDED: '条件：投資必要',
    DAY_NUMBER: '第 {number} 日',
    DAILY_SIGN_IN: '毎日チェックイン',
    BOUNS_ACTIVITY: '新しいイベントがこちらで表示',
    UN_LOGIN: 'まだチェックインしていません。',
    // 维护页面
    ONLINE_CUSTOM_SERVICE: 'オンラインお問い合わせ',
    WEB_MAINTAINING: 'ウェブサイトメンテナンス中',
    MAIN_NOTE_1: '毎回メンテナンス',
    MAIN_NOTE_2: 'より良いサービスサービスご提供するために',
    SYSTEM_MAINTAINED: 'システムメンテナンス中',
    WEB_TIME_FIXED: 'メンテナンスは%{msg}に完了する予定です',
    // 登入页面
    LOGIN_TO: 'ログイン %{name}',
    LOGIN_NOTE: 'ようこそ %{name} へ、今すぐ投資して、より多くの利益を得よう。',
    WELCOME_TO: 'ようこそ{name}へ',
    WELCOME_NOTE: '私達は、お客様が利益を得るために',
    MILLION_RMB: '百万人民元',
    YEAR: '年',
    BUSINESS_NOTE_1: '月まで顧客利益が554百万人民元に達しました',
    JOIN_US: '参加しましょうか',
    JOIN_NOTE: 'グローバルな顧客との投資活動',
    BUSINESS_NOTE_2: '万の顧客',
    BUSINESS_NOTE_3: '月まで4,917,200人に達しました',
    BUSINESS_GLOBAL_MARKET: 'グローバル市場のトレード',
    BUSINESS_NOTE_4: '通貨、コモディティ、株式、仮想通貨などに投資することができます',
    BUSINESS_NOTE_5: '百万オーダー',
    BUSINESS_NOTE_6: '月まで490.13百万オーダーに達しました。',
    INTEGRITY_FIRST: '誠実',
    SAFE_AND_TRUSTABLE: '安全、信頼、信用',
    BUSINESS_NOTE_7: '億回の取引',
    BUSINESS_NOTE_8: '月まで取引は490.09人民元に達しました',
    REGISTER: '登録',
    LOGIN: 'ログイン',
    BUSINESS_NOTE_9: 'グローバルな顧客と一緒に投資して高い利益を得ませんか。',
    ACCOUNT_REMINDER: '4～16桁のログインIDを設定してください。',
    PASSWORD_REMINDER: '6～16桁のパスワードを設定してください。',
    INVITATION: '招待コード',
    INVITATION_REMINDER: '8桁の招待コードを入力してください。',
    QQ: 'QQアカウント',
    QQ_REMINDER: 'QQアカウントをご設定ください',
    PHONE_NUMBERS: '携帯番号',
    PHONE_REMINDER: '携帯番号をご設定ください',
    IMMEDIATE_LOGIN_NOTE: 'アカウントをお持ちの方はこちら',
    REGISTER_NOTE_1: 'アカウント安全性のために、強力なパスワードを設定してください。',
    ALIEMPTYREMINDER: '確認コードをスライドしてください',
    VERIFY_1: ' スライダーを押したまま、右端にドラッグしてください。',
    VERIFY_2: '認証成功',
    VERIFY_3: '何か問題が発生したので、<a href="javascript:__nc.reset()">クリックして</a>ご更新ください。',
    VERIFY_4: '接続に問題がありますが、<a href="javascript:__nc.reset()">クリックして</a>ご更新ください。',
    REGISTER_NOTE_2: 'パスワード生成を利用したら、自動的に生成されたパスワードを忘れないようにしてください。',
    FORBID_ACCOUNT_PASSWORD_SAME: 'ログインIDとパスワードが同じ文字列を登録することがいけません。',
    FORBID_SIMPLE_PASSWORD: 'ログインIDとパスワードが同じ文字列ではなく、単純すぎる文字列を設定してはいけません。123456、123123、654321、1111111などの単純なパスワードを設定してはいけません。',
    // 快速钱包
    TODAY_INVESTMENT: '本日投資',
    TODAY_PROFIT: '本日利益',
    TODAY_ACTIVITY: '本日イベント',
    TODAY_REBATE: '本日リベート',
    TODAY_DEPOSIT: '本日入金',
    TODAY_WITHDRAW: '本日出金',
    /**
     * API ERROR CODE
     */
    INTERNET_PAY: 'ネットバンキング',
    BANK_TRANSFER: '銀行振込',
    ALIPAY: 'アリペイ',
    WECHAT_PAY: 'WeChat Pay',
    DIGITALCURRENCY_PAY: 'デジタル通貨',
    QQ_WALLET: 'QQウォレット',
    FOURTH_PAY: '第4者支払い',
    UNION_PAY: '銀聯支払い',
    CLOUD_PAY: 'ユニオンペイ',
    JD_PAY: 'ジンドンウォレット',
    INVEST_ORDER_CANCEL: '注文取消',
    CHASE_STOP: '追跡ストップ',
    AGENT_REBATE: '代理リベート',
    PROFIT_DISTRIBUTE: '利益配分',
    DEPOSIT_DISCOUNT: '入金特恵',
    PROFIT_RE_SEND: '利益補填',
    SYSTEM_CANCEL_ORDER: 'システムガオーダーを取消しました。',
    ENVELOPE_WITHDRAW: 'プレゼント受取',
    ENVELOPE_REFUND: 'プレゼント返金',
    TRANSFER_GET: '振込み受取',
    TRANSFER_REFUND: '振込み返る',
    DRAW_REFUND: 'タイで返金',
    ACTIVITY_ENVELOPE: 'イベントのプレゼント',
    TIPS_DEDUCTION: '賞の差し引き',
    ENVELOPE_DEDUCTION: 'プレゼントの差し引き',
    TRANSFER_DEDUCTION: '振込みの差し引き',
    THIRD_PARTY_WITHDRAW_DEDUCTION: '出金の立替払いを控除しました。',
    ARITIFICIAL_DEPOSIT: '手動入金',
    ARITIFICIAL_DEPOSIT_2: '手動デポジット',
    OTHER_DISCOUNT: '他の特恵',
    ERROR_WITHDRAW: 'エラー引き出し',
    ADMIN_WITHDRAW: 'アドミン引き出し',
    PROFIT_WITHDRAW: '利益引き出し',
    WITHDRAW_HANDLE_FEE: '出金の手数料',
    THIRD_PARTY_WITHDRAW_REJECT: '出金の立替払いが拒否されました。',
    HANDLE_FEE_REFUND: '手数料返金',
    VIRTUAL_WALLET_PAY: 'ウォレットで支払い',
    REJECT_WITHDDRAW_TO_VIRTUAL_WALLET: 'ウォレットに出金が拒否されました。',
    VIRTUAL_WALLET_SETTLE_REFUND: 'ウォレット決済の返金',
    NOLOGIN: 'まだ登録していません。',
    SUCCESS: '操作が成功しました。',
    SUCCESS_GET: 'アクセスが成功しました。',
    SUCCESS_QUERY: '検索が成功しました。',
    SUCCESS_SAVE: '保存が成功しました。',
    SUCCESS_INSERT: '追加が成功しました。',
    SUCCESS_UPDATE: '更新が成功しました。',
    SUCCESS_DELETE: '削除が成功しました。',
    FAIL: '操作が失敗しました。',
    FAIL_GET: 'アクセスが失敗しました。',
    FAIL_QUERY: '検索が失敗しました。',
    FAIL_SAVE: '保存が失敗しました。',
    FAIL_INSERT: '追加が失敗しました。',
    FAIL_UPDATE: '更新が失敗しました。',
    FAIL_DELETE: '削除が失敗しました。',
    FAIL_BAD_PARAMS: 'パラメータが間違っています。',
    FAIL_LACK_PARAMS: 'パラメータが欠如しています。',
    FAIL_ILLEGAL_PARAMS: 'パラメータ違反',
    ERROR: 'システムエラーが発生、少々お待ち、もう一度お試しください。',
    ERROR_NOAUTH: '操作の権限がありません。',
    ERROR_PW: 'パスワードが間違っています。',
    ERROR_SAFETY_PW: 'セキュリティーコードが間違っています',
    ERROR_AC_OR_PW: '登録IDまたはパスワードが間違っています。',
    ERROR_MASSAGE: 'Để bảo mật tài khoản, vui lòng liên hệ với chúng tôi và thực hiện các thay đổi',
    ERROR_OPERATE: '操作エラー',
    ERROR_UNKNOWN: 'システムエラー',
    TYPE_NOT_NULL: 'タイプはヌル値を入力しないでください。',
    TYPE_NOT_FOUND: 'このタイプがありません。',
    ACCOUNT_NOT_FOUND: 'この登録IDが存在しません。',
    USR_NOT_FOUND: 'ユーザーが存在しません。',
    USR_001: '登録IDフォーマットエラー',
    USR_002: 'パスワードフォーマットエラー',
    USR_003: 'ログインが制限されました。',
    USR_004: 'アカウントは保護されていますので、指定のIPでログインしてください。',
    USR_005: 'パスワードが無効です。オンラインお問い合わせにご連絡してパスワードをご変更ください。',
    USR_006: 'アカウントが凍結されました。詳細はお問い合わせにご連絡ください。',
    USR_007: 'パスワードはアカウントIDと同じで、セキュリティ上のリスクがありますので、再度パスワードをご変更ください。',
    USR_008: 'パスワードが単純すぎで、セキュリティ上のリスクがありますので、再度パスワードをご変更ください。',
    USR_009: 'ログインしました。',
    USR_010: 'ログインIDとパスワードが同じ文字列を登録することがいけません。',
    USR_011: 'ログインIDは数字と英語のみです。',
    USR_012: 'ログインIDの長さが違反。',
    USR_013: '携帯番号を入力してください。',
    USR_014: '携帯番号のフォーマットエラー',
    USR_015: 'QQアカウントIDを入力してください。',
    USR_016: 'QQアカウントIDのフォーマットエラー',
    USR_017: '招待コードが間違い、または期限が切れています。',
    USR_018: 'このアカウントIDが登録されました。',
    USR_019: 'この携帯番号が登録されました。',
    USR_020: 'このQQアカウントが登録されました。',
    USR_021: '招待コードのリベートが間違っており、無効になりました。',
    USR_022: '登録が失敗しました。',
    USR_023: '登録しました。',
    USR_024: 'プレイヤーが招待コードを作成することはできません。',
    USR_025: '今日招待コードの上限に達しましたので、明日再生成してください。',
    USR_026: '1日に招待カードの作成は50個のみです。',
    USR_027: 'チャージリベートエラー',
    USR_028: 'リベートフォーマットエラー',
    USR_029: 'リベート設定はヌル値を入力しないでください。',
    USR_030: '招待コードに設定されるリベートは、ご自身のリベートを超えてはいけません。',
    USR_031: 'リベートの値を負の数にすることはできません。',
    USR_032: '%lotteryNameリベート情報が欠如しています。',
    USR_033: 'リベートの設定は小数点以下の桁数1桁のみです。',
    USR_034: '招待コードが作成しました。招待コード管理でクリックして検索できます。',
    USR_035: '作成が失敗しました。',
    USR_036: 'ユーザー情報がありません。',
    USR_037: 'セキュリティコードの入力エラー数が1日の限度を6回以上超えてしました。',
    USR_038: '您的安全密码过于简单，无法通过安全密码找回登录密码，请使用密保问题或联系客服找回',
    USR_039: 'セキュリティコード正確',
    USR_040: 'セキュリティコードのフォーマットエラー',
    USR_041: 'セキュリティコードが設定されています。',
    USR_042: '設定が成功しました。',
    USR_043: '設定が失敗しました。',
    USR_044: '終了しました。',
    USR_045: 'まだパスワード保護のメールアドレスを連携していません。',
    USR_046: 'メールアドレスのフォーマットエラー',
    USR_047: '認証コードがメールアドレスに送信しました。それでも届かない場合は、「迷惑メール」をご確認ください。',
    USR_048: 'まだパスワード保護の携帯番号を連携していません。',
    USR_049: '機能はしばらく使えません。',
    USR_050: '携帯番号のフォーマットエラー',
    USR_051: '送信しました。',
    USR_052: '同じパスワード保護の質問が選べません。',
    USR_053: 'パスワード保護質問の不正解数が、本日の制限数である10回を超えました。',
    USR_054: 'パスワード保護質問の答えは不正解です。',
    USR_055: 'パスワード保護質問の答えは正解です。',
    USR_056: '認証が失敗しました',
    USR_057: 'SMS認証のエラー数が1日の限度を10回以上超えてしました。',
    USR_058: 'まずセキュリティーコードをご認証ください。',
    USR_059: 'コードの検証が間違っています。',
    USR_060: '携帯の検証が間違っています。',
    USR_061: '認証が成功しました。',
    USR_062: 'メールアドレス認証のエラー数が1日の限度を10回以上超えてしました。',
    USR_063: 'メールアドレスの認証エラー',
    USR_064: 'お答えはスペースを含んではいけません。',
    USR_065: 'まずパスワード保護の質問をご検証ください。',
    USR_066: 'このIDが存在していません。',
    USR_067: 'このIDへの読み権限がありません。',
    USR_068: 'ユーザーID又はカード番号がありません。',
    USR_069: 'この銀行は現時点でご利用できません。',
    USR_070: 'セキュリティーコードの認証が失敗しました。アカウント安全のために、セキュリティコードの認証を再度行ってください。。',
    USR_071: 'まず1枚目カードをご認証ください。',
    USR_072: '銀行口座名が間違っています、再度ご選択ください。',
    USR_074: '最大5枚まで連携することはできます。',
    USR_075: 'ユーザーIDがありません。',
    USR_076: 'テナントコードがありません。',
    USR_077: '銀行名がありません。',
    USR_078: '区がありません。',
    USR_079: 'シティがありません。',
    USR_080: 'カード番号のフォーマットエラー',
    USR_081: '名義人のフォーマットエラー',
    USR_082: 'ソースIDがありません。',
    USR_083: 'ユーザーがありません。',
    USR_084: 'この口座が連携することはできません。',
    USR_085: 'このカードが連携されています。他のカードをご変更ください。',
    USR_086: '連携しました。',
    USR_087: 'カードを修正してはいけません。',
    USR_088: 'カードがロックされているので、修正してはいけません。',
    USR_089: '今、このカードがお金を引き出していますので、暫くカードの情報が変更できません。',
    USR_090: '銀行口座名が間違っています、再度ご選択ください。',
    USR_091: '認証エラーが3回に達しました。明日、認証を再度行ってください。',
    USR_092: '認証情報正確',
    USR_093: '認証情報エラー',
    USR_094: '入力した回数が多すぎで、1日の最大限度を超えてしました。',
    USR_095: 'ユーザーの情報エラー',
    USR_096: 'ログインIDとパスワードが同じ文字列ではなく、単純すぎる文字列を設定してはいけません。123456、123123、654321、1111111などの単純なパスワードを設定してはいけません。',
    USR_097: 'まだ認証していません。',
    USR_098: '現在のパスワード正確',
    USR_099: 'まずカードをご認証ください。',
    USR_100: '送りました、審査結果は通知センターに注目してください',
    USR_101: '画像のアップロードに失敗しました。',
    USR_102: '現在のパスワードエラー',
    USR_103: '修正の場合は、お問い合わせに御連絡ください。',
    USR_104: 'プロフィールの写真エラー',
    USR_105: 'プロフィールサービスエラー',
    USR_106: 'プロフィールサービスエラー　データがありません',
    USR_107: 'ニックネームがありましたので、修正できません。',
    USR_108: '携帯番号がありましたので、修正できません。',
    USR_109: 'QQアカウントがありましたので、修正できません。',
    USR_110: 'まだロックしていません。',
    USR_111: 'カード番号がありません。',
    USR_112: '銀行名がありません。',
    USR_113: '認証正確',
    USR_114: '操作が失敗しました。',
    USR_115: '自分のことが検索できません。',
    USR_116: 'アカウントは他の所でログインしています。ご本人の操作ではない場合は、すぐにパスワードをご変更ください。',
    USR_117: 'ニックネームが設定していません',
    USR_118: 'ニックネームは日本語文字と漢字5文字まで、又は他の言語10文字までです。',
    USR_119: 'セキュリティコードとログインIDが同じ文字列ではなく、単純すぎる文字列を設定してはいけません。123456、123123、654321、1111111などの単純なパスワードを設定してはいけません。',
    USR_120: 'ページパラメータエラー',
    USR_121: 'ログインIDは数字と英語のみです。',
    USR_135: 'アドレスが使用されました。アドレスを変更してください。',
    LTRY_001: 'lotteryCodパラメータエラー',
    LTRY_002: 'dataNumパラメータエラー',
    LTRY_003: 'コードエラー',
    LTRY_004: '最大10種類の株タイプしか検索できません。',
    BET_001: 'インターネットに接続されていないので、後で再読み込みください。',
    BET_002: 'パラメータエラー！ベットデータがありません。',
    BET_003: '未知なるロト',
    BET_004: 'ベットする期間番号が間違っていますので、ページをご更新又は期間番号が正確かどうかご確認ください。',
    BET_005: '2秒以内のリピートベットができません。',
    BET_006: 'この株式が売り中止されました。',
    BET_007: 'このロトが売り中止です。',
    BET_008: 'この期の受注がクローズドされました。',
    BET_009: 'このロトがまだ発売していません。',
    BET_010: 'ゲット株式の設定が失敗しました。',
    BET_011: 'この株式はメルトダウンが発生しましたので、一時的に取引が停止されています。',
    BET_012: 'この株式は値幅制限が発生しましたので、一時的に取引が停止されています。',
    BET_013: '当期の優勝と準優勝はそれぞれ6個まで番号が選べます。',
    BET_014: '当期は、上昇/下降、奇/偶、ブル/ベアの両方の市場に投資することはできません。',
    BET_015: 'リベート情報エラーです、ページをご更新ください。',
    BET_016: '残高が不足しています。',
    BET_017: '残高控除エラー',
    BET_018: '残高サービスが空になって戻ります。',
    BET_019: 'ベットが成功しました。',
    BET_020: '今、追跡機能が使えません。',
    BET_021: 'このロトは追跡することができません。',
    BET_022: '優勝と準優勝の投資が毎期それぞれ6個まで番号が選べます。',
    BET_023: 'オーダー番号パラメータエラー',
    BET_024: '状態パラメータエラー',
    BET_025: '日付期間パラメータエラー',
    BET_026: 'キャンセル違反',
    BET_027: 'このプランの状態は、当せん結果を待つ状態ではありません',
    BET_028: 'キャンセルしてはいけません。',
    BET_029: 'キャンセルが成功しました。',
    BET_030: 'ゲットの設定が失敗しました。',
    BET_031: 'ゲット期号の情報が失敗しました。',
    BET_032: 'ロト番号エラー',
    BET_033: '株式タイプ番号エラー',
    BET_034: '自動キャンセルが失敗しました。',
    BET_035: 'オーダー番号が存在していません。',
    BET_036: 'ターンオフの状態です',
    MNG_001: 'パスコード又は唯一コードが正しくないです。',
    MNG_002: 'パスコードが間違い、又は締め切りました。',
    MNG_003: 'タイプは0を超えることが必要です。',
    TRSC_001: 'まだ処理中のオーダーがありますので、後で再度ご操作ください。',
    TRSC_002: 'すみませんが、5秒以内に重複送りしないでください。',
    TRSC_003: '頻繁にオーダーを送らないでください。',
    TRSC_004: 'オーダーが作成しました。',
    TRSC_005: 'オーダーを送りました、5分以内にお客様のクレジットを追加いたします。',
    TRSC_006: '引き出し申請を送りました。3~5分間の後で受け取るかどうか、ご確認ください。',
    TRSC_007: '引き出しが失敗しました。',
    TRSC_008: '送りました。',
    TRSC_009: '送りが失敗しました。オーダーがキャンセルされました。',
    TRSC_010: '対応するプロンプトが存在しません。',
    TRSC_011: '日付タイプパラメータにエラーがありますので、ご確認して再送信してください。',
    TRSC_012: 'このEnumPaymentRecordStateを対応するパラメータにエラーがありますので、ご確認して再送信してください。',
    TRSC_013: 'サードパーティベーシック配置のデータが存在しません。',
    TRSC_014: 'サードパーティ支払い配置の明細が存在しません。',
    TRSC_015: 'サードパーティインターフェースモード設定データが存在しません。',
    TRSC_016: '金額が間違っています。',
    TRSC_017: 'このEnumMerchantsPaymentTypeを対応するパラメータにエラーがありますので、ご確認して再送信してください。',
    TRSC_018: 'ニックネームはヌル値を入力しないでください。',
    TRSC_019: 'ゲット成功でしたが、プレヤーの歴史記録はゲットしていません。',
    TRSC_020: 'ウォレットの控除が失敗しました。',
    TRSC_021: '残高サービス：残高の更新が失敗しました。',
    TRSC_022: '残高サービス：残高備考の表示が失敗しました。',
    TRSC_023: 'ウォレットのリターンが失敗しました。',
    TRSC_024: 'このサイトで引き出せ金額の制限データがありません、又はデータエラーです。',
    TRSC_025: '引き出す金額が残高を超えます。',
    TRSC_026: '今、ウォレットをご利用することができません。',
    TRSC_027: 'このサイトマスターは引き出せ金額制限のデータがありません。',
    TRSC_028: '引き出しの申請回数が上限を超えました。',
    TRSC_029: 'システムエラー：ユーザーID',
    TRSC_030: 'このカードを使うことが禁止されています。詳しくはお問い合わせにご連絡ください。',
    TRSC_031: '金額は引き出せ金額を超えます。',
    TRSC_032: '時間はヌル値を入力しないでください。',
    TRSC_033: '開始時間が終了時間をイコール又は超えることができません。',
    TRSC_034: '受信する時間のフォーマットが問題があります。',
    TRSC_035: '終了日付は開始日付を超えることができません。',
    TRSC_036: 'チャージ金額の範囲が間違っています。',
    TRSC_037: 'チャージ金額の範囲パラメータエラー',
    TRSC_038: 'チャージ金額の範囲、最大金額が最小金額をイコール又は超えます。',
    TRSC_039: 'チャージ方法フォーマットが問題があります。',
    TRSC_040: 'レベルフォーマットが問題があります。',
    TRSC_041: 'レベルタイプのパラメータにエラーがありますので、ご確認して再送信してください。',
    TRSC_042: '状態はヌル値を入力しないでください。',
    TRSC_043: 'タイプフォーマットが問題があります。',
    TRSC_044: 'オーダーが存在していません。',
    TRSC_045: '同じデータが送れません。',
    TRSC_046: '金額が残高を超えます。',
    TRSC_047: '引き出せ金額が不足 しています。',
    TRSC_048: 'ユーザーサービス - ユーザーのアップダウンを検証が失敗しました。',
    TRSC_049: 'ユーザーサービス - ユーザーの情報をゲットが失敗、原因：%msg',
    TRSC_050: 'パラメータ変換エラー：%msg',
    TRSC_051: 'ユーザーの歴史情報をゲットエラー',
    TRSC_052: '残高サービス - ユーザーの残高をゲットが失敗、原因：%msg',
    TRSC_053: 'ユーザーサービス - カートロックが失敗、原因：%msg',
    TRSC_054: '残高サービス - 引き出し処理が拒否され、原因：%msg',
    TRSC_055: '残高サービス - 残高ロールバックの処理が失敗、原因：%msg',
    TRSC_056: 'ユーザーサービス - 成長点数の変更処理が失敗しました。',
    TRSC_057: 'イベントサービス -チャージ特恵の処理が失敗しました。',
    TRSC_058: '転送サービス - 金額設定情報の一括更新が失敗しました。',
    TRSC_059: '転送サービス - 転送サービスの引き出し回数設定情報の更新が失敗しました。',
    TRSC_060: '残高サービス - 残高の検索が失敗しました。',
    TRSC_061: 'ユーザーサービス - ユーザーカードの取得が失敗しました。',
    TRSC_062: '残高が不足してい、少なくとも以下の条件が必要：%minBalance',
    TRSC_063: '送信した金額エラー',
    TRSC_064: 'バンクフローが不足です！ %journal がまだ必要です。',
    TRSC_065: 'ウォレットの操作が失敗しました。',
    TRSC_066: 'すみませんが、システムは直近の %betweens 日間のデータのみを検索できます。',
    TRSC_083: '為替レートが変更されました',
    RPT_001: 'タイプの範囲が間違っています。',
    RPT_002: '不明のリクエストデータタイプ',
    CHAT_001: 'ゲストコードをまだ入力していません。',
    CHAT_002: 'ゲストコードが存在していません。',
    CHAT_003: 'ゲストコードエラー',
    CHAT_004: 'ユーザー名エラー',
    CHAT_005: 'ルーム番号エラー',
    CHAT_006: 'ページサイズエラー',
    CHAT_007: '今のグループがルームに入れません。',
    CHAT_008: '今のレベルがルームに入れません。',
    CHAT_009: '全員トーク禁止を行ってい、管理員以外のメンバーが発言できません。',
    CHAT_010: '今のグループが発言できません。',
    CHAT_011: '今のレベルが発言できません。',
    CHAT_012: 'トーク禁止が永久にられました。',
    CHAT_013: 'トーク禁止は１週間られました。',
    CHAT_014: 'トーク禁止は１日られました。',
    CHAT_015: 'あなたのIPはトーク禁止が永久にられました。',
    CHAT_016: 'あなたのIPはトーク禁止が１週間られました。',
    CHAT_017: 'あなたのIPはトーク禁止が１日られました。',
    CHAT_018: 'トーク禁止の状態は、画像、絵文字、プレゼントを送ることができません。',
    CHAT_019: '内容が無しで、送信できません。',
    CHAT_020: '特殊文字が使えません。',
    CHAT_021: '数字が使えません。',
    CHAT_022: '英語が使えません。',
    CHAT_023: '中国語が使えません。',
    CHAT_024: 'ルームが削除されました。',
    CHAT_025: '画像が存在していません。',
    CHAT_026: '画像のアドレスはヌル値を入力しないでください。',
    CHAT_027: '画像のソースエラー',
    CHAT_028: 'ゲストの方がオーダーをシェアすることはできません。',
    CHAT_029: 'ゲストの方戦績をシェアすることはできません。',
    CHAT_030: 'ゲストの方がプレゼントを送ることはできません。',
    CHAT_031: 'プレゼントを送信が失敗しました。',
    CHAT_032: 'メッセージタイプIDエラー',
    CHAT_033: 'メッセージエラー',
    CHAT_034: '最大31枚画像が収集できます。',
    CHAT_035: '画像を収集しました。',
    CHAT_036: '収集を取消することが失敗しました。',
    CHAT_037: 'ルームパスワードの間違い答え数が本日の制限を超えます。',
    CHAT_038: 'プレゼントを取消することができません。',
    CHAT_039: 'オーダーシェアを取消することができません。',
    CHAT_040: '戦績シェアをキャンセルすることができません。',
    CHAT_041: '管理員しかユーザーと自分のメーセージを取消することができません。',
    CHAT_042: '対応するメッセージの記録がありません。',
    CHAT_043: 'サイトマスターコード、管理員ID、ルーム番号、トーク禁止されるIDはヌル値を入力しないでください。',
    CHAT_044: '管理員はトーク禁止されることができません。',
    CHAT_045: 'トーク禁止時間エラー',
    CHAT_046: 'このゲストはルームにいません。',
    CHAT_047: 'ユーザーがトーク禁止されています。',
    CHAT_048: '管理員しかトーク禁止機能が使えません。',
    CHAT_049: 'このユーザーはまだトーク禁止していませんので、修正できません。',
    CHAT_050: '管理員しかトーク禁止機能がキャンセルできません。',
    CHAT_051: '管理員しか全員トーク禁止機能が使えません。',
    CHAT_052: 'ルーム番号はヌル値を入力しないでください。',
    CHAT_053: 'ページ情報を入力してください。',
    CHAT_054: 'ユーザーIDはヌル値を入力しないでください。',
    CHAT_055: 'ゲーム番号が存在していません。',
    CHAT_056: '対応するゲームはありませんので、管理者にご連絡ください。',
    CHAT_057: 'ゲストの方はプレゼントを受け取ることができません。',
    CHAT_058: 'ルームが存在していません。',
    CHAT_059: '今、トーク禁止がされてい、プレゼントを受け取ることができません。',
    CHAT_060: '今のグループ、プレゼントを受け取ることができません。',
    CHAT_061: '今日のユーザーレポートがゲットエラー',
    CHAT_062: '歴史ユーザーレポートがゲットエラー',
    CHAT_063: '収集の追加が失敗しました。',
    CHAT_064: 'メッセージ文字数は%lengthを超えることができません。',
    CHAT_065: 'メッセージ送信時間の間隔は%interval 秒未満ができません。',
    CHAT_066: '本日のチャージ金額は%dayRecharge円に達成すれば、このプレゼントを受け取ることができます。',
    CHAT_067: 'たまるリチャージ金額が%historyRecharge円に達した場合、このプレゼントを受け取ることができます。',
    CHAT_068: '今のレベルはこのプレゼントが受け取れません。',
    TRSF_001: '内部サービスがメンテナンス中です。',
    TRSF_002: 'このプレゼントが存在していません。',
    TRSF_003: '個人プレゼント機能はメンテナンス中です。',
    TRSF_004: 'プレゼントパラメータエラー',
    TRSF_005: 'テストアカウントはプレゼントを送ることができません。',
    TRSF_006: 'サイトの設定をゲットが失敗しました。',
    TRSF_007: '1回の最大金額設定を超えました。',
    TRSF_008: '金額を送信の制限に達しました。',
    TRSF_009: 'プレゼントを送る数の制限に達しました。',
    TRSF_010: 'プレゼントの合計金額が金額の制限を超えました。',
    TRSF_011: 'ユーザー情報のゲットが失敗しました。',
    TRSF_012: 'ユーザークレジットの控除が失敗しました。',
    TRSF_013: 'プレゼントを送信が失敗しました。',
    TRSF_014: 'プレゼントの合計金額は制限金額を超えました。',
    FILES_001: 'ファイルがありません。',
    FILES_002: 'ファイルサイズは5MB以下としてください。',
    FILES_003: '画像IDエラー',
    FILES_004: 'ファイル数量エラー',
    FILES_005: '画像アップロードのみです。',
    FILES_006: '画像が欠如しています、又は数量が多すぎます。',
    FILES_007: 'アップロードするファイルが画像であることをご確認ください。',
    ACT_001: 'まずはサイトを切り替えてください',
    ACT_002: '計算しています。',
    ACT_003: 'すみませんが、イベントの参加資格が禁止されています。',
    ACT_004: 'イベント中止です',
    ACT_005: '受け取りました。',
    ACT_006: 'ボーナスを受け取りました。',
    ACT_007: 'プレゼントが存在していません。',
    ACT_008: 'プレゼントが取り消しされました。',
    ACT_009: 'プレゼントが全部なくなりました。',
    ACT_010: 'プレゼントが締め切りました。',
    ACT_011: 'このプレゼントを受け取りました。',
    ACT_012: 'ユーザーインターフェースエラー',
    ACT_013: 'まだ受取の条件が達していません。',
    ACT_014: '受取の権限がありません。',
    ACT_015: '今、受取中なので、頻繁に受取しないでください',
    ACT_016: 'プレゼント受取エラー',
    ACT_017: 'イベントはまだ行っていません。',
    ACT_018: 'イベント終了',
    ACT_019: 'イベントが変更しましたので、ページをご更新ください',
    ACT_020: '今日のサインチェックを完了しました。',
    ACT_021: '金額の条件はまだ達していません。',
    VIRWLT_001: 'ウォレットアカウントエラー',
    STK_001: '株式タイプが存在していません。',
    BCT_001: '既に別の端末でログインしています。',
    BET_039: 'モード検証エラー',
    BET_037: 'モード検証エラーE01',
    BET_040: 'モード検証エラー、追跡リピート',
    BET_041: '投資が失敗しました。',
    BET_042: '投資タイプ検証エラーA',
    BET_043: '投資タイプ検証エラーB',
    BET_044: '金額検証エラーC',
    BET_045: '金額エラー',
    BET_046: '金額検証エラーD',
    BET_047: '追跡倍数エラー',
    BET_048: '追跡金額エラー',
    FRT_001: 'あなたのIP使用回数が多過ぎで、暫くの後で再試行してください。',
    FRT_002: '認証エラーので、管理者にご連絡ください。',
    FRT_003: 'リクエストヘッダーエラーので、ページを再読み込んでください。',
    FRT_004: 'リクエストがタイムアウトので、管理者にご連絡ください。',
    FRT_005: 'サービスエラー',
    FRT_006: 'ページを再読み込んでください。',
    MNG_004: '地域制限',
    MNG_005: 'サイトメンテナンス中',
    MNG_006: 'ドメイン名が接続されていません。',
    MNG_007: 'サイトがクローズドされています。',
    MNG_008: '異なるサイトを操作することが禁止されます。',
    USR_122: 'まだパスワード保護の携帯番号を連携していません。',
    USR_123: 'カードIDがありません。',
    USR_124: 'ユーザーがありません。',
    USR_125: 'カードを使うことが禁止されていて、変更も禁止されます。',
    USR_128: '登録されているIDは、英字と数字の組み合わせです',
    USR_126: 'ログインIDには番号のみを含めることができます',
    USR_127: 'ログインIDには英語のみを含めることができます',
    USR_073: '既存のカードデータを変更してください',
    USR_129: 'お名前のフォーマットエラー',
    USR_130: 'お名前をご入力してください',
    USR_131: 'ご本名で申請したカードを連携してください',
    USR_132: 'カードがありません',
    USR_133: '確認コードをスライドしてください',
    USR_134: '認証情報が間違っています',
    AXIOS_STATE_OVER_TIME_ERROR: 'リターンタイムアウトエラー',
    AXIOS_STATE_NET_ERROR: 'インターネットが異常になったので、もう一度お試しください。',
    AXIOS_STATE_LONG_TIME: '長い間に操作していないので、自動的にログアウトされました。もう一度ログインしてください。',
    AXIOS_STATE_LOCALNET_ERROR: 'インターネットに接続されていないので、チェックして再読み込みください。',
    NICKNAME_LIMIT: 'ニックネームは日本語文字と漢字5文字まで、又は他の言語10文字までです。',
    INVEST_RECORDS: '投資履歴',
    ACCOUNT_RECORDS: '会計履歴',
    WITHDRAW_DEDUCTION: '引き出し',
    DEDUCTION_DETAIL: '控除明細',
    TRANSFER_TO_VIRTUAL_WALLET: 'ウォレットに転入',
    DISTRIBUTE_PROFIT: '配布利益',
    INVEST_PLAN: '投資プラン',
    CANCEL_ORDER: '注文取消',
    NORMAL: '一般',
    CHASE: '追跡',
    UNSTART: '待機中',
    PROCESSING: '進行中',
    FINISHED: '終了',
    CANCEL_ORDER_REMINDER: 'ご注文を取消したら、戻すことができませんので、取消にして続きますか。',
    ISSUE_NUMBER: '第 {issue} 期',
    WITHDRAW_NOTE: '引き出し申請を送りました。3~5分間の後で受け取るかどうか、ご確認ください。',
    WITHDRAE_REFUND: '出金返る',
    WITHDRAW_REJECT: '出金が拒否されました。',
    THIRD_WITHDRAW_REJECT: '立替払いが拒否されました。',
    IN_PROCESS: '処理中',
    DEPOSIT_FAIL: '入金が失敗',
    WAREHOUSE_CHASE_NUMBER_ITEM_FULL: '未収益',
    WAREHOUSE_CHASE_NUMBER_ITEM_PENDING: '進行中',
    WAREHOUSE_CHASE_NUMBER_ITEM_WAIT: '発注中',
    WAREHOUSE_ACCOUNT_WITHDRAW_ITEM_TITLE: '引き出し',
    WAREHOUSE_ACCOUNT_ACTION_SHEET_SERIAL_NUMBER: '注文番号',
    WAREHOUSE_ACCOUNT_WITHDRAW_ACTION_TIME_TITLE: '取引時間',
    WAREHOUSE_ACCOUNT_WITHDRAW_ACTION_MONEY: '取引金額',
    WAREHOUSE_ACCOUNT_WITHDRAW_ACTION_BALANCE: '残高',
    WAREHOUSE_ACCOUNT_WITHDRAW_ACTION_REMARK: '備考',
    WAREHOUSE_ACCOUNT_WITHDRAW_CANCEL_BTN_TEXT: '戻す',
    WAREHOUSE_RECORDCENTER_CHASENUMBER_UNPROFITABLE: '未収益',
    WAREHOUSE_RECORDCENTER_CHASENUMBER_PENDING: '発注中',
    WAREHOUSE_RECORDCENTER_CHASENUMBER_ING: '進行中',
    WAREHOUSE_RECORDCENTER_CHASENUMBER_CHASE_NUMBER_TITLE: '追跡期号',
    WAREHOUSE_RECORDCENTER_CHASENUMBER_ORDER_TEXT1: '第',
    WAREHOUSE_RECORDCENTER_CHASENUMBER_ORDER_TEXT2: '%{num} 期から',
    WAREHOUSE_RECORDCENTER_CHASENUMBER_CHASE_TIME_TITLE: '追跡時間',
    WAREHOUSE_RECORDCENTER_CHASENUMBER_CHASE_ORDER_TITLE: '追跡番号',
    WAREHOUSE_RECORDCENTER_CHASENUMBER_CHASE_MONEY_TITLE: '追跡金額',
    WAREHOUSE_RECORDCENTER_CHASENUMBER_BET_TITLE: '投資金額',
    WAREHOUSE_RECORDCENTER_CHASENUMBER_BONUS_TITLE: '配布利益',
    WAREHOUSE_RECORDCENTER_CHASENUMBER_AUTO_BUY_AFTER_WIN_TITLE: '収益循環',
    WAREHOUSE_RECORDCENTER_CHASENUMBER_PLAY_TYPE: '投資プラン',
    WAREHOUSE_RECORDCENTER_CHASENUMBER_CHASE_SCHEDULE: '追跡進度',
    WAREHOUSE_RECORDCENTER_CHASENUMBER_CHASE_END: '%{num}期を追跡した',
    WAREHOUSE_RECORDCENTER_CHASENUMBER_CHASE_END_TEXT: '合計%{num}期',
    WAREHOUSE_RECORDCENTER_CHASENUMBER_CHANGE_PLAY_TYPE_MSG: '変更は元に戻すことができませんので、収益循環から收益後止しに変更を設定するかどうか、ご確認ください。',
    WAREHOUSE_RECORDCENTER_CHASENUMBER_C_ISSUE_TITLE: '投資期号',
    WAREHOUSE_RECORDCENTER_CHASENUMBER_C_TIME_TITLE: '投資時間',
    WAREHOUSE_RECORDCENTER_CHASENUMBER_C_ORDER_TITLE: '投資番号',
    WAREHOUSE_RECORDCENTER_CHASENUMBER_C_OPEN_NUMBER_TITLE: '抽選番号',
    WAREHOUSE_RECORDCENTER_CHASENUMBER_C_BET_TITLE: '投資金額',
    WAREHOUSE_RECORDCENTER_CHASENUMBER_C_BONUS_TITLE: '配布利益',
    WAREHOUSE_RECORDCENTER_CHASENUMBER_C_PLAYTYPE_TITLE: '投資プラン',
    PROFIT_LOSS_TODAY: '今日の利益',
    PROFIT_AMOUNT: '利益金額',
    PROFIT_TODAY: '今日',
    PROFIT_MOUNTH: '月',
    PROFIT_DAY: '日',
    PROFIT_INVEST_AMOUNT: '投資額',
    PROFIT_GAIN: '利益額',
    PROFIT_AGENT_REBATE: '代理リベート',
    PROFIT_ACTIVITY: 'イベント',
    PROFIT_FORMULA_MEESSAGE: '利益計算式 = 利益 - 投資 + イベント + リベート',
    PROFIT_CREDIT: '収入額',
    PROFIT_DEPOSIT: '入金額',
    PROFIT_GET_RED_ENVELOPE: 'プレゼントゲット',
    PROFIT_PAY_OUT: '支出額',
    PROFIT_WITHDRAWAL: '出金額',
    PROFIT_SEND_RED_ENVELOPE: 'プレゼント贈る',
    PROFIT_FORMULA: '損益計算式',
    PROFIT_LOSS_FORMULA: ' 損益 = 利益 - 投資 + イベント +リベート',
    PROFIT_I_KNOW: '分りました',
    ACTING_BALANCE: 'チーム残高',
    ACTING_AGENCY_REPORT: '代理レポート',
    ACTING_TEAM_PROFIT: 'チーム利益',
    ACTING_LOOK_ALL: 'すべて表示',
    ACTING_PROFIT_REBATE: '利益リベート',
    ACTING_SUB_REPORT: '下位会員レポート',
    ACTING_MEMBER_MANAGEMENT: '会員管理',
    ACTING_SUB_ACCOUNT: '下位会員アカウント開設',
    ACTING_INVESTMENT_DETAILS: '投資明细',
    ACTING_TRANSACTION_DETAILS: '取引明细',
    ACTING_UPDATE: '更新',
    ACTING_CARD01_TITLE: '投資と発展',
    ACTING_INSTRUCTION: '代理の説明',
    ACTING_CARD01_TEXT: 'このページが読めるということは、あなたのアカウントがプレイヤーであると同時に代理でもあるということです。つまり、あなたは自分で投資したり、下位会員を育成してリベートを得ることができます。',
    ACTING_CARD02_TITLE: 'どうやってリベートを得るですか',
    ACTING_INVESTMENT_REBATE: '投資リベート',
    ACTING_SELF: '自分',
    ACTING_SUB: '下位会員',
    ACTING_MATH: '数',
    ACTING_INVEST: '投資',
    ACTING_SUB_INVEST: '下位会員投資',
    ACTING_REBATE: 'リベート',
    ACTING_DEPOSIT_REBATE: '入金リベート',
    ACTING_SET_REBATE: 'コミッションリベートを設定',
    ACTING_CASH: 'Dが1,000円の現金を入れた場合',
    ACTING_TOTAL_REWARD: '総額',
    ACTING_CLICK_ACCOUNT: '下位会員アカウントを作成',
    ACTING_SEARCH_REBATE: '自分のリベートが読めるだけでなく、下位会員のリベートも設定できます。',
    ACTING_HOW_SUB_ACCOUNT: 'どうやって下位会員アカウントを作成しますか',
    ACTING_PARAGRAPH_FIR: '下位会員アカウント開設をクリックし、下位会員のリベートを設定し、完了すると招待コードが生成されます。',
    ACTING_PARAGRAPH_SEC: '相手に招待コードを送り、登録後は自分の下位会員になります。「会員管理」をクリックして登録したアカウントが確認できます。',
    ACTING_PARAGRAPH_THR: '代理タイプのアカウントを設定しておけば、下位会員を育てていくことができます。',
    ACTING_PARAGRAPH_FOUR: 'プレイヤータイプのアカウントを設定しておけば、下位会員が投資しかできず、さらに次の下位会員を育てることはできず、代理センターも使えません。',
    ACTING_TIPS: 'リマインド',
    ACTING_TEXT_FIR: '異なるリベートはオッズも異なります。リベートのオッズ表をクリックすると、リベートのオッズが表示されます。',
    ACTING_TEXT_SEC: 'リベートが低ければ低いほど、オッズも低くなりますので、下位会員に設定するリベートは低すぎないことをお勧めします。',
    ACTING_TEXT_THR: '代理の発展情報は、代理レポート、投資明細、取引明細で確認できます。',
    ACTING_TEXT_FOUR: '下位会員が代理タイプに作成することをお勧めします。また、レベルの成長状況に関わらず、リベートを得ることができます。',
    ACTING_SET_RANGE: 'リベートの範囲を設定',
    ACTING_WIN_LOTTERY: '下位会員が賞を獲得すると、コミッションが入ります。',
    ACTING_WIN_LOTTERY_TWO: '下位会員が利益を得ると、コミッションがもらえます。',
    ACTING_COMMISSION_FORMULA_TWO: 'コミッション計算式：(利益金額-ベット額)*利益リベート',
    ACTING_COMMISSION_FORMULA: 'コミッション計算式：(利益金額-ベット額)*利益リベート',
    REPORT_TOTAL_PERSON: '投資家の総数',
    REPORT_YESTERDAY: '昨日',
    REPORT_THIS_MONTH: '今月',
    REPORT_LAST_MONTH: '前月',
    REPORT_TEAM_DEAL: 'チーム取引',
    REPORT_TEAM_CREDIT: 'チーム収入額',
    REPORT_TEAM_BILLING: 'チーム支出額',
    REPORT_TEAM_MANAGE: 'チーム管理',
    REPORT_SING_PERSON: '登録者数',
    REPORT_PERSON: '<div class="number-title">登録者数</div><span>%{num}</span><span class="people-unit">人</span>',
    REPORT_COUNT_SUB_PERSON: '下位会員の総数',
    REPORT_CHECK_SING_PERSON: '登録者数表示',
    REPORT_INVESTORS_NUMBER: '投資家数',
    REPORT_TOTAL_TIMES: '合計回数',
    REPORT_TOTAL_AMOUNT: '合計金額',
    REPORT_TIME: '回',
    REPORT_COUNT_TOTAL_FIRST: '初回チャージする人数の合計',
    REPORT_AMOUNT_RECEIVED: '受取額',
    REPORT_TO_MONTH: '今月',
    REPORT_PREV_MONTH: '前月',
    REPORT_FIRST_CHARGE: '初回チャージする人数',
    REPORT_TRANSFER_AMOUNT: '転送金額',
    REPORT_NUMBER_SUBORDINATES: '下位会員数',
    REPORT_COUNT_PERSON: '合計人数',
    REPORT_OWNED_SUPERIOR: '上位層',
    REPORT_INVITER_ACCOUNT: '招待者のID',
    REPORT_SEARCH_FROM: '下位会員レポートを検索',
    REPORT_SEARCH_ACCOUNT: '下位会員のIDを入力してください。',
    REPORT_NOT_FIND: '情報がありません、他のキーワードにご変更ください。',
    REPORT_CHECK_SUB: '下位会員を見る',
    REPORT_ALREADY_SHOW: '%{msg}の全てのデータを表示しました。',
    REPORT_ACCOUNT_SEARCH: 'ログインIDを入力すると、検索できます。',
    REPORT_VIEW_REBATE: 'リベート表示',
    REPORT_GRADE: 'レベル',
    REPORT_GRADE2: 'Lv.%{grade}',
    REPORT_STOCK_REBATE: '株リベート',
    REPORT_DEPOSIT_REBATE: '入金リベート',
    REPORT_SHORT_TERM: 'ショート',
    REPORT_AGENT_TYPE: '代理タイプ',
    REPORT_PLAY_TYPE: 'プレーヤータイプ',
    REPORT_SING_UP: '登録',
    REPORT_SET_REBATE: '設定できる範囲',
    REPORT_SET_REBATE_1: '正の整数リベートが設定できる範囲',
    REPORT_SELF_REBATE: '自分のリベートは',
    REPORT_CODE_MESSAGE: '招待コードが作成しました。招待コード管理でクリックして検索できます。ご覧になりますか。?',
    REPORT_GO_VIEW: '見に行く',
    REPORT_STAY_PAGE: 'このページで',
    REPORT_BORN_INVITATION_CODE: '招待コードを作成',
    REPORT_QR_CODE: '招待コード(長押しで保存)',
    REPORT_COPY_LINK: 'リンクをコピー',
    REPORT_COPY_INVITATION_CODE: '招待コードをコピー',
    REPORT_SUCCESS_COPY: 'コピーしました。',
    REPORT_CONFIRM_DELETE_CODE: 'この招待コードを削除することがよろしいですか。',
    REPORT_DELETE_CODE: '招待コードを削除',
    REPORT_ACTING: '代理',
    REPORT_PLAYER: 'プレーヤー',
    REPORT_FIELD_ERROR: '入力欄エラー',
    REPORT_CHECK_INPUT: '入力した内容をチェックしてください。',
    REPORT_NOT_CREATED_CODE: '招待コードはまだ作成していません。',
    INVEST_TO: ' - ',
    INVEST_ALL: '全て',
    INVEST_PROFITABLE: '収益',
    INVEST_UNPROFITABLE: '未収益',
    INVEST_WAITING_RELEASED: '発注中',
    INVEST_ORDER_CANCELLED: '注文取消',
    INVEST_STOP_PROFIT: '収益後止し',
    INVEST_SEVEN_DAYS: '前七日',
    INVEST_PERIOD_NUMBER: '投資期号',
    INVEST_TIME: '投資時間',
    INVEST_SINGLE_NUMBER: '投資番号',
    INVEST_MONEY: '投資金額',
    INVEST_BONUS: 'ボーナス配布',
    INVEST_WINNING_NUMBERS: '抽選番号',
    INVEST_HOW_PLAY: 'モード',
    DEAL_ALL_TYPE: '全部',
    DEAL_WITHDRAWAL_RECORD: '出金履歴',
    DEAL_DEPOSIT_RECORD: '入金履歴',
    DEAL_SERIAL_NUMBER: '注文番号',
    DEAL_TIME: '取引時間',
    DEAL_CASH: '取引金額',
    DEAL_BALANCE: '残高',
    DEAL_REMARK: '備考',
    DEAL_WITHDRAWALING: '出金中',
    DEAL_WITHDRAWAL_SUCCESS: '出金が成功',
    DEAL_WITHDRAWAL_ERROR: '出金が失敗',
    DEAL_WITHDRAWAL_REJECT: '出金が拒否され',
    DEAL_PAY_ERROR: '立替払いが失敗',
    DEAL_PAY_REJECT: '立替払いが拒否され',
    DEAL_PAY_SUCCESS: '立替払いが成功',
    DEAL_PAYING: '立替払い中',
    DEAL_DEPOSITING: '入金中',
    DEAL_DEPOSIT_SUCCESS: '入金が成功',
    DEAL_DEPOSIT_ERROR: '入金が失敗',
    DEAL_PENDING_PAYMENT: '支払保留中',
    DEAL_COPY_SUCCESS: 'コピーしました。',
    DEAL_INVESTMENT_DEDUCTIONS: '投資控除',
    SECURITY_CENTER: 'セキュリティーセンター',
    SECURITY_CHANGE_LOGIN_PASSWORD: 'パスワード変更',
    SECURITY_CHANGE_SAFE_PASSWORD: 'セキュリティコードを変更',
    SECURITY_CHANGE_SECRET_PASSWORD: 'パスワード質問を変更',
    SECURITY_FIND_SAFE_PASSWORD: 'パスワード回復',
    SECURITY_CONFIRM_SAFE_PASSWORD: 'セキュリティコードを確認',
    SECURITY_BANK_CARD: 'カード管理',
    SECURITY_ALTER: '変更',
    SECURITY_SETING: '設定',
    SECURITY_GO_SETTING: '設定ヘ',
    SECURITY_NEW_OLD_NOT_SAME: '新しいパスワードは、現在のパスワードと同じ文字列を登録することがいけません。',
    SECURITY_NOT_SAME: 'ログインIDとパスワードが同じ文字列ではなく、単純すぎる文字列を設定してはいけません。123456、123123、654321、1111111などの単純なパスワードを設定してはいけません。',
    SECURITY_LOGIN_KEY: 'パスワード',
    SECURITY_UPDATE_PASSWORD: 'ログイン時に、2ヶ月ごとにパスワードを変更することをお勧めします。',
    SECURITY_PASSWORD: '現在のパスワード',
    SECURITY_RESET_PASSWORD: '新しいパスワード',
    SECURITY_CONFIRM_PASSWORD: '新しいパスワード再入力',
    SECURITY_SUCCESS_UPDATE: '変更が成功しました。もう一度ログインしてください。',
    SECURITY_PASSWORD_INCONSISTENT: 'パスワードが一致しません。',
    SECURITY_UPDATE_SUCCESS: '変更しました。',
    SECURITY_PASS_QUESTION: 'パスワード保護の質問で',
    SECURITY_PASS_ID: 'ご本人の認証で',
    SECURITY_SECRET_QUESTION: 'パスワード保護の質問',
    SECURITY_CONFIRM_SECRET: 'パスワード保護の質問を認証',
    SECURITY_FIND_ACCOUNT: 'アカウントの安全性を高め、早速にパスワードを回復することができます。',
    SECURITY_PASSWORD_AGIAIN: 'セキュリティコードをもう一度入力してください。',
    SECURITY_INPUT_ANSWER: 'お答えを入力',
    SECURITY_FIND_ENTER_QAMSG: 'パスワード保護の質問を入力する',
    SECURITY_VERITY_PASSWORD: 'パスワードを認証',
    SECURITY_CONFIRM_USER: 'ご本人を認証',
    SECURITY_LOGIN_SAFE_PASSWORD: 'パスワードからセキュリティコードを取り戻します。',
    SECURITY_LOGIN_PASSWORD: 'パスワード',
    BANKCARD_INSTRUCTION: '説明',
    BANKCARD_LAST_NUMBER: 'テールナンバー',
    BANKCARD_MESSAGE: '連携した&{num}枚カードがまだ使用していないので、他のカードは連携することはできません。他のカードを使用する必要がある場合は、連携情報を編集してください。',
    BANKCARD_LOCK: 'カードは、出金に成功すると自動的にロックされ、削除や変更ができなくなります。 同一名義のカードは最大5枚まで連携することはできます。',
    BANKCARD_ADD: 'カードを追加',
    BANKCARD_UPDATE: 'カードを変更',
    BANKCARD_CHECK: 'カードを認証',
    BANKCARD_PASS_ID_CONFIRM: 'ご本人を認証',
    BANKCARD_CONFIRM_SAFE_PASSWORD: 'セキュリティコード認証',
    BANKCARD_CONFIRM_RESULT: '認証結果',
    BANKCARD_OPEN_ACCOUNT: '口座の金融機関',
    BANKCARD_ACCOUNT_NAME: 'カード名義人',
    BANKCARD_NUMBER: 'カード番号',
    BANKCARD_ADDRESS: '金融機関の場所',
    BANKCARD_CONFIRM_NOT_ERROR: '内容をもう一度ご確認ください',
    BANKCARD_NAME_ERROR: '口座名が間違っています、もう一度選択してください。',
    BANKCARD_DATA_ERROR: 'カード情報が間違っています、もう一度お試しください。',
    BANKCARD_ID_SUCCESS: 'ご本人を認証しました。',
    BANKCARD_SPACE_DONT: 'ヌル値を入力しないでください。',
    BANKCARD_MESSAGE_ONE: '同一名義のカードは最大5枚まで連携することはできます。出金に成功すると自動的にロックされ、削除や変更ができなくなります。',
    BANKCARD_MESSAGE_TWO: '今、5枚カードを連携されていますので、別のカードが連携できません。出金に成功すると自動的にロックされ、削除や変更ができなくなります。',
    BANKCARD_MESSAGE_THREE: '連携されているカードが1枚まだ使っていませんので、別のカードが連携できません。別のカードを使用する必要がある場合は、現在の連携情報を変更します。<strong>出金に成功すると自動的にロックされ、削除や変更ができなくなります。同一名義のカードは最大5枚まで連携することはできます。</strong>',
    BANKCARD_MESSAGE_FOUR: '連携されているカードが1枚まだ使っていませんので、別のカードが連携できません。別のカードを使用する必要がある場合は、現在の連携情報を変更します。出金に成功すると自動的にロックされ、削除や変更ができなくなります。同一名義のカードは最大5枚まで連携することはできます。',
    BANKCARD_MANY_BIND: 'まだ%{num}枚カードが連携できます。',
    BACKCARD_OTHER: '他の銀行',
    BANKCARD_BIND_MORE: 'まだ%{msg}枚カードが連携できます。',
    BANKCARD_IMMEDIATELY: 'セキュリティコードを設定しました。今すぐカードを連携しませんか。',
    BANKCARD_BIND_CARD: 'カードを連携',
    BANKCARD_16_NUMBER: '16桁',
    BANKCARD_17_NUMBER: '17桁',
    BANKCARD_18_NUMBER: '18桁',
    BANKCARD_19_NUMBER: '19桁',
    BANKCARD_BANKTYPE_ID: '%{msg}桁',
    // 聯絡我們
    ABOUT_TITLE: '%{msg}は2014年に設立され、最も専門的な株式投資システムを提供し、ユーザーに「安心・安全・高品質」のサービス体験を提供しています。',
    ABOUT_SUB_TITLE: '複数認証した安全取引プラットフォーム',
    ABOUT_SUB_TITLE_TWO: '%{msg}は国際的な第三者機関であるSTS、Norton、GEOTRUSTの認証を受けており、その厳しい基準のもと、マルウェアを含まないサービスを提供しています。',
    ABOUT_TEXT_ONE: '%{msg}は、「誠を尽くし、信頼でき」という長期サービスの信条に基づき、お客様に最高品質の株式取引体験を提供します。',
    ABOUT_TEXT_TWO: '%{msg}は業界より品質がいい技術サービスを提供し、即時相談サービスシステム、自社開発のアプリ端末など。',
    ABOUT_TEXT_THREE: '%{msg}は最も合理的なオッズ、マルチチャンネルの出入金方法、専門的で厳格なリスク管理、第三者資金保険を提供し、プレイヤーの資金を100％保護します。',
    LEGAL_NOTICES: '法律上の注意事項',
    LEGAL_TITLE: '本ウェブサイトで提供されるすべてのコンテンツと情報を、本ウェブサイトが所有していないサーバーにミラーリングしではいけません。',
    LEGAL_SUB_TITLE: '%{msg}は理想的で安全な取引プラットフォーム',
    LEGAL_TEXT_ONE: '本サイトで提供されるすべてのコンテンツと情報（データ、テキスト、グラフィック、画像、音声、映像など）の著作権は、本サイトまたは該当する権利者に帰属します。',
    LEGAL_TEXT_TWO: '本ウェブサイトまたは関連する権利者の書面による事前の許可なしに、いかなる方法でも本ウェブサイトのコンテンツをコピー、再作成、配布、出版、再投稿、改作、または表示することはできません。',
    LEGAL_TEXT_THREE: '同時に、本ウェブサイトに提供されているすべてのコンテンツについて、本サイトの書面による許可なく、誰でも本ウェブサイトが所有していないサーバーにミラーリングすることは禁じられています。本ウェブサイトを無断で使用すると、中華人民共和国の著作権法(《中华人民共和国著作权法》)その他の法令および関連する国際条約の規定に違反することになります。',
    CONTACT_US: 'お問い合わせ',
    CONTACT_TITLE: '%{msg}のカスタマーサービスセンターは年中無休で、24時間いつでも質の高いサービスを提供しています。',
    CONTACT_SUB_TITLE: '%{msg}第三者機関の資金保険を受けており、資金の安全性を100％保証',
    CONTACT_TEXT_ONE: '本ウェブサイトの使用に関してご質問がある場合、以下の方法でサービススタッフにご連絡いただければ、即時相談サービスをご利用いただけます。',
    CONTACT_CLICK: 'クリック',
    CONTACT_TEXT_TWO: 'オンラインお問い合わせにアクセスし、サービススタッフに連絡することができます。',
    CONTACT_CONTENT_COOPERATION: 'コンテンツコラボレーション',
    CONTACT_TEXT_THREE: '株式プレー方法、株式チップに関連する素晴らしいコンテンツやオリジナルコンテンツなどがあれば、私たちにご連絡することを歓迎しています。',
    CONTACT_ADVERTISE_COOPERATION: '広告協力',
    CONTACT_TEXT_FOUR: 'ウェブサイト上の広告スペースの交換やその他の広告リソースの自由な組み合わせを通じて、宣伝を拡大し、サービスの入り口の種類を増やします。ウェブサイトのユーザーにとっての付加価値を高めることが目的です。 適切なリソースをお持ちの方は、ぜひご連絡ください。',
    CONTACT_MEDIA_COOPERATION: 'メディアコラボレーション',
    CONTACT_TEXT_FIVE: 'インターネット、WeChat、Weiboなどの伝統的なメディアや新しいメディアに豊富なリソースをお持ちの方は、ぜひ私たちにご連絡していただき、友好的な協力関係を通じて共に影響力を高めていきましょう。',
    CHOOSE: '%{msg}を選択',
    CHOOSE_TITLE: '%{msg}は、トレーダーの皆様に、金融商品の取引における高品質の体験サービスを提供し、市場で持続的な収益をできるにしたいと考えています。',
    CHOOSE_SUB_TITLE: '%{msg}は複数第三者機関国際認証を取得',
    CHOOSE_DEAL_TRANSPARNET: '取引の透明性',
    CHOOSE_TEXT_ONE: 'アジアのトップディーラーとして、お客様に最高レベルの透明性を提供し、取引執行におけるトレーダーの介入を排除しています。',
    CHOOSE_TEXT_TWO: ' 顧客の中には、多くのプロのトレーダーが%{msg}の操作機能の全体的と透明性を認めています。',
    CHOOSE_DEAL_SYSTEM: '強力な取引システム',
    CHOOSE_TEXT_THREE: '%{msg}は今、トップの実力を持ち、ユーザーに最も人気のある取引プラットフォームです。APPデュアルプラットフォームが取引できることを独立開発に専念しています。',
    CHOOSE_TEXT_FOURE: '%{msg}提供している商品は迅速で簡単な取引が体験できます。',
    CHOOSE_ORDER_SPEED: '圧倒的なオーダーフルフィルメントのスピードです。',
    CHOOSE_TEXT_SIX: '%{msg} は超高速実行を可能にする最先端技術を用い、ネット環境の最適化と安定化を実現します。',
    CHOOSE_TEXT_FIVE: '良い技術ソリューションにより、即時の取引が現実のものとなり、トレーダーは、刻々と変化する市場で有利な条件が得ます。',
    CHOOSE_INTERNATIONAL_NORMS: '国際標準規格に準拠',
    CHOOSE_TEXT_SEVENT: '%{msg} はTST、Norton、GEOTRUSTといった国際的な第三者機関の認定を受け、その厳しい基準のもとでサービスを提供しています。',
    CHOOSE_MONEY_SAFE: '資金の安全性、原価特恵',
    CHOOSE_TEXT_EIGHT: '%{msg} はアジアの一流銀行を顧客資金の信託銀行に指定し、顧客資金が独立保管されます。会社の運転資金とは別にしています。',
    CHOOSE_TEXT_NINE: '当社は、資金へのアクセスがお客様が許可した取引のみできます。',
    CHOOSE_TEXT_TEN: '%{msg}はサービス時間中に、引き出しと即日のお金到着を保証します。',
    CHOOSE_TEXT_ELEVEN: '%{msg}トッププロセスサービスプロバイダーと協力し、お客様に競争力のあるスプレッドとフレキシブル取引条件を提供します。',
    CHOOSE_HIGH_QUALITY: '色々なコモディティ、質の高いサービス',
    CHOOSE_TEXT_TWELVE: '%{msg}は外国為替、貴金属、仮想通貨を含む40以上の取引オプションを提供します。',
    PRIVACY_STATEMENT: 'プライバシー声明',
    PRIVACY_TITLE: '本ウェブサイトで提供されるすべてのコンテンツと情報を、本ウェブサイトが所有していないサーバーにミラーリングしではいけません。',
    PRIVACY_TEXT_ONE: 'ログイン時にお客様の個人情報をご提供いただくのは、お客様の当社に対する信頼に基づいています。私たちは、お客様の個人情報を慎重に、そして責任を持って取り扱います。',
    PRIVACY_TEXT_TWO: 'そのため、個人情報の保護体制を確立し、お客様の個人情報を保護しています。いかなる手段であれ、本ウェブサイトにアクセスした方、本ウェブサイトの情報を直接または間接的に使用した方は、本ウェブサイトの声明に自発的に拘束されているとみなされます。当社の個人情報保護の条件は下記',
    PRIVACY_MESSAGE: '個人情報の収集',
    PRIVACY_TEXT_THREE: '個人情報の収集。登録・利用する際に提供した個人情報は、全てお客様の意思によってご提供いただくものです。なお、サービスの利用に関連して当社が取得する情報の全部又は一部をご提供いただけない場合は、サービスをご利用いただけない場合がございますので、あらかじめご了承下さい。また、当社は、本のウェブサイトのより良いサービスをご提供できるよう、ユーザーエクスペリエンスを最適化する目的で、ご利用者の個人データを収集、使用する可能性があります。',
    PRIVACY_PROTECT: 'プライバシー保護',
    PRIVACY_TEXT_FOUR: 'プライバシー保護。本サイトでは、お客様のプライバシーを保護することを基本方針としています。 本サイトでは、以下の場合を除き、お客様が本サイトに登録した情報を公開・配布することはありません。',
    PRIVACY_ITEM_ONE: '事前にユーザーから明示的な承認を取得します。',
    PRIVACY_ITEM_TWO: 'ご自身の情報が不適切な自己保存により、ご自身の情報が漏洩すること。',
    PRIVACY_ITEM_THREE: 'ネットワーク回線、ハッカーの攻撃、コンピュータウイルス、政府の規制などによるデータの漏洩、紛失、盗難、改ざんなど。',
    PRIVACY_ITEM_FOUR: '法令及びその他規範に基づき',
    PRIVACY_ITEM_FIVE: '裁判所、仲裁機関の決定または裁定に基づいて、その他の司法手続きの要件を満たしていること。',
    PRIVACY_ITEM_SIX: '関連する政府当局の要求に基づき',
    PRIVACY_PUBLIC: 'ご自身更新と情報公開',
    PRIVACY_TEXT_FIVE: 'お客様には、個人情報を安全かつ有効なものにするために、更新や修正を行うことをお勧めします。',
    PRIVACY_TEXT_SIX: 'いつでも簡単にご自身の個人情報にアクセスし、変更することができます。また、ご自身の判断により、いつでもウェブサイト上でご自身の情報を修正・削除することができます。 アカウントとパスワードの情報の機密性については、お客様ご自身が唯一の責任者となりますので、いかなる場合でも安全性を確保するようご注意ください。',
    PRIVACY_TEXT_SEVEN: 'お客様がパブリックで自発的に個人情報を開示すると、個人情報は他者によって収集され使用される可能性があり、その結果としてお客様の個人情報が漏洩されたとしても、本ウェブサイトは責任を負いません。',
    PRIVACY_HIT: '注意',
    PRIVACY_TEXT_EIGHT: '本プライバシーポリシーは改定されることがあります。改定内容をこちらに公開しいたします。改定内容はお客様の権利と満足を考えて行われます。当社は、お客様が本ウェブサイトにご利用するたびに、プライバシーポリシーをご確認するようお願いします。また、サービスの利用を継続することにより、お客様は内容の変更に同意したものとみなされます。',
    // 聊天室
    CHATROOM: 'トークルーム',
    CHATROOM_NO_ROOM: '今、開いているルームがありません。',
    CHATROOM_DELETE_ROOM: 'ルームが削除されました',
    CHATROOM_ALL_SHOW_ROOM: '全てのルームを表示しました',
    CHATROOM_CELL: 'セル',
    CHATROOM_CONTENT: '内容',
    CHATROOM_YOU: 'あなた',
    CHATROOM_SPEND_IMG: '写真を送信しました',
    CHATROOM_SHARE_BET_SLIP: 'オーダーをシェアしました',
    CHATROOM_SPEND_PLAN: 'プランを送信しました',
    CHATROOM_SPEND_RED_ENVELOPE: 'プレゼントを送っています',
    CHATROOM_ALREADY_DELETE: 'ルームが削除されました',
    CHATROOM_YESTERDAY: '昨日',
    CHATROOM_BEFORE_YESTERDAY: '一昨日',
    CHATROOM_ALL_DATA: '全てのデータを表示しました',
    CHATROOM_FROM_WEB: 'この画像はインターネットから',
    CHATROOM_FORVER: '永久',
    CHATROOM_WEEK: '1週間',
    CHATROOM_DAY: '1日',
    CHATROOM_HOUR: '1時間',
    CHATROOM_HALF_HOUR: '30分',
    CHATROOM_UNMUTE: 'トーク禁止をキャンセル',
    CHATROOM_COLLECTION_SUCCESS: '保存が成功しました',
    CHATROOM_CONNECTION_FAILED: '接続に失敗しました、後でもう一度試してください',
    CHATROOM_UNMUTE_FORVER: 'トーク禁止が永久にられました。',
    CHATROOM_UNMUTE_DAY: 'トーク禁止は１日られました。',
    CHATROOM_UNMUTE_WEEK: 'トーク禁止は１週間られました。',
    CHATROOM_UNMUTE_HOUR: 'トーク禁止は１時間られました。',
    CHATROOM_UNMUTE_HAL_HOUR: 'トーク禁止は30分られました。',
    CHATROOM_ACCOUNT_CHAG: 'アカウントの権限が変更されましたので、再度トークルームにお入りください。',
    CHATROOM_UNMUTE_LEVEL: 'あなたのレベルはトーク禁止がされました',
    CHATROOM_WS_FAILED: 'wsを受取が失敗しました。',
    CHATROOM_CONGRATULATE: 'おめでとうございます！',
    CHATROOM_NOT_FOLLOW_UP: '访ゲストはフォローできないので、まず登録又はログインしてください。',
    // 聊天室component
    CHATCELL_ALL_UNMUTE: '全員トーク禁止',
    CHATCELL_ENTER: 'メッセージを入力',
    CHATCELL_IMG: '画像',
    CHATCELL_RED_ENVELOPE: 'プレゼント',
    CHATCELL_NOT_SPEND: 'ゲストはプレゼントを送ることができないので、まず登録又はログインしてください。',
    CHATCELL_NOT_SET_PASSWORD: 'セキュリティコードをまだ設定していないので、プレゼントを送ることができません。セキュリティコードを設定しませんか。',
    CHATCELL_OPEN_HELPER: 'ゲストはアシスタントの機能が操作できないので、まず登録又はログインしてください。',
    CHATCELL_IMG_LOAD_FAIL: '画像の読み込みに失敗しました。',
    CHATCELL_MUTE_MANAGEMENT: 'トーク禁止管理',
    CHATCELL_ROOM_INTRODUCE: 'トークルーム紹介',
    CHATCELL_LINE: '社群守則，但是「限制」的力量是有限的，真正重要的是每一位使用者，能了解、並遵守LINE社群守則，不只自己使用更安心，也才能讓「LINE社群」這個新功能發揮所長，好好來服務大家。',
    CHATCELL_EXPIRED: 'このプレゼントは、12時間以上にわたって提供されています。受け取った場合は、”私のプレゼント”で確認できます。',
    CHATCELL_ROBBED: '全部受け取った',
    CHATCELL_CHECK_RED_ENVELOPE: 'このプレゼントを受け取りました。”私のプレゼント”で確認できます。',
    CHATCELL_GET_DETAILS: '受取一覧を見る',
    CHATCELL_WHO_RED_ENVELOPE: '%{msg}のプレゼント',
    CHATCELL_SPELL: 'ラッキー',
    CHATCELL_GENERAL: '一般',
    CHATCELL_THE: '個人',
    CHATCELL_DOLLAR: '円',
    CHATCELL_SAVE_BALANCE: '残高に入金しました。投資することに使えます。',
    CHATCELL_GET_COUNT: '受け取った%{num}/%{total}つ',
    CHATCELL_NUM_TOTAL: '合計%{num}/%{total}円',
    CHATCELL_TOTAL: '合計%{num}円',
    CHATCELL_ROBBED_ALREADY: '%{num}つプレゼントが合計%{total}円、全部配りました。',
    CHATCELL_LACKY: 'ラッキー',
    CHATCELL_NOT_RECORD: '受け取ったデータなし',
    CHATCELL_OUT_RECORD: '発注データなし',
    CHATCELL_TEMPORARY_RECORD: 'データなし',
    CHATCELL_RESIVE_RED_ENVELOPE: '受け取ったプレゼント',
    CHATCELL_SEVEN_DAT_TWO: '前7日、合計%{num}つプレゼントを受け取りました。',
    CHATCELL_PLAYER_MESSAGE: 'プレヤー情報',
    CHATCELL_ADMINISTRATOR: '管理員',
    CHATCELL_ALL_PEOPLE: '全員',
    CHATCELL_NEWS: '%{num}件の新着メッセージ',
    CHATCELL_HISTORICAL_OUT: '発注履歴',
    CHATCELL_DATE: '%{msg}期',
    CHATCELL_NOT_GET: '当選なし',
    CHATCELL_GET: '当選',
    CHATCELL_WAIT: '待機中',
    CHATCELL_DRAW: 'タイ',
    CHATCELL_FALL: '下落',
    CHATCELL_BEAR: 'ベア',
    CHATCELL_SINGLE: '奇',
    CHATCELL_UP: '上昇',
    CHATCELL_PAIR: '偶',
    CHATCELL_COW: 'ブル',
    CHATCELL_BIND_SUCCESS: '連携しました。',
    CHATCELL_NOT_EMPTY: '内容はヌル値を入力しないでください。',
    CHATCELL_RECORD_UNMUTE: '内容は必須入力項目です。',
    CHATCELL_NOT_SET_NICKNAME: 'ニークネームはまだ設定していません。',
    // 紅包
    MAIL_SPEND: 'プレゼントを送る',
    MAIL_USE_RULES: 'プレゼントを送信ルール',
    MAIL_USE_MONEY: '出金できる出金金額は%{num}円以上があれば、',
    MAIL_USE_MONEY_TWO: '，プレゼントの送る機能が使用できます。',
    MAIL_SINGEL_MONEY: '一つの金額',
    MAIL_TOTAL: '合計金額',
    MAIL_NUMBER: 'プレゼントの数量',
    MAIL_TWELVE_HOURS: '未受領のプレゼントの返金は、12時間後に開始されます。',
    MAIL_LOW_ONE: ' お一人もらったプレゼントの平均金額は、',
    MAIL_HEIGH_DOLLAR: '最低額 %{one} 円 最高額で %{num}  円です。',
    MAIL_INPUT_PASSWROD: 'セキュリティコードを入力してください。',
    MAIL_SIX_PASSWORD: 'セキュリティコーは6桁数字です。',
    MAIL_HAVE_INTEGER: 'プレゼント数は正の整数でなければなりません。',
    MAIL_FIVE_HUNDRED: 'プレゼントの数は500個を超えることができません。',
    MAIL_BIG_DOLLAR: 'お一人もらったプレゼントの平均金額は、 %{num} 円以上にはできません',
    MAIL_CASH_NOT_ENOUGH: '出金できる出金金額が不足しています。',
    MAIL_GRAND_TOTAL: '毎日送信できるプレゼント金額が%{total}円を超えることはできませんが、まだ{num}円を送信することができます',
    MAIL_LIMIT_DAY: '毎日プレゼントが送れる数の制限が1日の限度%{num}回を超えてしました',
    MAIL_LESS_DOLLAR: 'お一人もらったプレゼントの平均金額は、最低限1円です。',
    MAIL_NOT_EMPTY: '不能为空',
    MAIL_WRONG_FORMAT: '格式不对',
    MAIL_LESS_DOLLAR_TWO: '一つのプレゼントの金額は、1円以下であってはなりません。',
    MAIL_REMARK: '備考は必須入力項目です。',
    MAIL_LACKY: 'ラッキープレゼントに変更しました。',
    MAIL_NORMAL: '一般プレゼントに変更しました。',
    MAIL_ADD_FACE: '絵文字追加',
    MAIL_TIDY: '整理',
    MAIL_FIRST_LOGIN: 'ゲストの方が絵文字画像を収集することはできません。まずログインしてください。',
    MAIL_DELETE: '削除中',
    MAIL_COMPRESSION: '圧縮中',
    MAIL_UPLOAD: 'アップロード中',
    MAIL_ASSISTANT: 'アシスタント',
    MAIL_CANT_OVER: ' 一つのプレゼントの金額は<span class="color-danger">%{num}</span>円を超えることができません',
    MAIL_BIGGER_OUT: '一回のプレゼントの合計金額は出金できる出金金額を超えることができません。',
    MAIL_ACCOUNT_SERACH: 'アカウントIDを検索する',
    MAIL_ALREADY_GET: '全部受取られてしまった',
    MAIL_RETURN: '返金した',
    MAIL_RECEIVE: '受け取った',
    MAIL_EXPIRED: '期限切れ',
    MAIL_VISITOR_GRAB: 'ゲストの方はプレゼントを受け取れませんので、まずログインしてください。',
    MAIL_MESSAGE: 'ラッキー！プレゼントをゲットしよう！',
    MAIL_TIME_UP: '期間限定プレゼント',
    // UserInfo.vue
    USERINFO_LIKE: 'このユーザーのお気に入り株式',
    USERINFO_TEST: 'テストグループ',
    USERINFO_SET_NICKNAME: 'ニークネームはまだ設定していません。',
    USERINFO_SECRET: '非公開',
    USERINFO_BRONZE: 'ブロンズ',
    USERINFO_SILVER: 'シルバー',
    USERINFO_GOLD: 'ゴールド',
    USERINFO_PLATINUM: 'プラチナム',
    USERINFO_DIAMOND: 'ダイヤモンド',
    USERINFO_CRYSTAL: 'クリスタル',
    USERINFO_CROWN: 'クラウン',
    USERINFO_STARSHINE: 'スターシャイン',
    USERINFO_KING: 'キング',
    USERINFO_GAMBLERS: 'ギャンブラーの神',
    USERINFO_MANAGE: '管理',
    // 出金 Sam
    OUTGOLD_CARD: 'カード',
    OUTGOLD_OUT: 'まだ出金できる回数：%{num}回',
    OUTGOLD_CASH: '出金金額',
    OUTGOLD_WATER: 'バンクフローは{num}円が不足です',
    OUTGOLD_DEAL: '取引へ',
    OUTGOLD_BALANCE: '引き出せ残高は %{num} X円',
    OUTGOLD_HANDLING_FEE: '手数料 %{num} 円',
    OUTGOLD_RULES: '出金ルール',
    OUTGOLD_DETAILS: '出金詳細',
    OUTGOLD_DEDUCT: '1回の引き出しにつき %{num}%の手数料',
    OUTGOLD_LOW_HEIGH: '1回の引き出し最低額%{num}円、最高額%{num}円',
    OUTGOLD_WATER_ENOUGH: 'バンクフローが足りたら、出金の申請することができます。',
    OUTGOLD_I_KNOW: 'わかりました',
    OUTGOLD_CONFIRM_ID: 'ご本人の認証',
    OUTGOLD_LIMIT_TIME: '引き出しの申請回数が上限を超えました。',
    OUTGOLD_CUSTOMER: '今、お金が引き出せません、お問い合わせにご連絡ください。',
    OUTGOLD_MESSAGE: '出金手数料は出金額の%{num}％で、出金成功後に残高から直接差し引かれます。',
    OUTGOLD_ALL: '全部引き出す',
    // 出金components
    OUTGOLD_RECORD: '出金履歴',
    OUTGOLD_FAIL: '出金が失敗',
    OUTGOLD_SHOW_ALL: '%{num}の全部履歴が表示されました。',
    OUTGOLD_MESSAGE_ONE: 'システムが出金処理を完了するまで、しばらくお待ちください。',
    OUTGOLD_MESSAGE_TWO: '処理完了しました。システムが金額をお客様の口座に入金しました。',
    OUTGOLD_MESSAGE_THREE: 'システムが出金請求をキャンセルしました。',
    OUTGOLD_MESSAGE_FOUR: 'システムが出金請求を拒否しました。',
    OUTGOLD_TEXT_ONE: '1.1回の引き出しにつき1%の手数料',
    OUTGOLD_TEXT_TWO: '2.1回の引き出し最低額1円、最高額2000000円',
    OUTGOLD_TEXT_THREE: 'システムが出金請求を拒否しました。',
    OUTGOLD_TEXT_FOUR: 'システムが出金の立替払い請求をキャンセルしました。',
    OUTGOLD_TEXT_FIVE: 'システムが出金の立替払い請求を拒否しました。',
    OUTGOLD_TEXT_SIX: '処理完了しました。システムが金額をお客様の口座に入金しました。',
    OUTGOLD_TEXT_SEVEN: 'システムが出金処理を完了するまで、しばらくお待ちください。',
    // BaseSecurity.vue
    GUARD_ACCOUNT_EMPTY: 'アカウントIDは必須入力項目です。',
    GUARD_CORRECT_ACCOUNT: '正しいロクインIDを入力してください。',
    GUARD_PASSWORD_EMPTY: 'パスワードは必須入力項目です。',
    GUARD_CORRECT_PASSWORD: '正しいパスワードを入力してください。',
    GUARD_PASSWORD_ENTER: 'パスワードは6～16桁のアルファベット、数字です。',
    GUARD_INVITE_EMPTY: '招待コードは必須入力項目です。',
    GUARD_CORRECT_INVITE: '正しい招待コードを入力してください。',
    GUARD_SING_EMPTY: 'ログインIDは必須入力項目です。',
    GUARD_ACCOUNT_ENTER: 'ログインIDは4～16桁のアルファベット、数字です。',
    GUARD_ACCOUNT_LETTER: 'IDには文字のみを含めることができます',
    GUARD_ACCOUNT_MEMEBER: 'IDは数字のみを含めることができます',
    GUARD_VERIFY_EMPTY: '認証コードは必須入力項目です。',
    GUARD_PHONE_EMPTY: '携帯番号は必須入力項目です。',
    GUARD_PHONE_CORRECT: '正しい携帯番号を入力してください。',
    GUARD_EMAIL_EMPTY: 'メールアドレスは必須入力項目です。',
    GUARD_CORRECT_EMAIL: '正しいメールアドレスを入力してください。',
    GUARD_QQ_EMPTY: 'QQアカウントは必須入力項目です。',
    // Components file
    ELEMENT_SHARE: 'シェア',
    ELEMENT_SHARE_SUCCESS: 'シャアしました。',
    ELEMENT_MUTE: 'トーク禁止がされました。',
    ELEMENT_FOLLOW: 'フォローする',
    ELEMENT_SHARE_WAR: '戦績をシェアする',
    ELEMENT_HIT_RATE: 'トータルヒット率',
    ELEMENT_WIN_RATE: '今日の勝率',
    ELEMENT_DONT_TALK: 'トーク禁止',
    ELEMENT_RECALL: 'キャンセル',
    ELEMENT_SHARE_MEMBER: 'シェアの会員',
    ELEMNET_INVESTMENT_CONTENT: '投資内容',
    ELEMENT_SYSTEM_TEST: 'システムテスト',
    ELEMENT_STRAIGHT: 'お問い合わせにご連絡ください',
    ELEMENT_CUSTOMER_SERVICE: '联系客服',
    ELEMENT_FINANCIAL_INFORMATION: '経済情報',
    ELEMENT_LOOK_EVERYWHERE: 'ノーモア、他のところへ見に行く',
    ELEMENT_SPEND_MEMEBER: '推送会员',
    ELEMENT_INPUT_UNIT: '请输入投资单位',
    ELEMENT_INVEST_UNIT: '投资单位',
    ELEMENT_DATE: '{prv}期が締め切りました、今は{now}期',
    ELEMENT_CASH: '金额',
    ELEMENT_ENTER_BET_SLIP: 'ベット%{msg}を入力してください。',
    ELEMENT_SEACH_RECORD: '投資が成功しました。投資履歴は「私のアカウント」で確認できます。',
    ELEMENT_CHECK_BET_SLIP: '注文チェック',
    ELEMENT_GO_ON: '投資を続ける',
    ELEMENT_INVEST_ERROR: '投資する期間番号が間違っています。',
    ELEMNET_REBATE: 'リベート',
    ELEMENT_WAIT_DRAW: '抽選を待つ',
    ELEMENT_DECIMAL: '小数、ダブルサイド',
    ELEMENT_NOT_WINNING: '当選なし',
    ELEMENT_WIINNING: '当選',
    ELEMENT_WAIT_PROFIT: '収益を待つ',
    ELEMENT_PAY_CASH: '支払った',
    ELEMENT_TEXT_ONE: '1.QQウォレットを利用して、上記口座にご送金ください。',
    ELEMENT_TEXT_TWO: '2.QRコードの場合は、QQウォレットでスキャンしてご送金ください。銀行口座の場合は、QQウォレットを使ってカードに振り込んでください。',
    ELEMENT_TEXT_THREE: '4.QQウォレットのニックネームとチャージの金額を正しくご記入ください。QQウォレットのニックネームは、QQウォレットのアカウントIDではありませんので、その区別にご注意ください。',
    ELEMENT_IS_SUBMIT: '送信しています。',
    // MODULE file
    MODULE_GET_CASH: 'おめでとう！',
    MODULE_CASH_DOLLAR: '円をゲットしました。 ',
    MODULE_FINISHED: '全部受け取られてしました。',
    MODULE_DATE: '期間切れ',
    MODULE_CONDITION_STANDARD: '条件に達成していませんので、今すぐチャージしませんか。',
    MODULE_ROOM_PASSWORD: 'ルームパスワード',
    MODULE_WRITE_ROOM: 'ルームパスワードを入力してください。',
    MODULE_ROOM_MESSAGE: 'ルームのパスワードは4桁で、ルームにアクセスする際に使用します。',
    MODULE_ROOM_MESSAGE2: '数回入力エラーになると、当日に再度入力することができません。',
    MODULE_NO_MORE: 'ノーモア',
    MODULE_PROXY: '代理レベル%{num}',
    MODULE_PLAYER: 'プレヤーレベル%{num}',
    MODULE_SHARE_ROOM: 'ルームをシェアする',
    MODULE_FIX_SHARE_CASH: 'シェアオーダーの金額を変更する',
    MODULE_REFIX_DEAL: 'シェアオーダーの金額をご修正ください。',
    MODULE_HOW_DATE: '%{num}期',
    // notify
    NOTIFY_BUY_SUCCESS: '購入しました',
    NOTIFY_UPDATE_DONE: '修正が完了、変更内容を保存しました。',
    NOTIFY_LOOP_DISABLED: 'サイクルが停止しています。',
    NOTIFY_SET_SUCCESS: '設定が完了、変更内容を保存しました。',
    NOTIFY_SAFEPASSWORD_DONE: 'セキュリティコードの設定完了',
    NOTIFY_BANKCARD_ADD: 'カードの追加が成功しました。',
    NOTIFY_COPY_WATER: '注文番号をコピーしました。',
    NOTIFY_COPY_ACCOUNT: '口座番号をコピーしました。',
    NOTIFY_COPY_NAME: '口座名義をコピーしました。',
    NOTIFY_COPY_SUCCESS: 'コピーしました。',
    NOTIFY_COPY_BANK: '銀行名をコピーしました。',
    NOTIFY_COPY_OPEN: '銀行支店をコピーしました。',
    NOTIFY_UPDATE_SUCCESS: '修正が完了、変更内容を保存しました。',
    NOTIFY_INVITE_DELETE: '招待コードが削除されました。',
    NOTIFY_COPY_PASSWORD: '生成パスワードをコピーしました。',
    NOTIFY_CANCEL_ORDER: '注文取消しました。',
    NOTIFY_MUTE_SUCCESS: 'トーク禁止を設定しました。',
    NOTIFY_MUTE_CANCEL: 'トーク禁止をキャンセルしました。',
    NOTIFY_FIXED_BUG: '変更エラー',
    NOTIFY_ID_ERROR: 'ご本人の認証エラー',
    NOTIFY_PASSWORD_NOT_SAME: 'セキュリティコードが一致しません。',
    // ---
    VIRTUAL_WALLET_WALLET_BALANCE: 'ウォレット残高',
    VIRTUAL_WALLET_WATER_INSUFFICIENT: 'バンクフローが不足',
    VIRTUAL_WALLET_RECEIVABLE: '引き出せ金額',
    RECHARGE_INPUT_TITLE: '入金方法',
    RECHARGE_INPUT_HINTTEXT: '最低額',
    RECHARGE_ACCOUNT＿INFORMATION: 'アカウント情報',
    RECHARGE_DEPOSITOR_NAME: '預金者の氏名',
    RECHARGE_DEPOSITOR_INPUTNAME: '預金者の氏名をご記入ください。',
    RECHARGE_DEPOSIT_AMOUNT: '入金金額',
    RECHARGE_VIRTUAL_WALLET_TEXT: 'ウォレット残高は',
    RECHARGE_FULL_DEPOSIT: '全部入金',
    RECHARGE_ACCOUNT_INFORMATION: 'アカウント情報',
    RECHARGE_SCAN_CODE_TEXT: 'QRコードをスキャン、クリックで保存',
    RECHARGE_DEPOSIT_DETAILS: '入金詳細',
    RECHARGE_ORDER_TITLE: 'オーダー情報',
    RECHARGE_ORDER_MSG: '制限時間内に取引を完了し、「入金確認」ボタンをクリックしてください。制限時間を過ぎても取引が完了しない場合、システムは自動的に入金をキャンセルします。',
    RECHARGE_CREATE_ORDER_BTN: 'オーダーを作成する',
    RECHARGE_RULE: '入金ルール',
    RECHARGE_COPYEND: 'コピーしました。',
    RECHARGE_SAVEQRCODE: 'QRコードを保存しました。',
    RECHARGE_NO_SAVEQRCODE: '現時点ではQRコードに対応していません',
    RECHARGE_SIDE_HEADER_TITLE: '入金履歴',
    RECHARGE_SIDE_ALERT_TEXT1_1: '1.%{msg}をご利用し、上記口座に振り込んでください。',
    RECHARGE_SIDE_ALERT_TEXT2_1: '2.QRコードの場合は、Xをご利用し、スキャンしてご送金ください。銀行口座の場合は、%{msg}をご利用し、スキャンしてご送金ください。銀行口座の場合は、%{msg}をご利用し、カードに振り込んください。',
    RECHARGE_SIDE_ALERT_TEXT3_1: '3.ご送金の際には、アカウントIDを備考に記載していただくと、即時に確認ができます。',
    RECHARGE_SIDE_ALERT_TEXT4_1: '4.お客様のニックネーム%{msg}とチャージの金額を正しくご記入ください。お客様のニックネーム%{msg}はチャージのアカウントID%{msg}ではありませんので、その区別にご注意くださ',
    RECHARGE_SIDE_ALERT_TEXT5_1: '5.オーダーを送信する前に、必ずお振込みをお願いします。そうしないと、金額の確認が間に合いませんので、重複送信しないでください。',
    RECHARGE_SIDE_ALERT_BTN_TEXT: '分かりました。',
    RECHARGE_SIDE_OPEN_POP_STATE0_INFO: 'システムが入金処理を完了するまで、しばらくお待ちください。',
    RECHARGE_SIDE_OPEN_POP_STATE1_INFO: '処理完了しました。システムが金額をお客様のアカウントに入金しました。',
    RECHARGE_SIDE_OPEN_POP_STATE2_TITLE: '入金が失敗',
    RECHARGE_SIDE_OPEN_POP_STATE2_INFO: '制限時間切れでも取引が完了しませんので、システムは今回の入金がキャンセルされています。',
    RECHARGE_SIDE_OPEN_POP_STATE4_INFO: '制限時間内に取引を完了し、「入金確認」ボタンをクリックしてください。制限時間を過ぎても取引が完了しない場合、システムは自動的に入金をキャンセルします。',
    RECHARGE_SIDE_OPEN_POP_ERROR_TITLE: '入金が失敗',
    RECHARGE_SIDE_OPEN_POP_ERROR_INFO: '制限時間内に取引を完了し、「入金確認」ボタンをクリックしてください。制限時間を過ぎても取引が完了しない場合、システムは自動的に入金をキャンセルします。',
    RECHARGE_SIDE_BANKITEM_WALLET_ACCOUNT_NAME: 'ウォレットID',
    RECHARGE_SIDE_BANKITEM_WALLET_NICK_NAME: 'ウォレットのニックネーム',
    RECHARGE_SIDE_BANKITEM_UNION_ACCOUNT: '銀聯口座',
    RECHARGE_SIDE_BANKITEM_UNION_ACCOUNT_NAME: '銀聯名義人',
    RECHARGE_SIDE_BANKITEM_UNION_NAME: '口座番号',
    RECHARGE_SIDE_BANKITEM_ACCOUNT: '口座名義人',
    RECHARGE_SIDE_BANKITEM_NAME: '口座名義人',
    RECHARGE_SIDE_BANKITEM_BANK_NAME: '口座の金融機関',
    RECHARGE_SIDE_BANKITEM_ALI_ACCOUNT: 'アリペイアカウント',
    RECHARGE_SIDE_BANKITEM_RECEIVING_ACCOUNT: 'アカウント番号',
    RECHARGE_SIDE_BANKITEM_ALI_NAME: 'アリペイ名義人',
    RECHARGE_SIDE_BANKITEM_OPEN_AN_ACCOUNT: '金融機関',
    RECHARGE_SIDE_BANKITEM_OPEN_API_ALERT: '入金金額が違います。',
    RECHARGE_SIDE_BANKITEM_OPEN_API_ALERT1: 'この入金方法の最低額',
    RECHARGE_SIDE_BANKITEM_AISLE: 'チャネル',
    RECHARGE_BASEBANKDATA_SELECTMONEY_INFO: '金額は最高額を超えることができません。',
    RECHARGE_BASEBANKDATA_DECIMAL_MSG: 'お客様の金額をより正確に確認するために、システムは入金額が%{msg}を調整しました。',
    RECHARGE_BASEBANKDATA_CHANGEURL_MSG: '引き出し申請を送りました。3~5分間の後で受け取るかどうか、ご確認ください。',
    RECHARGE_BASEBANKDATA_CHANGEURL_BTN1: '履歴を見る',
    RECHARGE_BASEBANKDATA_CHANGEURL_BTN2: 'このページで',
    RECHARGE_BASEBANKDATA_ERROR_PASSWORD_EMPTY: 'セキュリティコードは必須入力項目です。',
    RECHARGE_BASEBANKDATA_ERROR_SAFEPASSWORD: 'セキュリティコードのフォーマットが違います。',
    RECHARGE_BASEBANKDATA_ERROR_EMPTY: '必須入力項目です。',
    RECHARGE_BASEBANKDATA_ERROR_TYPE: 'フォーマットが間違っています。',
    RECHARGE_BASEBANKDATA_ERROR_CHARGE_MONEY: '入金金額の範囲は%{min}円ー%{max}円間',
    RECHARGE_BASEBANKDATA_ERROR_BANK_EMPTY: '口座の金融機関は必須入力項目です。',
    RECHARGE_BASEBANKDATA_ERROR_BANKCARD_EMPTY: 'カード番号は必須入力項目です。',
    RECHARGE_BASEBANKDATA_ERROR_BANKCARD_TYPE: 'カード番号のフォーマットが違います。',
    RECHARGE_BASEBANKDATA_ERROR_NAME_EMPTY: '口座名義人は必須入力項目です。',
    RECHARGE_BASEBANKDATA_ERROR_NAME_TYPE: '名義人のフォーマットが違います。',
    RECHARGE_BASEBANKDATA_ADD_EMPTY: '場所は必須入力項目です。',
    RECHARGE_BASEBANKDATA_EMPTY: '必須入力項目です。',
    RECHARGE_BASEBANKDATA_ERROR_BANKNAME_EMPTY: '金融機関名は必須入力項目です。',
    RECHARGE_BASEBANKDATA_ERROR_BANKNAME_TYPE: '银行名称格式不对',
    RECHARGE_BASEBANKDATA_SETINTERVALFUNC_MSG: '頻繁にオーダーを送らないでください。（%{msg}後で、再試行してください。）',
    SECOND: '秒',
    MINUTE: '分',
    RECHARGE_BASEBANKDATA_TEXT: '間',
    RECHARGE_BASEBANKDATA_JDNAME: 'ジンドンアカウント名',
    RECHARGE_BASEBANKDATA_JDMSG: 'ジンドンアカウント名をご記入ください。',
    FORGOTPASSWORD_TITLE: 'パスワードを取り戻す',
    FORGOTPASSWORD_INFO: 'アカウントからパスワードを取り戻します。',
    FORGOTPASSWORD_INPUT_LABEL: 'ログインID',
    FORGOTPASSWORD_SELECT_TYPE_SAFEPASSWORD: 'セキュリティコードで',
    FORGOTPASSWORD_CONTACT_SERVICE: 'オンラインお問い合わせにご連絡ください。',
    FORGOTPASSWORD_NEXT_BTN: '次へ',
    FORGOTPASSWORD_QAINPUT: 'お答えをを入力してください。',
    FORGOTPASSWORD_ALERT_MSG: 'お問い合わせ',
    FORGOTPASSWORD_SHOWPROMPT_MSG: 'パスワードが違いますので、再度入力してください。',
    FORGOTPASSWORD_SHOWPROMPT_CONFIRM_MSG: '変更が完了しました。すぐログインにしますか。',
    FORGOTPASSWORD_SHOWPROMPT_CONFIRM_OKBTN: '今すぐログイン',
    FORGOTPASSWORD_SHOWPROMPT_CONFIRM_CANCELBTN: 'ホームに戻す',
    MEMBERCENTER_CONFIRM_PW_YES: 'はい',
    MEMBERCENTER_CONFIRM_PW_NO: 'いいえ',
    SET_PHONE_NUMBER_LABEL: '携帯番号',
    SET_PHONE_NUMBER_PLACEHOLDER: '携帯番号を入力してください。',
    SET_PHONE_NUMBER_MAIL_LABEL: 'SMS認証コード',
    SET_PHONE_NUMBER_MAIL__PLACEHOLDER: 'SMS認証コードを入力してください。',
    SET_PHONE_SENDING: '送信中...',
    SET_PHONE_CONFIRM_MSG: 'パスワード保護の携帯連携はしばらく使えません。',
    BASELOTTERY_NET_ERROR: 'サーバーの時刻が同期されていないため、投資することができません。',
    // PC
    PC_SAFE_VERIFY: 'セキュリティ検証',
    PC_KLINE_CHART: 'ローソク足',
    PC_TREND_CHART: '移動平均線',
    PC_MARKET_CHART: 'トレンド',
    PC_SYSETM_BUILD: 'システムが開発していますので、暫く使用できません。',
    PC_STOCK_INDEX: '銘柄名',
    PC_ISSUE: '期号',
    PC_OUTGOING_INDEX: '発注指数',
    PC_TREND: '傾向',
    PC_INSTANT_PROFIT: 'ランキング',
    PC_TOTAL_COUNT: '合計',
    PC_TOTAL_MATH: '合計',
    PC_NOT_SELECTED: '未選択',
    PC_DOUBLE_DISC: 'ダブルサイド%{num} つ ¥ %{total}',
    PC_POSITION_DISC: 'ポジショニング%{num} つ ¥  %{total}',
    PC_PLAY_EXPLAN: 'チュートリアル',
    PC_MODEL: 'モード',
    PC_GENERAL_DEAL: '一般取引',
    PC_CHASE_DEAL: '追跡取引',
    PC_STATE: '状況',
    PC_STOP_WAREHOUSE: '收益後ストップ',
    PC_BET_STATUS: '注文状況',
    PC_WAREHOUSE_NUMBER: '当選番号',
    PC_START_DATE: '%{num} から',
    PC_TRAKING_STATUS: '追跡状況',
    PC_PROFTI_AMOUNT: '利益金額',
    PC_SUMBIT_ORDER: 'オーダーを送りました、5分以内にお客様の<br>クレジットを追加いたします。',
    PC_SUMBIT_ORDER2: 'クレジットを追加いたします。',
    PC_BET_DETAILS: '注文詳細',
    PC_TRACKING_DETAILS: '追跡詳細',
    PC_DEAL_CENTER: '取引センター',
    PC_WALLET: 'ウォレット',
    PC_ORDER_SUCCESS: '注文しました',
    PC_ORDER_FAIL: '注文が失敗しました',
    PC_CHANGE_IMG: '写真を変更',
    PC_CEKECK_INTEGRAL: '今の点数を見る',
    PC_ZOOM: 'エリアズーム',
    PC_BACK_ZOOM: 'エリアズームを回復',
    PC_OPEN_HORIZONTAL: '横向きをオンにする',
    PC_CLEAR_CHECKBOX: '選択した範囲クリア',
    PC_SAVE_IMG: '写真で保存',
    PC_NO_DATA: 'データなし',
    PC_TRADE_EVERY: '毎',
    PC_TRADE_TIME: '倍',
    PC_CHASE_DATE: '追跡期数',
    PC_CANCEL_ORDER: '注文取消',
    PC_IN_TOTAL: '全部で %{num} つ',
    PC_ANNOUNCE_NEW: 'アナウンス',
    PC_RULE_DOUBLE: '取引ルール：市場指数の値の小数点以下2桁に基準して、1桁目は優勝番号、2桁目は準優勝番号です。4より大きい（4を含まない）場合は「上昇」、4より小さい（4を含む）場合は「下落 」、 奇数の場合は 「奇」、偶数の場合は「偶」となります。優勝が準優勝より大きい場合は「ブル」となり、小さい場合は「ベア」となり、優勝と準優勝が同じ数字の場合は「タイ」となり、資本金が返金されます。当期は、上昇/下降、奇/偶、ブル/ベアの両方と同時に投資することはできません。',
    TRADE_INVEST_SINGLE: '一つの金額',
    TRADE_INVEST_COUNT: '投資数量',
    PC_RULE_LOCATION: '優勝者、準優勝者のポジションから最少1つの番号、最多各6つの番号を選び、選んだ番号は同じポジションで当選番号と一致しています。',
    PC_PREV_BTN: '前へ',
    PC_NEXT_BTN: '次へ',
    // 用戶中心
    PCMEMBER_ACCOUNT: 'アカウント',
    PCMEMBER_MEN: '男性',
    PCMEMBER_WOWAN: '女性',
    PCMEMBER_SAVE: '保存',
    PCMEMBER_BIND: '連携',
    PCMEMBER_SET: '設定',
    PCMEMBER_NOT_BIND: '未連携',
    PCMEMBER_SET_NOW: '設定した',
    PCMEMBER_BIND_NOW: '連携した',
    PCMEMBER_EDIT: '編集',
    PCMEMBER_OVERVIEW: '資金概要',
    PCMEMBER_SUMMARY: 'サマリ',
    PCMEMBER_NOTE_CONTENT: '備考',
    PCMEMBER_TOTAL_RECORD: '全部で %{num} つ記録',
    PCMEMBER_COPY_WATER: 'コピーしました',
    PCMEMBER_SEVEN_DAY: '七日',
    PCMEMBER_DEAL_ING: '処理中',
    PCMEMBER_DEAL_SUCCESS: '成功',
    PCMEMBER_DEAL_FAIL: '失敗',
    PCMEMBER_SUCCESS: '成功',
    PCMEMBER_ERROR: '失敗',
    PCMEMBER_GRADE: 'レベル',
    PCMEMBER_INCREASE: '1円で入金すると、1点になります',
    // 登入頁面
    PCLOGIN_PREV: '戻す',
    PCLOGIN_FIND_PASSWORD: 'パスワード保護質問がアカウントの安全性を高め、パスワード回復することもできます。',
    PCLOGIN_WITHDRAWAL: 'セキュリティコードは出金、カードの追加などの操作に使い、資金保護することができます。',
    PCLOGIN_PASSWORD_ERROR: 'セキュリティーコードが間違ってい、もう一度お試しください。',
    PCLOGIN_ENTER_ANSWER: 'お答えを入力してください',
    PCLOGIN_NEW_PASSWORD: '新しいパスワード',
    PClOGIN_SUCCESS_FIX: 'ログインパスワードの変更が完了しました。すぐログインにしますか。',
    PCLOGIN_ACCOUNT_NUMBER: 'IDを入力',
    PCLOGIN_VERIFICATION: '方法をご選択',
    PCLOGIN_PASS_ID: 'ご本人の認証',
    PCLOGIN_SET_PASSWORD: 'パスワード再設定',
    PCLOGIN_CONFIRM_VISITING: 'ご利用しているサイトをご確認',
    PCLOGIN_HAVE_ACCOUNT: 'すでにアカウントをお持ちですか？',
    PCLOGIN_ACCOUNT: 'ログインID',
    PCLOGIN_SIGN_UP: '今すぐ登録',
    PC_NOT_ACCOUNT: 'まだアカウントを持っていませんか？',
    PC_SUCCESS_SING: 'おめでとう！登録が成功しました',
    // layout
    LAOUT_SAFE_CONFIRM: 'セキュリティ',
    LAYOUT_APP_DOWNLOAD: 'アプリをダウンロード',
    LAYOUT_BROWSER_SUGGEST: '推奨ブラウザ：Safari/Google Chrome',
    LAYOUT_ACCOUNT_OVERAGE: '残高',
    LAYOUT_ASSETS_MANAGE: '資産運用',
    // Recharge.vue
    PC_RECHARGE_EXTENDS_TRADESELECTION_LABELTITLE: '入金チャネル',
    PC_RECHARGE_EXTENDS_FROMLISTBETMONEY_LABELTITLE: '入金金額',
    PC_RECHARGE_EXTENDS_FROMLISTBETNAME_LABELTITLE: '預金者の氏名',
    PC_RECHARGE_EXTENDS_FROMLISTQRCODESHOW_LABELTITLE: 'アカウント情報',
    PC_RECHARGE_EXTENDS_FROMLISTACCOUNTNAME_LABELTITLE: '口座名義人',
    PC_RECHARGE_EXTENDS_FROMLISTBANKNAME_LABELTITLE: '口座の金融機関',
    PC_RECHARGE_EXTENDS_FROMLISTBANKCARDNUMBER_LABELTITLE: 'アカウント番号',
    PC_RECHARGE_BTN: '送る',
    PC_RECHARGE_ILLUSTRATE: '注意事項',
    PC_RECHARGE_NOT_EMPTY: '必須入力項目です',
    PC_RECHARGE_LENGTH_ERROR: '長さエラーです',
    PC_RECHARGE_INPUTPLACEHOLDER: '入金金額を入力してください',
    PC_RECHARGE_SELECTTYPEPLACEHOLDER: '未選択',
    PC_RECHARGE_BANKPAGE_BETNAMEPLACEHOLDER: '氏名を入力してください',
    PC_RECHARGE_DROPDOWN_TEXT: '支払QRコードをスキャン',
    PC_RECHARGE_MAX_ERROR: '最大金額が間違っています',
    PC_RECHARGE_MIN_ERROR: '最小金額が間違っています',
    PC_RECHARGE_NUMBER_ERROR: '数字を入力してください',
    PC_RECHARGE_SUCCESS_DEAL: '注文は送信されました。新しく開いたページで支払いを完了してください。',
    PC_ANNOUNCEMENT_NEXT: '次へ',
    PC_ANNOUNCEMENT_PREV: '前へ',
    PC_CURRENCY_COUNT: '通貨の数量',
    PC_CURRENCY_RANGE: '通貨リミット %{min}-%{max}',
    PC_CURRENCY_TIP: '預金レート {converRate} 、入金金額 {total}。',
    PC_CURRENCY_ADDRESS: '支払いアドレス',
    PC_CURRENCY_DEAL: '取引通貨',
    PC_CURRENCY_CHOOSE: '%{msg}, トランザクションのスクリーンショット、その中の一つアイテムを入力してください。',
    PC_MIN_MAX_AMOUNT: '毎回トランザクション制限%{min}-%{max}',
    CURRENCY_DIGITAL: '仮想通貨',
    STOCK_1801: 'ビットコイン',
    STOCK_1803: 'イーサ(USA)',
    STOCK_1804: 'イーサ(CN)',
    STOCK_1811: 'CSI300',
    STOCK_1812: 'シンセン100',
    STOCK_1813: 'シンセン成分',
    STOCK_1814: 'シンセン成分B',
    STOCK_1815: 'SZSEInnovate',
    STOCK_1816: 'シンセン1000',
    STOCK_1817: 'SZSE Central',
    STOCK_1818: 'シンセンIT',
    STOCK_1819: 'シンセン科研',
    STOCK_1821: '上海総合指数',
    STOCK_1822: '上海Ａ株指数',
    STOCK_1823: 'SSEMarket100',
    STOCK_1824: 'SSE100',
    STOCK_1825: 'SSE Mega-cap',
    STOCK_1826: 'SSEFinancial',
    STOCK_1827: 'SSE Consumer',
    STOCK_1828: 'SSE Dividend',
    STOCK_1829: 'SSE 380',
    STOCK_1831: 'Business',
    STOCK_1832: 'SZSE Culture',
    STOCK_1833: 'KweichowMoutai',
    STOCK_1834: 'CSI Media',
    STOCK_1835: 'CCTV Culture',
    STOCK_1836: '伊利実業',
    STOCK_1837: 'ベストア',
    STOCK_1838: 'LuzhouLaojiao',
    STOCK_1839: '3 Squirrels',
    STOCK_1840: 'Yanker Shop',
    STOCK_1851: '現物金(USA)',
    STOCK_1852: '現物プラチナ',
    STOCK_1854: '現物金(CN)',
    STOCK_1861: 'ジューヤン',
    STOCK_1862: 'BDStar',
    STOCK_1863: 'BearElectric',
    STOCK_1871: 'CAC40',
    STOCK_1872: 'BEL20',
    STOCK_1881: 'マイクロソフト',
    STOCK_1882: 'アップル',
    STOCK_1883: 'アマゾン',
    STOCK_1884: 'ナスダック',
    STOCK_1885: 'ナスダック100'
  },
  cn: {
    DEPOSIT: '入金',
    IMMEDIATE_LOGIN: '立即登入',
    SING_OUT: '登出',
    ACCOUNT_USER: '用戶',
    WITHDRAWAL: '出金',
    CANCEL: '取消',
    FINISH: '完成',
    CONFIRM: '确认',
    CONFIRM2: '确 认',
    CONTINUE: '继续',
    CLOSE: '关闭',
    SUBMIT: '送出',
    SEARCH: '查询',
    PROCESS_DONE: '操作成功～',
    WAIT_A_MINUTE: '请稍等...',
    FORGOT_PASSWORD: '忘记密码',
    COPY: '复制',
    PLEASE_AGAIN: '请重试',
    RE_ENTER: '重新输入',
    YES: '是',
    NO: '否',
    SHOW_ALL: '已显示全部记录',
    NEW_MESSAGE: '有新的记录将在这里显示',
    NO_RECORD: '暂无纪录',
    PASSWORD: '密码',
    UNLOCK: '未锁定',
    DISABLED: '已禁用',
    LOCKED: '已锁定',
    RESTRICTED: '已限制',
    REVISE: '修改',
    REMARK: '备注',
    DELETE: '删除',
    PASSWORD_ERROR: '密码错误',
    // 用戶
    ACCOUNT_ANNOUNCEMENT: '公告',
    YOUR_TEAM_BANNED: '您的组别已禁言',
    YOUR_TEAM_BANNED_FOREVER: '您已被永久禁言',
    YOUR_TEAM_BANNED_ONEDAY: '您已被禁言一天',
    YOUR_TEAM_BANNED_ONEWEEK: '您已被禁言一周',
    YOUR_TEAM_BANNED_ONEHOUR: '您已被禁言一小时',
    YOUR_TEAM_BANNED_HALFHOUR: '您已被禁言半小时',
    CONGRATULATION: '恭喜获得',
    FLLOW_DATE: '追%{num}期',
    INJECTION: '注',
    PERSON: '人',
    ACCOUNT_PERSONAL_INFORMATION: '个人资料',
    ACCOUNT_PROFIT_LOSS_TODAY: '本日盈亏',
    ACCOUNT_AGENCY_CENTER: '代理中心',
    ACCOUNT_MESSAGE_CENTER: '讯息中心',
    ACCOUNT_HELP_GUIDE: '帮助指南',
    ACCOUNT_GUIDE_MEMBER: '账户指南',
    ACCOUNT_INVESTMENT_GUIDE: '投资指南',
    ACCOUNT_ABOUT_US: '关于我们',
    ACCOUNT_MY_ACCOUNT: '我的帐户',
    ACCOUNT_OVERAGE: '余额',
    ACCOUNT_MANAGEMENT: '安全管理',
    ACCOUNT_VIRTUAL_WALLET: '虚拟钱包',
    ALERT_NETWORK_ERROR_TEXT: '网络异常，请重新刷新',
    ACCOUNT_NEW_MESSAGE: '有新的讯息将在这里显示',
    ACCOUNT_PRIVATE_LETTER: '私信',
    // 交易頁面用可能會有共用
    TRADE_HOT: '热门',
    TRADE_ASIA: '亚洲盘',
    TRADE_EUROPE: '欧洲盘',
    TRADE_NORTH_AMERICA: '北美盘',
    TRADE_SOUTH_AMERICA: '南美盘',
    TRADE_OCEANIA: '大洋洲盘',
    TRADE_VIRTUALCURRENCY: '虚拟币',
    TRADE_STOP_TRADING: '暂停交易',
    TRADE_EXPECT: '期',
    TRADE_UNIT: '单位',
    TRADE_NUMBERPERIODS: '期数',
    TRADE_DOUBLED: '翻倍',
    TRADE_TIPS: '温馨提示',
    TRADE_TOTAl: '共',
    TRADE_STOCK: '股',
    TRADE_DOLLAR: '元',
    TRADE_MAX_INCOME: '最高收益',
    TRADE_CONTINUE_INVEST: '继续投资',
    TRADE_SHARE_ORDER: '分享注单',
    TRADE_BUY: '买入',
    TRADE_OPEN: '出仓',
    TRADE_SHIPPED: '出仓中',
    TRADE_OPEN_TODAY: '今开',
    TRADE_PRICING: ' 涨跌幅',
    TRADE_PRICING2: ' 涨跌额',
    TRADE_VOLUME: '成交量',
    TRADE_MAX: '最高',
    TRADE_MIN: '最低',
    TRADE_ODD: '单',
    TRADE_DOUBLE: '双',
    TRADE_CHAMPION: '冠军',
    TRADE_FALL: '赔',
    TRADE_RUNNER: '亚军',
    TRADE_PAR_VALUE: '每股金额',
    TRADE_MULTIPLE: '倍数',
    TRADE_CHASE_NUMBER: '追号',
    TRADE_PROFIT_STOP: '盈利即停',
    TRADE_PROFIT_CYCLE: '盈利循环',
    TRADE_DOUBLE_SIDE: '双面盘',
    TRADE_POSITIONING: '定位胆',
    TRADE_SINGLE_STRAND: '单股',
    TRADE_DOUBLE_STRAND: '双股',
    TRADE_BULL_MARKET: ' 牛',
    TRADE_BEAR_MARKET: '熊',
    TRADE_INVESTING: '正在投资',
    TRADE_INSUFFICIENT_BALANCE: '余额不足',
    TRADE_TAKE_A_BEATING: '投资失败',
    TRADE_I_SEE: '我知道了',
    TRADE_ACTION_SHEET_PLAYTYPE_TEXT: '玩法',
    TRADE_UP: '涨',
    TRADE_DOWN: '跌',
    TRADE_DALLAR: '元',
    TRADE_DIME: '角',
    TRADE_CENT: '分',
    TRADE_INPUTBASE: '单位 : 元',
    TRADE_UNCHANGED: '和',
    TRADE_MOST: '最多',
    TRADE_TIMES: '倍',
    TRADE_LIST_TAB_TITLE_BASE: '一般记录',
    TRADE_LIST_TAB_TITLE_CHASE: '追号纪录',
    TRADE_MOST_EXPECT: '最多%{num}期',
    TRADE_CHASE_START_ISSUE: '追号期号',
    TRADE_CHASE_COUNT: '追号注数',
    PC_ACCOUNT_BANK_CARD: '银行卡',
    PC_DATE_MULT: '每 %{chase} 期 %{mult} 倍',
    TRADE_CHASE_EXPECTED_PRIZE_TIME: '预计出仓时间',
    TRADE_CHASE_CONFIRM: '追号订单确认',
    //
    HOME_ICONBTN_BONUS: '盈亏',
    HOME_ICONBTN_GUIDE: '指南',
    HOME_ICONBTN_RANK: '排行榜',
    HOME_ICONBTN_HOT: '热推',
    HOME_ICONBTN_APP: 'APP',
    HOME_ICONBTN_SERVICE: '客服',
    HOME_NEWS_TITLE: '国际财经大小事',
    HOME_NEWS_MOREBTN: '查看更多财经资讯',
    HOME_NEWS_DEF_TITLE1: '股价遭低估！券商看好货柜船竞争力、日3大海运股齐飙',
    HOME_NEWS_DEF_SOURCE1: 'MoneyDJ新闻',
    HOME_NEWS_DEF_TITLE2: '股价遭低估！券商看好货柜船竞争力、日3大海运股齐飙',
    HOME_NEWS_DEF_SOURCE2: 'MoneyDJ新闻',
    HOME_NEWS_DEF_TITLE3: '股价遭低估！券商看好货柜船竞争力、日3大海运股齐飙',
    HOME_STOCK_TITLE: '为你推荐热门股指',
    HOME_GETNAME_TEXT: '指数',
    HOME_GETUSERINFO_TEXT: '账号信息已刷新，当前账号为',
    HOME_GETNOTICELIST_TEXT: '欢迎来到%{msg}',
    /**
     *APP下載頁面
     */
    HOME_APPDOWNLOAD_HEADER_TITLE: 'APP下载',
    HOME_APPDOWNLOAD_MAINTEXT: '下载APP 再也无须输入网址',
    HOME_APPDOWNLOAD_ANDROID_BTN: '点击下载安卓 APP版',
    HOME_APPDOWNLOAD_IOS_BTN: '点击下载IOS APP版',
    HOME_APPDOWNLOAD_IOSDISKTOP_BTN: '点击下载IOS 桌面版',
    HOME_APPDOWNLOAD_PROMPT: '部分浏览器可能无法安装, 建议使用Safari浏览器安装',
    HOME_APPDOWNLOAD_PROMPT_TEXT: '点击安装',
    HOME_APPDOWNLOAD_ACTION_IOSTITLE: 'IOS APP版',
    HOME_APPDOWNLOAD_ACTION_ANDROIDTITLE: '安卓 APP版',
    HOME_APPDOWNLOAD_ACTION_VERSION: '版本',
    HOME_APPDOWNLOAD_TEXT: '或选择',
    HOME_APPDOWNLOAD_IOS_STEP_TEXT: '安装完成',
    /**
     *排行版頁面
     */
    HOME_RANK_TITLE: '投资盈利榜 TOP10',
    HOME_RANK_TEXT1: '统计昨日全天盈利最多用户，今日',
    HOME_RANK_TEXT2: '05:00~06:00 新鲜出炉',
    HOME_RANK_USER_TITLE: '头衔',
    HOME_RANK_POPUP_CUMULATIVE_PROFIT: '累计盈利',
    HOME_ANNOUNCEMENT_TEXT: '查看',
    /**
     *交易頁面
     */
    TRADE_STOCK_INPUT_SEARCH: '输入股指名称',
    TRADE_STOCK_TRADEMAIN_RECORD_BTN: '买入记录',
    TRADE_STOCK_TRADEMAIN_BUY_BTN: '建仓买入',
    TRADE_STOCK_POPUP_SUCCESSFUL_TEXT1: ' 投资成功，',
    TRADE_STOCK_POPUP_SUCCESSFUL_TEXT2: '可点选分享注单或',
    TRADE_STOCK_CHART_TODAY_K_BTN: '日K',
    TRADE_STOCK_CHART_WEEK_K_BTN: '周K',
    TRADE_STOCK_CHART_MOON_K_BTN: '月K',
    TRADE_STOCK_TIMEBAR_STAES_CLOSED: '休市中',
    TRADE_STOCK_TIMEBAR_ORDER_ERROR: '期号有误',
    TRADE_STOCK_TIMEBAR_ORDER_ERROR1: '期号错误',
    TRADE_STOCK_LATESTRESULT_NOTIMSG1: '提醒您：',
    TRADE_STOCK_LATESTRESULT_NOTIMSG2: '%{num}期已截止，当前期号',
    TRADE_STOCK_SETKTYPE_TITLE: '平安指数历史股价',
    TRADE_STOCK_CONFIRMBET_ERROR: '投资类型校验错误',
    TRADE_STOCK_CONFIRMBET_TYPE_ERROR_REFRESH: '投资类型发生异动，您的投资未能成功，即将为您刷新页面',
    TRADE_STOCK_CONFIRMBET_UNIT_ERROR_REFRESH: '投资基准单位发生异动，您的投资未能成功，即将为您刷新页面',
    TRADE_STOCK_ACTIVE_CHAMPION_STATE: '对不起，冠亚军最多只能各选6个号码',
    TRADE_STOCK_NUMERIC_KEYBOARD_ALERT_TEXT: '最高投资额为',
    TRADE_STOCK_CONFIRMHANDLER_TYPE1_TEXT: '从冠军、亚军位置上至少选择1个号码、最多各6个号码，选号与相同位置上的出仓指数一致。',
    TRADE_STOCK_CONFIRMHANDLER_TYPE1_TITLE: '定位胆规则',
    TRADE_STOCK_CONFIRMHANDLER_TYPE0_TEXT: '买入规则:大盘指数小数点后两位数为基准值，第一位为冠军号，第二位为亚军号。各对应号码当大于4（不包含4）为“涨”，小于4（包含4）为“跌”，奇数为“单”，偶数为“双”。牛熊规则大PK，当冠军大于亚军为“牛”，小于为“熊”。当期投资不能同时投资涨跌、单双、牛熊。当冠军亚军出现一样的数字时为“和”则退还本金。',
    TRADE_STOCK_CONFIRMHANDLER_TYPE0_TITLE: '双面盘规则',
    TRADE_STOCK_NOTIFY_TEXT: '%{type} %{bet} 注 %{power}倍 追%{chase}期 ¥{bettingAllAmount}',
    TRADE_STOCK_MAX_CHASE_NUMBER: '最多可追号',
    TRADE_STOCK_RIGHT_TEXT: '点击右上角向左箭头可查看投资记录',
    TRADE_STOCK_NO_LOGIN: '用户未登录',
    TRADE_STOCK_STOP_SELL: '暂停销售',
    TRADE_STOCK_IS_STOP: '0123456789预休出等',
    TRADE_STOCK_NOW_CLOSE_ORDER: '当期封单',
    TRADE_STOCK_NOW_WAIT_OPEN_ORDER: '等待开局',
    /**
     *銀行卡
     */
    NOBIND_SAFEPASSWORD_AND_BANKCARD: '你还未设置安全密码和绑定银行卡，无法出金，是否先去设置安全密码?',
    NOBIND_BANKCARD: '你还未绑定银行卡，无法出金，是否先去绑定银行卡',
    // 個人資料
    PERSONAL_RANK_TITLE: '等级头衔',
    PERSONAL_SAFE_FINISH: '安全认证完成',
    PERSONAL_SAFTEY_NOT_COMPLETED: '安全认证未完成',
    PERSONAL_NICK_NAME: '昵称',
    PERSONAL_LOADING: '加载中',
    PERSONAL_ACCOUNT: '账号',
    PERSONAL_PHONE: '手机号',
    PERSONAL_QQ: 'QQ号',
    PERSONAL_NO_DATA: '無資料',
    PERSONAL_NO_BIND: '無綁定',
    PERSONAL_NO_SETING: '未设置',
    PERSONAL_SEX: '性别',
    PERSONAL_BIRTHDAY: '生日',
    PERSONAL_THEME: '主题',
    LANGUAGE: '语言',
    JAPANESE: '日文',
    CHINESE: '中文',
    VIETNAMESE: '越南文',
    PERSONAL_SET_NICK_NAME: '設置昵称',
    PERSONAL_SET_QQ: '設置QQ号',
    PERSONAL_SET_PHONE: '設置手机号',
    PERSONAL_NOT_SET: '不設置',
    PERSONAL_MAN: '男',
    PERSONAL_WONAN: '女',
    PERSONAL_OTHER: '其他',
    PERSONAL_DARK: '夜色',
    PERSONAL_LIGHT: '日光',
    PERSONAL_SET_IMG: '设置头像',
    PERSONAL_CHOESE_IMG: '选择头像',
    PERSONAL_SAVE: '保存',
    // 認證流程
    SAFE_PROCESS: '认证流程',
    SAFE_IDENTITY_CONFIRM: '身份确认',
    SAFE_MESSAGE: '根据KYC和AML政策， 您必须通过验证过程。 这是确保您的资金安全与加快提款速度所必须的。',
    SAFE_LOGING_PASSWORD: '设置登录密码',
    SAFE_PASSWORD: '设置安全密码',
    SAFE_SECRET_SECURITY: '设置密保问题',
    SAFE_GO_SAFE_CENTER: '前往安全中心',
    // 認證流程-action
    ACTION_NEW_PASSWORD: '请设置新的安全密码',
    ACTION_MESSAGE_DOT: '安全密码为6位数字，用于出金、设置密保、添加银行卡等操作，可保障资金安全',
    ACTION_PASSWORD_NOT_SIMPLE: '安全密码不能过于简单，禁止设置为：123456、123123、654321、111111等此类简单密码',
    ACTION_ENTER_OLD: '请输入旧的安全密码',
    ACTION_NOT_SAME: '两次密码输入不一致',
    ACTION_MESSAGE: '安全密码为6位数字用于出金设置密保添加银行卡等操作可保障资金安全',
    ACTION_SIX_PASSWORD: '新6位密码',
    ACTION_OLD_PASSWORD: '旧6-16密码',
    ACTION_CONFIRM_PASSWORD: '操作 - 验证安全密码',
    ACTION_SURE_UPDATE: '是否放弃修改安全密码',
    ACTION_NOT_SET_PASSWORD: '你尚未设置安全密码，请先设置安全密码',
    ACTION_AGAIN_CONFIRM: '请再次填写安全密码以确认',
    ACTION_VERIFY_ID: '请填写安全密码以验证身份',
    // 等級頭銜
    RANK_GROWTH_POINTS: '成长积分',
    RANK_PROMOTION_INSTRUCTIONS: '晋级说明',
    RANK_CAPTION_BEFORE: '距离下一级需要',
    RANK_CAPTION_AFTER: '分，每入金 1 元加 1 分',
    RANK_GRADE: '等级',
    RANK_MECHANISM: '等级机制',
    // 福利活動
    LEVELUP_PRIZE: '晋级奖励',
    DAILY_PRIZE: '每日加奖',
    SIGNIN_PRIZE: '签到有礼',
    PRIZE_GOTTEN: '得到奖金',
    AWARD_GOTTEN: '得到奖励',
    TODAY_PRIZE: '今日奖金',
    IMMEDIATE_GET: '立即领取',
    CANT_GET: '无法领取',
    ALREADY_GET: '已領取',
    YESTERDAY_INVESTMENT: '昨日投资',
    CURRENT_GRADE: '当前等级',
    ADDITIONAL_RATE: '加奖比例',
    GET_SUCCESSFULLY: '领取成功',
    ACTIVITY_NOTE: '活动说明',
    DAILY_NOTE_1: '每日加奖在每日凌晨00:20后开放领取',
    DAILY_NOTE_2: '加奖比例是根据会员等级以及昨日累计投注金额进行一定比例的加奖',
    DAILY_NOTE_3: '撤单和其他无效投注将不计算在内',
    DAILY_NOTE_4: '活动奖金当天24点前未领取 视为自动放弃活动资格',
    PROMOTION_RULES: '晋级机制',
    PROMOTION_NOTE_1: '每晋升一个等级都可以获得晋级奖励',
    PROMOTION_NOTE_2: '成长积分等于您充值的金额',
    ACCUMULATED_POINTS: '成长积分',
    PROMOTION_PRIZE: '普级奖金',
    SKIP_PRIZE: '跳级奖金',
    LEVELUP_PRIZE_NOTE: '每晋升一个等级可获得晋级奖励',
    DAILY_PRIZE_NOTE: '每日加奖是根据会员昨日投注金额进行加奖奖金无上限',
    SIGNIN_PRIZE_NOTE: '每日签到即可享受着更多福利及优惠 咱们还等什么呢',
    NOT_AVAILABLE_YET: '未达到领取条件',
    NOT_SIGNIN_YET: '尚未开签',
    ACTIVITY_OVER: '活动结束',
    ALREADY_SIGNIN: '已签到',
    NOT_FINISHED_YET: '尚未达标',
    IMMEDIATE_SIGNIN: '立即签到',
    SIGNIN_TOTAL_DAYS: '签到天数',
    SIGNIN_PRIZE_REMINDER: '连续签到30天享受盈利翻倍机会',
    SIGNIN_RULES: '签到机制',
    SIGNIN_DAYS: '签到天数',
    SIGNIN_BONUS: '签到奖励金',
    SIGNIN_INVESTMENT_NEEDED: '条件:需投资',
    DAY_NUMBER: '第 {number} 天',
    DAILY_SIGN_IN: '每日签到',
    BOUNS_ACTIVITY: '有活动将在这里显示',
    UN_LOGIN: '未登录',
    // 维护页面
    ONLINE_CUSTOM_SERVICE: '在线客服',
    WEB_MAINTAINING: '网站维护中',
    MAIN_NOTE_1: '每一次简单的维护',
    MAIN_NOTE_2: '都是为了给您带来更好的服务',
    SYSTEM_MAINTAINED: '系统维护中',
    WEB_TIME_FIXED: '预计于%{msg}维护完成',
    // 登入页面
    LOGIN_TO: '登入 %{name}',
    LOGIN_NOTE: '欢迎来到 %{name} 立即投资赚取更多获利',
    WELCOME_TO: '欢迎来到  {name}',
    WELCOME_NOTE: '我们致力于帮助客户实现盈利',
    MILLION_RMB: '百萬人民币',
    YEAR: '年',
    BUSINESS_NOTE_1: '月客户利润达到5.54亿人民币',
    JOIN_US: '加入我们',
    JOIN_NOTE: '与全球客户进行投资',
    BUSINESS_NOTE_2: '万客户',
    BUSINESS_NOTE_3: '月已估到491.72万客户',
    BUSINESS_GLOBAL_MARKET: '交易全球市场',
    BUSINESS_NOTE_4: '投资货币、商品、股票数字货币等',
    BUSINESS_NOTE_5: '百万订单',
    BUSINESS_NOTE_6: '月已估到490.13百万客户订单',
    INTEGRITY_FIRST: '诚信至上',
    SAFE_AND_TRUSTABLE: '安全可靠且值得信赖',
    BUSINESS_NOTE_7: '亿交易量',
    BUSINESS_NOTE_8: '月已估到490.09人民币的客户交易量',
    REGISTER: '注册',
    LOGIN: '登入',
    BUSINESS_NOTE_9: '加入我们与全球客户进行投资以实现更多盈利',
    ACCOUNT_REMINDER: '请设置4-16位账号',
    PASSWORD_REMINDER: '请设置6-16位密码',
    INVITATION: '邀请码',
    INVITATION_REMINDER: '请填写8位数字邀请码',
    QQ: 'QQ号',
    QQ_REMINDER: '请设置QQ号',
    PHONE_NUMBERS: '手机号',
    PHONE_REMINDER: '请设置手机号',
    IMMEDIATE_LOGIN_NOTE: '已有账号?立即登入',
    REGISTER_NOTE_1: '为了您的帐户安全,请设置高强度密码,为方便设',
    ALIEMPTYREMINDER: '请滑动验证码',
    VERIFY_1: '请按住滑块，拖动到最右边',
    VERIFY_2: '验证通过',
    VERIFY_3: '哎呀，出错了，点击<a href="javascript:__nc.reset()">刷新</a>再来一次',
    VERIFY_4: '网络不给力，请<a href="javascript:__nc.reset()">点击刷新</a>',
    REGISTER_NOTE_2: '置,可选用密码生成,也别忘记下自动生成的密码',
    FORBID_ACCOUNT_PASSWORD_SAME: '禁止登入密码与帐号相同',
    FORBID_SIMPLE_PASSWORD: '登录密码不能跟账号相同，且不能设置过于简单，禁止设置为：123456、123123、654321、111111等此类简单密码',
    // 快速钱包
    TODAY_INVESTMENT: '今日投资',
    TODAY_PROFIT: '今日盈利',
    TODAY_ACTIVITY: '今日活动',
    TODAY_REBATE: '今日返点',
    TODAY_DEPOSIT: '今日入金',
    TODAY_WITHDRAW: '今日出金',
    /**
     * API ERROR CODE
     */
    INTERNET_PAY: '网银支付',
    BANK_TRANSFER: '银行转账',
    ALIPAY: '支付宝',
    WECHAT_PAY: '微信支付',
    DIGITALCURRENCY_PAY: '数字货币',
    QQ_WALLET: 'QQ钱包',
    FOURTH_PAY: '第四方支付',
    UNION_PAY: '银联支付',
    CLOUD_PAY: '云闪付',
    JD_PAY: '京东钱包',
    INVEST_ORDER_CANCEL: '投资撤单',
    CHASE_STOP: '追号停止',
    AGENT_REBATE: '代理返点',
    PROFIT_DISTRIBUTE: '盈利派送',
    DEPOSIT_DISCOUNT: '入金优惠',
    PROFIT_RE_SEND: '盈利补派',
    SYSTEM_CANCEL_ORDER: '系统撤单',
    ENVELOPE_WITHDRAW: '红包领取',
    ENVELOPE_REFUND: '红包退回',
    TRANSFER_GET: '转账收款',
    TRANSFER_REFUND: '转账退回',
    DRAW_REFUND: '和局退款',
    ACTIVITY_ENVELOPE: '活动红包',
    TIPS_DEDUCTION: '打赏扣款',
    ENVELOPE_DEDUCTION: '红包扣款',
    TRANSFER_DEDUCTION: '转账扣款',
    THIRD_PARTY_WITHDRAW_DEDUCTION: '代付出金扣款',
    ARITIFICIAL_DEPOSIT: '人工存款',
    ARITIFICIAL_DEPOSIT_2: '人工存入',
    OTHER_DISCOUNT: '其他优惠',
    ERROR_WITHDRAW: '误存提出',
    ADMIN_WITHDRAW: '行政提出',
    PROFIT_WITHDRAW: '盈利提出',
    WITHDRAW_HANDLE_FEE: '出金手续费',
    THIRD_PARTY_WITHDRAW_REJECT: '代付出金拒绝',
    HANDLE_FEE_REFUND: '手续费退费',
    VIRTUAL_WALLET_PAY: '虚拟钱包支付',
    REJECT_WITHDDRAW_TO_VIRTUAL_WALLET: '拒絕提現至虚拟钱包',
    VIRTUAL_WALLET_SETTLE_REFUND: '虚拟钱包结清退款',
    NOLOGIN: '未登录',
    SUCCESS: '操作成功',
    SUCCESS_GET: '获取成功',
    SUCCESS_QUERY: '查询成功',
    SUCCESS_SAVE: '保存成功',
    SUCCESS_INSERT: '新增成功',
    SUCCESS_UPDATE: '更新成功',
    SUCCESS_DELETE: '删除成功',
    FAIL: '操作失败',
    FAIL_GET: '获取失败',
    FAIL_QUERY: '查询失败',
    FAIL_SAVE: '保存失败',
    FAIL_INSERT: '新增失败',
    FAIL_UPDATE: '更新失敗',
    FAIL_DELETE: '删除失敗',
    FAIL_BAD_PARAMS: '参数有误',
    FAIL_LACK_PARAMS: '参数缺少',
    FAIL_ILLEGAL_PARAMS: '参数非法',
    ERROR: '系统错误，请稍后重试',
    ERROR_NOAUTH: '无操作权限',
    ERROR_PW: '密码错误',
    ERROR_SAFETY_PW: '安全密码错误',
    ERROR_AC_OR_PW: '账号或密码错误',
    ERROR_MASSAGE: '为了您帐户安全，请联系客服做修改',
    ERROR_OPERATE: '操作异常',
    ERROR_UNKNOWN: '系统异常',
    TYPE_NOT_NULL: '类型不能为空',
    TYPE_NOT_FOUND: '无此类型',
    ACCOUNT_NOT_FOUND: '此账号不存在',
    USR_NOT_FOUND: '用户不存在',
    USR_001: '账号格式错误',
    USR_002: '密码格式错误',
    USR_003: '您已被限制登录',
    USR_004: '您的账号已经设置了登录保护，请使用指定IP登录',
    USR_005: '密码失效，联系在线客服修改',
    USR_006: '您的账号已被冻结，详情请咨询客服',
    USR_007: '您的登录密码跟账号相同，存在安全风险，请重新修改登录密码。',
    USR_008: '您的登录密码过于简单，存在安全风险，请重新修改登录密码。',
    USR_009: '登录成功',
    USR_010: '禁止登录跟账号相同',
    USR_011: '帐号只能包含数字及英文',
    USR_012: '帐号长度非法',
    USR_013: '请填写手机号',
    USR_014: '手机号格式错误',
    USR_015: '请填写QQ',
    USR_016: 'QQ格式错误',
    USR_017: '邀请码错误或者已过期',
    USR_018: '该账号已被注册',
    USR_019: '该手机号已被注册',
    USR_020: '该qq已被注册',
    USR_021: '邀请码返点有误，已失效',
    USR_022: '注册失败',
    USR_023: '注册成功',
    USR_024: '玩家不能生成邀请码',
    USR_025: '本站当日生成邀请码已到上限，请您明日重新生成',
    USR_026: '每日最多只允许生成50条邀请码！',
    USR_027: '充值返点错误',
    USR_028: '返点格式错误',
    USR_029: '返点值设置不能为空',
    USR_030: '邀请码返点不能大于自身返点',
    USR_031: '返点值设置不能为负数',
    USR_032: '缺少%lotteryName返点信息',
    USR_033: '返点只允许小数点后一位',
    USR_034: '邀请码已生成，请点击邀请码管理进行查询',
    USR_035: '生成失败',
    USR_036: '查不到用户信息',
    USR_037: '安全密码错误次数超过6次，已超当日限制',
    USR_038: '您的安全密码过于简单，无法通过安全密码找回登录密码，请使用密保问题或联系客服找回',
    USR_039: '安全密码正确',
    USR_040: '安全密码格式错误',
    USR_041: '已设置过安全密码',
    USR_042: '设置成功',
    USR_043: '设置失败',
    USR_044: '退出成功',
    USR_045: '尚未绑定密保邮箱',
    USR_046: '邮箱格式错误',
    USR_047: '我们已向您的邮箱发送了验证码，如未收到，请检查垃圾邮箱',
    USR_048: '尚未绑定密保手机',
    USR_049: '功能暂时关闭',
    USR_050: '手机格式错误',
    USR_051: '发送成功',
    USR_052: '不能选重复的密保问题',
    USR_053: '密保问题错误次数超过10次，已超当日限制',
    USR_054: '密保问题答案错误',
    USR_055: '密保问题答案正确',
    USR_056: '验证失败',
    USR_057: '验证手机错误次数超过10次，已超当日限制',
    USR_058: '请先验证安全密码',
    USR_059: '验证码错误',
    USR_060: '验证手机错误',
    USR_061: '验证成功',
    USR_062: '验证邮箱错误次数超过10次，已超当日限制',
    USR_063: '验证邮箱错误',
    USR_064: '答案不能包含空格',
    USR_065: '请先验证密保问题',
    USR_066: '当前id不存在',
    USR_067: '当前用户没有权限查看',
    USR_068: 'userId or cardId is null',
    USR_069: '暂不支持该银行',
    USR_070: '安全密码验证失效，为了您的账户安全，请重新验证安全密码',
    USR_071: '请先验证首张银行卡',
    USR_072: '银行账户名有误，请重新选择',
    USR_074: '最多只能添加5张银行卡',
    USR_075: 'userId is null',
    USR_076: 'tenantCode is null',
    USR_077: 'bankName is null',
    USR_078: 'province is null',
    USR_079: 'city is null',
    USR_080: '银行卡号格式错误',
    USR_081: '开户人姓名格式错误',
    USR_082: 'sourceId is null',
    USR_083: 'users is null',
    USR_084: '无法绑定此银行帐户',
    USR_085: '该银行卡已被使用,请更换银行卡',
    USR_086: '绑定成功',
    USR_087: '银行卡禁止修改',
    USR_088: '银行卡已锁定，禁止修改',
    USR_089: '当前银行卡正在提现，暂时无法修改"',
    USR_090: '银行账户名有误，请重新选择',
    USR_091: '已错误验证3次，请明天再试',
    USR_092: '填写的验证信息正确',
    USR_093: '填写的验证信息错误',
    USR_094: '输入次数过多，已超当日限制',
    USR_095: '用户信息错误',
    USR_096: '登录密码不能跟账号相同，且不能设置过于简单，禁止设置为：123456、123123、654321、111111等此类简单密码',
    USR_097: '尚未通过验证',
    USR_098: '原密码正确',
    USR_099: '请先验证银行卡',
    USR_100: '提交成功，审核结果请关注站内信通知',
    USR_101: '图片上传失败',
    USR_102: '原密码错误',
    USR_103: '请透过人工申诉修改',
    USR_104: '头像资料错误',
    USR_105: '档案服务异常',
    USR_106: '档案服务异常 资料为空',
    USR_107: '昵称已存在，不能修改',
    USR_108: '手机号已存在，不能修改',
    USR_109: 'QQ已存在，不能修改',
    USR_110: '尚未锁定',
    USR_111: 'bankCardNumber is null',
    USR_112: 'accountName is null',
    USR_113: '验证正确',
    USR_114: '操作失败',
    USR_115: '不能查询自己哦',
    USR_116: '账号已在其它地方登录，如不是本人操作请及时修改登录密码',
    USR_117: '昵称未设置',
    USR_118: '昵称最多可包含 5 个日文和中文字符，或其他语言10字以内',
    USR_119: '安全密码不能跟账号相同，且不能设置过于简单，禁止设置为：123456、123123、654321、111111等此类简单密码',
    USR_120: '分页参数异常',
    USR_121: '帐号只能是包含数字及英文',
    USR_135: '该钱包地址已被使用,请更换钱包地址',
    LTRY_001: 'lotteryCode参数错误',
    LTRY_002: 'dataNum参数错误',
    LTRY_003: '代码错误',
    LTRY_004: '最多只能查10个股种',
    BET_001: '网络出错...请稍后再试',
    BET_002: '参数错误!  投注数据为空',
    BET_003: '未知彩种',
    BET_004: '投注期号错误,请刷新或核对页面期号是否正确',
    BET_005: '两秒内不可重复投注',
    BET_006: '该股票已经暂停销售',
    BET_007: '该彩种已经暂停销售',
    BET_008: '该期已封单',
    BET_009: '该彩种未开放',
    BET_010: '获取股票设定失败',
    BET_011: '该股票已发生熔断，暂时停止交易',
    BET_012: '该股票发生涨/跌停，暂时停止交易',
    BET_013: '当前期投资冠亚军最多只能各选六码',
    BET_014: '当前期投资不能同时投资涨/跌、单/双、牛/熊',
    BET_015: '返点信息错误，请刷新页面',
    BET_016: '余额不足',
    BET_017: '余额扣减错误',
    BET_018: '余额服务返回空',
    BET_019: '投注成功',
    BET_020: '目前无法使用追号',
    BET_021: '该彩种不能追号',
    BET_022: '每期投资冠亚军最多只能各选六码',
    BET_023: '订单号参数有误',
    BET_024: '状态参数错误',
    BET_025: '日期区间参数错误',
    BET_026: '非法撤单',
    BET_027: '该方案状态不为等待开奖状态',
    BET_028: '不允许撤单',
    BET_029: '撤单成功',
    BET_030: '获取设定失败',
    BET_031: '获取旗号资料失败',
    BET_032: '彩种代码错误',
    BET_033: '股种代码错误',
    BET_034: '取消自动失败',
    BET_035: '订单号不存在',
    BET_036: '已经是关闭状态',
    MNG_001: '密钥或APP唯一码不正确',
    MNG_002: '密钥错误或已过期',
    MNG_003: '类型必须大于0',
    TRSC_001: '您尚有订单处理中，请稍后再操作',
    TRSC_002: '非常抱歉，请不要在5秒内重复提交',
    TRSC_003: '请勿频繁提交订单',
    TRSC_004: '订单建立成功',
    TRSC_005: '订单提交成功，我们将在5分钟内为您添加额度',
    TRSC_006: '提现申请提交成功，请在3-5分钟后查询是否到账',
    TRSC_007: '提现失败',
    TRSC_008: '提交成功',
    TRSC_009: '提交失败, 订单已取消',
    TRSC_010: '对应提示语不存在',
    TRSC_011: '日期类型参数有误，请检查后重新传',
    TRSC_012: '该EnumPaymentRecordState类所对应的类型参数有误，请检查后重新传',
    TRSC_013: '第三方基础配置数据不存在',
    TRSC_014: '第三方支付設置明细不存在',
    TRSC_015: '第三方接口模式配置数据不存在',
    TRSC_016: '金额有误',
    TRSC_017: '该EnumMerchantsPaymentType类所对应的类型参数有误，请检查后重新传',
    TRSC_018: '昵称不能为空',
    TRSC_019: '获取成功但是没有获取到玩家历史记录',
    TRSC_020: '虚拟钱包扣款失败',
    TRSC_021: '余额服务-更新余额失败',
    TRSC_022: '余额服务-更新余额备注显示失败',
    TRSC_023: '虚拟钱包回退失败',
    TRSC_024: '该站可提现金额限制数据为空或是数据条数有误',
    TRSC_025: '提现金额大于余额',
    TRSC_026: '当前无法使用虚拟钱包',
    TRSC_027: '该站长没有可提现金额限制数据',
    TRSC_028: '提现次数已经超过',
    TRSC_029: '系统异常-用户ID',
    TRSC_030: '该银行卡已被禁用，详情请咨询客服',
    TRSC_031: '金额超过可提现金额',
    TRSC_032: '时间不能为空',
    TRSC_033: '开始时间不能大于等于结束时间',
    TRSC_034: '传入的时间格式有问题',
    TRSC_035: '结束日期不能大于开始日期',
    TRSC_036: '充值金额区间有误',
    TRSC_037: '充值金额区间参数有误',
    TRSC_038: '充值金额区间,最大金额必须大于等于最小金额',
    TRSC_039: '充值方式格式有问题',
    TRSC_040: '等级格式有问题',
    TRSC_041: '等级类型参数有误，请检查后重新传',
    TRSC_042: '状态不能为空',
    TRSC_043: '类型格式有问题',
    TRSC_044: '订单不存在',
    TRSC_045: '不能重复提交数据',
    TRSC_046: '金额超过余额',
    TRSC_047: '您的可提现金额不足',
    TRSC_048: '用户服务-检验用户上下级失败，系统失败原因：%msg',
    TRSC_049: '用户服务-获取单个用户信息失败，系统异常原因：%msg',
    TRSC_050: '参数转换出错：%msg',
    TRSC_051: '获取用户历史信息错误',
    TRSC_052: '余额服务-获取用户余额失败，系统失败原因：%msg',
    TRSC_053: '用户服务-锁定银行卡失败，系统失败原因：%msg',
    TRSC_054: '余额服务-拒绝提现处理，系统失败原因：%msg',
    TRSC_055: '余额服务-余额回滚处理失败，系统失败原因：%msg',
    TRSC_056: '用户服务-变更成长值处理失败，系统失败原因：%msg',
    TRSC_057: '活动服务-充值优惠处理失败，系统失败原因：%msg',
    TRSC_058: '转账服务-批量更新金额配置信息失败，系统异常原因：%msg',
    TRSC_059: '转账服务-更新转账服务的提現次數配置信息失败，系统异常原因：%msg',
    TRSC_060: '余额服务-查询余额失败，原因：%msg',
    TRSC_061: '用户服务-获取用户关联银行卡失败，系统异常原因：%msg',
    TRSC_062: '余额不足，需至少为%minBalance',
    TRSC_063: '%nameType金额必须在%minAmount与%maxAmount之间',
    TRSC_064: '流水未达到！还需要 %journal 流水',
    TRSC_065: '钱包動作執行失敗 msg: %msg',
    TRSC_066: '非常抱歉，系统只支持查询最近$betweens天内的数据',
    TRSC_083: '汇率已变更',
    RPT_001: 'type range is wrong',
    RPT_002: '未知的请求数据类型',
    CHAT_001: '未输入访客码',
    CHAT_002: '访客码不存在',
    CHAT_003: '访客码错误',
    CHAT_004: '用户名错误',
    CHAT_005: '房间号错误',
    CHAT_006: 'pageSize错误',
    CHAT_007: '您当前组别不允许进入房间',
    CHAT_008: '您当前等级不允许进入房间',
    CHAT_009: '全体禁言中，除管理员不允许发言',
    CHAT_010: '您当前组别不允许发言',
    CHAT_011: '您当前等级不允许发言',
    CHAT_012: '您已被永久禁言',
    CHAT_013: '您已被禁言1周',
    CHAT_014: '您已被禁言1天',
    CHAT_015: '您的ip已被永久禁言',
    CHAT_016: '您的ip已被禁言1周',
    CHAT_017: '您的ip已被禁言1天',
    CHAT_018: '禁言状态不允许发送图片、表情、红包',
    CHAT_019: '不可发送空',
    CHAT_020: '发言不可包含特殊字符',
    CHAT_021: '发言不可包含数字',
    CHAT_022: '发言不可包含英文',
    CHAT_023: '发言不可包含中文',
    CHAT_024: '房间已被删除',
    CHAT_025: '图片不存在',
    CHAT_026: '图片地址不能为空',
    CHAT_027: '图片来源有误',
    CHAT_028: '访客不可分享注单',
    CHAT_029: '访客不可分享战绩',
    CHAT_030: '访客不可发红包',
    CHAT_031: '红包发送失败',
    CHAT_032: 'messageTypeId错误',
    CHAT_033: 'message错误',
    CHAT_034: '最多收藏31张图片',
    CHAT_035: '图片已加入收藏',
    CHAT_036: '取消收藏失败',
    CHAT_037: '此房间密码错误次数已达当日限制',
    CHAT_038: '不可撤回红包',
    CHAT_039: '不可撤回注单分享',
    CHAT_040: '不可撤回战绩分享',
    CHAT_041: '只允许管理员撤回与用户撤回自己的消息',
    CHAT_042: '没有对应的消息记录',
    CHAT_043: '站长编码，管理员账号，房间号，禁言账号不能为空',
    CHAT_044: '不允许禁言管理员',
    CHAT_045: '禁言时间错误',
    CHAT_046: '当前访客不再此房间',
    CHAT_047: '用户已被禁言',
    CHAT_048: '只允许管理员禁言',
    CHAT_049: '当前用户未被禁言，无法修改',
    CHAT_050: '只允许管理员删除禁言',
    CHAT_051: '只允许管理员全体禁言',
    CHAT_052: '房间号不能为空',
    CHAT_053: '请输入分页信息',
    CHAT_054: '用户ID不能为空',
    CHAT_055: '游戏编号不存在',
    CHAT_056: '没有对应的游戏,请联系管理员',
    CHAT_057: '游客不能抢红包',
    CHAT_058: '房间不存在',
    CHAT_059: '当前你已被禁言,不能抢红包',
    CHAT_060: '当前组别不能领取该红包',
    CHAT_061: '获取用户当日报表错误',
    CHAT_062: '获取用户历史报表错误',
    CHAT_063: '加入收藏失败',
    CHAT_064: '发言字符数不可超过 %length',
    CHAT_065: '发言间隔不可小于 %interval 秒',
    CHAT_066: '今日充值金额达到%dayRecharge元才可领取该红包',
    CHAT_067: '累计充值金额达到%historyRecharge元才可领取该红包',
    CHAT_068: '当前等级不能领取该红包',
    TRSF_001: '内部服务维护中',
    TRSF_002: '无此红包',
    TRSF_003: '个人红包功能维护中',
    TRSF_004: '红包参数错误',
    TRSF_005: '测试帐号不可发红包',
    TRSF_006: '站点设定取得失败',
    TRSF_007: '已超过单笔最大金额设定',
    TRSF_008: '已达到发送金额限制',
    TRSF_009: '红包已达到发送限制',
    TRSF_010: '红包总金额超过金额限制',
    TRSF_011: '用户资料取得失败',
    TRSF_012: '用户额度扣取失败',
    TRSF_013: '红包发送失败',
    TRSF_014: '红包总金额超出发送限制',
    FILES_001: '档案为空',
    FILES_002: '档案大小不可超过5MB',
    FILES_003: 'imageId有误',
    FILES_004: '档案数量有误',
    FILES_005: '只能上传图档',
    FILES_006: '缺少图片或数量过多',
    FILES_007: '请确认上传的档案为图片',
    ACT_001: '请先切换站点',
    ACT_002: '计算中…',
    ACT_003: '非常抱歉，您的活动资格已被禁止',
    ACT_004: '活动已停止',
    ACT_005: '领取成功',
    ACT_006: '奖励已领取',
    ACT_007: '红包不存在!',
    ACT_008: '红包已取消!',
    ACT_009: '红包已领完!',
    ACT_010: '红包已过期!',
    ACT_011: '该红包已领取!',
    ACT_012: '用户接口异常!',
    ACT_013: '未达到领取条件!',
    ACT_014: '您无权限领取',
    ACT_015: '请勿频繁领取,正在领取中...!',
    ACT_016: '红包领取异常!',
    ACT_017: '活动尚未开始',
    ACT_018: '活动已结束',
    ACT_019: '活动已变更，请重新刷新',
    ACT_020: '今天已签到',
    ACT_021: '金额条件未达成',
    VIRWLT_001: '虚拟帐户异常',
    STK_001: '股种不存在',
    BCT_001: '已在其他终端登录',
    BET_039: '玩法校验错误',
    BET_037: '玩法校验错误 E01',
    BET_040: '玩法校验错误,追号重复',
    BET_041: '投资失败',
    BET_042: '投资类型校验错误A',
    BET_043: '投资类型校验错误B',
    BET_044: '金额校验错误C',
    BET_045: '金额有误',
    BET_046: '金额校验错误D',
    BET_047: '追号倍数错误',
    BET_048: '追号金额错误',
    FRT_001: '您的IP调用次数过多，请稍后重试',
    FRT_002: '鉴权异常，请联系管理员',
    FRT_003: '请求头异常，请刷新重试',
    FRT_004: '调用超时,请联系管理员',
    FRT_005: '服务异常',
    FRT_006: '请刷新重试',
    MNG_004: '地区限制',
    MNG_005: '网站维护中',
    MNG_006: '域名未绑定',
    MNG_007: '站点已关闭',
    MNG_008: '禁止跨站操作',
    USR_122: '尚未验证密保手机',
    USR_123: 'cardId is null',
    USR_124: 'users is null',
    USR_125: '银行卡已禁用，禁止修改',
    USR_128: '注册账号须为英文字母加数字组合',
    USR_126: '帐号只能包含数字',
    USR_127: '帐号只能包含英文',
    USR_073: '请修改现有银行卡数据',
    USR_129: '姓名格式错误',
    USR_130: '请填写姓名',
    USR_131: '请绑定注册时姓名的银行卡',
    USR_132: '找不到银行卡',
    USR_133: '请滑动验证码',
    USR_134: '您的验证信息有误',
    AXIOS_STATE_OVER_TIME_ERROR: '回复超时错误',
    AXIOS_STATE_NET_ERROR: '网络异常，请重试',
    AXIOS_STATE_LONG_TIME: '由于您长时间未操作，已自动退出，需要重新登录',
    AXIOS_STATE_LOCALNET_ERROR: '当前网络不可用，请检查网络后重试',
    NICKNAME_LIMIT: '昵称最多可包含 5 个日文和中文字符，或其他语言10字以内',
    /**
     * 仓单
     */
    INVEST_RECORDS: '投资记录',
    ACCOUNT_RECORDS: '帐务记录',
    WITHDRAW_DEDUCTION: '出金扣款',
    DEDUCTION_DETAIL: '扣款详情',
    TRANSFER_TO_VIRTUAL_WALLET: '转出至虚拟钱包',
    DISTRIBUTE_PROFIT: '派送盈利',
    INVEST_PLAN: '投资方案',
    CANCEL_ORDER: '撤单',
    NORMAL: '一般',
    CHASE: '追号',
    UNSTART: '未开始',
    PROCESSING: '进行中',
    FINISHED: '已结束',
    CANCEL_ORDER_REMINDER: '撤单不可恢复，是否确认撤单',
    ISSUE_NUMBER: '第 {issue} 期',
    WITHDRAW_NOTE: '提現申請提交成功，請在3-5分鐘後，查詢是否到帳',
    WITHDRAE_REFUND: '出金退回',
    WITHDRAW_REJECT: '出金拒绝',
    THIRD_WITHDRAW_REJECT: '代付拒绝',
    IN_PROCESS: '处理中',
    DEPOSIT_FAIL: '入金失败',
    WAREHOUSE_CHASE_NUMBER_ITEM_FULL: '未盈利',
    WAREHOUSE_CHASE_NUMBER_ITEM_PENDING: '进行中',
    WAREHOUSE_CHASE_NUMBER_ITEM_WAIT: '等待出仓',
    WAREHOUSE_ACCOUNT_WITHDRAW_ITEM_TITLE: '出金扣款',
    WAREHOUSE_ACCOUNT_ACTION_SHEET_SERIAL_NUMBER: '流水号',
    WAREHOUSE_ACCOUNT_WITHDRAW_ACTION_TIME_TITLE: '交易时间',
    WAREHOUSE_ACCOUNT_WITHDRAW_ACTION_MONEY: '交易金额',
    WAREHOUSE_ACCOUNT_WITHDRAW_ACTION_BALANCE: '可用余额',
    WAREHOUSE_ACCOUNT_WITHDRAW_ACTION_REMARK: '备注',
    WAREHOUSE_ACCOUNT_WITHDRAW_CANCEL_BTN_TEXT: '返回',
    WAREHOUSE_RECORDCENTER_CHASENUMBER_UNPROFITABLE: '未盈利',
    WAREHOUSE_RECORDCENTER_CHASENUMBER_PENDING: '等待出仓',
    WAREHOUSE_RECORDCENTER_CHASENUMBER_ING: '进行中',
    WAREHOUSE_RECORDCENTER_CHASENUMBER_CHASE_NUMBER_TITLE: '追号期号',
    WAREHOUSE_RECORDCENTER_CHASENUMBER_ORDER_TEXT1: '第',
    WAREHOUSE_RECORDCENTER_CHASENUMBER_ORDER_TEXT2: '%{num} 期起',
    WAREHOUSE_RECORDCENTER_CHASENUMBER_CHASE_TIME_TITLE: '追号时间',
    WAREHOUSE_RECORDCENTER_CHASENUMBER_CHASE_ORDER_TITLE: '追号单号',
    WAREHOUSE_RECORDCENTER_CHASENUMBER_CHASE_MONEY_TITLE: '追号金额',
    WAREHOUSE_RECORDCENTER_CHASENUMBER_BET_TITLE: '投资金额',
    WAREHOUSE_RECORDCENTER_CHASENUMBER_BONUS_TITLE: '派送盈利',
    WAREHOUSE_RECORDCENTER_CHASENUMBER_AUTO_BUY_AFTER_WIN_TITLE: '盈利循环',
    WAREHOUSE_RECORDCENTER_CHASENUMBER_PLAY_TYPE: '投资方案',
    WAREHOUSE_RECORDCENTER_CHASENUMBER_CHASE_SCHEDULE: '追号进度',
    WAREHOUSE_RECORDCENTER_CHASENUMBER_CHASE_END: '已追%{num}期',
    WAREHOUSE_RECORDCENTER_CHASENUMBER_CHASE_END_TEXT: '总%{num}期',
    WAREHOUSE_RECORDCENTER_CHASENUMBER_CHANGE_PLAY_TYPE_MSG: '修改后不可恢复，是否确认变更设置为盈立即停，并停用盈利循环',
    WAREHOUSE_RECORDCENTER_CHASENUMBER_C_ISSUE_TITLE: '投资期号',
    WAREHOUSE_RECORDCENTER_CHASENUMBER_C_TIME_TITLE: '投资时间',
    WAREHOUSE_RECORDCENTER_CHASENUMBER_C_ORDER_TITLE: '投资单号',
    WAREHOUSE_RECORDCENTER_CHASENUMBER_C_OPEN_NUMBER_TITLE: '开奖号码',
    WAREHOUSE_RECORDCENTER_CHASENUMBER_C_BET_TITLE: '投资金额',
    WAREHOUSE_RECORDCENTER_CHASENUMBER_C_BONUS_TITLE: '派送盈利',
    WAREHOUSE_RECORDCENTER_CHASENUMBER_C_PLAYTYPE_TITLE: '投资方案',
    // 本日盈虧
    PROFIT_LOSS_TODAY: '本日盈虧',
    PROFIT_AMOUNT: '盈利金額',
    PROFIT_TODAY: '今日',
    PROFIT_MOUNTH: '月',
    PROFIT_DAY: '日',
    PROFIT_INVEST_AMOUNT: '投资额',
    PROFIT_GAIN: '盈利额',
    PROFIT_AGENT_REBATE: '代理返点',
    PROFIT_ACTIVITY: '活动礼金',
    PROFIT_FORMULA_MEESSAGE: '盈利计算公式: 盈利 - 投资 + 活动 + 返点',
    PROFIT_CREDIT: '入账',
    PROFIT_DEPOSIT: '入金額',
    PROFIT_GET_RED_ENVELOPE: '領取紅包',
    PROFIT_PAY_OUT: '出账',
    PROFIT_WITHDRAWAL: '出金額',
    PROFIT_SEND_RED_ENVELOPE: '发送红包',
    PROFIT_FORMULA: '盈亏计算公式',
    PROFIT_LOSS_FORMULA: ' 盈亏 = 盈利 - 投资 + 活动 + 返点',
    PROFIT_I_KNOW: '我知道了',
    // 代理中心
    ACTING_BALANCE: '团队余额',
    ACTING_AGENCY_REPORT: '代理报表',
    ACTING_TEAM_PROFIT: '团队盈利',
    ACTING_LOOK_ALL: '查看全部',
    ACTING_PROFIT_REBATE: '盈利返点',
    ACTING_SUB_REPORT: '下级报表',
    ACTING_MEMBER_MANAGEMENT: '会员管理',
    ACTING_SUB_ACCOUNT: '下级开户',
    ACTING_INVESTMENT_DETAILS: '投资明细',
    ACTING_TRANSACTION_DETAILS: '交易明细',
    ACTING_UPDATE: '更新',
    ACTING_CARD01_TITLE: '投资与发展',
    ACTING_INSTRUCTION: '代理说明',
    ACTING_CARD01_TEXT: '当您能看到这个页面，说明您的账号即是玩家账号也是代理账号，即可以自己投资，也可以发展下级玩家，赚取返点佣金。',
    ACTING_CARD02_TITLE: '如何赚取返点?',
    ACTING_INVESTMENT_REBATE: '投资返点',
    ACTING_SELF: '自已',
    ACTING_SUB: '下级',
    ACTING_MATH: '数',
    ACTING_INVEST: '投資',
    ACTING_SUB_INVEST: '下级投资',
    ACTING_REBATE: '返利',
    ACTING_DEPOSIT_REBATE: '入金返点',
    ACTING_SET_REBATE: '抽成设置返点',
    ACTING_CASH: '以D投入现金1000元为例',
    ACTING_TOTAL_REWARD: '總獎勵',
    ACTING_CLICK_ACCOUNT: '点击下级开户',
    ACTING_SEARCH_REBATE: '可查看自身返点，也可为下级设置返点。',
    ACTING_HOW_SUB_ACCOUNT: '如何为下级开户?',
    ACTING_PARAGRAPH_FIR: '点击下级开户，先为您的下级设置返点，设置成功后会生成一条邀请码。',
    ACTING_PARAGRAPH_SEC: '将邀请码发送给您的下级注册，注册后他就是您的下级，点击会员管理，就能查看他注册的账号。',
    ACTING_PARAGRAPH_THR: '如果您为下级设置的是代理类型的账号，那么您的下级就能继续发展下级。',
    ACTING_PARAGRAPH_FOUR: '如果设置的是玩家类型，那么您的下级只能投资，不能再发展下级，也看不到代理中心。',
    ACTING_TIPS: '温馨提示',
    ACTING_TEXT_FIR: '返点不同赔率也不同，点击返点赔率表，可查看返点赔率。',
    ACTING_TEXT_SEC: '返点越低，赔率就越低，建议为下级设置的返点不要过低。',
    ACTING_TEXT_THR: '可在代理报表、投资明细、交易明细查看代理的发展情况。',
    ACTING_TEXT_FOUR: '建议开设的下级也是代理类型，无论发展了几级，您都能获得返点。',
    ACTING_SET_RANGE: '返点设置范围',
    ACTING_WIN_LOTTERY: '當下級中獎時，就能獲得營利佣金。',
    ACTING_WIN_LOTTERY_TWO: '当下级营利时，就能获得营利佣金。',
    ACTING_COMMISSION_FORMULA_TWO: '营利佣金公式 : ( 营利金额 - 投注金额 ) * 营利反点',
    ACTING_COMMISSION_FORMULA: '营利佣金公式 : ( 营利金额 - 投注金额 ) * 营利反点',
    // 代理報表
    REPORT_TOTAL_PERSON: '总计投资人数',
    REPORT_YESTERDAY: '昨日',
    REPORT_THIS_MONTH: '这个月',
    REPORT_LAST_MONTH: '上个月',
    REPORT_TEAM_DEAL: '团队交易',
    REPORT_TEAM_CREDIT: '团队入账',
    REPORT_TEAM_BILLING: '团队出账',
    REPORT_TEAM_MANAGE: '团队管理',
    REPORT_SING_PERSON: '注册人数',
    REPORT_PERSON: '<div class="number-title">注册人数</div><span>%{num}</span><span class="people-unit">人</span>',
    REPORT_COUNT_SUB_PERSON: '总计下级人数',
    REPORT_CHECK_SING_PERSON: '查看注册人数',
    // 代理報表>詳細資訊
    REPORT_INVESTORS_NUMBER: '投资人数',
    REPORT_TOTAL_TIMES: '合计次数',
    REPORT_TOTAL_AMOUNT: '合计金额',
    REPORT_TIME: '次',
    REPORT_COUNT_TOTAL_FIRST: '总计首充人数',
    REPORT_AMOUNT_RECEIVED: '收款额',
    REPORT_TO_MONTH: '本月',
    REPORT_PREV_MONTH: '上月',
    REPORT_FIRST_CHARGE: '首充人数',
    REPORT_TRANSFER_AMOUNT: '转账额',
    REPORT_NUMBER_SUBORDINATES: '下级人数',
    REPORT_COUNT_PERSON: '合计人数',
    REPORT_OWNED_SUPERIOR: '所属上级',
    REPORT_INVITER_ACCOUNT: '邀请人账号',
    REPORT_SEARCH_FROM: '搜寻下级报表',
    REPORT_SEARCH_ACCOUNT: '填写下级账号可查询下级报表',
    REPORT_NOT_FIND: '查询查无资料，请更变其它关键字查询',
    //  代理中心>其他選項
    REPORT_CHECK_SUB: '查看下级',
    REPORT_ALREADY_SHOW: '已显示%{msg}全部紀錄',
    REPORT_ACCOUNT_SEARCH: '填写账号可查询下级',
    REPORT_VIEW_REBATE: '查看返点',
    REPORT_GRADE: '級',
    REPORT_GRADE2: '%{grade}級',
    REPORT_STOCK_REBATE: '股指返點',
    REPORT_DEPOSIT_REBATE: '入金返點',
    REPORT_SHORT_TERM: '短线股',
    REPORT_AGENT_TYPE: '代理類型',
    REPORT_PLAY_TYPE: '玩家類型',
    REPORT_SING_UP: '註冊',
    REPORT_SET_REBATE: '可设置返点',
    REPORT_SET_REBATE_1: '可设置整数返点',
    REPORT_SELF_REBATE: '自身返点',
    REPORT_CODE_MESSAGE: '邀请码已成生，请点击邀请码管理进行查询，是否查看?',
    REPORT_GO_VIEW: '前往查看',
    REPORT_STAY_PAGE: '留在本頁',
    REPORT_BORN_INVITATION_CODE: '生成邀请码',
    REPORT_QR_CODE: '邀请码推广链接二维码 (长按可保存)',
    REPORT_COPY_LINK: '复制推广链接',
    REPORT_COPY_INVITATION_CODE: '复制邀请码',
    REPORT_SUCCESS_COPY: '成功复制',
    REPORT_CONFIRM_DELETE_CODE: '您确定要删除这条邀请码?',
    REPORT_DELETE_CODE: '删除邀请码',
    REPORT_ACTING: '代理',
    REPORT_PLAYER: '玩家',
    REPORT_FIELD_ERROR: '输入栏位错误',
    REPORT_CHECK_INPUT: '请检查输入值',
    REPORT_NOT_CREATED_CODE: '邀请码未建立',
    //  投資明細
    INVEST_TO: '至',
    INVEST_ALL: '全部',
    INVEST_PROFITABLE: '已盈利',
    INVEST_UNPROFITABLE: '未盈利',
    INVEST_WAITING_RELEASED: '等待出仓',
    INVEST_ORDER_CANCELLED: '已撤单',
    INVEST_STOP_PROFIT: '盈利即停',
    INVEST_SEVEN_DAYS: '近七日',
    INVEST_PERIOD_NUMBER: '投资期号',
    INVEST_TIME: '投资时间',
    INVEST_SINGLE_NUMBER: '投资单号',
    INVEST_MONEY: '投资金额',
    INVEST_BONUS: '派送奖金',
    INVEST_WINNING_NUMBERS: '开奖号码',
    INVEST_HOW_PLAY: '玩法',
    // 交易明細
    DEAL_ALL_TYPE: '所有类型',
    DEAL_WITHDRAWAL_RECORD: '出金记录',
    DEAL_DEPOSIT_RECORD: '入金记录',
    DEAL_SERIAL_NUMBER: '流水号',
    DEAL_TIME: '交易时间',
    DEAL_CASH: '交易金额',
    DEAL_BALANCE: '可用余额',
    DEAL_REMARK: '备注',
    DEAL_WITHDRAWALING: '出金中',
    DEAL_WITHDRAWAL_SUCCESS: '出金成功',
    DEAL_WITHDRAWAL_ERROR: '出金失敗',
    DEAL_WITHDRAWAL_REJECT: '出金拒絕',
    DEAL_PAY_ERROR: '代付失敗',
    DEAL_PAY_REJECT: '代付拒絕',
    DEAL_PAY_SUCCESS: '代付成功',
    DEAL_PAYING: '代付中',
    DEAL_DEPOSITING: '入金中',
    DEAL_DEPOSIT_SUCCESS: '入金成功',
    DEAL_DEPOSIT_ERROR: '入金失敗',
    DEAL_PENDING_PAYMENT: '待付款',
    DEAL_COPY_SUCCESS: '复制成功',
    DEAL_INVESTMENT_DEDUCTIONS: '投资扣款',
    // 安全中心
    SECURITY_CENTER: '安全中心',
    SECURITY_CHANGE_LOGIN_PASSWORD: '更改登入密码',
    SECURITY_CHANGE_SAFE_PASSWORD: '更改安全密码',
    SECURITY_CHANGE_SECRET_PASSWORD: '更改密保问题',
    SECURITY_FIND_SAFE_PASSWORD: '找回安全密码',
    SECURITY_CONFIRM_SAFE_PASSWORD: '确认安全密码',
    SECURITY_BANK_CARD: '银行卡管理',
    SECURITY_ALTER: '更改',
    SECURITY_SETING: '设置',
    SECURITY_GO_SETTING: '前往设置',
    SECURITY_NEW_OLD_NOT_SAME: '新密码与旧密码不能一致',
    SECURITY_NOT_SAME: '密码不能跟账号相同，且不能设置过于简单，禁止设置为：123456、123123、654321、111111等此类简单密码',
    // 更改登入密碼
    SECURITY_LOGIN_KEY: '登入密钥',
    SECURITY_UPDATE_PASSWORD: '登录时使用，建议每二个月应更换密码。',
    SECURITY_PASSWORD: '原始密码',
    SECURITY_RESET_PASSWORD: '重设密码',
    SECURITY_CONFIRM_PASSWORD: '确认密码',
    SECURITY_SUCCESS_UPDATE: '修改成功，需要重新登入',
    SECURITY_PASSWORD_INCONSISTENT: '两次输入密码不一致',
    SECURITY_UPDATE_SUCCESS: '修改成功',
    // 找回安全密码
    SECURITY_PASS_QUESTION: '通过密保问题',
    SECURITY_PASS_ID: '通过身分识别',
    SECURITY_SECRET_QUESTION: '密保问题',
    SECURITY_CONFIRM_SECRET: '验证密保问题',
    SECURITY_FIND_ACCOUNT: '增加账户安全性，快速找回账号密码。',
    SECURITY_PASSWORD_AGIAIN: '再次输入安全密码',
    SECURITY_INPUT_ANSWER: '输入答案',
    SECURITY_FIND_ENTER_QAMSG: '请输入密保问题',
    SECURITY_VERITY_PASSWORD: '验证登入密码',
    SECURITY_CONFIRM_USER: '确认身份',
    SECURITY_LOGIN_SAFE_PASSWORD: '通过登入密码，找回您的安全密码',
    SECURITY_LOGIN_PASSWORD: '登入密码',
    // 銀行卡管理
    BANKCARD_INSTRUCTION: '银行卡说明',
    BANKCARD_LAST_NUMBER: '尾号',
    BANKCARD_MESSAGE: '已绑定%{num}张银行卡尚未使用，无法绑定其他银行卡。若需要使用其他银行卡，请编辑修改当前绑定资料即可。 ',
    BANKCARD_LOCK: '银行卡绑定成功出金后，会自动锁定，无法删除和修改。最多可以绑定5张同名银行卡。',
    BANKCARD_ADD: '添加银行卡',
    BANKCARD_UPDATE: '修改银行卡',
    BANKCARD_CHECK: '验证银行卡',
    BANKCARD_PASS_ID_CONFIRM: '请通过验证以确认身分',
    BANKCARD_CONFIRM_SAFE_PASSWORD: '验证安全密码',
    BANKCARD_CONFIRM_RESULT: '验证结果',
    BANKCARD_OPEN_ACCOUNT: '开户银行',
    BANKCARD_ACCOUNT_NAME: '开户人姓名',
    BANKCARD_NUMBER: '银行卡号',
    BANKCARD_ADDRESS: '开户地点',
    BANKCARD_CONFIRM_NOT_ERROR: '确认以下内容无误',
    BANKCARD_NAME_ERROR: '银行账户名有误，请重新选择',
    BANKCARD_DATA_ERROR: '银行卡资料有误，请重试',
    BANKCARD_ID_SUCCESS: '身份验证成功!',
    BANKCARD_SPACE_DONT: '请勿输入空值',
    BANKCARD_MESSAGE_ONE: '最多可以绑定5张银行卡，成功出金的银行卡会自动锁定，无法删除和修改。',
    BANKCARD_MESSAGE_TWO: '您已绑定5张银行卡，无法绑定更多的卡。成功出金的银行卡会自动锁定，无法删除和修改。',
    BANKCARD_MESSAGE_THREE: '您已绑定1张银行卡尚未使用，无法绑定其他银行卡。若需要使用其他银行卡，请编辑修改当前绑定资料即可。<strong>银行卡绑定成功出金后，会自动锁定，无法删除和修改。最多可以绑定5张同名银行卡。</strong>',
    BANKCARD_MESSAGE_FOUR: '您已绑定1张银行卡尚未使用，无法绑定其他银行卡。若需要使用其他银行卡，请联系客服。',
    BANKCARD_MANY_BIND: '还可以绑定%{num}张',
    BACKCARD_OTHER: '其它银行',
    BANKCARD_BIND_MORE: '还可以绑定%{msg}張',
    BANKCARD_IMMEDIATELY: '安全密码已设置成功,是否立即绑定银行卡',
    BANKCARD_BIND_CARD: '绑定银行卡',
    BANKCARD_16_NUMBER: '16码',
    BANKCARD_17_NUMBER: '17码',
    BANKCARD_18_NUMBER: '18码',
    BANKCARD_19_NUMBER: '19码',
    BANKCARD_BANKTYPE_ID: '%{msg}码',
    // 聯絡我們
    ABOUT_TITLE: '%{msg}成立于2014年，提供最专业的股票投资系统，为广大用户提供＂安全、可靠、极致＂的服务体验。',
    ABOUT_SUB_TITLE: '多重认证下的安全 交易平台',
    ABOUT_SUB_TITLE_TWO: '%{msg}获国际第三方 STS、诺顿、GEOTRUST认证，并在其严格标准下为各位服务，绝无任何恶意软件。',
    ABOUT_TEXT_ONE: '%{msg}长期秉持＂以诚为根，以信为本＂的服务宗旨，为广大用户提供最优质的购股体验。',
    ABOUT_TEXT_TWO: '%{msg}拥有行业领先的技术服务，包括完整方便的即时咨询服务系统，以及自主研发的app端等诸多技术产品。',
    ABOUT_TEXT_THREE: '%{msg}提供最合理的赔率，多渠道的收付款方式，专业严谨的风险控管及第三方资金保险，100％保证玩家资金。',
    LEGAL_NOTICES: '法律声明',
    LEGAL_TITLE: '对于本网站上的任何内容，任何人不得在非本网站所属的服务器上做镜像。',
    LEGAL_SUB_TITLE: '%{msg}一個理想 安全的交易平台',
    LEGAL_TEXT_ONE: '本网站提供的任何内容（包括但不限于数据、文字、图表、图象、声音或录象等）的版权均属于本网站或相关权利人。',
    LEGAL_TEXT_TWO: '未经本网站或相关权利人事先的书面许可，您不得以任何方式擅自复制、再造、传播、出版、转帖、改编或陈列本网站的内容。',
    LEGAL_TEXT_THREE: '同时，未经本网站书面许可，对于本网站上的任何内容，任何人不得在非本网站所属的服务器上做镜像。任何未经授权使用本网站的行为都将违反《中华人民共和国著作权法》和其他法律法规以及有关国际公约的规定。',
    CONTACT_US: '联系我们',
    CONTACT_TITLE: '%{msg}客服中心全年无休，提供1周7天、每天24小时的优质服务。',
    CONTACT_SUB_TITLE: '%{msg}第三方资金保险 100％保证资金安全',
    CONTACT_TEXT_ONE: '如果您对本网站的使用有任何疑问，可以通过下列任一方式与客服人员联系，享受最实时的服务。',
    CONTACT_CLICK: '点击',
    CONTACT_TEXT_TWO: '即可进入在线客服系统与人员联系。',
    CONTACT_CONTENT_COOPERATION: '内容合作',
    CONTACT_TEXT_THREE: '如果您拥有精彩或原创的与股票玩法股票技巧等相关的内容或其他资源,欢迎您与我们取得联系！',
    CONTACT_ADVERTISE_COOPERATION: '广告合作',
    CONTACT_TEXT_FOUR: '通过对网站广告位的互换及其他自由组合形式的广告资源置换来扩大宣传、增加多样化服务入口，最终以提升网站用户能获取更多附加价值为目的。如果您有相应的资源，非常欢迎您和我们取得联系。',
    CONTACT_MEDIA_COOPERATION: '媒体合作',
    CONTACT_TEXT_FIVE: '如果您拥有互联网、微信、微博等各类传统与新媒体的丰富资源欢迎您与我们取得联系,让我们通过双方的友好合作来共同提高彼此的影响力！',
    CHOOSE: '选择%{msg}',
    CHOOSE_TITLE: '%{msg}立志向交易者提供无可比拟的金融产品交易体验，让交易者们能够在市场上持续盈利创造优势。',
    CHOOSE_SUB_TITLE: '%{msg}通過多項三方國際认证',
    CHOOSE_DEAL_TRANSPARNET: '交易透明化',
    CHOOSE_TEXT_ONE: '作为一家亚洲顶级交易商，我们为客户提供顶级透明度，杜绝交易员干预交易执行。',
    CHOOSE_TEXT_TWO: '在我们的客户群中，已服务大量专业交易者，认可%{msg}产品运作的完整性与透明度。',
    CHOOSE_DEAL_SYSTEM: '强大交易系统',
    CHOOSE_TEXT_THREE: '%{msg}是当前顶尖强大最受用户欢迎的交易平台，致力于自主研发APP双平台交易。',
    CHOOSE_TEXT_FOURE: '在%{msg}您可体验快速便捷的金融商品交易。',
    CHOOSE_ORDER_SPEED: '无可比拟的订单执行速度',
    CHOOSE_TEXT_SIX: '%{msg}运用尖端技术优化及稳定网络传轮环境，保证超快的执行速度。',
    CHOOSE_TEXT_FIVE: '通过成熟技术解决方案，使得买入立即执行变为现实，也让平台上的交易者们，能在瞬息万变的市场上取得优势条件。',
    CHOOSE_INTERNATIONAL_NORMS: '认证合乎国际规范',
    CHOOSE_TEXT_SEVENT: '%{msg} 获得TST、诺顿、GEOTRUST国际第三方认证，并在其严格标准下为各位服务。',
    CHOOSE_MONEY_SAFE: '资金安全，成本优惠',
    CHOOSE_TEXT_EIGHT: '%{msg} 指定亚洲顶级银行作为客户资金信托银行，客户资金独立保存，于公司运营资金完全分离。',
    CHOOSE_TEXT_NINE: '我司只能于客户授权以交易为目的调取资金。',
    CHOOSE_TEXT_TEN: '在服务时间内%{msg}保证客户取款，当天到帐。',
    CHOOSE_TEXT_ELEVEN: '%{msg}与顶级流程性服务商合作，为每一位客户提供极具竞争力的点差，及灵活的交易条件。',
    CHOOSE_HIGH_QUALITY: '色々なコモディティ、質の高いサービス',
    CHOOSE_TEXT_TWELVE: '%{msg}提供包括外汇、贵金属、虚拟货币在内，计有40+交易品种',
    PRIVACY_STATEMENT: '隐私声明',
    PRIVACY_TITLE: '对于本网站上的任何内容，任何人不得在非本网站所属的服务器上做镜像。',
    PRIVACY_TEXT_ONE: '在登陆时向我们提供您的个人信息是基于对我们的信任，我们会以专业、谨慎和负责的态度对待您的个人信息。',
    PRIVACY_TEXT_TWO: '因此，我们制定了个人信息保密制度以保护您的个人信息。凡以任何方式登陆本网站或直接、间接使用本网站资料者，视为自愿接受本网站声明的约束。我们的隐私权保护条款如下幾點',
    PRIVACY_MESSAGE: '个人信息收集',
    PRIVACY_TEXT_THREE: '个人信息的收集在您注册、使用本网站服务时，经您的同意，我们收集与个人身份有关的信息。如果您无法提供相应信息，可能会不能使用对应服务。我们也会基于优化用户体验的目的，收集其他有关的信息，以便优化我们的网站服务。',
    PRIVACY_PROTECT: '隐私的保护',
    PRIVACY_TEXT_FOUR: '隐私的保护保护用户隐私是本网站的一项基本政策。本网站不会公布或传播您在本网站注册的任何资料，但下列情况除外：',
    PRIVACY_ITEM_ONE: '事先获得用户的明确授权；',
    PRIVACY_ITEM_TWO: '用户对自身信息保密不当原因，导致用户非公开信息泄露；',
    PRIVACY_ITEM_THREE: '由于网络线路、黑客攻击、计算机病毒、政府管制等原因造成的资料泄露、丢失、被盗用或被篡改等；',
    PRIVACY_ITEM_FOUR: '根据有关法律法规的要求',
    PRIVACY_ITEM_FIVE: '依据法院或仲裁机构的裁判或裁决，以及其他司法程序的要求；',
    PRIVACY_ITEM_SIX: '按照相关政府主管部门的要求',
    PRIVACY_PUBLIC: '自我更新与信息公开',
    PRIVACY_TEXT_FIVE: '我们鼓励您自我更新和修改个人信息以使其安全和有效。',
    PRIVACY_TEXT_SIX: '您能在任何时候非常容易地获取并修改您的个人信息，您可以随时自行决定修改、删除您在网站上的相关资料。您是唯一对您的账号和密码信息负有保密责任的人，任何情况下，请小心妥善保管。',
    PRIVACY_TEXT_SEVEN: '无论何时您自愿在公开场合披露个人信息， 此种信息可能被他人收集及使用，因此造成您的个人信息泄露，网站不承担责任。',
    PRIVACY_HIT: '提示',
    PRIVACY_TEXT_EIGHT: '我们可能会不时修改我们的隐私政策，这些修改会反映在本声明中，我们的任何修改都会将您的权益和满意度置於首位，我们希望您在每次访问我们的网页时都查阅我们的隐私声明，用户继续享用服务，则视为接受服务条款的变动。',
    // 聊天室
    CHATROOM: '聊天室',
    CHATROOM_NO_ROOM: '暂无开放的房间',
    CHATROOM_DELETE_ROOM: '房间已被删除',
    CHATROOM_ALL_SHOW_ROOM: '已显示全部房间',
    CHATROOM_CELL: '单元格',
    CHATROOM_CONTENT: '内容',
    CHATROOM_YOU: '你',
    CHATROOM_SPEND_IMG: '传送了一张图片',
    CHATROOM_SHARE_BET_SLIP: '分享了一则注单',
    CHATROOM_SPEND_PLAN: '传送了一則推送計畫',
    CHATROOM_SPEND_RED_ENVELOPE: '正在发红包',
    CHATROOM_ALREADY_DELETE: '房间已被删除',
    CHATROOM_YESTERDAY: '昨天',
    CHATROOM_BEFORE_YESTERDAY: '前天',
    CHATROOM_ALL_DATA: '已显示全部数据',
    CHATROOM_FROM_WEB: '此图片来源于网络',
    CHATROOM_FORVER: '永久',
    CHATROOM_WEEK: '一周',
    CHATROOM_DAY: '一天',
    CHATROOM_HOUR: '一小时',
    CHATROOM_HALF_HOUR: '半小时',
    CHATROOM_UNMUTE: '取消禁言',
    CHATROOM_COLLECTION_SUCCESS: '收藏成功',
    CHATROOM_CONNECTION_FAILED: '连接失败，请稍后再试',
    CHATROOM_UNMUTE_FORVER: '您已被永久禁言',
    CHATROOM_UNMUTE_DAY: '您已被禁言1天',
    CHATROOM_UNMUTE_WEEK: '您已被禁言1周',
    CHATROOM_UNMUTE_HOUR: '您已被禁言1小时',
    CHATROOM_UNMUTE_HAL_HOUR: '您已被禁言半小时',
    CHATROOM_ACCOUNT_CHAG: '账号权限已变更，请重新进入房间',
    CHATROOM_UNMUTE_LEVEL: '您的等级已禁言',
    CHATROOM_WS_FAILED: 'ws接收失败',
    CHATROOM_CONGRATULATE: '恭喜发财，大发发发发',
    CHATROOM_NOT_FOLLOW_UP: '访客不能跟投，请先注册登录',
    // 聊天室component
    CHATCELL_ALL_UNMUTE: '全体禁言',
    CHATCELL_ENTER: '输入发言',
    CHATCELL_IMG: '图片',
    CHATCELL_RED_ENVELOPE: '红包',
    CHATCELL_NOT_SPEND: '访客不能发送红包，请先注册登录',
    CHATCELL_NOT_SET_PASSWORD: '你还未设置安全密码,无法发送红包，是否先去设置安全密码?',
    CHATCELL_OPEN_HELPER: '访客不能开启长龙助手，请先注册登录',
    CHATCELL_IMG_LOAD_FAIL: '图片加载失败',
    CHATCELL_MUTE_MANAGEMENT: '禁言管理',
    CHATCELL_ROOM_INTRODUCE: '房间介绍',
    CHATCELL_LINE: '社群守則，但是「限制」的力量是有限的，真正重要的是每一位使用者，能了解、並遵守LINE社群守則，不只自己使用更安心，也才能讓「LINE社群」這個新功能發揮所長，好好來服務大家。',
    CHATCELL_EXPIRED: '该红包已超过12小时。如已领取，可在"我的红包"中查看。',
    CHATCELL_ROBBED: '已抢光',
    CHATCELL_CHECK_RED_ENVELOPE: '该红包已抢过，可在"我的红包"中查看。',
    CHATCELL_GET_DETAILS: '查看领取详情',
    CHATCELL_WHO_RED_ENVELOPE: '%{msg}的红包',
    CHATCELL_SPELL: '拼',
    CHATCELL_GENERAL: '普',
    CHATCELL_THE: '个',
    CHATCELL_DOLLAR: '元',
    CHATCELL_SAVE_BALANCE: '已存入余额，可用于投资',
    CHATCELL_GET_COUNT: '已领取%{num}/%{total}个',
    CHATCELL_NUM_TOTAL: '共%{num}/%{total}元',
    CHATCELL_TOTAL: '共%{num}元',
    CHATCELL_ROBBED_ALREADY: '%{num}个红包共%{total}元，已被抢光',
    CHATCELL_LACKY: '手气最佳',
    CHATCELL_NOT_RECORD: '暂无领取记录',
    CHATCELL_OUT_RECORD: '暂无出仓记录',
    CHATCELL_TEMPORARY_RECORD: '暂无记录',
    CHATCELL_RESIVE_RED_ENVELOPE: '收到的红包',
    CHATCELL_SEVEN_DAT_TWO: '最近7天收到%{num}个红包',
    CHATCELL_PLAYER_MESSAGE: '玩家信息',
    CHATCELL_ADMINISTRATOR: '管理员',
    CHATCELL_ALL_PEOPLE: '所有人',
    CHATCELL_NEWS: '%{num}条新消息',
    CHATCELL_HISTORICAL_OUT: '历史出仓',
    CHATCELL_DATE: '%{msg}期',
    CHATCELL_NOT_GET: '不中',
    CHATCELL_GET: '中',
    CHATCELL_WAIT: '等待中',
    CHATCELL_DRAW: '和',
    CHATCELL_FALL: '跌',
    CHATCELL_BEAR: '熊',
    CHATCELL_SINGLE: '单',
    CHATCELL_UP: '涨',
    CHATCELL_PAIR: '双',
    CHATCELL_COW: '牛',
    CHATCELL_BIND_SUCCESS: '绑定成功',
    CHATCELL_NOT_EMPTY: '内容不能为空',
    CHATCELL_RECORD_UNMUTE: '暂无禁言记录',
    CHATCELL_NOT_SET_NICKNAME: '未设置昵称',
    // 紅包
    MAIL_SPEND: '发红包',
    MAIL_USE_RULES: '发红包使用规则',
    MAIL_USE_MONEY: '可出金金额达%{num}元',
    MAIL_USE_MONEY_TWO: '，才能用于发红包。',
    MAIL_SINGEL_MONEY: '单个金额',
    MAIL_TOTAL: '总金额',
    MAIL_NUMBER: '红包个数',
    MAIL_TWELVE_HOURS: '未领取的红包，将于12小时后发起退款',
    MAIL_LOW_ONE: '人均单个红包金额',
    MAIL_HEIGH_DOLLAR: '最低 %{one} 元 最高 %{num} 元',
    MAIL_INPUT_PASSWROD: '请填写安全密码',
    MAIL_SIX_PASSWORD: '安全密码为6位数字',
    MAIL_HAVE_INTEGER: '红包个数必须为正整数',
    MAIL_FIVE_HUNDRED: '红包个数不能超过500个',
    MAIL_BIG_DOLLAR: '人均单个红包金额不能大于 %{num} 元',
    MAIL_CASH_NOT_ENOUGH: '您的可出金金额不足',
    MAIL_GRAND_TOTAL: '每日累计发红包金额不可超过%{total}元，您还可发%{num}元',
    MAIL_LIMIT_DAY: '您每日可发红包次数为%{num}次，已超限制',
    MAIL_LESS_DOLLAR: '人均单个红包金额不能小于1元',
    MAIL_NOT_EMPTY: '不能为空',
    MAIL_WRONG_FORMAT: '格式不对',
    MAIL_LESS_DOLLAR_TWO: '单个红包金额不可低于 1 元',
    MAIL_REMARK: '备注必须填写',
    MAIL_LACKY: '已改为拼手气红包',
    MAIL_NORMAL: '已改为普通红包',
    MAIL_ADD_FACE: '添加的表情',
    MAIL_TIDY: '整理',
    MAIL_FIRST_LOGIN: '访客不能收藏表情图片，请先注册登录',
    MAIL_DELETE: '正在删除',
    MAIL_COMPRESSION: '正在压缩',
    MAIL_UPLOAD: '正在上传',
    MAIL_ASSISTANT: '长龙助手',
    MAIL_CANT_OVER: '单个红包金额不可超过<span class="color-danger">%{num}</span>',
    MAIL_BIGGER_OUT: '单次红包金额不可大于可出金金额',
    MAIL_ACCOUNT_SERACH: '输入账号查询',
    MAIL_ALREADY_GET: '已被领完',
    MAIL_RETURN: '已退还',
    MAIL_RECEIVE: '已领取',
    MAIL_EXPIRED: '已过期',
    MAIL_VISITOR_GRAB: '访客不能抢红包，请先注册登录',
    MAIL_MESSAGE: '数来宝時來運轉，快來搶紅包',
    MAIL_TIME_UP: '限时红包',
    // UserInfo.vue
    USERINFO_LIKE: 'Ta喜欢的股票',
    USERINFO_TEST: '测试组',
    USERINFO_SET_NICKNAME: '昵称未设置',
    USERINFO_SECRET: '保密',
    USERINFO_BRONZE: '青铜',
    USERINFO_SILVER: '白银',
    USERINFO_GOLD: '黄金',
    USERINFO_PLATINUM: '铂金',
    USERINFO_DIAMOND: '钻石',
    USERINFO_CRYSTAL: '水晶',
    USERINFO_CROWN: '皇冠',
    USERINFO_STARSHINE: '星耀',
    USERINFO_KING: '王者',
    USERINFO_GAMBLERS: '赌神',
    USERINFO_MANAGE: '管理',
    // 出金 Sam
    OUTGOLD_CARD: '到帐卡片',
    OUTGOLD_OUT: '尚可出金 %{num} 次',
    OUTGOLD_CASH: '出金金額',
    OUTGOLD_WATER: '流水不足 %{num} 元',
    OUTGOLD_DEAL: '前往交易',
    OUTGOLD_BALANCE: '可提领余额为 %{num} 元',
    OUTGOLD_HANDLING_FEE: '手续费 %{num} 元',
    OUTGOLD_RULES: '出金规则',
    OUTGOLD_DETAILS: '出金详情',
    OUTGOLD_DEDUCT: '每笔出金扣除 %{num}%手续费',
    OUTGOLD_LOW_HEIGH: '单笔出金最低%{num}元，最高%{total}元',
    OUTGOLD_WATER_ENOUGH: '流水足额即可申请出金',
    OUTGOLD_I_KNOW: '我知道了',
    OUTGOLD_CONFIRM_ID: '确認身份',
    OUTGOLD_LIMIT_TIME: '已到达出金上限次数',
    OUTGOLD_CUSTOMER: '当前无法提款，请联络客服人员。',
    OUTGOLD_MESSAGE: '出金手续费为出金金额%{num}%，并于出金成功后直接于余额扣除',
    OUTGOLD_ALL: '全部提款',
    // 出金components
    OUTGOLD_RECORD: '出金纪录',
    OUTGOLD_FAIL: '出金失败',
    OUTGOLD_SHOW_ALL: '已显示%{num}全部记录',
    OUTGOLD_MESSAGE_ONE: '待出金系统回馈，请耐心等候系统完成出金作业。',
    OUTGOLD_MESSAGE_TWO: '已完成交易，系统已将此次出金入于您的银行帐户。',
    OUTGOLD_MESSAGE_THREE: '系统已取消本次出金申请。',
    OUTGOLD_MESSAGE_FOUR: '系统已拒绝本次出金申请。',
    OUTGOLD_TEXT_ONE: '1.每笔出金需扣除1%手续费',
    OUTGOLD_TEXT_TWO: '2.单笔出金最低1元，最高2000000元',
    OUTGOLD_TEXT_THREE: '系统已拒絕本次出金申请。',
    OUTGOLD_TEXT_FOUR: '系统已取消本次代付出金申请。',
    OUTGOLD_TEXT_FIVE: '系统已拒絕本次代付出金申请。',
    OUTGOLD_TEXT_SIX: '已完成交易，系统已将此次代付出金入于您的银行帐户。',
    OUTGOLD_TEXT_SEVEN: '待出金系统回馈，请耐心等候系统完成代付作业。',
    // BaseSecurity.vue
    GUARD_ACCOUNT_EMPTY: '账号不能为空',
    GUARD_CORRECT_ACCOUNT: '请填写正确的账号',
    GUARD_PASSWORD_EMPTY: '密码不能为空',
    GUARD_CORRECT_PASSWORD: '请填写正确的密码',
    GUARD_PASSWORD_ENTER: '密码6-16位，请使用字母、数字',
    GUARD_INVITE_EMPTY: '邀请码不能为空',
    GUARD_CORRECT_INVITE: '请填写正确的邀请码',
    GUARD_SING_EMPTY: '注册账号不能为空',
    GUARD_ACCOUNT_ENTER: '账号应为4-16个字符，可使用字母、数字',
    GUARD_ACCOUNT_LETTER: '账号只能包含字母',
    GUARD_ACCOUNT_MEMEBER: '账号只能包含数字',
    GUARD_VERIFY_EMPTY: '验证码不能为空',
    GUARD_PHONE_EMPTY: '手机号码不能为空',
    GUARD_PHONE_CORRECT: '请填写正确的手机号码',
    GUARD_EMAIL_EMPTY: '邮箱号码不能为空',
    GUARD_CORRECT_EMAIL: '请填写正确的邮箱号码',
    GUARD_QQ_EMPTY: 'QQ号不能为空',
    // Components file
    ELEMENT_SHARE: '分享',
    ELEMENT_SHARE_SUCCESS: '分享成功',
    ELEMENT_MUTE: '已禁言',
    ELEMENT_FOLLOW: '我要跟投',
    ELEMENT_SHARE_WAR: '分享战绩',
    ELEMENT_HIT_RATE: '总命中率',
    ELEMENT_WIN_RATE: '今日胜率',
    ELEMENT_DONT_TALK: '禁言',
    ELEMENT_RECALL: '撤回',
    ELEMENT_SHARE_MEMBER: '分享会员',
    ELEMNET_INVESTMENT_CONTENT: '投资内容',
    ELEMENT_SYSTEM_TEST: '系统测试',
    ELEMENT_STRAIGHT: '请将手机旋转为直式',
    ELEMENT_CUSTOMER_SERVICE: '联系客服',
    ELEMENT_FINANCIAL_INFORMATION: '财经资讯',
    ELEMENT_LOOK_EVERYWHERE: '没有更多，去别处看看',
    ELEMENT_SPEND_MEMEBER: '推送会员',
    ELEMENT_INPUT_UNIT: '请输入投资单位',
    ELEMENT_INVEST_UNIT: '投资单位',
    ELEMENT_DATE: '%{prv}期已封盘，当前为%{now}期',
    ELEMENT_CASH: '金额',
    ELEMENT_ENTER_BET_SLIP: '请输入单注%{msg}',
    ELEMENT_SEACH_RECORD: '投资成功，可以到我的账户查看投资记录',
    ELEMENT_CHECK_BET_SLIP: '查看注单',
    ELEMENT_GO_ON: '继续跟投',
    ELEMENT_INVEST_ERROR: '投资期号错误',
    ELEMNET_REBATE: '返点',
    ELEMENT_WAIT_DRAW: '等待开奖',
    ELEMENT_DECIMAL: '小数,双面盘',
    ELEMENT_NOT_WINNING: '未中奖',
    ELEMENT_WIINNING: '已中奖',
    ELEMENT_WAIT_PROFIT: '等待盈利',
    ELEMENT_PAY_CASH: '我已付款',
    ELEMENT_TEXT_ONE: '1.请使用QQ钱包转账到以上账户。',
    ELEMENT_TEXT_TWO: '2.若是二维码，请使用QQ钱包扫一扫进行转账，若是银行账号，请使用QQ钱包转账到银行卡方式进行转账。',
    ELEMENT_TEXT_THREE: '4.请正确填写您的QQ钱包昵称和充值金额，QQ钱包昵称并非QQ钱包账号，请注意区分。',
    ELEMENT_IS_SUBMIT: '正在提交',
    // MODULE file
    MODULE_GET_CASH: '恭喜获得',
    MODULE_CASH_DOLLAR: '元 ',
    MODULE_FINISHED: '已領完',
    MODULE_DATE: '已过期',
    MODULE_CONDITION_STANDARD: '条件未达 立即去充值',
    MODULE_ROOM_PASSWORD: '房间密码',
    MODULE_WRITE_ROOM: '请填写房间密码',
    MODULE_ROOM_MESSAGE: '房间密码为4位数字用于进入本聊天室',
    MODULE_ROOM_MESSAGE2: '若多次错误，当日该聊天室便不可再嚐试',
    MODULE_NO_MORE: '没有更多了',
    MODULE_PROXY: '%{num}級代理',
    MODULE_PLAYER: '%{num}級玩家',
    MODULE_SHARE_ROOM: '分享聊天室',
    MODULE_FIX_SHARE_CASH: '修改分享注单金额',
    MODULE_REFIX_DEAL: '请重新修改分享注单金额',
    MODULE_HOW_DATE: '%{num}期',
    // notify
    NOTIFY_BUY_SUCCESS: '买入成功',
    NOTIFY_UPDATE_DONE: '修改完成，已儲存變更',
    NOTIFY_LOOP_DISABLED: '循环已停用',
    NOTIFY_SET_SUCCESS: '设置完成，已儲存變更',
    NOTIFY_SAFEPASSWORD_DONE: '安全密码设置完成',
    NOTIFY_BANKCARD_ADD: '银行卡添加成功',
    NOTIFY_COPY_WATER: '已复制流水号',
    NOTIFY_COPY_ACCOUNT: '已复制收款账号',
    NOTIFY_COPY_NAME: '已复制收款户名',
    NOTIFY_COPY_SUCCESS: '已复制成功',
    NOTIFY_COPY_BANK: '已复制收款银行',
    NOTIFY_COPY_OPEN: '已复制开户支行',
    NOTIFY_UPDATE_SUCCESS: '修改完成，已儲存變更',
    NOTIFY_INVITE_DELETE: '邀请码已删除',
    NOTIFY_COPY_PASSWORD: '已复制生成密码',
    NOTIFY_CANCEL_ORDER: '撤单成功',
    NOTIFY_MUTE_SUCCESS: '禁言成功',
    NOTIFY_MUTE_CANCEL: '禁言取消成功',
    NOTIFY_FIXED_BUG: '修改錯誤',
    NOTIFY_ID_ERROR: '身份验证錯誤',
    NOTIFY_PASSWORD_NOT_SAME: '两次输入安全密码输入不一致',
    // ---
    VIRTUAL_WALLET_WALLET_BALANCE: '钱包余额',
    VIRTUAL_WALLET_WATER_INSUFFICIENT: '流水不足',
    VIRTUAL_WALLET_RECEIVABLE: '可领金额',
    RECHARGE_INPUT_TITLE: '入金方式',
    RECHARGE_INPUT_HINTTEXT: '单笔最低',
    RECHARGE_ACCOUNT＿INFORMATION: '帐户信息',
    RECHARGE_DEPOSITOR_NAME: '存款人姓名',
    RECHARGE_DEPOSITOR_INPUTNAME: '请填写存款人姓名',
    RECHARGE_DEPOSIT_AMOUNT: '入金金额',
    RECHARGE_VIRTUAL_WALLET_TEXT: '虚拟钱包余额为',
    RECHARGE_FULL_DEPOSIT: '全部入金',
    RECHARGE_ACCOUNT_INFORMATION: '帐户信息',
    RECHARGE_SCAN_CODE_TEXT: '扫码支付 点击保存',
    RECHARGE_DEPOSIT_DETAILS: '入金详情',
    RECHARGE_ORDER_TITLE: '订单资讯',
    RECHARGE_ORDER_MSG: '请在时间内完成交易并点击"确认付款"按纽，如果超过未完成，系统将会在结束后自动取消此次入金。',
    RECHARGE_CREATE_ORDER_BTN: '建立订单',
    RECHARGE_RULE: '入金规则',
    RECHARGE_COPYEND: '已复制',
    RECHARGE_SAVEQRCODE: '已保存二维码',
    RECHARGE_NO_SAVEQRCODE: '暂无支持二维码',
    RECHARGE_SIDE_HEADER_TITLE: '入金记录',
    RECHARGE_SIDE_ALERT_TEXT1_1: '1.请使用%{msg}转账到以上账户。',
    RECHARGE_SIDE_ALERT_TEXT2_1: '2.若是二维码，请使用%{msg}扫一扫进行转账，若是银行账号，请使用%{msg}转账到银行卡方式进行转账。',
    RECHARGE_SIDE_ALERT_TEXT3_1: '3.转账时请在留言中备注自己的游戏账号，便于及时确认充值金额。',
    RECHARGE_SIDE_ALERT_TEXT4_1: '4.请正确填写您的%{msg}昵称和充值金额，%{msg}昵称和充值金额%{msg}账号，请注意区分。',
    RECHARGE_SIDE_ALERT_TEXT5_1: '5.请务必转账后再提交订单，否则无法及时查到您的款项，转账1笔提交1次，请勿重复提交订单。',
    RECHARGE_SIDE_ALERT_BTN_TEXT: '我知道了',
    RECHARGE_SIDE_OPEN_POP_STATE0_INFO: '待入金系统回馈，请耐心等候系统完成入金作业。',
    RECHARGE_SIDE_OPEN_POP_STATE1_INFO: '已完成交易，系统已将此次入金入于您的帐户。',
    RECHARGE_SIDE_OPEN_POP_STATE2_TITLE: '入金失败',
    RECHARGE_SIDE_OPEN_POP_STATE2_INFO: '超过时间未完成交易，系统已取消此次入金。',
    RECHARGE_SIDE_OPEN_POP_STATE4_INFO: '请在时间内完成交易并点击"确认付款"按纽，如果超过未完成，系统将会在结束后自动取消此次入金。',
    RECHARGE_SIDE_OPEN_POP_ERROR_TITLE: '入金失败',
    RECHARGE_SIDE_OPEN_POP_ERROR_INFO: '请在时间内完成交易并点击"确认付款"按纽，如果超过未完成，系统将会在结束后自动取消此次入金。',
    RECHARGE_SIDE_BANKITEM_WALLET_ACCOUNT_NAME: '钱包账号',
    RECHARGE_SIDE_BANKITEM_WALLET_NICK_NAME: '钱包昵称',
    RECHARGE_SIDE_BANKITEM_UNION_ACCOUNT: '银联账号',
    RECHARGE_SIDE_BANKITEM_UNION_ACCOUNT_NAME: '银联名义人',
    RECHARGE_SIDE_BANKITEM_UNION_NAME: '银联姓名',
    RECHARGE_SIDE_BANKITEM_ACCOUNT: '收款账号',
    RECHARGE_SIDE_BANKITEM_NAME: '收款户名',
    RECHARGE_SIDE_BANKITEM_BANK_NAME: '收款银行',
    RECHARGE_SIDE_BANKITEM_ALI_ACCOUNT: '支付宝账号',
    RECHARGE_SIDE_BANKITEM_RECEIVING_ACCOUNT: '收款账号',
    RECHARGE_SIDE_BANKITEM_ALI_NAME: '支付宝姓名',
    RECHARGE_SIDE_BANKITEM_OPEN_AN_ACCOUNT: '开户支行',
    RECHARGE_SIDE_BANKITEM_OPEN_API_ALERT: '入金金额有误',
    RECHARGE_SIDE_BANKITEM_OPEN_API_ALERT1: '此入金模式单笔最低',
    RECHARGE_SIDE_BANKITEM_AISLE: '通道',
    RECHARGE_BASEBANKDATA_SELECTMONEY_INFO: '金额不能高于单笔最高金额',
    RECHARGE_BASEBANKDATA_DECIMAL_MSG: '为了更准确核对您的金额，系统已将入金金额调整为%{msg}',
    RECHARGE_BASEBANKDATA_CHANGEURL_MSG: '提現申請提交成功，請在3-5分鐘後，查詢是否到帳',
    RECHARGE_BASEBANKDATA_CHANGEURL_BTN1: '查看记录',
    RECHARGE_BASEBANKDATA_CHANGEURL_BTN2: '留在本页',
    RECHARGE_BASEBANKDATA_ERROR_PASSWORD_EMPTY: '安全密码不能为空',
    RECHARGE_BASEBANKDATA_ERROR_SAFEPASSWORD: '安全密码格式不对',
    RECHARGE_BASEBANKDATA_ERROR_EMPTY: '不能为空',
    RECHARGE_BASEBANKDATA_ERROR_TYPE: '格式不对',
    RECHARGE_BASEBANKDATA_ERROR_CHARGE_MONEY: '入金金额必须在%{min}-%{max}之間',
    RECHARGE_BASEBANKDATA_ERROR_BANK_EMPTY: '开户银行不能为空',
    RECHARGE_BASEBANKDATA_ERROR_BANKCARD_EMPTY: '银行卡号不能为空',
    RECHARGE_BASEBANKDATA_ERROR_BANKCARD_TYPE: '银行卡号格式不对',
    RECHARGE_BASEBANKDATA_ERROR_NAME_EMPTY: '开户人姓名不能为空',
    RECHARGE_BASEBANKDATA_ERROR_NAME_TYPE: '开户人姓名格式不对',
    RECHARGE_BASEBANKDATA_ADD_EMPTY: '开户地点不能为空',
    RECHARGE_BASEBANKDATA_EMPTY: '不能为空',
    RECHARGE_BASEBANKDATA_ERROR_BANKNAME_EMPTY: '银行名称不能为空',
    RECHARGE_BASEBANKDATA_ERROR_BANKNAME_TYPE: '银行名称格式不对',
    RECHARGE_BASEBANKDATA_SETINTERVALFUNC_MSG: '请勿频繁提交订单(請在%{msg}后重试)',
    SECOND: '秒',
    MINUTE: '分',
    RECHARGE_BASEBANKDATA_TEXT: '之间',
    RECHARGE_BASEBANKDATA_JDNAME: '京东户名',
    RECHARGE_BASEBANKDATA_JDMSG: '请填写您的京东户名',
    FORGOTPASSWORD_TITLE: '找回密码',
    FORGOTPASSWORD_INFO: '通過账号，找回您的密碼',
    FORGOTPASSWORD_INPUT_LABEL: '账号',
    FORGOTPASSWORD_SELECT_TYPE_SAFEPASSWORD: '通过安全密码',
    FORGOTPASSWORD_CONTACT_SERVICE: '请联系在线客服',
    FORGOTPASSWORD_NEXT_BTN: '下一步',
    FORGOTPASSWORD_QAINPUT: '请填写答案',
    FORGOTPASSWORD_ALERT_MSG: '客服',
    FORGOTPASSWORD_SHOWPROMPT_MSG: '密碼不同請重新輸入',
    FORGOTPASSWORD_SHOWPROMPT_CONFIRM_MSG: '修改成功是否立即登录',
    FORGOTPASSWORD_SHOWPROMPT_CONFIRM_OKBTN: '立即登录',
    FORGOTPASSWORD_SHOWPROMPT_CONFIRM_CANCELBTN: '返回首页',
    MEMBERCENTER_CONFIRM_PW_YES: '是',
    MEMBERCENTER_CONFIRM_PW_NO: '否',
    SET_PHONE_NUMBER_LABEL: '手机号码',
    SET_PHONE_NUMBER_PLACEHOLDER: '请填写手机号码',
    SET_PHONE_NUMBER_MAIL_LABEL: '短信验证码',
    SET_PHONE_NUMBER_MAIL__PLACEHOLDER: '请填写短信验证码',
    SET_PHONE_SENDING: '正在发送...',
    SET_PHONE_CONFIRM_MSG: '密保手机绑定暂时关闭',
    BASELOTTERY_NET_ERROR: '因无法同步服务器时间,您将无法投资,请检查网络情况',
    // PC
    PC_SAFE_VERIFY: '安全验证',
    PC_KLINE_CHART: 'K线图',
    PC_TREND_CHART: '走势图',
    PC_MARKET_CHART: '行情图',
    PC_SYSETM_BUILD: '系统建制中，暂且无法开放',
    PC_STOCK_INDEX: '股指',
    PC_ISSUE: '期号',
    PC_OUTGOING_INDEX: '出仓指数',
    PC_TREND: '走势',
    PC_INSTANT_PROFIT: '即时盈利榜',
    PC_TOTAL_COUNT: '总数',
    PC_TOTAL_MATH: '总计',
    PC_NOT_SELECTED: '未选择',
    PC_DOUBLE_DISC: '双面盘 %{num} 注 ¥ %{total}',
    PC_POSITION_DISC: '定位胆 %{num} 注 ¥ %{total}',
    PC_PLAY_EXPLAN: '玩法说明',
    PC_MODEL: '模式',
    PC_GENERAL_DEAL: '一般交易',
    PC_CHASE_DEAL: '追号交易',
    PC_STATE: '状态',
    PC_STOP_WAREHOUSE: '出仓即停',
    PC_BET_STATUS: '注单状态',
    PC_WAREHOUSE_NUMBER: '出仓号码',
    PC_START_DATE: '%{num} 起',
    PC_TRAKING_STATUS: '追号状态',
    PC_PROFTI_AMOUNT: '盈利金额',
    PC_SUMBIT_ORDER: '订单提交成功，我们将在5分钟内为',
    PC_SUMBIT_ORDER2: '您添加额度',
    PC_BET_DETAILS: '注单详情',
    PC_TRACKING_DETAILS: '追号详情',
    PC_DEAL_CENTER: '交易中心',
    PC_WALLET: '钱包',
    PC_ORDER_SUCCESS: '下单成功',
    PC_ORDER_FAIL: '下单失败',
    PC_CHANGE_IMG: '变更照片',
    PC_CEKECK_INTEGRAL: '查看当前积分',
    PC_ZOOM: '区域缩放',
    PC_BACK_ZOOM: '区域缩放还原',
    PC_OPEN_HORIZONTAL: '开启横向选择',
    PC_CLEAR_CHECKBOX: '清空所有选框',
    PC_SAVE_IMG: '保存为图片',
    PC_NO_DATA: '尚无资料',
    PC_TRADE_EVERY: '每',
    PC_TRADE_TIME: '倍',
    PC_CHASE_DATE: '追号期数',
    PC_CANCEL_ORDER: '撤單',
    PC_IN_TOTAL: '共 %{num} 則',
    PC_ANNOUNCE_NEW: '最新公告',
    PC_RULE_DOUBLE: '买入规则:大盘指数小数点后两位数为基准值，第一位为冠军号，第二位为亚军号。各对应号码当大于4（不包含4）为“涨”，小于4（包含4）为“跌”，奇数为“单”，偶数为“双”。牛熊规则大PK，当冠军大于亚军为“牛”，小于为“熊”。当期投资不能同时投资涨跌、单双、牛熊。当冠军亚军出现一样的数字时为“和”则退还本金。',
    TRADE_INVEST_SINGLE: '单注金额',
    TRADE_INVEST_COUNT: '投资注数',
    PC_RULE_LOCATION: '从冠军、亚军位置上至少选择1个号码、最多各6个号码，选号与相同位置上的出仓指数一致。',
    PC_PREV_BTN: '上一則',
    PC_NEXT_BTN: '下一則',
    // 用戶中心
    PCMEMBER_ACCOUNT: '账户',
    PCMEMBER_MEN: '男性',
    PCMEMBER_WOWAN: '女性',
    PCMEMBER_SAVE: '储存',
    PCMEMBER_BIND: '綁定',
    PCMEMBER_SET: '设置',
    PCMEMBER_NOT_BIND: '未綁定',
    PCMEMBER_SET_NOW: '已设置',
    PCMEMBER_BIND_NOW: '已绑定',
    PCMEMBER_EDIT: '编辑',
    PCMEMBER_OVERVIEW: '资金总览',
    PCMEMBER_SUMMARY: '摘要',
    PCMEMBER_NOTE_CONTENT: '备注内容',
    PCMEMBER_TOTAL_RECORD: '共 %{num} 则记录',
    PCMEMBER_COPY_WATER: '复制成功流水号',
    PCMEMBER_SEVEN_DAY: '七日',
    PCMEMBER_DEAL_ING: '交易中',
    PCMEMBER_DEAL_SUCCESS: '交易成功',
    PCMEMBER_DEAL_FAIL: '交易失败',
    PCMEMBER_SUCCESS: '成功',
    PCMEMBER_ERROR: '失败',
    PCMEMBER_GRADE: '等级费用',
    PCMEMBER_INCREASE: '每充值1元加1分',
    // 登入頁面
    PCLOGIN_PREV: '回上一步',
    PCLOGIN_FIND_PASSWORD: '密保问题可以增加账户安全性，快速找回密码',
    PCLOGIN_WITHDRAWAL: '安全密码用于出金、绑定银行卡等操作，可保障资金安全',
    PCLOGIN_PASSWORD_ERROR: '安全密码错误，请重试',
    PCLOGIN_ENTER_ANSWER: '请输入您的答案',
    PCLOGIN_NEW_PASSWORD: '新密码',
    PClOGIN_SUCCESS_FIX: '您的登录密码已修改成功，是否立即登录',
    PCLOGIN_ACCOUNT_NUMBER: '输入帐号',
    PCLOGIN_VERIFICATION: '选择验证方式',
    PCLOGIN_PASS_ID: '通过身分验证',
    PCLOGIN_SET_PASSWORD: '设置新密码',
    PCLOGIN_CONFIRM_VISITING: '请确认您正在造访',
    PCLOGIN_HAVE_ACCOUNT: '已有账号?',
    PCLOGIN_ACCOUNT: '帐号',
    PCLOGIN_SIGN_UP: '立即注册',
    PC_NOT_ACCOUNT: '还没有账号',
    PC_SUCCESS_SING: '恭喜您注册成功',
    // layout
    LAOUT_SAFE_CONFIRM: '安全认证',
    LAYOUT_APP_DOWNLOAD: '扫描下载APP',
    LAYOUT_BROWSER_SUGGEST: '建议浏览器：Safari/Google Chrome',
    LAYOUT_ACCOUNT_OVERAGE: '账户余额',
    LAYOUT_ASSETS_MANAGE: '资产管理',
    // Recharge.vue
    PC_RECHARGE_EXTENDS_TRADESELECTION_LABELTITLE: '入金通道',
    PC_RECHARGE_EXTENDS_FROMLISTBETMONEY_LABELTITLE: '入金金额',
    PC_RECHARGE_EXTENDS_FROMLISTBETNAME_LABELTITLE: '存款人姓名',
    PC_RECHARGE_EXTENDS_FROMLISTQRCODESHOW_LABELTITLE: '帐户信息',
    PC_RECHARGE_EXTENDS_FROMLISTACCOUNTNAME_LABELTITLE: '收款户名',
    PC_RECHARGE_EXTENDS_FROMLISTBANKNAME_LABELTITLE: '收款银行',
    PC_RECHARGE_EXTENDS_FROMLISTBANKCARDNUMBER_LABELTITLE: '收款账号',
    PC_RECHARGE_BTN: '提交订单',
    PC_RECHARGE_ILLUSTRATE: '注意事项',
    PC_RECHARGE_NOT_EMPTY: '此栏位不能为空',
    PC_RECHARGE_LENGTH_ERROR: '長度格式錯誤',
    PC_RECHARGE_INPUTPLACEHOLDER: '請輸入入金金额',
    PC_RECHARGE_SELECTTYPEPLACEHOLDER: '未选择',
    PC_RECHARGE_BANKPAGE_BETNAMEPLACEHOLDER: '请输入您的姓名',
    PC_RECHARGE_DROPDOWN_TEXT: '扫描支付二维码',
    PC_RECHARGE_MAX_ERROR: '最大金额错误',
    PC_RECHARGE_MIN_ERROR: '最小金额错误',
    PC_RECHARGE_NUMBER_ERROR: '请输入数字单位',
    PC_RECHARGE_SUCCESS_DEAL: '订单提交成功，请在新开的页面完成支付',
    PC_ANNOUNCEMENT_NEXT: '下一则',
    PC_ANNOUNCEMENT_PREV: '上一则',
    PC_CURRENCY_COUNT: '货币数量',
    PC_CURRENCY_RANGE: '单笔限额%{min}-%{max}数字货币',
    PC_CURRENCY_TIP: '存款汇率 {converRate} ， 入金金额 {total}',
    PC_CURRENCY_ADDRESS: '收款地址',
    PC_CURRENCY_DEAL: '交易币别',
    PC_CURRENCY_CHOOSE: '%{msg}、交易截图请择一填写',
    PC_MIN_MAX_AMOUNT: '单笔限额%{min}-%{max}',
    CURRENCY_DIGITAL: '数字货币',
    STOCK_1801: '比特币',
    STOCK_1803: '以太币(USA)',
    STOCK_1804: '以太币(CN)',
    STOCK_1811: '沪深300',
    STOCK_1812: '深证100',
    STOCK_1813: '深证成指',
    STOCK_1814: '深证成份B股',
    STOCK_1815: '深证创新',
    STOCK_1816: '深证1000',
    STOCK_1817: '深证央企',
    STOCK_1818: '深证IT信息技术',
    STOCK_1819: '深证科研',
    STOCK_1821: '上证综指',
    STOCK_1822: '上证A股',
    STOCK_1823: '上证市值百强',
    STOCK_1824: '上证100',
    STOCK_1825: '上证超大盘',
    STOCK_1826: '上证金融地产',
    STOCK_1827: '上证消费',
    STOCK_1828: '上证红利',
    STOCK_1829: '380消费',
    STOCK_1831: '商务指数',
    STOCK_1832: '深证文化',
    STOCK_1833: '贵州茅台',
    STOCK_1834: '中证传媒',
    STOCK_1835: '央视文化',
    STOCK_1836: '伊利股份',
    STOCK_1837: '良品铺子',
    STOCK_1838: '泸州老窖',
    STOCK_1839: '三只松鼠',
    STOCK_1840: '盐津铺子',
    STOCK_1851: '现货黄金(USA)',
    STOCK_1852: '现货铂金',
    STOCK_1854: '现货黄金(CN)',
    STOCK_1861: '九阳股份',
    STOCK_1862: '北斗星通',
    STOCK_1863: '小熊电器',
    STOCK_1871: '法国巴黎CAC40',
    STOCK_1872: '比利时BEL20',
    STOCK_1881: '微软',
    STOCK_1882: '苹果',
    STOCK_1883: '亚马逊',
    STOCK_1884: '那斯达克综合指数',
    STOCK_1885: '那斯达克100'
  },
  en: {
    DEPOSIT: 'Deposit',
    IMMEDIATE_LOGIN: 'Log in',
    SING_OUT: 'Log out',
    ACCOUNT_USER: 'User',
    WITHDRAWAL: 'Withdraw',
    CANCEL: 'Cancel',
    FINISH: 'Completed',
    CONFIRM: 'Confirm',
    CONFIRM2: 'Confirm',
    CONTINUE: 'Continue',
    CLOSE: 'Close',
    SUBMIT: 'Submit',
    SEARCH: 'Search',
    PROCESS_DONE: 'Success',
    WAIT_A_MINUTE: 'Please wait a moment...',
    FORGOT_PASSWORD: 'forgot password',
    COPY: 'Copy',
    PLEASE_AGAIN: 'Please try again',
    RE_ENTER: 'Re-enter',
    YES: 'Yes',
    NO: 'No',
    SHOW_ALL: 'All records are displayed ',
    NEW_MESSAGE: 'New records will be displayed here',
    NO_RECORD: 'No data',
    PASSWORD: 'Password',
    UNLOCK: 'Unlocked',
    DISABLED: 'Disabled',
    LOCKED: 'Locked',
    RESTRICTED: 'Restricted',
    REVISE: 'Revise',
    REMARK: 'Remark',
    DELETE: 'Delete',
    PASSWORD_ERROR: 'Password incorrect',
    ACCOUNT_ANNOUNCEMENT: 'Announcement',
    YOUR_TEAM_BANNED: 'Your group  has been muted',
    YOUR_TEAM_BANNED_FOREVER: 'You have been permanently muted',
    YOUR_TEAM_BANNED_ONEDAY: 'You have been muted for 1 day',
    YOUR_TEAM_BANNED_ONEWEEK: 'You have been muted for 1 week',
    YOUR_TEAM_BANNED_ONEHOUR: 'You have been muted for 1 hour',
    YOUR_TEAM_BANNED_HALFHOUR: 'You have been muted for half hour',
    CONGRATULATION: 'Congratulations',
    FLLOW_DATE: 'Chasing X periods',
    INJECTION: 'bets',
    PERSON: 'people',
    ACCOUNT_PERSONAL_INFORMATION: 'Profile',
    ACCOUNT_PROFIT_LOSS_TODAY: 'P/L today',
    ACCOUNT_AGENCY_CENTER: 'Agent Center',
    ACCOUNT_MESSAGE_CENTER: 'Message Center',
    ACCOUNT_HELP_GUIDE: 'Help Guide',
    ACCOUNT_GUIDE_MEMBER: 'Account Guide',
    ACCOUNT_INVESTMENT_GUIDE: 'Investment Guide',
    ACCOUNT_ABOUT_US: 'About Us',
    ACCOUNT_MY_ACCOUNT: 'My Account ',
    ACCOUNT_OVERAGE: 'Balance',
    ACCOUNT_MANAGEMENT: 'Security Center',
    ACCOUNT_VIRTUAL_WALLET: 'Wallet',
    ALERT_NETWORK_ERROR_TEXT: 'Network abnormal, please refresh',
    ACCOUNT_NEW_MESSAGE: 'Any new messages will be displayed here',
    ACCOUNT_PRIVATE_LETTER: 'Messages',
    TRADE_HOT: 'Hot',
    TRADE_ASIA: 'Asia',
    TRADE_EUROPE: 'Europe',
    TRADE_NORTH_AMERICA: 'North America',
    TRADE_SOUTH_AMERICA: 'South America',
    TRADE_OCEANIA: 'Oceania',
    TRADE_VIRTUALCURRENCY: 'Crypto',
    TRADE_STOP_TRADING: 'Stop Trading',
    TRADE_EXPECT: '',
    TRADE_UNIT: 'Unit',
    TRADE_NUMBERPERIODS: 'Periods',
    TRADE_DOUBLED: 'Doubled',
    TRADE_TIPS: 'Tips',
    TRADE_TOTAl: 'Total',
    TRADE_STOCK: 'Share',
    TRADE_DOLLAR: 'Dollar',
    TRADE_MAX_INCOME: 'Highest profit',
    TRADE_CONTINUE_INVEST: 'Continue',
    TRADE_SHARE_ORDER: 'Share',
    TRADE_BUY: 'Buy',
    TRADE_OPEN: 'Open',
    TRADE_SHIPPED: 'Opening',
    TRADE_OPEN_TODAY: 'Open',
    TRADE_PRICING: 'Rate',
    TRADE_PRICING2: 'Amount',
    TRADE_VOLUME: 'Volume',
    TRADE_MAX: 'High',
    TRADE_MIN: 'Low',
    TRADE_ODD: 'Odd',
    TRADE_DOUBLE: 'Even',
    TRADE_CHAMPION: 'Champion',
    TRADE_FALL: 'Odds',
    TRADE_RUNNER: 'Runner up',
    TRADE_PAR_VALUE: 'Price per share',
    TRADE_MULTIPLE: 'Multiple',
    TRADE_CHASE_NUMBER: 'Chase',
    TRADE_PROFIT_STOP: 'Stop',
    TRADE_PROFIT_CYCLE: 'Cycle',
    TRADE_DOUBLE_SIDE: 'Double-sided',
    TRADE_POSITIONING: 'Positioning',
    TRADE_SINGLE_STRAND: 'Odd',
    TRADE_DOUBLE_STRAND: 'Even',
    TRADE_BULL_MARKET: 'Bull',
    TRADE_BEAR_MARKET: 'Bear',
    TRADE_INVESTING: 'Investing',
    TRADE_INSUFFICIENT_BALANCE: 'Insufficient balance',
    TRADE_TAKE_A_BEATING: 'Investment  failed',
    TRADE_I_SEE: 'I see',
    TRADE_ACTION_SHEET_PLAYTYPE_TEXT: 'How to play',
    TRADE_UP: 'Up',
    TRADE_DOWN: 'Down',
    TRADE_DALLAR: 'Dollar',
    TRADE_DIME: 'Dime',
    TRADE_CENT: 'Cent',
    TRADE_INPUTBASE: 'Unit : Dollar',
    TRADE_UNCHANGED: 'Tie',
    TRADE_MOST: 'Maximum',
    TRADE_TIMES: 'times',
    TRADE_LIST_TAB_TITLE_BASE: 'General',
    TRADE_LIST_TAB_TITLE_CHASE: 'Chase',
    TRADE_MOST_EXPECT: 'Up to X periods',
    TRADE_CHASE_START_ISSUE: 'Chasing No.',
    TRADE_CHASE_COUNT: 'Chasing bets',
    PC_ACCOUNT_BANK_CARD: 'Bank card',
    PC_DATE_MULT: 'per %{chase} periods %{mult} times',
    TRADE_CHASE_EXPECTED_PRIZE_TIME: ' ',
    TRADE_CHASE_CONFIRM: 'Order Confirmation',
    HOME_ICONBTN_BONUS: 'P&L',
    HOME_ICONBTN_GUIDE: 'Guide',
    HOME_ICONBTN_RANK: 'Ranking',
    HOME_ICONBTN_HOT: 'Hot',
    HOME_ICONBTN_APP: 'APP',
    HOME_ICONBTN_SERVICE: 'Customer service',
    HOME_NEWS_TITLE: 'Financial News',
    HOME_NEWS_MOREBTN: 'More',
    HOME_NEWS_DEF_TITLE1: '股价遭低估！券商看好货柜船竞争力、日3大海运股齐飙',
    HOME_NEWS_DEF_SOURCE1: 'MoneyDJ新闻',
    HOME_NEWS_DEF_TITLE2: '股价遭低估！券商看好货柜船竞争力、日3大海运股齐飙',
    HOME_NEWS_DEF_SOURCE2: 'MoneyDJ新闻',
    HOME_NEWS_DEF_TITLE3: '股价遭低估！券商看好货柜船竞争力、日3大海运股齐飙',
    HOME_STOCK_TITLE: 'Popular stocks',
    HOME_GETNAME_TEXT: 'Index',
    HOME_GETUSERINFO_TEXT: 'The account information has been refreshed, the current account is',
    HOME_GETNOTICELIST_TEXT: 'Welcome to %{msg}',
    HOME_APPDOWNLOAD_HEADER_TITLE: 'Download the app',
    HOME_APPDOWNLOAD_MAINTEXT: 'Download the app and never have to input the URL again',
    HOME_APPDOWNLOAD_ANDROID_BTN: 'Android APP Download',
    HOME_APPDOWNLOAD_IOS_BTN: 'iOS APP Download',
    HOME_APPDOWNLOAD_IOSDISKTOP_BTN: 'iOS Desktop Download',
    HOME_APPDOWNLOAD_PROMPT: "Some browsers may not be able to install, it's recommended to use Safari browser to install",
    HOME_APPDOWNLOAD_PROMPT_TEXT: 'Download',
    HOME_APPDOWNLOAD_ACTION_IOSTITLE: 'iOS APP',
    HOME_APPDOWNLOAD_ACTION_ANDROIDTITLE: 'Android APP',
    HOME_APPDOWNLOAD_ACTION_VERSION: 'Versions',
    HOME_APPDOWNLOAD_TEXT: 'or',
    HOME_APPDOWNLOAD_IOS_STEP_TEXT: 'Installation succeed',
    HOME_RANK_TITLE: 'Top 10',
    HOME_RANK_TEXT1: 'Count the users who made the most profit yesterday, today',
    HOME_RANK_TEXT2: '05:00~06:00 Update',
    HOME_RANK_USER_TITLE: 'Title',
    HOME_RANK_POPUP_CUMULATIVE_PROFIT: 'Accumulated Profit',
    HOME_ANNOUNCEMENT_TEXT: 'Check',
    TRADE_STOCK_INPUT_SEARCH: 'Please enter a brand name',
    TRADE_STOCK_TRADEMAIN_RECORD_BTN: 'Record',
    TRADE_STOCK_TRADEMAIN_BUY_BTN: 'Buy',
    TRADE_STOCK_POPUP_SUCCESSFUL_TEXT1: 'You have invested successfully,',
    TRADE_STOCK_POPUP_SUCCESSFUL_TEXT2: 'you can share your order or',
    TRADE_STOCK_CHART_TODAY_K_BTN: 'Day',
    TRADE_STOCK_CHART_WEEK_K_BTN: 'Week',
    TRADE_STOCK_CHART_MOON_K_BTN: 'Month',
    TRADE_STOCK_TIMEBAR_STAES_CLOSED: 'Closed',
    TRADE_STOCK_TIMEBAR_ORDER_ERROR: 'The period number is wrong',
    TRADE_STOCK_TIMEBAR_ORDER_ERROR1: 'The period number is error',
    TRADE_STOCK_LATESTRESULT_NOTIMSG1: 'Attention',
    TRADE_STOCK_LATESTRESULT_NOTIMSG2: 'Period number %{num} have closed, the current period number ',
    TRADE_STOCK_SETKTYPE_TITLE: 'Ping An Index Historical Share Price',
    TRADE_STOCK_CONFIRMBET_ERROR: 'Investment type check error',
    TRADE_STOCK_CONFIRMBET_TYPE_ERROR_REFRESH: 'The investment type has changed, your investment was unsuccessful and the page will be refreshed for you soon',
    TRADE_STOCK_CONFIRMBET_UNIT_ERROR_REFRESH: 'The investment base unit has changed and your investment was not successful, the page will be refreshed for you soon',
    TRADE_STOCK_ACTIVE_CHAMPION_STATE: 'Sorry, champion and runner-up can only choose a maximum of 6 numbers each',
    TRADE_STOCK_NUMERIC_KEYBOARD_ALERT_TEXT: 'The maximum investment amount is',
    TRADE_STOCK_CONFIRMHANDLER_TYPE1_TEXT: 'Choose at least 1 number and up to 6 numbers each from the champion and runner-up positions. The number chosen is consistent with the index of positions taken in the same position.',
    TRADE_STOCK_CONFIRMHANDLER_TYPE1_TITLE: 'Positioning Rules',
    TRADE_STOCK_CONFIRMHANDLER_TYPE0_TEXT: "Two digits after the decimal point of the index is the base value, the first is the champion number, the second is the runner-up number.Each corresponding number when greater than 4 (doesn't contain 4) for Up, less than 4 (contains 4) for Down.The odd number is Oddand the even number is Even.Bull and bear rule : when the champion is greater than the runner-up, it's Bull. When the champion less than runner-up, it'sBear.The current investment can't invest Up / Down, Odd / Even, Bull / Bear at the same time. When the champion and runner-up appear the same number as the Tiethen the principal will be refunded.",
    TRADE_STOCK_CONFIRMHANDLER_TYPE0_TITLE: 'Double-side rule',
    TRADE_STOCK_MAX_CHASE_NUMBER: 'The maximum periods that can be tracked is',
    TRADE_STOCK_RIGHT_TEXT: 'Click the left-pointing arrow in the upper right corner to view your investment history.',
    TRADE_STOCK_NOTIFY_TEXT: '%{bet} bets %{power}times Chasing %{chase} periods',
    TRADE_STOCK_NO_LOGIN: 'Not logged in',
    TRADE_STOCK_STOP_SELL: 'Stop trading',
    TRADE_STOCK_IS_STOP: '0123456789预休出等',
    TRADE_STOCK_NOW_CLOSE_ORDER: 'Current Closed Orders',
    TRADE_STOCK_NOW_WAIT_OPEN_ORDER: 'Waiting for the opening',
    NOBIND_SAFEPASSWORD_AND_BANKCARD: "You haven't set a security code and bound your bank card.Would you like to go to set a security code first?",
    NOBIND_BANKCARD: "You haven't bound your bank card. Would you like to go to set a security code first?",
    PERSONAL_RANK_TITLE: 'Level Title',
    PERSONAL_SAFE_FINISH: 'Security certificate is completed',
    PERSONAL_SAFTEY_NOT_COMPLETED: 'Security certificate is not complete',
    PERSONAL_NICK_NAME: 'Nickname',
    PERSONAL_LOADING: 'Loading',
    PERSONAL_ACCOUNT: 'Login ID',
    PERSONAL_PHONE: 'Phone number',
    PERSONAL_QQ: 'QQ ID',
    PERSONAL_NO_DATA: 'No data',
    PERSONAL_NO_BIND: 'No connection',
    PERSONAL_NO_SETING: 'No setting',
    PERSONAL_SEX: 'Gender',
    PERSONAL_BIRTHDAY: 'Birthday',
    PERSONAL_THEME: 'Theme',
    LANGUAGE: 'Language',
    JAPANESE: 'Japanese',
    CHINESE: 'Chinese',
    VIETNAMESE: 'Vietnamese',
    PERSONAL_SET_NICK_NAME: 'Set nickname',
    PERSONAL_SET_QQ: 'Set QQ ID',
    PERSONAL_SET_PHONE: 'Set mobile number',
    PERSONAL_NOT_SET: 'Off',
    PERSONAL_MAN: 'Male',
    PERSONAL_WONAN: 'Female',
    PERSONAL_OTHER: 'Other',
    PERSONAL_DARK: 'Dark',
    PERSONAL_LIGHT: 'Light',
    PERSONAL_SET_IMG: 'Set photo',
    PERSONAL_CHOESE_IMG: 'Choose photo',
    PERSONAL_SAVE: 'Save',
    SAFE_PROCESS: 'Process',
    SAFE_IDENTITY_CONFIRM: 'Identification',
    SAFE_MESSAGE: 'According to KYC and AML policies, you must go through a verification process. This is necessary to ensure the safety of your funds and to speed up withdrawals. ',
    SAFE_LOGING_PASSWORD: 'Set the login password',
    SAFE_PASSWORD: 'Set security code',
    SAFE_SECRET_SECURITY: 'Set security question',
    SAFE_GO_SAFE_CENTER: 'Go to Security Center',
    ACTION_NEW_PASSWORD: 'Enter your new password',
    ACTION_MESSAGE_DOT: 'The security code is 6 digits, which can be used for withdrawing funds,  account security setting, adding bank cards and other operations, which can guarantee the safety of funds',
    ACTION_PASSWORD_NOT_SIMPLE: "Security code can't be set too simple. Forbidden to set: 123456, 123123, 654321, 111111 and other such simple password",
    ACTION_ENTER_OLD: 'Please input the old security code',
    ACTION_NOT_SAME: "Two times the code input doesn't match",
    ACTION_MESSAGE: 'The security code is 6 digits, which can be used to withdraw funds, set the password and add bank cards to protect the funds. ',
    ACTION_SIX_PASSWORD: 'New 6-digit security code',
    ACTION_OLD_PASSWORD: 'Old 6-16 digits security code',
    ACTION_CONFIRM_PASSWORD: 'Verify security code',
    ACTION_SURE_UPDATE: 'Give up changing your security code?',
    ACTION_NOT_SET_PASSWORD: "You haven't set security code, please do so first",
    ACTION_AGAIN_CONFIRM: 'Please input the security code again to confirm',
    ACTION_VERIFY_ID: 'Please input the security code',
    RANK_GROWTH_POINTS: 'Exp',
    RANK_PROMOTION_INSTRUCTIONS: 'Promotion Instructions',
    RANK_CAPTION_BEFORE: 'Upgrade to the next level requires',
    RANK_CAPTION_AFTER: 'points, plus 1 point for every $1 deposited',
    RANK_GRADE: 'Level',
    RANK_MECHANISM: 'Level Rules',
    LEVELUP_PRIZE: 'Level Up Bonus',
    DAILY_PRIZE: 'Daily Bonus',
    SIGNIN_PRIZE: 'Check-in Bonus',
    PRIZE_GOTTEN: 'Bonus earned',
    AWARD_GOTTEN: 'Bonus earned',
    TODAY_PRIZE: "Today's Bonus",
    IMMEDIATE_GET: 'Get it now',
    CANT_GET: 'Unable to receive',
    ALREADY_GET: 'Received',
    YESTERDAY_INVESTMENT: 'Yesterday',
    CURRENT_GRADE: 'The current level',
    ADDITIONAL_RATE: 'Proportion',
    GET_SUCCESSFULLY: 'Received successfully',
    ACTIVITY_NOTE: 'Event Description',
    DAILY_NOTE_1: 'Daily bonus is open for collection after 00:20 am daily',
    DAILY_NOTE_2: 'The bonus percentage is based on the member level and the amount of accumulated bets yesterday.',
    DAILY_NOTE_3: 'Cancellations and other void bets will not be counted',
    DAILY_NOTE_4: 'If the event bonus is not taken by 24:00 on the day , it will be automatically forfeited.',
    PROMOTION_RULES: 'Level Up Rules',
    PROMOTION_NOTE_1: 'Get rewards for every promotion',
    PROMOTION_NOTE_2: 'Exp equal the amount of your recharge',
    ACCUMULATED_POINTS: 'Exp',
    PROMOTION_PRIZE: 'Normal',
    SKIP_PRIZE: 'Skip',
    LEVELUP_PRIZE_NOTE: 'You can get promotion bonus for each promotion level',
    DAILY_PRIZE_NOTE: "The daily bonus is based on the member's betting amount yesterday. The bonus has no upper limit.",
    SIGNIN_PRIZE_NOTE: 'Check in daily to enjoy more privileges and offers.What are we waiting for?',
    NOT_AVAILABLE_YET: 'Not meet the conditions of receive',
    NOT_SIGNIN_YET: 'Check-in not start yet.',
    ACTIVITY_OVER: 'Event Ends',
    ALREADY_SIGNIN: 'Checked in',
    NOT_FINISHED_YET: 'Not yet achieved',
    IMMEDIATE_SIGNIN: 'Check-in now',
    SIGNIN_TOTAL_DAYS: 'Check-in days',
    SIGNIN_PRIZE_REMINDER: 'Check in for 30 consecutive days, with the opportunity to double your profits.',
    SIGNIN_RULES: 'Check-in Rules',
    SIGNIN_DAYS: 'Check-in days',
    SIGNIN_BONUS: 'Check-in Bonus',
    SIGNIN_INVESTMENT_NEEDED: 'Investment',
    DAY_NUMBER: 'Day X',
    DAILY_SIGN_IN: 'Daily check-in',
    BOUNS_ACTIVITY: 'View new events here',
    UN_LOGIN: 'No login',
    ONLINE_CUSTOM_SERVICE: 'Online Service',
    WEB_MAINTAINING: 'Site maintenance in progress',
    MAIN_NOTE_1: 'Every maintenance,',
    MAIN_NOTE_2: 'to provide you with better service.',
    SYSTEM_MAINTAINED: 'System maintenance in progress',
    WEB_TIME_FIXED: 'Maintenance will be completed in XX.',
    LOGIN_TO: 'Log in %{name}',
    LOGIN_NOTE: 'Welcome to %{name}, invest now and get more profit.',
    WELCOME_TO: 'Welcome to %{name}',
    WELCOME_NOTE: 'We are committed to helping our clients achieve profitability',
    MILLION_RMB: 'Million RMB',
    YEAR: 'years',
    BUSINESS_NOTE_1: 'Monthly customer profit reached 554 million RMB',
    JOIN_US: 'Join us',
    JOIN_NOTE: 'Investing with global clients',
    BUSINESS_NOTE_2: 'Million customers',
    BUSINESS_NOTE_3: 'has been estimated to 491.72 million customers',
    BUSINESS_GLOBAL_MARKET: 'Trading Global Markets',
    BUSINESS_NOTE_4: 'Invest in currencies, commodities, stocks digital currencies, etc.',
    BUSINESS_NOTE_5: 'Millions of orders',
    BUSINESS_NOTE_6: 'Estimated to 490.13 million customer orders',
    INTEGRITY_FIRST: 'Honesty',
    SAFE_AND_TRUSTABLE: 'Safe, reliable and trustworthy',
    BUSINESS_NOTE_7: 'Billion transaction',
    BUSINESS_NOTE_8: 'Estimated to 490.09 RMB customer transaction',
    REGISTER: 'Sign up',
    LOGIN: 'Log in',
    BUSINESS_NOTE_9: 'Join us in investing with global clients to achieve more profitability',
    ACCOUNT_REMINDER: 'Please set 4-16 digits  ID',
    PASSWORD_REMINDER: 'Please set 6-16 digits password',
    INVITATION: 'Invitation Code',
    INVITATION_REMINDER: 'Please input the 8-digit invitation code',
    QQ: 'QQ ID',
    QQ_REMINDER: 'Please set your QQ ID',
    PHONE_NUMBERS: 'Phone number',
    PHONE_REMINDER: 'Please set your mobile number',
    IMMEDIATE_LOGIN_NOTE: 'Login',
    REGISTER_NOTE_1: 'Please set a strong password for your account security.',
    ALIEMPTYREMINDER: 'Please slide the verification code',
    VERIFY_1: 'Please hold down the slider and drag to the far right.',
    VERIFY_2: 'Verification succeeded',
    VERIFY_3: 'Sorry, something went wrong. Please try again, or <a href="javascript:__nc.reset()">refresh</a>.',
    VERIFY_4: 'There is a connection problem, please <a href="javascript:__nc.reset()">click</a> to update.',
    REGISTER_NOTE_2: 'If you use the password generator, make sure you do not forget the automatically generated password.',
    FORBID_ACCOUNT_PASSWORD_SAME: 'Do not register the same string of characters for your login ID and password.',
    FORBID_SIMPLE_PASSWORD: 'Password can not be the same as the login ID , and can not be set too simple.Forbidden to set: 123456, 123123, 654321, 111111 and other such simple password',
    TODAY_INVESTMENT: 'Investment',
    TODAY_PROFIT: 'Profit',
    TODAY_ACTIVITY: 'Event',
    TODAY_REBATE: 'Rebate',
    TODAY_DEPOSIT: 'Deposits',
    TODAY_WITHDRAW: 'Withdrawal',
    INTERNET_PAY: 'Net banking',
    BANK_TRANSFER: 'Bank Transfer',
    ALIPAY: 'Alipay',
    WECHAT_PAY: 'WeChat Pay',
    DIGITALCURRENCY_PAY: 'Digital currency',
    QQ_WALLET: 'QQ Wallet',
    FOURTH_PAY: 'Fourth Party Payment',
    UNION_PAY: 'CUP',
    CLOUD_PAY: 'UnionPay',
    JD_PAY: 'Jingdong Wallet',
    INVEST_ORDER_CANCEL: 'Cancellation of investment',
    CHASE_STOP: 'Stop chasing',
    AGENT_REBATE: 'Agent Rebate',
    PROFIT_DISTRIBUTE: 'Profit',
    DEPOSIT_DISCOUNT: 'Deposit Offer',
    PROFIT_RE_SEND: 'Profit Supplement',
    SYSTEM_CANCEL_ORDER: 'Cancel by system',
    ENVELOPE_WITHDRAW: 'Gift Collection',
    ENVELOPE_REFUND: 'Gift Refund',
    TRANSFER_GET: 'Transfer Receiving',
    TRANSFER_REFUND: 'Transfer Refund',
    DRAW_REFUND: 'Refund for Tie',
    ACTIVITY_ENVELOPE: 'Event Gift',
    TIPS_DEDUCTION: 'Giveaway',
    ENVELOPE_DEDUCTION: 'Gift Deductions',
    TRANSFER_DEDUCTION: 'Transfer Deductions',
    THIRD_PARTY_WITHDRAW_DEDUCTION: 'Payment in lieu of payment deduction',
    ARITIFICIAL_DEPOSIT: 'Manual Deposit',
    ARITIFICIAL_DEPOSIT_2: 'Manual Deposit',
    OTHER_DISCOUNT: 'Other offers',
    ERROR_WITHDRAW: 'Withdrawal for mistake',
    ADMIN_WITHDRAW: 'Withdrawal from admin',
    PROFIT_WITHDRAW: 'withdrawal for profits',
    WITHDRAW_HANDLE_FEE: 'Handling fees',
    THIRD_PARTY_WITHDRAW_REJECT: 'Payment in lieu of rejection',
    HANDLE_FEE_REFUND: 'Handling fee refund',
    VIRTUAL_WALLET_PAY: 'Wallet Payments',
    REJECT_WITHDDRAW_TO_VIRTUAL_WALLET: 'Deny withdrawals to wallet',
    VIRTUAL_WALLET_SETTLE_REFUND: 'Wallet Settlement Refund',
    NOLOGIN: 'Haven\'t logged in yet',
    SUCCESS: 'Operation successful',
    SUCCESS_GET: 'Access successful',
    SUCCESS_QUERY: 'Search successful',
    SUCCESS_SAVE: 'Save successful',
    SUCCESS_INSERT: 'Addition successful',
    SUCCESS_UPDATE: 'Update successful',
    SUCCESS_DELETE: 'Deleted successfully',
    FAIL: 'Operation failed',
    FAIL_GET: 'Access failed',
    FAIL_QUERY: 'Search failed',
    FAIL_SAVE: 'Save failed',
    FAIL_INSERT: 'addition failed',
    FAIL_UPDATE: 'Update failed',
    FAIL_DELETE: 'Delete failed',
    FAIL_BAD_PARAMS: 'The parameters are wrong',
    FAIL_LACK_PARAMS: 'Parameter missing',
    FAIL_ILLEGAL_PARAMS: 'Parameter Illegal',
    ERROR: 'System error occurred, please wait a moment and try again',
    ERROR_NOAUTH: 'No operating privileges',
    ERROR_PW: 'Password is wrong',
    ERROR_SAFETY_PW: 'Security code is wrong ',
    ERROR_AC_OR_PW: 'Account ID or password is wrong',
    ERROR_MASSAGE: 'For your account security, please contact customer service to revise',
    ERROR_OPERATE: 'Operation Exception',
    ERROR_UNKNOWN: 'System Exception',
    TYPE_NOT_NULL: 'Type can\'t be null',
    TYPE_NOT_FOUND: 'None of this type',
    ACCOUNT_NOT_FOUND: 'The login ID does not exist.',
    USR_NOT_FOUND: 'The user does not exist',
    USR_001: 'LoginID format error',
    USR_002: 'Password format error',
    USR_003: 'You have been restricted from logging in',
    USR_004: 'Your account has been set up with login protection, please use the specified IP to login',
    USR_005: 'Password is invalid, contact online customer service to revise',
    USR_006: 'Your account has been frozen, please contact customer service ',
    USR_007: 'Your password is the same as your login ID, which is security risk, please change your password.',
    USR_008: 'Your login password is too simple  ,which is security risk, please change your password.',
    USR_009: 'Login successful',
    USR_010: 'Do not register the same string of characters for your login ID and password.',
    USR_011: 'Account ID can only contain numbers and alphabets',
    USR_012: 'Account length illegal',
    USR_013: 'Please enter your mobile number',
    USR_014: 'Mobile number format error',
    USR_015: 'Please enter your QQ  ID',
    USR_016: 'QQ ID format error',
    USR_017: 'The invitation code is wrong or has expired.',
    USR_018: 'This login ID has been registered.',
    USR_019: 'This mobile number has been registered.',
    USR_020: 'This QQ ID has been registered.',
    USR_021: 'Invitation code rebate is wrong, has expired',
    USR_022: 'Registration failed',
    USR_023: 'Registered',
    USR_024: 'Player are not allowed to create invitation codes.',
    USR_025: 'This site has reached the maximum number of invitation codes, please re-generate tomorrow',
    USR_026: 'Only a maximum of 50 invitation codes are allowed to be generated per day',
    USR_027: 'Charge rebate error',
    USR_028: 'Rebate format error',
    USR_029: 'The rebate setting is a required field',
    USR_030: 'The rebate set for the invitation code must not exceed your own rebate.',
    USR_031: 'The value of the rebate cannot be a negative number.',
    USR_032: '%lotteryName rebate information is missing',
    USR_033: 'Rebates are set to one decimal place only.',
    USR_034: 'An Invitation Code has been created. You can check it by clicking on Manage Invitation Codes.',
    USR_035: 'Creation failed',
    USR_036: 'Can\'t find user information',
    USR_037: 'The number of security code entry errors exceeded the daily limit of 6 times',
    USR_038: 'Your security code is too simple,you can\'t retrieve the login password through the security password, please use the Security question or contact customer service to retrieve',
    USR_039: 'The security code is correct',
    USR_040: 'Security code format error',
    USR_041: 'The security code has been set',
    USR_042: 'Configuration successful',
    USR_043: 'Configuration failed',
    USR_044: 'Exit successfully',
    USR_045: 'Not yet bound to the secret mail address',
    USR_046: 'Email address format error',
    USR_047: 'We have sent the verification code to your email, if you do not receive it, please check the junk email',
    USR_048: 'The security phone number isn\'t bound yet',
    USR_049: 'Function temporarily closed',
    USR_050: 'Mobile number format error',
    USR_051: 'Send successful',
    USR_052: 'Can\'t choose duplicate security question',
    USR_053: 'The number of incorrect answers exceeds 10 times, the daily limit has been exceeded.',
    USR_054: 'The answer is incorrect',
    USR_055: 'The answer is correct',
    USR_056: 'Authentication failed',
    USR_057: 'The number of validation cell phone error exceeds 10 times, has exceeded the limit of the day',
    USR_058: 'Please verify your security code first',
    USR_059: 'The code validation is wrong',
    USR_060: 'Validate mobile error',
    USR_061: 'Authentication successful',
    USR_062: 'The number of validation email errors exceeds 10 times, which exceeds the daily limit',
    USR_063: 'Email address verification error',
    USR_064: 'Your answer could\'t contain any spaces.',
    USR_065: 'Please verify the Security question first',
    USR_066: 'The current ID does not exist',
    USR_067: 'The current user does not have permission to view',
    USR_068: 'User Id or Card Id is null',
    USR_069: 'This bank is not available ',
    USR_070: 'Security code verification is not valid, for your account security, please re-verify the security code',
    USR_071: 'Please authorize the first card first',
    USR_072: 'The bank account name is wrong, please select it again.',
    USR_073: 'Please revise the information of the first bank card',
    USR_074: 'Maximum of 5 bank cards can be bound',
    USR_075: 'ID is a required field',
    USR_076: 'Tenant Code is a required field',
    USR_077: 'Bank name is a required field',
    USR_078: 'Province is a required field',
    USR_079: 'City is a required field',
    USR_080: 'Card number formatting error',
    USR_081: 'Name format error',
    USR_082: 'Source ID is a required field',
    USR_083: 'Users is a required field',
    USR_084: 'This bank account cannot be bound',
    USR_085: 'This bank card has been used, please change your card',
    USR_086: 'Bind successfully',
    USR_087: 'Bank card modification is prohibited',
    USR_088: 'The card is locked and could not be modified',
    USR_089: 'The bank card is currently withdrawing cash and cannot be modified at this time',
    USR_090: 'The bank account name is wrong, please choose again',
    USR_091: 'Authentication error has been reached 3 times. Please try to authenticate again tomorrow.',
    USR_092: 'The verification information inputed is correct',
    USR_093: 'The verification information inputed is wrong',
    USR_094: 'Entered too many times and exceeded the maximum daily limit',
    USR_095: 'User‘s information error',
    USR_096: 'Password can not be the same as the login ID , and can not be set too simple.Forbidden to set: 123456, 123123, 654321, 111111 and other such simple password',
    USR_097: 'Not yet validated',
    USR_098: 'The original password is correct',
    USR_099: 'Please verify your bank card first',
    USR_100: 'Submitted successfully, please pay attention to the results of the   message',
    USR_101: 'Image upload failed',
    USR_102: 'The original password is wrong',
    USR_103: 'If you need to revise, please contact us',
    USR_104: 'Profile photo error',
    USR_105: 'Profile Service Error',
    USR_106: 'File Service Exception Information is empty',
    USR_107: 'Nickname already exists, can not be modified',
    USR_108: 'Cell phone number already exists, can not be modified',
    USR_109: 'QQ already exists, can not be modified',
    USR_110: 'Not locked yet',
    USR_111: 'Bank card number is null',
    USR_112: 'Name is null',
    USR_113: 'Verification is correct',
    USR_114: 'Operation failed',
    USR_115: 'Can\'t search your own data',
    USR_116: 'The account has been logged in other device , if it\'s not you, please change the login password immediately',
    USR_117: 'Nickname is not set',
    LTRY_001: 'LotteryCode parameter error',
    LTRY_002: 'DataNum parameter error',
    LTRY_003: 'code error',
    LTRY_004: 'Only up to 10 stock types can be checked',
    BET_001: 'You are not connected to the Internet, please reload later',
    BET_002: 'Parameter error!  Betting data is empty',
    BET_003: 'Unknown lottery',
    BET_004: 'Please refresh or check the page for the correct chasing number',
    BET_005: 'Cannot place repeat bets of less than 2 seconds',
    BET_006: 'The stock has been suspended sale.',
    BET_007: 'The lottery has been suspended sale.',
    BET_008: 'Orders for this period have been closed.',
    BET_009: 'The lottery has not been released yet.',
    BET_010: 'Failed to get stock settings',
    BET_011: 'The stock has suffered a meltdown and trading is temporarily suspended',
    BET_012: 'The stock occurs limit up/ limit down and trading is temporarily suspended',
    BET_013: 'Investment champion and runner-up can only choose a maximum of 6 number each',
    BET_014: 'Investment cannot invest Up/Down, Odd/Even, Bull/Bear at the same period',
    BET_015: 'The rebate is wrong, please refresh the page',
    BET_016: 'Balance is insufficient',
    BET_017: 'Balance deduction error',
    BET_018: 'Balance service return empty',
    BET_019: 'Betting was successful',
    BET_020: 'Currently unable to use chasing',
    BET_021: 'The lottery can‘t be pursued',
    BET_022: 'Can choose up to 6 numbers for the winning and runner-up investments each period',
    BET_023: 'Order number parameter is wrong',
    BET_024: 'Status parameter error',
    BET_025: 'Date Parameter Error',
    BET_026: 'Cancellation illegal',
    BET_027: 'The plan status is not waiting status',
    BET_028: 'Cancellation is not allowed',
    BET_029: 'Cancellation successful',
    BET_030: 'Failed to get settings',
    BET_031: 'Failed to get period number information',
    BET_032: 'Lottery code error',
    BET_033: 'Index code error',
    BET_034: 'Cancel automatic failure',
    BET_035: 'The order number does not exist',
    BET_036: 'Already closed',
    MNG_001: 'Key or APP unique code is incorrect',
    MNG_002: 'The key is wrong or expired',
    MNG_003: 'Type must be larger than 0',
    TRSC_001: 'You still have an order in process, please wait until later',
    TRSC_002: 'Sorry, please do not duplicate submissions within 5 seconds',
    TRSC_003: 'Please do not submit orders frequently',
    TRSC_004: 'Order creation successful',
    TRSC_005: 'The order is submitted successfully and we will add the credit for you within 5 minutes',
    TRSC_006: 'Withdrawal application is submitted successfully, please check after 3-5 minutes',
    TRSC_007: 'Withdrawal failed',
    TRSC_008: 'Submitted successfully',
    TRSC_009: 'Submission failed, order cancelled',
    TRSC_010: 'Corresponding prompt does not exist',
    TRSC_011: 'The date type parameter is wrong, please check and resubmit',
    TRSC_012: 'There is an error in the parameter corresponding to this EnumPaymentRecordState, please check and resubmit.',
    TRSC_013: 'Third-party base configuration data does not exist',
    TRSC_014: 'Third party payment setting details do not exist',
    TRSC_015: 'Third-party interface mode configuration data does not exist',
    TRSC_016: 'The amount is wrong',
    TRSC_017: 'The type parameter corresponding to this EnumMerchantsPaymentType class is wrong, please check and resubmit',
    TRSC_018: 'Nickname can not be empty',
    TRSC_019: 'Get succeeded but did not get the player\'s history',
    TRSC_020: 'Wallet debit failure',
    TRSC_021: 'Balance Service: Failed to update the balance',
    TRSC_022: 'Balance Service: Failed to display balance remarks',
    TRSC_023: 'Wallet return failed',
    TRSC_024: 'The site\'s cash withdrawal limit data is empty or the number of data bars is incorrect',
    TRSC_025: 'Cash withdrawal is greater than the balance',
    TRSC_026: 'Wallets are currently unavailable',
    TRSC_027: 'The webmaster has no cash withdrawal limit data',
    TRSC_028: 'The number of withdrawals has exceeded',
    TRSC_029: 'System error: User ID',
    TRSC_030: 'This bank card has been disabled, please contact customer service for details',
    TRSC_031: 'The amount exceeds the amount  can withdraw',
    TRSC_032: 'Time can’t be null',
    TRSC_033: 'The start time cannot be greater than the end time',
    TRSC_034: 'There is problem with the format of the time to be received',
    TRSC_035: 'The end date cannot be greater than the start date',
    TRSC_036: 'The range of the charge amount is wrong',
    TRSC_037: 'The range of the charge amount parameter is wrong',
    TRSC_038: 'The range of the charge amount, the maximum amount equals or exceeds the minimum amount.',
    TRSC_039: 'There is  problem with the format of the recharge method',
    TRSC_040: 'There is  problem with the level format',
    TRSC_041: 'The level type parameter is wrong, please check and resubmit',
    TRSC_042: 'Status cannot be null',
    TRSC_043: 'There is problem with the type format',
    TRSC_044: 'Order does not exist',
    TRSC_045: 'Cannot submit data repeatedly',
    TRSC_046: 'Amount over balance',
    TRSC_047: 'You have insufficient cash available for withdrawal',
    TRSC_048: 'User services-check user superior and subordinate failure',
    TRSC_049: 'User Service-Failure to get single user information, system exception reason: %msg',
    TRSC_050: 'Parameter conversion error: %msg',
    TRSC_051: 'Error getting user history information',
    TRSC_052: 'Balance Service-getting user balance failed, system failure reason: %msg',
    TRSC_053: 'User service-locking bank card failed, system failure reason: %msg',
    TRSC_054: 'Balance Service-Refused to withdraw cash processing, system failure reason: %msg',
    TRSC_055: 'Balance Service-Balance rollback processing failed, system failure reason: %msg',
    TRSC_056: 'User service-change growth value processing failed, system failure reason: %msg',
    TRSC_057: 'Event service-Charge offer processing failed, system failure reason: %msg',
    TRSC_058: 'Transfer Service-Batch update amount configuration information failed, system exception reason: %msg',
    TRSC_059: 'Transfer Service-Update the configuration information of the number of withdrawals for the transfer service failed, system exception reason: %msg',
    TRSC_060: 'Balance Service-check balance failed, reason: %msg',
    TRSC_061: 'User service-get user associated bank card failed, system exception reason: %msg',
    TRSC_062: 'Insufficient balance, need to be at least %minBalance',
    TRSC_063: '%nameType amount must be between %minAmount and %maxAmount',
    TRSC_064: 'Flow not reached! Still need %journal ',
    TRSC_065: 'Wallet action failed msg: %msg',
    TRSC_066: 'Sorry, the system only supports querying data within the last $betweens days',
    TRSC_083: 'Exchange rate has changed',
    RPT_001: 'Type range is wrong',
    RPT_002: 'Unknown request data type',
    CHAT_001: 'Guest code not entered',
    CHAT_002: 'Guest code does not exist',
    CHAT_003: 'Guest code error',
    CHAT_004: 'User ID is wrong',
    CHAT_005: 'Chat number is wrong',
    CHAT_006: 'Page size is wrong ',
    CHAT_007: 'Your current group is not allowed to enter the Chat',
    CHAT_008: 'Your current level does not allow you to enter the Chat',
    CHAT_009: 'All mute, in addition to the administrator is not allowed to chat',
    CHAT_010: 'Your current group is not allowed to chat',
    CHAT_011: 'Your current level is not allowed to chat',
    CHAT_012: 'You have been permanently mute',
    CHAT_013: 'You have been mute for 1 week',
    CHAT_014: 'You have been mute for 1 day',
    CHAT_015: 'Your IP has been permanently mute',
    CHAT_016: 'Your ip has been mute for 1 week',
    CHAT_017: 'Your ip has been mute for 1 day',
    CHAT_018: 'Mute state does not allow to send pictures, emoticons, Gifts',
    CHAT_019: 'Can\'t send null',
    CHAT_020: 'Statements may not contain special characters',
    CHAT_021: 'Statements may not contain numbers',
    CHAT_022: 'Statements may not contain the alphabet',
    CHAT_023: 'Statements may not contain Chinese',
    CHAT_024: 'The Chat has been deleted',
    CHAT_025: 'The image does not exist',
    CHAT_026: 'Image address cannot be empty',
    CHAT_027: 'The image source is wrong',
    CHAT_028: 'Guest may not share Order',
    CHAT_029: 'Guest may not share achievement',
    CHAT_030: 'Guest are not allowed to send Gift',
    CHAT_031: 'Gift sending failure',
    CHAT_032: 'Massage type ID is wrong',
    CHAT_033: 'Massage is wrong',
    CHAT_034: 'Collection of up to 31 images',
    CHAT_035: 'Image has been added to the collection',
    CHAT_036: 'Failed to cancel favorite',
    CHAT_037: 'The number of wrong answers for the Chat password exceeds the limit for today',
    CHAT_038: 'Gifts cannot be cancelled',
    CHAT_039: 'Order sharing can\'t cancel',
    CHAT_040: 'Achievement sharing can\'t cancel',
    CHAT_041: 'Only administrators and users will be able to cancel their own messages.',
    CHAT_042: 'There is no corresponding message record',
    CHAT_043: 'Webmaster code, administrator account, Chat number, mute account can not be empty',
    CHAT_044: 'No mute of administrators allowed',
    CHAT_045: 'Mute time is error',
    CHAT_046: 'The current guest isn\'t in the Chat',
    CHAT_047: 'User has been mute',
    CHAT_048: 'Only administrator can use the mute feature.',
    CHAT_049: 'The current user is not mute and cannot be modified',
    CHAT_050: 'Only allow administrators to remove mute',
    CHAT_051: 'Only allow administrators to mute all',
    CHAT_052: 'Chat number can\'t be null',
    CHAT_053: 'Please enter paging information',
    CHAT_054: 'User ID can\'t be null',
    CHAT_055: 'The game number does not exist',
    CHAT_056: 'There is no corresponding game, please contact the administrator',
    CHAT_057: 'Guest cannot receive Gift',
    CHAT_058: 'The Chat does not exist',
    CHAT_059: 'Currently you have been mute, can not receive Gifts',
    CHAT_060: 'The current group cannot receive Gifts',
    CHAT_061: 'Get user\'s Daily Report Error',
    CHAT_062: 'Get user\'s History Report Error',
    CHAT_063: 'Failed to Add to Favorites',
    CHAT_064: 'The number of characters in the message cannot exceed %length',
    CHAT_065: 'The message sending time interval cannot be less than %interval seconds.',
    CHAT_066: 'Can only receive the Gift if you charge %dayRecharge today',
    CHAT_067: 'The Gift can only be received if the accumulated recharge amount reaches %historyRecharge',
    TRSF_001: 'Internal service maintenance in progress',
    TRSF_002: 'This Gift does not exist',
    TRSF_003: 'Personal Gift function is under maintenance',
    TRSF_004: 'Gift parameter error',
    TRSF_005: 'Test accounts are not allowed to send gift',
    TRSF_006: 'Site setting acquisition failure',
    TRSF_007: 'The maximum amount set for transaction has been exceeded',
    TRSF_008: 'The sending amount limit has been reached',
    TRSF_009: 'Gift have reached the sending limit',
    TRSF_010: 'The total amount of Gift exceeds the amount limit',
    TRSF_011: 'User data acquisition failure',
    TRSF_012: 'User credit deduction failure',
    TRSF_013: 'Gift sending failure',
    FILES_001: 'File is empty',
    FILES_002: 'File size must not exceed 5MB',
    FILES_003: 'Image ID is wrong',
    FILES_004: 'The number of files is wrong',
    FILES_005: 'Only upload image files',
    FILES_006: 'Image is missing or quantity is too large',
    FILES_007: 'Please make sure the uploaded file is picture',
    ACT_001: 'Please switch site first',
    ACT_002: 'In the calculation...',
    ACT_003: 'Sorry, your event qualification has been banned',
    ACT_004: 'The event is canceled',
    ACT_005: 'Receive Success',
    ACT_006: 'Received the bonus',
    ACT_007: 'Gift do not exist',
    ACT_008: 'The Gift has been cancelled',
    ACT_009: 'The gifts have all been taken',
    ACT_010: 'The Gift is expired',
    ACT_011: 'This gift has been received',
    ACT_012: 'User Interface Errors',
    ACT_013: 'The conditions for receipt have not yet been met',
    ACT_014: 'You are not authorized to receive',
    ACT_015: 'Please do not receive frequently, in the process of receiving ...!',
    ACT_016: 'Gift receipt error',
    ACT_017: 'The event has not  begun yet',
    ACT_018: 'The event is over',
    ACT_019: 'The event has been changed, please refresh',
    ACT_020: 'Completed today\'s Check in',
    ACT_021: 'The amount requirement has not yet been reached.',
    VIRWLT_001: 'Wallet Account Error',
    STK_001: 'Index does not exist',
    TRSF_014: 'The total amount of Gift has exceeded the limit.',
    BCT_001: 'Already logged in at another device',
    USR_118: 'Nickname can contain up to 5 Japanese and Chinese characters, or less than 10 characters in other language',
    BET_039: 'Mode verification error',
    BET_037: 'Mode verification error E01',
    BET_040: 'Mode verification error, duplicate chase number',
    BET_041: 'The investment failed',
    BET_042: 'Investment type verification error A',
    BET_043: 'Investment type verification error B',
    BET_044: 'Amount verification error C',
    BET_045: 'Amount is wrong',
    BET_046: 'Amount verification error D',
    BET_047: 'Chasing times error',
    BET_048: 'Chasing amount error',
    USR_119: 'The security code and the login ID should not be the same string of characters, and should not be too simple; do not set simple passwords such as 123456, 123123, 654321, 111111, etc.',
    USR_135: 'The wallet address has been used, please change the wallet address',
    CHAT_068: 'Your current level will not receive this gift',
    FRT_001: 'You have too many IP calls, please try again later',
    FRT_002: 'Authentication error, please contact your administrator',
    FRT_003: 'Request header error, please reload the page',
    FRT_004: 'Call timeout, please contact the administrator',
    FRT_005: 'Service Exception',
    FRT_006: 'Please reload the page',
    MNG_004: 'Area Restrictions',
    MNG_005: 'Site maintenance in progress',
    MNG_006: 'Domain is not bound',
    MNG_007: 'The site is closed',
    MNG_008: 'Prohibit cross site operation',
    USR_120: 'The number of errors exceeded 10 and exceeded the limit for the day',
    USR_121: 'Haven\'t yet authenticated your password- protected email address.',
    USR_122: 'Haven\'t authenticated your password- protected mobile number yet.',
    USR_123: 'Card ID is null',
    USR_124: 'Users is null',
    USR_125: 'Bank card is disabled, no modification is allowed',
    USR_128: 'Registration ID must be a combination of alphabet and numbers',
    USR_126: 'Account ID can only contain number',
    USR_127: 'Account ID can only contain alphabet',
    USR_129: 'Name format error',
    USR_130: 'Please enter your name',
    USR_131: 'Please bind the card that you have applied for with your real name.',
    USR_132: 'Can\'t find the bank card',
    USR_133: 'Please slide the verification code',
    USR_134: 'Your verification information is incorrect',
    AXIOS_STATE_OVER_TIME_ERROR: 'Response timeout error',
    AXIOS_STATE_NET_ERROR: 'Network abnormality, please try again',
    AXIOS_STATE_LONG_TIME: 'Automatically logged out for Idle for a long time,please log in again',
    AXIOS_STATE_LOCALNET_ERROR: 'The current network is not available, please check the network and try again',
    NICKNAME_LIMIT: 'Nickname can contain up to 5 Japanese and Chinese characters, or less than 10 characters in other languages',
    INVEST_RECORDS: 'Investment',
    ACCOUNT_RECORDS: 'Financial',
    WITHDRAW_DEDUCTION: 'Withdrawal deduction',
    DEDUCTION_DETAIL: 'Deduction detail',
    TRANSFER_TO_VIRTUAL_WALLET: 'Transfer to Wallet',
    DISTRIBUTE_PROFIT: 'Profit',
    INVEST_PLAN: 'Plan',
    CANCEL_ORDER: 'Cancel',
    NORMAL: 'Normal',
    CHASE: 'Chase',
    UNSTART: 'Standby',
    PROCESSING: 'In progress',
    FINISHED: 'Finished',
    CANCEL_ORDER_REMINDER: 'If you cancel the order, you will not be able to get it back, confirm cancellation?',
    ISSUE_NUMBER: '%{issue}',
    WITHDRAW_NOTE: 'Please check if the cash withdrawal application is submitted successfully after 3-5 minutes.',
    WITHDRAE_REFUND: 'Withdrawal Return',
    WITHDRAW_REJECT: 'Withdrawal was denied',
    THIRD_WITHDRAW_REJECT: 'Payment in lieu of payment was denied',
    IN_PROCESS: 'Processing',
    DEPOSIT_FAIL: 'Deposit failed',
    WAREHOUSE_CHASE_NUMBER_ITEM_FULL: 'Unprofitable',
    WAREHOUSE_CHASE_NUMBER_ITEM_PENDING: 'In progress',
    WAREHOUSE_CHASE_NUMBER_ITEM_WAIT: 'Waiting ',
    WAREHOUSE_ACCOUNT_WITHDRAW_ITEM_TITLE: 'Withdrawal debit',
    WAREHOUSE_ACCOUNT_ACTION_SHEET_SERIAL_NUMBER: 'Order no.',
    WAREHOUSE_ACCOUNT_WITHDRAW_ACTION_TIME_TITLE: 'Transaction time',
    WAREHOUSE_ACCOUNT_WITHDRAW_ACTION_MONEY: 'Trade amount',
    WAREHOUSE_ACCOUNT_WITHDRAW_ACTION_BALANCE: 'Balance',
    WAREHOUSE_ACCOUNT_WITHDRAW_ACTION_REMARK: 'Remarks',
    WAREHOUSE_ACCOUNT_WITHDRAW_CANCEL_BTN_TEXT: 'Back',
    WAREHOUSE_RECORDCENTER_CHASENUMBER_UNPROFITABLE: 'Unprofitable',
    WAREHOUSE_RECORDCENTER_CHASENUMBER_PENDING: 'Waiting ',
    WAREHOUSE_RECORDCENTER_CHASENUMBER_ING: 'In progress',
    WAREHOUSE_RECORDCENTER_CHASENUMBER_CHASE_NUMBER_TITLE: 'Chasing No.',
    WAREHOUSE_RECORDCENTER_CHASENUMBER_ORDER_TEXT1: '',
    WAREHOUSE_RECORDCENTER_CHASENUMBER_ORDER_TEXT2: 'From %{num}',
    WAREHOUSE_RECORDCENTER_CHASENUMBER_CHASE_TIME_TITLE: 'Chase time',
    WAREHOUSE_RECORDCENTER_CHASENUMBER_CHASE_ORDER_TITLE: 'Chase No.',
    WAREHOUSE_RECORDCENTER_CHASENUMBER_CHASE_MONEY_TITLE: 'Chase amount',
    WAREHOUSE_RECORDCENTER_CHASENUMBER_BET_TITLE: 'Amount',
    WAREHOUSE_RECORDCENTER_CHASENUMBER_BONUS_TITLE: 'Profit',
    WAREHOUSE_RECORDCENTER_CHASENUMBER_AUTO_BUY_AFTER_WIN_TITLE: 'Cycle',
    WAREHOUSE_RECORDCENTER_CHASENUMBER_PLAY_TYPE: 'Plan',
    WAREHOUSE_RECORDCENTER_CHASENUMBER_CHASE_SCHEDULE: 'Progress',
    WAREHOUSE_RECORDCENTER_CHASENUMBER_CHASE_END: 'Have been chasing %{num} periods',
    WAREHOUSE_RECORDCENTER_CHASENUMBER_CHASE_END_TEXT: 'Total %{num}',
    WAREHOUSE_RECORDCENTER_CHASENUMBER_CHANGE_PLAY_TYPE_MSG: 'It can\'t be restored after modification.Set to "Stop" and deactivate the "Cycle"',
    WAREHOUSE_RECORDCENTER_CHASENUMBER_C_ISSUE_TITLE: 'Period No.',
    WAREHOUSE_RECORDCENTER_CHASENUMBER_C_TIME_TITLE: 'Invest time',
    WAREHOUSE_RECORDCENTER_CHASENUMBER_C_ORDER_TITLE: 'Invest No.',
    WAREHOUSE_RECORDCENTER_CHASENUMBER_C_OPEN_NUMBER_TITLE: 'Numbers',
    WAREHOUSE_RECORDCENTER_CHASENUMBER_C_BET_TITLE: 'Amount',
    WAREHOUSE_RECORDCENTER_CHASENUMBER_C_BONUS_TITLE: 'Profit',
    WAREHOUSE_RECORDCENTER_CHASENUMBER_C_PLAYTYPE_TITLE: 'Plan',
    PROFIT_LOSS_TODAY: 'P&L Today',
    PROFIT_AMOUNT: 'Profit amount',
    PROFIT_TODAY: 'Today',
    PROFIT_MOUNTH: 'Month',
    PROFIT_DAY: 'Day',
    PROFIT_INVEST_AMOUNT: 'Investment',
    PROFIT_GAIN: 'Profit',
    PROFIT_AGENT_REBATE: 'Rebate',
    PROFIT_ACTIVITY: 'Event',
    PROFIT_FORMULA_MEESSAGE: 'P&L calculation formula: Profit-Investment+Event+Rebate',
    PROFIT_CREDIT: 'Credit',
    PROFIT_DEPOSIT: 'Deposit',
    PROFIT_GET_RED_ENVELOPE: 'Received gifts',
    PROFIT_PAY_OUT: 'Expense',
    PROFIT_WITHDRAWAL: 'Withdrawal',
    PROFIT_SEND_RED_ENVELOPE: 'Gave gifts',
    PROFIT_FORMULA: 'P&L calculation formula',
    PROFIT_LOSS_FORMULA: 'P&L=Profit-Investment+Event+Rebate',
    PROFIT_I_KNOW: 'I see',
    ACTING_BALANCE: 'Team balance',
    ACTING_AGENCY_REPORT: "Agent's report",
    ACTING_TEAM_PROFIT: 'Team profit ',
    ACTING_LOOK_ALL: 'View all',
    ACTING_PROFIT_REBATE: 'Rebate',
    ACTING_SUB_REPORT: 'Subordinate reports',
    ACTING_MEMBER_MANAGEMENT: 'Members',
    ACTING_SUB_ACCOUNT: 'Create subordinate account',
    ACTING_INVESTMENT_DETAILS: 'Investment details',
    ACTING_TRANSACTION_DETAILS: 'Transaction details',
    ACTING_UPDATE: 'Update',
    ACTING_CARD01_TITLE: 'Investment and Development',
    ACTING_INSTRUCTION: 'Agent Instructions',
    ACTING_CARD01_TEXT: 'When you can read this page, it means that your account is a player account as well as an agent account. You can invest or develop subordinate members to earn rebate. ',
    ACTING_CARD02_TITLE: 'How can I earn rebates?',
    ACTING_INVESTMENT_REBATE: 'Invest rebate',
    ACTING_SELF: 'Myself',
    ACTING_SUB: 'Subordinate',
    ACTING_MATH: '数',
    ACTING_INVEST: 'Invest',
    ACTING_SUB_INVEST: "Subordinate's investment",
    ACTING_REBATE: 'Rebate',
    ACTING_DEPOSIT_REBATE: 'Deposit rebate',
    ACTING_SET_REBATE: 'Set  rebate',
    ACTING_CASH: 'If D puts in 1,000  in cash',
    ACTING_TOTAL_REWARD: 'Total Reward',
    ACTING_CLICK_ACCOUNT: 'Create subordinate account',
    ACTING_SEARCH_REBATE: 'You can view your own rebate points and set rebates for your subordinates. ',
    ACTING_HOW_SUB_ACCOUNT: 'How can I create account for a subordinate?',
    ACTING_PARAGRAPH_FIR: 'Create subordinate account, first set the rebate for your subordinate, after the successful setting will generate an invitation code. ',
    ACTING_PARAGRAPH_SEC: "Send the invitation code to your subordinate to register, after registration, the user is your subordinate . Click on member management, you can view the subordinate's account. ",
    ACTING_PARAGRAPH_THR: 'If you set up agent account for your subordinates, then your subordinates will be able to continue to grow subordinates.',
    ACTING_PARAGRAPH_FOUR: "If you set up player account, then your subordinates can only invest and can't develop further subordinates, and can't view the agent center. ",
    ACTING_TIPS: 'Tips',
    ACTING_TEXT_FIR: 'Different rebates have different odds, click on the Rebate Odds Table to see the rebate and odds. ',
    ACTING_TEXT_SEC: "The lower the rebate, the lower the odds. It's recommended not to set too low rebates for subordinates. ",
    ACTING_TEXT_THR: "You can view the agent's development in the Agent report, Investment details, and Transaction details. ",
    ACTING_TEXT_FOUR: "It's recommended to create a subordinate that is also an agent account, and you will receive rebates regardless of the number of levels developed. ",
    ACTING_SET_RANGE: 'Rebate Point Setting Range',
    ACTING_WIN_LOTTERY: 'When a subordinate wins a prize, you will receive a commission on the profit.',
    ACTING_WIN_LOTTERY_TWO: 'When a subordinate get profit, you will receive profit commission.',
    ACTING_COMMISSION_FORMULA_TWO: 'Profit commission formula : ( Profit amount - Bet amount ) * Profit rebate',
    ACTING_COMMISSION_FORMULA: '营利佣金公式 : ( 营利金额 - 投注金额 ) * 营利反点',
    REPORT_TOTAL_PERSON: 'Number of investors',
    REPORT_YESTERDAY: 'Yesterday',
    REPORT_THIS_MONTH: 'This Month',
    REPORT_LAST_MONTH: 'Last Month',
    REPORT_TEAM_DEAL: 'Team Trading',
    REPORT_TEAM_CREDIT: 'Team Deposit',
    REPORT_TEAM_BILLING: 'Team Expense',
    REPORT_TEAM_MANAGE: 'Team Management',
    REPORT_SING_PERSON: 'Registrants',
    REPORT_PERSON: 'Registrants',
    REPORT_COUNT_SUB_PERSON: 'Subordinate members',
    REPORT_CHECK_SING_PERSON: 'View the number of registrants',
    REPORT_INVESTORS_NUMBER: 'Investors',
    REPORT_TOTAL_TIMES: 'Times',
    REPORT_TOTAL_AMOUNT: 'Total amount',
    REPORT_TIME: 'Time',
    REPORT_COUNT_TOTAL_FIRST: 'Total First Charge',
    REPORT_AMOUNT_RECEIVED: 'Receipts',
    REPORT_TO_MONTH: 'This month',
    REPORT_PREV_MONTH: 'Last month',
    REPORT_FIRST_CHARGE: 'First Charge',
    REPORT_TRANSFER_AMOUNT: 'Transfer amount',
    REPORT_NUMBER_SUBORDINATES: 'Number of subordinates',
    REPORT_COUNT_PERSON: 'Total',
    REPORT_OWNED_SUPERIOR: 'Superior',
    REPORT_INVITER_ACCOUNT: 'Inviter ID',
    REPORT_SEARCH_FROM: 'Search for Subordinate report',
    REPORT_SEARCH_ACCOUNT: 'Input the subordinate account ID',
    REPORT_NOT_FIND: 'No information available, please change other keywords to search',
    REPORT_CHECK_SUB: 'View Subordinates',
    REPORT_ALREADY_SHOW: 'All %{msg} records have been displayed',
    REPORT_ACCOUNT_SEARCH: 'Input the account ID to check the subordinate',
    REPORT_VIEW_REBATE: 'View Rebates ',
    REPORT_GRADE: 'Level',
    REPORT_GRADE2: 'Lv.%{grade}',
    REPORT_STOCK_REBATE: 'Stock Rebate',
    REPORT_DEPOSIT_REBATE: 'Deposit rebate',
    REPORT_SHORT_TERM: 'Short-term',
    REPORT_AGENT_TYPE: 'Agent Type',
    REPORT_PLAY_TYPE: 'Player Type',
    REPORT_SING_UP: 'Register',
    REPORT_SET_REBATE: 'Configurable range',
    REPORT_SET_REBATE_1: 'Integer rebate',
    REPORT_SELF_REBATE: 'My own rebate',
    REPORT_CODE_MESSAGE: 'The invitation code has been created, would you like to view the invitation code ?',
    REPORT_GO_VIEW: 'View',
    REPORT_STAY_PAGE: 'Later',
    REPORT_BORN_INVITATION_CODE: 'Create an invitation code',
    REPORT_QR_CODE: 'Invitation code promotion link QR code (long press can save)',
    REPORT_COPY_LINK: 'Copy the link',
    REPORT_COPY_INVITATION_CODE: 'Copy the invitation code',
    REPORT_SUCCESS_COPY: 'Copied',
    REPORT_CONFIRM_DELETE_CODE: 'Are you sure you want to delete this invitation code?',
    REPORT_DELETE_CODE: 'Remove',
    REPORT_ACTING: 'Agent',
    REPORT_PLAYER: 'Player',
    REPORT_FIELD_ERROR: 'Input field error',
    REPORT_CHECK_INPUT: 'Please check the input value',
    REPORT_NOT_CREATED_CODE: 'Invitation code is not created',
    INVEST_TO: '-',
    INVEST_ALL: 'All',
    INVEST_PROFITABLE: 'Profit',
    INVEST_UNPROFITABLE: 'Unprofitable',
    INVEST_WAITING_RELEASED: 'Waiting',
    INVEST_ORDER_CANCELLED: 'Cancelled',
    INVEST_STOP_PROFIT: 'Stop',
    INVEST_SEVEN_DAYS: '7 days',
    INVEST_PERIOD_NUMBER: 'Period No.',
    INVEST_TIME: 'Chasing time',
    INVEST_SINGLE_NUMBER: 'Order No.',
    INVEST_MONEY: 'Amount',
    INVEST_BONUS: 'Bonus',
    INVEST_WINNING_NUMBERS: 'Numbers',
    INVEST_HOW_PLAY: 'Mode',
    DEAL_ALL_TYPE: 'All',
    DEAL_WITHDRAWAL_RECORD: 'Withdraw',
    DEAL_DEPOSIT_RECORD: 'Deposit',
    DEAL_SERIAL_NUMBER: 'Order No.',
    DEAL_TIME: 'Time',
    DEAL_CASH: 'Amount',
    DEAL_BALANCE: 'Balance',
    DEAL_REMARK: 'Remarks',
    DEAL_WITHDRAWALING: 'Withdraw',
    DEAL_WITHDRAWAL_SUCCESS: 'Withdraw successfully',
    DEAL_WITHDRAWAL_ERROR: 'Withdraw failed',
    DEAL_WITHDRAWAL_REJECT: 'The withdraw was denied.',
    DEAL_PAY_ERROR: 'Payment in lieu failed',
    DEAL_PAY_REJECT: 'Payment in lieu of payment was denied',
    DEAL_PAY_SUCCESS: 'Payment in lieu of payment successful',
    DEAL_PAYING: 'Payment in lieu of payment',
    DEAL_DEPOSITING: 'Deposit',
    DEAL_DEPOSIT_SUCCESS: 'Deposit successfully',
    DEAL_DEPOSIT_ERROR: 'Deposit failed',
    DEAL_PENDING_PAYMENT: 'Payment pending',
    DEAL_COPY_SUCCESS: 'Replicated successfully',
    DEAL_INVESTMENT_DEDUCTIONS: 'Investment Deductions',
    SECURITY_CENTER: 'Security Center',
    SECURITY_CHANGE_LOGIN_PASSWORD: 'Change your password',
    SECURITY_CHANGE_SAFE_PASSWORD: 'Change security code',
    SECURITY_CHANGE_SECRET_PASSWORD: 'Security questions',
    SECURITY_FIND_SAFE_PASSWORD: 'Retrieve security code',
    SECURITY_CONFIRM_SAFE_PASSWORD: 'Confirm security code',
    SECURITY_BANK_CARD: 'Bank card management',
    SECURITY_ALTER: 'Change',
    SECURITY_SETING: 'Setting',
    SECURITY_GO_SETTING: 'Go to Settings',
    SECURITY_NEW_OLD_NOT_SAME: "New password can't be the same as your old password",
    SECURITY_NOT_SAME: "Password can't be the same as the account, and can't be set too simple. Forbidden to set: 123456, 123123, 654321, 111111 and other such simple password",
    SECURITY_LOGIN_KEY: 'Password',
    SECURITY_UPDATE_PASSWORD: 'You should change your passwords every 2 months.',
    SECURITY_PASSWORD: 'Password',
    SECURITY_RESET_PASSWORD: 'Change password',
    SECURITY_CONFIRM_PASSWORD: 'Confirm password',
    SECURITY_SUCCESS_UPDATE: 'Password has been successfully changed.Please login again',
    SECURITY_PASSWORD_INCONSISTENT: 'New Password and Confirm Password Field do not match',
    SECURITY_UPDATE_SUCCESS: 'Successfully changed',
    SECURITY_PASS_QUESTION: 'Security questions',
    SECURITY_PASS_ID: 'Identification',
    SECURITY_SECRET_QUESTION: 'Security questions',
    SECURITY_CONFIRM_SECRET: 'Verify the Security questions',
    SECURITY_FIND_ACCOUNT: 'Improve account security and retrieve  passwords quickly .',
    SECURITY_PASSWORD_AGIAIN: 'Input Security code again',
    SECURITY_INPUT_ANSWER: 'Enter your answer',
    SECURITY_FIND_ENTER_QAMSG: 'Please enter the security question',
    SECURITY_VERITY_PASSWORD: 'Verify login password',
    SECURITY_CONFIRM_USER: 'Confirmation of identity',
    SECURITY_LOGIN_SAFE_PASSWORD: 'Retrieve your security code by login password',
    SECURITY_LOGIN_PASSWORD: 'Password',
    BANKCARD_INSTRUCTION: 'Instructions',
    BANKCARD_LAST_NUMBER: 'Tail number',
    BANKCARD_MESSAGE: 'Bound %{num}  bank cards has not been used, can not bind other bank cards. If you need to use another bank card, please edit the current information. ',
    BANKCARD_LOCK: 'After the bank card is bound and successfully withdrawn, it will be automatically locked and cannot be deleted or modified. You can bind up to 5 bank cards with the same name. ',
    BANKCARD_ADD: 'Add  bank card ',
    BANKCARD_UPDATE: 'Modify bank card',
    BANKCARD_CHECK: 'Verify bank card',
    BANKCARD_PASS_ID_CONFIRM: 'Please verify your identity',
    BANKCARD_CONFIRM_SAFE_PASSWORD: 'Verify security code',
    BANKCARD_CONFIRM_RESULT: 'Validation results',
    BANKCARD_OPEN_ACCOUNT: 'Bank',
    BANKCARD_ACCOUNT_NAME: 'Name',
    BANKCARD_NUMBER: 'Card number',
    BANKCARD_ADDRESS: 'Location',
    BANKCARD_CONFIRM_NOT_ERROR: 'Confirm that the following are correct',
    BANKCARD_NAME_ERROR: 'The bank account name is wrong, please choose again',
    BANKCARD_DATA_ERROR: 'The bank card information is wrong, please try again',
    BANKCARD_ID_SUCCESS: 'Authentication successful!',
    BANKCARD_SPACE_DONT: 'Do not enter a null value',
    BANKCARD_MESSAGE_ONE: 'Maximum of 5 bank cards can be bound. Bank cards that have been successfully withdrawn will be automatically locked and cannot be deleted or modified.',
    BANKCARD_MESSAGE_TWO: 'You have already bound 5 bank cards, you cannot bind more cards. Bank cards that have been successfully withdrawn are automatically locked and cannot be deleted or modified.',
    BANKCARD_MESSAGE_THREE: 'You have bound 1 bank card which has not been used, you cannot bind other bank cards. If you need to use another bank card, please edit  the current binding information. After the bank card is successfully withdrawn, it will be automatically locked and cannot be deleted or modified. You can bind up to 5 bank cards with the same name.',
    BANKCARD_MESSAGE_FOUR: 'You have bound 1 bank card which has not been used, you cannot bind other bank cards.If you need to use another bank card, please contact to service.',
    BANKCARD_MANY_BIND: 'You can bind %{num} bank cards',
    BACKCARD_OTHER: 'Other',
    BANKCARD_BIND_MORE: 'You can bind %{msg} bank cards',
    BANKCARD_IMMEDIATELY: 'Security code has been set successfully, would you like to bind the bank card ?',
    BANKCARD_BIND_CARD: 'Bind bank card',
    BANKCARD_16_NUMBER: '16 digits',
    BANKCARD_17_NUMBER: '17 digits',
    BANKCARD_18_NUMBER: '18 digits',
    BANKCARD_19_NUMBER: '19 digits',
    BANKCARD_BANKTYPE_ID: '%{msg} digits',
    ABOUT_TITLE: '%{msg} was founded in 2014 to provide the most professional stock investment system, providing users with a safe, reliable and ultimate service experience.',
    ABOUT_SUB_TITLE: 'Secure trading platform with multiple authentication',
    ABOUT_SUB_TITLE_TWO: '%{msg} is certified by international third parties STS, Norton, GEOTRUST, and serves you under their strict standards, without any malware.',
    ABOUT_TEXT_ONE: '%{msg} has long been upholding the service tenet of sincerity as the root and trust as the basis to provide the best quality stock purchase experience for our users.',
    ABOUT_TEXT_TWO: '%{msg} has industry-leading technology services, including a complete and convenient instant consulting service system, as well as self-developed app side and many other technology products.',
    ABOUT_TEXT_THREE: '%{msg} provides the most reasonable odds, multi-channel collection and payment methods, professional and rigorous risk control and third-party fund insurance, 100% guarantee of player funds.',
    LEGAL_NOTICES: 'Legal Notice',
    LEGAL_TITLE: 'For any content on this website, no one is allowed to mirror it on a server that is not owned by this website.',
    LEGAL_SUB_TITLE: '%{msg} an ideal and secure trading platform',
    LEGAL_TEXT_ONE: 'The copyright of any content (including but not limited to data, text, graphics, images, sound or video, etc.) provided on this website belongs to this website or the relevant right holder.',
    LEGAL_TEXT_TWO: 'May not copy, reproduce, transmit, publish, repost, adapt or display the content of this website in any way without the prior written permission of this website or the relevant right holder.',
    LEGAL_TEXT_THREE: "At the same time, without the written permission of this website, for any content on this website, no one is allowed to make a mirror image on a server that is not owned by this website. Any unauthorized use of this website will violate the Copyright Law of the People's Republic of China and other laws and regulations as well as the provisions of relevant international conventions.",
    CONTACT_US: 'Contact Us',
    CONTACT_TITLE: 'The %{msg} customer service center is open all year round, providing quality service 24 hours a day, 7 days a week.',
    CONTACT_SUB_TITLE: '%{msg} third party fund insurance 100% guaranteed fund security',
    CONTACT_TEXT_ONE: 'If you have any questions about the use of this website, you can contact our customer service staff in any of the following ways to enjoy the most real-time service',
    CONTACT_CLICK: 'Click',
    CONTACT_TEXT_TWO: 'Instant access to the online customer service system to contact personnel.',
    CONTACT_CONTENT_COOPERATION: 'Content Cooperation',
    CONTACT_TEXT_THREE: 'If you have wonderful or original content or other resources related to stock play stock tips, you are welcome to contact us!',
    CONTACT_ADVERTISE_COOPERATION: 'Advertising Cooperation',
    CONTACT_TEXT_FOUR: 'Through the exchange of advertising space and other forms of free combination of advertising resources to expand publicity, increase the diversity of service entrance, and ultimately to enhance the website users can obtain more added value for the purpose. If you have the corresponding resources, you are very welcome to contact us.',
    CONTACT_MEDIA_COOPERATION: 'Media Cooperation',
    CONTACT_TEXT_FIVE: "If you have rich resources in traditional and new media such as Internet, WeChat, Weibo, etc., you are welcome to contact us and let us improve each other's influence through friendly cooperation.",
    CHOOSE: 'Select %{msg}',
    CHOOSE_TITLE: '%{msg} aspires to provide traders with an unparalleled experience in trading financial products, enabling them to create an edge in the market with consistent profits.',
    CHOOSE_SUB_TITLE: '%{msg}Multiple tripartite international certifications',
    CHOOSE_DEAL_TRANSPARNET: 'Transaction Transparency',
    CHOOSE_TEXT_ONE: 'As a top Asian dealer, we provide our clients with top-notch transparency, eliminating trader intervention in trade execution.',
    CHOOSE_TEXT_TWO: 'Among our client base, we have served a large number of professional traders who recognize the integrity and transparency of the operation of X products.',
    CHOOSE_DEAL_SYSTEM: 'Powerful trading system',
    CHOOSE_TEXT_THREE: '%{msg} is currently the top powerful and most popular trading platform, dedicated to independent research and development APP dual platform trading.',
    CHOOSE_TEXT_FOURE: '%{msg} in you can experience fast and convenient trading of financial instruments.',
    CHOOSE_ORDER_SPEED: 'Unparalleled order fulfillment speed',
    CHOOSE_TEXT_SIX: 'The %{msg} uses cutting-edge technology to optimize and stabilize the network transmission wheel environment, ensuring ultra-fast execution speed.',
    CHOOSE_TEXT_FIVE: 'Through proven technology solutions, immediate trade execution becomes a reality and allows traders on the platform, to gain advantageous conditions in a rapidly changing market.',
    CHOOSE_INTERNATIONAL_NORMS: 'Certified to international standards',
    CHOOSE_TEXT_SEVENT: '%{msg} is certified by TST, Norton and GEOTRUST international third parties and serves you under their strict standards.',
    CHOOSE_MONEY_SAFE: 'Secure funds and favorable costs',
    CHOOSE_TEXT_EIGHT: "%{msg} designates a top Asian bank as the trust bank for client funds, and client funds are kept independently and completely separate from the company's operating funds.",
    CHOOSE_TEXT_NINE: 'We can only withdraw funds for the purpose of trading with the authorization of the client.',
    CHOOSE_TEXT_TEN: 'During the service hours %{msg} the customer is guaranteed to pick up the money and get it on the same day.',
    CHOOSE_TEXT_ELEVEN: '%{msg} works with top process providers to provide each client with competitive spreads, and flexible trading conditions.',
    CHOOSE_HIGH_QUALITY: 'Rich products and excellent service',
    CHOOSE_TEXT_TWELVE: '%{msg} offers 40+ trading options including Forex, precious metals and virtual currencies',
    PRIVACY_STATEMENT: 'Privacy Notice',
    PRIVACY_TITLE: 'For any content on this website, no one is allowed to mirror it on a server that is not owned by this website.',
    PRIVACY_TEXT_ONE: 'Providing us with your personal information at login is based on trust that we will treat your personal information in a professional, careful and responsible manner.',
    PRIVACY_TEXT_TWO: 'Therefore, we have established a personal information confidentiality system to protect your personal information. Anyone who accesses this website in any way or uses the information on this website directly or indirectly is considered to be voluntarily bound by the statement on this website. The terms of our privacy protection are as follows',
    PRIVACY_MESSAGE: 'Personal Information Collection',
    PRIVACY_TEXT_THREE: 'Collection of personal information When you register and use the services of this website, we collect information related to personal identity with your consent. If you cannot provide the corresponding information, you may not be able to use the corresponding service. We may also collect other relevant information for the purpose of optimizing user experience in order to optimize our website services.',
    PRIVACY_PROTECT: 'Protection of privacy',
    PRIVACY_TEXT_FOUR: 'Protection of privacy Protection of user privacy is a basic policy of this website. This website will not publish or disseminate any information you have registered on this website, except for the following cases. ',
    PRIVACY_ITEM_ONE: 'Obtain explicit prior authorization from the user.',
    PRIVACY_ITEM_TWO: 'The leakage of non-public information of users due to improper confidentiality of their own information.',
    PRIVACY_ITEM_THREE: 'Data leakage, loss, theft or tampering due to network lines, hacker attacks, computer viruses, government controls, etc..',
    PRIVACY_ITEM_FOUR: 'According to the requirements of relevant laws and regulations',
    PRIVACY_ITEM_FIVE: 'Pursuant to a decision or award of a court or arbitration body, and as required by other judicial proceedings.',
    PRIVACY_ITEM_SIX: 'In accordance with the requirements of the relevant government authorities',
    PRIVACY_PUBLIC: 'Self-renewal and information disclosure',
    PRIVACY_TEXT_FIVE: 'We encourage you to self-update and modify your personal information to make it secure and valid.',
    PRIVACY_TEXT_SIX: 'You can easily access and modify your personal information at any time, and you can modify and delete your information on the Site at any time at your discretion. You are the only person responsible for maintaining the confidentiality of your account and password information, and in any event, please take care to keep it secure.',
    PRIVACY_TEXT_SEVEN: 'Whenever you voluntarily disclose personal information in public, such information may be collected and used by others, and the website will not be responsible for any leakage of your personal information as a result.',
    PRIVACY_HIT: 'Attention',
    PRIVACY_TEXT_EIGHT: 'We may change our privacy policy from time to time and these changes will be reflected in this statement. Any changes we make will be made with your rights and satisfaction in mind and we ask that you review our privacy statement each time you visit our web pages and by continuing to enjoy the service, users will be deemed to have accepted the changes to the Terms of Service.',
    CHATROOM: 'Chats',
    CHATROOM_NO_ROOM: 'There are no Chats open right now.',
    CHATROOM_DELETE_ROOM: 'The chat has been deleted',
    CHATROOM_ALL_SHOW_ROOM: 'Showed all',
    CHATROOM_CELL: 'Cell',
    CHATROOM_CONTENT: 'Content',
    CHATROOM_YOU: 'You',
    CHATROOM_SPEND_IMG: 'sent a photo',
    CHATROOM_SHARE_BET_SLIP: 'shared a order',
    CHATROOM_SPEND_PLAN: 'sent a plan',
    CHATROOM_SPEND_RED_ENVELOPE: 'Gift are being sent',
    CHATROOM_ALREADY_DELETE: 'The chat has been deleted',
    CHATROOM_YESTERDAY: 'Yesterday',
    CHATROOM_BEFORE_YESTERDAY: 'Day before yesterday',
    CHATROOM_ALL_DATA: 'All data is shown',
    CHATROOM_FROM_WEB: 'This picture is from the Internet',
    CHATROOM_FORVER: 'Permanent',
    CHATROOM_WEEK: 'One week',
    CHATROOM_DAY: 'One day',
    CHATROOM_HOUR: 'One hour',
    CHATROOM_HALF_HOUR: 'Half hour',
    CHATROOM_UNMUTE: 'Unmute',
    CHATROOM_COLLECTION_SUCCESS: 'Collection Success',
    CHATROOM_CONNECTION_FAILED: 'Connection failed, please try again later',
    CHATROOM_UNMUTE_FORVER: 'You have been permanently muted',
    CHATROOM_UNMUTE_DAY: 'You have been muted for 1 day',
    CHATROOM_UNMUTE_WEEK: 'You have been muted for 1 week',
    CHATROOM_UNMUTE_HOUR: 'You have been muted for 1 hour',
    CHATROOM_UNMUTE_HAL_HOUR: 'You have been muted for half hour',
    CHATROOM_ACCOUNT_CHAG: 'Account permissions have been changed, please re-enter the Chat',
    CHATROOM_UNMUTE_LEVEL: 'Your level  has been muted',
    CHATROOM_WS_FAILED: 'ws receive failed',
    CHATROOM_CONGRATULATE: 'Congratulations',
    CHATROOM_NOT_FOLLOW_UP: "Guest can't follow orders , please register first",
    CHATCELL_ALL_UNMUTE: 'All muted',
    CHATCELL_ENTER: 'Input message',
    CHATCELL_IMG: 'Image',
    CHATCELL_RED_ENVELOPE: 'Gift',
    CHATCELL_NOT_SPEND: "Guest can't give gifts , please register first",
    CHATCELL_NOT_SET_PASSWORD: "You haven't set the security code, you can't give gifts. Do you want to set the security code first?",
    CHATCELL_OPEN_HELPER: "Guest can't use the assistant, please register first",
    CHATCELL_IMG_LOAD_FAIL: 'Image loading is failed',
    CHATCELL_MUTE_MANAGEMENT: 'Mute',
    CHATCELL_ROOM_INTRODUCE: 'Introduction',
    CHATCELL_LINE: '社群守則，但是「限制」的力量是有限的，真正重要的是每一位使用者，能了解、並遵守LINE社群守則，不只自己使用更安心，也才能讓「LINE社群」這個新功能發揮所長，好好來服務大家。',
    CHATCELL_EXPIRED: 'The gift has been available for more than 12 hours. If you have got it, you can check it in My Gifts',
    CHATCELL_ROBBED: 'All already taken',
    CHATCELL_CHECK_RED_ENVELOPE: 'The gift has been got and you can view it in My Gifts',
    CHATCELL_GET_DETAILS: 'Check the gifts',
    CHATCELL_WHO_RED_ENVELOPE: "%{msg}'s gift",
    CHATCELL_SPELL: 'Lucky',
    CHATCELL_GENERAL: 'General',
    CHATCELL_THE: 'Personal',
    CHATCELL_DOLLAR: 'dollars',
    CHATCELL_SAVE_BALANCE: 'has been deposited to the balance and is available for investment',
    CHATCELL_GET_COUNT: 'Received %{num}/%{total}',
    CHATCELL_NUM_TOTAL: '%{num}/%{total} dollars',
    CHATCELL_TOTAL: 'Total %{num} dollars',
    CHATCELL_ROBBED_ALREADY: 'There are %{num} gifts totaling %{total} dollars, all of which have been given away',
    CHATCELL_LACKY: 'Lucky',
    CHATCELL_NOT_RECORD: 'No received data',
    CHATCELL_OUT_RECORD: 'No order data',
    CHATCELL_TEMPORARY_RECORD: 'No data',
    CHATCELL_RESIVE_RED_ENVELOPE: 'Gifts received',
    CHATCELL_SEVEN_DAT_TWO: 'Received %{num} gifts in the last 7 days',
    CHATCELL_PLAYER_MESSAGE: 'Player Info',
    CHATCELL_ADMINISTRATOR: 'Administrator',
    CHATCELL_ALL_PEOPLE: 'All',
    CHATCELL_NEWS: '%{num} new messages',
    CHATCELL_HISTORICAL_OUT: 'History',
    CHATCELL_DATE: '%{msg}',
    CHATCELL_NOT_GET: 'Not won',
    CHATCELL_GET: 'Win',
    CHATCELL_WAIT: 'Waiting',
    CHATCELL_DRAW: 'Tie',
    CHATCELL_FALL: 'Fall',
    CHATCELL_BEAR: 'Bear',
    CHATCELL_SINGLE: 'Odd',
    CHATCELL_UP: 'Up',
    CHATCELL_PAIR: 'Even',
    CHATCELL_COW: 'Bull',
    CHATCELL_BIND_SUCCESS: 'Already bound',
    CHATCELL_NOT_EMPTY: 'The content is a required field.',
    CHATCELL_RECORD_UNMUTE: 'No mute history',
    CHATCELL_NOT_SET_NICKNAME: "Haven't set nickname yet",
    MAIL_SPEND: 'Give gifts',
    MAIL_USE_RULES: 'Gift rules',
    MAIL_USE_MONEY: 'Withdrawable amounts up to %{num}$  ',
    MAIL_USE_MONEY_TWO: 'can be used to give Gifts',
    MAIL_SINGEL_MONEY: 'Amount per gift',
    MAIL_TOTAL: 'Total',
    MAIL_NUMBER: 'Quantity of gifts',
    MAIL_TWELVE_HOURS: 'Refunds for unclaimed gifts will be initiated after 12 hours',
    MAIL_LOW_ONE: 'The average amount of gifts each person received is',
    MAIL_HEIGH_DOLLAR: 'Minimum %{one}  Maximum %{num}',
    MAIL_INPUT_PASSWROD: 'Please enter your security code',
    MAIL_SIX_PASSWORD: 'Security code is 6 digits',
    MAIL_HAVE_INTEGER: 'the number of Gifts must be a positive integer',
    MAIL_FIVE_HUNDRED: "The number of Gifts can't exceed 500",
    MAIL_BIG_DOLLAR: 'The amount of Gift per capita can‘t be greater than $%{num} ',
    MAIL_CASH_NOT_ENOUGH: 'Insufficient amount available for withdrawal',
    MAIL_GRAND_TOTAL: 'The cumulative amount of Gift sent each day may not exceed $%{total} . You can send $%{num} ',
    MAIL_LIMIT_DAY: 'The number of times you can send Gift per day is %{num}, the limit has been exceeded',
    MAIL_LESS_DOLLAR: 'The amount of Gift per capita can‘t be less than $%{num} ',
    MAIL_NOT_EMPTY: 'Required field.',
    MAIL_WRONG_FORMAT: 'Format error',
    MAIL_LESS_DOLLAR_TWO: "Per Gift can't be less than $1 ",
    MAIL_REMARK: 'Remarks is a required field.',
    MAIL_LACKY: 'Change to Lucky Gift',
    MAIL_NORMAL: 'Change to Normal Gift',
    MAIL_ADD_FACE: 'Added Emotion',
    MAIL_TIDY: '整理',
    MAIL_FIRST_LOGIN: "Guest can't collect emoji pictures, please register and login first",
    MAIL_DELETE: 'Deleting',
    MAIL_COMPRESSION: 'Compressing',
    MAIL_UPLOAD: 'Uploading',
    MAIL_ASSISTANT: 'Assistants',
    MAIL_CANT_OVER: "Per Gift can't exceed %{num} ",
    MAIL_BIGGER_OUT: "Gift can't be greater than the amount available for withdrawal",
    MAIL_ACCOUNT_SERACH: 'Enter account ID to search',
    MAIL_ALREADY_GET: 'All been taken',
    MAIL_RETURN: 'Returned',
    MAIL_RECEIVE: 'Received',
    MAIL_EXPIRED: 'Expired',
    MAIL_VISITOR_GRAB: "Guest can't get gift, please register first",
    MAIL_MESSAGE: "Lucky! Let's get Gifts!",
    MAIL_TIME_UP: 'Limited-time gifts',
    USERINFO_LIKE: 'Favorite stocks',
    USERINFO_TEST: 'Test',
    USERINFO_SET_NICKNAME: 'Nickname is not set',
    USERINFO_SECRET: 'Private',
    USERINFO_BRONZE: 'Bronze',
    USERINFO_SILVER: 'Silver',
    USERINFO_GOLD: 'Golden',
    USERINFO_PLATINUM: 'Platinum',
    USERINFO_DIAMOND: 'Diamond',
    USERINFO_CRYSTAL: 'Crystal',
    USERINFO_CROWN: 'Crown',
    USERINFO_STARSHINE: 'Starshine',
    USERINFO_KING: 'King',
    USERINFO_GAMBLERS: 'God of Gamblers',
    USERINFO_MANAGE: 'Manage',
    OUTGOLD_CARD: 'Card',
    OUTGOLD_OUT: 'You can still withdraw: %{num} times',
    OUTGOLD_CASH: 'Withdraw Amount',
    OUTGOLD_WATER: 'Insufficient flow %{num} $',
    OUTGOLD_DEAL: 'Go to Trade',
    OUTGOLD_BALANCE: 'Withdrawable balance is %{num} $',
    OUTGOLD_HANDLING_FEE: 'Handling fee %{num}',
    OUTGOLD_RULES: 'Withdraw Rules',
    OUTGOLD_DETAILS: 'Withdraw details',
    OUTGOLD_DEDUCT: '%{num}% fees is deducted for each withdraw',
    OUTGOLD_LOW_HEIGH: 'Per withdrawal minimum %{num}, maximum %{total}',
    OUTGOLD_WATER_ENOUGH: 'Once you have enough flow, you can request a withdrawal.',
    OUTGOLD_I_KNOW: 'I see',
    OUTGOLD_CONFIRM_ID: 'Confirmation of identity',
    OUTGOLD_LIMIT_TIME: 'The maximum times of withdrawals has been reached',
    OUTGOLD_CUSTOMER: 'Currently unable to withdraw funds. please contact the service .',
    OUTGOLD_MESSAGE: 'The withdraw fee is %{num}% of the withdrawal amount, and is deducted directly from the balance after successful withdrawal',
    OUTGOLD_ALL: 'Withdraw all',
    OUTGOLD_RECORD: 'Withdrawal record',
    OUTGOLD_FAIL: 'Withdraw failed',
    OUTGOLD_SHOW_ALL: 'All records of %{num} are displayed',
    OUTGOLD_MESSAGE_ONE: 'Please wait patiently for the system to complete the withdrawal operation.',
    OUTGOLD_MESSAGE_TWO: 'The transaction has been completed and the system has credited the withdrawal to your bank account.',
    OUTGOLD_MESSAGE_THREE: 'The system has cancelled this withdrawal request.',
    OUTGOLD_MESSAGE_FOUR: 'The system has rejected this withdrawal request.',
    OUTGOLD_TEXT_ONE: '1. 1% fees will be deducted for each withdrawal',
    OUTGOLD_TEXT_TWO: '2. Per withdrawal minimum 1 , maximum 2000000 ',
    OUTGOLD_TEXT_THREE: 'The system has rejected this withdrawal request.',
    OUTGOLD_TEXT_FOUR: 'The system has cancelled this payment-in-lieu request.',
    OUTGOLD_TEXT_FIVE: 'The system has rejected this application for payment in lieu of money.',
    OUTGOLD_TEXT_SIX: 'The transaction has been completed and the system has credited this payment to your bank account.',
    OUTGOLD_TEXT_SEVEN: 'Please wait patiently for the system to complete the payment on behalf of the system, pending the return of the payment system.',
    GUARD_ACCOUNT_EMPTY: 'Login ID is a required field.',
    GUARD_CORRECT_ACCOUNT: 'Please enter the correct Login ID.',
    GUARD_PASSWORD_EMPTY: 'Password is a required field.',
    GUARD_CORRECT_PASSWORD: 'Please enter the correct password.',
    GUARD_PASSWORD_ENTER: 'Passwords are 6 to 16 digits in alphabetic and numeric characters.',
    GUARD_INVITE_EMPTY: 'The invitation code is a required field.',
    GUARD_CORRECT_INVITE: 'Please enter the correct invitation code.',
    GUARD_SING_EMPTY: 'Login ID is a required field.',
    GUARD_ACCOUNT_ENTER: 'The login ID is 4 to 16 digits of alphabet and numbers.',
    GUARD_ACCOUNT_LETTER: 'ID can contain only letters',
    GUARD_ACCOUNT_MEMEBER: 'ID can only contain numbers',
    GUARD_VERIFY_EMPTY: 'The authentication code is a required field.',
    GUARD_PHONE_EMPTY: 'Cell phone number is a required field.',
    GUARD_PHONE_CORRECT: 'Please enter the correct mobile number.',
    GUARD_EMAIL_EMPTY: 'Email address is a required field.',
    GUARD_CORRECT_EMAIL: 'Please enter a valid email address.',
    GUARD_QQ_EMPTY: 'QQ ID is a required field.',
    ELEMENT_SHARE: 'Share',
    ELEMENT_SHARE_SUCCESS: 'Share successful',
    ELEMENT_MUTE: 'Muted',
    ELEMENT_FOLLOW: 'Follow',
    ELEMENT_SHARE_WAR: 'Share the battle results',
    ELEMENT_HIT_RATE: 'Total hit rate',
    ELEMENT_WIN_RATE: "Today's Winning Percentage",
    ELEMENT_DONT_TALK: 'Mute',
    ELEMENT_RECALL: 'Retract',
    ELEMENT_SHARE_MEMBER: 'Member',
    ELEMNET_INVESTMENT_CONTENT: 'Investment content',
    ELEMENT_SYSTEM_TEST: 'System Test',
    ELEMENT_STRAIGHT: 'Rotate your smartphone to portrait orientation.',
    ELEMENT_CUSTOMER_SERVICE: 'Please contact to service.',
    ELEMENT_FINANCIAL_INFORMATION: 'News',
    ELEMENT_LOOK_EVERYWHERE: 'No more, read another news',
    ELEMENT_SPEND_MEMEBER: '推送会员',
    ELEMENT_INPUT_UNIT: '请输入投资单位',
    ELEMENT_INVEST_UNIT: '投资单位',
    ELEMENT_DATE: '%{prv} is closed, currently %{now}',
    ELEMENT_CASH: 'Amount',
    ELEMENT_ENTER_BET_SLIP: 'Please enter bet%{msg}',
    ELEMENT_SEACH_RECORD: 'Your investment has been successful. You can check your investment history in My Account',
    ELEMENT_CHECK_BET_SLIP: 'View Orders',
    ELEMENT_GO_ON: 'Continue to follow',
    ELEMENT_INVEST_ERROR: 'The period to invest is wrong',
    ELEMNET_REBATE: 'Rebate',
    ELEMENT_WAIT_DRAW: 'Waiting',
    ELEMENT_DECIMAL: 'Decimal, Double-sided',
    ELEMENT_NOT_WINNING: 'Not won',
    ELEMENT_WIINNING: 'Win',
    ELEMENT_WAIT_PROFIT: 'Waiting for profit',
    ELEMENT_PAY_CASH: "I've already paid",
    ELEMENT_TEXT_ONE: '1. Please use QQ Wallet to transfer funds to the above account.',
    ELEMENT_TEXT_TWO: "2. If it's a QR code, please use QQ wallet to swipe it for transfer. If it's a bank account, please use QQ wallet  to transfer to bank card.",
    ELEMENT_TEXT_THREE: '4. Please input your QQ wallet nickname and recharge amount correctly, QQ wallet nickname is not QQ wallet account, please note the difference.',
    ELEMENT_IS_SUBMIT: 'Submitting',
    MODULE_GET_CASH: 'Congratulations on getting ',
    MODULE_CASH_DOLLAR: '',
    MODULE_FINISHED: 'All already taken',
    MODULE_DATE: 'Expired',
    MODULE_CONDITION_STANDARD: 'Conditions not met. Go recharge now',
    MODULE_ROOM_PASSWORD: "Chat's password",
    MODULE_WRITE_ROOM: 'Please input the code',
    MODULE_ROOM_MESSAGE: 'The code is  4-digits used to enter this chat ',
    MODULE_ROOM_MESSAGE2: 'If you input the code incorrectly multiple times, you will not be able to try the chat room again that day.',
    MODULE_NO_MORE: 'No more',
    MODULE_PROXY: 'Agent Lv.%{num}',
    MODULE_PLAYER: 'Player Lv.%{num}',
    MODULE_SHARE_ROOM: 'Share to Chat',
    MODULE_FIX_SHARE_CASH: 'Modify order amount',
    MODULE_REFIX_DEAL: 'Please revise the share order amount',
    MODULE_HOW_DATE: '%{num}',
    NOTIFY_BUY_SUCCESS: 'Buy Successful',
    NOTIFY_UPDATE_DONE: 'Modification is complete and changes are stored',
    NOTIFY_LOOP_DISABLED: 'Cycle is deactivated',
    NOTIFY_SET_SUCCESS: 'Setup is completed and changes are stored',
    NOTIFY_SAFEPASSWORD_DONE: 'Security code setting completed',
    NOTIFY_BANKCARD_ADD: 'Bank card added successfully',
    NOTIFY_COPY_WATER: 'The order number has been copied',
    NOTIFY_COPY_ACCOUNT: 'The account number has been copied',
    NOTIFY_COPY_NAME: 'The Account name has been copied',
    NOTIFY_COPY_SUCCESS: 'Replicated successfully',
    NOTIFY_COPY_BANK: 'The Bank Name has been copied',
    NOTIFY_COPY_OPEN: 'The Bank Branch has been copied',
    NOTIFY_UPDATE_SUCCESS: 'Modification is complete and changes are stored',
    NOTIFY_INVITE_DELETE: 'Invitation code has been deleted',
    NOTIFY_COPY_PASSWORD: 'The password has been copied',
    NOTIFY_CANCEL_ORDER: 'Cancellation of the order is successful',
    NOTIFY_MUTE_SUCCESS: 'Mute has been set',
    NOTIFY_MUTE_CANCEL: 'Mute has been canceled',
    NOTIFY_FIXED_BUG: 'Modify Error',
    NOTIFY_ID_ERROR: 'Authentication error',
    NOTIFY_PASSWORD_NOT_SAME: 'Security code does not match',
    VIRTUAL_WALLET_WALLET_BALANCE: 'Balance',
    VIRTUAL_WALLET_WATER_INSUFFICIENT: 'Insufficient flow',
    VIRTUAL_WALLET_RECEIVABLE: 'Withdrawable amount',
    RECHARGE_INPUT_TITLE: 'Method',
    RECHARGE_INPUT_HINTTEXT: 'Minimum Amount',
    RECHARGE_ACCOUNT＿INFORMATION: 'Account Info',
    RECHARGE_DEPOSITOR_NAME: 'Depositor\'s name',
    RECHARGE_DEPOSITOR_INPUTNAME: 'Please input depositor\'s name',
    RECHARGE_DEPOSIT_AMOUNT: 'amount',
    RECHARGE_VIRTUAL_WALLET_TEXT: 'Wallet balance is',
    RECHARGE_FULL_DEPOSIT: 'Deposit all',
    RECHARGE_ACCOUNT_INFORMATION: 'Account Info',
    RECHARGE_SCAN_CODE_TEXT: 'Scan the QR code, click to save.',
    RECHARGE_DEPOSIT_DETAILS: 'Deposit Details',
    RECHARGE_ORDER_TITLE: 'Order Info',
    RECHARGE_ORDER_MSG: 'Please complete the transaction and click the "Confirm Payment" button within the time limit. If the transaction is not completed after the time limit, the system will automatically cancel the deposit .',
    RECHARGE_CREATE_ORDER_BTN: 'Create order',
    RECHARGE_RULE: 'Deposit Rules',
    RECHARGE_COPYEND: 'Replicated',
    RECHARGE_SAVEQRCODE: 'The QR code has been saved.',
    RECHARGE_NO_SAVEQRCODE: 'QR code is not supported.',
    RECHARGE_SIDE_HEADER_TITLE: 'Deposit history',
    RECHARGE_SIDE_ALERT_TEXT1_1: '1. Please use %{msg} to transfer funds to the above account.',
    RECHARGE_SIDE_ALERT_TEXT2_1: '2. If it\'s  QR code, please use % { msg } sweep to transfer.If it\'s  bank account, please use %{msg} transfer to bank card method to transfer.',
    RECHARGE_SIDE_ALERT_TEXT3_1: '3. When transferring money, please note your game account ID in the message to facilitate timely confirmation of the recharge amount.',
    RECHARGE_SIDE_ALERT_TEXT4_1: '4. Please input your %{msg} nickname and amount correctly, %{msg} nickname and  amount %{msg} account, please pay attention to the distinction.',
    RECHARGE_SIDE_ALERT_TEXT5_1: '5. Please be sure to transfer money and then submit your order, otherwise your payment will not be checked in time. Please do not submit orders repeatedly.',
    RECHARGE_SIDE_ALERT_BTN_TEXT: 'I See',
    RECHARGE_SIDE_OPEN_POP_STATE0_INFO: 'Please wait patiently for the system to complete the deposit operation.',
    RECHARGE_SIDE_OPEN_POP_STATE1_INFO: 'The transaction has been completed and the system has credited the deposit to your account.',
    RECHARGE_SIDE_OPEN_POP_STATE2_TITLE: 'Deposit failed',
    RECHARGE_SIDE_OPEN_POP_STATE2_INFO: 'The transaction is not completed after the time limit, the system has cancelled the deposit.',
    RECHARGE_SIDE_OPEN_POP_STATE4_INFO: 'Please complete the transaction and click the "Confirm Payment" button within the time limit. If the transaction is not completed after the time limit, the system will automatically cancel the deposit.',
    RECHARGE_SIDE_OPEN_POP_ERROR_TITLE: 'Deposit failed',
    RECHARGE_SIDE_OPEN_POP_ERROR_INFO: 'Please complete the transaction and click the "Confirm Payment" button within the time limit. If the transaction is not completed after the time limit, the system will automatically cancel the deposit.',
    RECHARGE_SIDE_BANKITEM_WALLET_ACCOUNT_NAME: 'Wallet ID',
    RECHARGE_SIDE_BANKITEM_WALLET_NICK_NAME: 'Wallet nickname',
    RECHARGE_SIDE_BANKITEM_UNION_ACCOUNT: 'UnionPay Account',
    RECHARGE_SIDE_BANKITEM_UNION_ACCOUNT_NAME: 'UnionPay Nominee',
    RECHARGE_SIDE_BANKITEM_UNION_NAME: 'Union Pay Holder',
    RECHARGE_SIDE_BANKITEM_ACCOUNT: 'Account number',
    RECHARGE_SIDE_BANKITEM_NAME: 'Account number',
    RECHARGE_SIDE_BANKITEM_BANK_NAME: 'Bank',
    RECHARGE_SIDE_BANKITEM_ALI_ACCOUNT: 'Alipay ID',
    RECHARGE_SIDE_BANKITEM_RECEIVING_ACCOUNT: 'Account number',
    RECHARGE_SIDE_BANKITEM_ALI_NAME: 'Alipay Holder',
    RECHARGE_SIDE_BANKITEM_OPEN_AN_ACCOUNT: 'Financial institutions',
    RECHARGE_SIDE_BANKITEM_OPEN_API_ALERT: 'The deposit amount is wrong',
    RECHARGE_SIDE_BANKITEM_OPEN_API_ALERT1: 'Minimum amount for the deposit method ',
    RECHARGE_SIDE_BANKITEM_AISLE: 'Channel',
    RECHARGE_BASEBANKDATA_SELECTMONEY_INFO: 'The amount cannot exceed the maximum amount.',
    RECHARGE_BASEBANKDATA_DECIMAL_MSG: 'For more accurate verification of your amount, the system has adjusted the deposit amount to %{msg}',
    RECHARGE_BASEBANKDATA_CHANGEURL_MSG: 'Please check if the cash withdrawal application is submitted successfully after 3-5 minutes.',
    RECHARGE_BASEBANKDATA_CHANGEURL_BTN1: 'View',
    RECHARGE_BASEBANKDATA_CHANGEURL_BTN2: 'Later',
    RECHARGE_BASEBANKDATA_ERROR_PASSWORD_EMPTY: 'The security code is a required field.',
    RECHARGE_BASEBANKDATA_ERROR_SAFEPASSWORD: 'Incorrect security code format',
    RECHARGE_BASEBANKDATA_ERROR_EMPTY: 'Required field',
    RECHARGE_BASEBANKDATA_ERROR_TYPE: 'Incorrect format',
    RECHARGE_BASEBANKDATA_ERROR_CHARGE_MONEY: 'Deposit amount must be between %{min}-%{max}',
    RECHARGE_BASEBANKDATA_ERROR_BANK_EMPTY: 'The financial institution of the account is a required field.',
    RECHARGE_BASEBANKDATA_ERROR_BANKCARD_EMPTY: 'Card number is a required field.',
    RECHARGE_BASEBANKDATA_ERROR_BANKCARD_TYPE: 'Incorrect card number format',
    RECHARGE_BASEBANKDATA_ERROR_NAME_EMPTY: 'The account holder is a required field',
    RECHARGE_BASEBANKDATA_ERROR_NAME_TYPE: 'Incorrect account holder format',
    RECHARGE_BASEBANKDATA_ADD_EMPTY: 'Location is a required field',
    RECHARGE_BASEBANKDATA_EMPTY: 'Required field',
    RECHARGE_BASEBANKDATA_ERROR_BANKNAME_EMPTY: 'The name of the financial institution is a required field.',
    RECHARGE_BASEBANKDATA_ERROR_BANKNAME_TYPE: 'Incorrect format',
    RECHARGE_BASEBANKDATA_SETINTERVALFUNC_MSG: 'Please do not submit orders frequently. Please retry after %{msg}',
    SECOND: 'second',
    MINUTE: 'minute',
    RECHARGE_BASEBANKDATA_TEXT: 'Between',
    RECHARGE_BASEBANKDATA_JDNAME: 'Jingdong account',
    RECHARGE_BASEBANKDATA_JDMSG: 'Please input your Jingdong account ',
    FORGOTPASSWORD_TITLE: 'Retrieve password',
    FORGOTPASSWORD_INFO: 'Retrieve the password from your account.',
    FORGOTPASSWORD_INPUT_LABEL: 'Login ID',
    FORGOTPASSWORD_SELECT_TYPE_SAFEPASSWORD: 'Security code',
    FORGOTPASSWORD_CONTACT_SERVICE: 'Please contact us at Online service.',
    FORGOTPASSWORD_NEXT_BTN: 'Next',
    FORGOTPASSWORD_QAINPUT: 'Please input the answer',
    FORGOTPASSWORD_ALERT_MSG: 'Service',
    FORGOTPASSWORD_SHOWPROMPT_MSG: 'Password is different, please re-enter',
    FORGOTPASSWORD_SHOWPROMPT_CONFIRM_MSG: 'Your changes are completed. Would you like to log in immediately?',
    FORGOTPASSWORD_SHOWPROMPT_CONFIRM_OKBTN: 'Login Now',
    FORGOTPASSWORD_SHOWPROMPT_CONFIRM_CANCELBTN: 'Back to home',
    MEMBERCENTER_CONFIRM_PW_YES: 'Yes',
    MEMBERCENTER_CONFIRM_PW_NO: 'No',
    SET_PHONE_NUMBER_LABEL: 'Phone number',
    SET_PHONE_NUMBER_PLACEHOLDER: 'Please input your phone number',
    SET_PHONE_NUMBER_MAIL_LABEL: 'SMS code',
    SET_PHONE_NUMBER_MAIL__PLACEHOLDER: 'Please input SMS code',
    SET_PHONE_SENDING: 'sending...',
    SET_PHONE_CONFIRM_MSG: 'Password-protected  phone number integration will not be available for a while.',
    BASELOTTERY_NET_ERROR: 'Due to the inability to synchronize the server time, you will not be able to invest, please check the network situation',
    PC_SAFE_VERIFY: 'Security Verification',
    PC_KLINE_CHART: 'Candles',
    PC_TREND_CHART: 'Moving Average',
    PC_MARKET_CHART: 'Trend',
    PC_SYSETM_BUILD: 'The system is developing',
    PC_STOCK_INDEX: 'Stock',
    PC_ISSUE: 'Period No.',
    PC_OUTGOING_INDEX: 'Index',
    PC_TREND: 'Trend',
    PC_INSTANT_PROFIT: 'Instant Ranking',
    PC_TOTAL_COUNT: 'Total',
    PC_TOTAL_MATH: 'Total',
    PC_NOT_SELECTED: 'Not selected',
    PC_DOUBLE_DISC: 'Double side %{num} ¥ %{total}',
    PC_POSITION_DISC: 'Positioning%{num}  ¥ %{total}',
    PC_PLAY_EXPLAN: 'How to play',
    PC_MODEL: 'Mode',
    PC_GENERAL_DEAL: 'Normal',
    PC_CHASE_DEAL: 'Chase',
    PC_STATE: 'Status',
    PC_STOP_WAREHOUSE: 'Stop when profit',
    PC_BET_STATUS: 'Order Status',
    PC_WAREHOUSE_NUMBER: 'Numbers',
    PC_START_DATE: 'From%{num} ',
    PC_TRAKING_STATUS: 'Chasing Status',
    PC_PROFTI_AMOUNT: 'Profit',
    PC_SUMBIT_ORDER: 'The order is submitted successfully ',
    PC_SUMBIT_ORDER2: 'and we will add the credit for you within 5 minutes',
    PC_BET_DETAILS: 'Order detail',
    PC_TRACKING_DETAILS: 'Chase detail',
    PC_DEAL_CENTER: 'Trade Center',
    PC_WALLET: 'Wallet',
    PC_ORDER_SUCCESS: 'Ordered successfully',
    PC_ORDER_FAIL: 'Order Failed',
    PC_CHANGE_IMG: 'Set new photo',
    PC_CEKECK_INTEGRAL: 'View current points',
    PC_ZOOM: 'Area Zoom',
    PC_BACK_ZOOM: 'Area scaling restore',
    PC_OPEN_HORIZONTAL: 'Turn on horizontal',
    PC_CLEAR_CHECKBOX: 'Clear all frames',
    PC_SAVE_IMG: 'Save as image',
    PC_NO_DATA: 'No data',
    PC_TRADE_EVERY: 'per',
    PC_TRADE_TIME: 'times',
    PC_CHASE_DATE: 'Chasing periods',
    PC_CANCEL_ORDER: 'Cancel',
    PC_IN_TOTAL: '%{num}  displayed',
    PC_ANNOUNCE_NEW: 'Latest announcement',
    PC_RULE_DOUBLE: "Two digits after the decimal point of the index is the base value, the first is the champion number, the second is the runner-up number. Each corresponding number when greater than 4 (doesn't contain 4) for Up, less than 4 (contains 4) for Down. The odd number is Odd and the even number is Even. Bull and bear rule : when the champion is greater than the runner-up, it's Bull . When the champion less than runner-up, it'sBear. The current investment can't invest Up / Down, Odd / Even, Bull / Bear at the same time. When the champion and runner-up appear the same number as the Tie then the principal will be refunded.",
    TRADE_INVEST_SINGLE: 'Amount',
    TRADE_INVEST_COUNT: 'Bets',
    PC_RULE_LOCATION: 'Choose at least 1 number and up to 6 numbers each from the champion and runner-up positions. The number chosen is consistent with the index of positions taken in the same position.',
    PC_PREV_BTN: 'Previous',
    PC_NEXT_BTN: 'Next',
    PCMEMBER_ACCOUNT: 'Account',
    PCMEMBER_MEN: 'Male',
    PCMEMBER_WOWAN: 'Female',
    PCMEMBER_SAVE: 'Save',
    PCMEMBER_BIND: 'Bind',
    PCMEMBER_SET: 'Set',
    PCMEMBER_NOT_BIND: 'Unbound',
    PCMEMBER_SET_NOW: 'Set',
    PCMEMBER_BIND_NOW: 'Bound',
    PCMEMBER_EDIT: 'Edit',
    PCMEMBER_OVERVIEW: 'Overview',
    PCMEMBER_SUMMARY: 'Summary',
    PCMEMBER_NOTE_CONTENT: 'Remark',
    PCMEMBER_TOTAL_RECORD: 'Total %{num}',
    PCMEMBER_COPY_WATER: 'Replicated successfully',
    PCMEMBER_SEVEN_DAY: '7 days',
    PCMEMBER_DEAL_ING: 'Trading',
    PCMEMBER_DEAL_SUCCESS: 'Traded successfully',
    PCMEMBER_DEAL_FAIL: 'Traded failed',
    PCMEMBER_SUCCESS: 'Success',
    PCMEMBER_ERROR: 'Fail',
    PCMEMBER_GRADE: 'Level',
    PCMEMBER_INCREASE: 'If deposit $1, you can get 1 point',
    PCLOGIN_PREV: 'Back',
    PCLOGIN_FIND_PASSWORD: 'Security question can increase the security of your account and quickly retrieve your password',
    PCLOGIN_WITHDRAWAL: 'The security code can be used for withdrawing funds,  account security setting, adding bank cards and other operations, which can guarantee the safety of funds',
    PCLOGIN_PASSWORD_ERROR: 'Security code error, please try again',
    PCLOGIN_ENTER_ANSWER: 'Please enter your answer',
    PCLOGIN_NEW_PASSWORD: 'New password',
    PClOGIN_SUCCESS_FIX: 'Your password has been changed successfully, would you like to login now?',
    PCLOGIN_ACCOUNT_NUMBER: 'Enter account ID',
    PCLOGIN_VERIFICATION: 'Select authentication',
    PCLOGIN_PASS_ID: 'Identification',
    PCLOGIN_SET_PASSWORD: 'Set new password',
    PCLOGIN_CONFIRM_VISITING: 'Please make sure you are visiting',
    PCLOGIN_HAVE_ACCOUNT: 'Already have an account? ',
    PCLOGIN_ACCOUNT: 'Account',
    PCLOGIN_SIGN_UP: 'Sign up',
    PC_NOT_ACCOUNT: "Don't have an account yet?",
    PC_SUCCESS_SING: 'Register successfully',
    LAOUT_SAFE_CONFIRM: 'Security Certification',
    LAYOUT_APP_DOWNLOAD: 'Scan to download APP',
    LAYOUT_BROWSER_SUGGEST: 'Recommended browsers: Safari/Google Chrome',
    LAYOUT_ACCOUNT_OVERAGE: 'Balance',
    LAYOUT_ASSETS_MANAGE: 'Asset Management',
    PC_RECHARGE_EXTENDS_TRADESELECTION_LABELTITLE: 'Deposit channel',
    PC_RECHARGE_EXTENDS_FROMLISTBETMONEY_LABELTITLE: 'Deposit amount',
    PC_RECHARGE_EXTENDS_FROMLISTBETNAME_LABELTITLE: "Depositor's name",
    PC_RECHARGE_EXTENDS_FROMLISTQRCODESHOW_LABELTITLE: 'Account Info',
    PC_RECHARGE_EXTENDS_FROMLISTACCOUNTNAME_LABELTITLE: 'Account Name',
    PC_RECHARGE_EXTENDS_FROMLISTBANKNAME_LABELTITLE: 'Bank',
    PC_RECHARGE_EXTENDS_FROMLISTBANKCARDNUMBER_LABELTITLE: 'Account number',
    PC_RECHARGE_BTN: 'Submit Order',
    PC_RECHARGE_ILLUSTRATE: 'Attention',
    PC_RECHARGE_NOT_EMPTY: 'This field is required',
    PC_RECHARGE_LENGTH_ERROR: 'Length format error',
    PC_RECHARGE_INPUTPLACEHOLDER: 'Please input the deposit amount',
    PC_RECHARGE_SELECTTYPEPLACEHOLDER: 'Not selected',
    PC_RECHARGE_BANKPAGE_BETNAMEPLACEHOLDER: 'Please enter your name',
    PC_RECHARGE_DROPDOWN_TEXT: 'Scan payment QR code',
    PC_RECHARGE_MAX_ERROR: 'Maximum amount error',
    PC_RECHARGE_MIN_ERROR: 'Minimum amount error',
    PC_RECHARGE_NUMBER_ERROR: 'Please enter numeric units',
    PC_RECHARGE_SUCCESS_DEAL: 'The order has been successfully submitted. Complete payment on the newly opened page',
    PC_ANNOUNCEMENT_NEXT: 'Next',
    PC_ANNOUNCEMENT_PREV: 'Previous',
    PC_CURRENCY_COUNT: 'Quantity of digital currency',
    PC_CURRENCY_RANGE: 'Single limit %{min}-%{max} digital currency',
    PC_CURRENCY_TIP: 'Deposit rate {converRate} , Deposit amount {total}',
    PC_CURRENCY_ADDRESS: 'Payment address',
    PC_CURRENCY_DEAL: 'Trading currency',
    PC_CURRENCY_CHOOSE: '%{msg}, transaction screenshot, please choose one and fill in',
    PC_MIN_MAX_AMOUNT: 'Transaction limit %{min}-%{max}',
    CURRENCY_DIGITAL: 'Digital currency',
    STOCK_1801: 'Bitcoin',
    STOCK_1803: 'ETH(USA)',
    STOCK_1804: 'ETH(CN)',
    STOCK_1811: 'CSI 300',
    STOCK_1812: 'SZSE 100',
    STOCK_1813: 'SSEC',
    STOCK_1814: 'SZSE B ',
    STOCK_1815: 'SZSEInnovate',
    STOCK_1816: 'SZSE 1000',
    STOCK_1817: 'SZSE Central',
    STOCK_1818: 'Shenzhen IT',
    STOCK_1819: 'SZScientific',
    STOCK_1821: 'SSEC',
    STOCK_1822: 'SSEC A',
    STOCK_1823: 'SSE Market100',
    STOCK_1824: 'SSE 100',
    STOCK_1825: 'SSE Mega-cap',
    STOCK_1826: 'SSEFinancial',
    STOCK_1827: 'SSE Consumer',
    STOCK_1828: 'SSE Dividend',
    STOCK_1829: 'SSE 380',
    STOCK_1831: 'Business',
    STOCK_1832: 'SZSE Culture',
    STOCK_1833: 'KweichowMoutai',
    STOCK_1834: 'CSI Media',
    STOCK_1835: 'CCTV Culture',
    STOCK_1836: 'Yili Group',
    STOCK_1837: 'Bestore',
    STOCK_1838: 'LuzhouLaojiao',
    STOCK_1839: '3 Squirrels',
    STOCK_1840: 'Yanker Shop ',
    STOCK_1851: 'Spot goldUSA',
    STOCK_1852: 'SpotPlatinum',
    STOCK_1854: 'spot gold CN',
    STOCK_1861: 'Joyoung',
    STOCK_1862: 'BDStar',
    STOCK_1863: 'BearElectric',
    STOCK_1871: 'CAC40',
    STOCK_1872: 'BEL20',
    STOCK_1881: 'Microsoft',
    STOCK_1882: 'Apple',
    STOCK_1883: 'Amazon',
    STOCK_1884: 'NASDAQ',
    STOCK_1885: 'NASDAQ 100'
  },
  vie: {
    DEPOSIT: 'Gửi tiền',
    IMMEDIATE_LOGIN: 'Đăng nhập ngay',
    WITHDRAWAL: 'Rút tiền',
    CANCEL: 'Hủy bỏ',
    CONFIRM: 'Xác nhận',
    CONFIRM2: 'Xác nhận',
    FINISH: 'Kết thúc',
    SING_OUT: 'Đăng xuất',
    CONTINUE: 'Tiếp tục',
    CLOSE: 'Khép kín',
    SEARCH: 'Hỏi thăm',
    COPY: 'copy',
    SUBMIT: 'Gửi đi',
    SHOW_ALL: 'Tất cả các bản ghi đã được hiển thị',
    NEW_MESSAGE: 'Xem dữ liệu mới tại đây',
    NO_RECORD: 'Không có hồ sơ',
    FORGOT_PASSWORD: 'Quên mật khẩu',
    PASSWORD: 'Mật khẩu',
    PLEASE_AGAIN: 'Vui lòng thử lại',
    RE_ENTER: 'Nhập lại',
    YES: 'đúng',
    NO: 'Không',
    UNLOCK: 'Không bị khóa',
    DISABLED: 'Vô hiệu hóa',
    LOCKED: 'Bị khóa',
    RESTRICTED: 'Hạn chế',
    REVISE: 'Ôn lại',
    REMARK: 'Nhận xét',
    DELETE: 'Xóa bỏ',
    PROCESS_DONE: 'Hoạt động thành công',
    WAIT_A_MINUTE: 'Vui lòng chờ...',
    PASSWORD_ERROR: 'Sai mật khẩu',
    YOUR_TEAM_BANNED: 'Nhóm của bạn bị tắt tiếng',
    YOUR_TEAM_BANNED_FOREVER: 'Bạn đã bị tắt tiếng vĩnh viễn',
    YOUR_TEAM_BANNED_ONEDAY: 'Bạn đã bị cấm trong một ngày',
    YOUR_TEAM_BANNED_ONEWEEK: 'Bạn đã bị cấm trong một tuần',
    YOUR_TEAM_BANNED_ONEHOUR: 'Bạn đã bị tắt tiếng trong một giờ',
    YOUR_TEAM_BANNED_HALFHOUR: 'Bạn đã bị tắt tiếng trong nửa giờ',
    AXIOS_STATE_OVER_TIME_ERROR: 'Lỗi hết thời gian trả lời',
    AXIOS_STATE_NET_ERROR: 'Mạng không bình thường, vui lòng thử lại',
    AXIOS_STATE_LONG_TIME: 'Do đã lâu không hoạt động nên bạn đã tự động đăng xuất và cần đăng nhập lại',
    AXIOS_STATE_LOCALNET_ERROR: 'Mạng hiện tại không khả dụng, vui lòng kiểm tra mạng và thử lại',
    NICKNAME_LIMIT: 'Biệt hiệu có thể chứa tối đa 5 ký tự tiếng Nhật và tiếng Trung hoặc ít hơn 10 ký tự ở các ngôn ngữ khác',
    CONGRATULATION: 'Chúc mừng',
    FLLOW_DATE: 'Dõi X thời gian',
    INJECTION: 'Cược',
    PERSON: 'Người',
    CONFIRM_BANKCARD_NUMBER: 'Xác nhận số thẻ',
    CONFIRM_BANKCARD_NAME: 'Xác nhận tên tài khoản',
    VERIFY_BANKCARD_REMINDER: 'Tăng tính bảo mật cho tài khoản, lấy lại mật khẩu tài khoản nhanh chóng',
    NOTIFY_CUSTOM_HUGE_WITHDRAW: 'Vui lòng mở kênh số tiền lớn trước khi rút tiền',
    NOTIFY_CUSTOM_LITTLE_WITHDRAW: 'Vui lòng mở một kênh số tiền nhỏ và sau đó rút tiền',
    // 用戶
    ACCOUNT_USER: 'Cá nhân',
    ACCOUNT_ANNOUNCEMENT: 'Sự thông báo',
    ACCOUNT_PERSONAL_INFORMATION: 'Hồ sơ',
    ACCOUNT_PROFIT_LOSS_TODAY: 'Lãi và lỗ hôm nay',
    ACCOUNT_AGENCY_CENTER: 'Trung tâm đại lý',
    ACCOUNT_MESSAGE_CENTER: 'Trung tâm tin nhắn',
    ACCOUNT_HELP_GUIDE: 'Trợ giúp chỉ dẫn',
    ACCOUNT_GUIDE_MEMBER: 'Hướng dẫn tài khoản',
    ACCOUNT_INVESTMENT_GUIDE: 'Hướng dẫn đầu tư',
    ACCOUNT_ABOUT_US: 'Về chúng tôi',
    ACCOUNT_MY_ACCOUNT: 'Tài khoản của tôi',
    ACCOUNT_OVERAGE: 'Thăng bằng',
    ACCOUNT_MANAGEMENT: 'Quản lý an toàn',
    ACCOUNT_VIRTUAL_WALLET: 'Ví ảo',
    ALERT_NETWORK_ERROR_TEXT: 'Mạng không bình thường, vui lòng làm mới lại',
    ACCOUNT_NEW_MESSAGE: 'Xem dữ liệu mới tại đây',
    ACCOUNT_PRIVATE_LETTER: 'Thư riêng',
    // 交易頁面用可能會有共用
    TRADE_HOT: 'Phổ biến',
    TRADE_ASIA: 'Đĩa châu á',
    TRADE_EUROPE: 'Đĩa Châu Âu',
    TRADE_NORTH_AMERICA: 'Đĩa Bắc Mỹ',
    TRADE_SOUTH_AMERICA: 'Nam Mỹ mảng',
    TRADE_OCEANIA: 'Đĩa châu đại dương',
    TRADE_VIRTUALCURRENCY: 'Tiền ảo',
    TRADE_STOP_TRADING: 'Ngừng giao dịch',
    TRADE_EXPECT: 'kỳ',
    TRADE_UNIT: 'đơn vị',
    TRADE_NUMBERPERIODS: 'Số kỳ',
    TRADE_DOUBLED: 'Kép',
    TRADE_MULTIPLE: 'Kép',
    TRADE_TIPS: 'Lời khuyên',
    TRADE_TOTAl: 'Toàn bộ',
    TRADE_STOCK: 'Báng súng',
    TRADE_DOLLAR: 'đồng',
    TRADE_MAX_INCOME: 'Năng suất cao nhất',
    TRADE_CONTINUE_INVEST: 'Tiếp tục đầu tư',
    TRADE_SHARE_ORDER: 'Chia sẻ',
    TRADE_BUY: 'Mua trong',
    TRADE_SHIPPED: 'Lô hàng',
    TRADE_OPEN_TODAY: 'Mở ',
    TRADE_PRICING: 'Thay đổi',
    TRADE_PRICING2: 'Thăng trầm',
    TRADE_OPEN: 'Lô hàng',
    TRADE_VOLUME: 'Âm lượng',
    TRADE_MAX: 'Cao nhất',
    TRADE_MIN: 'Thấp nhất ',
    TRADE_ODD: 'Odd',
    TRADE_DOUBLE: 'Even',
    TRADE_CHAMPION: 'Quán quân',
    TRADE_FALL: 'odds',
    TRADE_RUNNER: 'Về nhì',
    TRADE_PAR_VALUE: 'Số tiền trên mỗi cổ phiếu',
    TRADE_CHASE_NUMBER: 'Đuổi theo số',
    TRADE_PROFIT_STOP: 'Kết thúc',
    TRADE_PROFIT_CYCLE: 'Chu kỳ',
    TRADE_DOUBLE_SIDE: 'HAI PHÍA',
    TRADE_POSITIONING: 'VỊ TRÍ',
    TRADE_SINGLE_STRAND: 'Odd',
    TRADE_DOUBLE_STRAND: 'Even',
    TRADE_BULL_MARKET: 'Bull',
    TRADE_BEAR_MARKET: 'Bear',
    TRADE_INVESTING: 'Đang đầu tư',
    TRADE_INSUFFICIENT_BALANCE: 'Thiếu cân bằng',
    TRADE_TAKE_A_BEATING: 'Đầu tư thất bại',
    TRADE_I_SEE: 'Tôi hiểu rồi',
    TRADE_ACTION_SHEET_PLAYTYPE_TEXT: 'Cách chơi',
    TRADE_UP: 'Trỗi dậy',
    TRADE_DOWN: 'Ngã',
    TRADE_DALLAR: 'Đồng',
    TRADE_DIME: 'Dime',
    TRADE_CENT: 'Cent',
    TRADE_INPUTBASE: 'đơn vị:đồng',
    TRADE_UNCHANGED: 'Tie',
    TRADE_MOST: 'Phần lớn',
    TRADE_TIMES: 'Kép',
    TRADE_LIST_TAB_TITLE_BASE: 'Ghi chung',
    TRADE_LIST_TAB_TITLE_CHASE: 'Đuổi theo số ',
    TRADE_MOST_EXPECT: 'Phần lớn X Thời gian',
    //
    HOME_ICONBTN_BONUS: 'Đồ thị',
    HOME_ICONBTN_GUIDE: 'Cứu giúp',
    HOME_ICONBTN_RANK: 'Xếp hạng',
    HOME_ICONBTN_HOT: 'Đẩy nóng',
    HOME_ICONBTN_APP: 'APP',
    HOME_ICONBTN_SERVICE: 'Dịch vụ',
    HOME_NEWS_TITLE: 'Kinh tế quốc tế',
    HOME_NEWS_MOREBTN: 'more',
    HOME_NEWS_DEF_TITLE1: '股价遭低估！券商看好货柜船竞争力、日3大海运股齐飙',
    HOME_NEWS_DEF_SOURCE1: 'MoneyDJ新闻',
    HOME_NEWS_DEF_TITLE2: '股价遭低估！券商看好货柜船竞争力、日3大海运股齐飙',
    HOME_NEWS_DEF_SOURCE2: 'MoneyDJ新闻',
    HOME_NEWS_DEF_TITLE3: '股价遭低估！券商看好货柜船竞争力、日3大海运股齐飙',
    HOME_STOCK_TITLE: 'Cổ phiếu phổ biến',
    HOME_GETNAME_TEXT: 'Index',
    HOME_GETUSERINFO_TEXT: 'Thông tin tài khoản đã được làm mới, tài khoản hiện tại là',
    HOME_GETNOTICELIST_TEXT: 'Chào mừng đến với %{msg}',
    /**
     *APP下載頁面
     */
    HOME_APPDOWNLOAD_HEADER_TITLE: 'Tải xuống APP',
    HOME_APPDOWNLOAD_MAINTEXT: 'Tải ứng dụng Không cần nhập URL nữa',
    HOME_APPDOWNLOAD_ANDROID_BTN: 'Tải xuống phiên bản APP Android',
    HOME_APPDOWNLOAD_IOS_BTN: 'Tải xuống phiên bản APP IOS',
    HOME_APPDOWNLOAD_IOSDISKTOP_BTN: 'Tải xuống phiên bản dành cho máy tính iOS',
    HOME_APPDOWNLOAD_PROMPT: 'Một số trình duyệt có thể không được cài đặt, bạn nên sử dụng trình duyệt Safari để cài đặt',
    HOME_APPDOWNLOAD_PROMPT_TEXT: 'Bấm cài đặt',
    HOME_APPDOWNLOAD_ACTION_IOSTITLE: 'Phiên bản APP IOS',
    HOME_APPDOWNLOAD_ACTION_ANDROIDTITLE: 'Phiên bản APP Android',
    HOME_APPDOWNLOAD_ACTION_VERSION: 'Phiên bản',
    HOME_APPDOWNLOAD_TEXT: 'Hoặc chọn',
    HOME_APPDOWNLOAD_IOS_STEP_TEXT: 'Quá trình cài đặt hoàn tất',
    /**
     *排行版頁面
     */
    HOME_RANK_TITLE: 'Xếp hạng đầu tư TOP10',
    HOME_RANK_TEXT1: 'Đếm những người dùng có lợi nhất trong ngày hôm qua,hôm nay',
    HOME_RANK_TEXT2: '05: 00 ~ 06: 00 Mới nướng',
    HOME_RANK_USER_TITLE: 'Chức vụ',
    HOME_RANK_POPUP_CUMULATIVE_PROFIT: 'Lợi nhuận tích lũy',
    HOME_ANNOUNCEMENT_TEXT: 'Kiểm tra thêm',
    HOME_BECOME_VIP: 'Chúc mừng bạn đã thăng cấp thành công, cấp độ hiện tại là VIP X, hãy nhanh chân đến trung tâm hoạt động để nhận thưởng.',
    HOME_RECEIVE_AWARD: 'Nhận giải thưởng',
    HOME_CONGRATULATIONS: 'Xin chúc mừng',
    /**
     *交易頁面
     */
    TRADE_STOCK_INPUT_SEARCH: 'Nhập tên chỉ số chứng khoán',
    TRADE_STOCK_TRADEMAIN_RECORD_BTN: 'Mua bản ghi',
    TRADE_STOCK_TRADEMAIN_BUY_BTN: 'Order',
    TRADE_STOCK_POPUP_SUCCESSFUL_TEXT1: 'Đầu tư thành công',
    TRADE_STOCK_POPUP_SUCCESSFUL_TEXT2: 'Bạn có thể nhấp để chia sẻ danh sách đặt cược hoặc',
    TRADE_STOCK_CHART_TODAY_K_BTN: 'Ngày',
    TRADE_STOCK_CHART_WEEK_K_BTN: 'Tuần',
    TRADE_STOCK_CHART_MOON_K_BTN: 'Tháng',
    TRADE_STOCK_TIMEBAR_STAES_CLOSED: 'Đã đóng cửa',
    TRADE_STOCK_TIMEBAR_ORDER_ERROR: 'Lỗi thứ tự thanh thời gian',
    TRADE_STOCK_TIMEBAR_ORDER_ERROR1: 'Lỗi thứ tự thanh thời gian',
    TRADE_STOCK_LATESTRESULT_NOTIMSG1: 'Nhắc bạn:',
    TRADE_STOCK_LATESTRESULT_NOTIMSG2: 'Kỳ %{num} đã kết thúc, số kỳ hiện tại',
    TRADE_STOCK_SETKTYPE_TITLE: 'Ping An Index giá cổ phiếu lịch sử',
    TRADE_STOCK_CONFIRMBET_ERROR: 'Lỗi xác minh loại đầu tư',
    TRADE_STOCK_CONFIRMBET_TYPE_ERROR_REFRESH: 'Loại đầu tư đã thay đổi, khoản đầu tư của bạn không thành công và trang này sẽ sớm được làm mới cho bạn',
    TRADE_STOCK_CONFIRMBET_UNIT_ERROR_REFRESH: 'Có một sự thay đổi trong đơn vị điểm chuẩn đầu tư và khoản đầu tư của bạn không thành công, trang này sẽ sớm được làm mới cho bạn',
    TRADE_STOCK_ACTIVE_CHAMPION_STATE: 'Rất tiếc, quán quân và á quân chỉ được chọn tối đa 6 số',
    TRADE_STOCK_NUMERIC_KEYBOARD_ALERT_TEXT: 'Số tiền đầu tư tối đa là',
    TRADE_STOCK_CONFIRMHANDLER_TYPE1_TEXT: 'Chọn ít nhất 1 số từ các vị trí quán quân và á quân, và tối đa 6 số mỗi vị trí. Số được chọn phù hợp với chỉ số đi ở cùng vị trí.',
    TRADE_STOCK_CONFIRMHANDLER_TYPE1_TITLE: 'VỊ TRÍ quy tắc',
    TRADE_STOCK_CONFIRMHANDLER_TYPE0_TEXT: 'Quy tắc mua: Hai chữ số sau dấu thập phân của chỉ số thị trường là giá trị chuẩn, chữ số đầu tiên là số vô địch và số thứ hai là số á quân. Khi mỗi số tương ứng lớn hơn 4 (không bao gồm 4) là "Trỗi dậy", nhỏ hơn 4 (gồm 4) là "Ngã", các số lẻ là "Odd" và các số chẵn là "Even". Bò đực và gấu quy định PK lớn. Khi nhà vô địch lớn hơn người về nhì, đó là "Bull" và nhỏ hơn là "Bear". Khoản đầu tư hiện tại không thể đầu tư vào sự thăng trầm, lẻ và chẵn, bò và gấu cùng một lúc. Khi nhà vô địch và á quân hiển thị cùng một số với "Tie", tiền gốc sẽ được hoàn trả.',
    TRADE_STOCK_CONFIRMHANDLER_TYPE0_TITLE: 'HAI PHÍA quy tắc',

    TRADE_STOCK_NOTIFY_TEXT: '%{bet}Cược  %{power}Kép %{chase}Thời gian',
    TRADE_STOCK_MAX_CHASE_NUMBER: 'Số có thể theo dõi nhiều nhất',
    TRADE_STOCK_RIGHT_TEXT: 'Nhấp vào mũi tên trái ở góc trên bên phải để xem hồ sơ đầu tư',
    TRADE_STOCK_NO_LOGIN: 'Người dùng chưa đăng nhập',
    TRADE_STOCK_STOP_SELL: 'Ngừng giao dịch',
    TRADE_STOCK_IS_STOP: '0123456789预休出等',
    TRADE_STOCK_NOW_CLOSE_ORDER: 'Con dấu hiện tại',
    TRADE_STOCK_NOW_WAIT_OPEN_ORDER: 'Chờ đợi sự bắt đầu',
    /**
     *銀行卡
     */
    NOBIND_SAFEPASSWORD_AND_BANKCARD: 'Bạn chưa đặt mật khẩu bảo mật và thẻ ngân hàng bị ràng buộc và không thể rút tiền. Bạn có muốn đặt mật khẩu bảo mật trước không?',
    NOBIND_BANKCARD: 'Bạn chưa ràng buộc thẻ ngân hàng và không thể rút tiền. Bạn có muốn ràng buộc thẻ ngân hàng trước không?',
    // 個人資料
    PERSONAL_RANK_TITLE: 'Xếp hạng',
    PERSONAL_SAFE_FINISH: 'Chứng nhận an toàn đã hoàn thành',
    PERSONAL_SAFTEY_NOT_COMPLETED: 'Xác thực bảo mật chưa hoàn tất.',
    PERSONAL_NICK_NAME: 'Tên nick',
    PERSONAL_ACCOUNT: 'Tài khoản',
    PERSONAL_PHONE: 'Số điện thoại',
    PERSONAL_QQ: 'Tài khoản QQ',
    PERSONAL_NO_DATA: 'không có dữ liệu',
    PERSONAL_NO_BIND: 'Không ràng buộc',
    PERSONAL_NO_SETING: 'Riêng',
    PERSONAL_SEX: 'Giới tính',
    PERSONAL_BIRTHDAY: 'Ngày sinh nhật',
    PERSONAL_THEME: 'Chủ đề',
    LANGUAGE: 'Ngôn ngữ',
    CHINESE: 'Người Trung Quốc',
    JAPANESE: 'Tiếng Nhật',
    VIETNAMESE: 'Tiếng Việt',
    PERSONAL_LOADING: 'Đang tải',
    PERSONAL_SET_NICK_NAME: 'Đặt biệt hiệu',
    PERSONAL_SET_PHONE: 'Đặt số điện thoại di động',
    PERSONAL_SET_QQ: 'Đặt số QQ',
    PERSONAL_NOT_SET: 'Riêng',
    PERSONAL_MAN: 'Nam giới',
    PERSONAL_OTHER: 'Khác',
    PERSONAL_WONAN: 'Giống cái',
    PERSONAL_DARK: 'Đêm',
    PERSONAL_LIGHT: 'Soi rọi',
    PERSONAL_SET_IMG: 'Đặt hình đại diện',
    PERSONAL_CHOESE_IMG: 'Chọn hình đại diện',
    PERSONAL_SAVE: 'lưu',
    // 認證流程
    SAFE_PROCESS: 'Quy trình xác thực',
    SAFE_IDENTITY_CONFIRM: 'Xác nhận danh tính',
    SAFE_MESSAGE: 'Theo chính sách KYC và AML, bạn phải vượt qua quy trình xác minh. Điều này là cần thiết để đảm bảo an toàn cho tiền của bạn và tăng tốc độ rút tiền.',
    SAFE_LOGING_PASSWORD: 'Đặt mật khẩu đăng nhập',
    SAFE_PASSWORD: 'Đặt mật khẩu an toàn',
    SAFE_SECRET_SECURITY: 'Đặt câu hỏi bí mật',
    SAFE_GO_SAFE_CENTER: 'Đi đến trung tâm an toàn',
    // 認證流程-action
    ACTION_NEW_PASSWORD: 'Vui lòng đặt một mật khẩu an toàn mới',
    ACTION_MESSAGE_DOT: 'Mật khẩu bảo mật là một số gồm 6 chữ số, được sử dụng cho các hoạt động như rút tiền, thiết lập bảo vệ bí mật, thêm thẻ ngân hàng, v.v. để đảm bảo an toàn cho tiền',
    ACTION_PASSWORD_NOT_SIMPLE: 'Mật khẩu bảo mật không được quá đơn giản. Không được đặt các mật khẩu đơn giản như: 123456, 123123, 654321, 111111, v.v.',
    ACTION_ENTER_OLD: 'Vui lòng nhập mật khẩu bảo mật cũ',
    ACTION_NOT_SAME: 'Hai mục nhập mật khẩu không nhất quán',
    ACTION_MESSAGE: 'Mật khẩu bảo mật là một số gồm 6 chữ số được sử dụng để rút tiền, thiết lập bảo vệ bí mật, thêm thẻ ngân hàng và các hoạt động khác để đảm bảo an toàn cho tiền',
    ACTION_SIX_PASSWORD: 'Mật khẩu 6 chữ số mới',
    ACTION_OLD_PASSWORD: 'Mật khẩu cũ 6-16',
    ACTION_SURE_UPDATE: 'Có từ bỏ việc thay đổi mật khẩu bảo mật',
    ACTION_CONFIRM_PASSWORD: 'Hành động xác minh mật khẩu an toàn',
    ACTION_NOT_SET_PASSWORD: 'Bạn chưa đặt mật khẩu bảo mật, vui lòng đặt mật khẩu bảo mật trước',
    ACTION_AGAIN_CONFIRM: 'Vui lòng điền lại mật khẩu bảo mật để xác nhận',
    ACTION_VERIFY_ID: 'Vui lòng điền mật khẩu bảo mật để xác minh danh tính của bạn',
    // 等級頭銜
    RANK_GROWTH_POINTS: 'Điểm tăng trưởng',
    RANK_PROMOTION_INSTRUCTIONS: 'Hướng dẫn nâng cấp',
    RANK_CAPTION_BEFORE: 'Khoảng cách đến cấp độ tiếp theo',
    RANK_CAPTION_AFTER: 'Điểm, cộng 1 điểm cho mỗi 1 nhân dân tệ ký gửi',
    RANK_MECHANISM: 'Cơ chế xếp hạng',
    RANK_GRADE: 'Xếp hạng',
    // 福利活動
    LEVELUP_PRIZE: 'Level Up Bonus',
    DAILY_PRIZE: 'Daily bonus plus',
    SIGNIN_PRIZE: 'Check-in bonus',
    PRIZE_GOTTEN: 'Tôi có một phần thưởng.',
    AWARD_GOTTEN: 'Nhận tiền thưởng',
    UN_LOGIN: 'Tôi vẫn chưa đăng ký.',
    TODAY_PRIZE: 'Tiền thưởng hôm nay',
    IMMEDIATE_GET: 'Nhận ngay bây giờ',
    CANT_GET: 'Không thể nhận',
    ALREADY_GET: 'Nhận',
    YESTERDAY_INVESTMENT: 'Hôm qua',
    CURRENT_GRADE: 'Mức hiện tại',
    ADDITIONAL_RATE: 'Tiền thưởng cộng với tỷ lệ',
    GET_SUCCESSFULLY: 'Việc nhận đã thành công.',
    ACTIVITY_NOTE: 'Mô tả sự kiện',
    DAILY_NOTE_1: 'Bạn có thể nhận nó sau 00:20 sáng.',
    DAILY_NOTE_2: 'Tỷ lệ tiền thưởng dựa trên cấp độ thành viên và số tiền cá cược tích lũy ngày hôm qua.',
    DAILY_NOTE_3: 'Hủy đơn hàng và các cược không hợp lệ khác không được tính.',
    DAILY_NOTE_4: 'Nếu bạn không lấy nó trước 24:00 trong ngày, nó sẽ tự động bị mất.',
    PROMOTION_RULES: 'Quy tắc thăng cấp',
    PROMOTION_NOTE_1: 'Có phần thưởng khi lên cấp.',
    PROMOTION_NOTE_2: 'Điểm tăng trưởng = số tiền phí',
    ACCUMULATED_POINTS: 'Điểm tăng trưởng',
    PROMOTION_PRIZE: 'Normal',
    SKIP_PRIZE: 'Skip',
    LEVELUP_PRIZE_NOTE: 'Có phần thưởng khi lên cấp.',
    DAILY_PRIZE_NOTE: 'Nó được trả theo số tiền đặt cược ngày hôm qua và không có giới hạn tiền thưởng',
    SIGNIN_PRIZE_NOTE: 'Đăng ký hàng ngày để tận hưởng nhiều đặc quyền và ưu đãi hơn',
    NOT_AVAILABLE_YET: 'Các điều kiện mua lại vẫn chưa đạt được.',
    NOT_SIGNIN_YET: 'Tôi vẫn chưa đăng nhập.',
    ACTIVITY_OVER: 'Sự kiện kết thúc',
    ALREADY_SIGNIN: 'Tôi đã đăng nhập.',
    NOT_FINISHED_YET: 'Tôi vẫn chưa đạt được mục tiêu của mình.',
    IMMEDIATE_SIGNIN: 'Đăng nhập ngay',
    SIGNIN_TOTAL_DAYS: 'Ngày',
    SIGNIN_PRIZE_REMINDER: 'Đăng nhập trong 30 ngày liên tục và tận hưởng cơ hội nhân đôi',
    SIGNIN_RULES: 'Quy tắc đăng ký',
    SIGNIN_DAYS: 'Ngày nhận phòng',
    SIGNIN_BONUS: 'Phần thưởng',
    SIGNIN_INVESTMENT_NEEDED: 'Yêu cầu đầu tư',
    DAY_NUMBER: 'Ngày {number}',
    DAILY_SIGN_IN: 'Nhận phòng hàng ngày',
    BOUNS_ACTIVITY: 'Hiển thị các sự kiện mới tại đây',
    // 维护页面
    ONLINE_CUSTOM_SERVICE: 'Dịch vụ trực tuyến',
    WEB_MAINTAINING: 'Bảo trì trang web',
    MAIN_NOTE_1: 'Bảo trì mọi lúc',
    MAIN_NOTE_2: 'Để cung cấp dịch vụ tốt hơn',
    SYSTEM_MAINTAINED: 'Trong quá trình bảo trì hệ thống',
    WEB_TIME_FIXED: 'Bảo trì sẽ được hoàn thành trong %{msg}.',
    // 登入页面
    LOGIN_TO: 'Đăng nhập %{name}',
    LOGIN_NOTE: 'Chào mừng bạn đến với %{name}, đầu tư ngay bây giờ và nhận được nhiều lợi nhuận hơn.',
    WELCOME_TO: 'Chào mừng bạn đến với %{name}',
    WELCOME_NOTE: 'Chúng tôi vì khách hàng mà tạo ra lợi nhuận',
    MILLION_RMB: 'triệu nhân dân tệ',
    YEAR: 'Năm',
    BUSINESS_NOTE_1: 'Cho đến tháng thứ , lợi nhuận của khách hàng đạt 554 triệu NDT.',
    JOIN_US: 'Chúng ta sẽ tham gia',
    JOIN_NOTE: 'Đầu tư với khách hàng toàn cầu',
    BUSINESS_NOTE_2: 'triệu khách hàng',
    BUSINESS_NOTE_3: 'Đến tháng thứ  đạt 4.917.200 người.',
    BUSINESS_GLOBAL_MARKET: 'Giao dịch thị trường toàn cầu',
    BUSINESS_NOTE_4: 'Bạn có thể đầu tư vào tiền tệ, hàng hóa, cổ phiếu, tiền điện tử và hơn thế nữa',
    BUSINESS_NOTE_5: 'triệu đơn đặt hàng',
    BUSINESS_NOTE_6: 'Nó đạt 490,13 triệu đơn đặt hàng cho đến tháng .',
    INTEGRITY_FIRST: 'Sự chân thành',
    SAFE_AND_TRUSTABLE: 'An toàn, tin cậy, tin cậy',
    BUSINESS_NOTE_7: 'tỷ giao dịch',
    BUSINESS_NOTE_8: 'Giao dịch đạt 490,09 NDT cho đến tháng .',
    REGISTER: 'Đăng ký',
    LOGIN: 'Đăng nhập',
    BUSINESS_NOTE_9: 'Đầu tư với khách hàng toàn cầu và nhận được lợi nhuận cao?',
    ACCOUNT_REMINDER: 'Đặt ID từ 4 đến 16 chữ số.',
    PASSWORD_REMINDER: 'Đặt mật khẩu từ 6 đến 16 chữ số.',
    INVITATION: 'Mã mời',
    INVITATION_REMINDER: 'Vui lòng nhập mã mời gồm 8 chữ số.',
    QQ: 'Tài khoản QQ',
    QQ_REMINDER: 'Vui lòng thiết lập một tài khoản QQ',
    PHONE_NUMBERS: 'Số điện thoại di động',
    PHONE_REMINDER: 'Vui lòng đặt số điện thoại di động của bạn',
    IMMEDIATE_LOGIN_NOTE: 'Bấm vào đây nếu bạn có tài khoản',
    REGISTER_NOTE_1: 'Đặt mật khẩu mạnh để bảo mật tài khoản.',
    ALIEMPTYREMINDER: 'Vui lòng trượt mã xác minh',
    VERIFY_1: 'Vui lòng giữ thanh trượt và kéo sang bên phải.',
    VERIFY_2: 'Đã xác minh',
    VERIFY_3: 'Rất tiếc, đã xảy ra sự cố, hãy nhấn <a href="javascript:__nc.reset()">làm mới</a> và thực hiện lại',
    VERIFY_4: 'Mạng không mạnh, vui lòng nhấp để <a href="javascript:__nc.reset()">làm mới</a>',
    REGISTER_NOTE_2: 'Nếu bạn sử dụng tạo mật khẩu, đừng quên mật khẩu được tạo tự động.',
    FORBID_ACCOUNT_PASSWORD_SAME: 'Bạn không thể đăng ký một chuỗi ký tự có cùng ID đăng nhập và mật khẩu.',
    FORBID_SIMPLE_PASSWORD: 'Không đặt một chuỗi quá đơn giản và ID đăng nhập và mật khẩu không phải là cùng một chuỗi. Không đặt các mật khẩu đơn giản như 123456, 123123, 654321, 1111111.',
    // 快速钱包
    TODAY_INVESTMENT: 'Sự đầu tư',
    TODAY_PROFIT: 'Lợi nhuận',
    TODAY_ACTIVITY: 'Sự kiện',
    TODAY_REBATE: 'Hạ giá',
    TODAY_DEPOSIT: 'Thanh toán',
    TODAY_WITHDRAW: 'Rút tiền',
    /**
     * API ERROR CODE
     */
    INTERNET_PAY: 'Ngân hàng ròng',
    BANK_TRANSFER: 'chuyển khoản ngân hàng',
    ALIPAY: 'Alipay',
    WECHAT_PAY: 'WeChat Pay',
    DIGITALCURRENCY_PAY: 'Tiền kỹ thuật số',
    QQ_WALLET: 'Ví QQ',
    FOURTH_PAY: 'Thanh toán của bên thứ tư',
    UNION_PAY: 'Thanh toán UnionPay',
    CLOUD_PAY: 'Unionpay',
    JD_PAY: 'Ví Jin Dong',
    INVEST_ORDER_CANCEL: 'Hủy đơn hàng',
    CHASE_STOP: 'Theo dõi điểm dừng',
    AGENT_REBATE: 'Hạ giá',
    PROFIT_DISTRIBUTE: 'Phân bổ',
    DEPOSIT_DISCOUNT: 'Ưu đãi',
    PROFIT_RE_SEND: 'Đền bù',
    SYSTEM_CANCEL_ORDER: 'Tôi đã hủy đơn đặt hàng của hệ thống.',
    ENVELOPE_WITHDRAW: 'Nhận quà',
    ENVELOPE_REFUND: 'Trả lại quà tặng',
    TRANSFER_GET: 'Nhận chuyển giao',
    TRANSFER_REFUND: 'Chuyển lại',
    DRAW_REFUND: 'Hoàn tiền ở Thái Lan',
    ACTIVITY_ENVELOPE: 'Quà tặng sự kiện',
    TIPS_DEDUCTION: 'Khấu trừ giải thưởng',
    ENVELOPE_DEDUCTION: 'Giảm trừ quà tặng',
    TRANSFER_DEDUCTION: 'Chuyển khoản khấu trừ',
    THIRD_PARTY_WITHDRAW_DEDUCTION: 'Tôi đã trừ khoản thanh toán trước khi rút tiền.',
    ARITIFICIAL_DEPOSIT: 'Tiền gửi thủ công',
    ARITIFICIAL_DEPOSIT_2: 'Tiền gửi thủ công',
    OTHER_DISCOUNT: 'Ân huệ khác',
    ERROR_WITHDRAW: 'Lỗi khi rút tiền',
    ADMIN_WITHDRAW: 'Rút tiền của quản trị viên',
    PROFIT_WITHDRAW: 'Rút tiền lãi',
    WITHDRAW_HANDLE_FEE: 'Phí rút tiền',
    THIRD_PARTY_WITHDRAW_REJECT: 'Thanh toán trước khi rút tiền đã bị từ chối.',
    HANDLE_FEE_REFUND: 'Hoàn phí',
    VIRTUAL_WALLET_PAY: 'Thanh toán bằng ví',
    REJECT_WITHDDRAW_TO_VIRTUAL_WALLET: 'Việc rút tiền đã bị từ chối vào ví.',
    VIRTUAL_WALLET_SETTLE_REFUND: 'Hoàn lại tiền thanh toán qua Wallet',
    NOLOGIN: 'Chưa đăng nhập',
    SUCCESS: 'Hoạt động thành công',
    SUCCESS_GET: 'Đạt được thành công',
    SUCCESS_QUERY: 'Tìm kiếm thành công',
    SUCCESS_SAVE: 'đã lưu thành công',
    SUCCESS_INSERT: 'Thêm thành công',
    SUCCESS_UPDATE: 'Hoàn thành cập nhật',
    SUCCESS_DELETE: 'đã xóa thành công',
    FAIL: 'lỗi hệ thống',
    FAIL_GET: 'Nhận thất bại',
    FAIL_QUERY: 'Truy vấn không thành công',
    FAIL_SAVE: 'Lưu không thành công',
    FAIL_INSERT: 'Thêm không thành công',
    FAIL_UPDATE: 'Cập nhật không thành công',
    FAIL_DELETE: 'không xóa được',
    FAIL_BAD_PARAMS: 'Tham số sai',
    FAIL_LACK_PARAMS: 'Tham số bị thiếu',
    FAIL_ILLEGAL_PARAMS: 'Tham số không hợp lệ',
    ERROR: 'Lỗi hệ thống. Xin thử lại sau',
    ERROR_NOAUTH: 'Không có cơ quan điều hành',
    ERROR_PW: 'Sai mật khẩu',
    ERROR_SAFETY_PW: 'Lỗi mật khẩu bảo mật',
    ERROR_AC_OR_PW: 'Tên đăng nhập hoặc mật khẩu không chính xác',
    ERROR_MASSAGE: 'Để bảo mật tài khoản, vui lòng liên hệ với chúng tôi và thực hiện các thay đổi',
    ERROR_OPERATE: 'Hoạt động bất thường',
    ERROR_UNKNOWN: 'Hệ thống bất thường',
    TYPE_NOT_NULL: 'Loại không được để trống',
    TYPE_NOT_FOUND: 'Không có loại như vậy',
    ACCOUNT_NOT_FOUND: 'Tài khoản này không tồn tại',
    USR_NOT_FOUND: 'Người dùng không tồn tại',
    USR_001: 'Định dạng tài khoản không chính xác',
    USR_002: 'Định dạng mật khẩu sai',
    USR_003: 'Bạn đã bị hạn chế đăng nhập',
    USR_004: 'Tài khoản của bạn đã được thiết lập để bảo vệ đăng nhập, vui lòng sử dụng IP được chỉ định để đăng nhập',
    USR_005: 'Mật khẩu không hợp lệ, hãy liên hệ với dịch vụ khách hàng trực tuyến để sửa đổi',
    USR_006: 'Tài khoản của bạn đã bị đóng băng, vui lòng liên hệ với bộ phận chăm sóc khách hàng để biết thêm chi tiết',
    USR_007: 'Mật khẩu đăng nhập của bạn giống với tài khoản của bạn và có rủi ro về bảo mật. Vui lòng thay đổi lại mật khẩu đăng nhập.',
    USR_008: 'Mật khẩu đăng nhập của bạn quá đơn giản và có nguy cơ bảo mật. Vui lòng thay đổi lại mật khẩu đăng nhập.',
    USR_009: 'đăng nhập thành công',
    USR_010: 'Cấm đăng nhập giống như tài khoản',
    USR_011: 'Số tài khoản chỉ có thể chứa số và tiếng Anh',
    USR_012: 'Độ dài tài khoản bất hợp pháp',
    USR_013: 'Vui lòng điền số điện thoại của bạn',
    USR_014: 'Số điện thoại không đúng định dạng',
    USR_015: 'Vui lòng điền vào QQ',
    USR_016: 'Lỗi định dạng QQ',
    USR_017: 'Mã lời mời sai hoặc đã hết hạn',
    USR_018: 'Tài khoản đã được đăng ký',
    USR_019: 'Số điện thoại đã được đăng ký',
    USR_020: 'Qq đã được đăng ký',
    USR_021: 'Giảm giá cho mã lời mời không chính xác và đã hết hạn',
    USR_022: 'đăng ký thất bại',
    USR_023: 'đăng ký thành công',
    USR_024: 'Người chơi không thể tạo mã mời',
    USR_025: 'Mã lời mời được tạo trên trang web này đã đạt đến giới hạn trên, vui lòng tạo lại mã đó vào ngày mai',
    USR_026: 'Chỉ có 50 mã mời có thể được tạo mỗi ngày!',
    USR_027: 'Lỗi hoàn tiền giảm giá',
    USR_028: 'Lỗi định dạng giảm giá',
    USR_029: 'Không được để trống cài đặt giá trị hoàn lại',
    USR_030: 'Giảm giá cho mã lời mời không được lớn hơn chiết khấu cho bản thân',
    USR_031: 'Cài đặt giá trị hoàn lại không được âm',
    USR_032: '%lotteryNameThiếu thông tin giảm giá.',
    USR_033: 'Giảm giá chỉ cho phép một chữ số thập phân',
    USR_034: 'Mã lời mời đã được tạo, vui lòng bấm vào phần quản lý mã lời mời để kiểm tra',
    USR_035: 'Không xây dựng được',
    USR_036: 'Không thể tìm thấy thông tin người dùng',
    USR_037: 'Số lượng mật khẩu bảo mật sai đã vượt quá 6 lần, đã vượt quá giới hạn trong ngày',
    USR_038: 'Mật khẩu bảo mật của bạn quá đơn giản để lấy lại mật khẩu đăng nhập thông qua mật khẩu bảo mật, vui lòng sử dụng câu hỏi bảo mật hoặc liên hệ với bộ phận chăm sóc khách hàng để lấy lại',
    USR_039: 'Mật khẩu bảo mật là chính xác',
    USR_040: 'Lỗi định dạng mật khẩu bảo mật',
    USR_041: 'Mật khẩu bảo mật đã được đặt',
    USR_042: 'Đặt thành công',
    USR_043: 'Thiết lập không thành công',
    USR_044: 'Thoát thành công',
    USR_045: 'Hộp thư bí mật chưa bị ràng buộc',
    USR_046: 'Lỗi định dạng email',
    USR_047: 'Chúng tôi đã gửi mã xác minh đến hộp thư của bạn, nếu bạn chưa nhận được, vui lòng kiểm tra hộp thư spam',
    USR_048: 'Chiếc điện thoại bí mật chưa bị ràng buộc',
    USR_049: 'Tính năng tạm thời bị đóng',
    USR_050: 'Lỗi định dạng điện thoại',
    USR_051: 'Gửi thành công',
    USR_052: 'Không thể chọn các câu hỏi bảo mật bí mật trùng lặp',
    USR_053: 'Số lượng các vấn đề bảo mật bí mật đã vượt quá 10 lần, đã vượt quá giới hạn trong ngày',
    USR_054: 'Câu trả lời cho câu hỏi bảo mật bí mật là sai',
    USR_055: 'Câu trả lời cho câu hỏi bảo mật bí mật là đúng',
    USR_056: 'Xác minh không hoàn thành',
    USR_057: 'Số lỗi điện thoại xác minh vượt quá 10 lần, đã vượt quá giới hạn trong ngày',
    USR_058: 'Vui lòng xác minh mật khẩu bảo mật trước',
    USR_059: 'Lỗi mã xác minh',
    USR_060: 'Xác minh lỗi điện thoại',
    USR_061: 'Đã xác minh thành công',
    USR_062: 'Số lỗi email xác minh vượt quá 10 lần, đã vượt quá giới hạn trong ngày',
    USR_063: 'Xác minh lỗi email',
    USR_064: 'Câu trả lời không được chứa khoảng trắng',
    USR_065: 'Vui lòng xác minh vấn đề bảo mật trước',
    USR_066: 'Id hiện tại không tồn tại',
    USR_067: 'Người dùng hiện tại không có quyền xem',
    USR_068: 'userId hoặc cardId trống',
    USR_069: 'Ngân hàng này hiện không được hỗ trợ',
    USR_070: 'Xác minh mật khẩu bảo mật không hợp lệ. Để bảo mật tài khoản của bạn, vui lòng xác minh lại mật khẩu bảo mật',
    USR_071: 'Vui lòng xác minh thẻ ngân hàng đầu tiên trước',
    USR_072: 'Tên tài khoản ngân hàng không chính xác, vui lòng chọn lại',
    USR_074: 'Bạn chỉ có thể thêm tối đa 5 thẻ ngân hàng',
    USR_075: 'userId là null',
    USR_076: 'tenantCode là null',
    USR_077: 'bankName không có giá trị',
    USR_078: 'Tỉnh trống',
    USR_079: 'Thành phố trống',
    USR_080: 'Định dạng số thẻ ngân hàng sai',
    USR_081: 'Tên của chủ tài khoản không đúng định dạng',
    USR_082: 'sourceId là null',
    USR_083: 'người dùng là null',
    USR_084: 'Không thể ràng buộc tài khoản ngân hàng này',
    USR_085: 'Thẻ ngân hàng đã được sử dụng, vui lòng thay đổi thẻ ngân hàng',
    USR_086: 'Ràng buộc thành công',
    USR_087: 'Sửa đổi thẻ ngân hàng bị cấm',
    USR_088: 'Thẻ ngân hàng bị khóa và không thể sửa đổi',
    USR_089: 'Thẻ ngân hàng hiện đang bị rút tiền và tạm thời không thể sửa đổi ""',
    USR_090: 'Tên tài khoản ngân hàng không chính xác, vui lòng chọn lại',
    USR_091: '3 lần xác minh sai, vui lòng thử lại vào ngày mai',
    USR_092: 'Thông tin xác minh là chính xác',
    USR_093: 'Thông tin xác minh không chính xác',
    USR_094: 'Quá nhiều mục nhập, vượt quá giới hạn trong ngày',
    USR_095: 'Lỗi thông tin người dùng',
    USR_096: 'Mật khẩu đăng nhập không được giống với số tài khoản và không được đặt quá đơn giản, không được đặt các mật khẩu đơn giản như: 123456, 123123, 654321, 111111, v.v.',
    USR_097: 'Chưa được xác minh',
    USR_098: 'Mật khẩu ban đầu là chính xác',
    USR_099: 'Vui lòng xác minh thẻ ngân hàng trước',
    USR_100: 'Gửi thành công, vui lòng chú ý đến thông báo thư trang web để biết kết quả xem xét',
    USR_101: 'Tải lên hình ảnh không thành công',
    USR_102: 'Mật khẩu ban đầu sai',
    USR_103: 'Vui lòng chỉnh sửa thông qua khiếu nại thủ công',
    USR_104: 'Lỗi ảnh hồ sơ',
    USR_105: 'Dịch vụ tệp ngoại lệ',
    USR_106: 'Dịch vụ tệp ngoại lệ, dữ liệu trống',
    USR_107: 'Biệt hiệu đã tồn tại và không thể sửa đổi',
    USR_108: 'Số điện thoại đã tồn tại và không thể sửa đổi',
    USR_109: 'QQ đã tồn tại và không thể sửa đổi',
    USR_110: 'Chưa bị khóa',
    USR_111: 'accountName là trống',
    USR_112: 'accountName is null',
    USR_113: 'Xác minh chính xác',
    USR_114: 'lỗi hệ thống',
    USR_115: 'Không thể tự mình truy vấn',
    USR_116: 'Tài khoản của bạn đã đăng nhập ở nơi khác. Nếu đó không phải là thao tác của riêng bạn, vui lòng thay đổi mật khẩu của bạn ngay lập tức.',
    USR_117: 'Biệt hiệu chưa được đặt',
    USR_118: 'Biệt hiệu có thể chứa tối đa 5 ký tự tiếng Nhật và tiếng Trung hoặc ít hơn 10 ký tự ở các ngôn ngữ khác',
    USR_119: 'Mật khẩu bảo mật không được giống với số tài khoản và không được đặt quá đơn giản, không được đặt các mật khẩu đơn giản như: 123456, 123123, 654321, 111111, v.v.',
    USR_135: 'Địa chỉ ví đã được sử dụng, vui lòng thay đổi địa chỉ ví',
    LTRY_001: 'lỗi tham số mã xổ số',
    LTRY_002: 'Lỗi thông số DateNum',
    LTRY_003: 'Lỗi code',
    LTRY_004: 'Chỉ kiểm tra tối đa 10 cổ phiếu',
    BET_001: 'Lỗi mạng ... vui lòng thử lại sau',
    BET_002: 'Lỗi thông số! Dữ liệu đặt cược trống',
    BET_003: 'Loài màu không xác định',
    BET_004: 'Số chu kỳ cá cược bị sai, vui lòng làm mới hoặc kiểm tra xem số chu kỳ trang có chính xác không',
    BET_005: 'Không đặt cược lặp lại trong vòng hai giây',
    BET_006: 'Cổ phiếu đã bị đình chỉ bán',
    BET_007: 'Việc bán màu này đã bị đình chỉ',
    BET_008: 'Khoảng thời gian đã được niêm phong',
    BET_009: 'Màu này không mở',
    BET_010: 'Không lấy được cài đặt kho',
    BET_011: 'Cổ phiếu đã bị thổi bay và giao dịch tạm thời bị đình chỉ',
    BET_012: 'Cổ phiếu bị giới hạn lên / xuống, tạm ngừng giao dịch',
    BET_013: 'Các nhà vô địch đầu tư và á quân hiện tại chỉ được chọn tối đa sáu thước',
    BET_014: 'Khoản đầu tư hiện tại không thể đồng thời đầu tư vào tăng / giảm, lẻ / kép, tăng / giảm',
    BET_015: 'Thông tin giảm giá bị sai, vui lòng làm mới trang',
    BET_016: 'Thiếu cân bằng',
    BET_017: 'Lỗi khấu trừ số dư',
    BET_018: 'Dịch vụ số dư trả về trống',
    BET_019: 'Cá cược thành công',
    BET_020: 'Số theo dõi hiện không có sẵn',
    BET_021: 'Loại này không thể được truy tìm',
    BET_022: 'Mỗi nhà vô địch đầu tư và á quân chỉ được chọn tối đa sáu thước',
    BET_023: 'Tham số số đơn đặt hàng bị sai',
    BET_024: 'Lỗi tham số trạng thái',
    BET_025: 'Lỗi thông số khoảng ngày',
    BET_026: 'Rút tiền bất hợp pháp',
    BET_027: 'Tình trạng chương trình không chờ bốc thăm',
    BET_028: 'Không được phép hủy đơn hàng',
    BET_029: 'Hủy thành công',
    BET_030: 'Không tải được cài đặt',
    BET_031: 'Không lấy được thông tin về cờ',
    BET_032: 'Lỗi mã xổ số',
    BET_033: 'Lỗi mã loại cổ phiếu',
    BET_034: 'Hủy bỏ lỗi tự động',
    BET_035: 'Số đơn đặt hàng không tồn tại',
    BET_036: 'Đã đóng cửa',
    MNG_001: 'Mã khóa hoặc mã duy nhất APP không chính xác',
    MNG_002: 'Chìa khóa bị sai hoặc đã hết hạn',
    MNG_003: 'Loại phải lớn hơn 0',
    TRSC_001: 'Bạn vẫn đang xử lý đơn hàng, vui lòng thực hiện sau',
    TRSC_002: 'Xin lỗi, vui lòng không gửi lại trong vòng 5 giây',
    TRSC_003: 'Không gửi đơn đặt hàng thường xuyên',
    TRSC_004: 'Đơn hàng được tạo thành công',
    TRSC_005: 'Đơn hàng được gửi thành công, chúng tôi sẽ thêm hạn ngạch cho bạn trong vòng 5 phút',
    TRSC_006: 'Đơn rút tiền được gửi thành công, vui lòng kiểm tra tài khoản đã nhận được chưa sau 3-5 phút',
    TRSC_007: 'Rút tiền không thành công',
    TRSC_008: 'Gửi thành công',
    TRSC_009: 'Gửi không thành công, đơn đặt hàng đã bị hủy',
    TRSC_010: 'Lời nhắc tương ứng không tồn tại',
    TRSC_011: 'Lời nhắc tương ứng không tồn tại',
    TRSC_012: 'Tham số kiểu tương ứng với lớp EnumPaymentRecordState không chính xác, vui lòng kiểm tra và truyền lại',
    TRSC_013: 'Dữ liệu cấu hình cơ bản của bên thứ ba không tồn tại',
    TRSC_014: 'Chi tiết cài đặt thanh toán của bên thứ ba không tồn tại',
    TRSC_015: 'Dữ liệu cấu hình chế độ giao diện của bên thứ ba không tồn tại',
    TRSC_016: 'Số tiền sai',
    TRSC_017: 'Tham số kiểu tương ứng với lớp EnumMerchantsPaymentType không chính xác, vui lòng kiểm tra và truyền lại',
    TRSC_018: 'Tên người dùng không thể để trống',
    TRSC_019: 'Việc mua lại thành công nhưng không có được hồ sơ lịch sử người chơi',
    TRSC_020: 'Không thể khấu trừ ví ảo',
    TRSC_021: 'Số dư Dịch vụ-Không cập nhật được số dư',
    TRSC_022: 'Dịch vụ số dư-Không cập nhật được hiển thị nhận xét số dư',
    TRSC_023: 'Khôi phục ví ảo không thành công',
    TRSC_024: 'Dữ liệu hạn mức rút tiền mặt của trạm trống hoặc số lượng dữ liệu bị sai',
    TRSC_025: 'Số tiền rút lớn hơn số dư',
    TRSC_026: 'Ví ảo hiện không khả dụng',
    TRSC_027: 'Quản trị viên web không có dữ liệu về giới hạn số lượng tiền mặt có thể rút',
    TRSC_028: 'Số lần rút tiền đã vượt quá',
    TRSC_029: 'ID người dùng ngoại lệ hệ thống',
    TRSC_030: 'Thẻ ngân hàng đã bị vô hiệu hóa, vui lòng liên hệ với bộ phận chăm sóc khách hàng để biết thêm chi tiết',
    TRSC_031: 'Số tiền vượt quá số tiền mặt hiện có',
    TRSC_032: 'Thời gian không được để trống',
    TRSC_033: 'Thời gian bắt đầu không được lớn hơn hoặc bằng thời gian kết thúc',
    TRSC_034: 'Có sự cố với định dạng thời gian đến',
    TRSC_035: 'Ngày kết thúc không được lớn hơn ngày bắt đầu',
    TRSC_036: 'Phạm vi số tiền nạp bị sai',
    TRSC_037: 'Thông số phạm vi số tiền nạp lại bị sai',
    TRSC_038: 'Phạm vi số tiền nạp, số tiền tối đa phải lớn hơn hoặc bằng số tiền tối thiểu',
    TRSC_039: 'Có sự cố với định dạng của phương thức nạp tiền',
    TRSC_040: 'Vấn đề với định dạng điểm',
    TRSC_041: 'Thông số loại cấp bị sai, vui lòng kiểm tra và truyền lại',
    TRSC_042: 'Trạng thái không được để trống',
    TRSC_043: 'Sự cố với định dạng loại',
    TRSC_044: 'Đơn hàng không tồn tại',
    TRSC_045: 'Không gửi dữ liệu nhiều lần',
    TRSC_046: 'Số tiền vượt quá số dư',
    TRSC_047: 'Tiền mặt hiện có của bạn không đủ',
    TRSC_048: 'Dịch vụ người dùng không xác minh được cấp trên và cấp dưới của người dùng%msg',
    TRSC_049: 'Dịch vụ người dùng-Không lấy được thông tin người dùng, Nguyên nhân:%msg',
    TRSC_050: 'Lỗi chuyển đổi tham số：%msg',
    TRSC_051: 'Lỗi khi lấy thông tin lịch sử người dùng',
    TRSC_052: 'Balance Service-Không lấy được số dư của người dùng, Nguyên nhân:%msg',
    TRSC_053: 'Dịch vụ người dùng-Kurt Rock không thành công, Nguyên nhân:%msg',
    TRSC_054: 'Xử lý dịch vụ rút tiền số dư bị từ chối, Nguyên nhân:%msg',
    TRSC_055: 'Dịch vụ số dư-Xử lý khôi phục số dư không thành công, Nguyên nhân:%msg',
    TRSC_056: 'Dịch vụ Người dùng-Không thể xử lý sự thay đổi của giá trị tăng trưởng, lý do hệ thống bị lỗi：%msg',
    TRSC_057: 'Dịch vụ sự kiện-Không xử lý được chiết khấu khi nạp tiền, nguyên nhân do lỗi hệ thống：%msg',
    TRSC_058: 'Dịch vụ chuyển-Không cập nhật được thông tin cấu hình số lượng theo lô, lý do vì hệ thống ngoại lệ：%msg',
    TRSC_059: 'Dịch vụ chuyển-Không cập nhật được thông tin cấu hình của số lần rút tiền của dịch vụ chuyển, lý do hệ thống ngoại lệ：%msg',
    TRSC_060: 'Dịch vụ số dư-Không thể kiểm tra số dư, lý do：%msg',
    TRSC_061: 'Dịch vụ Người dùng Không lấy được thẻ ngân hàng được liên kết của người dùng, lý do cho ngoại lệ hệ thống：%msg',
    TRSC_062: 'Không đủ số dư, ít nhất %minBalance',
    TRSC_063: 'Số tiền %nameType phải từ %minAmount đến %maxAmount',
    TRSC_064: 'Dòng chảy chưa đạt được! Cũng cần tạp chí %journal tạp chí',
    TRSC_065: 'Thực thi hành động trên Wallet không thành công msg：%msg',
    TRSC_066: 'Xin lỗi, hệ thống chỉ hỗ trợ truy vấn dữ liệu trong vòng $betweens ngày gần đây nhất',
    TRSC_083: 'Tỷ giá hối đoái đã thay đổi',
    RPT_001: 'phạm vi loại sai',
    RPT_002: 'Loại dữ liệu yêu cầu không xác định',
    CHAT_001: 'Không có mã khách nào được nhập',
    CHAT_002: 'Mã khách không tồn tại',
    CHAT_003: 'Mã khách truy cập sai',
    CHAT_004: 'lỗi tên người dùng',
    CHAT_005: 'Số phòng sai',
    CHAT_006: 'lỗi kích thước trang',
    CHAT_007: 'Nhóm hiện tại của bạn không được phép vào phòng',
    CHAT_008: 'Cấp độ hiện tại của bạn không được phép vào phòng',
    CHAT_009: 'Tất cả tắt tiếng, ngoại trừ quản trị viên không được phép nói',
    CHAT_010: 'Nhóm hiện tại của bạn không được phép nói',
    CHAT_011: 'Trình độ hiện tại của bạn không được phép nói',
    CHAT_012: 'Bạn đã bị tắt tiếng vĩnh viễn',
    CHAT_013: 'Bạn đã bị cấm trong 1 tuầ',
    CHAT_014: 'Bạn đã bị cấm trong 1 ngày',
    CHAT_015: 'IP của bạn đã bị cấm vĩnh viễn',
    CHAT_016: 'IP của bạn đã bị cấm trong 1 tuần',
    CHAT_017: 'IP của bạn đã bị cấm trong 1 ngày',
    CHAT_018: 'Không được phép gửi ảnh, biểu tượng cảm xúc và phong bì màu đỏ ở trạng thái tắt tiếng',
    CHAT_019: 'Không thể gửi trống',
    CHAT_020: 'Bài phát biểu không được chứa các ký tự đặc biệt',
    CHAT_021: 'Bài phát biểu không được chứa số',
    CHAT_022: 'Bài phát biểu không được chứa tiếng Anh',
    CHAT_023: 'Bài phát biểu không được chứa tiếng Trung',
    CHAT_024: 'Phòng đã bị xóa',
    CHAT_025: 'Hình ảnh không tồn tại',
    CHAT_026: 'Địa chỉ ảnh không được để trống',
    CHAT_027: 'Nguồn hình ảnh bị sai',
    CHAT_028: 'Khách truy cập không thể chia sẻ phiếu cược',
    CHAT_029: 'Khách truy cập không thể chia sẻ hồ sơ',
    CHAT_030: 'Du khách không được phép phát phong bì đỏ',
    CHAT_031: 'Không gửi được gói màu đỏ',
    CHAT_032: 'lỗi messageTypeId',
    CHAT_033: 'lỗi tin nhắn',
    CHAT_034: 'Lưu đến 31 hình ảnh',
    CHAT_035: 'Hình ảnh đã được thêm vào mục yêu thích',
    CHAT_036: 'Không thể hủy yêu thích',
    CHAT_037: 'Số lượng mật khẩu sai ch',
    CHAT_038: 'Phong bì đỏ không thể hủy ngang',
    CHAT_039: 'Chia sẻ cược không thể hủy ngang',
    CHAT_040: 'Chia sẻ bản ghi không thể hủy ngang',
    CHAT_041: 'Chỉ cho phép quản trị viên rút lại và người dùng rút lại tin nhắn của chính họ',
    CHAT_042: 'Không có bản ghi tin nhắn tương ứng',
    CHAT_043: 'Không được để trống mã quản trị trang web, tài khoản quản trị viên, số phòng, tài khoản ẩn',
    CHAT_044: 'Không cho phép người quản lý tắt tiếng',
    CHAT_045: 'Lỗi thời gian tắt tiếng',
    CHAT_046: 'Khách hiện tại không còn ở trong phòng này nữa',
    CHAT_047: 'Người dùng đã bị cấm',
    CHAT_048: 'Chỉ cho phép quản trị viên tắt tiếng',
    CHAT_049: 'Người dùng hiện tại không bị cấm và không thể sửa đổi',
    CHAT_050: 'Chỉ cho phép quản trị viên xóa lệnh cấm',
    CHAT_051: 'Chỉ cho phép tất cả quản trị viên tắt tiếng',
    CHAT_052: 'Số phòng không được để trống',
    CHAT_053: 'Vui lòng nhập thông tin phân trang',
    CHAT_054: 'ID người dùng không được để trống',
    CHAT_055: 'ID trò chơi không tồn tại',
    CHAT_056: 'Không có game tương ứng, vui lòng liên hệ quản trị viên',
    CHAT_057: 'Khách du lịch không thể lấy phong bì đỏ',
    CHAT_058: 'Phòng không tồn tại',
    CHAT_059: 'Bạn hiện đang bị cấm và không thể lấy phong bì màu đỏ',
    CHAT_060: 'Nhóm hiện tại không thể nhận phong bì đỏ',
    CHAT_061: 'Nhận lỗi báo cáo của người dùng trong ngày',
    CHAT_062: 'Nhận lỗi báo cáo lịch sử người dùng',
    CHAT_063: 'Không thêm được vào danh sách yêu thích',
    CHAT_064: 'Số lượng ký tự lời nói không được vượt quá% độ dài',
    CHAT_065: 'Khoảng thời gian nói không được nhỏ hơn% khoảng thời gian giây',
    CHAT_066: 'Bạn chỉ có thể nhận được phong bao đỏ nếu số tiền nạp đạt đến% ngày',
    CHAT_067: 'Bạn chỉ có thể nhận được phong bao đỏ khi số tiền nạp tích lũy đạt đến% lịch sử',
    TRSF_001: 'Dịch vụ nội bộ đang được bảo trì',
    TRSF_002: 'Không có phong bì đỏ như vậy',
    TRSF_003: 'Chức năng phong bì đỏ cá nhân đang được bảo trì',
    TRSF_004: 'Lỗi tham số phong bì đỏ',
    TRSF_005: 'Không thể phát phong bì đỏ cho tài khoản thử nghiệm',
    TRSF_006: 'Không lấy được cài đặt trang web',
    TRSF_007: 'Đã vượt quá cài đặt số tiền tối đa duy nhất',
    TRSF_008: 'Đã đạt đến giới hạn số tiền gửi',
    TRSF_009: 'Phong bì màu đỏ đã đạt đến giới hạn gửi',
    TRSF_010: 'Tổng số lượng của phong bì đỏ vượt quá giới hạn số lượng',
    TRSF_011: 'Không lấy được thông tin người dùng',
    TRSF_012: 'Khấu trừ hạn ngạch người dùng không thành công',
    TRSF_013: 'Không gửi được gói màu đỏ',
    FILES_001: 'Tệp trống',
    FILES_002: 'Kích thước tệp không được vượt quá 5MB',
    FILES_003: 'Id hình ảnh sai',
    FILES_004: 'Số lượng tệp sai',
    FILES_005: 'Chỉ tải lên các tệp hình ảnh',
    FILES_006: 'Thiếu ảnh hoặc quá nhiều',
    FILES_007: 'Vui lòng xác nhận rằng tệp đã tải lên là một bức ảnh',
    ACT_001: 'Vui lòng chuyển trang web trước',
    ACT_002: 'Đang tính ...',
    ACT_003: 'Xin lỗi, tư cách tham gia sự kiện của bạn đã bị cấm',
    ACT_004: 'Hoạt động đã dừng lại',
    ACT_005: 'Đã nhận thành công',
    ACT_006: 'Phần thưởng đã được nhận',
    ACT_007: 'Phong bì màu đỏ không tồn tại!',
    ACT_008: 'Phong bì màu đỏ đã bị hủy bỏ!',
    ACT_009: 'Phong bì đỏ đã hết',
    ACT_010: 'Phong bì đỏ đã hết hạn sử dụng!',
    ACT_011: 'Phong bì đỏ đã được nhận!',
    ACT_012: 'Giao diện người dùng không bình thường!',
    ACT_013: 'Không đáp ứng các điều kiện yêu cầu bồi thường!',
    ACT_014: 'Bạn không có quyền yêu cầu',
    ACT_015: 'Vui lòng không yêu cầu nó thường xuyên, nó đang trong quá trình xác nhận quyền sở hữu ...!',
    ACT_016: 'Ngoại lệ nhận phong bì đỏ!',
    ACT_017: 'Hoạt động vẫn chưa bắt đầu',
    ACT_018: 'Sự kiện đã kết thúc',
    ACT_019: 'Sự kiện đã được thay đổi, vui lòng làm mới lại',
    ACT_020: 'Đã đăng nhập hôm nay',
    ACT_021: 'Điều kiện số tiền không được đáp ứng',
    VIRWLT_001: 'Ngoại lệ tài khoản ảo',
    STK_001: 'Loại cổ phiếu không tồn tại',
    TRSF_014: 'Tổng số gói màu đỏ vượt quá giới hạn gửi',
    BCT_001: 'Đã đăng nhập trên một thiết bị đầu cuối khác',
    BET_039: 'Phát lỗi kiểm tra',
    BET_037: 'Phát kiểm tra lỗi E01',
    BET_040: 'Lỗi xác minh lối chơi, rượt đuổi số lặp lại',
    BET_041: 'Đầu tư thất bại',
    BET_042: 'Lỗi xác minh loại đầu tư A',
    BET_043: 'Lỗi xác minh loại đầu tư B',
    BET_044: 'Lỗi xác minh số tiền C',
    BET_045: 'Số tiền sai',
    BET_046: 'Lỗi xác minh số tiền D',
    BET_047: 'Theo dõi nhiều lỗi',
    BET_048: 'Số lượng của số theo dõi bị sai',
    CHAT_068: 'Cấp hiện tại không thể nhận phong bao đỏ',
    FRT_001: 'IP của bạn đã bị gọi quá nhiều lần, vui lòng thử lại sau',
    FRT_002: 'Xác thực không bình thường, vui lòng liên hệ với quản trị viên',
    FRT_003: 'Tiêu đề yêu cầu không bình thường, vui lòng làm mới và thử lại',
    FRT_004: 'Cuộc gọi đã hết thời gian chờ, vui lòng liên hệ với quản trị viên',
    FRT_005: 'Dịch vụ ngoại lệ',
    FRT_006: 'Vui lòng làm mới và thử lại',
    MNG_004: 'Hạn chế khu vực',
    MNG_005: 'Trang web đang được bảo trì',
    MNG_006: 'Tên miền không bị ràng buộc',
    MNG_007: 'Trang web đã đóng cửa',
    MNG_008: 'Cấm hoạt động trên nhiều trang web',
    USR_120: 'Số lỗi vượt quá 10, đã vượt quá giới hạn trong ngày',
    USR_121: 'Hộp thư bí mật chưa được xác minh',
    USR_122: 'Điện thoại bí mật chưa được xác minh',
    USR_123: 'cardId là null',
    USR_124: 'người dùng trống',
    USR_125: 'Thẻ ngân hàng đã bị vô hiệu hóa, việc sửa đổi bị cấm',
    USR_128: 'ID đã đăng ký là sự kết hợp của các chữ cái và số',
    USR_126: 'Số tài khoản chỉ có thể chứa số',
    USR_127: 'Tài khoản chỉ có thể chứa tiếng Anh',
    USR_073: 'Vui lòng thay đổi dữ liệu thẻ hiện có',
    USR_129: 'Lỗi định dạng tên',
    USR_130: 'Xin hãy nhập tên của bạn',
    USR_131: 'Vui lòng liên kết thẻ bạn đã đăng ký với tên thật của bạn',
    USR_132: 'Không thể tìm thấy thẻ ngân hàng',
    USR_133: 'Vui lòng trượt mã xác minh',
    USR_134: 'Thông tin xác minh của bạn không chính xác',
    /**
     * 仓单
     */
    INVEST_RECORDS: 'Lịch sử đầu tư',
    ACCOUNT_RECORDS: 'Lịch sử kế toán',
    WITHDRAW_DEDUCTION: 'Rút tiền',
    DEDUCTION_DETAIL: 'Chi tiết khấu trừ',
    TRANSFER_TO_VIRTUAL_WALLET: 'Chuyển vào ví',
    DISTRIBUTE_PROFIT: 'lợi nhuận',
    INVEST_PLAN: 'Kế hoạch đầu tư',
    CANCEL_ORDER: 'Hủy đơn hàng',
    NORMAL: 'Normal',
    CHASE: 'Theo dõi',
    UNSTART: 'đợi đã',
    PROCESSING: 'Hoạt động',
    FINISHED: 'Kết thúc',
    CANCEL_ORDER_REMINDER: 'Nếu hủy đơn hàng tôi không trả lại được, vậy tôi có thể hủy đơn hàng và tiếp tục được không?',
    ISSUE_NUMBER: 'Giai đoạn {issue}',
    WITHDRAW_NOTE: 'Tôi đã gửi cho bạn một yêu cầu rút tiền. Vui lòng kiểm tra xem bạn sẽ nhận được nó sau 3-5 phút.',
    WITHDRAE_REFUND: 'Hoàn tiền',
    WITHDRAW_REJECT: 'Việc rút tiền đã bị từ chối.',
    THIRD_WITHDRAW_REJECT: 'Thanh toán trước đã bị từ chối.',
    IN_PROCESS: 'Chế biến',
    DEPOSIT_FAIL: 'Thất bại',
    WAREHOUSE_CHASE_NUMBER_ITEM_FULL: 'Không có',
    WAREHOUSE_CHASE_NUMBER_ITEM_PENDING: 'Hoạt động',
    WAREHOUSE_CHASE_NUMBER_ITEM_WAIT: 'đặt hàng',
    WAREHOUSE_ACCOUNT_WITHDRAW_ITEM_TITLE: 'Rút tiền',
    WAREHOUSE_ACCOUNT_ACTION_SHEET_SERIAL_NUMBER: 'Số thứ tự',
    WAREHOUSE_ACCOUNT_WITHDRAW_ACTION_TIME_TITLE: 'Thời gian',
    WAREHOUSE_ACCOUNT_WITHDRAW_ACTION_MONEY: 'Số lượng',
    WAREHOUSE_ACCOUNT_WITHDRAW_ACTION_BALANCE: 'Thăng bằng',
    WAREHOUSE_ACCOUNT_WITHDRAW_ACTION_REMARK: 'Nhận xét',
    WAREHOUSE_ACCOUNT_WITHDRAW_CANCEL_BTN_TEXT: 'Trở lại',
    WAREHOUSE_RECORDCENTER_CHASENUMBER_UNPROFITABLE: 'không có',
    WAREHOUSE_RECORDCENTER_CHASENUMBER_PENDING: 'Trong tiến trình',
    WAREHOUSE_RECORDCENTER_CHASENUMBER_ING: 'Hoạt động',
    WAREHOUSE_RECORDCENTER_CHASENUMBER_CHASE_NUMBER_TITLE: 'Số kỳ',
    WAREHOUSE_RECORDCENTER_CHASENUMBER_ORDER_TEXT1: 'Từ ',
    WAREHOUSE_RECORDCENTER_CHASENUMBER_ORDER_TEXT2: 'thời kỳ thứ %{num}',
    WAREHOUSE_RECORDCENTER_CHASENUMBER_CHASE_TIME_TITLE: 'Thời gian',
    WAREHOUSE_RECORDCENTER_CHASENUMBER_CHASE_ORDER_TITLE: 'Số đầu tư',
    WAREHOUSE_RECORDCENTER_CHASENUMBER_CHASE_MONEY_TITLE: 'Số lượng',
    WAREHOUSE_RECORDCENTER_CHASENUMBER_BET_TITLE: 'Số tiền đầu tư',
    WAREHOUSE_RECORDCENTER_CHASENUMBER_BONUS_TITLE: 'Lợi nhuận',
    WAREHOUSE_RECORDCENTER_CHASENUMBER_AUTO_BUY_AFTER_WIN_TITLE: 'Chu kỳ',
    WAREHOUSE_RECORDCENTER_CHASENUMBER_PLAY_TYPE: 'Kế hoạch',
    WAREHOUSE_RECORDCENTER_CHASENUMBER_CHASE_SCHEDULE: 'Theo dõi tiến độ',
    WAREHOUSE_RECORDCENTER_CHASENUMBER_CHASE_END: 'Kỳ tiếp theo %{num}',
    WAREHOUSE_RECORDCENTER_CHASENUMBER_CHASE_END_TEXT: 'Tổng số kỳ %{num}',
    WAREHOUSE_RECORDCENTER_CHASENUMBER_CHANGE_PLAY_TYPE_MSG: 'Các thay đổi là không thể thay đổi, vì vậy hãy kiểm tra xem bạn có muốn đặt các thay đổi từ Chu kỳ thành Kết thúc hay không.',
    WAREHOUSE_RECORDCENTER_CHASENUMBER_C_ISSUE_TITLE: 'Số kỳ',
    WAREHOUSE_RECORDCENTER_CHASENUMBER_C_TIME_TITLE: 'Thời gian',
    WAREHOUSE_RECORDCENTER_CHASENUMBER_C_ORDER_TITLE: 'Số đầu tư',
    WAREHOUSE_RECORDCENTER_CHASENUMBER_C_OPEN_NUMBER_TITLE: 'Xổ số',
    WAREHOUSE_RECORDCENTER_CHASENUMBER_C_BET_TITLE: 'Số lượng',
    WAREHOUSE_RECORDCENTER_CHASENUMBER_C_BONUS_TITLE: 'Lợi nhuận',
    WAREHOUSE_RECORDCENTER_CHASENUMBER_C_PLAYTYPE_TITLE: 'Kế hoạch',
    // 本日盈虧
    PROFIT_AMOUNT: 'Số tiền lợi nhuận',
    PROFIT_TODAY: 'Hôm nay',
    PROFIT_MOUNTH: 'Tháng',
    PROFIT_DAY: 'Ngày',
    PROFIT_INVEST_AMOUNT: 'Số tiền đầu tư',
    PROFIT_GAIN: 'Lợi nhuận',
    PROFIT_AGENT_REBATE: 'Phản hồi của đại lý',
    PROFIT_ACTIVITY: 'Sự kiện',
    PROFIT_FORMULA_MEESSAGE: 'Công thức lợi nhuận = Lợi nhuận - Đầu tư + Sự kiện + Giảm giá',
    PROFIT_CREDIT: 'Tín dụng',
    PROFIT_DEPOSIT: 'Số tiền đặt cọc',
    PROFIT_GET_RED_ENVELOPE: 'Phong bì đỏ',
    PROFIT_PAY_OUT: 'Thanh toán',
    PROFIT_WITHDRAWAL: 'Số lượng',
    PROFIT_SEND_RED_ENVELOPE: 'Gửi phong bì đỏ',
    PROFIT_FORMULA: 'Công thức tính lợi nhuận',
    PROFIT_LOSS_FORMULA: 'lợi nhuận = Lợi nhuận - Đầu tư + Sự kiện + Giảm giá',
    PROFIT_I_KNOW: 'Tôi hiểu rồi',
    PROFIT_LOSS_TODAY: 'Lãi và lỗ hôm nay',
    // 代理中心
    ACTING_BALANCE: 'Đội cân bằng',
    ACTING_AGENCY_REPORT: 'Báo cáo đại lý',
    ACTING_TEAM_PROFIT: 'Lợi nhuận nhóm',
    ACTING_LOOK_ALL: 'Xem tất cả',
    ACTING_PROFIT_REBATE: 'Phản hồi về lợi nhuận',
    ACTING_SUB_REPORT: 'Báo cáo thành viên cấp dưới',
    ACTING_MEMBER_MANAGEMENT: 'Quản lý thành viên',
    ACTING_SUB_ACCOUNT: 'Mở tài khoản thành viên thấp hơn',
    ACTING_INVESTMENT_DETAILS: 'đầu tư',
    ACTING_TRANSACTION_DETAILS: 'Giao dịch',
    ACTING_UPDATE: 'Cập nhật',
    ACTING_CARD01_TITLE: 'Đầu tư và phát triển',
    ACTING_CARD01_TEXT: 'Có thể đọc trang này có nghĩa là tài khoản của bạn vừa là trình phát vừa là proxy. Điều này có nghĩa là bạn có thể tự đầu tư hoặc đào tạo cấp dưới của mình để được giảm giá.',
    ACTING_CARD02_TITLE: 'Cách kiếm tiền giảm giá',
    ACTING_INVESTMENT_REBATE: 'Giảm giá đầu tư',
    ACTING_SELF: 'Riêng',
    ACTING_SUB: 'Thành viên',
    ACTING_MATH: '',
    ACTING_INVEST: 'Sự đầu tư',
    ACTING_SUB_INVEST: 'Đầu tư thành viên',
    ACTING_INSTRUCTION: 'Mô tả đại lý',
    ACTING_REBATE: 'Hạ giá',
    ACTING_DEPOSIT_REBATE: 'Giảm giá tiền gửi',
    ACTING_SET_REBATE: 'Thiết lập',
    ACTING_CASH: 'Lấy khoản đầu tư 1.000 nhân dân tệ bằng tiền mặt của D làm ví dụ',
    ACTING_TOTAL_REWARD: 'Toàn bộ',
    ACTING_CLICK_ACCOUNT: 'Bấm để mở tài khoản',
    ACTING_SEARCH_REBATE: 'Bạn có thể xem khoản giảm giá của riêng mình và bạn cũng có thể đặt khoản giảm giá cho cấp dưới.',
    ACTING_HOW_SUB_ACCOUNT: 'Làm thế nào để mở tài khoản cho cấp dưới?',
    ACTING_PARAGRAPH_FIR: 'Nhấp để mở tài khoản ở cấp thấp hơn, trước tiên hãy thiết lập khoản giảm giá cho cấp thấp hơn của bạn và mã mời sẽ được tạo sau khi cài đặt thành công.',
    ACTING_PARAGRAPH_SEC: 'Gửi mã mời cho cấp dưới của bạn để đăng ký, sau khi đăng ký, anh ấy là cấp dưới của bạn, Click vào Quản lý thành viên để xem tài khoản đã đăng ký của anh ấy.',
    ACTING_PARAGRAPH_THR: 'Nếu bạn thiết lập một tài khoản ủy quyền cho cấp dưới của mình, thì cấp dưới của bạn có thể tiếp tục phát triển cấp dưới của họ.',
    ACTING_PARAGRAPH_FOUR: 'Nếu bạn đặt loại người chơi, thì cấp dưới của bạn chỉ có thể đầu tư, bạn không thể phát triển cấp dưới nữa, và bạn không thể nhìn thấy trung tâm đại lý.',
    ACTING_TIPS: 'Lời khuyên',
    ACTING_TEXT_FIR: 'Các khoản giảm giá khác nhau có tỷ lệ cược khác nhau. Hãy nhấp vào bảng trả thưởng để xem tỷ lệ giảm giá.',
    ACTING_TEXT_SEC: 'Mức chiết khấu càng thấp, tỷ lệ cược càng thấp, Khuyến cáo không nên đặt mức chiết khấu cho cấp dưới quá thấp.',
    ACTING_TEXT_THR: 'Bạn có thể xem sự phát triển của đại lý trong báo cáo đại lý, chi tiết đầu tư và chi tiết giao dịch.',
    ACTING_TEXT_FOUR: 'Cấp thấp hơn được đề xuất cũng là một loại đại lý, bất kể bạn đã phát triển bao nhiêu cấp, bạn đều có thể được giảm giá.',
    ACTING_SET_RANGE: 'Hoàn trả phạm vi cài đặt',
    ACTING_WIN_LOTTERY: 'Khi cấp dưới đoạt giải, họ có thể nhận được hoa hồng lợi nhuận.',
    ACTING_WIN_LOTTERY_TWO: 'Khi cấp dưới có lãi, họ có thể được hưởng hoa hồng lợi nhuận.',
    ACTING_COMMISSION_FORMULA_TWO: 'Công thức hoa hồng lợi nhuận: (số tiền lợi nhuận-số tiền đặt cược) * điểm đảo ngược lợi nhuận',
    ACTING_COMMISSION_FORMULA: 'Công thức hoa hồng lợi nhuận: (số tiền lợi nhuận-số tiền đặt cược) * điểm đảo ngược lợi nhuận',
    // 代理報表
    REPORT_TOTAL_PERSON: 'Tổng số nhà đầu tư',
    REPORT_YESTERDAY: 'Hôm qua',
    REPORT_THIS_MONTH: 'Tháng này',
    REPORT_LAST_MONTH: 'Tháng trước',
    REPORT_TEAM_DEAL: 'Giao dịch theo nhóm',
    REPORT_TEAM_CREDIT: 'Tín dụng nhóm',
    REPORT_TEAM_BILLING: 'Thanh toán nhóm',
    REPORT_TEAM_MANAGE: 'Quản lý nhóm',
    REPORT_SING_PERSON: 'Số người đã đăng ký',
    REPORT_PERSON: 'Số người đã đăng ký',
    REPORT_COUNT_SUB_PERSON: 'Tổng số cấp dưới',
    REPORT_CHECK_SING_PERSON: 'Số lượng người đăng ký',
    // 代理報表>詳細資訊
    REPORT_INVESTORS_NUMBER: 'Số người',
    REPORT_TOTAL_TIMES: 'Tổng số lần',
    REPORT_TOTAL_AMOUNT: 'Tổng cộng',
    REPORT_TIME: 'lần',
    REPORT_COUNT_TOTAL_FIRST: 'Tổng số lần tính phí đầu tiên',
    REPORT_AMOUNT_RECEIVED: 'Nhận thanh toán',
    REPORT_TO_MONTH: 'Tháng này',
    REPORT_PREV_MONTH: 'Tháng trước',
    REPORT_FIRST_CHARGE: 'Số lần nạp tiền lần đầu',
    REPORT_TRANSFER_AMOUNT: 'Số tiền chuyển khoản',
    REPORT_NUMBER_SUBORDINATES: 'Số lượng các cấp dưới',
    REPORT_COUNT_PERSON: 'Tổng số người',
    REPORT_OWNED_SUPERIOR: 'Cấp trên sở hữu',
    REPORT_INVITER_ACCOUNT: 'ID mời',
    REPORT_SEARCH_FROM: 'Tìm kiếm báo cáo thành viên cấp dưới',
    REPORT_SEARCH_ACCOUNT: 'Nhập ID của thành viên thấp hơn',
    REPORT_NOT_FIND: 'Không có thông tin, vui lòng thay đổi từ khóa khác',
    //  代理中心>其他選項
    REPORT_CHECK_SUB: 'Truy vấn cấp dưới',
    REPORT_ALREADY_SHOW: 'Đã hiển thị tất cả dữ liệu của %{msg}.',
    REPORT_ACCOUNT_SEARCH: 'Bạn có thể tìm kiếm bằng cách nhập ID',
    REPORT_VIEW_REBATE: 'Xem giảm giá',
    REPORT_GRADE: 'Lớp',
    REPORT_GRADE2: 'Lv. %{grade}',
    REPORT_STOCK_REBATE: 'Cổ phần',
    REPORT_DEPOSIT_REBATE: 'Thanh toán',
    REPORT_SHORT_TERM: 'Cổ phiếu ngắn hạn',
    REPORT_AGENT_TYPE: 'Loại đại lý',
    REPORT_PLAY_TYPE: 'Loại người chơi',
    REPORT_SING_UP: 'Đăng ký',
    REPORT_SET_REBATE: 'Đặt giảm giá',
    REPORT_SET_REBATE_1: 'Đặt số nguyên giảm giá',
    REPORT_SELF_REBATE: 'Giảm giá riêng',
    REPORT_BORN_INVITATION_CODE: 'Tạo mã lời mời',
    REPORT_QR_CODE: 'Mã mời liên kết khuyến mãi Mã QR (nhấn và giữ để lưu)',
    REPORT_COPY_LINK: 'Sao chép liên kết khuyến mại',
    REPORT_COPY_INVITATION_CODE: 'Sao chép mã lời mời',
    REPORT_SUCCESS_COPY: 'Sao chép thành công',
    REPORT_CONFIRM_DELETE_CODE: 'Bạn có chắc chắn muốn xóa mã mời này không?',
    REPORT_CODE_MESSAGE: 'Mã mời vẫn còn đó bạn vui lòng bấm vào phần quản lý mã mời để hỏi Bạn có muốn xem không?',
    REPORT_GO_VIEW: 'Đi đến xem',
    REPORT_STAY_PAGE: 'Trên trang này',
    REPORT_DELETE_CODE: 'Xóa mã lời mời',
    REPORT_ACTING: 'đại lý',
    REPORT_PLAYER: 'Người chơi',
    REPORT_FIELD_ERROR: 'Trường nhập không chính xác',
    REPORT_CHECK_INPUT: 'Vui lòng kiểm tra giá trị đầu vào',
    REPORT_NOT_CREATED_CODE: 'Mã lời mời chưa được tạo',
    //  投資明細
    INVEST_TO: '-',
    INVEST_ALL: 'Tất cả các',
    INVEST_PROFITABLE: 'Doanh thu',
    INVEST_UNPROFITABLE: 'Không có lợi',
    INVEST_WAITING_RELEASED: 'đặt hàng',
    INVEST_ORDER_CANCELLED: 'Hủy đơn hàng',
    INVEST_STOP_PROFIT: 'Kết thúc',
    INVEST_SEVEN_DAYS: '7 ngày',
    INVEST_PERIOD_NUMBER: 'Số kỳ',
    INVEST_TIME: 'Thời gian',
    INVEST_SINGLE_NUMBER: 'Số seri',
    INVEST_MONEY: 'Số lượng',
    INVEST_BONUS: 'Lợi nhuận',
    INVEST_WINNING_NUMBERS: 'Trúng số',
    INVEST_HOW_PLAY: 'Cách chơi',
    // 交易明細
    DEAL_ALL_TYPE: 'Các loại',
    DEAL_WITHDRAWAL_RECORD: 'Hồ sơ rút tiền',
    DEAL_DEPOSIT_RECORD: 'Gửi tiền',
    DEAL_SERIAL_NUMBER: 'Số seri',
    DEAL_TIME: 'Thời gian',
    DEAL_CASH: 'Số lượng',
    DEAL_BALANCE: 'Số dư khả dụng',
    DEAL_REMARK: 'Nhận xét',
    DEAL_WITHDRAWALING: 'Rút tiền',
    DEAL_WITHDRAWAL_SUCCESS: 'Rút tiền thành công',
    DEAL_WITHDRAWAL_ERROR: 'Không rút được',
    DEAL_WITHDRAWAL_REJECT: 'Rút tiền bị từ chối',
    DEAL_PAY_ERROR: 'Không thanh toán được',
    DEAL_PAY_REJECT: 'Từ chối thanh toán',
    DEAL_PAY_SUCCESS: 'Thanh toán thành công',
    DEAL_PAYING: 'Thanh toán thay cho',
    DEAL_DEPOSITING: 'Xử lý',
    DEAL_DEPOSIT_SUCCESS: 'Kết thúc',
    DEAL_DEPOSIT_ERROR: 'Thất bại',
    DEAL_PENDING_PAYMENT: 'Trong khi chờ thanh toán',
    DEAL_COPY_SUCCESS: 'Sao chép thành công',
    DEAL_INVESTMENT_DEDUCTIONS: 'Sao chép thành công',
    // 安全中心
    SECURITY_CENTER: 'Trung tâm bảo vệ',
    SECURITY_CHANGE_LOGIN_PASSWORD: 'Thay đổi mật khẩu đăng nhập',
    SECURITY_CHANGE_SAFE_PASSWORD: 'Thay đổi mật khẩu bảo mật',
    SECURITY_CHANGE_SECRET_PASSWORD: 'Thay đổi câu hỏi bảo mật',
    SECURITY_FIND_SAFE_PASSWORD: 'Lấy lại mật khẩu bảo mật',
    SECURITY_CONFIRM_SAFE_PASSWORD: 'Xác nhận mật khẩu bảo mật',
    SECURITY_ALTER: 'Thay đổi',
    SECURITY_GO_SETTING: 'Đi tới cài đặt',
    SECURITY_SETING: 'Thiết lập',
    SECURITY_BANK_CARD: 'Quản lý thẻ',
    SECURITY_NEW_OLD_NOT_SAME: 'Mật khẩu mới không được giống với mật khẩu cũ',
    SECURITY_NOT_SAME: 'Mật khẩu không được giống với số tài khoản và không được đặt quá đơn giản. Không được đặt mật khẩu thành: 123456, 123123, 654321, 111111 và các mật khẩu đơn giản khác',
    // 更改登入密碼
    SECURITY_LOGIN_KEY: 'Mật khẩu',
    SECURITY_UPDATE_PASSWORD: 'Nó được sử dụng khi đăng nhập và bạn nên thay đổi mật khẩu hai tháng một lần.',
    SECURITY_PASSWORD: 'Mật khẩu ban đầu',
    SECURITY_RESET_PASSWORD: 'đặt lại mật khẩu',
    SECURITY_SUCCESS_UPDATE: 'Sửa đổi thành công, bạn cần đăng nhập lại',
    SECURITY_PASSWORD_INCONSISTENT: 'Hai mật khẩu không nhất quán',
    SECURITY_CONFIRM_PASSWORD: 'Xác nhận mật khẩu',
    SECURITY_UPDATE_SUCCESS: 'Đã sửa đổi thành công',
    // 找回安全密码
    SECURITY_PASS_QUESTION: 'Vượt qua câu hỏi bảo mật',
    SECURITY_PASS_ID: 'Theo danh tính',
    SECURITY_SECRET_QUESTION: 'Câu hỏi bảo mật',
    SECURITY_PASSWORD_AGIAIN: 'Nhập lại mã bảo mật',
    SECURITY_CONFIRM_SECRET: 'Xác minh sự cố bảo mật bí mật',
    SECURITY_FIND_ACCOUNT: 'Tăng tính bảo mật cho tài khoản và lấy lại mật khẩu tài khoản một cách nhanh chóng.',
    SECURITY_INPUT_ANSWER: 'Nhập câu trả lời',
    SECURITY_FIND_ENTER_QAMSG: 'Vui lòng nhập một câu hỏi bí mật',
    SECURITY_VERITY_PASSWORD: 'Xác minh mật khẩu đăng nhập',
    SECURITY_CONFIRM_USER: 'Xác định',
    SECURITY_LOGIN_SAFE_PASSWORD: 'Lấy mật khẩu bảo mật của bạn thông qua mật khẩu đăng nhập',
    SECURITY_LOGIN_PASSWORD: 'Mật khẩu đăng nhập',
    // 銀行卡管理
    BANKCARD_INSTRUCTION: 'Giải trình',
    BANKCARD_LAST_NUMBER: 'Số đuôi',
    BANKCARD_MESSAGE: 'Thẻ ngân hàng %{num} đã bị ràng buộc và chưa được sử dụng, và các thẻ ngân hàng khác không thể bị ràng buộc. Nếu bạn cần sử dụng thẻ ngân hàng khác, vui lòng chỉnh sửa và sửa đổi thông tin ràng buộc hiện tại.',
    BANKCARD_LOCK: 'Sau khi thẻ ngân hàng được ràng buộc và rút tiền thành công, thẻ sẽ tự động bị khóa và không thể xóa hoặc sửa đổi. Có thể ràng buộc tối đa 5 thẻ ngân hàng có cùng tên.',
    BANKCARD_UPDATE: 'Đổi thẻ',
    BANKCARD_ADD: 'Thêm một thẻ',
    BANKCARD_CHECK: 'Xác thực thẻ',
    BANKCARD_PASS_ID_CONFIRM: 'Xác thực bản thân',
    BANKCARD_CONFIRM_SAFE_PASSWORD: 'Xác thực mã bảo mật',
    BANKCARD_CONFIRM_RESULT: 'Kết quả xác thực',
    BANKCARD_OPEN_ACCOUNT: 'Tổ chức tài chính',
    BANKCARD_ACCOUNT_NAME: 'Chủ thẻ',
    BANKCARD_NUMBER: 'Số thẻ',
    BANKCARD_ADDRESS: 'Địa điểm',
    BANKCARD_CONFIRM_NOT_ERROR: 'Xác nhận rằng nội dung sau là chính xác',
    BANKCARD_NAME_ERROR: 'Tên tài khoản ngân hàng không chính xác, vui lòng chọn lại',
    BANKCARD_DATA_ERROR: 'Thông tin thẻ ngân hàng không chính xác, vui lòng thử lại',
    BANKCARD_ID_SUCCESS: 'Tôi đã xác thực người đó.',
    BANKCARD_IMMEDIATELY: 'Mật khẩu bảo mật đã được đặt thành công, liệu có liên kết thẻ ngân hàng ngay lập tức hay không',
    BANKCARD_BIND_CARD: 'Liên kết các thẻ',
    BANKCARD_SPACE_DONT: 'Vui lòng không nhập giá trị rỗng',
    BANKCARD_MESSAGE_ONE: 'Tối đa 5 thẻ có cùng tên có thể được liên kết. Nếu rút tiền thành công, nó sẽ tự động bị khóa và không thể xóa hoặc thay đổi.',
    BANKCARD_MESSAGE_TWO: 'Bây giờ 5 thẻ đã được liên kết, thẻ khác không thể được liên kết. Nếu rút tiền thành công, nó sẽ tự động bị khóa và không thể xóa hoặc thay đổi.',
    BANKCARD_MESSAGE_THREE: 'Tôi chưa sử dụng thẻ được liên kết nên không thể liên kết thẻ khác. Nếu bạn cần sử dụng thẻ khác, hãy thay đổi thông tin tích hợp hiện tại. Nếu rút tiền thành công, nó sẽ tự động bị khóa và không thể xóa hoặc thay đổi. Tối đa 5 thẻ có cùng tên có thể được liên kết.',
    BANKCARD_MESSAGE_FOUR: 'Tôi chưa sử dụng thẻ được liên kết nên không thể liên kết thẻ khác. Nếu bạn cần sử dụng thẻ khác, hãy thay đổi thông tin tích hợp hiện tại. <strong> Nếu rút tiền thành công, nó sẽ tự động bị khóa và không thể xóa hoặc thay đổi. Tối đa 5 thẻ có cùng tên có thể được liên kết. <strong>',
    BANKCARD_MANY_BIND: 'Bạn cũng có thể liên kết các trang tính %{num}. Vui lòng xem ghi chú cho giao diện người dùng',
    BACKCARD_OTHER: 'Các ngân hàng khác',
    BANKCARD_BIND_MORE: 'Bạn vẫn có thể liên kết các thẻ %{msg}.',
    BANKCARD_16_NUMBER: '16chữ số',
    BANKCARD_17_NUMBER: '17chữ số',
    BANKCARD_18_NUMBER: '18chữ số',
    BANKCARD_19_NUMBER: '19chữ số',
    BANKCARD_BANKTYPE_ID: '%{msg}chữ số',
    // 聯絡我們
    ABOUT_TITLE: '% {msg} được thành lập vào năm 2014, là hệ thống đầu tư chứng khoán chuyên nghiệp nhất, cung cấp trải nghiệm dịch vụ "an toàn, đáng tin cậy và tối ưu".',
    ABOUT_SUB_TITLE: 'Nền tảng giao dịch an toàn đa chứng nhận',
    ABOUT_SUB_TITLE_TWO: '%{msg} Nó được chứng nhận bởi STS, Norton và GEOTRUST của bên thứ ba quốc tế và phục vụ bạn theo các tiêu chuẩn nghiêm ngặt của nó, không có bất kỳ phần mềm độc hại nào.',
    ABOUT_TEXT_ONE: '%{msg} Tuân thủ tôn chỉ phục vụ "chân thành làm gốc, lấy chữ tín làm nền" trong một thời gian dài, chúng tôi mang đến cho người dùng trải nghiệm mua cổ phiếu tốt nhất.',
    ABOUT_TEXT_TWO: '%{msg} Nó có các dịch vụ kỹ thuật hàng',
    ABOUT_TEXT_THREE: '%{msg} Cung cấp tỷ lệ cược hợp lý nhất, phương thức thu chi đa kênh, kiểm soát rủi ro chuyên nghiệp và chặt chẽ và bảo hiểm vốn của bên thứ ba, đảm bảo 100% quỹ người chơi.',
    LEGAL_NOTICES: 'Thông báo pháp lý',
    LEGAL_TITLE: 'Về bất kỳ nội dung nào trên trang web này, không ai được phép sao chép trên máy chủ không phải là một phần của trang web này.',
    LEGAL_SUB_TITLE: '%{msg} là một hình thức giao dịch an toàn',
    LEGAL_TEXT_ONE: 'Bản quyền của bất kỳ nội dung nào được cung cấp trên trang web này (bao gồm nhưng không giới hạn ở dữ liệu, văn bản, đồ họa, hình ảnh, âm thanh hoặc video, v.v.) thuộc về trang web này hoặc các chủ sở hữu quyền có liên quan.',
    LEGAL_TEXT_TWO: 'Nếu không có sự cho phép trước bằng văn bản của trang web này hoặc các chủ sở hữu quyền liên quan, bạn không được sao chép, tạo lại, phổ biến, xuất bản, đăng lại, điều chỉnh hoặc hiển thị nội dung của trang web này theo bất kỳ cách nào mà không được phép.',
    LEGAL_TEXT_THREE: 'Đồng thời, nếu không có sự cho phép bằng văn bản của trang web này, không ai được phép sao chép bất kỳ nội dung nào trên trang web này trên một máy chủ không thuộc trang web này. Bất kỳ việc sử dụng trái phép trang web này sẽ vi phạm "Luật Bản quyền của Cộng hòa Nhân dân Trung Hoa" và các luật và quy định khác cũng như các quy định của các công ước quốc tế có liên quan.',
    CONTACT_US: 'liên hệ chúng tôi',
    CONTACT_TITLE: 'Trung tâm Dịch vụ Khách hàng% MSG mở cửa quanh năm, cung cấp dịch vụ chất lượng cao 24 giờ một ngày, 7 ngày một tuần.',
    CONTACT_SUB_TITLE: '%{msg} Bảo hiểm tiền theo cách thứ ba 100% Bảo hiểm tiền an toàn',
    CONTACT_TEXT_ONE: 'Nếu bạn có bất kỳ câu hỏi nào về việc sử dụng trang web này, bạn có thể liên hệ với nhân viên chăm sóc khách hàng thông qua bất kỳ phương thức nào sau đây để được hưởng dịch vụ theo thời gian thực nhất.',
    CONTACT_CLICK: 'Bấm vào',
    CONTACT_CUSTOMER_SERVICE: '"dịch vụ trực tuyến"',
    CONTACT_TEXT_TWO: 'Bạn có thể vào hệ thống dịch vụ khách hàng trực tuyến để liên hệ với nhân viên.',
    CONTACT_CONTENT_COOPERATION: 'Hợp tác nội dung',
    CONTACT_TEXT_THREE: 'Nếu bạn có nội dung tuyệt vời hoặc nguyên bản hoặc các tài nguyên khác liên quan đến chơi chứng khoán, kỹ năng chứng khoán, v.v., bạn có thể liên hệ với chúng tôi!',
    CONTACT_ADVERTISE_COOPERATION: 'Hợp tác quảng cáo',
    CONTACT_TEXT_FOUR: 'Thông qua việc trao đổi không gian quảng cáo trên trang web và các hình thức thay thế tài nguyên quảng cáo kết hợp tự do khác nhằm mở rộng quảng bá và tăng cường các cổng dịch vụ đa dạng, mục tiêu cuối cùng là thúc đẩy người dùng trang web thu được nhiều giá trị gia tăng hơn. Nếu bạn có các tài nguyên tương ứng, rất hoan nghênh bạn liên hệ với chúng tôi.',
    CONTACT_MEDIA_COOPERATION: 'Hợp tác truyền thông',
    CONTACT_TEXT_FIVE: 'Nếu bạn có nguồn tài nguyên phong phú về Internet, WeChat, Weibo và các phương tiện truyền thông mới và truyền thống khác, bạn có thể liên hệ với chúng tôi và để chúng tôi tăng cường ảnh hưởng lẫn nhau thông qua hợp tác hữu nghị giữa hai bên!',
    CHOOSE: 'Chọn %{msg}',
    CHOOSE_TITLE: '%{msg} muốn cung cấp cho các nhà giao dịch dịch vụ trải nghiệm chất lượng cao trong việc giao dịch các sản phẩm tài chính để họ có thể tạo ra lợi nhuận bền vững trên thị trường.',
    CHOOSE_SUB_TITLE: '%{msg} Đã đạt một số chứng nhận quốc tế của ba bên',
    CHOOSE_DEAL_TRANSPARNET: 'Giao dịch minh bạch',
    CHOOSE_TEXT_ONE: 'Với tư cách là nhà giao dịch hàng đầu châu Á, chúng tôi cung cấp cho khách hàng sự minh bạch ở cấp độ cao nhất và ngăn chặn các nhà giao dịch can thiệp vào việc thực hiện giao dịch.',
    CHOOSE_TEXT_TWO: 'Trong cơ sở khách hàng của mình, chúng tôi đã phục vụ một số lượng lớn các nhà giao dịch chuyên nghiệp và công nhận tính toàn vẹn và minh bạch trong hoạt động của các sản phẩm X.',
    CHOOSE_DEAL_SYSTEM: 'Hệ thống giao dịch mạnh mẽ',
    CHOOSE_TEXT_THREE: '%{msg} Nó hiện là nền tảng giao dịch mạnh mẽ nhất và phổ biến nhất đối với người dùng, đồng thời cam kết nghiên cứu và phát triển độc lập các giao dịch trên nền tảng kép APP.',
    CHOOSE_TEXT_FOURE: '%{msg} Bạn có thể trải nghiệm giao dịch hàng hóa tài chính nhanh chóng và tiện lợi.',
    CHOOSE_ORDER_SPEED: 'Tốc độ thực hiện đơn hàng vô song',
    CHOOSE_TEXT_SIX: '%{msg} Sử dụng công nghệ tiên tiến để tối ưu hóa và ổn định môi trường truyền dẫn mạng để đảm bảo tốc độ thực thi cực nhanh.',
    CHOOSE_TEXT_FIVE: 'Thông qua các giải pháp kỹ thuật hoàn thiện, việc thực hiện mua ngay lập tức trở thành hiện thực và các nhà giao dịch trên nền tảng có thể đạt được lợi thế trong thị trường luôn thay đổi.',
    CHOOSE_INTERNATIONAL_NORMS: 'Chứng nhận phù hợp với tiêu chuẩn quốc tế',
    CHOOSE_TEXT_SEVENT: '%{msg} Đạt được chứng nhận quốc tế TST, Norton, GEOTRUST của bên thứ ba và phục vụ bạn theo các tiêu chuẩn nghiêm ngặt của họ.',
    CHOOSE_MONEY_SAFE: 'Các quỹ an toàn và chi phí thuận lợi',
    CHOOSE_TEXT_EIGHT: '%{msg} Ngân hàng hàng đầu châu Á được chỉ định là ngân hàng ủy thác tài chính cho khách hàng, và các khoản tiền của khách hàng được giữ độc lập và hoàn toàn tách biệt với quỹ hoạt động của công ty.',
    CHOOSE_TEXT_NINE: 'Công ty chúng tôi chỉ có thể rút tiền cho các mục đích giao dịch được khách hàng cho phép.',
    CHOOSE_TEXT_TEN: '%{msg} đảm bảo khách hàng rút tiền trong thời gian phục vụ và đến ngay trong ngày.',
    CHOOSE_TEXT_ELEVEN: '%{msg} hợp tác với các nhà cung cấp dịch vụ quy trình hàng đầu để cung cấp cho mọi khách hàng mức chênh lệch cạnh tranh cao và các điều kiện giao dịch linh hoạt.',
    CHOOSE_HIGH_QUALITY: 'Sản phẩm phong phú, dịch vụ chất lượng cao',
    CHOOSE_TEXT_TWELVE: '%{msg} cung cấp hơn 40 loại giao dịch bao gồm ngoại hối, kim loại quý và tiền ảo',
    PRIVACY_STATEMENT: 'Cam kết bảo mật',
    PRIVACY_TITLE: 'Về bất kỳ nội dung nào trên trang web này, không ai được phép sao chép trên máy chủ không phải là một phần của trang web này.',
    PRIVACY_TEXT_ONE: 'Việc cung cấp cho chúng tôi thông tin cá nhân của bạn khi đăng nhập dựa trên sự tin tưởng vào chúng tôi và chúng tôi sẽ xử lý thông tin cá nhân của bạn một cách chuyên nghiệp, thận trọng và có trách nhiệm.',
    PRIVACY_TEXT_TWO: 'Vì vậy, chúng tôi đã phát triển một hệ thống bảo mật thông tin cá nhân để bảo vệ thông tin cá nhân của bạn. Bất kỳ ai đăng nhập vào trang web này theo bất kỳ cách nào hoặc trực tiếp hoặc gián tiếp sử dụng thông tin trên trang web này sẽ được coi là đã tự nguyện chấp nhận các ràng buộc của tuyên bố trang web này. Các điều khoản bảo vệ quyền riêng tư của chúng tôi như sau',
    PRIVACY_MESSAGE: 'Thu thập thông tin cá nhân',
    PRIVACY_TEXT_THREE: 'Thu thập thông tin cá nhân. Mọi thông tin cá nhân khi đăng ký và sử dụng đều được cung cấp theo ý muốn của khách hàng. Xin lưu ý rằng nếu bạn không cung cấp tất cả hoặc một phần thông tin chúng tôi thu thập liên quan đến việc sử dụng dịch vụ, bạn có thể không sử dụng được dịch vụ. Chúng tôi cũng có thể thu thập và sử dụng dữ liệu cá nhân của bạn nhằm tối ưu hóa trải nghiệm người dùng để chúng tôi có thể phục vụ trang web sách tốt hơn.',
    PRIVACY_PROTECT: 'Bảo vệ quyền riêng tư',
    PRIVACY_TEXT_FOUR: 'Bảo vệ quyền riêng tư Bảo vệ quyền riêng tư của người dùng là chính sách cơ bản của trang web này. Trang web này sẽ không công bố hoặc phổ biến bất kỳ thông tin nào bạn đã đăng ký trên trang web này, ngoại trừ các trường hợp sau:',
    PRIVACY_ITEM_ONE: 'Nhận trước ủy quyền rõ ràng từ người dùng;',
    PRIVACY_ITEM_TWO: 'Việc người dùng bảo mật thông tin của chính mình không đúng cách gây ra rò rỉ thông tin không công khai của người dùng;',
    PRIVACY_ITEM_THREE: 'Rò rỉ, mất mát, bị đánh cắp hoặc giả mạo thông tin do đường truyền mạng, tin tặc tấn công, vi rút máy tính, sự kiểm soát của chính phủ, v.v.;',
    PRIVACY_ITEM_FOUR: 'Theo yêu cầu của pháp luật và quy định có liên quan',
    PRIVACY_ITEM_FIVE: 'Theo phán quyết hoặc phán quyết của tòa án hoặc tổ chức trọng tài, và các yêu cầu của các thủ tục tư pháp khác;',
    PRIVACY_ITEM_SIX: 'Phù hợp với các yêu cầu của cơ quan chính phủ có liên quan',
    PRIVACY_PUBLIC: 'Tự đổi mới và công bố thông tin',
    PRIVACY_TEXT_FIVE: 'Chúng tôi khuyến khích bạn cập nhật và sửa đổi thông tin cá nhân của mình sao cho an toàn và hiệu quả.',
    PRIVACY_TEXT_SIX: 'Bạn có thể dễ dàng lấy và sửa đổi thông tin cá nhân của mình bất kỳ lúc nào và bạn có thể quyết định sửa đổi hoặc xóa thông tin liên quan của mình trên trang web bất kỳ lúc nào. Bạn là người duy nhất có trách nhiệm bảo mật thông tin tài khoản và mật khẩu của mình, trong mọi trường hợp vui lòng bảo mật thông tin tài khoản và mật khẩu của bạn một cách cẩn thận và đúng quy cách.',
    PRIVACY_TEXT_SEVEN: 'Bất cứ khi nào bạn tự nguyện tiết lộ thông tin cá nhân trước công chúng, những thông tin đó có thể được người khác thu thập và sử dụng.',
    PRIVACY_HIT: 'Gợi ý',
    PRIVACY_TEXT_EIGHT: 'Chúng tôi có thể sửa đổi chính sách bảo mật của mình theo thời gian. Những sửa đổi này sẽ được phản ánh trong tuyên bố này. Tuyên bố về quyền riêng tư, nếu người dùng tiếp tục sử dụng dịch vụ, thì được coi là đã chấp nhận các thay đổi trong điều khoản dịch vụ.',
    // 聊天室
    CHATROOM: 'Phòng chat',
    CHATROOM_NO_ROOM: 'Không có phòng trống',
    CHATROOM_DELETE_ROOM: 'Phòng đã bị xóa',
    CHATROOM_ALL_SHOW_ROOM: 'Tất cả các phòng được hiển thị',
    CHATROOM_CELL: 'Tủ',
    CHATROOM_CONTENT: 'Nội dung',
    CHATROOM_YOU: 'Bạn',
    CHATROOM_SPEND_IMG: 'Đã gửi một bức tranh',
    CHATROOM_SHARE_BET_SLIP: 'Đã chia sẻ một cá cược',
    CHATROOM_SPEND_PLAN: 'Đã gửi một kế hoạch đẩy',
    CHATROOM_SPEND_RED_ENVELOPE: 'Gửi phong bì đỏ',
    CHATROOM_ALREADY_DELETE: 'Phòng đã bị xóa',
    CHATROOM_YESTERDAY: 'Hôm qua',
    CHATROOM_BEFORE_YESTERDAY: 'Ngày hôm kia',
    CHATROOM_ALL_DATA: 'Tất cả dữ liệu được hiển thị',
    CHATROOM_FROM_WEB: 'Hình ảnh này lấy từ Internet',
    CHATROOM_FORVER: 'Dài hạn',
    CHATROOM_WEEK: 'Một tuần',
    CHATROOM_DAY: 'Một ngày',
    CHATROOM_HOUR: 'Một giờ',
    CHATROOM_HALF_HOUR: 'Nửa tiếng',
    CHATROOM_UNMUTE: 'Bật tiếng',
    CHATROOM_COLLECTION_SUCCESS: 'Sưu tầm',
    CHATROOM_CONNECTION_FAILED: 'Kết nối không thành công, vui lòng thử lại sau',
    CHATROOM_UNMUTE_FORVER: 'Bạn đã bị tắt tiếng vĩnh viễn',
    CHATROOM_UNMUTE_DAY: 'Bạn đã bị cấm trong 1 ngày',
    CHATROOM_UNMUTE_WEEK: 'Bạn đã bị cấm trong 1 tuần',
    CHATROOM_UNMUTE_HOUR: 'Bạn đã bị cấm trong 1 giờ',
    CHATROOM_UNMUTE_HAL_HOUR: 'Bạn đã bị tắt tiếng trong nửa giờ',
    CHATROOM_ACCOUNT_CHAG: 'Quyền tài khoản đã được thay đổi, vui lòng vào lại phòng',
    CHATROOM_UNMUTE_LEVEL: 'Cấp độ của bạn bị tắt tiếng',
    CHATROOM_WS_FAILED: 'tiếp nhận ws không thành công',
    CHATROOM_CONGRATULATE: 'Chúc mừng bạn đã trở nên giàu có',
    CHATROOM_NOT_FOLLOW_UP: 'Du khách không thể theo dõi,',
    // 聊天室component
    CHATCELL_ALL_UNMUTE: 'Tắt tiếng tất cả',
    CHATCELL_ENTER: 'Nhập tin nhắn',
    CHATCELL_IMG: 'Hình ảnh',
    CHATCELL_RED_ENVELOPE: 'Món quà',
    CHATCELL_NOT_SPEND: 'Khách không thể gửi quà nên vui lòng đăng ký hoặc đăng nhập trước.',
    CHATCELL_NOT_SET_PASSWORD: 'Tôi chưa đặt mã bảo mật nên không thể gửi quà tặng. Bạn có muốn đặt mã bảo mật không?',
    CHATCELL_OPEN_HELPER: 'Khách không thể vận hành các chức năng trợ lý, vì vậy vui lòng đăng ký hoặc đăng nhập trước.',
    CHATCELL_IMG_LOAD_FAIL: 'Không thể tải hình ảnh.',
    CHATCELL_MUTE_MANAGEMENT: 'Quản lý cấm nói chuyện',
    CHATCELL_ROOM_INTRODUCE: 'Giới thiệu',
    CHATCELL_LINE: '社群守則，但是「限制」的力量是有限的，真正重要的是每一位使用者，能了解、並遵守LINE社群守則，不只自己使用更安心，也才能讓「LINE社群」這個新功能發揮所長，好好來服務大家。',
    CHATCELL_EXPIRED: 'Món quà này đã được cung cấp trong hơn 12 giờ. Nếu bạn nhận được nó, bạn có thể kiểm tra nó trong "My Present".',
    CHATCELL_ROBBED: 'Đã nhận tất cả',
    CHATCELL_CHECK_RED_ENVELOPE: 'Tôi đã nhận được món quà này. Bạn có thể kiểm tra nó trong "Quà của tôi".',
    CHATCELL_GET_DETAILS: 'Xem danh sách biên nhận',
    CHATCELL_WHO_RED_ENVELOPE: '%{msg} quà tặng',
    CHATCELL_SPELL: 'Lucky',
    CHATCELL_GENERAL: 'Tổng quan',
    CHATCELL_THE: 'Riêng biệt',
    CHATCELL_DOLLAR: 'Đồng',
    CHATCELL_SAVE_BALANCE: 'Tôi đã gửi vào số dư của mình. Nó có thể được sử dụng để đầu tư',
    CHATCELL_GET_COUNT: 'Đã nhận %{num} / %{total}',
    CHATCELL_NUM_TOTAL: 'Tổng %{num}/%{total} đồng',
    CHATCELL_TOTAL: 'Tổng %{num} đồng',
    CHATCELL_ROBBED_ALREADY: 'Tổng số quà tặng %{num} là %{total} đồng, tất cả đã được phân phát.',
    CHATCELL_LACKY: 'Lucky',
    CHATCELL_TEMPORARY_RECORD: 'Không có dữ liệu',
    CHATCELL_OUT_RECORD: 'Không có dữ liệu đặt hàng',
    CHATCELL_NOT_RECORD: 'Không nhận được dữ liệu',
    CHATCELL_RESIVE_RED_ENVELOPE: 'Quà đã nhận',
    CHATCELL_SEVEN_DAT_TWO: 'Hôm trước, tôi nhận được tổng cộng X quà tặng.',
    CHATCELL_PLAYER_MESSAGE: 'Thông tin người chơi',
    CHATCELL_ADMINISTRATOR: 'Người quản lý',
    CHATCELL_ALL_PEOPLE: 'Tất cả các',
    CHATCELL_NEWS: '%{num} tin nhắn mới',
    CHATCELL_HISTORICAL_OUT: 'Lịch sử đơn hàng',
    CHATCELL_DATE: 'Kỳ %{msg}',
    CHATCELL_NOT_GET: 'Không thắng',
    CHATCELL_GET: 'Chiến thắng',
    CHATCELL_WAIT: 'Đang chờ đợi',
    CHATCELL_DRAW: 'Tie',
    CHATCELL_FALL: 'Ngã',
    CHATCELL_BEAR: 'Bear',
    CHATCELL_SINGLE: 'Odd',
    CHATCELL_UP: 'Trỗi dậy',
    CHATCELL_PAIR: 'Even',
    CHATCELL_COW: 'Bull',
    CHATCELL_BIND_SUCCESS: 'Nó đã làm việc cùng nhau.',
    CHATCELL_NOT_EMPTY: 'Nội dung là một mục đầu vào bắt buộc.',
    CHATCELL_RECORD_UNMUTE: 'Không có lịch sử cấm nói chuyện.',
    CHATCELL_NOT_SET_NICKNAME: 'Tôi vẫn chưa đặt tên cho đầu gối.',
    // 紅包
    MAIL_SPEND: 'Gửi một món quà',
    MAIL_USE_RULES: 'Quy tắc gửi quà',
    MAIL_USE_MONEY: 'Nếu số tiền rút có thể rút là %{num} đồng trở lên,',
    MAIL_USE_MONEY_TWO: ',Bạn có thể sử dụng chức năng để gửi quà tặng.',
    MAIL_SINGEL_MONEY: 'Một lượng',
    MAIL_TOTAL: 'Số lượng',
    MAIL_NUMBER: 'Số lượng quà tặng',
    MAIL_TWELVE_HOURS: 'Việc hoàn tiền cho những phần quà chưa nhận được sẽ bắt đầu sau 12 giờ.',
    MAIL_LOW_ONE: 'Số quà trung bình một người nhận được là',
    MAIL_HEIGH_DOLLAR: 'Số tiền tối thiểu là %{one} đồng và số tiền tối đa là %{num} đồng.',
    MAIL_INPUT_PASSWROD: 'Vui lòng nhập mã bảo mật.',
    MAIL_SIX_PASSWORD: 'Security Code là một số có 6 chữ số.',
    MAIL_HAVE_INTEGER: 'Số lượng quà tặng phải là số nguyên dương.',
    MAIL_FIVE_HUNDRED: 'Số lượng quà tặng không được vượt quá 500 suất.',
    MAIL_BIG_DOLLAR: 'Số lượng quà tặng trung bình mỗi người không được vượt quá %{num} đồng',
    MAIL_CASH_NOT_ENOUGH: 'Số tiền rút có thể rút không đủ.',
    MAIL_GRAND_TOTAL: 'Số lượng quà bạn có thể gửi hàng ngày không được vượt quá %{total} đồng, nhưng bạn vẫn có thể gửi %{num} đồng',
    MAIL_LIMIT_DAY: 'Giới hạn hàng ngày về số lượng quà tặng bạn có thể gửi đã vượt quá giới hạn hàng ngày% {num} lần.',
    MAIL_LESS_DOLLAR: 'Số lượng quà tặng trung bình mỗi người nhận được ít nhất là 1 đồng',
    MAIL_NOT_EMPTY: 'Đây là một mục đầu vào bắt buộc.',
    MAIL_WRONG_FORMAT: 'Lỗi định dạng',
    MAIL_LESS_DOLLAR_TWO: 'Số lượng một món quà không được dưới 1 đồng',
    MAIL_REMARK: 'Ghi chú là mục đầu vào bắt buộc.',
    MAIL_LACKY: 'Tôi đã đổi nó thành một món quà Lucky.',
    MAIL_NORMAL: 'Tôi đã đổi nó thành một món quà chung.',
    MAIL_ADD_FACE: 'Thêm biểu tượng cảm xúc',
    MAIL_TIDY: '',
    MAIL_FIRST_LOGIN: 'Khách không thể thu thập hình ảnh biểu tượng cảm xúc. Vui lòng đăng nhập trước.',
    MAIL_DELETE: 'Xóa',
    MAIL_COMPRESSION: 'Nén',
    MAIL_UPLOAD: 'Đang tải lên',
    MAIL_ASSISTANT: 'Phụ tá',
    MAIL_CANT_OVER: 'Số lượng một món quà không được vượt quá %{num} đồng',
    MAIL_BIGGER_OUT: 'Tổng số tiền của một phần quà không được vượt quá số tiền rút có thể rút.',
    MAIL_ACCOUNT_SERACH: 'Tìm kiếm ID tài khoản',
    MAIL_ALREADY_GET: 'Tất cả đã được nhận',
    MAIL_RETURN: 'Đã hoàn lại',
    MAIL_RECEIVE: 'Cầm lấy',
    MAIL_EXPIRED: 'Hết hạn',
    MAIL_VISITOR_GRAB: 'Khách mời sẽ không thể nhận quà nên vui lòng đăng nhập trước.',
    MAIL_MESSAGE: 'May mắn! Nhận quà!',
    MAIL_TIME_UP: 'Quà tặng trong thời gian có hạn',
    // UserInfo.vue
    USERINFO_LIKE: 'Cổ phiếu yêu thích của người dùng này',
    USERINFO_TEST: 'Nhóm thử nghiệm',
    USERINFO_SET_NICKNAME: 'Không có tiêu đề',
    USERINFO_SECRET: 'Riêng',
    USERINFO_BRONZE: 'đồng',
    USERINFO_SILVER: 'Bạc',
    USERINFO_GOLD: 'Vàng',
    USERINFO_PLATINUM: 'Bạch kim',
    USERINFO_DIAMOND: 'Kim cương',
    USERINFO_CRYSTAL: 'Pha lê',
    USERINFO_CROWN: 'Vương miện',
    USERINFO_STARSHINE: 'Ngôi sao',
    USERINFO_KING: 'Nhà vua',
    USERINFO_GAMBLERS: 'Thần cờ bạc',
    USERINFO_MANAGE: 'Ban quản lý',
    // 出金 Sam
    OUTGOLD_CARD: 'Thẻ',
    OUTGOLD_OUT: 'Số lần bạn vẫn có thể rút: %{num} lần',
    OUTGOLD_CASH: 'Số tiền rút',
    OUTGOLD_WATER: 'Dòng chảy không đủ %{num} đồng',
    OUTGOLD_DEAL: 'Để giao dịch',
    OUTGOLD_BALANCE: 'Số dư rút tiền là %{num} đồng',
    OUTGOLD_HANDLING_FEE: 'Phí %{num} đồng',
    OUTGOLD_RULES: 'Quy tắc rút tiền',
    OUTGOLD_DETAILS: 'Chi tiết rút tiền',
    OUTGOLD_DEDUCT: 'Phí %{num}% mỗi lần rút tiền',
    OUTGOLD_LOW_HEIGH: 'Một lần rút số tiền tối thiểu %{num} đồng, số tiền tối đa %{total} đồng',
    OUTGOLD_WATER_ENOUGH: 'Khi bạn có đủ ngân hàng, bạn có thể đăng ký rút tiền.',
    OUTGOLD_I_KNOW: 'Hiểu rồi',
    OUTGOLD_CONFIRM_ID: 'Xác thực',
    OUTGOLD_LIMIT_TIME: 'Số lượng đơn rút tiền đã vượt quá giới hạn.',
    OUTGOLD_CUSTOMER: 'Tôi không thể rút tiền ngay bây giờ, vui lòng liên hệ với tôi để được giải đáp.',
    OUTGOLD_MESSAGE: 'Phí rút tiền là %{num}% trên số tiền rút và sẽ được trừ trực tiếp vào số dư của bạn sau khi rút tiền thành công.',
    OUTGOLD_ALL: 'Rút ra tất cả',
    // 出金components
    OUTGOLD_RECORD: 'Hồ sơ rút tiền',
    OUTGOLD_FAIL: 'Rút tiền không thành công.',
    OUTGOLD_SHOW_ALL: 'Toàn bộ lịch sử của %{num} ngày được hiển thị.',
    OUTGOLD_MESSAGE_ONE: 'Vui lòng đợi một lúc cho đến khi hệ thống hoàn tất quá trình rút tiền.',
    OUTGOLD_MESSAGE_TWO: 'Quá trình hoàn tất. Hệ thống đã ghi có số tiền vào tài khoản của bạn.',
    OUTGOLD_MESSAGE_THREE: 'Hệ thống đã hủy yêu cầu rút tiền.',
    OUTGOLD_MESSAGE_FOUR: 'Hệ thống đã từ chối yêu cầu rút tiền.',
    OUTGOLD_TEXT_ONE: '1. Hoa hồng 1% cho mỗi lần rút tiền',
    OUTGOLD_TEXT_TWO: '2. Một lần rút số tiền tối thiểu 1 đồng, số tiền tối đa 2000000 đồng',
    OUTGOLD_TEXT_THREE: 'Hệ thống đã từ chối yêu cầu rút tiền.',
    OUTGOLD_TEXT_FOUR: 'Hệ thống đã hủy yêu cầu ứng tiền rút tiền.',
    OUTGOLD_TEXT_FIVE: 'Hệ thống đã từ chối yêu cầu thanh toán trước khi rút tiền.',
    OUTGOLD_TEXT_SIX: 'Quá trình hoàn tất. Hệ thống đã ghi có số tiền vào tài khoản của bạn.',
    OUTGOLD_TEXT_SEVEN: 'Vui lòng đợi một lúc cho đến khi hệ thống hoàn tất quá trình rút tiền.',
    // BaseSecurity.vue
    GUARD_ACCOUNT_EMPTY: 'ID đăng nhập là một mục bắt buộc.',
    GUARD_CORRECT_ACCOUNT: 'Vui lòng nhập đúng ID Roquin.',
    GUARD_PASSWORD_EMPTY: 'Mật khẩu là một mục bắt buộc.',
    GUARD_CORRECT_PASSWORD: 'Vui lòng nhập đúng mật khẩu.',
    GUARD_PASSWORD_ENTER: 'Mật khẩu là một bảng chữ cái và số từ 6 đến 16 chữ số',
    GUARD_INVITE_EMPTY: 'Mã mời là một mục nhập bắt buộc.',
    GUARD_CORRECT_INVITE: 'Vui lòng nhập mã mời chính xác.',
    GUARD_SING_EMPTY: 'ID đăng nhập là một mục bắt buộc.',
    GUARD_ACCOUNT_ENTER: 'ID đăng nhập là một bảng chữ cái hoặc số có từ 4 đến 16 chữ số.',
    GUARD_VERIFY_EMPTY: 'Mã xác minh là một mục bắt buộc.',
    GUARD_PHONE_EMPTY: 'Số điện thoại di động là một mục bắt buộc.',
    GUARD_PHONE_CORRECT: 'Vui lòng nhập số điện thoại di động chính xác.',
    GUARD_EMAIL_EMPTY: 'Địa chỉ email là một mục bắt buộc.',
    GUARD_CORRECT_EMAIL: 'Vui lòng nhập địa chỉ email chính xác.',
    GUARD_QQ_EMPTY: 'Tài khoản QQ là một mục nhập bắt buộc.',
    // Components file
    ELEMENT_SHARE: 'Chia sẻ',
    ELEMENT_SHARE_SUCCESS: 'Chia sẻ thành công',
    ELEMENT_MUTE: 'Chia sẻ thành công',
    ELEMENT_FOLLOW: 'Theo chúng tôi',
    ELEMENT_SHARE_WAR: 'Chia sẻ kỷ lục trận chiến',
    ELEMENT_HIT_RATE: 'Tổng tỷ lệ truy cập',
    ELEMENT_WIN_RATE: 'Phần trăm chiến thắng hôm nay',
    ELEMENT_DONT_TALK: 'Nói chuyện bị cấm',
    ELEMENT_RECALL: 'Sự hủy bỏ',
    ELEMENT_SHARE_MEMBER: 'Thành viên',
    ELEMNET_INVESTMENT_CONTENT: 'Nội dung',
    ELEMENT_SYSTEM_TEST: 'Kiểm tra hệ thống',
    ELEMENT_STRAIGHT: 'Xoay điện thoại thông minh của bạn theo chiều dọc.',
    ELEMENT_CUSTOMER_SERVICE: 'Xin vui lòng liên hệ với chúng tôi',
    ELEMENT_FINANCIAL_INFORMATION: 'Thông tin kinh tế',
    ELEMENT_LOOK_EVERYWHERE: 'Không cần nữa, đi xem chỗ khác',
    ELEMENT_SPEND_MEMEBER: '推送会员',
    ELEMENT_INPUT_UNIT: '请输入投资单位',
    ELEMENT_INVEST_UNIT: '投资单位',
    ELEMENT_DATE: 'Thời kỳ %{prv} đã kết thúc, bây giờ là thời kỳ %{now}',
    ELEMENT_CASH: 'Số tiền',
    ELEMENT_ENTER_BET_SLIP: 'Vui lòng nhập cược %{msg}.',
    ELEMENT_SEACH_RECORD: 'Khoản đầu tư đã thành công. Bạn có thể kiểm tra lịch sử đầu tư của mình trong "Tài khoản của tôi".',
    ELEMENT_CHECK_BET_SLIP: 'Đi đến xem',
    ELEMENT_GO_ON: 'Tiếp tục đầu tư',
    ELEMENT_INVEST_ERROR: 'Số kỳ để đầu tư không chính xác.',
    ELEMNET_REBATE: 'Hạ giá',
    ELEMENT_WAIT_DRAW: 'Chờ xổ số',
    ELEMENT_DECIMAL: 'Số thập phân,HAI PHÍA',
    ELEMENT_NOT_WINNING: 'Không thắng',
    ELEMENT_WIINNING: 'Chiến thắng',
    ELEMENT_WAIT_PROFIT: 'Chờ lợi nhuận',
    ELEMENT_PAY_CASH: 'Trả',
    ELEMENT_TEXT_ONE: '1. Vui lòng sử dụng ví QQ để gửi tiền vào tài khoản trên.',
    ELEMENT_TEXT_TWO: '2. Đối với mã QR, vui lòng quét bằng ví QQ và gửi tiền. Nếu bạn có tài khoản ngân hàng, hãy sử dụng ví QQ để chuyển vào thẻ của bạn.',
    ELEMENT_TEXT_THREE: '4. Vui lòng nhập chính xác biệt danh của ví QQ và số tiền phí. Xin lưu ý rằng biệt hiệu ví QQ không phải là ID tài khoản ví QQ.',
    ELEMENT_IS_SUBMIT: 'Tôi đang gửi.',
    // MODULE file
    MODULE_GET_CASH: 'Chúc mừng! ',
    MODULE_CASH_DOLLAR: 'Tôi nhận được một  .',
    MODULE_FINISHED: 'Tất cả đã được nhận.',
    MODULE_DATE: 'Hết hạn',
    MODULE_CONDITION_STANDARD: 'Bạn chưa đáp ứng đủ điều kiện, vậy tại sao không tính phí ngay bây giờ?',
    MODULE_ROOM_PASSWORD: 'Mật khẩu Phòng',
    MODULE_WRITE_ROOM: 'Vui lòng nhập mật khẩu phòng.',
    MODULE_ROOM_MESSAGE: 'Mật khẩu của phòng có 4 chữ số và được sử dụng để vào phòng.',
    MODULE_ROOM_MESSAGE2: 'Nếu bạn gặp lỗi nhập liệu nhiều lần, bạn sẽ không thể nhập lại trong ngày.',
    MODULE_NO_MORE: 'No more',
    MODULE_PROXY: 'Cấp đại lý%{num}',
    MODULE_PLAYER: 'Cấp độ %{num}',
    MODULE_SHARE_ROOM: 'Chia sẻ phòng',
    MODULE_FIX_SHARE_CASH: 'Sửa đổi số tiền đặt cược được chia sẻ',
    MODULE_REFIX_DEAL: 'Vui lòng sửa lại số tiền đặt cược chia sẻ',
    MODULE_HOW_DATE: 'Kỳ %{num}',
    // notify
    NOTIFY_BUY_SUCCESS: 'Tôi đã mua nó',
    NOTIFY_UPDATE_DONE: 'Việc sửa chữa đã hoàn tất và các thay đổi đã được lưu.',
    NOTIFY_LOOP_DISABLED: 'Chu kỳ được dừng lại.',
    NOTIFY_SET_SUCCESS: 'Các cài đặt đã hoàn tất và các thay đổi đã được lưu.',
    NOTIFY_SAFEPASSWORD_DONE: 'Đã hoàn tất cài đặt mã bảo mật',
    NOTIFY_BANKCARD_ADD: 'Thẻ đã được thêm thành công.',
    NOTIFY_COPY_WATER: 'Tôi đã sao chép số thứ tự.',
    NOTIFY_COPY_ACCOUNT: 'Tôi đã sao chép số tài khoản.',
    NOTIFY_COPY_NAME: 'Tôi đã sao chép tên tài khoản.',
    NOTIFY_COPY_SUCCESS: 'Tôi đã sao chép nó.',
    NOTIFY_COPY_BANK: 'Tôi đã sao chép tên ngân hàng.',
    NOTIFY_COPY_OPEN: 'Tôi đã sao chép chi nhánh ngân hàng.',
    NOTIFY_UPDATE_SUCCESS: 'Việc sửa chữa đã hoàn tất và các thay đổi đã được lưu.',
    NOTIFY_INVITE_DELETE: 'Mã lời mời đã bị xóa.',
    NOTIFY_COPY_PASSWORD: 'Tôi đã sao chép mật khẩu đã tạo.',
    NOTIFY_CANCEL_ORDER: 'Tôi đã hủy đơn đặt hàng của mình.',
    NOTIFY_MUTE_SUCCESS: 'Cấm nói chuyện đã được thiết lập.',
    NOTIFY_MUTE_CANCEL: 'Lệnh cấm trò chuyện đã bị hủy bỏ.',
    NOTIFY_FIXED_BUG: 'Thay đổi lỗi',
    NOTIFY_ID_ERROR: 'Lỗi xác thực của người',
    NOTIFY_PASSWORD_NOT_SAME: 'Mã bảo mật không khớp.',
    // ---
    VIRTUAL_WALLET_WALLET_BALANCE: 'Số dư trên ví',
    VIRTUAL_WALLET_WATER_INSUFFICIENT: 'Dòng chảy không đủ',
    VIRTUAL_WALLET_RECEIVABLE: 'Số tiền rút',
    RECHARGE_INPUT_TITLE: 'Phương pháp',
    RECHARGE_INPUT_HINTTEXT: 'Số tiền tối thiểu',
    RECHARGE_ACCOUNT＿INFORMATION: 'Thông tin tài khoản',
    RECHARGE_DEPOSITOR_NAME: 'Tên người gửi tiền',
    RECHARGE_DEPOSITOR_INPUTNAME: 'Vui lòng nhập tên của người gửi tiền.',
    RECHARGE_DEPOSIT_AMOUNT: 'Số tiền đặt cọc',
    RECHARGE_VIRTUAL_WALLET_TEXT: 'Số dư ví là',
    RECHARGE_FULL_DEPOSIT: 'Tất cả tiền gửi',
    RECHARGE_ACCOUNT_INFORMATION: 'Thông tin tài khoản',
    RECHARGE_SCAN_CODE_TEXT: 'Quét mã QR, nhấp để lưu',
    RECHARGE_DEPOSIT_DETAILS: 'Chi tiết tiền gửi',
    RECHARGE_ORDER_TITLE: 'Thông tin đặt hàng',
    RECHARGE_ORDER_MSG: 'Vui lòng hoàn thành giao dịch trong thời hạn và nhấp vào nút "Xác nhận Thanh toán". Nếu quá thời hạn mà giao dịch không được hoàn tất, hệ thống sẽ tự động hủy đặt cọc.',
    RECHARGE_CREATE_ORDER_BTN: 'Tạo đơn hàng',
    RECHARGE_RULE: 'Quy tắc gửi tiền',
    RECHARGE_COPYEND: 'Tôi đã sao chép nó',
    RECHARGE_SAVEQRCODE: 'Tôi đã lưu mã QR',
    RECHARGE_NO_SAVEQRCODE: 'Không hỗ trợ mã QR',
    RECHARGE_SIDE_HEADER_TITLE: 'Gửi tiền',
    RECHARGE_SIDE_ALERT_TEXT1_1: '1. Vui lòng sử dụng %{msg} và chuyển vào tài khoản trên.',
    RECHARGE_SIDE_ALERT_TEXT2_1: '2. Đối với mã QR, vui lòng sử dụng %{msg} để quét và gửi tiền. Nếu bạn có tài khoản ngân hàng, vui lòng sử dụng% {msg} để chuyển vào thẻ của bạn.',
    RECHARGE_SIDE_ALERT_TEXT3_1: '3. Khi gửi tiền, vui lòng ghi ID tài khoản của bạn vào phần nhận xét để có thể kiểm tra ngay.',
    RECHARGE_SIDE_ALERT_TEXT4_1: '4. Vui lòng nhập biệt hiệu %{msg} của bạn và số tiền phí một cách chính xác. Xin lưu ý rằng biệt hiệu %{msg} của bạn không phải là ID %{msg} tài khoản Phí của bạn.',
    RECHARGE_SIDE_ALERT_TEXT5_1: '5. Vui lòng chuyển khoản trước khi gửi đơn hàng. Nếu bạn không làm như vậy, bạn sẽ không thể xác nhận số tiền kịp thời, vì vậy vui lòng không gửi trùng lặp.',
    RECHARGE_SIDE_ALERT_BTN_TEXT: 'Hiểu không.',
    RECHARGE_SIDE_OPEN_POP_STATE0_INFO: 'Bạn vui lòng đợi một lúc để hệ thống hoàn tất quá trình nạp tiền',
    RECHARGE_SIDE_OPEN_POP_STATE1_INFO: 'Quá trình hoàn tất. Hệ thống đã ghi có số tiền vào tài khoản của bạn.',
    RECHARGE_SIDE_OPEN_POP_STATE2_TITLE: 'Không gửi được tiền',
    RECHARGE_SIDE_OPEN_POP_STATE2_INFO: 'Hệ thống đã hủy khoản tiền gửi này vì giao dịch sẽ không được hoàn thành ngay cả khi hết thời hạn.',
    RECHARGE_SIDE_OPEN_POP_STATE4_INFO: 'Vui lòng hoàn thành giao dịch trong thời hạn và nhấp vào nút "Xác nhận Thanh toán". Nếu quá thời hạn mà giao dịch không được hoàn tất, hệ thống sẽ tự động hủy đặt cọc.',
    RECHARGE_SIDE_OPEN_POP_ERROR_TITLE: 'Không gửi được tiền',
    RECHARGE_SIDE_OPEN_POP_ERROR_INFO: 'Vui lòng hoàn thành giao dịch trong thời hạn và nhấp vào nút "Xác nhận Thanh toán". Nếu quá thời hạn mà giao dịch không được hoàn tất, hệ thống sẽ tự động hủy đặt cọc.',
    RECHARGE_SIDE_BANKITEM_WALLET_ACCOUNT_NAME: 'Ví tiền ID',
    RECHARGE_SIDE_BANKITEM_WALLET_NICK_NAME: 'Biệt hiệu ví',
    RECHARGE_SIDE_BANKITEM_UNION_ACCOUNT: 'Tài khoản UnionPay',
    RECHARGE_SIDE_BANKITEM_UNION_ACCOUNT_NAME: 'Mạng không mạnh, vui lòng nhấp để làm mới',
    RECHARGE_SIDE_BANKITEM_UNION_NAME: 'Chủ UnionPay',
    RECHARGE_SIDE_BANKITEM_ACCOUNT: 'Số tài khoản',
    RECHARGE_SIDE_BANKITEM_NAME: 'Chủ tài khoản',
    RECHARGE_SIDE_BANKITEM_BANK_NAME: 'Ngân hàng',
    RECHARGE_SIDE_BANKITEM_ALI_ACCOUNT: 'Tài khoản alipay',
    RECHARGE_SIDE_BANKITEM_RECEIVING_ACCOUNT: 'Số tài khoản',
    RECHARGE_SIDE_BANKITEM_ALI_NAME: 'Người giữ Alipay',
    RECHARGE_SIDE_BANKITEM_OPEN_AN_ACCOUNT: 'Tổ chức tài chính',
    RECHARGE_SIDE_BANKITEM_OPEN_API_ALERT: 'Số tiền gửi là khác nhau.',
    RECHARGE_SIDE_BANKITEM_OPEN_API_ALERT1: 'Số tiền tối thiểu của phương thức gửi tiền này',
    RECHARGE_SIDE_BANKITEM_AISLE: 'Kênh',
    RECHARGE_BASEBANKDATA_SELECTMONEY_INFO: 'Số tiền không được vượt quá số tiền tối đa.',
    RECHARGE_BASEBANKDATA_DECIMAL_MSG: 'Để xác nhận số tiền của bạn chính xác hơn, hệ thống đã điều chỉnh số tiền gửi thành %{msg}.',
    RECHARGE_BASEBANKDATA_CHANGEURL_MSG: 'Tôi đã gửi cho bạn một yêu cầu rút tiền. Vui lòng kiểm tra xem bạn sẽ nhận được nó sau 3-5 phút.',
    RECHARGE_BASEBANKDATA_CHANGEURL_BTN1: 'Xem lịch sử',
    RECHARGE_BASEBANKDATA_CHANGEURL_BTN2: 'Trên trang này',
    RECHARGE_BASEBANKDATA_ERROR_PASSWORD_EMPTY: 'Mã bảo mật là một mục bắt buộc.',
    RECHARGE_BASEBANKDATA_ERROR_SAFEPASSWORD: 'Định dạng mã bảo mật là khác nhau.',
    RECHARGE_BASEBANKDATA_ERROR_EMPTY: 'Đây là một mục đầu vào bắt buộc.',
    RECHARGE_BASEBANKDATA_ERROR_TYPE: 'Định dạng không chính xác.',
    RECHARGE_BASEBANKDATA_ERROR_CHARGE_MONEY: 'Phạm vi số tiền gửi là từ %{min} đồng đến %{max} đồng',
    RECHARGE_BASEBANKDATA_ERROR_BANK_EMPTY: 'Tổ chức tài chính của tài khoản là mục nhập bắt buộc.',
    RECHARGE_BASEBANKDATA_ERROR_BANKCARD_EMPTY: 'Số thẻ là một mục nhập bắt buộc.',
    RECHARGE_BASEBANKDATA_ERROR_BANKCARD_TYPE: 'Định dạng của số thẻ là khác nhau.',
    RECHARGE_BASEBANKDATA_ERROR_NAME_EMPTY: 'Chủ tài khoản là mục nhập bắt buộc.',
    RECHARGE_BASEBANKDATA_ERROR_NAME_TYPE: 'Định dạng của chủ sở hữu là khác nhau.',
    RECHARGE_BASEBANKDATA_ADD_EMPTY: 'Vị trí là một mục nhập bắt buộc.',
    RECHARGE_BASEBANKDATA_EMPTY: 'Đây là một mục đầu vào bắt buộc.',
    RECHARGE_BASEBANKDATA_ERROR_BANKNAME_EMPTY: 'Tên tổ chức tài chính là một mục nhập bắt buộc.',
    RECHARGE_BASEBANKDATA_ERROR_BANKNAME_TYPE: 'Tổ chức tài chính',
    RECHARGE_BASEBANKDATA_SETINTERVALFUNC_MSG: 'Vui lòng không gửi đơn đặt hàng thường xuyên. (Vui lòng thử lại sau %{msg}.)',
    SECOND: 'Giây',
    MINUTE: 'Phút',
    RECHARGE_BASEBANKDATA_TEXT: 'Giữa',
    RECHARGE_BASEBANKDATA_JDNAME: 'Tên tài khoản Jin Dong',
    RECHARGE_BASEBANKDATA_JDMSG: 'Vui lòng nhập tên tài khoản Jin Dong của bạn.',
    FORGOTPASSWORD_TITLE: 'Lấy lại mật khẩu',
    FORGOTPASSWORD_INFO: 'Lấy lại mật khẩu từ tài khoản của bạn.',
    FORGOTPASSWORD_INPUT_LABEL: 'Đăng nhập  ID',
    FORGOTPASSWORD_SELECT_TYPE_SAFEPASSWORD: 'An toàn để sử dụng',
    FORGOTPASSWORD_CONTACT_SERVICE: 'Vui lòng liên hệ với chúng tôi để được giải đáp thắc mắc trực tuyến.',
    FORGOTPASSWORD_NEXT_BTN: 'Tiếp theo',
    FORGOTPASSWORD_QAINPUT: 'Vui lòng nhập câu trả lời của bạn.',
    FORGOTPASSWORD_ALERT_MSG: 'Dịch vụ',
    FORGOTPASSWORD_SHOWPROMPT_MSG: 'Mật khẩu không chính xác, vì vậy hãy nhập lại.',
    FORGOTPASSWORD_SHOWPROMPT_CONFIRM_MSG: 'Đăng nhập ngay sau khi sửa đổi thành công hay không',
    FORGOTPASSWORD_SHOWPROMPT_CONFIRM_OKBTN: 'Đăng nhập',
    FORGOTPASSWORD_SHOWPROMPT_CONFIRM_CANCELBTN: 'Quay lại trang chủ',
    MEMBERCENTER_CONFIRM_PW_YES: 'đúng',
    MEMBERCENTER_CONFIRM_PW_NO: 'Không',
    SET_PHONE_NUMBER_LABEL: 'Số điện thoại di động',
    SET_PHONE_NUMBER_PLACEHOLDER: 'Vui lòng nhập số điện thoại di động của bạn.',
    SET_PHONE_NUMBER_MAIL_LABEL: 'Mã xác minh SMS',
    SET_PHONE_NUMBER_MAIL__PLACEHOLDER: 'Vui lòng nhập mã xác minh SMS.',
    SET_PHONE_SENDING: 'Gửi',
    SET_PHONE_CONFIRM_MSG: 'Không thể sử dụng tích hợp điện thoại di động được bảo vệ bằng mật khẩu trong một thời gian.',
    BASELOTTERY_NET_ERROR: 'Do thời gian máy chủ không đồng bộ được nên sẽ không đầu tư được, bạn vui lòng kiểm tra lại tình hình mạng',
    // PC
    PC_SAFE_VERIFY: 'Xác minh an toàn',
    PC_KLINE_CHART: 'Nên',
    PC_TREND_CHART: 'Đang chạy',
    PC_MARKET_CHART: 'Báo giá',
    PC_SYSETM_BUILD: 'Hệ thống đang được xây dựng và không thể mở được trong thời gian này',
    PC_STOCK_INDEX: 'Tên giao dịch',
    PC_ISSUE: 'Con số',
    PC_OUTGOING_INDEX: 'Chỉ số',
    PC_TREND: 'Xu hướng',
    PC_INSTANT_PROFIT: 'Đang chạy',
    PC_TOTAL_COUNT: 'Toàn bộ',
    PC_TOTAL_MATH: 'Toàn bộ',
    PC_NOT_SELECTED: 'Không được chọn',
    PC_DOUBLE_DISC: 'Đôi bên %{num} ¥ %{total}',
    PC_POSITION_DISC: 'Định vị %{num} ¥ %{total}',
    PC_PLAY_EXPLAN: 'Hướng dẫn',
    PC_MODEL: 'người mẫu',
    PC_GENERAL_DEAL: 'Giao dịch chung',
    PC_CHASE_DEAL: 'Theo dõi giao dịch',
    PC_STATE: 'Trạng thái',
    PC_STOP_WAREHOUSE: 'Kết thúc',
    PC_BET_STATUS: 'Trạng thái',
    PC_WAREHOUSE_NUMBER: 'Con số',
    PC_START_DATE: 'Từ %{num}',
    PC_TRAKING_STATUS: 'Theo dõi tình trạng',
    PC_PROFTI_AMOUNT: 'Lợi nhuận',
    PC_SUMBIT_ORDER: 'Chúng tôi đã gửi đơn đặt hàng của bạn trong vòng 5 phút',
    PC_SUMBIT_ORDER2: 'Chúng tôi sẽ thêm các khoản tín dụng.',
    PC_BET_DETAILS: 'Chi tiết đơn hàng',
    PC_TRACKING_DETAILS: 'Theo dõi chi tiết',
    PC_DEAL_CENTER: 'Trung tâm giao dịch',
    PC_WALLET: 'Ví tiền',
    PC_ORDER_SUCCESS: 'đặt hàng thành công',
    PC_ORDER_FAIL: 'Đặt hàng không thành công',
    PC_CHANGE_IMG: 'Thay đổi hình ảnh',
    PC_CEKECK_INTEGRAL: 'Xem điểm hiện tại',
    PC_ZOOM: 'Thu phóng khu vực',
    PC_BACK_ZOOM: 'Khôi phục thu phóng khu vực',
    PC_OPEN_HORIZONTAL: 'Bật lựa chọn ngang',
    PC_CLEAR_CHECKBOX: 'Xóa tất cả các hộp kiểm',
    PC_SAVE_IMG: 'Lưu dưới dạng hình ảnh',
    PC_NO_DATA: 'Chưa có dữ liệu',
    PC_TRADE_EVERY: 'Mỗi',
    PC_TRADE_TIME: 'lần',
    PC_CHASE_DATE: 'Thời gian theo dõi',
    PC_IN_TOTAL: '%{num} trong tất cả',
    PC_ANNOUNCE_NEW: 'Sự thông báo',
    PC_CANCEL_ORDER: 'Hủy bỏ',
    PC_RULE_LOCATION: 'Từ vị trí của người chiến thắng và người về nhì, chọn tối thiểu 1 số và tối đa 6 số, mỗi số được chọn trùng với số trúng thưởng ở cùng vị trí.',
    PC_RULE_DOUBLE: 'Quy tắc mua: Hai chữ số sau dấu thập phân của chỉ số thị trường là giá trị chuẩn, chữ số đầu tiên là số vô địch và số thứ hai là số á quân. Khi mỗi số tương ứng lớn hơn 4 (không bao gồm 4) là "Trỗi dậy", nhỏ hơn 4 (gồm 4) là "Ngã", các số lẻ là "Odd" và các số chẵn là "Even". Bò đực và gấu quy định PK lớn. Khi nhà vô địch lớn hơn người về nhì, đó là "Bull" và nhỏ hơn là "Bear". Khoản đầu tư hiện tại không thể đầu tư vào sự thăng trầm, lẻ và chẵn, bò và gấu cùng một lúc. Khi nhà vô địch và á quân hiển thị cùng một số với "Tie", tiền gốc sẽ được hoàn trả.',
    TRADE_INVEST_SINGLE: 'Một lượng',
    TRADE_INVEST_COUNT: 'đếm',
    PC_PREV_BTN: 'Trước',
    PC_NEXT_BTN: 'Kế tiếp',
    TRADE_CHASE_EXPECTED_PRIZE_TIME: 'Thời gian đặt hàng',
    TRADE_CHASE_CONFIRM: 'Theo dõi xác nhận đơn hàng',
    TRADE_CHASE_START_ISSUE: 'Thời gian theo dõi',
    TRADE_CHASE_COUNT: 'Theo dõi số lượng',
    PC_ACCOUNT_BANK_CARD: 'Thẻ',
    PC_DATE_MULT: '%{chase} kỳ %{mult} lần',
    // 用戶中心
    PCMEMBER_ACCOUNT: 'Tài khoản',
    PCMEMBER_MEN: 'Nam giới',
    PCMEMBER_WOWAN: 'Giống cái',
    PCMEMBER_SAVE: 'lưu',
    PCMEMBER_NOT_BIND: 'Không ràng buộc',
    PCMEMBER_SET: 'Bộ',
    PCMEMBER_BIND: 'Trói buộc',
    PCMEMBER_BIND_NOW: 'Ràng buộc',
    PCMEMBER_SET_NOW: 'Bộ',
    PCMEMBER_EDIT: 'chỉnh sửa',
    PCMEMBER_OVERVIEW: 'Tổng quat',
    PCMEMBER_SUMMARY: 'Tóm lược',
    PCMEMBER_NOTE_CONTENT: 'Nhận xét',
    PCMEMBER_TOTAL_RECORD: 'Tổng số bản ghi %{num}',
    PCMEMBER_COPY_WATER: 'Tôi đã sao chép',
    PCMEMBER_SEVEN_DAY: '7 ngày',
    PCMEMBER_DEAL_ING: 'Chế biến',
    PCMEMBER_DEAL_SUCCESS: 'Sự thành công',
    PCMEMBER_DEAL_FAIL: 'Thất bại',
    PCMEMBER_SUCCESS: 'Sự thành công',
    PCMEMBER_ERROR: 'Thất bại',
    PCMEMBER_GRADE: 'Cấp độ',
    PCMEMBER_INCREASE: 'Cộng 1 điểm cho mỗi 1 nhân dân tệ được nạp',
    // 登入頁面
    PCLOGIN_PREV: 'lùi bước',
    PCLOGIN_FIND_PASSWORD: 'Các câu hỏi bảo vệ bằng mật khẩu có thể giúp tài khoản của bạn an toàn hơn và thậm chí có thể khôi phục được mật khẩu',
    PCLOGIN_WITHDRAWAL: 'Mã bảo mật có thể được sử dụng cho các hoạt động như rút tiền và thêm thẻ để bảo vệ tiền',
    PCLOGIN_PASSWORD_ERROR: 'Mã bảo mật không chính xác, vui lòng thử lại',
    PCLOGIN_ENTER_ANSWER: 'Vui lòng nhập câu trả lời của bạn',
    PCLOGIN_NEW_PASSWORD: 'Mật khẩu mới',
    PClOGIN_SUCCESS_FIX: 'Việc thay đổi mật khẩu đăng nhập đã hoàn tất. Bạn có muốn đăng nhập ngay bây giờ?',
    PCLOGIN_ACCOUNT_NUMBER: 'Số nhập khẩu',
    PCLOGIN_VERIFICATION: 'Phương pháp lựa chọn',
    PCLOGIN_PASS_ID: 'Xác thực người',
    PCLOGIN_SET_PASSWORD: 'Đặt lại mật khẩu',
    PCLOGIN_CONFIRM_VISITING: 'Kiểm tra trang web bạn đang sử dụng',
    PCLOGIN_HAVE_ACCOUNT: 'Bạn đã có tài khoản chưa?',
    PCLOGIN_ACCOUNT: 'Số tài khoản',
    PCLOGIN_SIGN_UP: 'Đăng ký ngay',
    PC_NOT_ACCOUNT: 'Chưa có tài khoản',
    PC_SUCCESS_SING: 'Chúc mừng bạn đã đăng ký thành công',
    // layout
    LAOUT_SAFE_CONFIRM: 'Chứng chỉ an toàn',
    LAYOUT_APP_DOWNLOAD: 'Quét để tải xuống APP',
    LAYOUT_BROWSER_SUGGEST: 'Trình duyệt được đề xuất: Safari / Google Chrome',
    LAYOUT_ACCOUNT_OVERAGE: 'Số dư tài khoản',
    LAYOUT_ASSETS_MANAGE: 'Tài sản',
    // Recharge.vue
    PC_RECHARGE_EXTENDS_TRADESELECTION_LABELTITLE: 'Kênh gửi tiền',
    PC_RECHARGE_EXTENDS_FROMLISTBETMONEY_LABELTITLE: 'Số tiền đặt cọc',
    PC_RECHARGE_EXTENDS_FROMLISTBETNAME_LABELTITLE: 'Tên người gửi tiền',
    PC_RECHARGE_EXTENDS_FROMLISTQRCODESHOW_LABELTITLE: 'Thông tin tài khoản',
    PC_RECHARGE_EXTENDS_FROMLISTACCOUNTNAME_LABELTITLE: 'Chủ tài khoản',
    PC_RECHARGE_EXTENDS_FROMLISTBANKNAME_LABELTITLE: 'Tổ chức tài chính',
    PC_RECHARGE_EXTENDS_FROMLISTBANKCARDNUMBER_LABELTITLE: 'Số tài khoản',
    PC_RECHARGE_BTN: 'Gửi',
    PC_RECHARGE_ILLUSTRATE: 'Chú ý',
    PC_RECHARGE_NOT_EMPTY: 'Trường này không thể để trống',
    PC_RECHARGE_LENGTH_ERROR: 'Lỗi định dạng độ dài',
    PC_RECHARGE_INPUTPLACEHOLDER: 'Vui lòng nhập số tiền gửi',
    PC_RECHARGE_SELECTTYPEPLACEHOLDER: 'Không được chọn',
    PC_RECHARGE_BANKPAGE_BETNAMEPLACEHOLDER: 'Xin hãy nhập tên của bạn',
    PC_RECHARGE_DROPDOWN_TEXT: 'Quét mã QR thanh toán',
    PC_RECGARGE_BANKPAGE_BETNAMEPLACEHOLDER: 'Xin hãy nhập tên của bạn',
    PC_RECGARGE_DROPDOWN_TEXT: 'Quét mã QR thanh toán',
    PC_RECHARGE_MAX_ERROR: 'Lỗi số tiền tối đa',
    PC_RECHARGE_MIN_ERROR: 'Lỗi số tiền tối thiểu',
    PC_RECHARGE_NUMBER_ERROR: 'Vui lòng nhập một đơn vị số',
    PC_RECHARGE_SUCCESS_DEAL: 'Đơn đặt hàng đã được gửi thành công. Hoàn tất thanh toán trên trang mới mở',
    PC_ANNOUNCEMENT_NEXT: 'Kế tiếp',
    PC_ANNOUNCEMENT_PREV: 'Trước',
    PC_CURRENCY_COUNT: 'Số lượng tiền kỹ thuật số',
    PC_CURRENCY_RANGE: 'Giới hạn tiền kỹ thuật số %{min} - %{max}',
    PC_CURRENCY_TIP: 'Tỷ lệ ký quỹ {converRate}, số tiền ký quỹ {total}',
    PC_CURRENCY_ADDRESS: 'Địa chỉ thanh toán',
    PC_CURRENCY_DEAL: 'Giao dịch tiền tệ',
    PC_CURRENCY_CHOOSE: '%{msg}, ảnh chụp màn hình giao dịch, vui lòng chọn một cái và điền và',
    PC_MIN_MAX_AMOUNT: 'Giới hạn giao dịch %{min}-%{max} ',
    CURRENCY_DIGITAL: 'Tiền kỹ thuật số',
    STOCK_1801: 'Bitcoin',
    STOCK_1803: 'ETH(USA)',
    STOCK_1804: 'ETH(CN)',
    STOCK_1811: 'CSI 300',
    STOCK_1812: 'SZSE 100',
    STOCK_1813: 'SSEC',
    STOCK_1814: 'SZSE B ',
    STOCK_1815: 'SZSEInnovate',
    STOCK_1816: 'SZSE 1000',
    STOCK_1817: 'SZSE Central Company',
    STOCK_1818: 'Shenzhen IT',
    STOCK_1819: 'SZScientific',
    STOCK_1821: 'SSEC',
    STOCK_1822: 'SSEC A',
    STOCK_1823: 'SSEMarket100',
    STOCK_1824: 'SSE 100',
    STOCK_1825: 'SSE Mega-cap',
    STOCK_1826: 'SSEFinancial',
    STOCK_1827: 'SSE Consumer',
    STOCK_1828: 'SSE Dividend',
    STOCK_1829: 'SSE 380',
    STOCK_1831: 'Business',
    STOCK_1832: 'SZSE Culture',
    STOCK_1833: 'KweichowMoutai',
    STOCK_1834: 'CSI Media',
    STOCK_1835: 'CCTV Culture',
    STOCK_1836: 'Yili Group',
    STOCK_1837: 'Bestore ',
    STOCK_1838: 'LuzhouLaojiao',
    STOCK_1839: '3 Squirrels',
    STOCK_1840: 'Yanker Shop ',
    STOCK_1851: 'Spot goldUSA',
    STOCK_1852: 'SpotPlatinum',
    STOCK_1854: 'Spot gold CN',
    STOCK_1861: 'Joyoung',
    STOCK_1862: 'BDStar',
    STOCK_1863: 'BearElectric',
    STOCK_1871: 'CAC 40',
    STOCK_1872: 'BEL20',
    STOCK_1881: 'Microsoft',
    STOCK_1882: 'Apple',
    STOCK_1883: 'Amazon',
    STOCK_1884: 'NASDAQ',
    STOCK_1885: 'NASDAQ 100'
  }
}
