import Vue from 'vue'
// import VueResource from 'vue-resource'
import axios from 'axios'
import qs from 'qs'
import { Dialog, Toast } from 'vant'
import router from '@/router'
// import { imul } from 'core-js/fn/number'
import i18n from '@/utils/i18n'
import { apiResMsg } from '@/utils'
import store from '@/store'

Vue.use(Dialog)
Vue.use(Toast)
axios.defaults.timeout = 15000
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'
// axios.defaults.headers.baseURL = 'http://m.dfcdn40.com:8000'
// axios.defaults.headers.baseURL = 'http://54.254.138.188:8000'
// let sendDate = null
axios.interceptors.request.use((config) => {
  // sendDate = (new Date()).getTime()
  return config
}, (error) => {
  return Promise.reject(error)
})
// 返回状态判断
// let arry = []
// let timer = localStorage.getItem('timer')
axios.interceptors.response.use((res) => {
  const i18nMsg = apiResMsg(res, res.config.url)

  // 流量报表状态拦截
  if (res.config.url === '/v1/statistics/push' || res.config.url === '/v1/statistics/push/yy' || res.config.url === '/v1/management/content/saveLogsTable') {
    return ''
  }
  if (res.status === 408) {
    Dialog.alert({
      showCancelButton: false,
      className: 'alert-big',
      message: i18nMsg,
      title: i18n.tc('PRIVACY_HIT'),
      confirmButtonText: i18n.tc('CONFIRM')
    })
    return
  }

  if (res.status !== 200) {
    return Promise.reject(res)
  }
  // Toast.clear()
  delete axios.defaults.headers.random
  const code = res.data.code
  const userInfo = (localStorage.getItem('userInfo') && JSON.parse(localStorage.getItem('userInfo'))) || 0
  const pathName = location.pathname
  // localStorage.setItem('maintainData', '')
  localStorage.setItem('WebsiteClosed', '')
  switch (code) {
    case 0:
      if (res.config.url === '/v1/users/logout') return res
      // 非访问访客聊天室地址
      if (location.pathname.indexOf('chatGuest') === -1) {
        if (!userInfo) {
          if (location.pathname.indexOf('chat') !== -1) {
            router.push({
              path: '/login'
            })
          }
        } else {
          if (userInfo.login) {
            Dialog.confirm({
              showCancelButton: false,
              className: 'notice-big',
              title: i18n.tc('PRIVACY_HIT'),
              message: i18n.tc('AXIOS_STATE_LONG_TIME'),
              confirmButtonText: i18n.tc('CONFIRM')
            }).then(() => {
              localStorage.setItem('userInfo', 0)
              router.push({
                path: '/login'
              })
            // // window.document.location.href = '/login'
            }).catch(() => {
              // on cancel
            })
              .then(() => {
                localStorage.setItem('userInfo', 0)
                router.push({
                  path: '/login'
                })
                // // window.document.location.href = '/login'
              })
              .catch(() => {
                // on cancel
              })
          }
        }
      }
      return res
    case 1:
    case -30:// 出金简单安全密码专用
      return res
    case -4:
      return res
    case -6:
    case -8:
      errorRes(res, () => {
        let url = location.href.toLocaleLowerCase()
        url = url.indexOf('login') === -1
        if (url) {
          router.push({
            path: '/login'
          })
          // window.document.location.href = '/login'
          return res
        }
      })
      return res
    case -11:
    case -33:
      errorRes(res, () => {
        let url = location.href.toLocaleLowerCase()
        url = url.indexOf('login') === -1
        if (url) {
          router.push({
            path: '/login'
          })
          // window.document.location.href = '/login'
          return res
        }
      })
      return res
    case -7:
      errorRes1(res)
      return res
    case -12:
      errorRes2(res)
      return res
    case -19:
      localStorage.setItem('noserviceInfo', JSON.stringify(res.data))
      if (pathName !== '/noservice') {
        // router.push({path: '/noservice'})
        location.href = '/noservice'
      }
      return res
    case -3:
      return res
    // 限制撤单
    case -41:
      // 關閉撤單 處理loading狀態
      return res
    default:
      errorRes(res)
      return res
  }
}, (error) => {
  if (error.response.status === 403 || error.response.status === 503) {
    Dialog.confirm({
      showCancelButton: false,
      className: 'notice-big',
      title: i18n.tc('PRIVACY_HIT'),
      message: i18n.tc('AXIOS_STATE_NET_ERROR'),
      confirmButtonText: i18n.tc('CONFIRM')
    })
    return error
  }
  if (error.response.config.url === '/v1/statistics/push' || error.response.config.url === '/v1/statistics/push/yy' || error.response.config.url === '/v1/management/content/saveLogsTable') {
    return error
  }
  if (location.pathname.indexOf('games') === -1) {
    loseInter()
  }
  if (error.response) {
    errorResCatch(error.response)
    return
  }
  errorResCatch(error)
  return Promise.reject(error)
})

export const rest = {
  create (url, params) {
    return new Promise((resolve, reject) => {
      axios.post(url, params, {
        method: 'post',
        headers: { 'Content-Type': 'multipart/form-data' }
      })
        .then(response => {
          resolve(response.data)
        }, error => {
          reject(error.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },
  post: (url, params) => {
    return new Promise((resolve, reject) => {
      axios.post(url, qs.stringify(params))
        .then(response => {
          resolve(response.data)
        }, error => {
          if (error.message === '取消请求') return
          reject(error.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },
  post2: (url, params) => {
    return new Promise((resolve, reject) => {
      axios.post(url, qs.stringify(params))
        .then(response => {
          resolve({ ...response.data, hasNoWarning: true })
        }, error => {
          if (error.message === '取消请求') return
          reject(error.data)
        })
        .catch((error) => {
          reject(error.data)
        })
    })
  },
  get: (url, params) => {
    return new Promise((resolve, reject) => {
      const uri = getStrUrl(url, params)
      axios.get(uri, {
        cancelToken: new axios.CancelToken(function executor (c) { // 设置 cancel token
          window.source = c
        })
      })
        .then(function (res) {
          // handle success
          resolve(res.data)
        }, error => {
          if (error.message === '取消请求') return
          reject(error.data)
        })
        .catch(function (error) {
          // handle error
          reject(error.data)
        })
    })
  }
}
export function getStrUrl (url, param) {
  let uri = '?'
  const params = param || {}
  for (const [key, value] of Object.entries(params)) {
    uri = uri + key + '=' + value + '&'
  }
  return url + uri
}

export function errorRes (error, fun) {
  // if (error.config.url.indexOf('apis') !== -1) return
  if (error.message === '取消请求' || error.hasNoWarning) return
  try {
    if (router.history.current.name === 'tradeMainOrder') return
  } catch (error) {
    console.log(error)
  }

  const i18nMsg = apiResMsg(error, error.config.url)

  if (i18nMsg) {
    Dialog.alert({
      showCancelButton: false,
      className: 'alert-big',
      title: i18n.tc('USR_056'),
      message: i18nMsg,
      confirmButtonText: i18n.tc('CONFIRM')
    }).then(error => {
      Dialog.close()
      store.dispatch('getLoading', false)
      if (fun) {
        localStorage.setItem('userInfo', '0')
        fun && fun()
      }
      return error
    })
  }
}
export function errorResCatch (error, fun) {
  if (error.message === '取消请求') return
  const res = error
  const msg = error?.data.respMsg
  /**
   * 如果沒有使用預設msg
   */
  const i18nMsg = i18n.tc(msg) || error.data.msg
  let message = i18nMsg
  if (error.code === 'ECONNABORTED') {
    message = error.message
  }
  Toast.clear()
  if (res.status === 404 && res.config.url.includes('getFrontCacheUpdatedAt')) return
  Dialog.confirm({
    showCancelButton: false,
    className: 'notice-big',
    title: i18n.tc('PRIVACY_HIT'),
    message: message || `${res.status}:${res.statusText}———url:${res.config.url}`,
    confirmButtonText: i18n.tc('CONFIRM')
  }).then((error) => {
    Dialog.close()
    if (fun) {
      localStorage.setItem('userInfo', '0')
      fun && fun()
    }
    return error
  }).catch(() => {
  })
    .then(error => {
      Dialog.close()
      if (fun) {
        localStorage.setItem('userInfo', '0')
        fun && fun()
      }
      return error
    })
    .catch(() => {})
}
export function errorRes1 (error) {
  Toast.clear()
  const res = error
  localStorage.setItem('maintainData', JSON.stringify(res.data.data))
  store.dispatch('actionSetHasMaintain', true)
  if (location.pathname === '/login') return
  window.document.location.href = '/login'
}
export function errorRes2 (error) {
  Toast.clear()
  const res = error
  localStorage.setItem('WebsiteClosed', JSON.stringify(res.data))
  if (location.pathname === '/websiteClosed') return
  window.document.location.href = '/websiteClosed?from=login'
}
export function loseInter () {
  if (!window.navigator.onLine) {
    return Dialog.confirm({
      showCancelButton: false,
      className: 'notice-big',
      title: i18n.tc('PRIVACY_HIT'),
      message: i18n.tc('AXIOS_STATE_LOCALNET_ERROR'),
      confirmButtonText: i18n.tc('CONFIRM')
    })
  }
}
