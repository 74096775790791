import { rest } from './index'
import { addMd5 } from './users'
import { getUsername } from '@/utils'
const Api = {
  frontPaySort: '/v1/transaction/frontAppPaySort',
  frontWithdrawRecord: '/v1/transaction/frontWithdrawRecord',
  frontWithdrawRecord2: 'v1/transaction/frontWithdrawRecord2',
  saveFrontWithdrawRecord: '/v1/transaction/saveFrontWithdrawRecord',
  getWechatPayment: '/v1/transaction/frontWechatPayment',
  getAlipayPayment: '/v1/transaction/frontAlipayPayment',
  getBankTransferPayment: '/v1/transaction/frontBankTransferPayment',
  getInternetBankPayment: '/v1/transaction/frontInternetBankPayment',
  getQQPayment: '/v1/transaction/frontQQPayment',
  getUnionpayPayment: '/v1/transaction/frontUnionpayPayment',
  frontJdpayPayment: '/v1/transaction/frontJdpayPayment', // 京东钱包
  frontQuickpassPayment: '/v1/transaction/frontQuickpassPayment', // 云闪付

  // 入金
  rechargeFrontPaymentRecord: '/v1/transaction/rechargeFrontPaymentRecord',
  createRechargeFrontPaymentRecord: '/v1/transaction/createRechargeFrontPaymentRecord', // 建立待付款充值訂單
  rechargeRecordPaid: '/v1/transaction/rechargeRecordPaid', // 訂單付款狀態更新
  // 交易记录查询
  withdrawRecordList: '/v1/transaction/frontTradingWithdrawRecordList',
  rechargeRecordList: '/v1/transaction/frontSummaryPaymentRecordList',
  transctionRecordList: '/v1/balance/getTransactionRecordsFront',
  frontPaymentSettings: '/v1/transaction/frontPaymentSettings',
  frontDigitalCurrency: '/v1/transaction/frontDigitalCurrency',
  virtualWalletBalance: 'v1/virtualWallet/myBalance', // 查詢虛擬帳戶餘額
  frontVirtualWalletPay: 'v1/transaction/frontVirtualWalletPay' // 虛擬帳戶說明
}
export default {
  /**
   * 交易服务
   * */
  // 获取前端入金排序列表
  getPaySort () {
    return rest.get(Api.frontPaySort)
  },
  // 微信支付入金页面请求接口
  getWechatPayment () {
    return rest.get(Api.getWechatPayment)
  },
  // 支付宝入金页面请求接口
  getAlipayPayment () {
    return rest.get(Api.getAlipayPayment)
  },
  // 银行转账接口页面请求接口
  getBankTransferPayment () {
    return rest.get(Api.getBankTransferPayment)
  },
  // 网银支付接口 --- 手机版不需要了
  getInternetBankPayment () {
    return rest.get(Api.getInternetBankPayment)
  },
  // QQ钱包接口
  getQQPayment () {
    return rest.get(Api.getQQPayment)
  },
  // 银联支付接口
  getUnionpayPayment () {
    return rest.get(Api.getUnionpayPayment)
  },
  // 京东钱包接口
  getFrontJdpayPayment () {
    return rest.get(Api.frontJdpayPayment)
  },
  // 云闪付接口
  getFrontQuickpassPayment () {
    return rest.get(Api.frontQuickpassPayment)
  },
  /**
   * 支付入金接口
   * */
  rechargeFrontPaymentRecord (params) {
    return rest.post(Api.rechargeFrontPaymentRecord, params)
  },
  rechargeFrontPaymentRecordFormData (params) {
    return rest.create(Api.rechargeFrontPaymentRecord, params)
  },
  createRechargeFrontPaymentRecord (params) {
    return rest.post(Api.createRechargeFrontPaymentRecord, params)
  },
  rechargeRecordPaid (params) {
    return rest.post(Api.rechargeRecordPaid, params)
  },
  /**
   * 出金模块
   * */
  getWithdrawRecord () {
    return rest.get(Api.frontWithdrawRecord)
  },
  getWithdrawRecord2 () {
    return rest.get(Api.frontWithdrawRecord2)
  },
  // 出金保存接口
  saveFrontWithdrawRecord (params) {
    params.safetyPassword = addMd5(getUsername(), params.safetyPassword)
    return rest.post(Api.saveFrontWithdrawRecord, params)
  },
  /**
   * 交易记录-出金记录
   * */
  withdrawRecordList (params) {
    return rest.get(Api.withdrawRecordList, params)
  },
  rechargeRecordList (params) {
    return rest.get(Api.rechargeRecordList, params)
  },
  transctionRecordList (params) {
    return rest.get(Api.transctionRecordList, params)
  },
  frontPaymentSettings (params) {
    return rest.get(Api.frontPaymentSettings, params)
  },
  frontDigitalCurrency (params) {
    return rest.get(Api.frontDigitalCurrency, params)
  },
  virtualWalletBalance (params) {
    return rest.get(Api.virtualWalletBalance, params)
  },
  frontVirtualWalletPay (params) {
    return rest.get(Api.frontVirtualWalletPay, params)
  }
}
