<template>
  <div
    class="childitem__group"
  >
    <div
      class="order childitem"
    >
      {{ childItem.bettingIssue }}
    </div>
    <div class="money childitem">
      {{ childItem.graduationCount }}
    </div>
    <div class="opennumber childitem">
      {{ childItem.bettingAmount }}
    </div>
    <div
      class="bones childitem"
    >
      {{ childItem.prizeTime }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    childItem: {
      type: Object,
      required: true
    }
  },
  computed: {
    openNumberWait () {
      return this.childItem.openNum === '-' ? '-- -- --' : this.childItem.openNum
    }
  }
}
</script>

<style lang="scss" scoped>
.childitem__group {
  display: flex;
  align-items: center;
  overflow-x: hidden;
  padding-right: 15px;
  padding-left: 20px;
  height: 24px;
  font-size: 12px;
  transition: 0.5s;

  &:hover {
    background-color: var(--popupChaseChildItemHoverBgc);
  }
}

.childitem {
  width: 20%;
  text-align: left;
  color: #879AAF;

  &:first-child,
  &:last-child {
    width: 30%;
  }
}
</style>
