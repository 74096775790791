/* eslint-disable */
import Vue from 'vue'
import { Dialog, Toast } from 'vant'
import api from '@/api/lottery'
import router from '@/router'
import i18n from '@/utils/i18n'
import { apiResMsg2 } from '@/utils'

Vue.use(Dialog)
const setUserName = (str) => {
  const rgb = /(\w{2})\w+(\w{1})/
  return str.replace(rgb, '$1***$2')
}
const setMoney = (data) => {
  let num = 0
  if (data && data.length) {
    data.forEach(v => {
      num += v.bettingAmount * 1
    })
  }
  return num
}
// 期号处理
const setIssue = (num) => {
  num = num + ''
  if (num && num.length) {
    return num.length > 8 ? num.substr(4) : num
  } else {
    return ''
  }
}
/** 添加HTML */
const setHtml = (data, key) => {
  let str = ''
  if (data && data.length) {
    data.forEach(v => {
      str += ((key ? v.bettingNumber : v.desc) + '<br>')
    })
  }
  if (key) {
    str = str.split(',').join(' ')
  }
  return str
}
const getAllRebate = () => {
  const userInfo = JSON.parse(localStorage.userInfo)
  api.getAllBetRebate().then((res) => {
    if (res.code === 1) {
      const object = {}
      object[userInfo.rebateupdatedAt] = res.data
      if (userInfo.userName !== 'undefined') { localStorage.setItem(`rebateAll${userInfo.userName}`, JSON.stringify(object)) }
    }
  })
}

const postLottery = (Basket, fun) => {
  if (Basket[0].shareFrom) {
    delete Basket[0].shareFrom
  }
  const Data = {
    bettingData: JSON.stringify(Basket)
  }

  console.log('betting')

  api.addBetting(Data).then(ref => {
    // this.$toast.clear()
    // let dom = document.querySelector('.van-modal')
    // dom && (dom.className = 'van-modal share')
    if (ref.code === 1) {
      fun && fun()
      // 投注成功
      Dialog.confirm({
        showCancelButton: true,
        // cancelButtonText: '继续跟投',
        closeOnClickOverlay: false,
        confirmButtonText: i18n.tc('ELEMENT_CHECK_BET_SLIP'),
        // confirmButtonText: '确认',
        className: 'notice lottery-dialog lotteryType',
        title: i18n.tc('TRADE_TIPS'),
        message: `<div class="text-center"><span class="van-bet-money">${i18n.tc('ELEMENT_SEACH_RECORD')}</span></div>`
      }).then(() => {
        console.log('查看投注记录')
        router.push({ name: 'warehouseReceipt' })
        // dom && (dom.className = 'van-modal')
      }).catch(() => {
        // dom && (dom.className = 'van-modal')
        // console.log('关闭')
      })
    } else {
      // 投注失败
      // this.msgInfo(ref.msg)
      const msg = ref.msg
      if (msg.indexOf('投资期号错误') > -1) {
        // msg = '当前分享投注已过期'
      }
      if (msg.indexOf('返点不正确') > -1) {
        getAllRebate()
      }
      Toast.clear()
      if (msg.indexOf('余额不足') > -1) {
        Dialog.alert({
          showCancelButton: true,
          cancelButtonText: i18n.tc('CANCEL'),
          confirmButtonText: i18n.tc('DEPOSIT'),
          closeOnClickOverlay: false,
          className: 'alert',
          title: apiResMsg2(ref)
        // title: '温馨提示',
        // message: `<div class="text-center">${msg}</div>`
        }).then(() => {
          window.location.href = '/recharge'
        // dom && (dom.className = 'van-modal')
        // if (ref.msg.indexOf('余额不足') === -1) {
        //   getAllRebate()
        // }
        }).catch(() => {
          // dom && (dom.className = 'van-modal')
        })
      } else {
        Dialog.alert({
          showCancelButton: false,
          confirmButtonText: this.$t('CONFIRM'),
          closeOnClickOverlay: false,
          className: 'alert',
          title: apiResMsg2(ref)
        // title: '温馨提示',
        // message: `<div class="text-center">${msg}</div>`
        }).then(() => {
          // dom && (dom.className = 'van-modal')
          // if (ref.msg.indexOf('余额不足') === -1) {
          //   getAllRebate()
          // }
        }).catch(() => {
          // dom && (dom.className = 'van-modal')
        })
      }
    }
  })
}
window.meelineState = {
  isShare: false,
  shareEdit: false, // 是否编辑金额
  shareEditData: null,
  postBetShow: false, // 是否展示推送计划
  postBetData: null, // 推送计划数据
  allMoney: 0,
  // meeline 必须方法和属性
  _App: '',
  UserName: '',
  meeline: 0,
  minimodel: 0,
  mlLoading: false, // 首页加载动画
  meelineOpenBy: '',
  meelineToken: null, // null,
  meelineLoading: 1,
  shareShow: false, // 分享开启
  shareData: null, // 分享数据
  recordshow: false,
  recordStatus: false,
  indexPopupState: false,
  indexPopupBtnState: false,
  roomCode: 0, // 房间号
  openLotteryState: false,
  playId: 0,
  // meeline-iframe 使用
  scrollTop () {
    document.body.parentElement.scrollTop = 999999999
    document.body.scrollTop = 999999999
  },
  meelineInit () {
    const state = this
    if (state.UserName) {
      const meeline = localStorage.getItem('meeline') * 1 || 0
      if (meeline) {
        state.meeline = 1
      } else {
        state.meeline = 0
      }
    }
  },
  meelineReset () {
    this.meelineToken = ''
  },
  openRecord () {
    this.recordStatus = true
  },
  openrecordshow () {
    this.recordshow = true
  },
  openLotteryRecord (id) {
    this.openLotteryState = true
    this.playId = id
  },
  meelineCallback (d) {
    const state = this
    const close = (msg) => {
      state.meeline = 0
      state.minimodel = 0
      state.meelineToken = ''
      localStorage.removeItem('meeline')
      localStorage.removeItem('minimodel')
      Dialog.confirm({
        showCancelButton: false,
        className: 'notice',
        title: '',
        message: `<div style="text-align:center">${msg}</div>`
      })
    }
    if (d.code === 1) {
      if (d.data.s !== '1') {
        close(d.data.m)
      }
    } else if (d.code === 0) {
      state.meeline = 0
      state.minimodel = 0
      state.meelineToken = ''
      Dialog.confirm({
        showCancelButton: false,
        className: 'notice',
        title: '',
        message: i18n.tc('AXIOS_STATE_LONG_TIME')
      })
    } else {
      if (d.msg) {
        close(d.msg)
        return
      }
      close('请求超时')
    }
  },
  share (json, fun) {
    // window.meelineState.minimodel = 1
    // 分享投注
    if (json.type === 1) {
      this.shareEditData = JSON.parse(JSON.stringify(json))
      if (!json.data) return
      const lotteryName = json.data[0].lotteryType
      const getMoney = setMoney(json.data)
      this.allMoney = getMoney
      let message = null
      if (lotteryName.indexOf('K3') > -1 || json.data[0].shareFrom === 'longQueue') {
        message = `<div>${i18n.tc('ELEMENT_SHARE_MEMBER')}：${setUserName(json.username)}</div></div><div>${json.data[0].lotteryName}: ${setIssue(json.data[0].bettingIssue)}${i18n.tc('TRADE_EXPECT')}</div><div>${i18n.tc('WAREHOUSE_RECORDCENTER_CHASENUMBER_BET_TITLE')}: <span class="van-bet-money share-bet-money">${this.allMoney}${i18n.tc('CHATCELL_DOLLAR')}</span><span class="share-edit-btn">${i18n.tc('REVISE')}</span></div><div class="van-betstr">${i18n.tc('ELEMNET_INVESTMENT_CONTENT')}: ${json.data[0].betDetail ? (Array.isArray(json.data[0].betDetail) ? json.data[0].betDetail.join('<br>') : json.data[0].betDetail) : setHtml(json.data, true)}</div>`
      } else {
        message = `<div>${i18n.tc('ELEMENT_SHARE_MEMBER')}：${setUserName(json.username)}</div></div><div>${json.data[0].lotteryName}: ${setIssue(json.data[0].bettingIssue)}${i18n.tc('TRADE_EXPECT')}</div><div>${i18n.tc('WAREHOUSE_RECORDCENTER_CHASENUMBER_BET_TITLE')}: <span class="van-bet-money share-bet-money">${this.allMoney}${i18n.tc('CHATCELL_DOLLAR')}</span><span class="share-edit-btn">${i18n.tc('REVISE')}</span></div><div>${i18n.tc('ELEMNET_INVESTMENT_CONTENT')}: </div><div class="van-betstr">${json.data[0].betDetail ? (Array.isArray(json.data[0].betDetail) ? json.data[0].betDetail.join('<br>') : json.data[0].betDetail) : setHtml(json.data)}</div>`
      }
      Dialog.confirm({
        showCancelButton: true,
        cancelButtonText: i18n.tc('CLOSE'),
        confirmButtonText: i18n.tc('ELEMENT_FOLLOW'),
        closeOnClickOverlay: false,
        className: 'lottery-dialog notice',
        title: i18n.tc('TRADE_SHARE_ORDER'),
        message: message,
        getContainer: 'body',
        beforeClose: (action, done) => {
          if (action === 'confirm') {
            done()
          } else {
            const dom = document.querySelector('.share-bet-money')
            if (dom) {
              dom.innerText = getMoney
            }
            done()
          }
        }
      // message: `<div class="shareTable">${msg}</div>`
      }).then(() => {
        // console.log('我跟投了')
        // window.meelineState.minimodel = 1
        // dom && (dom.className = 'van-modal')
        window.meelineState.shareShow = false
        if (this.shareEditData) {
          window.meelineState.nextBetting(this.shareEditData, fun)
        } else {
          window.meelineState.nextBetting(json, fun)
        }
      }).catch(() => {
        this.allMoney = getMoney
      // dom && (dom.className = 'van-modal')
      // console.log('我关闭了')
      // router.push('/lottery/K3/1402')
      })
    } else {
      // 分享战绩
    }
  },
  nextBetting (json, fun) {
    // 跟投函数
    const userInfo = JSON.parse(localStorage.userInfo)
    const userName = userInfo && userInfo.userName
    if (userName) {
      const lotteryType = json.data[0].lotteryType
      let backdata = JSON.parse(JSON.stringify(json.data))
      let suerRebate = localStorage.getItem('rebateAll' + userName)
      suerRebate = suerRebate && Object.values(JSON.parse(suerRebate))[0].allRebateData
      if (suerRebate && suerRebate[lotteryType]) {
        const rebate = suerRebate && suerRebate[lotteryType].rebate
        if (backdata) {
          delete backdata[0].lotteryType
          delete backdata[0].lotteryName
          delete backdata[0].betDetail
          delete backdata[0].playRebate
          delete backdata[0].desc
          delete backdata[0].shareFrom
        }
        backdata = backdata.map(v => {
          v.bettingPoint = rebate
          delete v.money
          return v
        })
        postLottery(backdata, fun)
      } else {
        getAllRebate()
      }
    }
  }
}
export const meelineState = {
  state: window.meelineState
}
