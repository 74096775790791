import apiUser from '@/api/users'

export default {
  /**
   * 判断是否是app
   * */
  setIsAPP ({ commit }, isApp) {
    commit('SET_IS_APP', isApp)
  },
  setHasError ({ commit }, { val, res }) {
    commit('SET_HAS_ERROR', val)
    commit('SET_ERROR_MESSAGE', res)
  },
  setMessageShow ({ commit }, flag) { // 站内信未读取
    commit('SET_UN_READER_SHOW', flag)
  },
  // setMessageNum ({ commit }, num) { // 几天信息新来的
  //   localStorage.setItem('getUnReadNum', JSON.stringify(num))
  //   commit('SET_MESSAGE_SHOW', num)
  // },
  setNoticeEnd ({ commit }, flag) {
    commit('SET_NOTICE_END', flag)
  },
  /**
   * 接口加载Loading
   */
  setInterfaceLoading ({ commit }, res) {
    commit('SET_INTERFACE_LOADING', res)
  },
  /**
   * 设置要更新的版本号
   * */
  setVersion ({ commit }, res) {
    commit('SET_VERSION', res)
  },
  /**
   * 设置siteConfig
   * */
  setSiteConfig ({ commit }, res) {
    commit('SET_SITE_CONFIG', res)
  },
  setIsFastShow ({ commit }, res) {
    commit('SET_ISFASTSHOW', res)
  },
  // actionSetThemeValue ({ commit }, val) {
  //   commit('SET_THEME_VALUE', val)
  // },
  actionSetHasNotify ({ commit }, val) {
    commit('SET_HAS_NOTIFY', val)
  },
  actionCloseHasNotify ({ commit }, val) {
    commit('CLOSE_HAS_NOTIFY', val)
  },
  actionSetNotifyIconName ({ commit }, val) {
    commit('SET_NOTIFY_ICON_NAME', val)
  },
  actionSetNotifyContent ({ commit }, val) {
    commit('SET_NOTIFY_CONTENT', val)
  },
  // stockIssueData setting
  actionSetStockIssueData ({ commit }, val) {
    commit('setStockIssueData', val)
  },
  // set OldIssue
  actionSetOldIssue ({ commit }, val) {
    commit('setOldIssue', val)
  },
  actionSetCancelBtnColor: ({ commit }, val) => {
    commit('setCancelBtnColor', val)
  },
  // set activityDetail
  actionSetActivityDetail: ({ commit }, val) => {
    commit('setActivityDetail', val)
  },
  // set setShareData
  actionSetShareData: ({ commit }, val) => {
    commit('setShareData', val)
  },
  // set setHasShareRoom
  actionSetHasShareRoom: ({ commit }, val) => {
    commit('setHasShareRoom', val)
  },
  // set hasLimitLoading
  actionSetHasLimitLoading ({ commit }, val) {
    commit('setHasLimitLoading', val)
  },
  // set setHasMaintain
  actionSetHasMaintain ({ commit }, val) {
    commit('setHasMaintain', val)
  },
  // set hasLimitLoading
  actionSetLimitMessage ({ commit }, val) {
    commit('setLimitMessage', val)
  },
  // set setHasSearch
  actionSetHasSearch ({ commit }, val) {
    commit('setHasSearch', val)
  },
  // set setIsBgFixed
  actionSetIsFixed ({ commit }, val) {
    commit('setIsBgFixed', val)
  },
  // set setIsBgMax
  actionSetIsBgMax ({ commit }, val) {
    commit('setIsBgMax', val)
  },
  actionSetHasProfitTable ({ commit }, val) {
    commit('setHasProfixTable', val)
  },
  actionSetHasProfitTableMore ({ commit }, val) {
    commit('setHasProfixTableMore', val)
  },
  // set setUserProfit
  actionSetUserProfit ({ commit }, val) {
    commit('setUserProfit', val)
  },
  // set setStockDetailData
  actionSetStockDetailData ({ commit }, val) {
    commit('setStockDetailData', val)
  },
  // set setUserBalance
  actionSetUserBalance ({ commit, state }) {
    apiUser.userBalance().then((e) => {
      if (e.code === 1) {
        commit('setUserBalance', e.data || '---')
        if (state.upadteBalance) {
          commit('setUpadteBalance', false)
        }
      }
    })
  },
  // set setTabActive
  actionSetTabActive ({ commit }, val) {
    commit('setTabActive', val)
  },
  // set setGraphType
  actionSetGraphType ({ commit }, val) {
    commit('setGraphType', val)
  },
  // set setTrendData
  actionSetTrendData ({ commit }, val) {
    commit('setTrendData', val)
  },
  // set setBetOrderList
  actionSetBetOrderList ({ commit, state }, val) {
    commit('setBetOrderList', val)
    if (!state.upadteBalance) {
      commit('setUpadteBalance', true)
    }
  },
  // set setChaseBetOrderList
  actionSetChaseBetOrderList ({ commit, state }, val) {
    commit('setChaseBetOrderList', val)
    if (!state.upadteBalance) {
      commit('setUpadteBalance', true)
    }
  },
  // setCurrentTab
  actionSetCurrentTab ({ commit }, val) {
    commit('setCurrentTab', val)
  },
  // set setChartTabData
  actionSetChartTabData ({ commit }, val) {
    commit('setChartTabData', val)
  },
  actionLogout ({ commit }, val) {
    commit('setLogout', val)
  },
  // lt_notifyData
  actionUpdateNotifyData: ({ commit }, val) => {
    commit('lt_notifyData', val)
  },
  // set lt_updateIssue
  actionUpdateIssue ({ commit }, val) {
    commit('lt_updateIssue', val)
  },
  actionUpdateNextIssue ({ commit }, val) {
    commit('lt_updateNextIssue', val)
  },
  actionsSetNewLotteryList: ({ commit }, val) => {
    commit('lt_setNewLotteryList', val)
  },
  actionsSetNewLotteryConfig: ({ commit }, val) => {
    commit('lt_setNewLotteryConfig', val)
  },
  // optionSides
  actionSetOptionSides ({ commit }, val) {
    commit('setOptionSides', val)
  },
  // setCuurentTag
  actionSetCurrentTag ({ commit }, val) {
    commit('setCuurentTag', val)
  }
}
