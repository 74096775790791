
export const confirmState = {
  state: {
    // 交易狀態
    confirmState: false
  },
  getters: {
    confirmState: state => state.confirmState
  },
  actions: {
    setConfirmState ({ commit }, val) {
      // commit(types.SET_ANCHOR_INFO, res)
      return new Promise((resolve, reject) => {
        commit('stateInfo', val)
      })
    }
  },
  mutations: {
    stateInfo (state, res) {
      state.confirmState = res
    }
  }
}
