export const prompt = {
  state: {
    showNotify: false,
    showNotifyType: 'def'
  },
  mutations: {
    switchNotify (state, data) {
      setTimeout(() => {
        state.showNotify = data.show
        state.showNotifyType = data.type
      }, 500)
      setTimeout(() => {
        if (state.showNotify) {
          state.showNotify = false
        }
      }, 2500)
    }
  },
  actions: {
  },
  modules: {
  }
}
