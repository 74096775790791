export default {
  data () {
    return {
      themeWord: '',
      baseTheme: {
        checkUse: '',
        fontBase: '',
        /**
         * app.vue
         */
        contentBgc: '',
        scrollBarTrackBgc: '',
        fireFoxScrollBar: '',
        fireScrollBarTrackShadow: '',
        fireScrollbarThumbBgc: '',
        vantDialogBgc: '',
        vantButtonDefaultBgc: '',
        vantDialogCancel: '',
        vApplicationBgc: '',
        menuRise: '',
        menufall: '',
        dropDownShadow: '',
        /**
         * Graph.vue
         */
        graphBgc: '',
        /**
         *
         */
        bankMangeBgc: '',
        bankMangeColor: '',
        bankMangeTitleBorderColor: '',
        /**
          * base系列
         */
        baseBtnColor: '',
        baseBtnHoverColor: '',
        baseBtnSuccessColor: '',
        baseBtnSuccessBgc: '',
        baseBtnSuccessHoverBgc: '',
        baseBtnBorder: '',
        baseInputColor: '',
        baseInputFocusColor: '',
        baseInputBorderColor: '',
        /**
         * mainText.vue
         */
        tradeMainTextProgressColor: '',
        tradeMainTextGetColorMoreZero: '',
        tradeMainTextGetColorSLessZero: '',
        tradeMainTextGetColorNotEqualZero: '',
        tradeMainTextGetColorEqualZero: '',
        tradeMainTextGetColorDef: '',
        tradeMainTextOrderColor: '',
        /**
         * tradeMain.vue
         */
        tradeMainTabLineBgc: '',
        tradeMainTabsNavColor: '',
        tradeMainTabsNavHoverColor: '',
        tradeMainTabsNavBgc: '',
        tradeMainItemActive: '',
        tradeMainIconBgc: '',
        tradeMainTabsNavColor2: '',
        tradeMainPcTitleColor: '',
        tradeMainPcBorderBottom: '',
        /**
         * trade.vue
         */
        tradeNavBgc: '',
        tradeNavMenuItemColor: '',
        tradeNavMenuItemIsActFFF: '',
        tradeListTitle: '',
        tradeListItemBgc: '',
        tradeListItemBgcHover: '',
        tradeListItemBgcActive: '',
        tradeListHeaderColor: '',
        tradeListItemContentColor: '',
        tradeListItemUpColor: '',
        tradeListItemDownColor: '',
        tradeVtabsBgc: '',
        tradeVtabsActive: '',
        tradeVtabsUrl: '',
        /**
         *trendRank.vue
         */
        trendRankHeaderColor: '',
        trendRankHeaderBorder: '',
        trendRankTableColor: '',
        trendRankTrendInfoColor: '',
        trendRankTrendInfoHoverBgc: '',
        trendRankUpColor: '',
        trendRankDownColor: '',
        trendRankNameColor: '',
        trendRankTdFirstChild: '',
        trendRankTdNth2Child: '',
        trendRankTdUp: '',
        trendRankTdDown: '',
        trendRankSlideBgc: '',
        trendRankUseIdColor: '',
        trendRankUserNameColor: '',
        trendRankProfit: '',
        trendRankBorderBgc: '',
        /**
         *Double.vue
         */
        doubleTitleColor: '',
        doubleItemWrapperBorder: '',
        doubleVanCellColor: '',
        doubleVanCellColor2: '',
        doubleCollapseContentColor: '',
        doubleCollapseContentBgc: '',
        doubleIconArrowUrl: '',
        doubleBtnActiveColor: '',
        doubleBtnActiveBgc: '',
        doubleOrderMoneyBorder: '',
        doubleOrderMoneyActiveBorder: '',
        doubleItemNameColor: '',
        doubleItemValueColor: '',
        doubleBoubleBtnColor: '',
        doubleBoubleBtnBgc: '',
        doubleBoubleBtnActiveBgc: '',
        doubleDropdownItemBorder: '',
        doubleDropdownItemShadow: '',
        doubleDropdownItemActiveColor: '',
        doubleDropdownItemActiveBgc: '',
        doubleDropdownItemTitleColor: '',
        doubleDropdownItemTitleBgc: '',
        doubleDropdownItemTitleHoverColor: '',
        doubleDropdownItemTitleHoverBgc: '',
        doubleDropdownVanCellColor: '',
        doubleDropdownVanCelBgc: '',
        doubleUnitBgc: '',
        doubleDropdownMenuColor: '',
        doubleDropdownMenuBorder: '',
        doubleVanEllipsisColor: '',
        doubleInputAreaVanCellBorder: '',
        doubleInputAreaCollapseItemBorder: '',
        doubleMaxIncome: '',
        doubleVanCellColor3: '',
        /**
         *indexBetTab.vue
         */
        indexBetTabTitleColor: '',
        indexBetTabTitleBorder: '',
        indexBetStockWrapQaColor: '',
        indexBetTabNavColor: '',
        indexBetVanTabColor: '',
        indexBetVanTabActiveColor: '',
        indexBetVanTabActiveBgc: '',
        indexBetSwiperBtnBgc: '',
        indexBetHoverBtnColor: '',
        indexBetActiveColor: '',
        indexBetBtnColor: '',
        indexBetMoveLineBgc: '',
        /**
         *locations.vue
         */
        locationInputSetColor: '',
        locationTitleColor: '',
        locationWrapperBorder: '',
        locationVanCellColor: '',
        locationIsShowVanCellColor: '',
        locationItemContentColor: '',
        locationItemContentBgc: '',
        locationItemIconArrowUrl: '',
        locationItemIconArrowUrlActive: '',
        locationItemActiveColor: '',
        locationItemTitleExpandedBorder: '',
        locationItemBtnActiveColor: '',
        locationItemBtnActiveBgc: '',
        locationItemOrderMoneyBorder: '',
        locationItemOrderMoneyActiveBorder: '',
        locationShowInfoItemNameColor: '',
        locationShowInfoItemValueColor: '',
        locationBoubleBtnColor: '',
        locationDropdownItemBorder: '',
        locationDropdownItemShadow: '',
        locationDropdownItemActiveColor: '',
        locationDropdownItemActiveBgc: '',
        locationCellTitleHoverColor: '',
        locationCellTitleHoverBgc: '',
        locationUnitVanCellColor: '',
        locationUnitVanCellBgc: '',
        locationUnitBgc: '',
        locationUnitDropdownTitleActiveColor: '',
        locationUnitDropdownEllipsisColor: '',
        locationChaseVanCellColor: '',
        locationChaseSwitchColor: '',
        locationChaseOnSwitchColor: '',
        locationInputWrapBorder: '',
        locationInputWrapBgc: '',
        locationInputInputBgc: '',
        locationSelectInputBorder: '',
        locationSelectInputActiveBorder: '',
        locationSelectRemarkerColor: '',
        locationPlayModeBgc: '',
        locationPlayModeBarOpenBorder: '',
        locationPlayModeVanPopupBgc: '',
        locationPlayModeTitleColor: '',
        locationBetInputAreaVanCellBorder: '',
        locationBetInputAreaTitleExpandedBorder: '',
        locationBetWrapBtnBgc: '',
        locationBoubleTitleColor: '',
        locationBoubleTitleBgc: '',
        locationBoubleTitleActiveColor: '',
        locationBoubleTitleActiveBorder: '',
        locationPlayTypeSpanColor: '',
        locationPlayTypeSpanBgc: '',
        locationPlayTypeSpanActiveColor: '',
        locationPlayTypeSpanActiveBgc: '',
        locationPlayTypeSpanActiveBorder: '',
        locationDobleBorder: '',
        locationDobleActiveBorder: '',
        locationDobleInputColor: '',
        locationUnitWrapColor: '',
        locationUnitWrapBgc: '',
        locationVanCellColor2: '',
        locationItemWrapperBgc: '',
        locationItemWrapperBorder: '',
        locationItemDivColor: '',
        locationItemHoverDivColor: '',
        locationItemHoverDivBgc: '',
        locationMaxIncomeColor: '',
        locationBetShowBorder: '',
        /**
         *postion.vue
         */
        postionTabActiveColor: '',
        postionTabLineColor: '',
        postionTabNavBgc: '',
        /**
         *indexWareHouse => chaseNumberBtns.vue
         */
        chaseNumberBtnsBtnsBgc: '',
        chaseNumberBtnsBtnColor: '',
        chaseNumberBtnsBtnActiveColor: '',
        chaseNumberBtnsBtnActiveBgc: '',
        chaseNumberBtnsDaysColor: '',
        chaseNumberDropdownTitleColor: '',
        chaseNumberDropdownTitleActiveColor: '',
        chaseNumberDropdownItemContentBorder: '',
        chaseNumberDropdownItemActiveColor: '',
        chaseNumberDropdownItemActiveBgc: '',
        chaseNumberDropdownVanCellTitleColor: '',
        chaseNumberDropdownVanCellColor: '',
        chaseNumberDropdownVanCellBgc: '',
        /**
         *indexWareHouse => selectBtns.vue
         */
        selectBtnsContentBtnsBgc: '',
        selectBtnsBtnColor: '',
        selectBtnsBtnActiveColor: '',
        selectBtnsBtnActiveBgc: '',
        selectBtnsDaysColor: '',
        selectBtnsDayDropdownTitleColor: '',
        selectBtnsDayDropdownActiveTitleColor: '',
        selectBtnsDayDropdownOptionColor: '',
        selectBtnsDayDropdownVanCellTitleColor: '',
        selectBtnsDayDropdownVanCellColor: '',
        selectBtnsDayDropdownVanCellBgc: '',
        /**
         *indexWareHouse => list=> Title => AccountTitle.vue
         */
        accountingTitleColor: '',
        /**
         *indexWareHouse => list=>
         */
        accountingItemBonesRiseColor: '',
        accountingItemIngColor: '',
        accountingItemPendingColor: '',
        accountingItemRiseColor: '',
        accountingItemItemStockColor: '',
        /**
         *indexWareHouse => list=>ChaseNumber
         */
        accountingItemChaseRiseColor: '',
        accountingItemChaseIngColor: '',
        accountingItemChaseColor: '',
        /**
         *indexWareHouse => popup
         */
        popupTitleColor: '',
        popupNameColor: '',
        popupValueColor: '',
        popupValuePendingColor: '',
        popupValueRiseColor: '',
        popupValueRecordCodeRiseColor: '',
        popupValueRecordCodePendingColor: '',
        popupValueRecordCodeFallColor: '',
        popupRevokeBtnColor: '',
        popupRevokeBtnBorder: '',
        popupRevokeBtnRevokeColor: '',
        popupRevokeBtnRevokeBgc: '',
        popupRevokeBtnRevokeActiveBgc: '',
        /**
         *indexWareHouse => popup => Chase.vue
         */
        popupChaseRevokeBtnCancelColor: '',
        popupChaseRevokeBtnCancelBorder: '',
        popupChaseRevokeBtnRevokeColor: '',
        popupChaseRevokeBtnRevokeBgc: '',
        popupChaseCollapseVanCellColor: '',
        popupChaseCollapseVanItemContentColor: '',
        popupChaseCollapseVanTitleContentBgc: '',
        popupChaseStepInfoColor: '',
        popupChaseStepInfoShowBgc: '',
        popupChaseChaseStepBgc: '',
        popupChaseIssueColor: '',
        popupChaseHaveValueColor: '',
        popupChaseStatePendingColor: '',
        popupChaseStateFallColor: '',
        popupChaseStateRiseColor: '',
        popupChaseStateIngColor: '',
        popupChaseSendBonesColor: '',
        popupChaseRadioItemTitleColor: '',
        popupChaseRadioItemVanSwitchBgc: '',
        popupChaseRadioItemVanOnSwitchBgc: '',
        popupCancelBgc: '',
        popupCancelActiveBgc: '',
        popupRadioBorder: '',
        /**
         *indexWareHouse => popup => ChaseChildItem.vue
         */
        popupChaseChildItemHoverBgc: '',
        popupChaseChildItemDivColor: '',
        popupChaseChildItemOrderColor: '',
        popupChaseChildItemOrderIngColor: '',
        popupChaseChildItemBonesColor: '',
        popupChaseChildItemWaitColor: '',
        popupChaseChildItemProfitableColor: '',
        /**
         *indexWareHouse => def.vue
         */
        popupChaseDefRiseColor: '',
        popupChaseDefFallColor: '',
        /**
         *indexWareHouse => pcuserware.scss
         */
        pcUserWareBgc: '',
        pcUserWareBeforeBgc: '',
        pcUserWareItemColor: '',
        pcUserWareItemHoverColor: '',
        pcUserWareItemFinishedColor: '',
        pcUserWareItemFinishedBgc: '',
        pcUserWareItemVanTabsColor: '',
        pcUserWareItemVanTabsActiveColor: '',
        /**
         *layout
         */
        layoutHeaderWrapBgc: '',
        layoutLoginTitleColor: '',
        layoutIveSelectBgc: '',
        layoutIveSelectDropdownBgc: '',
        layoutIveSelectItemFocusColor: '',
        layoutIveSelectItemFocusBgc: '',
        layoutIveSelectItemHoverColor: '',
        layoutIveSelectItemHoverBgc: '',
        layoutSelectBtnsBgc: '',
        layoutSelectBtnsBtnColor: '',
        layoutSelectBtnsBtnActiveColor: '',
        layoutSelectBtnsBtnActiveBgc: '',
        layoutSelectBtnsDaysColor: '',
        layoutSelectBtnsDropdownTitleColor: '',
        layoutSelectBtnsDropdownActiveTitleColor: '',
        layoutSelectBtnsEllipsisColor: '',
        layoutSelectBtnsDropdownOpenColor: '',
        layoutSelectBtnsDropdownOpenTitleColor: '',
        layoutSelectBtnsVanCellColor: '',
        layoutSelectBtnsVanCellBgc: '',
        /**
         *notify
         */
        notifyWrapColor: '',
        notifyWrapNoticeColor: '',
        notifyWrapNoticeShadow: '',
        notifyWrapErrorColor: '',
        notifyWrapErrorShadow: '',
        /**
         *personal => header.vue
         */
        headerMenuBgc: '',
        headerMenuBeforeBgc: '',
        headerTitleColor: '',
        headerSubTitleColor: '',
        headerCardTitleColor: '',
        headerCardBtnColor: '',
        headerCardNameColor: '',
        /**
         *Popup
         */
        PopupBgc: '',
        PopupShadow: '',
        PopupHeaderColor: '',
        PopupHeaderUnFocusColor: '',
        PopupHeaderBgc: '',
        /**
         *safeVerify
         */
        safeVerifyBgc: '',
        safeVerifyTitleColor: '',
        safeVerifyBorder: '',
        /**
         *stock => indexRevolving.vue
         */
        indexRevolvingItemColor: '',
        indexRevolvingUpColor: '',
        indexRevolvingDownColor: '',
        indexRevolvingCardBottomColor: '',
        indexRevolvingStockTitleColor: '',
        indexRevolvingStockOrderColor: '',
        indexRevolvingStockTimeColor: '',
        indexRevolvingOpenNumberUpColor: '',
        indexRevolvingOpenNumberDownColor: '',
        /**
         *Home.vue
         */
        homeBgc: '',
        homeTabBgc: '',
        homeTabHoverColor: '',
        homeVanTabColor: '',
        homeTabWrapBgc: '',
        homeTabsNavCardColor: '',
        homeTabsNavCardBgc: '',
        homeSideCloseBtnBgc: '',
        homeSideCloseSwitchBtnBgc: '',
        homeCloseIconBgc: '',
        homeXlBorder: '',
        homeTabsGroupColor: '',
        homeActiveColor: '',
        homeActiveBgc: '',
        homeScrollBarColor: '',
        homeScrollBarShadow: '',
        homeScrollBarBgc: '',
        homeBgcTransparent: '',
        homeBgcBeforeTransparent: '',
        homeBgcMixBlendMode: '',
        homeEmptyColor: '',
        homeThemeAfterBgc: '',
        homeThemeIconActiveBgc: '',
        homeCloseBtnHoverBgc: '',
        /**
         *BasePanel.vue
         */
        basePanelBgc: '',
        basePanelColor: '',
        basePanelHoverColor: '',
        basePanelActiveColor: '',
        /**
         *BaseTooltip.vue
         */
        baseTooltipColor: '',
        baseTooltipBgc: '',
        baseTooltipBorderColor: '',
        baseTooltipShadow: '',
        /**
         *MemberCenter.vue
         */
        memberCenterBarBgc: '',
        memberCenterBtnColor: '',
        memberCenterBtnHoverColor: '',
        memberCenterBtnActiveColor: '',
        memberCenterBgcMix: '',
        memberCenterListBorder: '',
        memberCenterTitleColor: '',
        memberCenterTextColor: '',
        memberCenterSpanActiveColor: '',
        memberCenterDisableColor: '',
        memberCenterDateBorder: '',
        memberCenterPickerBgc: '',
        memberCenterPickerBorder: '',
        memberCenterPickerShadow: '',
        memberCenterPickerPrimary: '',
        memberCenterRadioBorder: '',
        memberCenterRadioBgc: '',
        memberCenterRadioCheckedBorder: '',
        memberCenterMenuTitleColor: '',
        /**
         *AssetsMange.vue
         */
        assetsManageBtnColor: '',
        assetsManageBtnActiveColor: '',
        assetsManageBtnLineColor: '',
        assetsManageFontColor: '',
        assetsManageLightColor: '',
        assetsManagePaginationColor: '',
        assetsManageMenuHoverBgc: '',

        /**
         *step
         */
        stepBtnBgc: '',
        stepWrapBorder: '',
        stepWrapActiveBorder: '',
        stepWrapErrorBorder: '',
        stepInputColor: '',
        stepInputPlaceholder: '',
        /**
         *step-2
         */
        step2BackBtnColor: '',
        step2BackBtnBorder: '',
        step2NextBtnColor: '',
        step2NextBtnBorder: '',
        step2CheckWrapBorder: '',
        step2CheckWrapHoverText: '',
        step2CheckWrapText: '',
        step2SelectBorder: '',
        step2SelectBgc: '',
        step2SelectText: '',
        /**
         *step-3
         */
        step3TitleColor: '',
        step3PassWordFocusBorder: '',
        step3PassWordBgc: '',
        step3PassWordDomI: '',
        step3QaListItemColor: '',
        step3QaInputColor: '',
        step3QaInputBorder: '',
        step3QaInputPlaceholder: '',
        step3PassWordFocusColor: '',
        /**
         *Forget
         */
        ForgetShowStepWrapTitleColor: '',
        ForgetHaveAccountWrapText: '',
        ForgetHaveAccountWrapLink: '',
        ForgetStepLineBgc: '',
        ForgetStepLineNowBgc: '',
        ForgetNowStepColor: '',
        ForgetNowStepNumberBgc: '',
        ForgetNowStepNumberBorder: '',
        ForgetNowStepCheckOkColor: '',
        ForgetNowStepCheckOkBgc: '',
        ForgetNumberBgc: '',
        ForgetNumberBgcBefore: '',
        /**
         *FromLogin
         */
        fromLoginTitleColor: '',
        fromLoginRegisterTextColor: '',
        fromLoginRegisterGotoColor: '',
        fromLoginBtnBgc: '',
        fromLoginGotoColor: '',
        fromLoginUrlTitleColor: '',
        fromLoginUrlTitleBorder: '',
        fromLoginInputWrapBorder: '',
        fromLoginInputWrapActiveBorder: '',
        fromLoginInputWrapHoverBorder: '',
        fromLoginInputWrapErrorBorder: '',
        fromLoginInputPlaceholder: '',
        fromLoginItemContentBgc: '',
        fromLoginItemContentBorder: '',
        /**
         *inputerror
         */
        inputErrorColor: '',
        /**
         *Loginscss
         */
        loginScssBgc: '',
        loginScssInputBgc: '',
        loginScssLoadingLogo: '',
        loginScssShadow: '',
        /**
         *Loginslider
         */
        LoginsliderSwiperPaginationBgc: '',
        LoginsliderSwiperPaginationActiveBgc: '',
        loginsliderTitleColor: '',
        loginsliderDescribeColor: '',
        loginsliderNumberShowColor: '',
        loginsliderNumberTitleColor: '',
        loginsliderNumberDescribeColor: '',
        /**
         *Resgister
         */
        registerTitleColor: '',
        registerInfoColor: '',
        registerGotoTextColor: '',
        registerGotoLinkColor: '',
        registerBtnBgc: '',
        registerWrapSetBorder: '',
        registerWrapSetInputColor: '',
        registerWrapSetPlaceHolderColor: '',
        registerWrapSetInputHover: '',
        registerWrapSetInputActive: '',
        registerWrapSetInputError: '',
        /**
         *app
         */
        appContentViewBgc: '',
        appVanDialogBgc: '',
        appVanAlertDialogBgc: '',
        appVanAlertHeaderColor: '',
        appVanDialogShadow: '',
        appPcNoticeColor: '',
        appPcNoticeHasTitleColor: '',
        appPcNoticeCancelColor: '',
        appPcNoticeHeaderColor: '',
        appPcNoticeHeaderAfterBorder: '',
        appErrorAlertColor: '',
        appErrorAlertTitleColor: '',
        appErrorAlertHeaderColor: '',
        appErrorAlertHeaderAfterBorder: '',
        appDialogHeaderColor: '',
        appDialogFooterBorder: '',
        appDialogConfirmColor: '',
        appDialogConfirmBgc: '',
        appDialogCancelColor: '',
        appDialogCancelBorder: '',
        appVpickerBgc: '',
        appVDataBeforeBorder: '',
        appScrollTrackShadow: '',
        appScrollThumb: '',
        appVDatePickerColor: '',
        appVDatePickerActiveColor: '',
        appDatePickerTableColor: '',
        /**
         * public
         */
        confirmBtnBgc: '',
        confirmBtnColor: '',
        confirmBtnHoverBgc: '',
        cancelBtnColor: '',
        cancelBtnHoverBgc: '',
        /**
         * Navbar
         */
        layoutRouterHide: '',
        layoutRouterHover: '',
        /**
         * dropdownqrcode
         */
        dropdownVancellColor: '',
        dropdownContentBgc: '',
        dropdownBorder: '',
        dropdownTitleBorder: '',
        dropdownInfoColor: '',
        dropdownItemColor: '',
        dropdownHoverItemColor: '',
        dropdownMainTextColor: '',
        dropdownMainQrcodeBorder: '',
        dropdownBoxShadow: '',
        dropdownRankLevelColor: '',
        dropdownTypeBgc: '',
        /**
         * bankMange
         */
        bankMangeSpanColor: '',
        bankMangeTitleColor: '',
        /**
        * announcements
        */
        announcementSwiperColor: '',
        announcementBtnsColor: '',
        announcementTitleColor: '',
        /**
        * ClobalLoading
        */
        ClobalLoadingBox1Bgc: '',
        ClobalLoadingBox2Bgc: '',
        ClobalLoadingBox3Bgc: '',
        ClobalLoadingBox4Bgc: '',
        /**
        * Maintain
        */
        MaintainBgc: '',
        MaintainTitleColor: '',
        MaintainInfoColor: '',
        MaintainBtnColor: '',
        MaintainBtnBorder: '',
        MaintainTimeShow: '',
        /**
        * Navigation
        */
        navigationCardWrapBgc: '',
        navigationCardWrapBeforeBgc: '',
        navigationWrapBgc: '',
        navigationWrapBorder: '',
        navigationSlideBtnBgc: '',
        navigationSlideOutBgc: '',
        /**
        * RechargeComponents
        */
        rechargeInputWrap: '',
        rechargeInputWrapBorder: '',
        rechargeInputWrapHoverBorder: '',
        rechargeInputWrapFocusBorder: '',
        rechargeInputWrapErrorBorder: '',
        rechargeInputColor: '',
        rechargeInputPlaceholderColor: '',
        rechargeInputErrorColor: '',
        rechargeItemContentBgc: '',
        rechargeItemContentBorder: '',
        rechargeItemColor: '',
        rechargeItemSelectActiveColor: '',
        rechargeItemSelectActiveBgc: '',
        rechargeItemHoverColor: '',
        rechargeItemHoverBgc: '',
        /**
        * RechargePage
        */
        rechargePageNavAfter: '',
        rechargePageSpanColor: '',
        rechargePageMenuTitle: '',
        rechargeWalletAfterBgc: '',
        rechargeVanTabColor: '',
        rechargeVanTabHoverColor: '',
        rechargeVanTabActiveColor: '',
        rechargeVanTabLingBgc: ''
      }
    }
  },
  created () {
    switch (this.$store.getters.userInfo.theme) {
      case 'classic':
        this.themeWord = 'classic'
        /*
            dP""b8 88        db     dP""b8  dP""b8 88  dP""b8
          dP   `" 88       dPYb   dP   `" dP   `" 88 dP   `"
          Yb      88  .o  dP__Yb  Yb      Yb      88 Yb
           YboodP 88ood8 dP""""Yb  YboodP  YboodP 88  YboodP
        */
        this.baseTheme.checkUse = '#F00'
        this.baseTheme.fontBase = '14px'
        /**
         * app.vue
         */
        this.baseTheme.contentBgc = 'transparent'
        this.baseTheme.scrollBarTrackBgc = '#162236'
        this.baseTheme.fireFoxScrollBar = '#31445E'
        this.baseTheme.fireScrollBarTrackShadow =
          'inset 0 0 6px rgba(0, 0, 0, 0)'
        this.baseTheme.fireScrollbarThumbBgc = '#31445E'
        this.baseTheme.vantDialogBgc = '#162236'
        this.baseTheme.vantButtonDefaultBgc = '#162236'
        this.baseTheme.vantDialogCancel = '#0589D7'
        this.baseTheme.menuRise = '#E1533B'
        this.baseTheme.menufall = '#2BA66F'
        this.baseTheme.dropDownShadow = '0 8px 25px #0006'
        this.baseTheme.vApplicationBgc =
          'transparent linear-gradient(180deg, #1E2E48 0%, #101E35 100%) 0% 0% no-repeat padding-box'
        /**
         * Graph.vue
         */
        this.baseTheme.graphBgc = 'transparent'
        /**
         * bankMange.vue
         */
        this.baseTheme.bankMangeBgc = '#CCC'
        this.baseTheme.bankMangeColor = '#879AAF'
        this.baseTheme.bankMangeTitleBorderColor = '1px solid #253C5A'
        /**
         * base系列
         */
        this.baseTheme.baseBtnColor = '#879AAF'
        this.baseTheme.baseBtnHoverColor = '#879AAF26'
        this.baseTheme.baseBtnSuccessColor = '#FAFAFA'
        this.baseTheme.baseBtnSuccessBgc = '#0589D7'
        this.baseTheme.baseBtnSuccessHoverBgc = '#45A2D9'
        this.baseTheme.baseBtnBorder = '1px solid #879AAF'
        this.baseTheme.baseInputColor = '#FAFAFA'
        this.baseTheme.baseInputFocusColor = '#0589D7'
        this.baseTheme.baseInputBorderColor = '1px solid #879AAF'
        /**
         * mainText.vue
         */
        this.baseTheme.tradeMainTextProgressColor = '#0589D7'
        this.baseTheme.tradeMainTextGetColorMoreZero = '#E1533B'
        this.baseTheme.tradeMainTextGetColorSLessZero = '#37C882'
        this.baseTheme.tradeMainTextGetColorNotEqualZero = '#253C5A'
        this.baseTheme.tradeMainTextGetColorEqualZero = '#656D7A'
        this.baseTheme.tradeMainTextGetColorDef = '#253C5A'
        this.baseTheme.tradeMainTextOrderColor = '#879AAF'
        /**
         * tradeMain.vue
         */
        this.baseTheme.tradeMainTabLineBgc = '#FAFAFA'
        this.baseTheme.tradeMainTabsNavColor = '#879AAF'
        this.baseTheme.tradeMainTabsNavHoverColor = '#0589D7'
        this.baseTheme.tradeMainTabsNavBgc = 'transparent'
        this.baseTheme.tradeMainItemActive = '#1E2E48'
        this.baseTheme.tradeMainIconBgc = '#20314E'
        this.baseTheme.tradeMainTabsNavColor2 = '#162236'
        this.baseTheme.tradeMainPcTitleColor = '#FAFAFA'
        this.baseTheme.tradeMainPcBorderBottom = '1px solid #1E2E48'
        /**
         * trade.vue
         */
        this.baseTheme.tradeNavBgc = '#17253D'
        this.baseTheme.tradeNavMenuItemColor = '#7D91A5'
        this.baseTheme.tradeNavMenuItemIsActFFF = '#0472B3'
        this.baseTheme.tradeListTitle = '#879AAF'
        this.baseTheme.tradeListItemBgc = '#1E2E48'
        this.baseTheme.tradeListItemBgcHover = '#1B2A43'
        this.baseTheme.tradeListItemBgcActive = '#1B2A43'
        this.baseTheme.tradeListHeaderColor = '#FFF'
        this.baseTheme.tradeListItemContentColor = '#879AAF'
        this.baseTheme.tradeListItemUpColor = '#E1533B'
        this.baseTheme.tradeListItemDownColor = '#37C882'
        this.baseTheme.tradeVtabsBgc = '#1B2A43'
        this.baseTheme.tradeVtabsActive = '#0472B3'
        this.baseTheme.tradeVtabsUrl =
          "url('/static/images/trade/arrow-left.svg')"
        /**
         *trendRank.vue
         */
        this.baseTheme.trendRankHeaderColor = '#FAFAFA'
        this.baseTheme.trendRankHeaderBorder = '1px solid #1E2E48'
        this.baseTheme.trendRankTableColor = '#879AAF'
        this.baseTheme.trendRankTrendInfoColor = '#879AAF'
        this.baseTheme.trendRankTrendInfoHoverBgc = '#1B2A43'
        this.baseTheme.trendRankUpColor = '#E1533B'
        this.baseTheme.trendRankDownColor = '#2BA66F'
        this.baseTheme.trendRankNameColor = '#FAFAFA'
        this.baseTheme.trendRankTdFirstChild = '#FAFAFA'
        this.baseTheme.trendRankTdNth2Child = '#879AAF'
        this.baseTheme.trendRankTdUp = '#E1533B'
        this.baseTheme.trendRankTdDown = '#2BA66F'
        this.baseTheme.trendRankSlideBgc = '#17253D'
        this.baseTheme.trendRankUseIdColor = '#FFF'
        this.baseTheme.trendRankUserNameColor = '#879AAF'
        this.baseTheme.trendRankProfit = '#E1533B'
        this.baseTheme.trendRankBorderBgc = '#17253D'
        /**
         *Double.vue
         */
        this.baseTheme.doubleTitleColor = '#879AAF'
        this.baseTheme.doubleItemWrapperBorder = '1px solid #879AAF'
        this.baseTheme.doubleVanCellColor = '#7D91A5'
        this.baseTheme.doubleVanCellColor2 = '#FAFAFA'
        this.baseTheme.doubleCollapseContentColor = '#879AAF'
        this.baseTheme.doubleCollapseContentBgc = '#17253D'
        this.baseTheme.doubleIconArrowUrl =
          "url('/static/images/bet/triangle.svg')"
        this.baseTheme.doubleBtnActiveColor = '#FAFAFA'
        this.baseTheme.doubleBtnActiveBgc = '#1B2A43'
        this.baseTheme.doubleOrderMoneyBorder = '1px solid #879AAF'
        this.baseTheme.doubleOrderMoneyActiveBorder = '1px solid #0589D7'
        this.baseTheme.doubleItemNameColor = '#879AAF'
        this.baseTheme.doubleItemValueColor = '#FAFAFA'
        this.baseTheme.doubleBoubleBtnColor = '#FAFAFA'
        this.baseTheme.doubleBoubleBtnBgc = '#0589D7'
        this.baseTheme.doubleBoubleBtnActiveBgc = '#45A2D9'
        this.baseTheme.doubleDropdownItemBorder = '1px solid #253C5A'
        this.baseTheme.doubleDropdownItemShadow = '0 8px 15px #0003'
        this.baseTheme.doubleDropdownItemActiveColor = '#FAFAFA'
        this.baseTheme.doubleDropdownItemActiveBgc = '#1B2A43'
        this.baseTheme.doubleDropdownItemTitleColor = '#FAFAFA'
        this.baseTheme.doubleDropdownItemTitleBgc = '#1B2A43'
        this.baseTheme.doubleDropdownItemTitleHoverColor = '#FAFAFA'
        this.baseTheme.doubleDropdownItemTitleHoverBgc = '#1B2A43'
        this.baseTheme.doubleDropdownVanCellColor = '#879AAF'
        this.baseTheme.doubleDropdownVanCelBgc = '#17253D'
        this.baseTheme.doubleUnitBgc = '#253C5A'
        this.baseTheme.doubleDropdownMenuColor = '#7D91A5'
        this.baseTheme.doubleDropdownMenuBorder =
          'transparent transparent #7D91A5 #7D91A5'
        this.baseTheme.doubleVanEllipsisColor = '#879AAF'
        this.baseTheme.doubleInputAreaVanCellBorder = '1px solid #879AAF'
        this.baseTheme.doubleInputAreaCollapseItemBorder = '1px solid #0589D7'
        this.baseTheme.doubleMaxIncome = '#253C5A'
        this.baseTheme.doubleVanCellColor3 = '#879AAF'
        /**
         *indexBetTab.vue
         */
        this.baseTheme.indexBetTabTitleColor = '879AAF'
        this.baseTheme.indexBetTabTitleBorder = '1px solid #1E2E48'
        this.baseTheme.indexBetStockWrapQaColor = '#879AAF'
        this.baseTheme.indexBetTabNavColor = '#17253D'
        this.baseTheme.indexBetVanTabColor = '#879AAF'
        this.baseTheme.indexBetVanTabActiveColor = '#FAFAFA'
        this.baseTheme.indexBetVanTabActiveBgc = '#0589D7'
        this.baseTheme.indexBetSwiperBtnBgc = '#1B2A43'
        this.baseTheme.indexBetHoverBtnColor = '#0589D7'
        this.baseTheme.indexBetActiveColor = '#FAFAFA'
        this.baseTheme.indexBetBtnColor = '#879AAF'
        this.baseTheme.indexBetMoveLineBgc = '#0589D7'
        /**
         *locations.vue
         */
        this.baseTheme.locationInputSetColor = '#FAFAFA'
        this.baseTheme.locationTitleColor = '#879AAF'
        this.baseTheme.locationWrapperBorder = '1px solid #253C5A'
        this.baseTheme.locationVanCellColor = '#7D91A5'
        this.baseTheme.locationIsShowVanCellColor = '#FAFAFA'
        this.baseTheme.locationItemContentColor = '#879AAF'
        this.baseTheme.locationItemContentBgc = '#17253D'
        this.baseTheme.locationItemIconArrowUrl =
          "url('/static/images/bet/triangle.svg')"
        this.baseTheme.locationItemIconArrowUrlActive = "url('/static/images/bet/triangle-active.svg')"
        this.baseTheme.locationItemActiveColor = '#0589D7'
        this.baseTheme.locationItemTitleExpandedBorder = '1px solid #0589D7'
        this.baseTheme.locationItemBtnActiveColor = '#FAFAFA'
        this.baseTheme.locationItemBtnActiveBgc = '#1B2A43'
        this.baseTheme.locationItemOrderMoneyBorder = '1px solid #879AAF'
        this.baseTheme.locationItemOrderMoneyActiveBorder = '1px solid #0589D7'
        this.baseTheme.locationShowInfoItemNameColor = '#879AAF'
        this.baseTheme.locationShowInfoItemValueColor = '#FAFAFA'
        this.baseTheme.locationBoubleBtnColor = '#FAFAFA'
        this.baseTheme.locationDropdownItemBorder = '1px solid #253C5A'
        this.baseTheme.locationDropdownItemShadow = '0 8px 15px #0003'
        this.baseTheme.locationDropdownItemActiveColor = '#FAFAFA'
        this.baseTheme.locationDropdownItemActiveBgc = '#1B2A43'
        this.baseTheme.locationCellTitleHoverColor = '#FAFAFA'
        this.baseTheme.locationCellTitleHoverBgc = '#1B2A43'
        this.baseTheme.locationUnitVanCellColor = '#879AAF'
        this.baseTheme.locationUnitVanCellBgc = '#17253D'
        this.baseTheme.locationUnitBgc = '#253C5A'
        this.baseTheme.locationUnitDropdownTitleActiveColor = '#0589D7'
        this.baseTheme.locationUnitDropdownEllipsisColor = '#879AAF'
        this.baseTheme.locationChaseVanCellColor = '#879AAF'
        this.baseTheme.locationChaseSwitchColor = '#31445E'
        this.baseTheme.locationChaseOnSwitchColor = '#0589D7'
        this.baseTheme.locationInputWrapBorder = '1px solid #879AAF'
        this.baseTheme.locationInputWrapBgc = '#253C5A'
        this.baseTheme.locationInputInputBgc = '#17253D'
        this.baseTheme.locationSelectInputBorder = '1px solid #879AAF'
        this.baseTheme.locationSelectInputActiveBorder = '1px solid #0589D7'
        this.baseTheme.locationSelectRemarkerColor = '#879AAF'
        this.baseTheme.locationPlayModeBgc = '#17253D'
        this.baseTheme.locationPlayModeBarOpenBorder = '1px solid #0589D7'
        this.baseTheme.locationPlayModeVanPopupBgc = '#17253D'
        this.baseTheme.locationPlayModeTitleColor = '#879AAF'
        this.baseTheme.locationBetInputAreaVanCellBorder = '1px solid #879AAF'
        this.baseTheme.locationBetInputAreaTitleExpandedBorder =
          '1px solid #0589D7'
        this.baseTheme.locationBetWrapBtnBgc = '#1B2A43'
        this.baseTheme.locationBoubleTitleColor = '#879AAF'
        this.baseTheme.locationBoubleTitleBgc = '#1B2A43'
        this.baseTheme.locationBoubleTitleActiveColor = '#0589D7'
        this.baseTheme.locationBoubleTitleActiveBorder = '1px solid #0589D7'
        this.baseTheme.locationPlayTypeSpanColor = '#879AAF'
        this.baseTheme.locationPlayTypeSpanBgc = '#1B2A43'
        this.baseTheme.locationPlayTypeSpanActiveColor = '#0589D7'
        this.baseTheme.locationPlayTypeSpanActiveBgc = '#131C4C'
        this.baseTheme.locationPlayTypeSpanActiveBorder = '0 0 0 1px #0589D7'
        this.baseTheme.locationDobleBorder = '1px solid #879AAF'
        this.baseTheme.locationDobleActiveBorder = '1px solid #0589D7'
        this.baseTheme.locationDobleInputColor = '#FAFAFA'
        this.baseTheme.locationUnitWrapColor = '#879AAF'
        this.baseTheme.locationUnitWrapBgc = '#253C5A'
        this.baseTheme.locationVanCellColor2 = '#879AAF'
        this.baseTheme.locationItemWrapperBgc = '#17253D'
        this.baseTheme.locationItemWrapperBorder = '1px solid #253C5A'
        this.baseTheme.locationItemDivColor = '#879AAF'
        this.baseTheme.locationItemHoverDivColor = '#FAFAFA'
        this.baseTheme.locationItemHoverDivBgc = '#1B2A43'
        this.baseTheme.locationMaxIncomeColor = '#253C5A'
        this.baseTheme.locationBetShowBorder = '1px solid #1D324B'
        /**
         *postion.vue
         */
        this.baseTheme.postionTabActiveColor = '#0589D7'
        this.baseTheme.postionTabLineColor = '#0589D7'
        this.baseTheme.postionTabNavBgc = '#1B2A43'
        /**
         *indexWareHouse => chaseNumberBtns.vue
         */
        this.baseTheme.chaseNumberBtnsBtnsBgc = '#1E2E48'
        this.baseTheme.chaseNumberBtnsBtnColor = '#879AAF'
        this.baseTheme.chaseNumberBtnsBtnActiveColor = '#0589D7'
        this.baseTheme.chaseNumberBtnsBtnActiveBgc = '#0589D7'
        this.baseTheme.chaseNumberBtnsDaysColor = '#879AAF'
        this.baseTheme.chaseNumberDropdownTitleColor = '#FAFAFA'
        this.baseTheme.chaseNumberDropdownTitleActiveColor = '#0589D7'
        this.baseTheme.chaseNumberDropdownItemContentBorder =
          '2px solid #253C5A'
        this.baseTheme.chaseNumberDropdownItemActiveColor = '#FAFAFA'
        this.baseTheme.chaseNumberDropdownItemActiveBgc = '#1B2A43'
        this.baseTheme.chaseNumberDropdownVanCellTitleColor = '#FAFAFA'
        this.baseTheme.chaseNumberDropdownVanCellColor = '#879AAF'
        this.baseTheme.chaseNumberDropdownVanCellBgc = '#17253D'
        /**
         *indexWareHouse => selectBtns.vue
         */
        this.baseTheme.selectBtnsContentBtnsBgc = '#1E2E48'
        this.baseTheme.selectBtnsBtnColor = '#879AAF'
        this.baseTheme.selectBtnsBtnActiveColor = '#0589D7'
        this.baseTheme.selectBtnsBtnActiveBgc = '#1E2E48'
        this.baseTheme.selectBtnsDaysColor = '#879AAF'
        this.baseTheme.selectBtnsDayDropdownTitleColor = '#879AAF'
        this.baseTheme.selectBtnsDayDropdownActiveTitleColor = '#0589D7'
        this.baseTheme.selectBtnsDayDropdownOptionColor = '#0589D7'
        this.baseTheme.selectBtnsDayDropdownVanCellTitleColor = '#0589D7'
        this.baseTheme.selectBtnsDayDropdownVanCellColor = '#879AAF'
        this.baseTheme.selectBtnsDayDropdownVanCellBgc = '#17253D'
        /**
         *indexWareHouse => list=> Title => AccountTitle.vue
         */
        this.baseTheme.accountingTitleColor = '#879AAF'
        /**
         *indexWareHouse => list=>
         */
        this.baseTheme.accountingItemBonesRiseColor = '#E1533B'
        this.baseTheme.accountingItemIngColor = '#2BA66F'
        this.baseTheme.accountingItemPendingColor = '#2BA66F'
        this.baseTheme.accountingItemRiseColor = '#FAFAFA'
        this.baseTheme.accountingItemItemStockColor = '#FAFAFA'
        /**
         *indexWareHouse => list=>ChaseNumber
         */
        this.baseTheme.accountingItemChaseRiseColor = '#E1533B'
        this.baseTheme.accountingItemChaseIngColor = '#0589D7'
        this.baseTheme.accountingItemChaseColor = '#FAFAFA'
        /**
         *indexWareHouse => popup
         */
        this.baseTheme.popupTitleColor = '#FAFAFA'
        this.baseTheme.popupNameColor = '#879AAF'
        this.baseTheme.popupValueColor = '#FAFAFA'
        this.baseTheme.popupValuePendingColor = '#879AAF'
        this.baseTheme.popupValueRiseColor = '#E1533B'
        this.baseTheme.popupValueRecordCodeRiseColor = '#E1533B'
        this.baseTheme.popupValueRecordCodePendingColor = '#2BA66F'
        this.baseTheme.popupValueRecordCodeFallColor = '#879AAF'
        this.baseTheme.popupRevokeBtnColor = '#879AAF'
        this.baseTheme.popupRevokeBtnBorder = '1px solid #879AAF'
        this.baseTheme.popupRevokeBtnRevokeColor = '#FAFAFA'
        this.baseTheme.popupRevokeBtnRevokeBgc = '#E73744'
        this.baseTheme.popupRevokeBtnRevokeActiveBgc = '#F95D68'
        /**
         *indexWareHouse => popup => Chase.vue
         */
        this.baseTheme.popupChaseRevokeBtnCancelColor = '#879AAF'
        this.baseTheme.popupChaseRevokeBtnCancelBorder = '1px solid #879AAF'
        this.baseTheme.popupChaseRevokeBtnRevokeColor = '#FAFAFA'
        this.baseTheme.popupChaseRevokeBtnRevokeBgc = '#E73744'
        this.baseTheme.popupChaseCollapseVanCellColor = '#879AAF'
        this.baseTheme.popupChaseCollapseVanItemContentColor = '#879AAF'
        this.baseTheme.popupChaseCollapseVanTitleContentBgc = '#233C5A'
        this.baseTheme.popupChaseStepInfoColor = '#FAFAFA'
        this.baseTheme.popupChaseStepInfoShowBgc = '#233C5A'
        this.baseTheme.popupChaseChaseStepBgc = '#1B2A43'
        this.baseTheme.popupChaseIssueColor = '#0589D7'
        this.baseTheme.popupChaseHaveValueColor = '#FAFAFA'
        this.baseTheme.popupChaseStatePendingColor = '#2BA66F'
        this.baseTheme.popupChaseStateFallColor = '#879AAF'
        this.baseTheme.popupChaseStateRiseColor = '#E1533B'
        this.baseTheme.popupChaseStateIngColor = '#0589D7'
        this.baseTheme.popupChaseSendBonesColor = '#E1533B'
        this.baseTheme.popupChaseRadioItemTitleColor = '#879AAF'
        this.baseTheme.popupChaseRadioItemVanSwitchBgc = '#31445E'
        this.baseTheme.popupChaseRadioItemVanOnSwitchBgc = '#034072'
        this.baseTheme.popupCancelBgc = ''
        this.baseTheme.popupCancelActiveBgc = '#28374E'
        this.baseTheme.popupRadioBorder = '1px solid #253C5A'
        /**
         *indexWareHouse => popup => ChaseChildItem.vue
         */
        this.baseTheme.popupChaseChildItemHoverBgc = '1E2E48'
        this.baseTheme.popupChaseChildItemDivColor = '#879AAF'
        this.baseTheme.popupChaseChildItemOrderColor = '#0589D7'
        this.baseTheme.popupChaseChildItemOrderIngColor = '#879AAF'
        this.baseTheme.popupChaseChildItemBonesColor = '#FAFAFA'
        this.baseTheme.popupChaseChildItemWaitColor = '#2BA66F'
        this.baseTheme.popupChaseChildItemProfitableColor = '#E1533B'
        /**
         *indexWareHouse => def.vue
         */
        this.baseTheme.popupChaseDefRiseColor = '#E1533B'
        this.baseTheme.popupChaseDefFallColor = '#2BA66F'
        /**
         *indexWareHouse => pcuserware.scss
         */
        this.baseTheme.pcUserWareBgc = `transparent linear-gradient(180deg
    , #1E2E48 0%, #0B1021 100%) 0% 0% no-repeat padding-box`
        this.baseTheme.pcUserWareBeforeBgc =
          '#D6D6D6 0% 0% no-repeat padding-box'
        this.baseTheme.pcUserWareItemColor = '#879AAF'
        this.baseTheme.pcUserWareItemHoverColor = '#1B2A43'
        this.baseTheme.pcUserWareItemFinishedColor = '#879AAF'
        this.baseTheme.pcUserWareItemFinishedBgc = '#1E2E48'
        this.baseTheme.pcUserWareItemVanTabsColor = '#879AAF'
        this.baseTheme.pcUserWareItemVanTabsActiveColor = '#FAFAFA'
        /**
         *layout
         */
        this.baseTheme.layoutHeaderWrapBgc = 'transparent'
        this.baseTheme.layoutLoginTitleColor = '#FAFAFA'
        this.baseTheme.layoutIveSelectBgc = '#1E2E48'
        this.baseTheme.layoutIveSelectDropdownBgc = '#1E2E48'
        this.baseTheme.layoutIveSelectItemFocusColor = '#0589D7'
        this.baseTheme.layoutIveSelectItemFocusBgc = '#1B2A43'
        this.baseTheme.layoutIveSelectItemHoverColor = '#0589D7'
        this.baseTheme.layoutIveSelectItemHoverBgc = '#1B2A43'
        this.baseTheme.layoutSelectBtnsBgc = '#17253D'
        this.baseTheme.layoutSelectBtnsBtnColor = '#879AAF'
        this.baseTheme.layoutSelectBtnsBtnActiveColor = '#0589D7'
        this.baseTheme.layoutSelectBtnsBtnActiveBgc = '#1E2E48'
        this.baseTheme.layoutSelectBtnsDaysColor = '#879AAF'
        this.baseTheme.layoutSelectBtnsDropdownTitleColor = '#879AAF'
        this.baseTheme.layoutSelectBtnsDropdownActiveTitleColor = '#0589D7'
        this.baseTheme.layoutSelectBtnsEllipsisColor = '#FAFAFA'
        this.baseTheme.layoutSelectBtnsDropdownOpenColor = '#0589D7'
        this.baseTheme.layoutSelectBtnsDropdownOpenTitleColor = '#0589D7'
        this.baseTheme.layoutSelectBtnsVanCellColor = '#879AAF'
        this.baseTheme.layoutSelectBtnsVanCellBgc = '#1E2E48'
        /**
         *notify
         */
        this.baseTheme.notifyWrapColor = '#FAFAFA'
        this.baseTheme.notifyWrapNoticeColor = '#0589D7'
        this.baseTheme.notifyWrapNoticeShadow = '0 8px 15px #0003'
        this.baseTheme.notifyWrapErrorColor = '#E73744'
        this.baseTheme.notifyWrapErrorShadow = '0 8px 15px #0003'
        /**
         *personal => header.vue
         */
        this.baseTheme.headerMenuBgc = '#071121'
        this.baseTheme.headerMenuBeforeBgc = '#253C5A'
        this.baseTheme.headerTitleColor = '#FAFAFA'
        this.baseTheme.headerSubTitleColor = '#CCC'
        this.baseTheme.headerCardTitleColor = '#879AAF'
        this.baseTheme.headerCardBtnColor = '#0589D7'
        this.baseTheme.headerCardNameColor = '#FAFAFA'
        /**
         *Popup
         */
        this.baseTheme.PopupBgc = '#1E2E48'
        this.baseTheme.PopupShadow = '0 8px 25px #0006'
        this.baseTheme.PopupHeaderColor = '#FAFAFA'
        this.baseTheme.PopupHeaderUnFocusColor = '#879AAF'
        this.baseTheme.PopupHeaderBgc = '#17253D'
        /**
         *safeVerify
         */
        this.baseTheme.safeVerifyBgc = '#CCC'
        this.baseTheme.safeVerifyTitleColor = '#879AAF'
        this.baseTheme.safeVerifyBorder = '1px solid #253C5A'
        /**
         *stock => indexRevolving.vue
         */
        this.baseTheme.indexRevolvingItemColor = '#879AAF'
        this.baseTheme.indexRevolvingUpColor = '#E1533B'
        this.baseTheme.indexRevolvingDownColor = '#2BA66F'
        this.baseTheme.indexRevolvingCardBottomColor = '#87AF8E'
        this.baseTheme.indexRevolvingStockTitleColor = '#FAFAFA'
        this.baseTheme.indexRevolvingStockOrderColor = '#879AAF'
        this.baseTheme.indexRevolvingStockTimeColor = '#FAFAFA'
        this.baseTheme.indexRevolvingOpenNumberUpColor = '#E1533B'
        this.baseTheme.indexRevolvingOpenNumberDownColor = '#2BA66F'
        /**
         *Home.vue
         */
        this.baseTheme.homeBgc = 'transparent'
        this.baseTheme.homeTabBgc = 'rgba(7, 17, 33, .2)'
        this.baseTheme.homeTabHoverColor = '#0589D7'
        this.baseTheme.homeVanTabColor = '#879AAF'
        this.baseTheme.homeTabWrapBgc = '#1E2E48'
        this.baseTheme.homeTabsNavCardColor = '#0472B3'
        this.baseTheme.homeTabsNavCardBgc = '#1E2E48'
        this.baseTheme.homeSideCloseBtnBgc =
          'transparent linear-gradient(180deg, #1D2D46 0%, #101E35 100%) 0% 0% no-repeat padding-box'
        this.baseTheme.homeSideCloseSwitchBtnBgc = '#17253D'
        this.baseTheme.homeCloseIconBgc = '#17253D'
        this.baseTheme.homeXlBorder = '6px solid #162236'
        this.baseTheme.homeTabsGroupColor = '#879AAF'
        this.baseTheme.homeActiveColor = '#FAFAFA'
        this.baseTheme.homeActiveBgc = 'transparent'
        this.baseTheme.homeScrollBarColor = '#31445E transparent'
        this.baseTheme.homeScrollBarShadow = 'inset 0 0 6px rgba(0, 0, 0, 0)'
        this.baseTheme.homeScrollBarBgc = '#31445E'
        this.baseTheme.homeBgcTransparent = 'rgba(7, 17, 33, .35)'
        this.baseTheme.homeBgcBeforeTransparent =
          '#D6D6D6 0% 0% no-repeat padding-box'
        this.baseTheme.homeBgcMixBlendMode = 'multiply'
        this.baseTheme.homeEmptyColor = '#879AAF'
        this.baseTheme.homeThemeAfterBgc = '#253C5A'
        this.baseTheme.homeThemeIconActiveBgc = '#20385A'
        this.baseTheme.homeCloseBtnHoverBgc = '#1E2E48 0% 0% no-repeat padding-box'
        /**
         *BasePanel.vue
         */
        this.baseTheme.basePanelBgc = '#071121'
        this.baseTheme.basePanelColor = '#879AAF'
        this.baseTheme.basePanelHoverColor = '#0589D7'
        this.baseTheme.basePanelActiveColor = '#FAFAFA'
        /**
         *BaseTooltip.vue
         */
        this.baseTheme.baseTooltipColor = '#879AAF'
        this.baseTheme.baseTooltipBgc = '#17253D'
        this.baseTheme.baseTooltipBorderColor = '#253C5A'
        this.baseTheme.baseTooltipShadow = '0 1px 2px #00000026'
        /**
         *MemberCenter.vue
         */
        this.baseTheme.memberCenterBarBgc = '#FAFAFA'
        this.baseTheme.memberCenterBtnColor = '#879AAF'
        this.baseTheme.memberCenterBtnHoverColor = '#0589D7'
        this.baseTheme.memberCenterBtnActiveColor = '#FAFAFA'
        this.baseTheme.memberCenterBgcMix = '#071121'
        this.baseTheme.memberCenterListBorder = '1px solid #1E2E48'
        this.baseTheme.memberCenterTitleColor = '#879AAF'
        this.baseTheme.memberCenterTextColor = '#FAFAFA'
        this.baseTheme.memberCenterSpanActiveColor = '#FAFAFA'
        this.baseTheme.memberCenterDisableColor = '#879AAF'
        this.baseTheme.memberCenterDateBorder = ' 1px solid #879AAF'
        this.baseTheme.memberCenterPickerBgc = '#17253D'
        this.baseTheme.memberCenterPickerBorder = '1px solid #253C5A'
        this.baseTheme.memberCenterPickerShadow = '0 8px 15px #0003'
        this.baseTheme.memberCenterPickerPrimary = '#F0F'
        this.baseTheme.memberCenterRadioBorder = '1px solid #FFF'
        this.baseTheme.memberCenterRadioBgc = '#0589D7'
        this.baseTheme.memberCenterRadioCheckedBorder = '#0589D7'
        this.baseTheme.memberCenterMenuTitleColor = '#375E86'
        /**
         *AssetsMange.vue
         */
        this.baseTheme.assetsManageBtnColor = '#879AAF'
        this.baseTheme.assetsManageBtnActiveColor = '#0589D7'
        this.baseTheme.assetsManageBtnLineColor = '#1E2E48'
        this.baseTheme.assetsManageFontColor = '#879AAF'
        this.baseTheme.assetsManageLightColor = '#FAFAFA'
        this.baseTheme.assetsManagePaginationColor = '#879AAF'
        this.baseTheme.assetsManageMenuHoverBgc =
          'rgba(30,46,72,.6)'
        /**
         *step
         */
        this.baseTheme.stepBtnBgc = '#0589D7'
        this.baseTheme.stepWrapBorder = '1px solid #879AAF'
        this.baseTheme.stepWrapActiveBorder = '1px solid #0589D7'
        this.baseTheme.stepWrapErrorBorder = ' 1px solid #E73744'
        this.baseTheme.stepInputColor = '#FAFAFA'
        this.baseTheme.stepInputPlaceholder = '#879AAF'

        /**
         *step-2
         */
        this.baseTheme.step2BackBtnColor = '#879AAF'
        this.baseTheme.step2BackBtnBorder = '1px solid #879AAF'
        this.baseTheme.step2NextBtnColor = '#FAFAFA'
        this.baseTheme.step2NextBtnBorder = ' #0589D7'
        this.baseTheme.step2CheckWrapBorder = '1px solid #879AAF'
        this.baseTheme.step2CheckWrapHoverText = '#FAFAFA'
        this.baseTheme.step2CheckWrapText = '#879AAF'
        this.baseTheme.step2SelectBorder = '1px solid #0589D7'
        this.baseTheme.step2SelectBgc = '#0589D714'
        this.baseTheme.step2SelectText = '#FAFAFA'
        /**
         *step-3
         */
        this.baseTheme.step3TitleColor = '#879AAF'
        this.baseTheme.step3PassWordFocusBorder = '1px solid #0589D7'
        this.baseTheme.step3PassWordBgc = '#879AAF26'
        this.baseTheme.step3PassWordDomI = '#FFF'
        this.baseTheme.step3QaListItemColor = '#879AAF'
        this.baseTheme.step3QaInputColor = '#879AAF'
        this.baseTheme.step3QaInputBorder = '1px solid #879AAF'
        this.baseTheme.step3QaInputPlaceholder = '#879AAF'
        this.baseTheme.step3PassWordFocusColor = '#0589D7'
        /**
         *Forget
         */
        this.baseTheme.ForgetShowStepWrapTitleColor = '#FAFAFA'
        this.baseTheme.ForgetHaveAccountWrapText = '#879AAF'
        this.baseTheme.ForgetHaveAccountWrapLink = '#0589D7'
        this.baseTheme.ForgetStepLineBgc = '#879AAF'
        this.baseTheme.ForgetStepLineNowBgc = '#1E2E48'
        this.baseTheme.ForgetNowStepColor = '#0589D7'
        this.baseTheme.ForgetNowStepNumberBgc = '#101C30'
        this.baseTheme.ForgetNowStepNumberBorder = '1px solid #0589D7'
        this.baseTheme.ForgetNowStepCheckOkColor = '#FAFAFA'
        this.baseTheme.ForgetNowStepCheckOkBgc = '#0589D7'
        this.baseTheme.ForgetNumberBgc = '#101C30'
        this.baseTheme.ForgetNumberBgcBefore = '#1E2E48'
        /**
         *FromLogin
         */
        this.baseTheme.fromLoginTitleColor = '#FAFAFA'
        this.baseTheme.fromLoginRegisterTextColor = '#879AAF'
        this.baseTheme.fromLoginRegisterGotoColor = '#0589D7'
        this.baseTheme.fromLoginBtnBgc = '#0589D7'
        this.baseTheme.fromLoginGotoColor = '#0589D7'
        this.baseTheme.fromLoginUrlTitleColor = '#879AAF'
        this.baseTheme.fromLoginUrlTitleBorder = '1px solid #879AAF'
        this.baseTheme.fromLoginInputWrapBorder = '1px solid #879AAF'
        this.baseTheme.fromLoginInputWrapActiveBorder = '1px solid #0589D7'
        this.baseTheme.fromLoginInputWrapHoverBorder = '1px solid #0589D7'
        this.baseTheme.fromLoginInputWrapErrorBorder = '1px solid #E73744'
        this.baseTheme.fromLoginInputPlaceholder = '#879AAF'
        this.baseTheme.fromLoginItemContentBgc = '#17253D'
        this.baseTheme.fromLoginItemContentBorder = '1px solid #253C5A'
        /**
         *inputerror
         */
        this.baseTheme.inputErrorColor = '#E73744'
        /**
         *Loginscss
         */
        this.baseTheme.loginScssBgc = '#1E2E48'
        this.baseTheme.loginScssInputBgc = '#101C30'
        this.baseTheme.loginScssLoadingLogo = '#1E2E48'
        this.baseTheme.loginScssShadow = '0px 15px 30px #00000033'
        /**
         *Loginslider
         */
        this.baseTheme.LoginsliderSwiperPaginationBgc =
          'rgba(255, 255, 255, 0.1)'
        this.baseTheme.LoginsliderSwiperPaginationActiveBgc = '#FFF'
        this.baseTheme.loginsliderTitleColor = '#FAFAFA'
        this.baseTheme.loginsliderDescribeColor = '#879AAF'
        this.baseTheme.loginsliderNumberShowColor = '#0589D7'
        this.baseTheme.loginsliderNumberTitleColor = '#FAFAFA'
        this.baseTheme.loginsliderNumberDescribeColor = '#879AAF'
        /**
         *Resgister
         */
        this.baseTheme.registerTitleColor = '#FAFAFA'
        this.baseTheme.registerInfoColor = '#879AAF'
        this.baseTheme.registerGotoTextColor = '#879AAF'
        this.baseTheme.registerGotoLinkColor = '#0589D7'
        this.baseTheme.registerBtnBgc = '#0589D7'
        this.baseTheme.registerWrapSetBorder = '1px solid #879AAF'
        this.baseTheme.registerWrapSetInputColor = '#FAFAFA'
        this.baseTheme.registerWrapSetPlaceHolderColor = '#879AAF'
        this.baseTheme.registerWrapSetInputHover = '1px solid #0589D7'
        this.baseTheme.registerWrapSetInputActive = '1px solid #0589D7'
        this.baseTheme.registerWrapSetInputError = '1px solid #E73744'
        /**
         *app
         */
        this.baseTheme.appContentViewBgc = '#1E2E48'
        this.baseTheme.appVanDialogBgc = '#101E35'
        this.baseTheme.appVanAlertDialogBgc = '#17253D'
        this.baseTheme.appVanAlertHeaderColor = '#FAFAFA'
        this.baseTheme.appVanDialogShadow = '0 8px 25px #0006'
        this.baseTheme.appPcNoticeColor = '#879AAF'
        this.baseTheme.appPcNoticeHasTitleColor = '#879AAF'
        this.baseTheme.appPcNoticeCancelColor = '#879AA'
        this.baseTheme.appPcNoticeHeaderColor = '#FAFAFA'
        this.baseTheme.appPcNoticeHeaderAfterBorder = '3px solid #2BA66F'
        this.baseTheme.appErrorAlertColor = '#879AAF'
        this.baseTheme.appErrorAlertTitleColor = '#879AAF'
        this.baseTheme.appErrorAlertHeaderColor = '#FAFAFA'
        this.baseTheme.appErrorAlertHeaderAfterBorder = '3px solid #E73744'
        this.baseTheme.appDialogHeaderColor = '#879AAF'
        this.baseTheme.appDialogFooterBorder = '1px solid #253C5A'
        this.baseTheme.appDialogConfirmColor = '#FAFAFA'
        this.baseTheme.appDialogConfirmBgc = '#0589D7'
        this.baseTheme.appDialogCancelColor = '#879AAF'
        this.baseTheme.appDialogCancelBorder = '1px solid #879AAF'
        this.baseTheme.appVpickerBgc = '#17253D'
        this.baseTheme.appVDataBeforeBorder = '1px solid #253C5A'
        this.baseTheme.appScrollTrackShadow = 'inset 0 0 6px rgba(0, 0, 0, 0)'
        this.baseTheme.appScrollThumb = '#31445E'
        this.baseTheme.appVDatePickerColor = '#879AAF'
        this.baseTheme.appVDatePickerActiveColor = '#FAFAFA'
        this.baseTheme.appDatePickerTableColor = '#879AAF'
        /**
         * public
         */
        this.baseTheme.confirmBtnBgc = '#0589D7'
        this.baseTheme.confirmBtnColor = '#FAFAFA'
        this.baseTheme.confirmBtnHoverBgc = '#45A2D9'
        this.baseTheme.cancelBtnColor = '#879AAF'
        this.baseTheme.cancelBtnHoverBgc = '#222F43'
        /**
         * Navbar
         */
        this.baseTheme.layoutRouterHide = '#879AAF'
        this.baseTheme.layoutRouterHover = '#0589D7'
        /**
         * dropdownqrcode
         */
        this.baseTheme.dropdownVancellColor = '#FAFAFA'
        this.baseTheme.dropdownContentBgc = '#1B2A43'
        this.baseTheme.dropdownBorder = '1px solid #1D324B'
        this.baseTheme.dropdownTitleBorder = '1px solid #1D324B'
        this.baseTheme.dropdownInfoColor = '#879AAF'
        this.baseTheme.dropdownItemColor = '#FAFAFA'
        this.baseTheme.dropdownHoverItemColor = '#253C5A'
        this.baseTheme.dropdownMainTextColor = '#FAFAFA'
        this.baseTheme.dropdownMainQrcodeBorder = '1px solid #707070'
        this.baseTheme.dropdownBoxShadow = '0 8px 25px #0006'
        this.baseTheme.dropdownRankLevelColor = '#0589D7'
        this.baseTheme.dropdownTypeBgc = 'rgba(37, 60, 90, 0) 0% 0% no-repeat padding-box'
        /**
         * bankMange
         */
        this.baseTheme.bankMangeSpanColor = '#FAFAFA'
        this.baseTheme.bankMangeTitleColor = '#375E86'
        /**
        * announcements
        */
        this.baseTheme.announcementSwiperColor = '#879AAF'
        this.baseTheme.announcementBtnsColor = '#879AAF'
        this.baseTheme.announcementTitleColor = '#FAFAFA'
        /**
        * ClobalLoading
        */
        this.baseTheme.ClobalLoadingBox1Bgc = '#0589D7 0% 0% no-repeat padding-box'
        this.baseTheme.ClobalLoadingBox2Bgc = '#253C5A 0% 0% no-repeat padding-box'
        this.baseTheme.ClobalLoadingBox3Bgc = '#253C5A 0% 0% no-repeat padding-box'
        this.baseTheme.ClobalLoadingBox4Bgc = '#0589D7 0% 0% no-repeat padding-box'
        /**
        * Maintain
        */
        this.baseTheme.MaintainBgc = '#0B1021'
        this.baseTheme.MaintainTitleColor = '#FAFAFA'
        this.baseTheme.MaintainInfoColor = '#879AAF'
        this.baseTheme.MaintainBtnColor = '#879AAF'
        this.baseTheme.MaintainBtnBorder = '1px solid #879AAF'
        this.baseTheme.MaintainTimeShow = '#0589D7'
        /**
        * Navigation
        */
        this.baseTheme.navigationCardWrapBgc = '#1E2E48'
        this.baseTheme.navigationCardWrapBeforeBgc = '#17253D'
        this.baseTheme.navigationWrapBgc = '#1E2E48'
        this.baseTheme.navigationWrapBorder = '#17253D'
        this.baseTheme.navigationSlideBtnBgc = '17253D'
        this.baseTheme.navigationSlideOutBgc = '#17253D'
        /**
        * RechargeComponents
        */
        this.baseTheme.rechargeInputWrap = '#879AAF'
        this.baseTheme.rechargeInputWrapBorder = '1px solid #879AAF'
        this.baseTheme.rechargeInputWrapHoverBorder = '1px solid #0589D7'
        this.baseTheme.rechargeInputWrapFocusBorder = '1px solid #0589D7'
        this.baseTheme.rechargeInputWrapErrorBorder = '1px solid #E73744'
        this.baseTheme.rechargeInputColor = '#FAFAFA'
        this.baseTheme.rechargeInputPlaceholderColor = '#879AAF'
        this.baseTheme.rechargeInputErrorColor = '#E73744'
        this.baseTheme.rechargeItemContentBgc = '#17253D'
        this.baseTheme.rechargeItemContentBorder = '1px solid #253C5A'
        this.baseTheme.rechargeItemColor = '#879AAF'
        this.baseTheme.rechargeItemSelectActiveColor = '#FAFAFA'
        this.baseTheme.rechargeItemSelectActiveBgc = '#1B2A43'
        this.baseTheme.rechargeItemHoverColor = '#FAFAFA'
        this.baseTheme.rechargeItemHoverBgc = '#1B2A43'
        /**
        * RechargePage
        */
        this.baseTheme.rechargePageNavAfter = '#071121'
        this.baseTheme.rechargePageSpanColor = '#FAFAFA'
        this.baseTheme.rechargePageMenuTitle = '#375E86'
        this.baseTheme.rechargeWalletAfterBgc = '#1E2E48'
        this.baseTheme.rechargeVanTabColor = '#879AAF'
        this.baseTheme.rechargeVanTabHoverColor = '#0589D7'
        this.baseTheme.rechargeVanTabActiveColor = '#FAFAFA'
        this.baseTheme.rechargeVanTabLingBgc = '#FAFAFA'
        //* * ============ */
        break
      // case 'lightclassic':
      case 'test': // 暫時沒有其他主題
        this.baseTheme.checkUse = '#CCC'
        this.themeWord = 'lightclassic'
        /*
        88     88  dP""b8 88  88 888888
        88     88 dP   `" 88  88   88
        88  .o 88 Yb  "88 888888   88
        88ood8 88  YboodP 88  88   88
        */
        this.baseTheme.fontBase = '14px'
        /**
         * app.vue
         */
        this.baseTheme.contentBgc =
          'transparent linear-gradient(180deg, #1E2E48 0%, #0B1021 100%) 0% 0% no-repeat padding-box'
        this.baseTheme.scrollBarTrackBgc = '#162236'
        this.baseTheme.fireFoxScrollBar = '#31445E'
        this.baseTheme.fireScrollBarTrackShadow =
          'inset 0 0 6px rgba(0, 0, 0, 0)'
        this.baseTheme.fireScrollbarThumbBgc = '#31445E'
        this.baseTheme.vantDialogBgc = '#162236'
        this.baseTheme.vantButtonDefaultBgc = '#162236'
        this.baseTheme.vantDialogCancel = '#0589D7'
        this.baseTheme.menuRise = '#E1533B'
        this.baseTheme.menufall = '#2BA66F'
        this.baseTheme.dropDownShadow = '0 8px 25px #0006'
        this.baseTheme.vApplicationBgc = 'transparent'
        /**
         * Graph.vue
         */
        this.baseTheme.graphBgc = '#1E2E48'
        /**
         * bankMange.vue
         */
        this.baseTheme.bankMangeBgc = '#CCC'
        this.baseTheme.bankMangeColor = '#879AAF'
        this.baseTheme.bankMangeTitleBorderColor = '1px solid #253C5A'
        /**
          * base系列
          */
        this.baseTheme.baseBtnColor = '#879AAF'
        this.baseTheme.baseBtnHoverColor = '#879AAF26'
        this.baseTheme.baseBtnSuccessColor = '#FAFAFA'
        this.baseTheme.baseBtnSuccessBgc = '#0589D7'
        this.baseTheme.baseBtnSuccessHoverBgc = '#45A2D9'
        this.baseTheme.baseBtnBorder = '1px solid #879AAF'
        this.baseTheme.baseInputColor = '#FAFAFA'
        this.baseTheme.baseInputFocusColor = '#0589D7'
        this.baseTheme.baseInputBorderColor = '1px solid #879AAF'
        /**
         * mainText.vue
         */
        this.baseTheme.tradeMainTextProgressColor = '#0589D7'
        this.baseTheme.tradeMainTextGetColorMoreZero = '#E1533B'
        this.baseTheme.tradeMainTextGetColorSLessZero = '#37C882'
        this.baseTheme.tradeMainTextGetColorNotEqualZero = '#253C5A'
        this.baseTheme.tradeMainTextGetColorEqualZero = '#656D7A'
        this.baseTheme.tradeMainTextGetColorDef = '#253C5A'
        this.baseTheme.tradeMainTextOrderColor = '#879AAF'
        /**
         * tradeMain.vue
         */
        this.baseTheme.tradeMainTabLineBgc = '#FAFAFA'
        this.baseTheme.tradeMainTabsNavColor = '#879AAF'
        this.baseTheme.tradeMainTabsNavHoverColor = '#0589D7'
        this.baseTheme.tradeMainTabsNavBgc = 'transparent'
        this.baseTheme.tradeMainItemActive = '#1E2E48'
        this.baseTheme.tradeMainIconBgc = '#20314E'
        this.baseTheme.tradeMainTabsNavColor2 = '#162236'
        this.baseTheme.tradeMainPcTitleColor = '#FAFAFA'
        this.baseTheme.tradeMainPcBorderBottom = '1px solid #1E2E48'
        /**
         * trade.vue
         */
        this.baseTheme.tradeNavBgc = '#17253D'
        this.baseTheme.tradeNavMenuItemColor = '#7D91A5'
        this.baseTheme.tradeNavMenuItemIsActFFF = '#0472B3'
        this.baseTheme.tradeListTitle = '#879AAF'
        this.baseTheme.tradeListItemBgc = '#1E2E48'
        this.baseTheme.tradeListItemBgcHover = '#1B2A43'
        this.baseTheme.tradeListItemBgcActive = '#1B2A43'
        this.baseTheme.tradeListHeaderColor = '#FFF'
        this.baseTheme.tradeListItemContentColor = '#879AAF'
        this.baseTheme.tradeListItemUpColor = '#E1533B'
        this.baseTheme.tradeListItemDownColor = '#37C882'
        this.baseTheme.tradeVtabsBgc = '#1B2A43'
        this.baseTheme.tradeVtabsActive = '#0472B3'
        this.baseTheme.tradeVtabsUrl =
          "url('/static/images/trade/arrow-left.svg')"
        /**
         *trendRank.vue
         */
        this.baseTheme.trendRankHeaderColor = '#FAFAFA'
        this.baseTheme.trendRankHeaderBorder = '1px solid #1E2E48'
        this.baseTheme.trendRankTableColor = '#879AAF'
        this.baseTheme.trendRankTrendInfoColor = '#879AAF'
        this.baseTheme.trendRankTrendInfoHoverBgc = '#1B2A43'
        this.baseTheme.trendRankUpColor = '#E1533B'
        this.baseTheme.trendRankDownColor = '#2BA66F'
        this.baseTheme.trendRankNameColor = '#FAFAFA'
        this.baseTheme.trendRankTdFirstChild = '#FAFAFA'
        this.baseTheme.trendRankTdNth2Child = '#879AAF'
        this.baseTheme.trendRankTdUp = '#E1533B'
        this.baseTheme.trendRankTdDown = '#2BA66F'
        this.baseTheme.trendRankSlideBgc = '#17253D'
        this.baseTheme.trendRankUseIdColor = '#FFF'
        this.baseTheme.trendRankUserNameColor = '#879AAF'
        this.baseTheme.trendRankProfit = '#E1533B'
        this.baseTheme.trendRankBorderBgc = '#17253D'
        /**
         *Double.vue
         */
        this.baseTheme.doubleTitleColor = '#879AAF'
        this.baseTheme.doubleItemWrapperBorder = '1px solid #879AAF'
        this.baseTheme.doubleVanCellColor = '#7D91A5'
        this.baseTheme.doubleVanCellColor2 = '#FAFAFA'
        this.baseTheme.doubleCollapseContentColor = '#879AAF'
        this.baseTheme.doubleCollapseContentBgc = '#17253D'
        this.baseTheme.doubleIconArrowUrl =
          "url('/static/images/bet/triangle.svg')"
        this.baseTheme.doubleBtnActiveColor = '#FAFAFA'
        this.baseTheme.doubleBtnActiveBgc = '#1B2A43'
        this.baseTheme.doubleOrderMoneyBorder = '1px solid #879AAF'
        this.baseTheme.doubleOrderMoneyActiveBorder = '1px solid #0589D7'
        this.baseTheme.doubleItemNameColor = '#879AAF'
        this.baseTheme.doubleItemValueColor = '#FAFAFA'
        this.baseTheme.doubleBoubleBtnColor = '#FAFAFA'
        this.baseTheme.doubleBoubleBtnBgc = '#0589D7'
        this.baseTheme.doubleBoubleBtnActiveBgc = '#45A2D9'
        this.baseTheme.doubleDropdownItemBorder = '1px solid #253C5A'
        this.baseTheme.doubleDropdownItemShadow = '0 8px 15px #0003'
        this.baseTheme.doubleDropdownItemActiveColor = '#FAFAFA'
        this.baseTheme.doubleDropdownItemActiveBgc = '#1B2A43'
        this.baseTheme.doubleDropdownItemTitleColor = '#FAFAFA'
        this.baseTheme.doubleDropdownItemTitleBgc = '#1B2A43'
        this.baseTheme.doubleDropdownItemTitleHoverColor = '#FAFAFA'
        this.baseTheme.doubleDropdownItemTitleHoverBgc = '#1B2A43'
        this.baseTheme.doubleDropdownVanCellColor = '#879AAF'
        this.baseTheme.doubleDropdownVanCelBgc = '#17253D'
        this.baseTheme.doubleUnitBgc = '#253C5A'
        this.baseTheme.doubleDropdownMenuColor = '#7D91A5'
        this.baseTheme.doubleDropdownMenuBorder =
          'transparent transparent #7D91A5 #7D91A5'
        this.baseTheme.doubleVanEllipsisColor = '#879AAF'
        this.baseTheme.doubleInputAreaVanCellBorder = '1px solid #879AAF'
        this.baseTheme.doubleInputAreaCollapseItemBorder = '1px solid #0589D7'
        this.baseTheme.doubleMaxIncome = '#253C5A'
        this.baseTheme.doubleVanCellColor3 = '#879AAF'
        /**
         *indexBetTab.vue
         */
        this.baseTheme.indexBetTabTitleColor = '879AAF'
        this.baseTheme.indexBetTabTitleBorder = '1px solid #1E2E48'
        this.baseTheme.indexBetStockWrapQaColor = '#879AAF'
        this.baseTheme.indexBetTabNavColor = '#17253D'
        this.baseTheme.indexBetVanTabColor = '#879AAF'
        this.baseTheme.indexBetVanTabActiveColor = '#FAFAFA'
        this.baseTheme.indexBetVanTabActiveBgc = '#0589D7'
        this.baseTheme.indexBetSwiperBtnBgc = '#1B2A43'
        this.baseTheme.indexBetHoverBtnColor = '#0589D7'
        this.baseTheme.indexBetActiveColor = '#FAFAFA'
        this.baseTheme.indexBetBtnColor = '#879AAF'
        this.baseTheme.indexBetMoveLineBgc = '#0589D7'
        /**
         *locations.vue
         */
        this.baseTheme.locationInputSetColor = '#FAFAFA'
        this.baseTheme.locationTitleColor = '#879AAF'
        this.baseTheme.locationWrapperBorder = '1px solid #253C5A'
        this.baseTheme.locationVanCellColor = '#7D91A5'
        this.baseTheme.locationIsShowVanCellColor = '#FAFAFA'
        this.baseTheme.locationItemContentColor = '#879AAF'
        this.baseTheme.locationItemContentBgc = '#17253D'
        this.baseTheme.locationItemIconArrowUrl =
          "url('/static/images/bet/triangle.svg')"
        this.baseTheme.locationItemIconArrowUrlActive = "url('/static/images/bet/triangle-active.svg')"
        this.baseTheme.locationItemActiveColor = '#0589D7'
        this.baseTheme.locationItemTitleExpandedBorder = '1px solid #0589D7'
        this.baseTheme.locationItemBtnActiveColor = '#FAFAFA'
        this.baseTheme.locationItemBtnActiveBgc = '#1B2A43'
        this.baseTheme.locationItemOrderMoneyBorder = '1px solid #879AAF'
        this.baseTheme.locationItemOrderMoneyActiveBorder = '1px solid #0589D7'
        this.baseTheme.locationShowInfoItemNameColor = '#879AAF'
        this.baseTheme.locationShowInfoItemValueColor = '#FAFAFA'
        this.baseTheme.locationBoubleBtnColor = '#FAFAFA'
        this.baseTheme.locationDropdownItemBorder = '1px solid #253C5A'
        this.baseTheme.locationDropdownItemShadow = '0 8px 15px #0003'
        this.baseTheme.locationDropdownItemActiveColor = '#FAFAFA'
        this.baseTheme.locationDropdownItemActiveBgc = '#1B2A43'
        this.baseTheme.locationCellTitleHoverColor = '#FAFAFA'
        this.baseTheme.locationCellTitleHoverBgc = '#1B2A43'
        this.baseTheme.locationUnitVanCellColor = '#879AAF'
        this.baseTheme.locationUnitVanCellBgc = '#17253D'
        this.baseTheme.locationUnitBgc = '#253C5A'
        this.baseTheme.locationUnitDropdownTitleActiveColor = '#0589D7'
        this.baseTheme.locationUnitDropdownEllipsisColor = '#879AAF'
        this.baseTheme.locationChaseVanCellColor = '#879AAF'
        this.baseTheme.locationChaseSwitchColor = '#31445E'
        this.baseTheme.locationChaseOnSwitchColor = '#0589D7'
        this.baseTheme.locationInputWrapBorder = '1px solid #879AAF'
        this.baseTheme.locationInputWrapBgc = '#253C5A'
        this.baseTheme.locationInputInputBgc = '#17253D'
        this.baseTheme.locationSelectInputBorder = '1px solid #879AAF'
        this.baseTheme.locationSelectInputActiveBorder = '1px solid #0589D7'
        this.baseTheme.locationSelectRemarkerColor = '#879AAF'
        this.baseTheme.locationPlayModeBgc = '#17253D'
        this.baseTheme.locationPlayModeBarOpenBorder = '1px solid #0589D7'
        this.baseTheme.locationPlayModeVanPopupBgc = '#17253D'
        this.baseTheme.locationPlayModeTitleColor = '#879AAF'
        this.baseTheme.locationBetInputAreaVanCellBorder = '1px solid #879AAF'
        this.baseTheme.locationBetInputAreaTitleExpandedBorder =
          '1px solid #0589D7'
        this.baseTheme.locationBetWrapBtnBgc = '#1B2A43'
        this.baseTheme.locationBoubleTitleColor = '#879AAF'
        this.baseTheme.locationBoubleTitleBgc = '#1B2A43'
        this.baseTheme.locationBoubleTitleActiveColor = '#0589D7'
        this.baseTheme.locationBoubleTitleActiveBorder = '1px solid #0589D7'
        this.baseTheme.locationPlayTypeSpanColor = '#879AAF'
        this.baseTheme.locationPlayTypeSpanBgc = '#1B2A43'
        this.baseTheme.locationPlayTypeSpanActiveColor = '#0589D7'
        this.baseTheme.locationPlayTypeSpanActiveBgc = '#131C4C'
        this.baseTheme.locationPlayTypeSpanActiveBorder = '0 0 0 1px #0589D7'
        this.baseTheme.locationDobleBorder = '1px solid #879AAF'
        this.baseTheme.locationDobleActiveBorder = '1px solid #0589D7'
        this.baseTheme.locationDobleInputColor = '#FAFAFA'
        this.baseTheme.locationUnitWrapColor = '#879AAF'
        this.baseTheme.locationUnitWrapBgc = '#253C5A'
        this.baseTheme.locationVanCellColor2 = '#879AAF'
        this.baseTheme.locationItemWrapperBgc = '#17253D'
        this.baseTheme.locationItemWrapperBorder = '1px solid #253C5A'
        this.baseTheme.locationItemDivColor = '#879AAF'
        this.baseTheme.locationItemHoverDivColor = '#FAFAFA'
        this.baseTheme.locationItemHoverDivBgc = '#1B2A43'
        this.baseTheme.locationMaxIncomeColor = '#253C5A'
        this.baseTheme.locationBetShowBorder = '1px solid #1D324B'
        /**
         *postion.vue
         */
        this.baseTheme.postionTabActiveColor = '#0589D7'
        this.baseTheme.postionTabLineColor = '#0589D7'
        this.baseTheme.postionTabNavBgc = '#1B2A43'
        /**
         *indexWareHouse => chaseNumberBtns.vue
         */
        this.baseTheme.chaseNumberBtnsBtnsBgc = '#1E2E48'
        this.baseTheme.chaseNumberBtnsBtnColor = '#879AAF'
        this.baseTheme.chaseNumberBtnsBtnActiveColor = '#0589D7'
        this.baseTheme.chaseNumberBtnsBtnActiveBgc = '#0589D7'
        this.baseTheme.chaseNumberBtnsDaysColor = '#879AAF'
        this.baseTheme.chaseNumberDropdownTitleColor = '#FAFAFA'
        this.baseTheme.chaseNumberDropdownTitleActiveColor = '#0589D7'
        this.baseTheme.chaseNumberDropdownItemContentBorder =
          '2px solid #253C5A'
        this.baseTheme.chaseNumberDropdownItemActiveColor = '#FAFAFA'
        this.baseTheme.chaseNumberDropdownItemActiveBgc = '#1B2A43'
        this.baseTheme.chaseNumberDropdownVanCellTitleColor = '#FAFAFA'
        this.baseTheme.chaseNumberDropdownVanCellColor = '#879AAF'
        this.baseTheme.chaseNumberDropdownVanCellBgc = '#17253D'
        /**
         *indexWareHouse => selectBtns.vue
         */
        this.baseTheme.selectBtnsContentBtnsBgc = '#1E2E48'
        this.baseTheme.selectBtnsBtnColor = '#879AAF'
        this.baseTheme.selectBtnsBtnActiveColor = '#0589D7'
        this.baseTheme.selectBtnsBtnActiveBgc = '#1E2E48'
        this.baseTheme.selectBtnsDaysColor = '#879AAF'
        this.baseTheme.selectBtnsDayDropdownTitleColor = '#879AAF'
        this.baseTheme.selectBtnsDayDropdownActiveTitleColor = '#0589D7'
        this.baseTheme.selectBtnsDayDropdownOptionColor = '#0589D7'
        this.baseTheme.selectBtnsDayDropdownVanCellTitleColor = '#0589D7'
        this.baseTheme.selectBtnsDayDropdownVanCellColor = '#879AAF'
        this.baseTheme.selectBtnsDayDropdownVanCellBgc = '#17253D'
        /**
         *indexWareHouse => list=> Title => AccountTitle.vue
         */
        this.baseTheme.accountingTitleColor = '#879AAF'
        /**
        /**
        *indexWareHouse => list=>
        */
        this.baseTheme.accountingItemBonesRiseColor = '#E1533B'
        this.baseTheme.accountingItemPendingColor = '#2BA66F'
        this.baseTheme.accountingItemIngColor = '#2BA66F'
        this.baseTheme.accountingItemRiseColor = '#FAFAFA'
        this.baseTheme.accountingItemItemStockColor = '#FAFAFA'
        /**
         *indexWareHouse => list=>ChaseNumber
         */
        this.baseTheme.accountingItemChaseRiseColor = '#E1533B'
        this.baseTheme.accountingItemChaseIngColor = '#0589D7'
        this.baseTheme.accountingItemChaseColor = '#FAFAFA'
        /**
         *indexWareHouse => popup
         */
        this.baseTheme.popupTitleColor = '#FAFAFA'
        this.baseTheme.popupNameColor = '#879AAF'
        this.baseTheme.popupValueColor = '#FAFAFA'
        this.baseTheme.popupValuePendingColor = '#879AAF'
        this.baseTheme.popupValueRiseColor = '#E1533B'
        this.baseTheme.popupValueRecordCodeRiseColor = '#E1533B'
        this.baseTheme.popupValueRecordCodePendingColor = '#2BA66F'
        this.baseTheme.popupValueRecordCodeFallColor = '#879AAF'
        this.baseTheme.popupRevokeBtnColor = '#879AAF'
        this.baseTheme.popupRevokeBtnBorder = '1px solid #879AAF'
        this.baseTheme.popupRevokeBtnRevokeColor = '#FAFAFA'
        this.baseTheme.popupRevokeBtnRevokeBgc = '#E73744'
        this.baseTheme.popupRevokeBtnRevokeActiveBgc = '#F95D68'
        /**
         *indexWareHouse => popup => Chase.vue
         */
        this.baseTheme.popupChaseRevokeBtnCancelColor = '#879AAF'
        this.baseTheme.popupChaseRevokeBtnCancelBorder = '1px solid #879AAF'
        this.baseTheme.popupChaseRevokeBtnRevokeColor = '#FAFAFA'
        this.baseTheme.popupChaseRevokeBtnRevokeBgc = '#E73744'
        this.baseTheme.popupChaseCollapseVanCellColor = '#879AAF'
        this.baseTheme.popupChaseCollapseVanItemContentColor = '#879AAF'
        this.baseTheme.popupChaseCollapseVanTitleContentBgc = '#233C5A'
        this.baseTheme.popupChaseStepInfoColor = '#FAFAFA'
        this.baseTheme.popupChaseStepInfoShowBgc = '#233C5A'
        this.baseTheme.popupChaseChaseStepBgc = '#1B2A43'
        this.baseTheme.popupChaseIssueColor = '#0589D7'
        this.baseTheme.popupChaseHaveValueColor = '#FAFAFA'
        this.baseTheme.popupChaseStatePendingColor = '#2BA66F'
        this.baseTheme.popupChaseStateFallColor = '#879AAF'
        this.baseTheme.popupChaseStateRiseColor = '#E1533B'
        this.baseTheme.popupChaseStateIngColor = '#0589D7'
        this.baseTheme.popupChaseSendBonesColor = '#E1533B'
        this.baseTheme.popupChaseRadioItemTitleColor = '#879AAF'
        this.baseTheme.popupChaseRadioItemVanSwitchBgc = '#31445E'
        this.baseTheme.popupChaseRadioItemVanOnSwitchBgc = '#034072'
        this.baseTheme.popupCancelBgc = ''
        this.baseTheme.popupCancelActiveBgc = '#28374E'
        this.baseTheme.popupRadioBorder = '1px solid #253C5A'
        /**
         *indexWareHouse => popup => ChaseChildItem.vue
         */
        this.baseTheme.popupChaseChildItemHoverBgc = '1E2E48'
        this.baseTheme.popupChaseChildItemDivColor = '#879AAF'
        this.baseTheme.popupChaseChildItemOrderColor = '#0589D7'
        this.baseTheme.popupChaseChildItemOrderIngColor = '#879AAF'
        this.baseTheme.popupChaseChildItemBonesColor = '#FAFAFA'
        this.baseTheme.popupChaseChildItemWaitColor = '#2BA66F'
        this.baseTheme.popupChaseChildItemProfitableColor = '#E1533B'
        /**
         *indexWareHouse => def.vue
         */
        this.baseTheme.popupChaseDefRiseColor = '#E1533B'
        this.baseTheme.popupChaseDefFallColor = '#2BA66F'
        /**
         *indexWareHouse => pcuserware.scss
         */
        this.baseTheme.pcUserWareBgc = `transparent linear-gradient(180deg
    , #1E2E48 0%, #0B1021 100%) 0% 0% no-repeat padding-box`
        this.baseTheme.pcUserWareBeforeBgc =
          '#D6D6D6 0% 0% no-repeat padding-box'
        this.baseTheme.pcUserWareItemColor = '#879AAF'
        this.baseTheme.pcUserWareItemHoverColor = '#1B2A43'
        this.baseTheme.pcUserWareItemFinishedColor = '#879AAF'
        this.baseTheme.pcUserWareItemFinishedBgc = '#1E2E48'
        this.baseTheme.pcUserWareItemVanTabsColor = '#879AAF'
        this.baseTheme.pcUserWareItemVanTabsActiveColor = '#FAFAFA'
        /**
         *layout
         */
        this.baseTheme.layoutHeaderWrapBgc = '#1E2E48'
        this.baseTheme.layoutLoginTitleColor = '#FAFAFA'
        this.baseTheme.layoutIveSelectBgc = '#1E2E48'
        this.baseTheme.layoutIveSelectDropdownBgc = '#1E2E48'
        this.baseTheme.layoutIveSelectItemFocusColor = '#0589D7'
        this.baseTheme.layoutIveSelectItemFocusBgc = '#1B2A43'
        this.baseTheme.layoutIveSelectItemHoverColor = '#0589D7'
        this.baseTheme.layoutIveSelectItemHoverBgc = '#1B2A43'
        this.baseTheme.layoutSelectBtnsBgc = '#17253D'
        this.baseTheme.layoutSelectBtnsBtnColor = '#879AAF'
        this.baseTheme.layoutSelectBtnsBtnActiveColor = '#0589D7'
        this.baseTheme.layoutSelectBtnsBtnActiveBgc = '#1E2E48'
        this.baseTheme.layoutSelectBtnsDaysColor = '#879AAF'
        this.baseTheme.layoutSelectBtnsDropdownTitleColor = '#879AAF'
        this.baseTheme.layoutSelectBtnsDropdownActiveTitleColor = '#0589D7'
        this.baseTheme.layoutSelectBtnsEllipsisColor = '#FAFAFA'
        this.baseTheme.layoutSelectBtnsDropdownOpenColor = '#0589D7'
        this.baseTheme.layoutSelectBtnsDropdownOpenTitleColor = '#0589D7'
        this.baseTheme.layoutSelectBtnsVanCellColor = '#879AAF'
        this.baseTheme.layoutSelectBtnsVanCellBgc = '#1E2E48'
        /**
         *notify
         */
        this.baseTheme.notifyWrapColor = '#FAFAFA'
        this.baseTheme.notifyWrapNoticeColor = '#0589D7'
        this.baseTheme.notifyWrapNoticeShadow = '0 8px 15px #0003'
        this.baseTheme.notifyWrapErrorColor = '#E73744'
        this.baseTheme.notifyWrapErrorShadow = '0 8px 15px #0003'
        /**
         *personal => header.vue
         */
        this.baseTheme.headerMenuBgc = '#071121'
        this.baseTheme.headerMenuBeforeBgc = '#253C5A'
        this.baseTheme.headerTitleColor = '#FAFAFA'
        this.baseTheme.headerSubTitleColor = '#CCC'
        this.baseTheme.headerCardTitleColor = '#879AAF'
        this.baseTheme.headerCardBtnColor = '#0589D7'
        this.baseTheme.headerCardNameColor = '#FAFAFA'
        /**
         *Popup
         */
        this.baseTheme.PopupBgc = '#1E2E48'
        this.baseTheme.PopupShadow = '0 8px 25px #0006'
        this.baseTheme.PopupHeaderColor = '#FAFAFA'
        this.baseTheme.PopupHeaderUnFocusColor = '#879AAF'
        this.baseTheme.PopupHeaderBgc = '#17253D'
        /**
         *safeVerify
         */
        this.baseTheme.safeVerifyBgc = '#CCC'
        this.baseTheme.safeVerifyTitleColor = '#879AAF'
        this.baseTheme.safeVerifyBorder = '1px solid #253C5A'
        /**
        stock => indexRevolving.vue
        */
        this.baseTheme.indexRevolvingItemColor = '#879AAF'
        this.baseTheme.indexRevolvingUpColor = '#E1533B'
        this.baseTheme.indexRevolvingDownColor = '#2BA66F'
        this.baseTheme.indexRevolvingCardBottomColor = '#87AF8E'
        this.baseTheme.indexRevolvingStockTitleColor = '#FAFAFA'
        this.baseTheme.indexRevolvingStockOrderColor = '#879AAF'
        this.baseTheme.indexRevolvingStockTimeColor = '#FAFAFA'
        this.baseTheme.indexRevolvingOpenNumberUpColor = '#E1533B'
        this.baseTheme.indexRevolvingOpenNumberDownColor = '#2BA66F'
        /**
         *Home.vue
         */
        this.baseTheme.homeBgc = '#17253D'
        this.baseTheme.homeTabBgc = 'rgba(7, 17, 33, .2)'
        this.baseTheme.homeTabHoverColor = '#0589D7'
        this.baseTheme.homeVanTabColor = '#879AAF'
        this.baseTheme.homeTabWrapBgc = '#1E2E48'
        this.baseTheme.homeTabsNavCardColor = '#0472B3'
        this.baseTheme.homeTabsNavCardBgc = '#1E2E48'
        this.baseTheme.homeSideCloseBtnBgc = '#17253D'
        this.baseTheme.homeSideCloseSwitchBtnBgc = '#17253D'
        this.baseTheme.homeCloseIconBgc = '#17253D'
        this.baseTheme.homeXlBorder = '6px solid #162236'
        this.baseTheme.homeTabsGroupColor = '#879AAF'
        this.baseTheme.homeActiveColor = '#FAFAFA'
        this.baseTheme.homeActiveBgc = 'transparent'
        this.baseTheme.homeScrollBarColor = '#31445E transparent'
        this.baseTheme.homeScrollBarShadow = 'inset 0 0 6px rgba(0, 0, 0, 0)'
        this.baseTheme.homeScrollBarBgc = '#31445E'
        this.baseTheme.homeBgcTransparent = 'rgba(7, 17, 33, .35)'
        this.baseTheme.homeBgcBeforeTransparent = 'transparent'
        this.baseTheme.homeBgcMixBlendMode = 'normal'
        this.baseTheme.homeEmptyColor = '#879AAF'
        this.baseTheme.homeThemeAfterBgc = '#253C5A'
        this.baseTheme.homeThemeIconActiveBgc = '#20385A'
        this.baseTheme.homeCloseBtnHoverBgc = '#1E2E48 0% 0% no-repeat padding-box'
        /**
         *BasePanel.vue
         */
        this.baseTheme.basePanelBgc = '#071121'
        this.baseTheme.basePanelColor = '#879AAF'
        this.baseTheme.basePanelHoverColor = '#0589D7'
        this.baseTheme.basePanelActiveColor = '#FAFAFA'
        /**
         *BaseTooltip.vue
         */
        this.baseTheme.baseTooltipColor = '#879AAF'
        this.baseTheme.baseTooltipBgc = '#17253D'
        this.baseTheme.baseTooltipBorderColor = '#253C5A'
        this.baseTheme.baseTooltipShadow = '0 1px 2px #00000026'
        /**
         *MemberCenter.vue
         */
        this.baseTheme.memberCenterBarBgc = '#FAFAFA'
        this.baseTheme.memberCenterBtnColor = '#879AAF'
        this.baseTheme.memberCenterBtnHoverColor = '#0589D7'
        this.baseTheme.memberCenterBtnActiveColor = '#FAFAFA'
        this.baseTheme.memberCenterBgcMix = '#071121'
        this.baseTheme.memberCenterListBorder = '1px solid #1E2E48'
        this.baseTheme.memberCenterTitleColor = '#879AAF'
        this.baseTheme.memberCenterTextColor = '#FAFAFA'
        this.baseTheme.memberCenterSpanActiveColor = '#FAFAFA'
        this.baseTheme.memberCenterDisableColor = '#879AAF'
        this.baseTheme.memberCenterDateBorder = ' 1px solid #879AAF'
        this.baseTheme.memberCenterPickerBgc = '#17253D'
        this.baseTheme.memberCenterPickerBorder = '1px solid #253C5A'
        this.baseTheme.memberCenterPickerShadow = '0 8px 15px #0003'
        this.baseTheme.memberCenterPickerPrimary = '#F0F'
        this.baseTheme.memberCenterRadioBorder = '1px solid #FFF'
        this.baseTheme.memberCenterRadioBgc = '#0589D7'
        this.baseTheme.memberCenterRadioCheckedBorder = '#0589D7'
        this.baseTheme.memberCenterMenuTitleColor = '#375E86'
        /**
         *AssetsMange.vue
         */
        this.baseTheme.assetsManageBtnColor = '#879AAF'
        this.baseTheme.assetsManageBtnActiveColor = '#0589D7'
        this.baseTheme.assetsManageBtnLineColor = '#1E2E48'
        this.baseTheme.assetsManageFontColor = '#879AAF'
        this.baseTheme.assetsManageLightColor = '#FAFAFA'
        this.baseTheme.assetsManagePaginationColor = '#879AAF'
        this.baseTheme.assetsManageMenuHoverBgc =
          'rgba(30,46,72,.6)'
        /**
         *step
         */
        this.baseTheme.stepBtnBgc = '#0589D7'
        this.baseTheme.stepWrapBorder = '1px solid #879AAF'
        this.baseTheme.stepWrapActiveBorder = '1px solid #0589D7'
        this.baseTheme.stepWrapErrorBorder = ' 1px solid #E73744'
        this.baseTheme.stepInputColor = '#FAFAFA'
        this.baseTheme.stepInputPlaceholder = '#879AAF'

        /**
         *step-2
         */
        this.baseTheme.step2BackBtnColor = '#879AAF'
        this.baseTheme.step2BackBtnBorder = '1px solid #879AAF'
        this.baseTheme.step2NextBtnColor = '#FAFAFA'
        this.baseTheme.step2NextBtnBorder = ' #0589D7'
        this.baseTheme.step2CheckWrapBorder = '1px solid #879AAF'
        this.baseTheme.step2CheckWrapHoverText = '#FAFAFA'
        this.baseTheme.step2CheckWrapText = '#879AAF'
        this.baseTheme.step2SelectBorder = '1px solid #0589D7'
        this.baseTheme.step2SelectBgc = '#0589D714'
        this.baseTheme.step2SelectText = '#FAFAFA'
        /**
         *step-3
         */
        this.baseTheme.step3TitleColor = '#879AAF'
        this.baseTheme.step3PassWordFocusBorder = '1px solid #0589D7'
        this.baseTheme.step3PassWordBgc = '#879AAF26'
        this.baseTheme.step3PassWordDomI = '#FFF'
        this.baseTheme.step3QaListItemColor = '#879AAF'
        this.baseTheme.step3QaInputColor = '#879AAF'
        this.baseTheme.step3QaInputBorder = '1px solid #879AAF'
        this.baseTheme.step3QaInputPlaceholder = '#879AAF'
        this.baseTheme.step3PassWordFocusColor = '#0589D7'
        /**
         *Forget
         */
        this.baseTheme.ForgetShowStepWrapTitleColor = '#FAFAFA'
        this.baseTheme.ForgetHaveAccountWrapText = '#879AAF'
        this.baseTheme.ForgetHaveAccountWrapLink = '#0589D7'
        this.baseTheme.ForgetStepLineBgc = '#879AAF'
        this.baseTheme.ForgetStepLineNowBgc = '#1E2E48'
        this.baseTheme.ForgetNowStepColor = '#0589D7'
        this.baseTheme.ForgetNowStepNumberBgc = '#101C30'
        this.baseTheme.ForgetNowStepNumberBorder = '1px solid #0589D7'
        this.baseTheme.ForgetNowStepCheckOkColor = '#FAFAFA'
        this.baseTheme.ForgetNowStepCheckOkBgc = '#0589D7'
        this.baseTheme.ForgetNumberBgc = '#101C30'
        this.baseTheme.ForgetNumberBgcBefore = '#1E2E48'
        /**
         *FromLogin
         */
        this.baseTheme.fromLoginTitleColor = '#FAFAFA'
        this.baseTheme.fromLoginRegisterTextColor = '#879AAF'
        this.baseTheme.fromLoginRegisterGotoColor = '#0589D7'
        this.baseTheme.fromLoginBtnBgc = '#0589D7'
        this.baseTheme.fromLoginGotoColor = '#0589D7'
        this.baseTheme.fromLoginUrlTitleColor = '#879AAF'
        this.baseTheme.fromLoginUrlTitleBorder = '1px solid #879AAF'
        this.baseTheme.fromLoginInputWrapBorder = '1px solid #879AAF'
        this.baseTheme.fromLoginInputWrapActiveBorder = '1px solid #0589D7'
        this.baseTheme.fromLoginInputWrapHoverBorder = '1px solid #0589D7'
        this.baseTheme.fromLoginInputWrapErrorBorder = '1px solid #E73744'
        this.baseTheme.fromLoginInputPlaceholder = '#879AAF'
        this.baseTheme.fromLoginItemContentBgc = '#17253D'
        this.baseTheme.fromLoginItemContentBorder = '1px solid #253C5A'
        /**
         *inputerror
         */
        this.baseTheme.inputErrorColor = '#E73744'
        /**
         *Loginscss
         */
        this.baseTheme.loginScssBgc = '#1E2E48'
        this.baseTheme.loginScssInputBgc = '#101C30'
        this.baseTheme.loginScssLoadingLogo = '#1E2E48'
        this.baseTheme.loginScssShadow = '0px 15px 30px #00000033'
        /**
         *Loginslider
         */
        this.baseTheme.LoginsliderSwiperPaginationBgc =
          'rgba(255, 255, 255, 0.1)'
        this.baseTheme.LoginsliderSwiperPaginationActiveBgc = '#FFF'
        this.baseTheme.loginsliderTitleColor = '#FAFAFA'
        this.baseTheme.loginsliderDescribeColor = '#879AAF'
        this.baseTheme.loginsliderNumberShowColor = '#0589D7'
        this.baseTheme.loginsliderNumberTitleColor = '#FAFAFA'
        this.baseTheme.loginsliderNumberDescribeColor = '#879AAF'
        /**
         *Resgister
         */
        this.baseTheme.registerTitleColor = '#FAFAFA'
        this.baseTheme.registerInfoColor = '#879AAF'
        this.baseTheme.registerGotoTextColor = '#879AAF'
        this.baseTheme.registerGotoLinkColor = '#0589D7'
        this.baseTheme.registerBtnBgc = '#0589D7'
        this.baseTheme.registerWrapSetBorder = '1px solid #879AAF'
        this.baseTheme.registerWrapSetInputColor = '#FAFAFA'
        this.baseTheme.registerWrapSetPlaceHolderColor = '#879AAF'
        this.baseTheme.registerWrapSetInputHover = '1px solid #0589D7'
        this.baseTheme.registerWrapSetInputActive = '1px solid #0589D7'
        this.baseTheme.registerWrapSetInputError = '1px solid #E73744'
        /**
         *app
         */
        this.baseTheme.appContentViewBgc = '#1E2E48'
        this.baseTheme.appVanDialogBgc = '#101E35'
        this.baseTheme.appVanAlertDialogBgc = '#17253D'
        this.baseTheme.appVanAlertHeaderColor = '#FAFAFA'
        this.baseTheme.appVanDialogShadow = '0 8px 25px #0006'
        this.baseTheme.appPcNoticeColor = '#879AAF'
        this.baseTheme.appPcNoticeHasTitleColor = '#879AAF'
        this.baseTheme.appPcNoticeCancelColor = '#879AA'
        this.baseTheme.appPcNoticeHeaderColor = '#FAFAFA'
        this.baseTheme.appPcNoticeHeaderAfterBorder = '3px solid #2BA66F'
        this.baseTheme.appErrorAlertColor = '#879AAF'
        this.baseTheme.appErrorAlertTitleColor = '#879AAF'
        this.baseTheme.appErrorAlertHeaderColor = '#FAFAFA'
        this.baseTheme.appErrorAlertHeaderAfterBorder = '3px solid #E73744'
        this.baseTheme.appDialogHeaderColor = '#879AAF'
        this.baseTheme.appDialogFooterBorder = '1px solid #253C5A'
        this.baseTheme.appDialogConfirmColor = '#FAFAFA'
        this.baseTheme.appDialogConfirmBgc = '#0589D7'
        this.baseTheme.appDialogCancelColor = '#879AAF'
        this.baseTheme.appDialogCancelBorder = '1px solid #879AAF'
        this.baseTheme.appVpickerBgc = '#17253D'
        this.baseTheme.appVDataBeforeBorder = '1px solid #253C5A'
        this.baseTheme.appScrollTrackShadow = 'inset 0 0 6px rgba(0, 0, 0, 0)'
        this.baseTheme.appScrollThumb = '#31445E'
        this.baseTheme.appVDatePickerColor = '#879AAF'
        this.baseTheme.appVDatePickerActiveColor = '#FAFAFA'
        this.baseTheme.appDatePickerTableColor = '#879AAF'
        /**
         * public
         */
        this.baseTheme.confirmBtnBgc = '#0589D7'
        this.baseTheme.confirmBtnColor = '#FAFAFA'
        this.baseTheme.confirmBtnHoverBgc = '#45A2D9'
        this.baseTheme.cancelBtnColor = '#879AAF'
        this.baseTheme.cancelBtnHoverBgc = '#222F43'
        /**
         * Navbar
         */
        this.baseTheme.layoutRouterHide = '#879AAF'
        this.baseTheme.layoutRouterHover = '#0589D7'
        /**
         * dropdownqrcode
         */
        this.baseTheme.dropdownVancellColor = '#FAFAFA'
        this.baseTheme.dropdownContentBgc = '#1B2A43'
        this.baseTheme.dropdownBorder = '1px solid #1D324B'
        this.baseTheme.dropdownTitleBorder = '1px solid #1D324B'
        this.baseTheme.dropdownInfoColor = '#879AAF'
        this.baseTheme.dropdownItemColor = '#FAFAFA'
        this.baseTheme.dropdownHoverItemColor = '#253C5A'
        this.baseTheme.dropdownMainTextColor = '#FAFAFA'
        this.baseTheme.dropdownMainQrcodeBorder = '1px solid #707070'
        this.baseTheme.dropdownBoxShadow = '0 8px 25px #0006'
        this.baseTheme.dropdownRankLevelColor = '#0589D7'
        this.baseTheme.dropdownTypeBgc = 'rgba(37, 60, 90, 0) 0% 0% no-repeat padding-box'
        /**
        * bankMange
        */
        this.baseTheme.bankMangeSpanColor = '#FAFAFA'
        this.baseTheme.bankMangeTitleColor = '#375E86'
        /**
        * announcements
        */
        this.baseTheme.announcementSwiperColor = '#879AAF'
        this.baseTheme.announcementBtnsColor = '#879AAF'
        this.baseTheme.announcementTitleColor = '#FAFAFA'
        /**
         * ClobalLoading
        */
        this.baseTheme.ClobalLoadingBox1Bgc = '#0589D7 0% 0% no-repeat padding-box'
        this.baseTheme.ClobalLoadingBox2Bgc = '#253C5A 0% 0% no-repeat padding-box'
        this.baseTheme.ClobalLoadingBox3Bgc = '#253C5A 0% 0% no-repeat padding-box'
        this.baseTheme.ClobalLoadingBox4Bgc = '#0589D7 0% 0% no-repeat padding-box'
        /**
        * Maintain
        */
        this.baseTheme.MaintainBgc = '#0B1021'
        this.baseTheme.MaintainTitleColor = '#FAFAFA'
        this.baseTheme.MaintainInfoColor = '#879AAF'
        this.baseTheme.MaintainBtnColor = '#879AAF'
        this.baseTheme.MaintainBtnBorder = '1px solid #879AAF'
        this.baseTheme.MaintainTimeShow = '#0589D7'
        /**
        * Navigation
        */
        this.baseTheme.navigationCardWrapBgc = '#1E2E48'
        this.baseTheme.navigationCardWrapBeforeBgc = '#17253D'
        this.baseTheme.navigationWrapBgc = '#1E2E48'
        this.baseTheme.navigationWrapBorder = '#17253D'
        this.baseTheme.navigationSlideBtnBgc = '17253D'
        this.baseTheme.navigationSlideOutBgc = '#17253D'
        /**
        * RechargeComponents
        */
        this.baseTheme.rechargeInputWrap = '#879AAF'
        this.baseTheme.rechargeInputWrapBorder = '1px solid #879AAF'
        this.baseTheme.rechargeInputWrapHoverBorder = '1px solid #0589D7'
        this.baseTheme.rechargeInputWrapFocusBorder = '1px solid #0589D7'
        this.baseTheme.rechargeInputWrapErrorBorder = '1px solid #E73744'
        this.baseTheme.rechargeInputColor = '#FAFAFA'
        this.baseTheme.rechargeInputPlaceholderColor = '#879AAF'
        this.baseTheme.rechargeInputErrorColor = '#E73744'
        this.baseTheme.rechargeItemContentBgc = '#17253D'
        this.baseTheme.rechargeItemContentBorder = '1px solid #253C5A'
        this.baseTheme.rechargeItemColor = '#879AAF'
        this.baseTheme.rechargeItemSelectActiveColor = '#FAFAFA'
        this.baseTheme.rechargeItemSelectActiveBgc = '#1B2A43'
        this.baseTheme.rechargeItemHoverColor = '#FAFAFA'
        this.baseTheme.rechargeItemHoverBgc = '#1B2A43'
        /**
        * RechargePage
        */
        this.baseTheme.rechargePageNavAfter = '#071121'
        this.baseTheme.rechargePageSpanColor = '#FAFAFA'
        this.baseTheme.rechargePageMenuTitle = '#375E86'
        this.baseTheme.rechargeWalletAfterBgc = '#1E2E48'
        this.baseTheme.rechargeVanTabColor = '#879AAF'
        this.baseTheme.rechargeVanTabHoverColor = '#0589D7'
        this.baseTheme.rechargeVanTabActiveColor = '#FAFAFA'
        this.baseTheme.rechargeVanTabLingBgc = '#FAFAFA'
        /** ============ */
        break
      default:
        this.themeWord = 'classic'
        /*
          8888b.  888888 888888    db    88   88 88     888888
          8I  Yb 88__   88__     dPYb   88   88 88       88
          8I  dY 88""   88""    dP__Yb  Y8   8P 88  .o   88
          8888Y"  888888 88     dP""""Yb `YbodP' 88ood8   88
        */
        this.baseTheme.fontBase = '14px'
        this.baseTheme.checkUse = '#F00'
        /**
         * app.vue
         */
        this.baseTheme.contentBgc = 'transparent'
        this.baseTheme.scrollBarTrackBgc = '#162236'
        this.baseTheme.fireFoxScrollBar = '#31445E'
        this.baseTheme.fireScrollBarTrackShadow =
          'inset 0 0 6px rgba(0, 0, 0, 0)'
        this.baseTheme.fireScrollbarThumbBgc = '#31445E'
        this.baseTheme.vantDialogBgc = '#162236'
        this.baseTheme.vantButtonDefaultBgc = '#162236'
        this.baseTheme.vantDialogCancel = '#0589D7'
        this.baseTheme.menuRise = '#E1533B'
        this.baseTheme.menufall = '#2BA66F'
        this.baseTheme.dropDownShadow = '0 8px 25px #0006'
        this.baseTheme.vApplicationBgc =
          'transparent linear-gradient(180deg, #1E2E48 0%, #101E35 100%) 0% 0% no-repeat padding-box'
        /**
         * Graph.vue
         */
        this.baseTheme.graphBgc = 'transparent'
        /**
         * bankMange.vue
         */
        this.baseTheme.bankMangeBgc = '#CCC'
        this.baseTheme.bankMangeColor = '#879AAF'
        this.baseTheme.bankMangeTitleBorderColor = '1px solid #253C5A'
        /**
          * base系列
          */
        this.baseTheme.baseBtnColor = '#879AAF'
        this.baseTheme.baseBtnHoverColor = '#879AAF26'
        this.baseTheme.baseBtnSuccessColor = '#FAFAFA'
        this.baseTheme.baseBtnSuccessBgc = '#0589D7'
        this.baseTheme.baseBtnSuccessHoverBgc = '#45A2D9'
        this.baseTheme.baseBtnBorder = '1px solid #879AAF'
        this.baseTheme.baseInputColor = '#FAFAFA'
        this.baseTheme.baseInputFocusColor = '#0589D7'
        this.baseTheme.baseInputBorderColor = '1px solid #879AAF'
        /**
         * mainText.vue
         */
        this.baseTheme.tradeMainTextProgressColor = '#0589D7'
        this.baseTheme.tradeMainTextGetColorMoreZero = '#E1533B'
        this.baseTheme.tradeMainTextGetColorSLessZero = '#37C882'
        this.baseTheme.tradeMainTextGetColorNotEqualZero = '#253C5A'
        this.baseTheme.tradeMainTextGetColorEqualZero = '#656D7A'
        this.baseTheme.tradeMainTextGetColorDef = '#253C5A'
        this.baseTheme.tradeMainTextOrderColor = '#879AAF'
        /**
         * tradeMain.vue
         */
        this.baseTheme.tradeMainTabLineBgc = '#FAFAFA'
        this.baseTheme.tradeMainTabsNavColor = '#879AAF'
        this.baseTheme.tradeMainTabsNavHoverColor = '#0589D7'
        this.baseTheme.tradeMainTabsNavBgc = 'transparent'
        this.baseTheme.tradeMainItemActive = '#1E2E48'
        this.baseTheme.tradeMainIconBgc = '#20314E'
        this.baseTheme.tradeMainTabsNavColor2 = '#162236'
        this.baseTheme.tradeMainPcTitleColor = '#FAFAFA'
        this.baseTheme.tradeMainPcBorderBottom = '1px solid #1E2E48'
        /**
         * trade.vue
         */
        this.baseTheme.tradeNavBgc = '#17253D'
        this.baseTheme.tradeNavMenuItemColor = '#7D91A5'
        this.baseTheme.tradeNavMenuItemIsActFFF = '#0472B3'
        this.baseTheme.tradeListTitle = '#879AAF'
        this.baseTheme.tradeListItemBgc = '#1E2E48'
        this.baseTheme.tradeListItemBgcHover = '#1B2A43'
        this.baseTheme.tradeListItemBgcActive = '#1B2A43'
        this.baseTheme.tradeListHeaderColor = '#FFF'
        this.baseTheme.tradeListItemContentColor = '#879AAF'
        this.baseTheme.tradeListItemUpColor = '#E1533B'
        this.baseTheme.tradeListItemDownColor = '#37C882'
        this.baseTheme.tradeVtabsBgc = '#1B2A43'
        this.baseTheme.tradeVtabsActive = '#0472B3'
        this.baseTheme.tradeVtabsUrl =
          "url('/static/images/trade/arrow-left.svg')"
        /**
         *trendRank.vue
         */
        this.baseTheme.trendRankHeaderColor = '#FAFAFA'
        this.baseTheme.trendRankHeaderBorder = '1px solid #1E2E48'
        this.baseTheme.trendRankTableColor = '#879AAF'
        this.baseTheme.trendRankTrendInfoColor = '#879AAF'
        this.baseTheme.trendRankTrendInfoHoverBgc = '#1B2A43'
        this.baseTheme.trendRankUpColor = '#E1533B'
        this.baseTheme.trendRankDownColor = '#2BA66F'
        this.baseTheme.trendRankNameColor = '#FAFAFA'
        this.baseTheme.trendRankTdFirstChild = '#FAFAFA'
        this.baseTheme.trendRankTdNth2Child = '#879AAF'
        this.baseTheme.trendRankTdUp = '#E1533B'
        this.baseTheme.trendRankTdDown = '#2BA66F'
        this.baseTheme.trendRankSlideBgc = '#17253D'
        this.baseTheme.trendRankUseIdColor = '#FFF'
        this.baseTheme.trendRankUserNameColor = '#879AAF'
        this.baseTheme.trendRankProfit = '#E1533B'
        this.baseTheme.trendRankBorderBgc = '#17253D'
        /**
         *Double.vue
         */
        this.baseTheme.doubleTitleColor = '#879AAF'
        this.baseTheme.doubleItemWrapperBorder = '1px solid #879AAF'
        this.baseTheme.doubleVanCellColor = '#7D91A5'
        this.baseTheme.doubleVanCellColor2 = '#FAFAFA'
        this.baseTheme.doubleCollapseContentColor = '#879AAF'
        this.baseTheme.doubleCollapseContentBgc = '#17253D'
        this.baseTheme.doubleIconArrowUrl =
          "url('/static/images/bet/triangle.svg')"
        this.baseTheme.doubleBtnActiveColor = '#FAFAFA'
        this.baseTheme.doubleBtnActiveBgc = '#1B2A43'
        this.baseTheme.doubleOrderMoneyBorder = '1px solid #879AAF'
        this.baseTheme.doubleOrderMoneyActiveBorder = '1px solid #0589D7'
        this.baseTheme.doubleItemNameColor = '#879AAF'
        this.baseTheme.doubleItemValueColor = '#FAFAFA'
        this.baseTheme.doubleBoubleBtnColor = '#FAFAFA'
        this.baseTheme.doubleBoubleBtnBgc = '#0589D7'
        this.baseTheme.doubleBoubleBtnActiveBgc = '#45A2D9'
        this.baseTheme.doubleDropdownItemBorder = '1px solid #253C5A'
        this.baseTheme.doubleDropdownItemShadow = '0 8px 15px #0003'
        this.baseTheme.doubleDropdownItemActiveColor = '#FAFAFA'
        this.baseTheme.doubleDropdownItemActiveBgc = '#1B2A43'
        this.baseTheme.doubleDropdownItemTitleColor = '#FAFAFA'
        this.baseTheme.doubleDropdownItemTitleBgc = '#1B2A43'
        this.baseTheme.doubleDropdownItemTitleHoverColor = '#FAFAFA'
        this.baseTheme.doubleDropdownItemTitleHoverBgc = '#1B2A43'
        this.baseTheme.doubleDropdownVanCellColor = '#879AAF'
        this.baseTheme.doubleDropdownVanCelBgc = '#17253D'
        this.baseTheme.doubleUnitBgc = '#253C5A'
        this.baseTheme.doubleDropdownMenuColor = '#7D91A5'
        this.baseTheme.doubleDropdownMenuBorder =
          'transparent transparent #7D91A5 #7D91A5'
        this.baseTheme.doubleVanEllipsisColor = '#879AAF'
        this.baseTheme.doubleInputAreaVanCellBorder = '1px solid #879AAF'
        this.baseTheme.doubleInputAreaCollapseItemBorder = '1px solid #0589D7'
        this.baseTheme.doubleMaxIncome = '#253C5A'
        this.baseTheme.doubleVanCellColor3 = '#879AAF'
        /**
         *indexBetTab.vue
         */
        this.baseTheme.indexBetTabTitleColor = '879AAF'
        this.baseTheme.indexBetTabTitleBorder = '1px solid #1E2E48'
        this.baseTheme.indexBetStockWrapQaColor = '#879AAF'
        this.baseTheme.indexBetTabNavColor = '#17253D'
        this.baseTheme.indexBetVanTabColor = '#879AAF'
        this.baseTheme.indexBetVanTabActiveColor = '#FAFAFA'
        this.baseTheme.indexBetVanTabActiveBgc = '#0589D7'
        this.baseTheme.indexBetSwiperBtnBgc = '#1B2A43'
        this.baseTheme.indexBetHoverBtnColor = '#0589D7'
        this.baseTheme.indexBetActiveColor = '#FAFAFA'
        this.baseTheme.indexBetBtnColor = '#879AAF'
        this.baseTheme.indexBetMoveLineBgc = '#0589D7'
        /**
         *locations.vue
         */
        this.baseTheme.locationInputSetColor = '#FAFAFA'
        this.baseTheme.locationTitleColor = '#879AAF'
        this.baseTheme.locationWrapperBorder = '1px solid #253C5A'
        this.baseTheme.locationVanCellColor = '#7D91A5'
        this.baseTheme.locationIsShowVanCellColor = '#FAFAFA'
        this.baseTheme.locationItemContentColor = '#879AAF'
        this.baseTheme.locationItemContentBgc = '#17253D'
        this.baseTheme.locationItemIconArrowUrl =
          "url('/static/images/bet/triangle.svg')"
        this.baseTheme.locationItemIconArrowUrlActive = "url('/static/images/bet/triangle-active.svg')"
        this.baseTheme.locationItemActiveColor = '#0589D7'
        this.baseTheme.locationItemTitleExpandedBorder = '1px solid #0589D7'
        this.baseTheme.locationItemBtnActiveColor = '#FAFAFA'
        this.baseTheme.locationItemBtnActiveBgc = '#1B2A43'
        this.baseTheme.locationItemOrderMoneyBorder = '1px solid #879AAF'
        this.baseTheme.locationItemOrderMoneyActiveBorder = '1px solid #0589D7'
        this.baseTheme.locationShowInfoItemNameColor = '#879AAF'
        this.baseTheme.locationShowInfoItemValueColor = '#FAFAFA'
        this.baseTheme.locationBoubleBtnColor = '#FAFAFA'
        this.baseTheme.locationDropdownItemBorder = '1px solid #253C5A'
        this.baseTheme.locationDropdownItemShadow = '0 8px 15px #0003'
        this.baseTheme.locationDropdownItemActiveColor = '#FAFAFA'
        this.baseTheme.locationDropdownItemActiveBgc = '#1B2A43'
        this.baseTheme.locationCellTitleHoverColor = '#FAFAFA'
        this.baseTheme.locationCellTitleHoverBgc = '#1B2A43'
        this.baseTheme.locationUnitVanCellColor = '#879AAF'
        this.baseTheme.locationUnitVanCellBgc = '#17253D'
        this.baseTheme.locationUnitBgc = '#253C5A'
        this.baseTheme.locationUnitDropdownTitleActiveColor = '#0589D7'
        this.baseTheme.locationUnitDropdownEllipsisColor = '#879AAF'
        this.baseTheme.locationChaseVanCellColor = '#879AAF'
        this.baseTheme.locationChaseSwitchColor = '#31445E'
        this.baseTheme.locationChaseOnSwitchColor = '#0589D7'
        this.baseTheme.locationInputWrapBorder = '1px solid #879AAF'
        this.baseTheme.locationInputWrapBgc = '#253C5A'
        this.baseTheme.locationInputInputBgc = '#17253D'
        this.baseTheme.locationSelectInputBorder = '1px solid #879AAF'
        this.baseTheme.locationSelectInputActiveBorder = '1px solid #0589D7'
        this.baseTheme.locationSelectRemarkerColor = '#879AAF'
        this.baseTheme.locationPlayModeBgc = '#17253D'
        this.baseTheme.locationPlayModeBarOpenBorder = '1px solid #0589D7'
        this.baseTheme.locationPlayModeVanPopupBgc = '#17253D'
        this.baseTheme.locationPlayModeTitleColor = '#879AAF'
        this.baseTheme.locationBetInputAreaVanCellBorder = '1px solid #879AAF'
        this.baseTheme.locationBetInputAreaTitleExpandedBorder =
          '1px solid #0589D7'
        this.baseTheme.locationBetWrapBtnBgc = '#1B2A43'
        this.baseTheme.locationBoubleTitleColor = '#879AAF'
        this.baseTheme.locationBoubleTitleBgc = '#1B2A43'
        this.baseTheme.locationBoubleTitleActiveColor = '#0589D7'
        this.baseTheme.locationBoubleTitleActiveBorder = '1px solid #0589D7'
        this.baseTheme.locationPlayTypeSpanColor = '#879AAF'
        this.baseTheme.locationPlayTypeSpanBgc = '#1B2A43'
        this.baseTheme.locationPlayTypeSpanActiveColor = '#0589D7'
        this.baseTheme.locationPlayTypeSpanActiveBgc = '#131C4C'
        this.baseTheme.locationPlayTypeSpanActiveBorder = '0 0 0 1px #0589D7'
        this.baseTheme.locationDobleBorder = '1px solid #879AAF'
        this.baseTheme.locationDobleActiveBorder = '1px solid #0589D7'
        this.baseTheme.locationDobleInputColor = '#FAFAFA'
        this.baseTheme.locationUnitWrapColor = '#879AAF'
        this.baseTheme.locationUnitWrapBgc = '#253C5A'
        this.baseTheme.locationVanCellColor2 = '#879AAF'
        this.baseTheme.locationItemWrapperBgc = '#17253D'
        this.baseTheme.locationItemWrapperBorder = '1px solid #253C5A'
        this.baseTheme.locationItemDivColor = '#879AAF'
        this.baseTheme.locationItemHoverDivColor = '#FAFAFA'
        this.baseTheme.locationItemHoverDivBgc = '#1B2A43'
        this.baseTheme.locationMaxIncomeColor = '#253C5A'
        this.baseTheme.locationBetShowBorder = '1px solid #1D324B'
        /**
         *postion.vue
         */
        this.baseTheme.postionTabActiveColor = '#0589D7'
        this.baseTheme.postionTabLineColor = '#0589D7'
        this.baseTheme.postionTabNavBgc = '#1B2A43'
        /**
         *indexWareHouse => chaseNumberBtns.vue
         */
        this.baseTheme.chaseNumberBtnsBtnsBgc = '#1E2E48'
        this.baseTheme.chaseNumberBtnsBtnColor = '#879AAF'
        this.baseTheme.chaseNumberBtnsBtnActiveColor = '#0589D7'
        this.baseTheme.chaseNumberBtnsBtnActiveBgc = '#0589D7'
        this.baseTheme.chaseNumberBtnsDaysColor = '#879AAF'
        this.baseTheme.chaseNumberDropdownTitleColor = '#FAFAFA'
        this.baseTheme.chaseNumberDropdownTitleActiveColor = '#0589D7'
        this.baseTheme.chaseNumberDropdownItemContentBorder =
          '2px solid #253C5A'
        this.baseTheme.chaseNumberDropdownItemActiveColor = '#FAFAFA'
        this.baseTheme.chaseNumberDropdownItemActiveBgc = '#1B2A43'
        this.baseTheme.chaseNumberDropdownVanCellTitleColor = '#FAFAFA'
        this.baseTheme.chaseNumberDropdownVanCellColor = '#879AAF'
        this.baseTheme.chaseNumberDropdownVanCellBgc = '#17253D'
        /**
         *indexWareHouse => selectBtns.vue
         */
        this.baseTheme.selectBtnsContentBtnsBgc = '#1E2E48'
        this.baseTheme.selectBtnsBtnColor = '#879AAF'
        this.baseTheme.selectBtnsBtnActiveColor = '#0589D7'
        this.baseTheme.selectBtnsBtnActiveBgc = '#1E2E48'
        this.baseTheme.selectBtnsDaysColor = '#879AAF'
        this.baseTheme.selectBtnsDayDropdownTitleColor = '#879AAF'
        this.baseTheme.selectBtnsDayDropdownActiveTitleColor = '#0589D7'
        this.baseTheme.selectBtnsDayDropdownOptionColor = '#0589D7'
        this.baseTheme.selectBtnsDayDropdownVanCellTitleColor = '#0589D7'
        this.baseTheme.selectBtnsDayDropdownVanCellColor = '#879AAF'
        this.baseTheme.selectBtnsDayDropdownVanCellBgc = '#17253D'
        /**
         *indexWareHouse => list=> Title => AccountTitle.vue
         */
        this.baseTheme.accountingTitleColor = '#879AAF'
        /**
        /**
        *indexWareHouse => list=>
        */
        this.baseTheme.accountingItemBonesRiseColor = '#E1533B'
        this.baseTheme.accountingItemPendingColor = '#2BA66F'
        this.baseTheme.accountingItemIngColor = '#2BA66F'
        this.baseTheme.accountingItemRiseColor = '#FAFAFA'
        this.baseTheme.accountingItemItemStockColor = '#FAFAFA'
        /**
        /**
        *indexWareHouse => list=>ChaseNumber
        */
        this.baseTheme.accountingItemChaseRiseColor = '#E1533B'
        this.baseTheme.accountingItemChaseIngColor = '#0589D7'
        this.baseTheme.accountingItemChaseColor = '#FAFAFA'
        /**
         *indexWareHouse => popup
         */
        this.baseTheme.popupTitleColor = '#FAFAFA'
        this.baseTheme.popupNameColor = '#879AAF'
        this.baseTheme.popupValueColor = '#FAFAFA'
        this.baseTheme.popupValuePendingColor = '#879AAF'
        this.baseTheme.popupValueRiseColor = '#E1533B'
        this.baseTheme.popupValueRecordCodeRiseColor = '#E1533B'
        this.baseTheme.popupValueRecordCodePendingColor = '#2BA66F'
        this.baseTheme.popupValueRecordCodeFallColor = '#879AAF'
        this.baseTheme.popupRevokeBtnColor = '#879AAF'
        this.baseTheme.popupRevokeBtnBorder = '1px solid #879AAF'
        this.baseTheme.popupRevokeBtnRevokeColor = '#FAFAFA'
        this.baseTheme.popupRevokeBtnRevokeBgc = '#E73744'
        this.baseTheme.popupRevokeBtnRevokeActiveBgc = '#F95D68'
        /**
         *indexWareHouse => popup => Chase.vue
         */
        this.baseTheme.popupChaseRevokeBtnCancelColor = '#879AAF'
        this.baseTheme.popupChaseRevokeBtnCancelBorder = '1px solid #879AAF'
        this.baseTheme.popupChaseRevokeBtnRevokeColor = '#FAFAFA'
        this.baseTheme.popupChaseRevokeBtnRevokeBgc = '#E73744'
        this.baseTheme.popupChaseCollapseVanCellColor = '#879AAF'
        this.baseTheme.popupChaseCollapseVanItemContentColor = '#879AAF'
        this.baseTheme.popupChaseCollapseVanTitleContentBgc = '#233C5A'
        this.baseTheme.popupChaseStepInfoColor = '#FAFAFA'
        this.baseTheme.popupChaseStepInfoShowBgc = '#233C5A'
        this.baseTheme.popupChaseChaseStepBgc = '#1B2A43'
        this.baseTheme.popupChaseIssueColor = '#0589D7'
        this.baseTheme.popupChaseHaveValueColor = '#FAFAFA'
        this.baseTheme.popupChaseStatePendingColor = '#2BA66F'
        this.baseTheme.popupChaseStateFallColor = '#879AAF'
        this.baseTheme.popupChaseStateRiseColor = '#E1533B'
        this.baseTheme.popupChaseStateIngColor = '#0589D7'
        this.baseTheme.popupChaseSendBonesColor = '#E1533B'
        this.baseTheme.popupChaseRadioItemTitleColor = '#879AAF'
        this.baseTheme.popupChaseRadioItemVanSwitchBgc = '#31445E'
        this.baseTheme.popupChaseRadioItemVanOnSwitchBgc = '#034072'
        this.baseTheme.popupCancelBgc = ''
        this.baseTheme.popupCancelActiveBgc = '#28374E'
        this.baseTheme.popupRadioBorder = '1px solid #253C5A'
        /**
         *indexWareHouse => popup => ChaseChildItem.vue
         */
        this.baseTheme.popupChaseChildItemHoverBgc = '1E2E48'
        this.baseTheme.popupChaseChildItemDivColor = '#879AAF'
        this.baseTheme.popupChaseChildItemOrderColor = '#0589D7'
        this.baseTheme.popupChaseChildItemOrderIngColor = '#879AAF'
        this.baseTheme.popupChaseChildItemBonesColor = '#FAFAFA'
        this.baseTheme.popupChaseChildItemWaitColor = '#2BA66F'
        this.baseTheme.popupChaseChildItemProfitableColor = '#E1533B'
        /**
         *indexWareHouse => def.vue
         */
        this.baseTheme.popupChaseDefRiseColor = '#E1533B'
        this.baseTheme.popupChaseDefFallColor = '#2BA66F'
        /**
         *indexWareHouse => pcuserware.scss
         */
        this.baseTheme.pcUserWareBgc = `transparent linear-gradient(180deg
    , #1E2E48 0%, #0B1021 100%) 0% 0% no-repeat padding-box`
        this.baseTheme.pcUserWareBeforeBgc =
          '#D6D6D6 0% 0% no-repeat padding-box'
        this.baseTheme.pcUserWareItemColor = '#879AAF'
        this.baseTheme.pcUserWareItemHoverColor = '#1B2A43'
        this.baseTheme.pcUserWareItemFinishedColor = '#879AAF'
        this.baseTheme.pcUserWareItemFinishedBgc = '#1E2E48'
        this.baseTheme.pcUserWareItemVanTabsColor = '#879AAF'
        this.baseTheme.pcUserWareItemVanTabsActiveColor = '#FAFAFA'
        /**
         *layout
         */
        this.baseTheme.layoutHeaderWrapBgc = 'transparent'
        this.baseTheme.layoutLoginTitleColor = '#FAFAFA'
        this.baseTheme.layoutIveSelectBgc = '#1E2E48'
        this.baseTheme.layoutIveSelectDropdownBgc = '#1E2E48'
        this.baseTheme.layoutIveSelectItemFocusColor = '#0589D7'
        this.baseTheme.layoutIveSelectItemFocusBgc = '#1B2A43'
        this.baseTheme.layoutIveSelectItemHoverColor = '#0589D7'
        this.baseTheme.layoutIveSelectItemHoverBgc = '#1B2A43'
        this.baseTheme.layoutSelectBtnsBgc = '#17253D'
        this.baseTheme.layoutSelectBtnsBtnColor = '#879AAF'
        this.baseTheme.layoutSelectBtnsBtnActiveColor = '#0589D7'
        this.baseTheme.layoutSelectBtnsBtnActiveBgc = '#1E2E48'
        this.baseTheme.layoutSelectBtnsDaysColor = '#879AAF'
        this.baseTheme.layoutSelectBtnsDropdownTitleColor = '#879AAF'
        this.baseTheme.layoutSelectBtnsDropdownActiveTitleColor = '#0589D7'
        this.baseTheme.layoutSelectBtnsEllipsisColor = '#FAFAFA'
        this.baseTheme.layoutSelectBtnsDropdownOpenColor = '#0589D7'
        this.baseTheme.layoutSelectBtnsDropdownOpenTitleColor = '#0589D7'
        this.baseTheme.layoutSelectBtnsVanCellColor = '#879AAF'
        this.baseTheme.layoutSelectBtnsVanCellBgc = '#1E2E48'
        /**
         *notify
         */
        this.baseTheme.notifyWrapColor = '#FAFAFA'
        this.baseTheme.notifyWrapNoticeColor = '#0589D7'
        this.baseTheme.notifyWrapNoticeShadow = '0 8px 15px #0003'
        this.baseTheme.notifyWrapErrorColor = '#E73744'
        this.baseTheme.notifyWrapErrorShadow = '0 8px 15px #0003'
        /**
         *personal => header.vue
         */
        this.baseTheme.headerMenuBgc = '#071121'
        this.baseTheme.headerMenuBeforeBgc = '#253C5A'
        this.baseTheme.headerTitleColor = '#FAFAFA'
        this.baseTheme.headerSubTitleColor = '#CCC'
        this.baseTheme.headerCardTitleColor = '#879AAF'
        this.baseTheme.headerCardBtnColor = '#0589D7'
        this.baseTheme.headerCardNameColor = '#FAFAFA'
        /**
         *Popup
         */
        this.baseTheme.PopupBgc = '#1E2E48'
        this.baseTheme.PopupShadow = '0 8px 25px #0006'
        this.baseTheme.PopupHeaderColor = '#FAFAFA'
        this.baseTheme.PopupHeaderUnFocusColor = '#879AAF'
        this.baseTheme.PopupHeaderBgc = '#17253D'
        /**
         *safeVerify
         */
        this.baseTheme.safeVerifyBgc = '#CCC'
        this.baseTheme.safeVerifyTitleColor = '#879AAF'
        this.baseTheme.safeVerifyBorder = '1px solid #253C5A'
        /**
         *stock => indexRevolving.vue
         */
        this.baseTheme.indexRevolvingItemColor = '#879AAF'
        this.baseTheme.indexRevolvingUpColor = '#E1533B'
        this.baseTheme.indexRevolvingDownColor = '#2BA66F'
        this.baseTheme.indexRevolvingCardBottomColor = '#87AF8E'
        this.baseTheme.indexRevolvingStockTitleColor = '#FAFAFA'
        this.baseTheme.indexRevolvingStockOrderColor = '#879AAF'
        this.baseTheme.indexRevolvingStockTimeColor = '#FAFAFA'
        this.baseTheme.indexRevolvingOpenNumberUpColor = '#E1533B'
        this.baseTheme.indexRevolvingOpenNumberDownColor = '#2BA66F'
        /**
         *Home.vue
         */
        this.baseTheme.homeBgc = 'transparent'
        this.baseTheme.homeTabBgc = 'rgba(7, 17, 33, .2)'
        this.baseTheme.homeTabHoverColor = '#0589D7'
        this.baseTheme.homeVanTabColor = '#879AAF'
        this.baseTheme.homeTabWrapBgc = '#1E2E48'
        this.baseTheme.homeTabsNavCardColor = '#0472B3'
        this.baseTheme.homeTabsNavCardBgc = '#1E2E48'
        this.baseTheme.homeSideCloseBtnBgc =
          'transparent linear-gradient(180deg, #1D2D46 0%, #101E35 100%) 0% 0% no-repeat padding-box'
        this.baseTheme.homeSideCloseSwitchBtnBgc = '#17253D'
        this.baseTheme.homeCloseIconBgc = '#17253D'
        this.baseTheme.homeXlBorder = '6px solid #162236'
        this.baseTheme.homeTabsGroupColor = '#879AAF'
        this.baseTheme.homeActiveColor = '#FAFAFA'
        this.baseTheme.homeActiveBgc = 'transparent'
        this.baseTheme.homeScrollBarColor = '#31445E transparent'
        this.baseTheme.homeScrollBarShadow = 'inset 0 0 6px rgba(0, 0, 0, 0)'
        this.baseTheme.homeScrollBarBgc = '#31445E'
        this.baseTheme.homeBgcTransparent = 'rgba(7, 17, 33, .35)'
        this.baseTheme.homeBgcBeforeTransparent =
          '#D6D6D6 0% 0% no-repeat padding-box'
        this.baseTheme.homeBgcMixBlendMode = 'multiply'
        this.baseTheme.homeEmptyColor = '#879AAF'
        this.baseTheme.homeThemeAfterBgc = '#253C5A'
        this.baseTheme.homeThemeIconActiveBgc = '#20385A'
        this.baseTheme.homeCloseBtnHoverBgc = '#1E2E48 0% 0% no-repeat padding-box'
        /**
         *BasePanel.vue
         */
        this.baseTheme.basePanelBgc = '#071121'
        this.baseTheme.basePanelColor = '#879AAF'
        this.baseTheme.basePanelHoverColor = '#0589D7'
        this.baseTheme.basePanelActiveColor = '#FAFAFA'
        /**
         *BaseTooltip.vue
         */
        this.baseTheme.baseTooltipColor = '#879AAF'
        this.baseTheme.baseTooltipBgc = '#17253D'
        this.baseTheme.baseTooltipBorderColor = '#253C5A'
        this.baseTheme.baseTooltipShadow = '0 1px 2px #00000026'
        /**
         *MemberCenter.vue
         */
        this.baseTheme.memberCenterBarBgc = '#FAFAFA'
        this.baseTheme.memberCenterBtnColor = '#879AAF'
        this.baseTheme.memberCenterBtnHoverColor = '#0589D7'
        this.baseTheme.memberCenterBtnActiveColor = '#FAFAFA'
        this.baseTheme.memberCenterBgcMix = '#071121'
        this.baseTheme.memberCenterListBorder = '1px solid #1E2E48'
        this.baseTheme.memberCenterTitleColor = '#879AAF'
        this.baseTheme.memberCenterTextColor = '#FAFAFA'
        this.baseTheme.memberCenterSpanActiveColor = '#FAFAFA'
        this.baseTheme.memberCenterDisableColor = '#879AAF'
        this.baseTheme.memberCenterDateBorder = ' 1px solid #879AAF'
        this.baseTheme.memberCenterPickerBgc = '#17253D'
        this.baseTheme.memberCenterPickerBorder = '1px solid #253C5A'
        this.baseTheme.memberCenterPickerShadow = '0 8px 15px #0003'
        this.baseTheme.memberCenterPickerPrimary = '#F0F'
        this.baseTheme.memberCenterRadioBorder = '1px solid #FFF'
        this.baseTheme.memberCenterRadioBgc = '#0589D7'
        this.baseTheme.memberCenterRadioCheckedBorder = '#0589D7'
        this.baseTheme.memberCenterMenuTitleColor = '#375E86'
        /**
         *AssetsMange.vue
         */
        this.baseTheme.assetsManageBtnColor = '#879AAF'
        this.baseTheme.assetsManageBtnActiveColor = '#0589D7'
        this.baseTheme.assetsManageBtnLineColor = '#1E2E48'
        this.baseTheme.assetsManageFontColor = '#879AAF'
        this.baseTheme.assetsManageLightColor = '#FAFAFA'
        this.baseTheme.assetsManagePaginationColor = '#879AAF'
        this.baseTheme.assetsManageMenuHoverBgc =
          'rgba(30,46,72,.6)'
        /**
         *step
         */
        this.baseTheme.stepBtnBgc = '#0589D7'
        this.baseTheme.stepWrapBorder = '1px solid #879AAF'
        this.baseTheme.stepWrapActiveBorder = '1px solid #0589D7'
        this.baseTheme.stepWrapErrorBorder = ' 1px solid #E73744'
        this.baseTheme.stepInputColor = '#FAFAFA'
        this.baseTheme.stepInputPlaceholder = '#879AAF'
        /**
         *step-2
         */
        this.baseTheme.step2BackBtnColor = '#879AAF'
        this.baseTheme.step2BackBtnBorder = '1px solid #879AAF'
        this.baseTheme.step2NextBtnColor = '#FAFAFA'
        this.baseTheme.step2NextBtnBorder = ' #0589D7'
        this.baseTheme.step2CheckWrapBorder = '1px solid #879AAF'
        this.baseTheme.step2CheckWrapHoverText = '#FAFAFA'
        this.baseTheme.step2CheckWrapText = '#879AAF'
        this.baseTheme.step2SelectBorder = '1px solid #0589D7'
        this.baseTheme.step2SelectBgc = '#0589D714'
        this.baseTheme.step2SelectText = '#FAFAFA'
        /**
         *step-3
         */
        this.baseTheme.step3TitleColor = '#879AAF'
        this.baseTheme.step3PassWordFocusBorder = '1px solid #0589D7'
        this.baseTheme.step3PassWordBgc = '#879AAF26'
        this.baseTheme.step3PassWordDomI = '#FFF'
        this.baseTheme.step3QaListItemColor = '#879AAF'
        this.baseTheme.step3QaInputColor = '#879AAF'
        this.baseTheme.step3QaInputBorder = '1px solid #879AAF'
        this.baseTheme.step3QaInputPlaceholder = '#879AAF'
        this.baseTheme.step3PassWordFocusColor = '#0589D7'
        /**
         *Forget
         */
        this.baseTheme.ForgetShowStepWrapTitleColor = '#FAFAFA'
        this.baseTheme.ForgetHaveAccountWrapText = '#879AAF'
        this.baseTheme.ForgetHaveAccountWrapLink = '#0589D7'
        this.baseTheme.ForgetStepLineBgc = '#879AAF'
        this.baseTheme.ForgetStepLineNowBgc = '#1E2E48'
        this.baseTheme.ForgetNowStepColor = '#0589D7'
        this.baseTheme.ForgetNowStepNumberBgc = '#101C30'
        this.baseTheme.ForgetNowStepNumberBorder = '1px solid #0589D7'
        this.baseTheme.ForgetNowStepCheckOkColor = '#FAFAFA'
        this.baseTheme.ForgetNowStepCheckOkBgc = '#0589D7'
        this.baseTheme.ForgetNumberBgc = '#101C30'
        this.baseTheme.ForgetNumberBgcBefore = '#1E2E48'
        /**
         *FromLogin
         */
        this.baseTheme.fromLoginTitleColor = '#FAFAFA'
        this.baseTheme.fromLoginRegisterTextColor = '#879AAF'
        this.baseTheme.fromLoginRegisterGotoColor = '#0589D7'
        this.baseTheme.fromLoginBtnBgc = '#0589D7'
        this.baseTheme.fromLoginGotoColor = '#0589D7'
        this.baseTheme.fromLoginUrlTitleColor = '#879AAF'
        this.baseTheme.fromLoginUrlTitleBorder = '1px solid #879AAF'
        this.baseTheme.fromLoginInputWrapBorder = '1px solid #879AAF'
        this.baseTheme.fromLoginInputWrapActiveBorder = '1px solid #0589D7'
        this.baseTheme.fromLoginInputWrapHoverBorder = '1px solid #0589D7'
        this.baseTheme.fromLoginInputWrapErrorBorder = '1px solid #E73744'
        this.baseTheme.fromLoginInputPlaceholder = '#879AAF'
        this.baseTheme.fromLoginItemContentBgc = '#17253D'
        this.baseTheme.fromLoginItemContentBorder = '1px solid #253C5A'
        /**
         *inputerror
         */
        this.baseTheme.inputErrorColor = '#E73744'
        /**
         *Loginscss
         */
        this.baseTheme.loginScssBgc = '#1E2E48'
        this.baseTheme.loginScssInputBgc = '#101C30'
        this.baseTheme.loginScssLoadingLogo = '#1E2E48'
        this.baseTheme.loginScssShadow = '0px 15px 30px #00000033'
        /**
         *Loginslider
         */
        this.baseTheme.LoginsliderSwiperPaginationBgc =
          'rgba(255, 255, 255, 0.1)'
        this.baseTheme.LoginsliderSwiperPaginationActiveBgc = '#FFF'
        this.baseTheme.loginsliderTitleColor = '#FAFAFA'
        this.baseTheme.loginsliderDescribeColor = '#879AAF'
        this.baseTheme.loginsliderNumberShowColor = '#0589D7'
        this.baseTheme.loginsliderNumberTitleColor = '#FAFAFA'
        this.baseTheme.loginsliderNumberDescribeColor = '#879AAF'
        /**
         *Resgister
         */
        this.baseTheme.registerTitleColor = '#FAFAFA'
        this.baseTheme.registerInfoColor = '#879AAF'
        this.baseTheme.registerGotoTextColor = '#879AAF'
        this.baseTheme.registerGotoLinkColor = '#0589D7'
        this.baseTheme.registerBtnBgc = '#0589D7'
        this.baseTheme.registerWrapSetBorder = '1px solid #879AAF'
        this.baseTheme.registerWrapSetInputColor = '#FAFAFA'
        this.baseTheme.registerWrapSetPlaceHolderColor = '#879AAF'
        this.baseTheme.registerWrapSetInputHover = '1px solid #0589D7'
        this.baseTheme.registerWrapSetInputActive = '1px solid #0589D7'
        this.baseTheme.registerWrapSetInputError = '1px solid #E73744'
        /**
         *app
         */
        this.baseTheme.appContentViewBgc = '#1E2E48'
        this.baseTheme.appVanDialogBgc = '#101E35'
        this.baseTheme.appVanAlertDialogBgc = '#17253D'
        this.baseTheme.appVanAlertHeaderColor = '#FAFAFA'
        this.baseTheme.appVanDialogShadow = '0 8px 25px #0006'
        this.baseTheme.appPcNoticeColor = '#879AAF'
        this.baseTheme.appPcNoticeHasTitleColor = '#879AAF'
        this.baseTheme.appPcNoticeCancelColor = '#879AA'
        this.baseTheme.appPcNoticeHeaderColor = '#FAFAFA'
        this.baseTheme.appPcNoticeHeaderAfterBorder = '3px solid #2BA66F'
        this.baseTheme.appErrorAlertColor = '#879AAF'
        this.baseTheme.appErrorAlertTitleColor = '#879AAF'
        this.baseTheme.appErrorAlertHeaderColor = '#FAFAFA'
        this.baseTheme.appErrorAlertHeaderAfterBorder = '3px solid #E73744'
        this.baseTheme.appDialogHeaderColor = '#879AAF'
        this.baseTheme.appDialogFooterBorder = '1px solid #253C5A'
        this.baseTheme.appDialogConfirmColor = '#FAFAFA'
        this.baseTheme.appDialogConfirmBgc = '#0589D7'
        this.baseTheme.appDialogCancelColor = '#879AAF'
        this.baseTheme.appDialogCancelBorder = '1px solid #879AAF'
        this.baseTheme.appVpickerBgc = '#17253D'
        this.baseTheme.appVDataBeforeBorder = '1px solid #253C5A'
        this.baseTheme.appScrollTrackShadow = 'inset 0 0 6px rgba(0, 0, 0, 0)'
        this.baseTheme.appScrollThumb = '#31445E'
        this.baseTheme.appVDatePickerColor = '#879AAF'
        this.baseTheme.appVDatePickerActiveColor = '#FAFAFA'
        this.baseTheme.appDatePickerTableColor = '#879AAF'
        /**
         * public
         */
        this.baseTheme.confirmBtnBgc = '#0589D7'
        this.baseTheme.confirmBtnColor = '#FAFAFA'
        this.baseTheme.confirmBtnHoverBgc = '#45A2D9'
        this.baseTheme.cancelBtnColor = '#879AAF'
        this.baseTheme.cancelBtnHoverBgc = '#222F43'
        /**
         * Navbar
         */
        this.baseTheme.layoutRouterHide = '#879AAF'
        this.baseTheme.layoutRouterHover = '#0589D7'
        /**
         * dropdownqrcode
         */
        this.baseTheme.dropdownVancellColor = '#FAFAFA'
        this.baseTheme.dropdownContentBgc = '#1B2A43'
        this.baseTheme.dropdownBorder = '1px solid #1D324B'
        this.baseTheme.dropdownTitleBorder = '1px solid #1D324B'
        this.baseTheme.dropdownInfoColor = '#879AAF'
        this.baseTheme.dropdownItemColor = '#FAFAFA'
        this.baseTheme.dropdownHoverItemColor = '#253C5A'
        this.baseTheme.dropdownMainTextColor = '#FAFAFA'
        this.baseTheme.dropdownMainQrcodeBorder = '1px solid #707070'
        this.baseTheme.dropdownBoxShadow = '0 8px 25px #0006'
        this.baseTheme.dropdownRankLevelColor = '#0589D7'
        this.baseTheme.dropdownTypeBgc = 'rgba(37, 60, 90, 0) 0% 0% no-repeat padding-box'
        /**
        * bankMange
        */
        this.baseTheme.bankMangeSpanColor = '#FAFAFA'
        this.baseTheme.bankMangeTitleColor = '#375E86'
        /**
        * announcements
        */
        this.baseTheme.announcementSwiperColor = '#879AAF'
        this.baseTheme.announcementBtnsColor = '#879AAF'
        this.baseTheme.announcementTitleColor = '#FAFAFA'
        /**
        * ClobalLoading
        */
        this.baseTheme.ClobalLoadingBox1Bgc = '#0589D7 0% 0% no-repeat padding-box'
        this.baseTheme.ClobalLoadingBox2Bgc = '#253C5A 0% 0% no-repeat padding-box'
        this.baseTheme.ClobalLoadingBox3Bgc = '#253C5A 0% 0% no-repeat padding-box'
        this.baseTheme.ClobalLoadingBox4Bgc = '#0589D7 0% 0% no-repeat padding-box'
        /**
        * Maintain
        */
        this.baseTheme.MaintainBgc = '#0B1021'
        this.baseTheme.MaintainTitleColor = '#FAFAFA'
        this.baseTheme.MaintainInfoColor = '#879AAF'
        this.baseTheme.MaintainBtnColor = '#879AAF'
        this.baseTheme.MaintainBtnBorder = '1px solid #879AAF'
        this.baseTheme.MaintainTimeShow = '#0589D7'
        /**
        * Navigation
        */
        this.baseTheme.navigationCardWrapBgc = '#1E2E48'
        this.baseTheme.navigationCardWrapBeforeBgc = '#17253D'
        this.baseTheme.navigationWrapBgc = '#1E2E48'
        this.baseTheme.navigationWrapBorder = '#17253D'
        this.baseTheme.navigationSlideBtnBgc = '17253D'
        this.baseTheme.navigationSlideOutBgc = '#17253D'
        /**
        * RechargeComponents
        */
        this.baseTheme.rechargeInputWrap = '#879AAF'
        this.baseTheme.rechargeInputWrapBorder = '1px solid #879AAF'
        this.baseTheme.rechargeInputWrapHoverBorder = '1px solid #0589D7'
        this.baseTheme.rechargeInputWrapFocusBorder = '1px solid #0589D7'
        this.baseTheme.rechargeInputWrapErrorBorder = '1px solid #E73744'
        this.baseTheme.rechargeInputColor = '#FAFAFA'
        this.baseTheme.rechargeInputPlaceholderColor = '#879AAF'
        this.baseTheme.rechargeInputErrorColor = '#E73744'
        this.baseTheme.rechargeItemContentBgc = '#17253D'
        this.baseTheme.rechargeItemContentBorder = '1px solid #253C5A'
        this.baseTheme.rechargeItemColor = '#879AAF'
        this.baseTheme.rechargeItemSelectActiveColor = '#FAFAFA'
        this.baseTheme.rechargeItemSelectActiveBgc = '#1B2A43'
        this.baseTheme.rechargeItemHoverColor = '#FAFAFA'
        this.baseTheme.rechargeItemHoverBgc = '#1B2A43'
        /**
        * RechargePage
        */
        this.baseTheme.rechargePageNavAfter = '#071121'
        this.baseTheme.rechargePageSpanColor = '#FAFAFA'
        this.baseTheme.rechargePageMenuTitle = '#375E86'
        this.baseTheme.rechargeWalletAfterBgc = '#1E2E48'
        this.baseTheme.rechargeVanTabColor = '#879AAF'
        this.baseTheme.rechargeVanTabHoverColor = '#0589D7'
        this.baseTheme.rechargeVanTabActiveColor = '#FAFAFA'
        this.baseTheme.rechargeVanTabLingBgc = '#FAFAFA'
        /** ============ */
        break
    }
  },
  computed: {
    styleVar () {
      switch (this.$store.getters.userInfo.theme) {
        case 'classic':
          /*
           dP""b8 88        db     dP""b8  dP""b8 88  dP""b8
          dP   `" 88       dPYb   dP   `" dP   `" 88 dP   `"
          Yb      88  .o  dP__Yb  Yb      Yb      88 Yb
           YboodP 88ood8 dP""""Yb  YboodP  YboodP 88  YboodP
          */
          return {
            '--checkUse': '#F00',
            '--fontBase': '14px',
            /**
             *app
             */
            '--contentBgc': 'transparent',
            '--scrollBarTrackBgc': '#162236',
            '--fireFoxScrollBar': '#31445E',
            '--fireScrollBarTrackShadow': 'inset 0 0 6px rgba(0, 0, 0, 0)',
            '--fireScrollbarThumbBgc': '#31445E',
            '--vantDialogBgc': '#162236',
            '--vantButtonDefaultBgc': '#162236',
            '--vantDialogCancel': '#0589D7',
            '--menuRise': '#E1533B',
            '--menufall': '#2BA66F',
            '--dropDownShadow': '0 8px 25px #0006',
            '--vApplicationBgc':
              'transparent linear-gradient(180deg, #1E2E48 0%, #101E35 100%) 0% 0% no-repeat padding-box',
            /**
             * Graph.vue
             */
            '--graphBgc': 'transparent',
            /**
             * bankMange.vue
             */
            '--bankMangeBgc': '#CCC',
            '--bankMangeColor': '#879AAF',
            '--bankMangeTitleBorderColor': '1px solid #253C5A',
            /**
              * base系列
              */
            '--baseBtnColor': '#879AAF',
            '--baseBtnHoverColor': '#879AAF26',
            '--baseBtnSuccessColor': '#FAFAFA',
            '--baseBtnSuccessBgc': '#0589D7',
            '--baseBtnSuccessHoverBgc': '#45A2D9',
            '--baseBtnBorder': '1px solid #879AAF',
            '--baseInputColor': '#FAFAFA',
            '--baseInputFocusColor': '#0589D7',
            '--baseInputBorderColor': '1px solid #879AAF',
            /**
             * mainText.vue
             */
            '--tradeMainTextProgressColor': '#0589D7',
            '--tradeMainTextGetColorMoreZero': '#E1533B',
            '--tradeMainTextGetColorSLessZero': '#37C882',
            '--tradeMainTextGetColorNotEqualZero': '#253C5A',
            '--tradeMainTextGetColorEqualZero': '#656D7A',
            '--tradeMainTextGetColorDef': '#253C5A',
            '--tradeMainTextOrderColor': '#879AAF',
            /**
             * tradeMain.vue
             */
            '--tradeMainTabLineBgc': '#FAFAFA',
            '--tradeMainTabsNavColor': '#879AAF',
            '--tradeMainTabsNavHoverColor': '#0589D7',
            '--tradeMainTabsNavBgc': 'transparent',
            '--tradeMainItemActive': '#1E2E48',
            '--tradeMainIconBgc': '#20314E',
            '--tradeMainTabsNavColor2': '#162236',
            '--tradeMainPcTitleColor': '#FAFAFA',
            '--tradeMainPcBorderBottom': '1px solid #1E2E48',
            /**
             * trade.vue
             */
            '--tradeNavBgc': '#17253D',
            '--tradeNavMenuItemColor': '#7D91A5',
            '--tradeNavMenuItemIsActFFF': '#0472B3',
            '--tradeListTitle': '#879AAF',
            '--tradeListItemBgc': '#1E2E48',
            '--tradeListItemBgcHover': '#1B2A43',
            '--tradeListItemBgcActive': '#1B2A43',
            '--tradeListHeaderColor': '#FFF',
            '--tradeListItemContentColor': '#879AAF',
            '--tradeListItemUpColor': '#E1533B',
            '--tradeListItemDownColor': '#37C882',
            '--tradeVtabsBgc': '#1B2A43',
            '--tradeVtabsActive': '#0472B3',
            '--tradeVtabsUrl': "url('/static/images/trade/arrow-left.svg')",
            /**
             *trendRank.vue
             */
            '--trendRankHeaderColor': '#FAFAFA',
            '--trendRankHeaderBorder': '1px solid #1E2E48',
            '--trendRankTableColor': '#879AAF',
            '--trendRankTrendInfoColor': '#879AAF',
            '--trendRankTrendInfoHoverBgc': '#1B2A43',
            '--trendRankUpColor': '#E1533B',
            '--trendRankDownColor': '#2BA66F',
            '--trendRankNameColor': '#FAFAFA',
            '--trendRankTdFirstChild': '#FAFAFA',
            '--trendRankTdNth2Child': '#879AAF',
            '--trendRankTdUp': '#E1533B',
            '--trendRankTdDown': '#2BA66F',
            '--trendRankSlideBgc': '#17253D',
            '--trendRankUseIdColor': '#FFF',
            '--trendRankUserNameColor': '#879AAF',
            '--trendRankProfit': '#E1533B',
            '--trendRankBorderBgc': '#17253D',
            /**
             *Double.vue
             */
            '--doubleTitleColor': '#879AAF',
            '--doubleItemWrapperBorder': '1px solid #879AAF',
            '--doubleVanCellColor': '#7D91A5',
            '--doubleVanCellColor2': '#FAFAFA',
            '--doubleCollapseContentColor': '#879AAF',
            '--doubleCollapseContentBgc': '#17253D',
            '--doubleIconArrowUrl': "url('/static/images/bet/triangle.svg')",
            '--doubleBtnActiveColor': '#FAFAFA',
            '--doubleBtnActiveBgc': '#1B2A43',
            '--doubleOrderMoneyBorder': '1px solid #879AAF',
            '--doubleOrderMoneyActiveBorder': '1px solid #0589D7',
            '--doubleItemNameColor': '#879AAF',
            '--doubleItemValueColor': '#FAFAFA',
            '--doubleBoubleBtnColor': '#FAFAFA',
            '--doubleBoubleBtnBgc': '#0589D7',
            '--doubleBoubleBtnActiveBgc': '#45A2D9',
            '--doubleDropdownItemBorder': '1px solid #253C5A',
            '--doubleDropdownItemShadow': '0 8px 15px #0003',
            '--doubleDropdownItemActiveColor': '#FAFAFA',
            '--doubleDropdownItemActiveBgc': '#1B2A43',
            '--doubleDropdownItemTitleColor': '#FAFAFA',
            '--doubleDropdownItemTitleBgc': '#1B2A43',
            '--doubleDropdownItemTitleHoverColor': '#FAFAFA',
            '--doubleDropdownItemTitleHoverBgc': '#1B2A43',
            '--doubleDropdownVanCellColor': '#879AAF',
            '--doubleDropdownVanCelBgc': '#17253D',
            '--doubleUnitBgc': '#253C5A',
            '--doubleDropdownMenuColor': '#7D91A5',
            '--doubleDropdownMenuBorder':
              'transparent transparent #7D91A5 #7D91A5',
            '--doubleVanEllipsisColor': '#879AAF',
            '--doubleInputAreaVanCellBorder': '1px solid #879AAF',
            '--doubleInputAreaCollapseItemBorder': '1px solid #0589D7',
            '--doubleMaxIncome': '#253C5A',
            '--doubleVanCellColor3': '#879AAF',
            /**
             *indexBetTab.vue
             */
            '--indexBetTabTitleColor': '879AAF',
            '--indexBetTabTitleBorder': '1px solid #1E2E48',
            '--indexBetStockWrapQaColor': '#879AAF',
            '--indexBetTabNavColor': '#17253D',
            '--indexBetVanTabColor': '#879AAF',
            '--indexBetVanTabActiveColor': '#FAFAFA',
            '--indexBetVanTabActiveBgc': '#0589D7',
            '--indexBetSwiperBtnBgc': '#1B2A43',
            '--indexBetHoverBtnColor': '#0589D7',
            '--indexBetActiveColor': '#FAFAFA',
            '--indexBetBtnColor': '#879AAF',
            '--indexBetMoveLineBgc': '#0589D7',
            /**
             *locations.vue
             */
            '--locationInputSetColor': '#FAFAFA',
            '--locationTitleColor': '#879AAF',
            '--locationWrapperBorder': '1px solid #253C5A',
            '--locationVanCellColor': '#7D91A5',
            '--locationIsShowVanCellColor': '#FAFAFA',
            '--locationItemContentColor': '#879AAF',
            '--locationItemContentBgc': '#17253D',
            '--locationItemIconArrowUrl':
              "url('/static/images/bet/triangle.svg')",
            '--locationItemIconArrowUrlActive': "url('/static/images/bet/triangle-active.svg')",
            '--locationItemActiveColor': '#0589D7',
            '--locationItemTitleExpandedBorder': '1px solid #0589D7',
            '--locationItemBtnActiveColor': '#FAFAFA',
            '--locationItemBtnActiveBgc': '#1B2A43',
            '--locationItemOrderMoneyBorder': '1px solid #879AAF',
            '--locationItemOrderMoneyActiveBorder': '1px solid #0589D7',
            '--locationShowInfoItemNameColor': '#879AAF',
            '--locationShowInfoItemValueColor': '#FAFAFA',
            '--locationBoubleBtnColor': '#FAFAFA',
            '--locationDropdownItemBorder': '1px solid #253C5A',
            '--locationDropdownItemShadow': '0 8px 15px #0003',
            '--locationDropdownItemActiveColor': '#FAFAFA',
            '--locationDropdownItemActiveBgc': '#1B2A43',
            '--locationCellTitleHoverColor': '#FAFAFA',
            '--locationCellTitleHoverBgc': '#1B2A43',
            '--locationUnitVanCellColor': '#879AAF',
            '--locationUnitVanCellBgc': '#17253D',
            '--locationUnitBgc': '#253C5A',
            '--locationUnitDropdownTitleActiveColor': '#0589D7',
            '--locationUnitDropdownEllipsisColor': '#879AAF',
            '--locationChaseVanCellColor': '#879AAF',
            '--locationChaseSwitchColor': '#31445E',
            '--locationChaseOnSwitchColor': '#0589D7',
            '--locationInputWrapBorder': '1px solid #879AAF',
            '--locationInputWrapBgc': '#253C5A',
            '--locationInputInputBgc': '#17253D',
            '--locationSelectInputBorder': '1px solid #879AAF',
            '--locationSelectInputActiveBorder': '1px solid #0589D7',
            '--locationSelectRemarkerColor': '#879AAF',
            '--locationPlayModeBgc': '#17253D',
            '--locationPlayModeBarOpenBorder': '1px solid #0589D7',
            '--locationPlayModeVanPopupBgc': '#17253D',
            '--locationPlayModeTitleColor': '#879AAF',
            '--locationBetInputAreaVanCellBorder': '1px solid #879AAF',
            '--locationBetInputAreaTitleExpandedBorder': '1px solid #0589D7',
            '--locationBetWrapBtnBgc': '#1B2A43',
            '--locationBoubleTitleColor': '#879AAF',
            '--locationBoubleTitleBgc': '#1B2A43',
            '--locationBoubleTitleActiveColor': '#0589D7',
            '--locationBoubleTitleActiveBorder': '1px solid #0589D7',
            '--locationPlayTypeSpanColor': '#879AAF',
            '--locationPlayTypeSpanBgc': '#1B2A43',
            '--locationPlayTypeSpanActiveColor': '#0589D7',
            '--locationPlayTypeSpanActiveBgc': '#132C4C',
            '--locationPlayTypeSpanActiveBorder': '0 0 0 1px #0589D7',
            '--locationDobleBorder': '1px solid #879AAF',
            '--locationDobleActiveBorder': '1px solid #0589D7',
            '--locationDobleInputColor': '#FAFAFA',
            '--locationUnitWrapColor': '#879AAF',
            '--locationUnitWrapBgc': '#253C5A',
            '--locationVanCellColor2': '#879AAF',
            '--locationItemWrapperBgc': '#17253D',
            '--locationItemWrapperBorder': '1px solid #253C5A',
            '--locationItemDivColor': '#879AAF',
            '--locationItemHoverDivColor': '#FAFAFA',
            '--locationItemHoverDivBgc': '#1B2A43',
            '--locationMaxIncomeColor': '#253C5A',
            '--locationBetShowBorder': '1px solid #1D324B',
            /**
             *postion.vue
             */
            '--postionTabActiveColor': '#0589D7',
            '--postionTabLineColor': '#0589D7',
            '--postionTabNavBgc': '#1B2A43',
            /**
             *indexWareHouse => chaseNumberBtns.vue
             */
            '--chaseNumberBtnsBtnsBgc': '#1E2E48',
            '--chaseNumberBtnsBtnColor': '#879AAF',
            '--chaseNumberBtnsBtnActiveColor': '#0589D7',
            '--chaseNumberBtnsBtnActiveBgc': '#0589D7',
            '--chaseNumberBtnsDaysColor': '#879AAF',
            '--chaseNumberDropdownTitleColor': '#FAFAFA',
            '--chaseNumberDropdownTitleActiveColor': '#0589D7',
            '--chaseNumberDropdownItemContentBorder': '2px solid #253C5A',
            '--chaseNumberDropdownItemActiveColor': '#FAFAFA',
            '--chaseNumberDropdownItemActiveBgc': '#1B2A43',
            '--chaseNumberDropdownVanCellTitleColor': '#FAFAFA',
            '--chaseNumberDropdownVanCellColor': '#879AAF',
            '--chaseNumberDropdownVanCellBgc': '#17253D',
            /**
             *indexWareHouse => selectBtns.vue
             */
            '--selectBtnsContentBtnsBgc': '#1E2E48',
            '--selectBtnsBtnColor': '#879AAF',
            '--selectBtnsBtnActiveColor': '#0589D7',
            '--selectBtnsBtnActiveBgc': '#1E2E48',
            '--selectBtnsDaysColor': '#879AAF',
            '--selectBtnsDayDropdownTitleColor': '#879AAF',
            '--selectBtnsDayDropdownActiveTitleColor': '#0589D7',
            '--selectBtnsDayDropdownOptionColor': '#0589D7',
            '--selectBtnsDayDropdownVanCellTitleColor': '#0589D7',
            '--selectBtnsDayDropdownVanCellColor': '#879AAF',
            '--selectBtnsDayDropdownVanCellBgc': '#17253D',
            /**
             *indexWareHouse => list=> Title => AccountTitle.vue
             */
            '--accountingTitleColor': '#879AAF',
            /**
            /**
             *indexWareHouse => list=>
             */
            '--accountingItemBonesRiseColor': '#E1533B',
            '--accountingItemPendingColor': '#2BA66F',
            '--accountingItemIngColor': '#2BA66F',
            '--accountingItemRiseColor': '#FAFAFA',
            '--accountingItemItemStockColor': '#FAFAFA',
            /**
            /**
            *indexWareHouse => list=>ChaseNumber
            */
            '--accountingItemChaseRiseColor': '#E1533B',
            '--accountingItemChaseIngColor': '#0589D7',
            '--accountingItemChaseColor': '#FAFAFA',
            /**
             *indexWareHouse => popup
             */
            '--popupTitleColor': '#FAFAFA',
            '--popupNameColor': '#879AAF',
            '--popupValueColor': '#FAFAFA',
            '--popupValuePendingColor': '#879AAF',
            '--popupValueRiseColor': '#E1533B',
            '--popupValueRecordCodeRiseColor': '#E1533B',
            '--popupValueRecordCodePendingColor': '#2BA66F',
            '--popupValueRecordCodeFallColor': '#879AAF',
            '--popupRevokeBtnColor': '#879AAF',
            '--popupRevokeBtnBorder': '1px solid #879AAF',
            '--popupRevokeBtnRevokeColor': '#FAFAFA',
            '--popupRevokeBtnRevokeBgc': '#E73744',
            '--popupRevokeBtnRevokeActiveBgc': '#F95D68',
            /**
             *indexWareHouse => popup => Chase.vue
             */
            '--popupChaseRevokeBtnCancelColor': '#879AAF',
            '--popupChaseRevokeBtnCancelBorder': '1px solid #879AAF',
            '--popupChaseRevokeBtnRevokeColor': '#FAFAFA',
            '--popupChaseRevokeBtnRevokeBgc': '#E73744',
            '--popupChaseCollapseVanCellColor': '#879AAF',
            '--popupChaseCollapseVanItemContentColor': '#879AAF',
            '--popupChaseCollapseVanTitleContentBgc': '#233C5A',
            '--popupChaseStepInfoColor': '#FAFAFA',
            '--popupChaseStepInfoShowBgc': '#233C5A',
            '--popupChaseChaseStepBgc': '#1B2A43',
            '--popupChaseIssueColor': '#0589D7',
            '--popupChaseHaveValueColor': '#FAFAFA',
            '--popupChaseStatePendingColor': '#2BA66F',
            '--popupChaseStateFallColor': '#879AAF',
            '--popupChaseStateRiseColor': '#E1533B',
            '--popupChaseStateIngColor': '#0589D7',
            '--popupChaseSendBonesColor': '#E1533B',
            '--popupChaseRadioItemTitleColor': '#879AAF',
            '--popupChaseRadioItemVanSwitchBgc': '#31445E',
            '--popupChaseRadioItemVanOnSwitchBgc': '#034072',
            '--popupCancelBgc': '',
            '--popupCancelActiveBgc': '#28374E',
            '--popupRadioBorder': '1px solid #253C5A',
            /**
             *indexWareHouse => popup => ChaseChildItem.vue
             */
            '--popupChaseChildItemHoverBgc': '1E2E48',
            '--popupChaseChildItemDivColor': '#879AAF',
            '--popupChaseChildItemOrderColor': '#0589D7',
            '--popupChaseChildItemOrderIngColor': '#879AAF',
            '--popupChaseChildItemBonesColor': '#FAFAFA',
            '--popupChaseChildItemWaitColor': '#2BA66F',
            '--popupChaseChildItemProfitableColor': '#E1533B',
            /**
             *indexWareHouse => def.vue
             */
            '--popupChaseDefRiseColor': '#E1533B',
            '--popupChaseDefFallColor': '#2BA66F',
            /**
             *indexWareHouse => pcuserware.scss
             */
            '--pcUserWareBgc':
              'transparent linear-gradient(180deg , #1E2E48 0%, #0B1021 100%) 0% 0% no-repeat padding-box',
            '--pcUserWareBeforeBgc': '#D6D6D6 0% 0% no-repeat padding-box',
            '--pcUserWareItemColor': '#879AAF',
            '--pcUserWareItemHoverColor': '#1B2A43',
            '--pcUserWareItemFinishedColor': '#879AAF',
            '--pcUserWareItemFinishedBgc': '#1E2E48',
            '--pcUserWareItemVanTabsColor': '#879AAF',
            '--pcUserWareItemVanTabsActiveColor': '#FAFAFA',
            /**
             *layout
             */
            '--layoutHeaderWrapBgc': 'transparent',
            '--layoutLoginTitleColor': '#FAFAFA',
            '--layoutIveSelectBgc': '#1E2E48',
            '--layoutIveSelectDropdownBgc': '#1E2E48',
            '--layoutIveSelectItemFocusColor': '#0589D7',
            '--layoutIveSelectItemFocusBgc': '#1B2A43',
            '--layoutIveSelectItemHoverColor': '#0589D7',
            '--layoutIveSelectItemHoverBgc': '#1B2A43',
            '--layoutSelectBtnsBgc': '#17253D',
            '--layoutSelectBtnsBtnColor': '#879AAF',
            '--layoutSelectBtnsBtnActiveColor': '#0589D7',
            '--layoutSelectBtnsBtnActiveBgc': '#1E2E48',
            '--layoutSelectBtnsDaysColor': '#879AAF',
            '--layoutSelectBtnsDropdownTitleColor': '#879AAF',
            '--layoutSelectBtnsDropdownActiveTitleColor': '#0589D7',
            '--layoutSelectBtnsEllipsisColor': '#FAFAFA',
            '--layoutSelectBtnsDropdownOpenColor': '#0589D7',
            '--layoutSelectBtnsDropdownOpenTitleColor': '#0589D7',
            '--layoutSelectBtnsVanCellColor': '#879AAF',
            '--layoutSelectBtnsVanCellBgc': '#1E2E48',
            /**
             *notify
             */
            '--notifyWrapColor': '#FAFAFA',
            '--notifyWrapNoticeColor': '#0589D7',
            '--notifyWrapNoticeShadow': '0 8px 15px #0003',
            '--notifyWrapErrorColor': '#E73744',
            '--notifyWrapErrorShadow': '0 8px 15px #0003',
            /**
             *personal => header.vue
             */
            '--headerMenuBgc': '#071121',
            '--headerMenuBeforeBgc': '#253C5A',
            '--headerTitleColor': '#FAFAFA',
            '--headerSubTitleColor': '#CCC',
            '--headerCardTitleColor': '#879AAF',
            '--headerCardBtnColor': '#0589D7',
            '--headerCardNameColor': '#FAFAFA',
            /**
             *Popup
             */
            '--PopupBgc': '#1E2E48',
            '--PopupShadow': '0 8px 25px #0006',
            '--PopupHeaderColor': '#FAFAFA',
            '--PopupHeaderUnFocusColor': '#879AAF',
            '--PopupHeaderBgc': '#17253D',
            /**
             *safeVerify
             */
            '--safeVerifyBgc': '#CCC',
            '--safeVerifyTitleColor': '#879AAF',
            '--safeVerifyBorder': '1px solid #253C5A',
            /**
             *stock => indexRevolving.vue
             */
            '--indexRevolvingItemColor': '#879AAF',
            '--indexRevolvingUpColor': '#E1533B',
            '--indexRevolvingDownColor': '#2BA66F',
            '--indexRevolvingCardBottomColor': '#87AF8E',
            '--indexRevolvingStockTitleColor': '#FAFAFA',
            '--indexRevolvingStockOrderColor': '#879AAF',
            '--indexRevolvingStockTimeColor': '#FAFAFA',
            '--indexRevolvingOpenNumberUpColor': '#E1533B',
            '--indexRevolvingOpenNumberDownColor': '#2BA66F',
            /**
             *Home.vue
             */
            '--homeBgc': 'transparent',
            '--homeTabBgc': 'rgba(7, 17, 33, .2)',
            '--homeTabHoverColor': '#0589D7',
            '--homeVanTabColor': '#879AAF',
            '--homeTabWrapBgc': '#1E2E48',
            '--homeTabsNavCardColor': '#0472B3',
            '--homeTabsNavCardBgc': '#1E2E48',
            '--homeSideCloseBtnBgc':
              'transparent linear-gradient(180deg, #1D2D46 0%, #101E35 100%) 0% 0% no-repeat padding-box',
            '--homeSideCloseSwitchBtnBgc': '#17253D',
            '--homeCloseIconBgc': '#17253D',
            '--homeXlBorder': '6px solid #162236',
            '--homeTabsGroupColor': '#879AAF',
            '--homeActiveColor': '#FAFAFA',
            '--homeActiveBgc': 'transparent',
            '--homeScrollBarColor': '#31445E transparent',
            '--homeScrollBarShadow': 'inset 0 0 6px rgba(0, 0, 0, 0)',
            '--homeScrollBarBgc': '#31445E',
            '--homeBgcTransparent': 'rgba(7, 17, 33, .35)',
            '--homeBgcBeforeTransparent': '#D6D6D6 0% 0% no-repeat padding-box',
            '--homeBgcMixBlendMode': 'multiply',
            '--homeEmptyColor': '#879AAF',
            '--homeThemeAfterBgc': '#253C5A',
            '--homeThemeIconActiveBgc': '#20385A',
            '--homeCloseBtnHoverBgc': '#1E2E48 0% 0% no-repeat padding-box',
            /**
             *BasePanel.vue
             */
            '--basePanelBgc': '#071121',
            '--basePanelColor': '#879AAF',
            '--basePanelHoverColor': '#0589D7',
            '--basePanelActiveColor': '#FAFAFA',
            /**
             *BaseTooltip.vue
             */
            '--baseTooltipColor': '#879AAF',
            '--baseTooltipBgc': '#17253D',
            '--baseTooltipBorderColor': '#253C5A',
            '--baseTooltipShadow': '0 1px 2px #00000026',
            /**
             *MemberCenter.vue
             */
            '--memberCenterBarBgc': '#FAFAFA',
            '--memberCenterBtnColor': '#879AAF',
            '--memberCenterBtnHoverColor': '#0589D7',
            '--memberCenterBtnActiveColor': '#FAFAFA',
            '--memberCenterBgcMix': '#071121',
            '--memberCenterListBorder': '1px solid #1E2E48',
            '--memberCenterTitleColor': '#879AAF',
            '--memberCenterTextColor': '#FAFAFA',
            '--memberCenterSpanActiveColor': '#FAFAFA',
            '--memberCenterDisableColor': '#879AAF',
            '--memberCenterDateBorder': ' 1px solid #879AAF',
            '--memberCenterPickerBgc': '#17253D',
            '--memberCenterPickerBorder': '1px solid #253C5A',
            '--memberCenterPickerShadow': '0 8px 15px #0003',
            '--memberCenterPickerPrimary': '#F0F',
            '--memberCenterRadioBorder': '1px solid #FFF',
            '--memberCenterRadioBgc': '#0589D7',
            '--memberCenterRadioCheckedBorder': '#0589D7',
            '--memberCenterMenuTitleColor': '#375E86',
            /**
             *AssetsMange.vue
             */
            '--assetsManageBtnColor': '#879AAF',
            '--assetsManageBtnActiveColor': '#0589D7',
            '--assetsManageBtnLineColor': '#1E2E48',
            '--assetsManageFontColor': '#879AAF',
            '--assetsManageLightColor': '#FAFAFA',
            '--assetsManagePaginationColor': '#879AAF',
            '--assetsManageMenuHoverBgc': 'rgba(30,46,72,.6)',
            /**
             *step
             */
            '--stepBtnBgc': '#0589D7',
            '--stepWrapBorder': '1px solid #879AAF',
            '--stepWrapActiveBorder': '1px solid #0589D7',
            '--stepWrapErrorBorder': ' 1px solid #E73744',
            '--stepInputColor': '#FAFAFA',
            '--stepInputPlaceholder': '#879AAF',

            /**
             *step-2
             */
            '--step2BackBtnColor': '#879AAF',
            '--step2BackBtnBorder': '1px solid #879AAF',
            '--step2NextBtnColor': '#FAFAFA',
            '--step2NextBtnBorder': ' #0589D7',
            '--step2CheckWrapBorder': '1px solid #879AAF',
            '--step2CheckWrapHoverText': '#FAFAFA',
            '--step2CheckWrapText': '#879AAF',
            '--step2SelectBorder': '1px solid #0589D7',
            '--step2SelectBgc': '#0589D714',
            '--step2SelectText': '#FAFAFA',
            /**
             *step-3
             */
            '--step3TitleColor': '#879AAF',
            '--step3PassWordFocusBorder ': '1px solid #0589D7',
            '--step3PassWordBgc': '#879AAF26',
            '--step3PassWordDomI': '#FFF',
            '--step3QaListItemColor': '#879AAF',
            '--step3QaInputColor': '#879AAF',
            '--step3QaInputBorder': '1px solid #879AAF',
            '--step3QaInputPlaceholder': '#879AAF',
            '--step3PassWordFocusColor': '#0589D7',
            /**
             *Forget
             */
            '--ForgetShowStepWrapTitleColor': '#FAFAFA',
            '--ForgetHaveAccountWrapText': '#879AAF',
            '--ForgetHaveAccountWrapLink': '#0589D7',
            '--ForgetStepLineBgc': '#879AAF',
            '--ForgetStepLineNowBgc': '#1E2E48',
            '--ForgetNowStepColor': '#0589D7',
            '--ForgetNowStepNumberBgc': '#101C30',
            '--ForgetNowStepNumberBorder': '1px solid #0589D7',
            '--ForgetNowStepCheckOkColor': '#FAFAFA',
            '--ForgetNowStepCheckOkBgc': '#0589D7',
            '--ForgetNumberBgc': '#101C30',
            '--ForgetNumberBgcBefore': '#1E2E48',
            /**
             *FromLogin
             */
            '--fromLoginTitleColor': '#FAFAFA',
            '--fromLoginRegisterTextColor': '#879AAF',
            '--fromLoginRegisterGotoColor': '#0589D7',
            '--fromLoginBtnBgc': '#0589D7',
            '--fromLoginGotoColor': '#0589D7',
            '--fromLoginUrlTitleColor': '#879AAF',
            '--fromLoginUrlTitleBorder': '1px solid #879AAF',
            '--fromLoginInputWrapBorder': '1px solid #879AAF',
            '--fromLoginInputWrapActiveBorder': '1px solid #0589D7',
            '--fromLoginInputWrapHoverBorder': '1px solid #0589D7',
            '--fromLoginInputWrapErrorBorder': '1px solid #E73744',
            '--fromLoginInputPlaceholder': '#879AAF',
            '--fromLoginItemContentBgc': '#17253D',
            '--fromLoginItemContentBorder': '1px solid #253C5A',
            /**
             *inputerror
             */
            '--inputErrorColor': '#E73744',
            /**
             *Loginscss
             */
            '--loginScssBgc': '#1E2E48',
            '--loginScssInputBgc': '#101C30',
            '--loginScssLoadingLogo': '#1E2E48',
            '--loginScssShadow': '0px 15px 30px #00000033',
            /**
             *Loginslider
             */
            '--LoginsliderSwiperPaginationBgc': 'rgba(255, 255, 255, 0.1)',
            '--LoginsliderSwiperPaginationActiveBgc': '#FFF',
            '--loginsliderTitleColor': '#FAFAFA',
            '--loginsliderDescribeColor': '#879AAF',
            '--loginsliderNumberShowColor': '#0589D7',
            '--loginsliderNumberTitleColor': '#FAFAFA',
            '--loginsliderNumberDescribeColor': '#879AAF',
            /**
             *Resgister
             */
            '--registerTitleColor': '#FAFAFA',
            '--registerInfoColor': '#879AAF',
            '--registerGotoTextColor': '#879AAF',
            '--registerGotoLinkColor': '#0589D7',
            '--registerBtnBgc': '#0589D7',
            '--registerWrapSetBorder': '1px solid #879AAF',
            '--registerWrapSetInputColor': '#FAFAFA',
            '--registerWrapSetPlaceHolderColor': '#879AAF',
            '--registerWrapSetInputHover': '1px solid #0589D7',
            '--registerWrapSetInputActive': '1px solid #0589D7',
            '--registerWrapSetInputError': '1px solid #E73744',
            /**
             *app
             */
            '--appContentViewBgc': '#1E2E48',
            '--appVanDialogBgc': '#101E35',
            '--appVanAlertDialogBgc': '#17253D',
            '--appVanAlertHeaderColor': '#FAFAFA',
            '--appVanDialogShadow': '0 8px 25px #0006',
            '--appPcNoticeColor': '#879AAF',
            '--appPcNoticeHasTitleColor': '#879AAF',
            '--appPcNoticeCancelColor': '#879AA',
            '--appPcNoticeHeaderColor': '#FAFAFA',
            '--appPcNoticeHeaderAfterBorder': '3px solid #2BA66F',
            '--appErrorAlertColor': '#879AAF',
            '--appErrorAlertTitleColor': '#879AAF',
            '--appErrorAlertHeaderColor': '#FAFAFA',
            '--appErrorAlertHeaderAfterBorder': '3px solid #E73744',
            '--appDialogHeaderColor': '#879AAF',
            '--appDialogFooterBorder': '1px solid #253C5A',
            '--appDialogConfirmColor': '#FAFAFA',
            '--appDialogConfirmBgc': '#0589D7',
            '--appDialogCancelColor': '#879AAF',
            '--appDialogCancelBorder': '1px solid #879AAF',
            '--appVpickerBgc': '#17253D',
            '--appVDataBeforeBorder': '1px solid #253C5A',
            '--appScrollTrackShadow': 'inset 0 0 6px rgba(0, 0, 0, 0)',
            '--appScrollThumb': '#31445E',
            '--appVDatePickerColor': '#879AAF',
            '--appVDatePickerActiveColor': '#FAFAFA',
            '--appDatePickerTableColor': '#879AAF',
            /**
             * public
             */
            '--confirmBtnBgc': '#0589D7',
            '--confirmBtnColor': '#FAFAFA',
            '--confirmBtnHoverBgc': '#45A2D9',
            '--cancelBtnColor': '#879AAF',
            '--cancelBtnHoverBgc': '#222F43',
            /**
             * Navbar
             */
            '--layoutRouterHide': '#879AAF',
            '--layoutRouterHover': '#0589D7',
            /**
             * dropdownqrcode
             */
            '--dropdownVancellColor': '#FAFAFA',
            '--dropdownContentBgc': '#1B2A43',
            '--dropdownBorder': '1px solid #1D324B',
            '--dropdownTitleBorder': '1px solid #1D324B',
            '--dropdownInfoColor': '#879AAF',
            '--dropdownItemColor': '#FAFAFA',
            '--dropdownHoverItemColor': '#253C5A',
            '--dropdownMainTextColor': '#FAFAFA',
            '--dropdownMainQrcodeBorder': '1px solid #707070',
            '--dropdownBoxShadow': '0 8px 25px #0006',
            '--dropdownRankLevelColor': '#0589D7',
            '--dropdownTypeBgc': 'rgba(37, 60, 90, 0) 0% 0% no-repeat padding-box',
            /**
            * bankMange
            */
            '--bankMangeSpanColor': '#FAFAFA',
            '--bankMangeTitleColor': '#375E86',
            /**
            * announcements
            */
            '--announcementSwiperColor': '#879AAF',
            '--announcementBtnsColor': '#879AAF',
            '--announcementTitleColor': '#FAFAFA',
            /**
            * ClobalLoading
            */
            '--ClobalLoadingBox1Bgc': '#0589D7 0% 0% no-repeat padding-box',
            '--ClobalLoadingBox2Bgc': '#253C5A 0% 0% no-repeat padding-box',
            '--ClobalLoadingBox3Bgc': '#253C5A 0% 0% no-repeat padding-box',
            '--ClobalLoadingBox4Bgc': '#0589D7 0% 0% no-repeat padding-box',
            /**
            *Maintain
            */
            '--MaintainBgc': '#0B1021',
            '--MaintainTitleColor': '#FAFAFA',
            '--MaintainInfoColor': '#879AAF',
            '--MaintainBtnColor': '#879AAF',
            '--MaintainBtnBorder': '1px solid #879AAF',
            '--MaintainTimeShow': '#0589D7',
            /**
            * Navigation
            */
            '--navigationCardWrapBgc': '#1E2E48',
            '--navigationCardWrapBeforeBgc': '#17253D',
            '--navigationWrapBgc': '#1E2E48',
            '--navigationWrapBorder': '#17253D',
            '--navigationSlideBtnBgc': '17253D',
            '--navigationSlideOutBgc': '#17253D',
            /**
            * RechargeComponents
            */
            '--rechargeInputWrap': '#879AAF',
            '--rechargeInputWrapBorder': '1px solid #879AAF',
            '--rechargeInputWrapHoverBorder': '1px solid #0589D7',
            '--rechargeInputWrapFocusBorder': '1px solid #0589D7',
            '--rechargeInputWrapErrorBorder': '1px solid #E73744',
            '--rechargeInputColor': '#FAFAFA',
            '--rechargeInputPlaceholderColor': '#879AAF',
            '--rechargeInputErrorColor': '#E73744',
            '--rechargeItemContentBgc': '#17253D',
            '--rechargeItemContentBorder': '1px solid #253C5A',
            '--rechargeItemColor': '#879AAF',
            '--rechargeItemSelectActiveColor': '#FAFAFA',
            '--rechargeItemSelectActiveBgc': '#1B2A43',
            '--rechargeItemHoverColor': '#FAFAFA',
            '--rechargeItemHoverBgc': '#1B2A43',
            /**
            * RechargePage
            */
            '--rechargePageNavAfter': '#071121',
            '--rechargePageSpanColor': '#FAFAFA',
            '--rechargePageMenuTitle': '#375E86',
            '--rechargeWalletAfterBgc': '#1E2E48',
            '--rechargeVanTabColor': '#879AAF',
            '--rechargeVanTabHoverColor': '#0589D7',
            '--rechargeVanTabActiveColor': '#FAFAFA',
            '--rechargeVanTabLingBgc': '#FAFAFA'
          }
        /*
        88     88  dP""b8 88  88 888888
        88     88 dP   `" 88  88   88
        88  .o 88 Yb  "88 888888   88
        88ood8 88  YboodP 88  88   88
        */
        // case 'lightclassic':
        case 'test': // 暫時沒有其他主題
          return {
            '--checkUse': '#CCC',
            '--fontBase': '14px',
            /**
             *app
             */
            '--contentBgc':
              'transparent linear-gradient(180deg, #1E2E48 0%, #0B1021 100%) 0% 0% no-repeat padding-box',
            '--scrollBarTrackBgc': '#162236',
            '--fireFoxScrollBar': '#31445E',
            '--fireScrollBarTrackShadow': 'inset 0 0 6px rgba(0, 0, 0, 0)',
            '--fireScrollbarThumbBgc': '#31445E',
            '--vantDialogBgc': '#162236',
            '--vantButtonDefaultBgc': '#162236',
            '--vantDialogCancel': '#0589D7',
            '--menuRise': '#E1533B',
            '--menufall': '#2BA66F',
            '--dropDownShadow': '0 8px 25px #0006',
            '--vApplicationBgc':
              'transparenttransparent linear-gradient(180deg, #1E2E48 0%, #101E35 100%) 0% 0% no-repeat padding-box',
            /**
             * Graph.vue
             */
            '--graphBgc': '#1E2E48',
            /**
             * bankMange.vue
             */
            '--bankMangeBgc': '#CCC',
            '--bankMangeColor': '#879AAF',
            '--bankMangeTitleBorderColor': '1px solid #253C5A',
            /**
              * base系列
              */
            '--baseBtnColor': '#879AAF',
            '--baseBtnHoverColor': '#879AAF26',
            '--baseBtnSuccessColor': '#FAFAFA',
            '--baseBtnSuccessBgc': '#0589D7',
            '--baseBtnSuccessHoverBgc': '#45A2D9',
            '--baseBtnBorder': '1px solid #879AAF',
            '--baseInputColor': '#FAFAFA',
            '--baseInputFocusColor': '#0589D7',
            '--baseInputBorderColor': '1px solid #879AAF',
            /**
             * mainText.vue
             */
            '--tradeMainTextProgressColor': '#0589D7',
            '--tradeMainTextGetColorMoreZero': '#E1533B',
            '--tradeMainTextGetColorSLessZero': '#37C882',
            '--tradeMainTextGetColorNotEqualZero': '#253C5A',
            '--tradeMainTextGetColorEqualZero': '#656D7A',
            '--tradeMainTextGetColorDef': '#253C5A',
            '--tradeMainTextOrderColor': '#879AAF',
            /**
             * tradeMain.vue
             */
            '--tradeMainTabLineBgc': '#FAFAFA',
            '--tradeMainTabsNavColor': '#879AAF',
            '--tradeMainTabsNavHoverColor': '#0589D7',
            '--tradeMainTabsNavBgc': 'transparent',
            '--tradeMainItemActive': '#1E2E48',
            '--tradeMainIconBgc': '#20314E',
            '--tradeMainTabsNavColor2': '#162236',
            '--tradeMainPcTitleColor': '#FAFAFA',
            '--tradeMainPcBorderBottom': '1px solid #1E2E48',
            /**
             * trade.vue
             */
            '--tradeNavBgc': '#17253D',
            '--tradeNavMenuItemColor': '#7D91A5',
            '--tradeNavMenuItemIsActFFF': '#0472B3',
            '--tradeListTitle': '#879AAF',
            '--tradeListItemBgc': '#1E2E48',
            '--tradeListItemBgcHover': '#1B2A43',
            '--tradeListItemBgcActive': '#1B2A43',
            '--tradeListHeaderColor': '#FFF',
            '--tradeListItemContentColor': '#879AAF',
            '--tradeListItemUpColor': '#E1533B',
            '--tradeListItemDownColor': '#37C882',
            '--tradeVtabsBgc': '#1B2A43',
            '--tradeVtabsActive': '#0472B3',
            '--tradeVtabsUrl': "url('/static/images/trade/arrow-left.svg')",
            /**
             *trendRank.vue
             */
            '--trendRankHeaderColor': '#FAFAFA',
            '--trendRankHeaderBorder': '1px solid #1E2E48',
            '--trendRankTableColor': '#879AAF',
            '--trendRankTrendInfoColor': '#879AAF',
            '--trendRankTrendInfoHoverBgc': '#1B2A43',
            '--trendRankUpColor': '#E1533B',
            '--trendRankDownColor': '#2BA66F',
            '--trendRankNameColor': '#FAFAFA',
            '--trendRankTdFirstChild': '#FAFAFA',
            '--trendRankTdNth2Child': '#879AAF',
            '--trendRankTdUp': '#E1533B',
            '--trendRankTdDown': '#2BA66F',
            '--trendRankSlideBgc': '#17253D',
            '--trendRankUseIdColor': '#FFF',
            '--trendRankUserNameColor': '#879AAF',
            '--trendRankProfit': '#E1533B',
            '--trendRankBorderBgc': '#17253D',
            /**
             *Double.vue
             */
            '--doubleTitleColor': '#879AAF',
            '--doubleItemWrapperBorder': '1px solid #879AAF',
            '--doubleVanCellColor': '#7D91A5',
            '--doubleVanCellColor2': '#FAFAFA',
            '--doubleCollapseContentColor': '#879AAF',
            '--doubleCollapseContentBgc': '#17253D',
            '--doubleIconArrowUrl': "url('/static/images/bet/triangle.svg')",
            '--doubleBtnActiveColor': '#FAFAFA',
            '--doubleBtnActiveBgc': '#1B2A43',
            '--doubleOrderMoneyBorder': '1px solid #879AAF',
            '--doubleOrderMoneyActiveBorder': '1px solid #0589D7',
            '--doubleItemNameColor': '#879AAF',
            '--doubleItemValueColor': '#FAFAFA',
            '--doubleBoubleBtnColor': '#FAFAFA',
            '--doubleBoubleBtnBgc': '#0589D7',
            '--doubleBoubleBtnActiveBgc': '#45A2D9',
            '--doubleDropdownItemBorder': '1px solid #253C5A',
            '--doubleDropdownItemShadow': '0 8px 15px #0003',
            '--doubleDropdownItemActiveColor': '#FAFAFA',
            '--doubleDropdownItemActiveBgc': '#1B2A43',
            '--doubleDropdownItemTitleColor': '#FAFAFA',
            '--doubleDropdownItemTitleBgc': '#1B2A43',
            '--doubleDropdownItemTitleHoverColor': '#FAFAFA',
            '--doubleDropdownItemTitleHoverBgc': '#1B2A43',
            '--doubleDropdownVanCellColor': '#879AAF',
            '--doubleDropdownVanCelBgc': '#17253D',
            '--doubleUnitBgc': '#253C5A',
            '--doubleDropdownMenuColor': '#7D91A5',
            '--doubleDropdownMenuBorder':
              'transparent transparent #7D91A5 #7D91A5',
            '--doubleVanEllipsisColor': '#879AAF',
            '--doubleInputAreaVanCellBorder': '1px solid #879AAF',
            '--doubleInputAreaCollapseItemBorder': '1px solid #0589D7',
            '--doubleMaxIncome': '#253C5A',
            '--doubleVanCellColor3': '#879AAF',
            /**
             *indexBetTab.vue
             */
            '--indexBetTabTitleColor': '879AAF',
            '--indexBetTabTitleBorder': '1px solid #1E2E48',
            '--indexBetStockWrapQaColor': '#879AAF',
            '--indexBetTabNavColor': '#17253D',
            '--indexBetVanTabColor': '#879AAF',
            '--indexBetVanTabActiveColor': '#FAFAFA',
            '--indexBetVanTabActiveBgc': '#0589D7',
            '--indexBetSwiperBtnBgc': '#1B2A43',
            '--indexBetHoverBtnColor': '#0589D7',
            '--indexBetActiveColor': '#FAFAFA',
            '--indexBetBtnColor': '#879AAF',
            '--indexBetMoveLineBgc': '#0589D7',
            /**
             *locations.vue
             */
            '--locationInputSetColor': '#FAFAFA',
            '--locationTitleColor': '#879AAF',
            '--locationWrapperBorder': '1px solid #253C5A',
            '--locationVanCellColor': '#7D91A5',
            '--locationIsShowVanCellColor': '#FAFAFA',
            '--locationItemContentColor': '#879AAF',
            '--locationItemContentBgc': '#17253D',
            '--locationItemIconArrowUrl':
              "url('/static/images/bet/triangle.svg')",
            '--locationItemIconArrowUrlActive': "url('/static/images/bet/triangle-active.svg')",
            '--locationItemActiveColor': '#0589D7',
            '--locationItemTitleExpandedBorder': '1px solid #0589D7',
            '--locationItemBtnActiveColor': '#FAFAFA',
            '--locationItemBtnActiveBgc': '#1B2A43',
            '--locationItemOrderMoneyBorder': '1px solid #879AAF',
            '--locationItemOrderMoneyActiveBorder': '1px solid #0589D7',
            '--locationShowInfoItemNameColor': '#879AAF',
            '--locationShowInfoItemValueColor': '#FAFAFA',
            '--locationBoubleBtnColor': '#FAFAFA',
            '--locationDropdownItemBorder': '1px solid #253C5A',
            '--locationDropdownItemShadow': '0 8px 15px #0003',
            '--locationDropdownItemActiveColor': '#FAFAFA',
            '--locationDropdownItemActiveBgc': '#1B2A43',
            '--locationCellTitleHoverColor': '#FAFAFA',
            '--locationCellTitleHoverBgc': '#1B2A43',
            '--locationUnitVanCellColor': '#879AAF',
            '--locationUnitVanCellBgc': '#17253D',
            '--locationUnitBgc': '#253C5A',
            '--locationUnitDropdownTitleActiveColor': '#0589D7',
            '--locationUnitDropdownEllipsisColor': '#879AAF',
            '--locationChaseVanCellColor': '#879AAF',
            '--locationChaseSwitchColor': '#31445E',
            '--locationChaseOnSwitchColor': '#0589D7',
            '--locationInputWrapBorder': '1px solid #879AAF',
            '--locationInputWrapBgc': '#253C5A',
            '--locationInputInputBgc': '#17253D',
            '--locationSelectInputBorder': '1px solid #879AAF',
            '--locationSelectInputActiveBorder': '1px solid #0589D7',
            '--locationSelectRemarkerColor': '#879AAF',
            '--locationPlayModeBgc': '#17253D',
            '--locationPlayModeBarOpenBorder': '1px solid #0589D7',
            '--locationPlayModeVanPopupBgc': '#17253D',
            '--locationPlayModeTitleColor': '#879AAF',
            '--locationBetInputAreaVanCellBorder': '1px solid #879AAF',
            '--locationBetInputAreaTitleExpandedBorder': '1px solid #0589D7',
            '--locationBetWrapBtnBgc': '#1B2A43',
            '--locationBoubleTitleColor': '#879AAF',
            '--locationBoubleTitleBgc': '#1B2A43',
            '--locationBoubleTitleActiveColor': '#0589D7',
            '--locationBoubleTitleActiveBorder': '1px solid #0589D7',
            '--locationPlayTypeSpanColor': '#879AAF',
            '--locationPlayTypeSpanBgc': '#1B2A43',
            '--locationPlayTypeSpanActiveColor': '#0589D7',
            '--locationPlayTypeSpanActiveBgc': '#132C4C',
            '--locationPlayTypeSpanActiveBorder': '0 0 0 1px #0589D7',
            '--locationDobleBorder': '1px solid #879AAF',
            '--locationDobleActiveBorder': '1px solid #0589D7',
            '--locationDobleInputColor': '#FAFAFA',
            '--locationUnitWrapColor': '#879AAF',
            '--locationUnitWrapBgc': '#253C5A',
            '--locationVanCellColor2': '#879AAF',
            '--locationItemWrapperBgc': '#17253D',
            '--locationItemWrapperBorder': '1px solid #253C5A',
            '--locationItemDivColor': '#879AAF',
            '--locationItemHoverDivColor': '#FAFAFA',
            '--locationItemHoverDivBgc': '#1B2A43',
            '--locationMaxIncomeColor': '#253C5A',
            '--locationBetShowBorder': '1px solid #1D324B',
            /**
             *postion.vue
             */
            '--postionTabActiveColor': '#0589D7',
            '--postionTabLineColor': '#0589D7',
            '--postionTabNavBgc': '#1B2A43',
            /**
             *indexWareHouse => chaseNumberBtns.vue
             */
            '--chaseNumberBtnsBtnsBgc': '#1E2E48',
            '--chaseNumberBtnsBtnColor': '#879AAF',
            '--chaseNumberBtnsBtnActiveColor': '#0589D7',
            '--chaseNumberBtnsBtnActiveBgc': '#0589D7',
            '--chaseNumberBtnsDaysColor': '#879AAF',
            '--chaseNumberDropdownTitleColor': '#FAFAFA',
            '--chaseNumberDropdownTitleActiveColor': '#0589D7',
            '--chaseNumberDropdownItemContentBorder': '2px solid #253C5A',
            '--chaseNumberDropdownItemActiveColor': '#FAFAFA',
            '--chaseNumberDropdownItemActiveBgc': '#1B2A43',
            '--chaseNumberDropdownVanCellTitleColor': '#FAFAFA',
            '--chaseNumberDropdownVanCellColor': '#879AAF',
            '--chaseNumberDropdownVanCellBgc': '#17253D',
            /**
             *indexWareHouse => selectBtns.vue
             */
            '--selectBtnsContentBtnsBgc': '#1E2E48',
            '--selectBtnsBtnColor': '#879AAF',
            '--selectBtnsBtnActiveColor': '#0589D7',
            '--selectBtnsBtnActiveBgc': '#1E2E48',
            '--selectBtnsDaysColor': '#879AAF',
            '--selectBtnsDayDropdownTitleColor': '#879AAF',
            '--selectBtnsDayDropdownActiveTitleColor': '#0589D7',
            '--selectBtnsDayDropdownOptionColor': '#0589D7',
            '--selectBtnsDayDropdownVanCellTitleColor': '#0589D7',
            '--selectBtnsDayDropdownVanCellColor': '#879AAF',
            '--selectBtnsDayDropdownVanCellBgc': '#17253D',
            /**
             *indexWareHouse => list=> Title => AccountTitle.vue
             */
            '--accountingTitleColor': '#879AAF',
            /**
            /**
             *indexWareHouse => list=>
             */
            '--accountingItemBonesRiseColor': '#E1533B',
            '--accountingItemPendingColor': '#2BA66F',
            '--accountingItemIngColor': '#2BA66F',
            '--accountingItemRiseColor': '#FAFAFA',
            '--accountingItemItemStockColor': '#FAFAFA',
            /**
            /**
            *indexWareHouse => list=>ChaseNumber
            */
            '--accountingItemChaseRiseColor': '#E1533B',
            '--accountingItemChaseIngColor': '#0589D7',
            '--accountingItemChaseColor': '#FAFAFA',
            /**
             *indexWareHouse => popup
             */
            '--popupTitleColor': '#FAFAFA',
            '--popupNameColor': '#879AAF',
            '--popupValueColor': '#FAFAFA',
            '--popupValuePendingColor': '#879AAF',
            '--popupValueRiseColor': '#E1533B',
            '--popupValueRecordCodeRiseColor': '#E1533B',
            '--popupValueRecordCodePendingColor': '#2BA66F',
            '--popupValueRecordCodeFallColor': '#879AAF',
            '--popupRevokeBtnColor': '#879AAF',
            '--popupRevokeBtnBorder': '1px solid #879AAF',
            '--popupRevokeBtnRevokeColor': '#FAFAFA',
            '--popupRevokeBtnRevokeBgc': '#E73744',
            '--popupRevokeBtnRevokeActiveBgc': '#F95D68',
            /**
             *indexWareHouse => popup => Chase.vue
             */
            '--popupChaseRevokeBtnCancelColor': '#879AAF',
            '--popupChaseRevokeBtnCancelBorder': '1px solid #879AAF',
            '--popupChaseRevokeBtnRevokeColor': '#FAFAFA',
            '--popupChaseRevokeBtnRevokeBgc': '#E73744',
            '--popupChaseCollapseVanCellColor': '#879AAF',
            '--popupChaseCollapseVanItemContentColor': '#879AAF',
            '--popupChaseCollapseVanTitleContentBgc': '#233C5A',
            '--popupChaseStepInfoColor': '#FAFAFA',
            '--popupChaseStepInfoShowBgc': '#233C5A',
            '--popupChaseChaseStepBgc': '#1B2A43',
            '--popupChaseIssueColor': '#0589D7',
            '--popupChaseHaveValueColor': '#FAFAFA',
            '--popupChaseStatePendingColor': '#2BA66F',
            '--popupChaseStateFallColor': '#879AAF',
            '--popupChaseStateRiseColor': '#E1533B',
            '--popupChaseStateIngColor': '#0589D7',
            '--popupChaseSendBonesColor': '#E1533B',
            '--popupChaseRadioItemTitleColor': '#879AAF',
            '--popupChaseRadioItemVanSwitchBgc': '#31445E',
            '--popupChaseRadioItemVanOnSwitchBgc': '#034072',
            '--popupCancelBgc': '',
            '--popupCancelActiveBgc': '#28374E',
            '--popupRadioBorder': '1px solid #253C5A',
            /**
             *indexWareHouse => popup => ChaseChildItem.vue
             */
            '--popupChaseChildItemHoverBgc': '1E2E48',
            '--popupChaseChildItemDivColor': '#879AAF',
            '--popupChaseChildItemOrderColor': '#0589D7',
            '--popupChaseChildItemOrderIngColor': '#879AAF',
            '--popupChaseChildItemBonesColor': '#FAFAFA',
            '--popupChaseChildItemWaitColor': '#2BA66F',
            '--popupChaseChildItemProfitableColor': '#E1533B',
            /**
             *indexWareHouse => def.vue
             */
            '--popupChaseDefRiseColor': '#E1533B',
            '--popupChaseDefFallColor': '#2BA66F',
            /**
             *indexWareHouse => pcuserware.scss
             */
            '--pcUserWareBgc':
              'transparent linear-gradient(180deg , #1E2E48 0%, #0B1021 100%) 0% 0% no-repeat padding-box',
            '--pcUserWareBeforeBgc': '#D6D6D6 0% 0% no-repeat padding-box',
            '--pcUserWareItemColor': '#879AAF',
            '--pcUserWareItemHoverColor': '#1B2A43',
            '--pcUserWareItemFinishedColor': '#879AAF',
            '--pcUserWareItemFinishedBgc': '#1E2E48',
            '--pcUserWareItemVanTabsColor': '#879AAF',
            '--pcUserWareItemVanTabsActiveColor': '#FAFAFA',
            /**
             *layout
             */
            '--layoutHeaderWrapBgc': '#1E2E48',
            '--layoutLoginTitleColor': '#FAFAFA',
            '--layoutIveSelectBgc': '#1E2E48',
            '--layoutIveSelectDropdownBgc': '#1E2E48',
            '--layoutIveSelectItemFocusColor': '#0589D7',
            '--layoutIveSelectItemFocusBgc': '#1B2A43',
            '--layoutIveSelectItemHoverColor': '#0589D7',
            '--layoutIveSelectItemHoverBgc': '#1B2A43',
            '--layoutSelectBtnsBgc': '#17253D',
            '--layoutSelectBtnsBtnColor': '#879AAF',
            '--layoutSelectBtnsBtnActiveColor': '#0589D7',
            '--layoutSelectBtnsBtnActiveBgc': '#1E2E48',
            '--layoutSelectBtnsDaysColor': '#879AAF',
            '--layoutSelectBtnsDropdownTitleColor': '#879AAF',
            '--layoutSelectBtnsDropdownActiveTitleColor': '#0589D7',
            '--layoutSelectBtnsEllipsisColor': '#FAFAFA',
            '--layoutSelectBtnsDropdownOpenColor': '#0589D7',
            '--layoutSelectBtnsDropdownOpenTitleColor': '#0589D7',
            '--layoutSelectBtnsVanCellColor': '#879AAF',
            '--layoutSelectBtnsVanCellBgc': '#1E2E48',
            /**
             *notify
             */
            '--notifyWrapColor': '#FAFAFA',
            '--notifyWrapNoticeColor': '#0589D7',
            '--notifyWrapNoticeShadow': '0 8px 15px #0003',
            '--notifyWrapErrorColor': '#E73744',
            '--notifyWrapErrorShadow': '0 8px 15px #0003',
            /**
             *personal => header.vue
             */
            '--headerMenuBgc': '#071121',
            '--headerMenuBeforeBgc': '#253C5A',
            '--headerTitleColor': '#FAFAFA',
            '--headerSubTitleColor': '#CCC',
            '--headerCardTitleColor': '#879AAF',
            '--headerCardBtnColor': '#0589D7',
            '--headerCardNameColor': '#FAFAFA',
            /**
             *Popup
             */
            '--PopupBgc': '#1E2E48',
            '--PopupShadow': '0 8px 25px #0006',
            '--PopupHeaderColor': '#FAFAFA',
            '--PopupHeaderUnFocusColor': '#879AAF',
            '--PopupHeaderBgc': '#17253D',
            /**
             *safeVerify
             */
            '--safeVerifyBgc': '#CCC',
            '--safeVerifyTitleColor': '#879AAF',
            '--safeVerifyBorder': '1px solid #253C5A',
            /**
             *stock => indexRevolving.vue
             */
            '--indexRevolvingItemColor': '#879AAF',
            '--indexRevolvingUpColor': '#E1533B',
            '--indexRevolvingDownColor': '#2BA66F',
            '--indexRevolvingCardBottomColor': '#87AF8E',
            '--indexRevolvingStockTitleColor': '#FAFAFA',
            '--indexRevolvingStockOrderColor': '#879AAF',
            '--indexRevolvingStockTimeColor': '#FAFAFA',
            '--indexRevolvingOpenNumberUpColor': '#E1533B',
            '--indexRevolvingOpenNumberDownColor': '#2BA66F',
            /**
             *Home.vue
             */
            '--homeBgc': '#17253D',
            '--homeTabBgc': 'rgba(7, 17, 33, .2)',
            '--homeTabHoverColor': '#0589D7',
            '--homeVanTabColor': '#879AAF',
            '--homeTabWrapBgc': '#1E2E48',
            '--homeTabsNavCardColor': '#0472B3',
            '--homeTabsNavCardBgc': '#1E2E48',
            '--homeSideCloseBtnBgc': '#17253D',
            '--homeSideCloseSwitchBtnBgc': '#17253D',
            '--homeCloseIconBgc': '#17253D',
            '--homeXlBorder': '6px solid #162236',
            '--homeTabsGroupColor': '#879AAF',
            '--homeActiveColor': '#FAFAFA',
            '--homeActiveBgc': 'transparent',
            '--homeScrollBarColor': '#31445E transparent',
            '--homeScrollBarShadow': 'inset 0 0 6px rgba(0, 0, 0, 0)',
            '--homeScrollBarBgc': '#31445E',
            '--homeBgcTransparent': 'rgba(7, 17, 33, .35)',
            '--homeBgcBeforeTransparent': 'transparent',
            '--homeBgcMixBlendMode': 'normal',
            '--homeEmptyColor': '#879AAF',
            '--homeThemeAfterBgc': '#253C5A',
            '--homeThemeIconActiveBgc': '#20385A',
            '--homeCloseBtnHoverBgc': '#1E2E48 0% 0% no-repeat padding-box',
            /**
             *BasePanel.vue
             */
            '--basePanelBgc': '#071121',
            '--basePanelColor': '#879AAF',
            '--basePanelHoverColor': '#0589D7',
            '--basePanelActiveColor': '#FAFAFA',
            /**
             *BaseTooltip.vue
             */
            '--baseTooltipColor': '#879AAF',
            '--baseTooltipBgc': '#17253D',
            '--baseTooltipBorderColor': '#253C5A',
            '--baseTooltipShadow': '0 1px 2px #00000026',
            /**
             *MemberCenter.vue
             */
            '--memberCenterBarBgc': '#FAFAFA',
            '--memberCenterBtnColor': '#879AAF',
            '--memberCenterBtnHoverColor': '#0589D7',
            '--memberCenterBtnActiveColor': '#FAFAFA',
            '--memberCenterBgcMix': '#071121',
            '--memberCenterListBorder': '1px solid #1E2E48',
            '--memberCenterTitleColor': '#879AAF',
            '--memberCenterTextColor': '#FAFAFA',
            '--memberCenterSpanActiveColor': '#FAFAFA',
            '--memberCenterDisableColor': '#879AAF',
            '--memberCenterDateBorder': ' 1px solid #879AAF',
            '--memberCenterPickerBgc': '#17253D',
            '--memberCenterPickerBorder': '1px solid #253C5A',
            '--memberCenterPickerShadow': '0 8px 15px #0003',
            '--memberCenterPickerPrimary': '#F0F',
            '--memberCenterRadioBorder': '1px solid #FFF',
            '--memberCenterRadioBgc': '#0589D7',
            '--memberCenterRadioCheckedBorder': '#0589D7',
            '--memberCenterMenuTitleColor': '#375E86',
            /**
             *AssetsMange.vue
             */
            '--assetsManageBtnColor': '#879AAF',
            '--assetsManageBtnActiveColor': '#0589D7',
            '--assetsManageBtnLineColor': '#1E2E48',
            '--assetsManageFontColor': '#879AAF',
            '--assetsManageLightColor': '#FAFAFA',
            '--assetsManagePaginationColor': '#879AAF',
            '--assetsManageMenuHoverBgc': 'rgba(30,46,72,.6)',
            /**
             *step
             */
            '--stepBtnBgc': '#0589D7',
            '--stepWrapBorder': '1px solid #879AAF',
            '--stepWrapActiveBorder': '1px solid #0589D7',
            '--stepWrapErrorBorder': ' 1px solid #E73744',
            '--stepInputColor': '#FAFAFA',
            '--stepInputPlaceholder': '#879AAF',

            /**
             *step-2
             */
            '--step2BackBtnColor': '#879AAF',
            '--step2BackBtnBorder': '1px solid #879AAF',
            '--step2NextBtnColor': '#FAFAFA',
            '--step2NextBtnBorder': ' #0589D7',
            '--step2CheckWrapBorder': '1px solid #879AAF',
            '--step2CheckWrapHoverText': '#FAFAFA',
            '--step2CheckWrapText': '#879AAF',
            '--step2SelectBorder': '1px solid #0589D7',
            '--step2SelectBgc': '#0589D714',
            '--step2SelectText': '#FAFAFA',
            /**
             *step-3
             */
            '--step3TitleColor': '#879AAF',
            '--step3PassWordFocusBorder': '1px solid #0589D7',
            '--step3PassWordBgc': '#879AAF26',
            '--step3PassWordDomI': '#FFF',
            '--step3QaListItemColor': '#879AAF',
            '--step3QaInputColor': '#879AAF',
            '--step3QaInputBorder': '1px solid #879AAF',
            '--step3QaInputPlaceholder': '#879AAF',
            '--step3PassWordFocusColor': '#0589D7',
            /**
             *Forget
             */
            '--ForgetShowStepWrapTitleColor': '#FAFAFA',
            '--ForgetHaveAccountWrapText': '#879AAF',
            '--ForgetHaveAccountWrapLink': '#0589D7',
            '--ForgetStepLineBgc': '#879AAF',
            '--ForgetStepLineNowBgc': '#1E2E48',
            '--ForgetNowStepColor': '#0589D7',
            '--ForgetNowStepNumberBgc': '#101C30',
            '--ForgetNowStepNumberBorder': '1px solid #0589D7',
            '--ForgetNowStepCheckOkColor': '#FAFAFA',
            '--ForgetNowStepCheckOkBgc': '#0589D7',
            '--ForgetNumberBgc': '#101C30',
            '--ForgetNumberBgcBefore': '#1E2E48',
            /**
             *FromLogin
             */
            '--fromLoginTitleColor': '#FAFAFA',
            '--fromLoginRegisterTextColor': '#879AAF',
            '--fromLoginRegisterGotoColor': '#0589D7',
            '--fromLoginBtnBgc': '#0589D7',
            '--fromLoginGotoColor': '#0589D7',
            '--fromLoginUrlTitleColor': '#879AAF',
            '--fromLoginUrlTitleBorder': '1px solid #879AAF',
            '--fromLoginInputWrapBorder': '1px solid #879AAF',
            '--fromLoginInputWrapActiveBorder': '1px solid #0589D7',
            '--fromLoginInputWrapHoverBorder': '1px solid #0589D7',
            '--fromLoginInputWrapErrorBorder': '1px solid #E73744',
            '--fromLoginInputPlaceholder': '#879AAF',
            '--fromLoginItemContentBgc': '#17253D',
            '--fromLoginItemContentBorder': '1px solid #253C5A',
            /**
             *inputerror
             */
            '--inputErrorColor': '#E73744',
            /**
             *Loginscss
             */
            '--loginScssBgc': '#1E2E48',
            '--loginScssInputBgc': '#101C30',
            '--loginScssLoadingLogo': '#1E2E48',
            '--loginScssShadow': '0px 15px 30px #00000033',
            /**
             *Loginslider
             */
            '--LoginsliderSwiperPaginationBgc': 'rgba(255, 255, 255, 0.1)',
            '--LoginsliderSwiperPaginationActiveBgc': '#FFF',
            '--loginsliderTitleColor': '#FAFAFA',
            '--loginsliderDescribeColor': '#879AAF',
            '--loginsliderNumberShowColor': '#0589D7',
            '--loginsliderNumberTitleColor': '#FAFAFA',
            '--loginsliderNumberDescribeColor': '#879AAF',
            /**
             *Resgister
             */
            '--registerTitleColor': '#FAFAFA',
            '--registerInfoColor': '#879AAF',
            '--registerGotoTextColor': '#879AAF',
            '--registerGotoLinkColor': '#0589D7',
            '--registerBtnBgc': '#0589D7',
            '--registerWrapSetBorder': '1px solid #879AAF',
            '--registerWrapSetInputColor': '#FAFAFA',
            '--registerWrapSetPlaceHolderColor': '#879AAF',
            '--registerWrapSetInputHover': '1px solid #0589D7',
            '--registerWrapSetInputActive': '1px solid #0589D7',
            '--registerWrapSetInputError': '1px solid #E73744',
            /**
             *app
             */
            '--appContentViewBgc': '#1E2E48',
            '--appVanDialogBgc': '#101E35',
            '--appVanAlertDialogBgc': '#17253D',
            '--appVanAlertHeaderColor': '#FAFAFA',
            '--appVanDialogShadow': '0 8px 25px #0006',
            '--appPcNoticeColor': '#879AAF',
            '--appPcNoticeHasTitleColor': '#879AAF',
            '--appPcNoticeCancelColor': '#879AA',
            '--appPcNoticeHeaderColor': '#FAFAFA',
            '--appPcNoticeHeaderAfterBorder': '3px solid #2BA66F',
            '--appErrorAlertColor': '#879AAF',
            '--appErrorAlertTitleColor': '#879AAF',
            '--appErrorAlertHeaderColor': '#FAFAFA',
            '--appErrorAlertHeaderAfterBorder': '3px solid #E73744',
            '--appDialogHeaderColor': '#879AAF',
            '--appDialogFooterBorder': '1px solid #253C5A',
            '--appDialogConfirmColor': '#FAFAFA',
            '--appDialogConfirmBgc': '#0589D7',
            '--appDialogCancelColor': '#879AAF',
            '--appDialogCancelBorder': '1px solid #879AAF',
            '--appVpickerBgc': '#17253D',
            '--appVDataBeforeBorder': '1px solid #253C5A',
            '--appScrollTrackShadow': 'inset 0 0 6px rgba(0, 0, 0, 0)',
            '--appScrollThumb': '#31445E',
            '--appVDatePickerColor': '#879AAF',
            '--appVDatePickerActiveColor': '#FAFAFA',
            '--appDatePickerTableColor': '#879AAF',
            /**
             * public
             */
            '--confirmBtnBgc': '#0589D7',
            '--confirmBtnColor': '#FAFAFA',
            '--confirmBtnHoverBgc': '#45A2D9',
            '--cancelBtnColor': '#879AAF',
            '--cancelBtnHoverBgc': '#222F43',
            /**
             * Navbar
             */
            '--layoutRouterHide': '#879AAF',
            '--layoutRouterHover': '#0589D7',
            /**
             * dropdownqrcode
             */
            '--dropdownVancellColor': '#FAFAFA',
            '--dropdownContentBgc': '#1B2A43',
            '--dropdownBorder': '1px solid #1D324B',
            '--dropdownTitleBorder': '1px solid #1D324B',
            '--dropdownInfoColor': '#879AAF',
            '--dropdownItemColor': '#FAFAFA',
            '--dropdownHoverItemColor': '#253C5A',
            '--dropdownMainTextColor': '#FAFAFA',
            '--dropdownMainQrcodeBorder': '1px solid #707070',
            '--dropdownBoxShadow': '0 8px 25px #0006',
            '--dropdownRankLevelColor': '#0589D7',
            '--dropdownTypeBgc': 'rgba(37, 60, 90, 0) 0% 0% no-repeat padding-box',
            /**
            * bankMange
            */
            '--bankMangeSpanColor': '#FAFAFA',
            '--bankMangeTitleColor': '#375E86',
            /**
            * announcements
            */
            '--announcementSwiperColor': '#879AAF',
            '--announcementBtnsColor': '#879AAF',
            '--announcementTitleColor': '#FAFAFA',
            /**
            * ClobalLoading
            */
            '--ClobalLoadingBox1Bgc': '#0589D7 0% 0% no-repeat padding-box',
            '--ClobalLoadingBox2Bgc': '#253C5A 0% 0% no-repeat padding-box',
            '--ClobalLoadingBox3Bgc': '#253C5A 0% 0% no-repeat padding-box',
            '--ClobalLoadingBox4Bgc': '#0589D7 0% 0% no-repeat padding-box',
            /**
            *Maintain
            */
            '--MaintainBgc': '#0B1021',
            '--MaintainTitleColor': '#FAFAFA',
            '--MaintainInfoColor': '#879AAF',
            '--MaintainBtnColor': '#879AAF',
            '--MaintainBtnBorder': '1px solid #879AAF',
            '--MaintainTimeShow': '#0589D7',
            /**
            * Navigation
            */
            '--navigationCardWrapBgc': '#1E2E48',
            '--navigationCardWrapBeforeBgc': '#17253D',
            '--navigationWrapBgc': '#1E2E48',
            '--navigationWrapBorder': '#17253D',
            '--navigationSlideBtnBgc': '17253D',
            '--navigationSlideOutBgc': '#17253D',
            /**
            * RechargeComponents
            */
            '--rechargeInputWrap': '#879AAF',
            '--rechargeInputWrapBorder': '1px solid #879AAF',
            '--rechargeInputWrapHoverBorder': '1px solid #0589D7',
            '--rechargeInputWrapFocusBorder': '1px solid #0589D7',
            '--rechargeInputWrapErrorBorder': '1px solid #E73744',
            '--rechargeInputColor': '#FAFAFA',
            '--rechargeInputPlaceholderColor': '#879AAF',
            '--rechargeInputErrorColor': '#E73744',
            '--rechargeItemContentBgc': '#17253D',
            '--rechargeItemContentBorder': '1px solid #253C5A',
            '--rechargeItemColor': '#879AAF',
            '--rechargeItemSelectActiveColor': '#FAFAFA',
            '--rechargeItemSelectActiveBgc': '#1B2A43',
            '--rechargeItemHoverColor': '#FAFAFA',
            '--rechargeItemHoverBgc': '#1B2A43',
            /**
            * RechargePage
            */
            '--rechargePageNavAfter': '#071121',
            '--rechargePageSpanColor': '#FAFAFA',
            '--rechargePageMenuTitle': '#375E86',
            '--rechargeWalletAfterBgc': '#1E2E48',
            '--rechargeVanTabColor': '#879AAF',
            '--rechargeVanTabHoverColor': '#0589D7',
            '--rechargeVanTabActiveColor': '#FAFAFA',
            '--rechargeVanTabLingBgc': '#FAFAFA'
          }
        /*
        8888b.  888888 888888    db    88   88 88     888888
         8I  Yb 88__   88__     dPYb   88   88 88       88
         8I  dY 88""   88""    dP__Yb  Y8   8P 88  .o   88
        8888Y"  888888 88     dP""""Yb `YbodP' 88ood8   88
        */
        default:
          return {
            '--checkUse': '#F00',
            '--fontBase': '14px',
            /**
             *app
             */
            '--contentBgc': 'transparent',
            '--scrollBarTrackBgc': '#162236',
            '--fireFoxScrollBar': '#31445E',
            '--fireScrollBarTrackShadow': 'inset 0 0 6px rgba(0, 0, 0, 0)',
            '--fireScrollbarThumbBgc': '#31445E',
            '--vantDialogBgc': '#162236',
            '--vantButtonDefaultBgc': '#162236',
            '--vantDialogCancel': '#0589D7',
            '--menuRise': '#E1533B',
            '--menufall': '#2BA66F',
            '--dropDownShadow': '0 8px 25px #0006',
            '--vApplicationBgc':
              'transparent linear-gradient(180deg, #1E2E48 0%, #101E35 100%) 0% 0% no-repeat padding-box',
            /**
             * Graph.vue
             */
            '--graphBgc': 'transparent',
            /**
             * bankMange.vue
             */
            '--bankMangeBgc': '#CCC',
            '--bankMangeColor': '#879AAF',
            '--bankMangeTitleBorderColor': '1px solid #253C5A',
            /**
              * base系列
              */
            '--baseBtnColor': '#879AAF',
            '--baseBtnHoverColor': '#879AAF26',
            '--baseBtnSuccessColor': '#FAFAFA',
            '--baseBtnSuccessBgc': '#0589D7',
            '--baseBtnSuccessHoverBgc': '#45A2D9',
            '--baseBtnBorder': '1px solid #879AAF',
            '--baseInputColor': '#FAFAFA',
            '--baseInputFocusColor': '#0589D7',
            '--baseInputBorderColor': '1px solid #879AAF',
            /**
             * mainText.vue
             */
            '--tradeMainTextProgressColor': '#0589D7',
            '--tradeMainTextGetColorMoreZero': '#E1533B',
            '--tradeMainTextGetColorSLessZero': '#37C882',
            '--tradeMainTextGetColorNotEqualZero': '#253C5A',
            '--tradeMainTextGetColorEqualZero': '#656D7A',
            '--tradeMainTextGetColorDef': '#253C5A',
            '--tradeMainTextOrderColor': '#879AAF',
            /**
             * tradeMain.vue
             */
            '--tradeMainTabLineBgc': '#FAFAFA',
            '--tradeMainTabsNavColor': '#879AAF',
            '--tradeMainTabsNavHoverColor': '#0589D7',
            '--tradeMainTabsNavBgc': 'transparent',
            '--tradeMainItemActive': '#1E2E48',
            '--tradeMainIconBgc': '#20314E',
            '--tradeMainTabsNavColor2': '#162236',
            '--tradeMainPcTitleColor': '#FAFAFA',
            '--tradeMainPcBorderBottom': '1px solid #1E2E48',
            /**
             * trade.vue
             */
            '--tradeNavBgc': '#17253D',
            '--tradeNavMenuItemColor': '#7D91A5',
            '--tradeNavMenuItemIsActFFF': '#0472B3',
            '--tradeListTitle': '#879AAF',
            '--tradeListItemBgc': '#1E2E48',
            '--tradeListItemBgcHover': '#1B2A43',
            '--tradeListItemBgcActive': '#1B2A43',
            '--tradeListHeaderColor': '#FFF',
            '--tradeListItemContentColor': '#879AAF',
            '--tradeListItemUpColor': '#E1533B',
            '--tradeListItemDownColor': '#37C882',
            '--tradeVtabsBgc': '#1B2A43',
            '--tradeVtabsActive': '#0472B3',
            '--tradeVtabsUrl': "url('/static/images/trade/arrow-left.svg')",
            /**
             *trendRank.vue
             */
            '--trendRankHeaderColor': '#FAFAFA',
            '--trendRankHeaderBorder': '1px solid #1E2E48',
            '--trendRankTableColor': '#879AAF',
            '--trendRankTrendInfoColor': '#879AAF',
            '--trendRankTrendInfoHoverBgc': '#1B2A43',
            '--trendRankUpColor': '#E1533B',
            '--trendRankDownColor': '#2BA66F',
            '--trendRankNameColor': '#FAFAFA',
            '--trendRankTdFirstChild': '#FAFAFA',
            '--trendRankTdNth2Child': '#879AAF',
            '--trendRankTdUp': '#E1533B',
            '--trendRankTdDown': '#2BA66F',
            '--trendRankSlideBgc': '#17253D',
            '--trendRankUseIdColor': '#FFF',
            '--trendRankUserNameColor': '#879AAF',
            '--trendRankProfit': '#E1533B',
            '--trendRankBorderBgc': '#17253D',
            /**
             *Double.vue
             */
            '--doubleTitleColor': '#879AAF',
            '--doubleItemWrapperBorder': '1px solid #879AAF',
            '--doubleVanCellColor': '#7D91A5',
            '--doubleVanCellColor2': '#FAFAFA',
            '--doubleCollapseContentColor': '#879AAF',
            '--doubleCollapseContentBgc': '#17253D',
            '--doubleIconArrowUrl': "url('/static/images/bet/triangle.svg')",
            '--doubleBtnActiveColor': '#FAFAFA',
            '--doubleBtnActiveBgc': '#1B2A43',
            '--doubleOrderMoneyBorder': '1px solid #879AAF',
            '--doubleOrderMoneyActiveBorder': '1px solid #0589D7',
            '--doubleItemNameColor': '#879AAF',
            '--doubleItemValueColor': '#FAFAFA',
            '--doubleBoubleBtnColor': '#FAFAFA',
            '--doubleBoubleBtnBgc': '#0589D7',
            '--doubleBoubleBtnActiveBgc': '#45A2D9',
            '--doubleDropdownItemBorder': '1px solid #253C5A',
            '--doubleDropdownItemShadow': '0 8px 15px #0003',
            '--doubleDropdownItemActiveColor': '#FAFAFA',
            '--doubleDropdownItemActiveBgc': '#1B2A43',
            '--doubleDropdownItemTitleColor': '#FAFAFA',
            '--doubleDropdownItemTitleBgc': '#1B2A43',
            '--doubleDropdownItemTitleHoverColor': '#FAFAFA',
            '--doubleDropdownItemTitleHoverBgc': '#1B2A43',
            '--doubleDropdownVanCellColor': '#879AAF',
            '--doubleDropdownVanCelBgc': '#17253D',
            '--doubleUnitBgc': '#253C5A',
            '--doubleDropdownMenuColor': '#7D91A5',
            '--doubleDropdownMenuBorder':
              'transparent transparent #7D91A5 #7D91A5',
            '--doubleVanEllipsisColor': '#879AAF',
            '--doubleInputAreaVanCellBorder': '1px solid #879AAF',
            '--doubleInputAreaCollapseItemBorder': '1px solid #0589D7',
            '--doubleMaxIncome': '#253C5A',
            '--doubleVanCellColor3': '#879AAF',
            /**
             *indexBetTab.vue
             */
            '--indexBetTabTitleColor': '879AAF',
            '--indexBetTabTitleBorder': '1px solid #1E2E48',
            '--indexBetStockWrapQaColor': '#879AAF',
            '--indexBetTabNavColor': '#17253D',
            '--indexBetVanTabColor': '#879AAF',
            '--indexBetVanTabActiveColor': '#FAFAFA',
            '--indexBetVanTabActiveBgc': '#0589D7',
            '--indexBetSwiperBtnBgc': '#1B2A43',
            '--indexBetHoverBtnColor': '#0589D7',
            '--indexBetActiveColor': '#FAFAFA',
            '--indexBetBtnColor': '#879AAF',
            '--indexBetMoveLineBgc': '#0589D7',
            /**
             *locations.vue
             */
            '--locationInputSetColor': '#FAFAFA',
            '--locationTitleColor': '#879AAF',
            '--locationWrapperBorder': '1px solid #253C5A',
            '--locationVanCellColor': '#7D91A5',
            '--locationIsShowVanCellColor': '#FAFAFA',
            '--locationItemContentColor': '#879AAF',
            '--locationItemContentBgc': '#17253D',
            '--locationItemIconArrowUrl':
              "url('/static/images/bet/triangle.svg')",
            '--locationItemIconArrowUrlActive': "url('/static/images/bet/triangle-active.svg')",
            '--locationItemActiveColor': '#0589D7',
            '--locationItemTitleExpandedBorder': '1px solid #0589D7',
            '--locationItemBtnActiveColor': '#FAFAFA',
            '--locationItemBtnActiveBgc': '#1B2A43',
            '--locationItemOrderMoneyBorder': '1px solid #879AAF',
            '--locationItemOrderMoneyActiveBorder': '1px solid #0589D7',
            '--locationShowInfoItemNameColor': '#879AAF',
            '--locationShowInfoItemValueColor': '#FAFAFA',
            '--locationBoubleBtnColor': '#FAFAFA',
            '--locationDropdownItemBorder': '1px solid #253C5A',
            '--locationDropdownItemShadow': '0 8px 15px #0003',
            '--locationDropdownItemActiveColor': '#FAFAFA',
            '--locationDropdownItemActiveBgc': '#1B2A43',
            '--locationCellTitleHoverColor': '#FAFAFA',
            '--locationCellTitleHoverBgc': '#1B2A43',
            '--locationUnitVanCellColor': '#879AAF',
            '--locationUnitVanCellBgc': '#17253D',
            '--locationUnitBgc': '#253C5A',
            '--locationUnitDropdownTitleActiveColor': '#0589D7',
            '--locationUnitDropdownEllipsisColor': '#879AAF',
            '--locationChaseVanCellColor': '#879AAF',
            '--locationChaseSwitchColor': '#31445E',
            '--locationChaseOnSwitchColor': '#0589D7',
            '--locationInputWrapBorder': '1px solid #879AAF',
            '--locationInputWrapBgc': '#253C5A',
            '--locationInputInputBgc': '#17253D',
            '--locationSelectInputBorder': '1px solid #879AAF',
            '--locationSelectInputActiveBorder': '1px solid #0589D7',
            '--locationSelectRemarkerColor': '#879AAF',
            '--locationPlayModeBgc': '#17253D',
            '--locationPlayModeBarOpenBorder': '1px solid #0589D7',
            '--locationPlayModeVanPopupBgc': '#17253D',
            '--locationPlayModeTitleColor': '#879AAF',
            '--locationBetInputAreaVanCellBorder': '1px solid #879AAF',
            '--locationBetInputAreaTitleExpandedBorder': '1px solid #0589D7',
            '--locationBetWrapBtnBgc': '#1B2A43',
            '--locationBoubleTitleColor': '#879AAF',
            '--locationBoubleTitleBgc': '#1B2A43',
            '--locationBoubleTitleActiveColor': '#0589D7',
            '--locationBoubleTitleActiveBorder': '1px solid #0589D7',
            '--locationPlayTypeSpanColor': '#879AAF',
            '--locationPlayTypeSpanBgc': '#1B2A43',
            '--locationPlayTypeSpanActiveColor': '#0589D7',
            '--locationPlayTypeSpanActiveBgc': '#132C4C',
            '--locationPlayTypeSpanActiveBorder': '0 0 0 1px #0589D7',
            '--locationDobleBorder': '1px solid #879AAF',
            '--locationDobleActiveBorder': '1px solid #0589D7',
            '--locationDobleInputColor': '#FAFAFA',
            '--locationUnitWrapColor': '#879AAF',
            '--locationUnitWrapBgc': '#253C5A',
            '--locationVanCellColor2': '#879AAF',
            '--locationItemWrapperBgc': '#17253D',
            '--locationItemWrapperBorder': '1px solid #253C5A',
            '--locationItemDivColor': '#879AAF',
            '--locationItemHoverDivColor': '#FAFAFA',
            '--locationItemHoverDivBgc': '#1B2A43',
            '--locationMaxIncomeColor': '#253C5A',
            '--locationBetShowBorder': '1px solid #1D324B',
            /**
             *postion.vue
             */
            '--postionTabActiveColor': '#0589D7',
            '--postionTabLineColor': '#0589D7',
            '--postionTabNavBgc': '#1B2A43',
            /**
             *indexWareHouse => chaseNumberBtns.vue
             */
            '--chaseNumberBtnsBtnsBgc': '#1E2E48',
            '--chaseNumberBtnsBtnColor': '#879AAF',
            '--chaseNumberBtnsBtnActiveColor': '#0589D7',
            '--chaseNumberBtnsBtnActiveBgc': '#0589D7',
            '--chaseNumberBtnsDaysColor': '#879AAF',
            '--chaseNumberDropdownTitleColor': '#FAFAFA',
            '--chaseNumberDropdownTitleActiveColor': '#0589D7',
            '--chaseNumberDropdownItemContentBorder': '2px solid #253C5A',
            '--chaseNumberDropdownItemActiveColor': '#FAFAFA',
            '--chaseNumberDropdownItemActiveBgc': '#1B2A43',
            '--chaseNumberDropdownVanCellTitleColor': '#FAFAFA',
            '--chaseNumberDropdownVanCellColor': '#879AAF',
            '--chaseNumberDropdownVanCellBgc': '#17253D',
            /**
             *indexWareHouse => selectBtns.vue
             */
            '--selectBtnsContentBtnsBgc': '#1E2E48',
            '--selectBtnsBtnColor': '#879AAF',
            '--selectBtnsBtnActiveColor': '#0589D7',
            '--selectBtnsBtnActiveBgc': '#1E2E48',
            '--selectBtnsDaysColor': '#879AAF',
            '--selectBtnsDayDropdownTitleColor': '#879AAF',
            '--selectBtnsDayDropdownActiveTitleColor': '#0589D7',
            '--selectBtnsDayDropdownOptionColor': '#0589D7',
            '--selectBtnsDayDropdownVanCellTitleColor': '#0589D7',
            '--selectBtnsDayDropdownVanCellColor': '#879AAF',
            '--selectBtnsDayDropdownVanCellBgc': '#17253D',
            /**
             *indexWareHouse => list=> Title => AccountTitle.vue
             */
            '--accountingTitleColor': '#879AAF',
            /**
            /**
             *indexWareHouse => list=>
             */
            '--accountingItemBonesRiseColor': '#E1533B',
            '--accountingItemPendingColor': '#2BA66F',
            '--accountingItemIngColor': '#2BA66F',
            '--accountingItemRiseColor': '#FAFAFA',
            '--accountingItemItemStockColor': '#FAFAFA',
            /**
            /**
            *indexWareHouse => list=>ChaseNumber
            */
            '--accountingItemChaseRiseColor': '#E1533B',
            '--accountingItemChaseIngColor': '#0589D7',
            '--accountingItemChaseColor': '#FAFAFA',
            /**
             *indexWareHouse => popup
             */
            '--popupTitleColor': '#FAFAFA',
            '--popupNameColor': '#879AAF',
            '--popupValueColor': '#FAFAFA',
            '--popupValuePendingColor': '#879AAF',
            '--popupValueRiseColor': '#E1533B',
            '--popupValueRecordCodeRiseColor': '#E1533B',
            '--popupValueRecordCodePendingColor': '#2BA66F',
            '--popupValueRecordCodeFallColor': '#879AAF',
            '--popupRevokeBtnColor': '#879AAF',
            '--popupRevokeBtnBorder': '1px solid #879AAF',
            '--popupRevokeBtnRevokeColor': '#FAFAFA',
            '--popupRevokeBtnRevokeBgc': '#E73744',
            '--popupRevokeBtnRevokeActiveBgc': '#F95D68',
            /**
             *indexWareHouse => popup => Chase.vue
             */
            '--popupChaseRevokeBtnCancelColor': '#879AAF',
            '--popupChaseRevokeBtnCancelBorder': '1px solid #879AAF',
            '--popupChaseRevokeBtnRevokeColor': '#FAFAFA',
            '--popupChaseRevokeBtnRevokeBgc': '#E73744',
            '--popupChaseCollapseVanCellColor': '#879AAF',
            '--popupChaseCollapseVanItemContentColor': '#879AAF',
            '--popupChaseCollapseVanTitleContentBgc': '#233C5A',
            '--popupChaseStepInfoColor': '#FAFAFA',
            '--popupChaseStepInfoShowBgc': '#233C5A',
            '--popupChaseChaseStepBgc': '#1B2A43',
            '--popupChaseIssueColor': '#0589D7',
            '--popupChaseHaveValueColor': '#FAFAFA',
            '--popupChaseStatePendingColor': '#2BA66F',
            '--popupChaseStateFallColor': '#879AAF',
            '--popupChaseStateRiseColor': '#E1533B',
            '--popupChaseStateIngColor': '#0589D7',
            '--popupChaseSendBonesColor': '#E1533B',
            '--popupChaseRadioItemTitleColor': '#879AAF',
            '--popupChaseRadioItemVanSwitchBgc': '#31445E',
            '--popupChaseRadioItemVanOnSwitchBgc': '#034072',
            '--popupCancelBgc': '',
            '--popupCancelActiveBgc': '#28374E',
            '--popupRadioBorder': '1px solid #253C5A',
            /**
             *indexWareHouse => popup => ChaseChildItem.vue
             */
            '--popupChaseChildItemHoverBgc': '1E2E48',
            '--popupChaseChildItemDivColor': '#879AAF',
            '--popupChaseChildItemOrderColor': '#0589D7',
            '--popupChaseChildItemOrderIngColor': '#879AAF',
            '--popupChaseChildItemBonesColor': '#FAFAFA',
            '--popupChaseChildItemWaitColor': '#2BA66F',
            '--popupChaseChildItemProfitableColor': '#E1533B',
            /**
             *indexWareHouse => def.vue
             */
            '--popupChaseDefRiseColor': '#E1533B',
            '--popupChaseDefFallColor': '#2BA66F',
            /**
             *indexWareHouse => pcuserware.scss
             */
            '--pcUserWareBgc':
              'transparent linear-gradient(180deg , #1E2E48 0%, #0B1021 100%) 0% 0% no-repeat padding-box',
            '--pcUserWareBeforeBgc': '#D6D6D6 0% 0% no-repeat padding-box',
            '--pcUserWareItemColor': '#879AAF',
            '--pcUserWareItemHoverColor': '#1B2A43',
            '--pcUserWareItemFinishedColor': '#879AAF',
            '--pcUserWareItemFinishedBgc': '#1E2E48',
            '--pcUserWareItemVanTabsColor': '#879AAF',
            '--pcUserWareItemVanTabsActiveColor': '#FAFAFA',
            /**
             *layout
             */
            '--layoutHeaderWrapBgc': 'transparent',
            '--layoutLoginTitleColor': '#FAFAFA',
            '--layoutIveSelectBgc': '#1E2E48',
            '--layoutIveSelectDropdownBgc': '#1E2E48',
            '--layoutIveSelectItemFocusColor': '#0589D7',
            '--layoutIveSelectItemFocusBgc': '#1B2A43',
            '--layoutIveSelectItemHoverColor': '#0589D7',
            '--layoutIveSelectItemHoverBgc': '#1B2A43',
            '--layoutSelectBtnsBgc': '#17253D',
            '--layoutSelectBtnsBtnColor': '#879AAF',
            '--layoutSelectBtnsBtnActiveColor': '#0589D7',
            '--layoutSelectBtnsBtnActiveBgc': '#1E2E48',
            '--layoutSelectBtnsDaysColor': '#879AAF',
            '--layoutSelectBtnsDropdownTitleColor': '#879AAF',
            '--layoutSelectBtnsDropdownActiveTitleColor': '#0589D7',
            '--layoutSelectBtnsEllipsisColor': '#FAFAFA',
            '--layoutSelectBtnsDropdownOpenColor': '#0589D7',
            '--layoutSelectBtnsDropdownOpenTitleColor': '#0589D7',
            '--layoutSelectBtnsVanCellColor': '#879AAF',
            '--layoutSelectBtnsVanCellBgc': '#1E2E48',
            /**
             *notify
             */
            '--notifyWrapColor': '#FAFAFA',
            '--notifyWrapNoticeColor': '#0589D7',
            '--notifyWrapNoticeShadow': '0 8px 15px #0003',
            '--notifyWrapErrorColor': '#E73744',
            '--notifyWrapErrorShadow': '0 8px 15px #0003',
            /**
             *personal => header.vue
             */
            '--headerMenuBgc': '#071121',
            '--headerMenuBeforeBgc': '#253C5A',
            '--headerTitleColor': '#FAFAFA',
            '--headerSubTitleColor': '#CCC',
            '--headerCardTitleColor': '#879AAF',
            '--headerCardBtnColor': '#0589D7',
            '--headerCardNameColor': '#FAFAFA',
            /**
             *Popup
             */
            '--PopupBgc': '#1E2E48',
            '--PopupShadow': '0 8px 25px #0006',
            '--PopupHeaderColor': '#FAFAFA',
            '--PopupHeaderUnFocusColor': '#879AAF',
            '--PopupHeaderBgc': '#17253D',
            /**
             *safeVerify
             */
            '--safeVerifyBgc': '#CCC',
            '--safeVerifyTitleColor': '#879AAF',
            '--safeVerifyBorder': '1px solid #253C5A',
            /**
             *stock => indexRevolving.vue
             */
            '--indexRevolvingItemColor': '#879AAF',
            '--indexRevolvingUpColor': '#E1533B',
            '--indexRevolvingDownColor': '#2BA66F',
            '--indexRevolvingCardBottomColor': '#87AF8E',
            '--indexRevolvingStockTitleColor': '#FAFAFA',
            '--indexRevolvingStockOrderColor': '#879AAF',
            '--indexRevolvingStockTimeColor': '#FAFAFA',
            '--indexRevolvingOpenNumberUpColor': '#E1533B',
            '--indexRevolvingOpenNumberDownColor': '#2BA66F',
            /**
             *Home.vue
             */
            '--homeBgc': 'transparent',
            '--homeTabBgc': 'rgba(7, 17, 33, .2)',
            '--homeTabHoverColor': '#0589D7',
            '--homeVanTabColor': '#879AAF',
            '--homeTabWrapBgc': '#1E2E48',
            '--homeTabsNavCardColor': '#0472B3',
            '--homeTabsNavCardBgc': '#1E2E48',
            '--homeSideCloseBtnBgc':
              'transparent linear-gradient(180deg, #1D2D46 0%, #101E35 100%) 0% 0% no-repeat padding-box',
            '--homeSideCloseSwitchBtnBgc': '#17253D',
            '--homeCloseIconBgc': '#17253D',
            '--homeXlBorder': '6px solid #162236',
            '--homeTabsGroupColor': '#879AAF',
            '--homeActiveColor': '#FAFAFA',
            '--homeActiveBgc': 'transparent',
            '--homeScrollBarColor': '#31445E transparent',
            '--homeScrollBarShadow': 'inset 0 0 6px rgba(0, 0, 0, 0)',
            '--homeScrollBarBgc': '#31445E',
            '--homeBgcTransparent': 'rgba(7, 17, 33, .35)',
            '--homeBgcBeforeTransparent': '#D6D6D6 0% 0% no-repeat padding-box',
            '--homeBgcMixBlendMode': 'multiply',
            '--homeEmptyColor': '#879AAF',
            '--homeThemeAfterBgc': '#253C5A',
            '--homeThemeIconActiveBgc': '#20385A',
            '--homeCloseBtnHoverBgc': '#1E2E48 0% 0% no-repeat padding-box',
            /**
             *BasePanel.vue
             */
            '--basePanelBgc': '#071121',
            '--basePanelColor': '#879AAF',
            '--basePanelHoverColor': '#0589D7',
            '--basePanelActiveColor': '#FAFAFA',
            /**
             *BaseTooltip.vue
             */
            '--baseTooltipColor': '#879AAF',
            '--baseTooltipBgc': '#17253D',
            '--baseTooltipBorderColor': '#253C5A',
            '--baseTooltipShadow': '0 1px 2px #00000026',
            /**
             *MemberCenter.vue
             */
            '--memberCenterBarBgc': '#FAFAFA',
            '--memberCenterBtnColor': '#879AAF',
            '--memberCenterBtnHoverColor': '#0589D7',
            '--memberCenterBtnActiveColor': '#FAFAFA',
            '--memberCenterBgcMix': '#071121',
            '--memberCenterListBorder': '1px solid #1E2E48',
            '--memberCenterTitleColor': '#879AAF',
            '--memberCenterTextColor': '#FAFAFA',
            '--memberCenterSpanActiveColor': '#FAFAFA',
            '--memberCenterDisableColor': '#879AAF',
            '--memberCenterDateBorder': ' 1px solid #879AAF',
            '--memberCenterPickerBgc': '#17253D',
            '--memberCenterPickerBorder': '1px solid #253C5A',
            '--memberCenterPickerShadow': '0 8px 15px #0003',
            '--memberCenterPickerPrimary': '#F0F',
            '--memberCenterRadioBorder': '1px solid #FFF',
            '--memberCenterRadioBgc': '#0589D7',
            '--memberCenterRadioCheckedBorder': '#0589D7',
            '--memberCenterMenuTitleColor': '#375E86',
            /**
             *AssetsMange.vue
             */
            '--assetsManageBtnColor': '#879AAF',
            '--assetsManageBtnActiveColor': '#0589D7',
            '--assetsManageBtnLineColor': '#1E2E48',
            '--assetsManageFontColor': '#879AAF',
            '--assetsManageLightColor': '#FAFAFA',
            '--assetsManagePaginationColor': '#879AAF',
            '--assetsManageMenuHoverBgc': 'rgba(30,46,72,.6)',
            /**
             *step
             */
            '--stepBtnBgc': '#0589D7',
            '--stepWrapBorder': '1px solid #879AAF',
            '--stepWrapActiveBorder': '1px solid #0589D7',
            '--stepWrapErrorBorder': ' 1px solid #E73744',
            '--stepInputColor': '#FAFAFA',
            '--stepInputPlaceholder': '#879AAF',
            /**
             *step-2
             */
            '--step2BackBtnColor': '#879AAF',
            '--step2BackBtnBorder': '1px solid #879AAF',
            '--step2NextBtnColor': '#FAFAFA',
            '--step2NextBtnBorder': ' #0589D7',
            '--step2CheckWrapBorder': '1px solid #879AAF',
            '--step2CheckWrapHoverText': '#FAFAFA',
            '--step2CheckWrapText': '#879AAF',
            '--step2SelectBorder': '1px solid #0589D7',
            '--step2SelectBgc': '#0589D714',
            '--step2SelectText': '#FAFAFA',
            /**
             *step-3
             */
            '--step3TitleColor': '#879AAF',
            '--step3PassWordFocusBorder': '1px solid #0589D7',
            '--step3PassWordBgc': '#879AAF26',
            '--step3PassWordDomI': '#FFF',
            '--step3QaListItemColor': '#879AAF',
            '--step3QaInputColor': '#879AAF',
            '--step3QaInputBorder': '1px solid #879AAF',
            '--step3QaInputPlaceholder': '#879AAF',
            '--step3PassWordFocusColor': '#0589D7',
            /**
             *Forget
             */
            '--ForgetShowStepWrapTitleColor': '#FAFAFA',
            '--ForgetHaveAccountWrapText': '#879AAF',
            '--ForgetHaveAccountWrapLink': '#0589D7',
            '--ForgetStepLineBgc': '#879AAF',
            '--ForgetStepLineNowBgc': '#1E2E48',
            '--ForgetNowStepColor': '#0589D7',
            '--ForgetNowStepNumberBgc': '#101C30',
            '--ForgetNowStepNumberBorder': '1px solid #0589D7',
            '--ForgetNowStepCheckOkColor': '#FAFAFA',
            '--ForgetNowStepCheckOkBgc': '#0589D7',
            '--ForgetNumberBgc': '#101C30',
            '--ForgetNumberBgcBefore': '#1E2E48',
            /**
             *FromLogin
             */
            '--fromLoginTitleColor': '#FAFAFA',
            '--fromLoginRegisterTextColor': '#879AAF',
            '--fromLoginRegisterGotoColor': '#0589D7',
            '--fromLoginBtnBgc': '#0589D7',
            '--fromLoginGotoColor': '#0589D7',
            '--fromLoginUrlTitleColor': '#879AAF',
            '--fromLoginUrlTitleBorder': '1px solid #879AAF',
            '--fromLoginInputWrapBorder': '1px solid #879AAF',
            '--fromLoginInputWrapActiveBorder': '1px solid #0589D7',
            '--fromLoginInputWrapHoverBorder': '1px solid #0589D7',
            '--fromLoginInputWrapErrorBorder': '1px solid #E73744',
            '--fromLoginInputPlaceholder': '#879AAF',
            '--fromLoginItemContentBgc': '#17253D',
            '--fromLoginItemContentBorder': '1px solid #253C5A',
            /**
             *inputerror
             */
            '--inputErrorColor': '#E73744',
            /**
             *Loginscss
             */
            '--loginScssBgc': '#1E2E48',
            '--loginScssInputBgc': '#101C30',
            '--loginScssLoadingLogo': '#1E2E48',
            '--loginScssShadow': '0px 15px 30px #00000033',
            /**
             *Loginslider
             */
            '--LoginsliderSwiperPaginationBgc': 'rgba(255, 255, 255, 0.1)',
            '--LoginsliderSwiperPaginationActiveBgc': '#FFF',
            '--loginsliderTitleColor': '#FAFAFA',
            '--loginsliderDescribeColor': '#879AAF',
            '--loginsliderNumberShowColor': '#0589D7',
            '--loginsliderNumberTitleColor': '#FAFAFA',
            '--loginsliderNumberDescribeColor': '#879AAF',
            /**
             *Resgister
             */
            '--registerTitleColor': '#FAFAFA',
            '--registerInfoColor': '#879AAF',
            '--registerGotoTextColor': '#879AAF',
            '--registerGotoLinkColor': '#0589D7',
            '--registerBtnBgc': '#0589D7',
            '--registerWrapSetBorder': '1px solid #879AAF',
            '--registerWrapSetInputColor': '#FAFAFA',
            '--registerWrapSetPlaceHolderColor': '#879AAF',
            '--registerWrapSetInputHover': '1px solid #0589D7',
            '--registerWrapSetInputActive': '1px solid #0589D7',
            '--registerWrapSetInputError': '1px solid #E73744',
            /**
             *app
             */
            '--appContentViewBgc': '#1E2E48',
            '--appVanDialogBgc': '#101E35',
            '--appVanAlertDialogBgc': '#17253D',
            '--appVanAlertHeaderColor': '#FAFAFA',
            '--appVanDialogShadow': '0 8px 25px #0006',
            '--appPcNoticeColor': '#879AAF',
            '--appPcNoticeHasTitleColor': '#879AAF',
            '--appPcNoticeCancelColor': '#879AA',
            '--appPcNoticeHeaderColor': '#FAFAFA',
            '--appPcNoticeHeaderAfterBorder': '3px solid #2BA66F',
            '--appErrorAlertColor': '#879AAF',
            '--appErrorAlertTitleColor': '#879AAF',
            '--appErrorAlertHeaderColor': '#FAFAFA',
            '--appErrorAlertHeaderAfterBorder': '3px solid #E73744',
            '--appDialogHeaderColor': '#879AAF',
            '--appDialogFooterBorder': '1px solid #253C5A',
            '--appDialogConfirmColor': '#FAFAFA',
            '--appDialogConfirmBgc': '#0589D7',
            '--appDialogCancelColor': '#879AAF',
            '--appDialogCancelBorder': '1px solid #879AAF',
            '--appVpickerBgc': '#17253D',
            '--appVDataBeforeBorder': '1px solid #253C5A',
            '--appScrollTrackShadow': 'inset 0 0 6px rgba(0, 0, 0, 0)',
            '--appScrollThumb': '#31445E',
            '--appVDatePickerColor': '#879AAF',
            '--appVDatePickerActiveColor': '#FAFAFA',
            '--appDatePickerTableColor': '#879AAF',
            /**
             * public
             */
            '--confirmBtnBgc': '#0589D7',
            '--confirmBtnColor': '#FAFAFA',
            '--confirmBtnHoverBgc': '#45A2D9',
            '--cancelBtnColor': '#879AAF',
            '--cancelBtnHoverBgc': '#222F43',
            /**
             * Navbar
             */
            '--layoutRouterHide': '#879AAF',
            '--layoutRouterHover': '#0589D7',
            /**
             * dropdownqrcode
             */
            '--dropdownVancellColor': '#FAFAFA',
            '--dropdownContentBgc': '#1B2A43',
            '--dropdownBorder': '1px solid #1D324B',
            '--dropdownTitleBorder': '1px solid #1D324B',
            '--dropdownInfoColor': '#879AAF',
            '--dropdownItemColor': '#FAFAFA',
            '--dropdownHoverItemColor': '#253C5A',
            '--dropdownMainTextColor': '#FAFAFA',
            '--dropdownMainQrcodeBorder': '1px solid #707070',
            '--dropdownBoxShadow': '0 8px 25px #0006',
            '--dropdownRankLevelColor': '#0589D7',
            '--dropdownTypeBgc': 'rgba(37, 60, 90, 0) 0% 0% no-repeat padding-box',
            /**
            * bankMange
            */
            '--bankMangeSpanColor': '#FAFAFA',
            '--bankMangeTitleColor': '#375E86',
            /**
            * announcements
            */
            '--announcementSwiperColor': '#879AAF',
            '--announcementBtnsColor': '#879AAF',
            '--announcementTitleColor': '#FAFAFA',
            /**
            * ClobalLoading
            */
            '--ClobalLoadingBox1Bgc': '#0589D7 0% 0% no-repeat padding-box',
            '--ClobalLoadingBox2Bgc': '#253C5A 0% 0% no-repeat padding-box',
            '--ClobalLoadingBox3Bgc': '#253C5A 0% 0% no-repeat padding-box',
            '--ClobalLoadingBox4Bgc': '#0589D7 0% 0% no-repeat padding-box',
            /**
            *Maintain
            */
            '--MaintainBgc': '#0B1021',
            '--MaintainTitleColor': '#FAFAFA',
            '--MaintainInfoColor': '#879AAF',
            '--MaintainBtnColor': '#879AAF',
            '--MaintainBtnBorder': '1px solid #879AAF',
            '--MaintainTimeShow': '#0589D7',
            /**
            * Navigation
            */
            '--navigationCardWrapBgc': '#1E2E48',
            '--navigationCardWrapBeforeBgc': '#17253D',
            '--navigationWrapBgc': '#1E2E48',
            '--navigationWrapBorder': '#17253D',
            '--navigationSlideBtnBgc': '17253D',
            '--navigationSlideOutBgc': '#17253D',
            /**
            * RechargeComponents
            */
            '--rechargeInputWrap': '#879AAF',
            '--rechargeInputWrapBorder': '1px solid #879AAF',
            '--rechargeInputWrapHoverBorder': '1px solid #0589D7',
            '--rechargeInputWrapFocusBorder': '1px solid #0589D7',
            '--rechargeInputWrapErrorBorder': '1px solid #E73744',
            '--rechargeInputColor': '#FAFAFA',
            '--rechargeInputPlaceholderColor': '#879AAF',
            '--rechargeInputErrorColor': '#E73744',
            '--rechargeItemContentBgc': '#17253D',
            '--rechargeItemContentBorder': '1px solid #253C5A',
            '--rechargeItemColor': '#879AAF',
            '--rechargeItemSelectActiveColor': '#FAFAFA',
            '--rechargeItemSelectActiveBgc': '#1B2A43',
            '--rechargeItemHoverColor': '#FAFAFA',
            '--rechargeItemHoverBgc': '#1B2A43',
            /**
            * RechargePage
            */
            '--rechargePageNavAfter': '#071121',
            '--rechargePageSpanColor': '#FAFAFA',
            '--rechargePageMenuTitle': '#375E86',
            '--rechargeWalletAfterBgc': '#1E2E48',
            '--rechargeVanTabColor': '#879AAF',
            '--rechargeVanTabHoverColor': '#0589D7',
            '--rechargeVanTabActiveColor': '#FAFAFA',
            '--rechargeVanTabLingBgc': '#FAFAFA'
          }
      }
    }
  }
}
