<template>
  <v-app-bar
    v-if="showHeader"
    app
    absolute
    clipped-left
    class="header__wrap"
    :mex-height="60"
  >
    <div class="content">
      <div
        class="logo"
        @click="goHome"
      >
        <img
          v-if="logoSrc"
          :src="logoSrc"
          alt=""
        >
      </div>
      <!-- Stock info -->
      <Revolving />
      <LoginBtns />
    </div>
  </v-app-bar>
</template>
<script>
import Revolving from '@/components/Stock/IndexRevolving.vue'
import LoginBtns from '@/components/Layout/Navbarbtngroup.vue'
import { imgHost } from '@/filters/index'
export default {
  components: {
    Revolving,
    LoginBtns
  },
  computed: {
    showHeader () {
      return this.$route.meta.header
    },
    logoSrc () {
      return imgHost(this.$store.state.siteConfig.logoPc)
    }
  },
  methods: {
    goHome () {
      this.$router.push({ name: 'Home' })
    }
  }
}
</script>
<style lang="scss" scoped>
.header__wrap {
  position: fixed !important;
  right: 0 !important;
  left: 0 !important;
  height: 60px !important;
  background-color: var(--layoutHeaderWrapBgc) !important;
  z-index: 11 !important;
  box-shadow: none !important;

  &::after {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: var(--dropdownHoverItemColor);
    opacity: 0.35;
    z-index: -1;
    box-shadow: var(--dropdownBoxShadow);
    content: '';
    transform: translateY(100%);
  }

  &:not {
    box-shadow: none !important;
  }

  .content {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    // border: 1px solid #F00;

    .logo {
      display: flex;
      align-items: center;
      margin-right: 40px;
      padding: 10px 0;
      cursor: pointer;

      img {
        max-width: 200px;
        max-height: 40px;
      }
    }
  }

  ::v-deep {
    .v-toolbar__content {
      padding: 0 20px;
      height: 60px !important;
    }
  }
}
</style>
