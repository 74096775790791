/**
 * 此js文件为工具函数的集合。建议根据功能进行分拆，避免过大难以维护
 */

import Vue from 'vue'
const bus = new Vue() // 空vue用来做事件管理
const DAYTIME = 24 * 60 * 60 * 1000 // 一天的毫秒值
const HOURTIME = 60 * 60 * 1000 // 一小时的毫秒值
const MINUTETIME = 60 * 1000 // 一分钟的毫秒值
const SECONDTIME = 1000 // 一秒的毫秒值
// let GMT_DIF =new Date().getTimezoneOffset()*60*1000
const GMTDIF = -8 * 60 * 60 * 1000 // 格林威治和东八区的相差时间
const PERBET = 1 // 每注1元
const MaxRate = 10000 // 最大倍数
const MaxChaseIssue = 50 // 追号最大期数
const MaxChaseRate = 50 // 追号最大倍数
const MaxExpectRate = 20000 // 追号最大预期盈利率限制
// 一元模式配置
const isShowBox = ['STOCKB11', 'STOCKC11', 'STOCKD11']
// 盘面配置
const n0to9 = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
const dsds = ['大', '小', '单', '双']
const gyhdsds = ['和大', '和小', '和单', '和双']
const dsdslh = ['大', '小', '单', '双', '龙', '虎']
const dsdszh = ['大', '小', '单', '双', '质', '合']
/**
 * 股票玩法 Start
*/
// 雙面
const stockTwoFaceFirst = ['涨', '跌', '单股', '双股', '牛', '熊']
const stockTwoFaceSecond = ['涨', '跌', '单股', '双股']
// 定位膽
const STOCK = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']
/**
 * 股票玩法 End
*/

const above = createStringArray(1, 40)
const below = createStringArray(41, 80)
const n1to10String = createStringArray(1, 10)

const eachLen = data => data.map(arr => arr.length)

// eslint-disable-next-line
Date.prototype.format = function (format) {
  const date = {
    'M+': this.getMonth() + 1,
    'd+': this.getDate(),
    'h+': this.getHours(),
    'm+': this.getMinutes(),
    's+': this.getSeconds(),
    'q+': Math.floor((this.getMonth() + 3) / 3),
    'S+': this.getMilliseconds()
  }
  if (/(y+)/i.test(format)) {
    format = format.replace(RegExp.$1, (this.getFullYear() + '').substr(4 - RegExp.$1.length))
  }
  for (const k in date) {
    if (new RegExp('(' + k + ')').test(format)) {
      format = format.replace(RegExp.$1, (RegExp.$1.length === 1) ? date[k] : ('00' + date[k]).substr(('' + date[k]).length))
    }
  }
  return format
}

/**
 * 阶乘
 * @param  n    基数
 * @return {[number]} [n!]
 */
const factorial = (function () {
  const cache = {}
  return function (n) {
    if (cache[n]) {
      return cache[n]
    } else {
      if (n < 0) return false
      if (n === 0) return 1
      if (n === 1) {
        return 1
      } else {
        cache[n] = n * factorial(n - 1)
        return cache[n]
      }
    }
  }
})()

/**
 * mul [分步计数]
 * @len 几个数
 * @arr 要相乘起来的数组
 */
const mul = (function () {
  const cache = {}
  return function (len, arr) {
    if (arr.length < len) return 0
    let s = 1
    const index = arr.join(' ')
    if (cache[index]) {
      return cache[index]
    } else {
      if (arr.length) {
        for (let i = 0; i < arr.length; i++) {
          s *= arr[i]
        }
      }
      cache[index] = s
      // debugger
      return cache[index]
    }
  }
})()

/**
 * C [组合]
 * @param  [m] [底数：比较大的那个数]
 * @param  [n] [选几：比较小的那个数]
 */

const C = (function () {
  const cache = {}
  return function (m, n) {
    if (m === n) { return 1 }
    if (m < n) {
      return 0
    // 互换顺序
    // m ^= n
    // n ^= m
    // m ^= n
    }
    const index = [m, n].join(' ')
    if (cache[index]) {
      return cache[index]
    } else {
      let a = 1; let b = 1
      for (let i = 0; i < n; i++) {
        a *= m - i
        b *= i + 1
      }
      cache[index] = Math.round(a / b)
      return cache[index]
    }
  }
})()
/**
 * combNoRepeat [有去重要求的跨行求组合]
 */
function combNoRepeat (singleArr, combArr, n) {
  const common = []; const notcommon = []

  for (let i = 0, len = singleArr.length; i < len; i++) {
    const item = singleArr[i]
    if (combArr.indexOf(singleArr[i]) === -1) {
      notcommon.push(item)
    } else {
      common.push(item)
    }
  }

  const a = common.length
  const b = notcommon.length
  const m = combArr.length

  return a * C(m - 1, n) + b * C(m, n)
}

// 数组去重
function unique (origin) {
  return origin.filter((item, index, arr) => arr.indexOf(item) === index)
}

// 嵌套循环3次
function loop3 (arr, fn) {
  const l = arr.length
  for (let i = 0; i < l; i++) {
    for (let j = 0; j < l; j++) {
      for (let k = 0; k < l; k++) {
        fn([i, j, k])
      }
    }
  }
}

// 嵌套循环3次
function loop2 (arr, fn) {
  const l = arr.length
  for (let i = 0; i < l; i++) {
    for (let j = 0; j < l; j++) {
      fn([i, j])
    }
  }
}

// 直选和值3
const normalSum3 = (function () {
  const base = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
  const cache = {}
  return function (n) {
    if (cache[n]) {
      return cache[n]
    }

    // 三重循环
    loop3(base, function (arr) {
      let s = 0
      for (let i = 0; i < arr.length; i++) {
        s += base[arr[i]]
      }
      const str = arr.join('')

      if (cache[s]) {
        cache[s].push(str)
      } else {
        cache[s] = [str]
      }
    })

    for (const item in cache) {
      cache[item] = cache[item].length
    }

    return cache[n]
  }
})()

// 直选和值2
const normalSum2 = (function () {
  const base = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
  const cache = {}
  return function (n) {
    if (cache[n]) {
      return cache[n]
    }

    // 三重循环
    loop2(base, function (arr) {
      let s = 0
      for (let i = 0; i < arr.length; i++) {
        s += base[arr[i]]
      }
      const str = arr.join('')

      if (cache[s]) {
        cache[s].push(str)
      } else {
        cache[s] = [str]
      }
    })

    for (const item in cache) {
      cache[item] = cache[item].length
    }

    return cache[n]
  }
})()

// 跨度3
const diff3 = (function () {
  const base = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
  const cache = {}
  return function (n) {
    if (cache[n]) {
      return cache[n]
    }

    loop3(base, function (arr) {
      const max = Math.max.apply({}, arr)
      const min = Math.min.apply({}, arr)
      const diff = max - min
      const str = arr.join('')
      if (cache[diff]) {
        cache[diff].push(str)
      } else {
        cache[diff] = [str]
      }
    })

    for (const item in cache) {
      cache[item] = cache[item].length
    }

    return cache[n]
  }
})()

// 跨度2
const diff2 = (function () {
  const base = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
  const cache = {}
  return function (n) {
    if (cache[n]) {
      return cache[n]
    }

    loop2(base, function (arr) {
      const max = Math.max.apply({}, arr)
      const min = Math.min.apply({}, arr)
      const diff = max - min
      const str = arr.join('')
      if (cache[diff]) {
        cache[diff].push(str)
      } else {
        cache[diff] = [str]
      }
    })

    for (const item in cache) {
      cache[item] = cache[item].length
    }

    return cache[n]
  }
})()

// 组选和值3
const combSum3 = (function () {
  const base = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
  const cache = {}
  return function (n) {
    if (cache[n]) {
      return cache[n]
    }

    // 三重循环
    loop3(base, function (arr) {
      if (arr.some(function (item) { return item !== arr[0] })) { // 只要数组不全等
        let s = 0
        for (let i = 0; i < arr.length; i++) {
          s += base[arr[i]]
        }
        const tmpArr = arr.sort(function (a, b) { return a - b })
        const str = tmpArr.join('')

        if (cache[s]) {
          if (cache[s].indexOf(str) === -1) {
            cache[s].push(str)
          }
        } else {
          cache[s] = [str]
        }
      }
    })

    for (const item in cache) {
      cache[item] = cache[item].length
    }

    return cache[n]
  }
})()

// 组选和值2
const combSum2 = (function () {
  const base = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
  const cache = {}
  return function (n) {
    if (cache[n]) {
      return cache[n]
    }

    // 二重循环
    loop2(base, function (arr) {
      if (arr.some(function (item) { return item !== arr[0] })) { // 只要数组不全等
        let s = 0
        for (let i = 0; i < arr.length; i++) {
          s += base[arr[i]]
        }
        const tmpArr = arr.sort(function (a, b) { return a - b })
        const str = tmpArr.join('')

        if (cache[s]) {
          // console.log(cache[s])
          if (cache[s].indexOf(str) === -1) {
            cache[s].push(str)
          }
        } else {
          cache[s] = [str]
        }
      }
    })

    for (const item in cache) {
      cache[item] = cache[item].length
    }

    return cache[n]
  }
})()

// 将一个数组(arr)中的每个数，使用fn 进行运算，并将结果求和
function accumulate (arr, fn) {
  let s = 0
  for (let i = 0; i < arr.length; i++) {
    s += fn(arr[i])
  }
  return s
}

// 复制对象，仅复制字符串
function easyClone (obj) {
  const newObj = {}
  for (const item in obj) {
    if (typeof item === 'string') {
      newObj[item] = obj[item]
    }
  }

  return newObj
}
// 删除compress
function deleteCompress (basket, ifChase) {
  return basket.map(function (item) {
    const cloneItem = easyClone(item)
    if (cloneItem.compress) {
      cloneItem.bettingNumber = cloneItem.compress
    }

    if (ifChase) {
      delete cloneItem.bettingIssue
      delete cloneItem.graduationCount
    }

    delete cloneItem.compress
    return cloneItem
  })
}

// 获得方案列表中的总注数
function getBasketAmount (state) {
  let totalNum = 0; let totalMoney = 0; const basket = state.lt.basket
  for (let i = 0, len = basket.length; i < len; i++) {
    const el = basket[i]
    totalNum += el.bettingCount
    totalMoney += el.bettingAmount
  }
  return [totalNum, totalMoney]
}

// 压缩注单 （五星复式）
function compress (source) {
  source = unique(source.map(function (item) { return +item })).sort(function (a, b) { return a - b })
  // 如果只有一注，直接返回
  if (source.length < 2) {
    return source.toString()
  }
  const baseNum = source[0]
  const diff = []
  for (let i = 1; i < source.length; i++) {
    diff.push(source[i] - source[i - 1])
  }
  const diffC = diffConti(diff)
  diffC.unshift(baseNum)
  return diffC.join(',')

  function diffConti (arr) {
    const res = []
    let start
    let count = 0
    const last = arr.length - 1
    for (let i = 0; i < arr.length; i++) {
      if (!start) {
        if (arr[i] !== 1) {
          start = arr[i]
          if (count) {
            const item = 'K' + count
            res.push(item)
            count = 0
          }

          if (i === last) {
            res.push(arr[i] + '')
          }
        } else {
          count++
          if (i === last) {
            const item = 'K' + count
            res.push(item)
          }
        }
      } else {
        if (arr[i] !== 1) {
          const item = count ? (start + 'K' + count) : start + ''
          res.push(item)
          if (i === last) {
            res.push(arr[i] + '')
          }
          start = arr[i]
          count = 0
        } else {
          count++
          if (i === last) {
            const item = start ? (start + 'K' + count) : 'K' + count
            res.push(item)
          }
        }
      }
    }
    return res
  }
}

/**
 * [howManyNum description]
 * @param  {[type]} arr [传入包含注单（string）的数组]
 * @param  {[type]} n   [每个注单期望拥有的数字,如123为三个,112为二个，111为一个]
 * @return {[arr]}     [返回期望的数字的注单的数组]
 */
function howManyNum (arr, n) {
  const newArr = []
  for (let i = 0; i < arr.length; i++) {
    const len = unique(arr[i].split('')).length // 该注单拥有的数字数，112为两个
    if (typeof n === 'number') {
      if (len === n) {
        newArr.push(arr[i])
      }
    } else {
      if (n.some(function (item) { return len === item })) {
        newArr.push(arr[i])
      }
    }
  }
  return newArr
}

/**
 * [combNote 组合单式]
 * x 混合单式传[2,3] 组三单式传2 组六单式传3 组选单式传2
 * @return {[arr]}   [滤除后的新数组]
 */
function combNote (x, arr) {
  const noRepeatArr = cunique(arr) // 数字不重复的数组
  return howManyNum(noRepeatArr, x)
}

// 节流函数（用于注单组件的输入节流）
const throttle = (function (delay) {
  let timer = null
  // let count = 0
  return function (fn) {
    if (!timer) {
      timer = setTimeout(function () {
        fn()
        // count++
        clearTimeout(timer)
        timer = null
      }, delay)
    }
  }
}(400))

// 如果赔率中有逗号，说明是多赔率的玩法
function getMultipleRebate (mode, Odds) {
  for (let i = 0; i < Odds.length; i++) {
    if (Odds[i].playCode === mode) {
      if (Odds[i].bonus.indexOf(',') > -1) {
        return Odds[i].bonus.split(',')
      } else {
        return Odds[i].bonus
      }
    }
  }
}

// 单行计数
const countSingle = (order, tmp) => betSum(order, tmp)[0]

// 获得每个框的号码的数目
function betSum (order, tmp) {
  const arr = []
  for (let i = 0; i < order.length; i++) {
    arr.push(tmp[order[i]].length)
  }
  return arr
}

/**
 * [createStringArray 根据起始和结束数字来获得字符串数组]
 * @param  {[number]} start [起始数，如1]
 * @param  {[number]} end   [结束数，如3]
 * @return {[array]}       [字符串数组,如['01','02','03']]
 */
function createStringArray (start, end) {
  const result = []
  for (let i = start; i <= end; i++) {
    const item = ('0' + i).slice(-2)
    result.push(item)
  }
  return result
}

/**
 * [xRandom 获得机选数组]
 * @param  {[array]} cfgArr    [配置数组]
 * @param  {[boolean]} canRepeat [是否允许跨行重复，单行肯定不能重复的]
 * @param  {[array]} baseArr   [基础数组]
 * @return {[array]}           [返回一个层级数组]
 */
function xRandom (cfgArr, canRepeat, baseArr) {
  const len = cfgArr.length // 有几行
  const res = [] // 结果数组
  const feeds = baseArr.slice(0) // 基础数组的拷贝

  for (let i = 0; i < len; i++) {
    const lineLen = cfgArr[i] // 每行要获取的随机数个数
    let lineRes = [] // 每行的结果数组
    const _feeds = canRepeat ? feeds.slice(0) : feeds // 每行的数据源

    for (let j = 0; j < lineLen; j++) {
      const feed = Math.floor(Math.random() * _feeds.length) // 随机索引
      lineRes.push(_feeds[feed]) // 添加到每一行的结果数组中
      _feeds.splice(feed, 1) // 从源数组中剔掉一个，保证单行不会出现重复
    }

    lineRes = lineRes.sort((a, b) => a - b) // 对每一行的结果数组进行排序
    res.push(lineRes)
  }
  return res
}

// 给出0-(n-1)的随机数
const oneRandom = (n) => Math.floor(Math.random() * n)

// 一星
function oneStar (num) {
  num = num || 5
  const line = Math.floor(Math.random() * num)
  const res = []
  for (let i = 0; i < num; i++) {
    const lineRes = []
    if (line === i) {
      lineRes.push(Math.floor(Math.random() * 10))
    }
    res.push(lineRes)
  }
  return res
}

function BaseBet (state, count, betStr) {
  const lt = state.lt
  const bet = state.lt.bet
  const _count = count || bet.bettingCount
  const _betStr = betStr || bet.bettingNumber

  this.lotteryCode = lt.lottery.lotteryCode // 彩种
  this.playDetailCode = lt.lottery.lotteryCode + lt.mode.mode // 玩法code
  this.bettingNumber = _betStr // 投注号码

  this.bettingCount = _count // 这个方案多少注
  if ((lt.lottery.lotteryCode.indexOf('14') > -1) && lt.mode.mode === 'A10') {
    this.bettingAmount = +(lt.perbet * bet.bettingUnit * bet.graduationCount).toFixed(2)
  } else if (lt.lottery.lotteryType === 'STOCK' && lt.mode.mode === 'A11') {
    this.bettingAmount = +(_count * bet.bettingUnit * 1 * bet.graduationCount).toFixed(2)
  } else {
    this.bettingAmount = +(lt.perbet * _count * bet.bettingUnit * bet.graduationCount).toFixed(2)
  }
  let award = 0
  if (Array.isArray(lt.award)) {
    award = lt.award[0]
  } else {
    award = lt.award
  }
  // this.bettingPoint = lt.lottery.LotteryType!=='K3'?(lt.award.split(',')[0] + '-' + lt.Rebate[lt.lottery.LotteryType]):(lt.award + '-' + lt.Rebate[lt.lottery.LotteryType])           //赔率
  // this.bettingPoint = ('object'===typeof(lt.award)?lt.award[0]:lt.award) + '-' + lt.Rebate[lt.lottery.LotteryType]
  this.bettingPoint = award + '-' + lt.Rebate[lt.lottery.lotteryType]

  this.bettingUnit = bet.bettingUnit // 元角分
  this.bettingIssue = state.NowIssue // 当前期号
  this.graduationCount = bet.graduationCount // 当前倍率
  this.compress = bet.compress // 压缩字符串
}

// 倍数归一，普通投注切换追号时，号码篮内倍率变成1
BaseBet.prototype.power2one = function (state) {
  this.graduationCount = 1
  this.bettingAmount = +(state.lt.perbet * this.bettingCount * this.bettingUnit * this.graduationCount).toFixed(2)
}

// 设置赔率
BaseBet.prototype.setRebate = function (rebate, state) {
  const lt = state.lt
  this.bettingPoint = rebate + '-' + lt.Rebate[lt.lottery.lotteryType]
}

// 设置倍数
BaseBet.prototype.setPower = function (power, state) {
  this.graduationCount = power
  this.bettingAmount = +(state.lt.perbet * this.bettingCount * this.bettingUnit * this.graduationCount).toFixed(2)
}

// 清楚压缩字符串
BaseBet.prototype.clearCompress = function () {
  this.compress = ''
}

// 获取tag和 [五星, 复式， 直选这种]
function getTag (code, config) {
  for (const group in config) {
    const groupItem = config[group]
    for (const subGroup in groupItem) {
      const subGroupItem = groupItem[subGroup]
      for (let i = 0; i < subGroupItem.length; i++) {
        if (subGroupItem[i].mode === code) {
          const str = `[${subGroupItem[i].group},${subGroupItem[i].subGroup},${subGroupItem[i].name}]`
          return [subGroupItem[i].tag, str]
        }
      }
    }
  }
}
// 追号构造函数
function funScheme (issueStr, power, money) {
  return {
    bettingIssue: issueStr,
    graduationCount: power,
    bettingAmount: money
  }
}

// 组选注单的去重  123 和 321 视为一注，仅留一个
function cunique (arr) {
  for (let i = 0; i < arr.length; i++) {
    arr[i] = arr[i].split('').sort(function (a, b) {
      return (+a) - (+b)
    }).join('')
  }
  return unique(arr)
}

function getLegalStr (str) {
  str = str.replace(/^\s+/g, '')
  str = str.replace(/[^\d,，;；\s]/g, '')
  str = str.replace(/，/g, ',')
  str = str.replace(/；/g, ';')
  return str
}

// 时时彩的赔率处理函数
function getSSCRebate (mode, Odds) {
  // 前三中三后三一样，前二后二一样
  switch (mode[0]) {
    case 'E':
    case 'D':
      mode = 'F' + mode.slice(1)
      break
    case 'B':
      mode = 'C' + mode.slice(1)
      break
    case 'I':
      if (mode === 'I92') mode = 'I91'
      if (mode === 'I94' || mode === 'I95') mode = 'I93'
      break
  }

  // 一个玩法中有两个赔率的
  const specialMode = ['F21', 'F24', 'F25', 'E21', 'E24', 'E25', 'D21', 'D24', 'D25']

  const rebateSSC = Odds
  for (let i = 0; i < rebateSSC.length; i++) {
    if (rebateSSC[i].playCode === mode) {
      const bonus = rebateSSC[i].bonus

      if (specialMode.indexOf(mode) > -1) {
        const half = ((+bonus) / 2).toFixed(3)
        return [bonus, half]
      } else {
        return bonus
      }
    }
  }
}

// 如果赔率中有逗号，说明是多赔率的玩法
function getStockRebate (mode, Odds) {
  if (!Odds.length) {
    return []
  }

  const oddsFilter = Odds.filter(i => i.playCode === mode)
  const oddsBonus = oddsFilter.length ? oddsFilter[0].bonus : ''

  if (oddsBonus.includes(',')) {
    return oddsBonus.split(',')
  } else {
    return oddsBonus
  }
}

export { factorial, mul, C, combNoRepeat, unique, normalSum2, normalSum3, accumulate, diff2, diff3, combSum2, combSum3, bus, BaseBet, compress, throttle, easyClone, eachLen, deleteCompress, getBasketAmount, getSSCRebate, getMultipleRebate, DAYTIME, HOURTIME, MINUTETIME, SECONDTIME, GMTDIF, PERBET, MaxRate, MaxChaseIssue, MaxChaseRate, MaxExpectRate, above, below, n1to10String, countSingle, betSum, createStringArray, xRandom, n0to9, dsds, dsdszh, gyhdsds, dsdslh, oneRandom, oneStar, getTag, funScheme, combNote, cunique, getLegalStr, isShowBox, getStockRebate, STOCK, stockTwoFaceFirst, stockTwoFaceSecond }
