<template>
  <div class="wallet__dropdownwrap mr-4">
    <van-collapse
      :value="activeUserAccount"
      accordion
      class="wallet__dropdown"
    >
      <van-collapse-item
        class="wallet__vancollapseitem"
        name="0"
        :title="curLang"
      >
        <div class="wallet__showwrap">
          <div class="show__wallet">
            <div
              v-for="(item, key) in langTable"
              :key="`key${key}`"
              :class="{active: key === $i18n.locale}"
              class="typea type-set"
              @click.stop="updateLang(key)"
            >
              <span> {{ item }}</span>
            </div>
          </div>
        </div>
      </van-collapse-item>
    </van-collapse>
  </div>
</template>

<script>
export default {
  props: {
    activeUserAccount: {
      type: String,
      default: '',
      required: true
    }
  },
  data () {
    return {
      dayValue: 'wallet',
      option1: [
        { text: this.$t('PC_WALLET'), value: 'wallet', icon: '' }
      ],
      selectType: 0,
      userInfo: null,
      langTable: {
        cn: '中文',
        en: 'English',
        ja: '日本語',
        vie: 'Tiếng Việt'
      },
      linkItem: [
        {
          name: this.$t('LAYOUT_ASSETS_MANAGE'),
          to: 'about'
        },
        {
          name: this.$t('DEPOSIT'),
          to: 'about'
        },
        {
          name: this.$t('WITHDRAWAL'),
          to: 'about'
        },
        {
          name: this.$t('ACTING_TRANSACTION_DETAILS'),
          to: 'about'
        }
      ]
    }
  },
  computed: {
    curLang () {
      return this.$t(this.langTable[this.$i18n.locale] || 'CHINESE')
    }
  },
  mounted () {
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
  },
  methods: {
    updateLang (key) {
      this.$emit('close')
      this.$store.dispatch('setInterfaceLoading', true)
      this.$i18n.locale = key
      localStorage.setItem('lang', key)
      setTimeout(() => {
        this.$store.dispatch('setInterfaceLoading', false)
      }, 1500)
    }
  }
}

</script>

<style lang="scss" scoped>
.wallet {
  &__dropdownwrap {
    &::v-deep {
      .van-hairline--top-bottom {
        &::after {
          display: none;
        }
      }
    }
  }

  &__vancollapseitem {
    position: relative;
    // border: 1px solid #F00;

    &::v-deep {
      .van-collapse-item__wrapper {
        position: absolute;
        right: 0;
        border-radius: 4px;
        z-index: 20;
        box-shadow: var(--dropdownBoxShadow);
        transform: translateY(10px);
        transition-duration: 0.1s;
      }

      .van-cell {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        color: var(--dropdownVancellColor);
        background-color: transparent;

        &::after {
          display: none;
        }
      }

      .van-cell__title {
        flex: 0 0 auto;
      }

      .van-collapse-item__content {
        padding: 0;
        width: 140px;
        background-color: transparent;
        border: var(--dropdownBorder);
        border-radius: 4px;
        box-sizing: border-box;
        // box-shadow: 0 0 0 10px #F00;
      }

      .van-icon {
        position: relative;
        width: 12px;
        height: 12px;

        &::before {
          display: none;
        }

        &::after {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          content: '';
          background-image: url('/public/static/images/Layout/triangle.svg');
        }
      }

      .van-collapse-item__title--expanded {
        color: var(--layoutRouterHover);

        .van-icon {
          &::after {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            content: '';
            background-image: url('/public/static/images/Layout/triangle-active.svg');
          }
        }

      }
    }
  }
}

.show__wallet {
  overflow: hidden;
  box-sizing: border-box;
  background-color: var(--dropdownContentBgc);
  // border: var(--dropdownBorder);
  border-radius: 4px;
  box-shadow: var(--dropdownBorder);

  .type-set {
    display: flex;
    align-items: center;
    padding-left: 16px;
    // padding-left: 16px;
    // width: 138px;
    height: 36px;
    font-size: 14px;
    color: var(--dropdownInfoColor);
    background: var(--dropdownTypeBgc);
    cursor: pointer;
    // transition: 0.5s;

    &.active,
    &:hover {
      color: var(--dropdownItemColor);
    }

    &:hover {
      background: var(--dropdownHoverItemColor);
    }

    &.active {
      cursor: default;
    }
  }
}

.typea {
  &:nth-of-type(1) {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  &:nth-last-of-type(1) {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}
</style>
