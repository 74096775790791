<template>
  <!-- 替代用/等api -->
  <div class="main__text">
    <div class="main__title">
      <div class="main__titlename">
        <!-- {{name.length > 6 ? name.slice(0, 6) + '..' : name}} -->
        {{ name }}
      </div>
      <div
        v-if="isShow"
        class="main__opernumber"
        :style="{ color: getColor }"
      >
        {{ openNumber }}
        <!-- <span
          v-if="openNumber"
          class="gain"
        >
          {{ currency || 'USD' }}：
          <span class="num">
            {{ dynamicGain(gain) }}
          </span>
        </span> -->
      </div>
    </div>
    <!-- 待補上開獎結果 -->
    <div
      v-if="issue"
      class="main__order"
    >
      <span class="issue__title">
        {{ issue && issue.slice(4) }}{{ $t('TRADE_EXPECT') }}
      </span>
      <span
        v-if="isShow"
        class="percentage"
        :style="{ color: getColor }"
      >
        <span v-if="updateChange > 0">+</span>
        <span>{{ changeExtent }}%</span>
      </span>
    </div>
    <div
      v-if="!isShow"
      class="loading"
    >
      <v-progress-circular
        :size="20"
        color="#0589D7"
        indeterminate
      />
    </div>
  </div>
</template>

<script>
import theme from '@/assets/js/theme'
/* eslint-disable */ 
const gainTable = (unit) => {
  return {
    1: unit.dollar,
    10: unit.dime,
    100: unit.cent,
    1000: '1/1K',
    10000: '1/10K',
    100000: '1/100K',
    1000000: '1/1M',
    10000000: '1/10M',
    100000000: '1/100M'
  }
}
export default {
  props: ['issue', 'name', 'openNumber', 'gain', 'currency','code','visible','maindata'],
  mixins: [theme],
  data(){
    return{
      lotteryDetailData: [],
      updateChange: null,
      changeExtent: null,
    }
  },
  watch: {
    visible (nv) {
      if (nv) {
        this.init()
      }
    }
  },
  computed:{
    progressColor() {
      return this.baseTheme.tradeMainTextProgressColor
    },
    moreZero () {
      return this.baseTheme.tradeMainTextGetColorMoreZero
    },
    lessZero() {
      return this.baseTheme.tradeMainTextGetColorSLessZero
    },
    notEqualZero () {
      return this.baseTheme.tradeMainTextOrderColor
    },
    equalZero () {
      return this.baseTheme.tradeMainTextGetColorEqualZero
    },
    getDef () {
      return this.baseTheme.tradeMainTextGetColorDef
    },
    isShow () {
      return (this.updateChange !== null && this.lotteryDetailData.length !== 0)
    },
    getColor () {
      if (this.updateChange > 0) {
        return this.moreZero
      } else if (this.updateChange < 0) {
        return this.lessZero
      } else if (this.lotteryDetailData.length !== 0) {
        return this.notEqualZero
      } else if (this.updateChange === 0) {
        return this.equalZero
      } else {
        return this.getDef
      }
    },
  },
  methods: {
    dynamicGain (gain) {
      const unit = {
        dollar: this.$t('CHATCELL_DOLLAR'),
        dime: this.$t('TRADE_DIME'),
        cent: this.$t('TRADE_CENT')
      }
      // default 1
      return gainTable(unit)[gain || 1]
    },
    init () {
      this.lotteryDetailData = this.maindata.todayTrendDataList && this.maindata.todayTrendDataList.map(i => ([i[2] * 1000, i[0]]))
      this.updateChange = this.maindata.changePrice
      this.changeExtent = this.maindata.changeExtent
    }
  },
  created(){
    if (this.visible) {
      this.init()
    } else {
    }
  }
}
</script>

<style lang="scss" scoped>
.main {
  &__text {
    display: flex;
    justify-content: center;
    padding-right: 16px;
    padding-left: 16px;
    min-height: 60px;
    font-size: 14px;
    text-align: left;
    flex: 1;
    flex-direction: column;
  }

  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 4px;
  }

  &__titlename {
    display: inline-block;
    overflow: hidden;
    margin-right: 5px;
    // font-weight: bold;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__order {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    color: var(--tradeMainOrderColor);
  }

  &__number {
    margin-top: 6px;
    font-weight: 700;

    .gain {
      margin-left: 4px;
      font-size: 12px;

      .num {
        margin-left: 2px;
      }
    }
  }
}

.loading {
  position: absolute;
  top: 17px;
  right: 15px;
}

.issue__title {
  color: #879AAF;
}
</style>
