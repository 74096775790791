<template>
  <div class="position__wrap">
    <Locations
      :is-chase="active === 'chase'"
      :active-index="activeIndex"
    />
  </div>
</template>

<script>
import Locations from './Locations.vue'
export default {
  components: {
    Locations
  },
  props: {
    activeIndex: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      active: 'normal'
    }
  },
  methods: {
    switchTab (val) {
      this.active = val
    }
  }
}
</script>

<style lang="scss" scoped>
.position {
  &__wrap {
    width: 100%;
  }
}
</style>
