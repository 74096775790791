<template>
  <div class="item__group">
    <span class="item__itemstock base__width">
      <!-- {{ itemData.lotteryName }} -->
      {{ stockResMsg(`STOCK_${itemData.lotteryCode}`,itemData.lotteryName) }}
    </span>
    <span class="item__itemorder base__width">{{ itemData.startIssue }}</span>
    <span class="item__itemtime double__width">{{ formatTime }}</span>
    <span class="item__itemstep base__width">{{ itemData.completeCount }}</span>
    <span class="item__itemchasemoney base__width ">{{ chaseMoney[0] }}</span>
    <span class="item__itemmoney base__width">{{ chaseMoney[1] }}</span>
    <span
      class="item__itemtbones base__width"
      :class="[chaseGetType[1]]"
    >{{ chaseGetType[2] }}</span>
    <span
      class="item__itemstate small__width"
      :class="[chaseGetType[1]]"
    >{{ chaseGetType[0] }}
    </span>
  </div>
</template>

<script>
import moment from 'moment'
import { stockResMsg } from '@/utils'
export default {
  props: {
    itemData: {
      type: Object,
      required: true
    }
  },
  computed: {
    formatTime () {
      return moment(this.itemData.gmtCreated).format('YYYY-MM-DD hh:mm:ss')
    },
    chaseMoney () {
      return this.itemData.completeMoney.split('/')
    },
    chaseGetType () {
      // 0等待开奖 1未出仓 2已出仓 3已撤单 4出仓即停 5系统撤单
      // socket.js 判斷未盈利給unprofitable
      switch (this.itemData.state) {
        case '已结束':
          return [this.$t('WAREHOUSE_CHASE_NUMBER_ITEM_FULL'), 'def', '0.00']
        case '进行中':
        case 'ing':
          return [this.$t('WAREHOUSE_CHASE_NUMBER_ITEM_PENDING'), 'ing', '-']
        case '未开始':
          return [this.$t('WAREHOUSE_CHASE_NUMBER_ITEM_WAIT'), 'pending', '-']
        case 'unprofitable' :
          return [this.$t('WAREHOUSE_CHASE_NUMBER_ITEM_FULL'), 'def', '0.00']
        default:
          return [this.$t('INVEST_PROFITABLE'), 'rise', `+${Number(this.itemData.state).toFixed(2)}`]
      }
    }
  },
  methods: {
    stockResMsg
  }
}
</script>

<style lang="scss" scoped>
.item__group {
  display: flex;
  align-items: center;
  overflow: hidden;
  padding-right: 8px;
  padding-left: 16px;

  .double__width {
    flex: 3;
  }

  .base__width {
    flex: 2;
  }

  .small__width {
    padding-right: 1px;
    white-space: nowrap;
    flex: 1;
  }

  .item__itemorder {
    // flex: 1.5;
  }

  .item__itemtime {
    // flex: 1.5;
  }

  .item__itemtbones {
    &.rise {
      color: var(--accountingItemChaseRiseColor);
    }
  }

  .item__itemstate {
    &.rise {
      color: var(--accountingItemChaseColor);
    }

    &.ing {
      color: var(--accountingItemChaseIngColor);
    }

    &.pending {
      color: var(--accountingItemPendingColor);
    }
  }
}

.item__itemstock,
.item__itemchasemoney,
.item__itemmoney {
  color: var(--accountingItemChaseColor);
}
</style>
