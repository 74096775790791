import * as types from '../types'

export const security = {
  state: {
    securityList: null, // 安全中心列表
    dateType: 'today' // 交易记录时间查询 today，yesterday，sevenday
  },
  getters: {
    securityList: state => state.securityList
  },
  actions: {
    /**
     * 安全中心列表
     */
    setSecurityList ({ commit }, res) {
      commit(types.SET_SECURITY_LIST, res)
    },
    /**
     * 交易记录时间 // 今天 七天 昨天
     */
    setRecordTime ({ commit }, res) {
      commit(types.SET_DATE_TYPE, res)
    }
  },
  mutations: {
    [types.SET_SECURITY_LIST] (state, res) {
      state.securityList = res
    },
    [types.SET_DATE_TYPE] (state, res) {
      state.dateType = res
    }
  }
}
