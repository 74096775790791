<template>
  <div class="bouble__wrap">
    <div
      v-if="!onlySecond"
      ref="champion"
      class="champion__title"
      :class="{onlyChampion:onlyFirst}"
    >
      <div class="in-title">
        {{ $t('TRADE_CHAMPION') }}
      </div>
      <van-collapse
        v-model="activeChampNames"
        accordion
        class="champion-collapse"
        :class="{'is-show':filterChampionData}"
      >
        <van-collapse-item
          :title="championText"
          class="bet__inputarea"
          name="1"
        >
          <div class="champion__wrap dropdown-set">
            <div
              v-for="(item, index) in positionChampionData"
              :key="`champ-${index}`"
              class="champion__btn"
              :class="{'is-active': item.isActive}"
              @click="activeChampionBtn(index, item.type, award[index])"
            >
              <div class="item-title">
                {{ item.name }}
              </div>
            </div>
          </div>
        </van-collapse-item>
      </van-collapse>
    </div>
    <div
      v-if="!onlyFirst"
      ref="runner"
      class="runner__title"
      :class="{onlyRunner:onlySecond}"
    >
      <div class="in-title">
        {{ $t('TRADE_RUNNER') }}
      </div>
      <van-collapse
        v-model="activeRunnerNames"
        class="runner-collapse"
        :class="{'is-show':filterRunnerData}"
        accordion
      >
        <van-collapse-item
          :title="runnerText"
          name="1"
          class="bet__inputarea"
        >
          <div class="runner__title dropdown-set">
            <div
              v-for="(item, index) in positionRunnerData"
              :key="`champ-${index}`"
              class="runner__btn"
              :class="{'is-active': item.isActive}"
              @click="activeRunnerBtn(index, item.type, award[index])"
            >
              <div class="item-title">
                {{ item.name }}
              </div>
            </div>
          </div>
        </van-collapse-item>
      </van-collapse>
    </div>
    <div
      v-if="isChase"
      class="stock__unit"
      :class="[{'is-active':outlineDollar||unitNamesOpen}]"
    >
      <div class="in-title">
        {{ $t('TRADE_MULTIPLE') }}
      </div>
      <div class="order__money_chase">
        <div
          v-show="outlineDollar"
          class="select__close"
          @click="outlineDollar=false"
        />
        <div
          class="select__input other-set-select"
          :class="{'is-active':outlineDollar}"
        >
          <input
            v-model.number="chasePower"
            type="number"
            class="input-set"
            @focus="outlineDollar=true"
            @blur="outlineDollar=false"
          >
          <!--倍數 -->
          <div
            v-if="stockCentEnable"
            ref="locationunit"
            class="unit"
            :class="$i18n.locale"
          >
            <van-collapse
              v-model="unitNames"
              accordion
            >
              <!-- :class="{'is-show':filterRunnerData}" -->
              <van-collapse-item
                :title="unitText"
                name="1"
              >
                <div class="runner__title">
                  <div
                    v-for="(item, index) in unitOption"
                    :key="`champ-${index}`"
                    :class="{'is-active': unitIndex===index}"
                    @click="activeUnitBtn(index, item)"
                  >
                    {{ item.text }}
                  </div>
                </div>
              </van-collapse-item>
            </van-collapse>
          </div>
        </div>
      </div>
    </div>
    <div
      v-else
      class="stock__unit"
      :class="{'is-active':outline||unitNamesOpen}"
    >
      <div class="in-title">
        {{ $t('TRADE_MULTIPLE') }}
      </div>
      <div
        class="order__money"
        :class="{'is-active':outline}"
      >
        <input
          v-model.number="chasePower"
          type="number"
          class="input-set"
          @focus="outline=true"
          @blur="outline=false"
        >
        <div
          v-if="stockCentEnable"
          ref="locationunit"
          class="unit"
          :class="$i18n.locale"
        >
          <van-collapse
            v-model="unitNames"
            accordion
          >
            <!-- @change="unitAciveHandler" -->
            <!-- :class="{'is-show':filterRunnerData}" -->
            <van-collapse-item
              :title="unitText"
              name="1"
            >
              <div class="runner__title">
                <div
                  v-for="(item, index) in unitOption"
                  :key="`champ-${index}`"
                  :class="{'is-active':unitIndex===index}"
                  @click="activeUnitBtn(index, item)"
                >
                  {{ item.text }}
                </div>
              </div>
            </van-collapse-item>
          </van-collapse>
        </div>
      </div>
    </div>
    <div class="radio__chasenumber">
      <van-collapse
        v-model="chaseNumberNames"
        class="chase__modevancollapse penetrate-set"
      >
        <van-collapse-item
          name="1"
          class="chase__vancollapse"
        >
          <template #title>
            <div
              class="collapse__title"
            >
              <div>{{ chaseShowText }}</div>
              <van-switch
                v-model="isChase"
                class="radio__switch"
              />
            </div>
          </template>
          <div
            class="issue_length"
            :class="{'is-active':outlineDate}"
          >
            <input
              v-model.number="chaseIssues"
              type="number"
              class="input-set"
              @focus="outlineDate=true"
              @blur="outlineDate=false"
            >
            <div
              class="remarker"
              :class="$i18n.locale"
            >
              {{ $t('TRADE_EXPECT') }}
            </div>
          </div>
        </van-collapse-item>
      </van-collapse>
    </div>
    <div
      v-show="isChase"
      class="mode__wrap"
    >
      <div class="play__typewrap">
        <span
          :class="[{active:hasWinStop}]"
          @click="hasWinStop = !hasWinStop;autoBuyIfStopAfterWin = false"
        >{{ $t('TRADE_PROFIT_STOP') }}</span>
        <span
          :class="[{active:autoBuyIfStopAfterWin}]"
          @click="hasWinStop = false;autoBuyIfStopAfterWin = !autoBuyIfStopAfterWin"
        >{{ $t('TRADE_PROFIT_CYCLE') }}</span>
      </div>
      <van-collapse
        v-model="playType"
        class="chase__vancollapse"
      >
        <van-collapse-item
          name="1"
          class="playtype__collapse-item"
        >
          <template
            #title
          >
            <div
              class="double__title"
              :class="{active:hasMultiple}"
            >
              {{ $t('TRADE_DOUBLED') }}
            </div>
          </template>
          <div
            class="doble__wrap"
            :class="{'is-active':outlineDouble}"
          >
            <div class="unit__wrap">
              <div class="text">
                {{ $t('PC_TRADE_EVERY') }}
              </div>
              <div class="text expect">
                {{ $t('TRADE_EXPECT') }}
              </div>
              <div class="text">
                {{ $t('PC_TRADE_TIME') }}
              </div>
            </div>
            <!-- 期 -->
            <input
              v-model.number="chaseEach"
              type="number"
              class="number__input"
              @focus="outlineDouble = true"
              @blur="outlineDouble = false"
            >
            <!-- 倍 -->
            <input
              v-model.number="chaseEachMultiple"
              type="number"
              class="number__input"
              @focus="outlineDouble = true"
              @blur="outlineDouble = false"
            >
          </div>
        </van-collapse-item>
      </van-collapse>
    </div>
    <div class="show__info bet-show">
      <div class="item">
        <div class="itme-name">
          {{ $t('PC_TOTAL_COUNT') }}
        </div>
        <div class="item-value">
          {{ totalSelected }}&nbsp;{{ $t('TRADE_STOCK') }}
        </div>
      </div>
      <!-- <div class="item">
        <div class="itme-name">
          手續費：
        </div>
        <div class="item-value">
          0.1%
        </div>
      </div> -->
      <div class="item">
        <div class="itme-name">
          {{ $t('PC_TOTAL_MATH') }}
        </div>
        <div class="item-value">
          ¥ {{ !(Number(totalOrderMoney) === 0 || (hasMultiple && (!chaseEach || !chaseEachMultiple)) || (isChase && !chaseIssues)) ? totalOrderMoney : '0.00' }}
        </div>
      </div>
    </div>
    <div
      class="bouble__btn"
      :class="[{ hide: Number(totalOrderMoney) === 0 || (hasMultiple && (!chaseEach || !chaseEachMultiple)) || (isChase && !chaseIssues) || !chasePower ||betState}]"
      @click="confirmBet"
    >
      {{ betState ? $t('TRADE_STOP_TRADING'): $t('TRADE_BUY') }}
    </div>
    <div class="item max-income">
      <div class="itme-name">
        {{ $t('TRADE_MAX_INCOME') }}
      </div>
      <div class="item-value">
        ¥ {{ posiiblePrize }}
      </div>
    </div>
    <!-- 一般投資確認外框 -->
    <PopupBase
      ref="parentBet"
      class="parentBet"
      :slide-item="'parentBet'"
      :has-overlay="true"
      :popup-title="$t('PC_BET_DETAILS')"
    >
      <BasePopup
        :one-item="doubleDetail"
        @cancel="cancelBet"
        @confirm="submitBet"
      />
    </PopupBase>
    <!-- 追號投資確認外框 -->
    <PopupBase
      ref="chaseBet"
      class="chaseBet"
      :slide-item="'chaseBet'"
      :has-overlay="true"
      :popup-title="$t('TRADE_CHASE_CONFIRM')"
    >
      <ChaseBasePopup
        :one-item="chaseDetail"
        @cancel="cancelChase"
        @confirm="submitBet"
        @changeStopAfterWinning="updateIsStopAfterWin"
        @changeAutoBuyIfStopAfterWinning="updateAutoBuyIfStopAfterWinning"
      />
    </PopupBase>
  </div>
</template>

<script>
import pubsub from 'pubsub-js'
// A11
import { MaxRate, MaxChaseIssue, MaxChaseRate } from '@/assets/js/lottery/lotteryFun'
import lotteryAPI from '@/api/lottery'
import { mapGetters, mapState } from 'vuex'
import {
  apiResMsg2,
  playType,
  stockResMsg
} from '@/utils'
// 新共用組
import PopupBase from './PopupBase.vue'
// 一般
import BasePopup from './popup/Base.vue'
import ChaseBasePopup from './popup/Chase.vue'

function setTimeStr (arr) {
  if (arr && arr.length) {
    const list = []
    arr.forEach(v => {
      v.startTime && (v.startTime = v.startTime.split(' ')[1])
      v.endTime && (v.endTime = v.endTime.split(' ')[1])
      list.push(v)
    })
    return list
  }
  return []
}
export default {
  name: 'Locations',
  components: {
    ChaseBasePopup,
    PopupBase,
    BasePopup
  },
  props: {
    activeIndex: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      isChase: false,
      // 每股單位
      unitValue: 1,
      // 冠军選單
      positionChampionActiveArray: [],
      activeChampNames: '',
      positionChampionData: [
        { name: 0, isActive: false },
        { name: 1, isActive: false },
        { name: 2, isActive: false },
        { name: 3, isActive: false },
        { name: 4, isActive: false },
        { name: 5, isActive: false },
        { name: 6, isActive: false },
        { name: 7, isActive: false },
        { name: 8, isActive: false },
        { name: 9, isActive: false }
      ],
      // 亚军選單
      activeRunnerNames: '',
      positionRunnerActiveArray: [],
      positionRunnerData: [
        { name: 0, isActive: false },
        { name: 1, isActive: false },
        { name: 2, isActive: false },
        { name: 3, isActive: false },
        { name: 4, isActive: false },
        { name: 5, isActive: false },
        { name: 6, isActive: false },
        { name: 7, isActive: false },
        { name: 8, isActive: false },
        { name: 9, isActive: false }
      ],
      // 投资内容
      doubleDetail: {},
      chaseDetail: {},
      currentBet: null,
      // 單位選單
      unitNames: '',
      // 單位index
      unitIndex: 0,
      bankSwitch: false,
      stepOrder: 0,
      checked1: false,
      checked2: false,
      activeNames: [],
      playMode: '',
      hasWinStop: false,
      // outline樣式
      outline: false,
      outlineDollar: false,
      outlineDate: false,
      outlineTime: false,
      outlineDouble: false,
      chaseNumberNames: [],
      playType: []
    }
  },
  computed: {
    ...mapState(['newLotteryList']),
    ...mapGetters(['currentBettingIssue', 'currentStockScheme']),
    stockCentEnable () {
      return this.$store.state.siteConfig.stockCentEnable
    },
    // 語言切換
    unitOption () {
      return [
        { text: this.$t('TRADE_DALLAR'), value: 1, icon: '' },
        { text: this.$t('TRADE_DIME'), value: 0.1, icon: '' },
        { text: this.$t('TRADE_CENT'), value: 0.01, icon: '' }
      ]
    },
    posiiblePrize () {
      const orderNumber = parseInt(this.chasePower)
      const positionChampion = this.positionChampionActiveArray.length
      const positionRunner = this.positionRunnerActiveArray.length
      const award = Number(this.award && this.award[0]) || 0
      let champion = 0
      let runner = 0
      if (isNaN(orderNumber)) {
        return '0.00'
      } else if (this.isChase) {
        if (positionChampion > 0) {
          champion = positionChampion * orderNumber
        } else {
          champion = 0
        }
        if (positionRunner > 0) {
          runner = positionRunner * orderNumber
        } else {
          runner = 0
        }

        const bettingCount = champion + runner
        if (this.hasMultiple) {
          if (!this.chaseEachMultiple || !this.chaseEach) {
            return '0.00'
          }
          const chaseValue = Array(Number(this.chaseIssues)).fill('').reduce((prev, curr, index) => {
            return prev + bettingCount * this.chasePower * Math.pow(this.chaseEachMultiple, Math.floor((index) / this.chaseEach))
          }, 0)
          return (chaseValue * this.selectUnit * award).toFixed(2)
        }

        return ((Number(this.chaseIssues) * Number(this.chasePower) * bettingCount) * award * this.selectUnit).toFixed(2)
      } else {
        if (positionChampion > 0) {
          champion = positionChampion * orderNumber
        } else {
          champion = 0
        }
        if (positionRunner > 0) {
          runner = positionRunner * orderNumber
        } else {
          runner = 0
        }
        return (((champion + runner) * award) * this.selectUnit).toFixed(2)
      }
    },
    radioCheck () {
      if (this.activeNames.length === 1) {
        if (this.activeNames[0] === '0') {
          return [true, false]
        } else {
          return [false, true]
        }
      } else if (this.activeNames.length === 2) {
        return [true, true]
      } else {
        return [false, false]
      }
    },
    lCode () {
      return this.$store.state.lt?.lCode // 彩种code
    },
    currentStockData () {
      const data = this.newLotteryList
      return data ? data.filter(item => item.lotteryCode === this.lCode)[0] : {}
    },
    chaseIssues: {
      get () {
        return this.$store.state.lt.chaseConf.chaseCount
      },
      set (issue) {
        // this.$store.commit('lt_setChaseIssue', issue)
        const v = String(issue)
        // if (!(+v)) {
        //   this.$store.commit('lt_setChaseIssue', 10)
        //   return
        // }

        if (v.match(/\./ig)) {
          this.$store.commit('lt_setChaseIssue', +(v.replace(/\..*/ig, '')))
        } else {
          this.$store.commit('lt_setChaseIssue', v)
        }
        // if (issue.search(/[^\d]+/) > -1 || issue <= 0) {
        //   this.$store.commit('lt_setChaseIssue', 1)
        //   this.$store.dispatch('lt_ordinaryChase')
        // } else if (issue > MaxChaseIssue) {
        //   this.$store.commit('lt_setChaseIssue', +MaxChaseIssue)
        //   this.$store.dispatch('lt_ordinaryChase')
        //   this.msgInfo(`最多${MaxChaseIssue}期`)
        //   this.$store.state.lt.chaseConf.chaseCount = +MaxChaseIssue
        //   this.isPost = true
        //   setTimeout(() => {
        //     this.isPost = false
        //   }, 200)
        // } else {
        //   if (this.isChase) {
        //     this.$store.dispatch('lt_ordinaryChase')
        //   }
        // }

        if (issue > MaxChaseIssue) {
          this.$store.commit('lt_setChaseIssue', +MaxChaseIssue)
          this.msgAlertBig(this.$t('TRADE_MOST_EXPECT', { num: MaxChaseIssue }), this.$t('CONFIRM'))
          this.$store.state.lt.chaseConf.chaseCount = String(MaxChaseIssue)
          this.isPost = true
          setTimeout(() => {
            this.isPost = false
          }, 200)
        }
      }
    },
    // 是否开启了追号
    // isChase: {
    //   get () {
    //     return this.$store.state.lt.isChase
    //   },
    //   set (val) {
    //     this.$store.commit('lt_setIsChase', val)
    //   }
    // },
    chaseEachMultiple: {
      get () {
        return this.$store.state.lt.chaseConf.chaseEachMultiple
      },
      set (power) {
        // this.$store.dispatch('actionUpdateChaseEachMultiple', power)
        const v = String(power)
        if (!(+v) || !/^\d*$/.test(v)) {
          this.$store.dispatch('actionUpdateChaseEachMultiple', '')
          return
        }

        if (v.match(/\./ig)) {
          this.$store.dispatch('actionUpdateChaseEachMultiple', +(v.replace(/\..*/ig, '')))
        } else {
          if ((+v) > MaxChaseRate) {
            this.$store.dispatch('actionUpdateChaseEachMultiple', MaxChaseRate)
            this.msgAlertBig(`最多${MaxChaseRate}期`, '确认')
          } else {
            this.$store.dispatch('actionUpdateChaseEachMultiple', +(v))
          }
        }
        // if (String(power).search(/[^\d]+/) > -1 || power <= 0) {
        //   this.$store.dispatch('actionUpdateChaseEachMultiple', 2)
        // }
      }
    },
    chaseEach: {
      get () {
        return this.$store.state.lt.chaseConf.chaseEach
      },
      set (each) {
        // this.$store.dispatch('actionUpdateChaseEach', each)
        const v = String(each)
        if (!(+v) || !/^\d*$/.test(v)) {
          this.$store.dispatch('actionUpdateChaseEach', '')
          return
        }

        if (v.match(/\./ig)) {
          this.$store.dispatch('actionUpdateChaseEach', +(v.replace(/\..*/ig, '')))
        } else {
          if ((+v) > MaxChaseIssue) {
            this.$store.dispatch('actionUpdateChaseEach', MaxChaseIssue)
            this.msgAlertBig(`最多${MaxChaseIssue}倍`, '确认')
          } else {
            this.$store.dispatch('actionUpdateChaseEach', +(v))
          }
        }
        // if (String(each).search(/[^\d]+/) > -1 || each <= 0) {
        //   this.$store.commit('actionUpdateChaseEach', 2)
        // }
      }
    },
    hasMultiple: {
      get () {
        return this.$store.state.lt.hasMultiple
      },
      set (val) {
        this.$store.dispatch('actionUpdateHasMultiple', val)
      }
    },
    totalOrderMoney () {
      const bettingCount = this.positionChampionActiveArray.length + this.positionRunnerActiveArray.length // 注數
      if (this.isChase) {
        if (this.hasMultiple) {
          const chaseValue = Array(Number(this.chaseIssues)).fill('').reduce((prev, curr, index) => {
            return prev + bettingCount * this.chasePower * Math.pow(this.chaseEachMultiple, Math.floor((index) / this.chaseEach))
          }, 0)
          return (chaseValue * this.selectUnit).toFixed(2)
        }
        return ((Number(this.chaseIssues) * Number(this.chasePower) * bettingCount) * this.selectUnit).toFixed(2)
      }
      return ((Number(this.chasePower) * bettingCount) * this.selectUnit).toFixed(2)
    },
    // 每股金額
    chasePower: {
      get () {
        return this.$store.state.lt.chaseConf.power
      },
      set (power) {
        const v = String(power)
        if (!(+v) || !/^\d*$/.test(v)) {
          this.$store.commit('lt_setChasePower', '')
          return
        }

        if (v.match(/\./ig)) {
          this.$store.commit('lt_setChasePower', +(v.replace(/\..*/ig, '')))
        } else {
          this.$store.commit('lt_setChasePower', +(v))
        }

        if (Number(v) > +MaxRate) {
          this.$store.commit('lt_setChasePower', +MaxRate)
          this.msgAlertBig(this.$t('TRADE_MOST') + MaxRate + this.$t('TRADE_TIMES'), this.$t('CONFIRM'))
          this.$store.state.lt.chaseConf.power = 10000
          this.isPost = true
          setTimeout(() => {
            this.isPost = false
          }, 200)
        }
      }
    },
    mode () {
      return this.$store.state.lt && this.$store.state.lt.mode.mode
    },
    // 每股金額單位
    selectUnit: {
      get () {
        return this.$store.state.lt.chaseConf.bettingUnit
      },
      set (val) {
        this.$store.commit('lt_setChaseBettingUnit', val)
      }
    },
    totalSelected () {
      return this.positionChampionActiveArray.length + this.positionRunnerActiveArray.length
    },
    championNumber () {
      return this.positionChampionActiveArray.map((item) => item.name).join(' ')
        ? this.positionChampionActiveArray.map((item) => item.name).join(' ')
        : ''
    },
    championText: function () {
      return this.positionChampionActiveArray.map((item) => item.name).join(',')
        ? this.positionChampionActiveArray.map((item) => item.name).join(',')
        : this.$t('PC_NOT_SELECTED')
    },
    runnerNumber () {
      return this.positionRunnerActiveArray.map((item) => item.name).join(' ')
        ? this.positionRunnerActiveArray.map((item) => item.name).join(' ')
        : ''
    },
    runnerText: function () {
      return this.positionRunnerActiveArray.map((item) => item.name).join(',')
        ? this.positionRunnerActiveArray.map((item) => item.name).join(',')
        : this.$t('PC_NOT_SELECTED')
    },
    unitText () {
      return this.unitOption[this.unitIndex].text
    },
    award () {
      if (this.mode === 'A11') {
        return [this.$store.state.lt.award]
      }

      if (this.mode === 'D11') {
        let oddsList = []
        const order = [4, 2, 4]

        order.forEach((item, index) => {
          oddsList = [...oddsList, ...Array(item).fill(this.$store.state.lt.award[index])]
        })

        return oddsList
      }

      return []
    },
    autoBuyIfStopAfterWin: {
      get () {
        return this.$store.state.lt.chaseConf.autoBuyIfStopAfterWinning
      },
      set (val) {
        this.$store.commit('SetautoBuyIfStopAfterWin', val)
      }
    },
    filterChampionData () {
      const filter = this.positionChampionData.filter(item => {
        return item.isActive === true
      })
      return filter.length
    },
    filterRunnerData () {
      const filter = this.positionRunnerData.filter(item => {
        return item.isActive === true
      })
      return filter.length
    },
    siteConfig () {
      return this.$store.state.siteConfig
    },
    // 0全開 1冠軍 2亞軍
    // 顯示冠軍與否
    onlyFirst () {
      return this.siteConfig[`stockPlayType${this.mode}`] === 1
    },
    // 顯示亞軍與否
    onlySecond () {
      return this.siteConfig[`stockPlayType${this.mode}`] === 2
    },
    chaseShowText () {
      return this.isChase === false ? this.$t('CHASE') : this.$t('PC_CHASE_DATE')
    },
    dropdownState () {
      const { activeChampNames, activeRunnerNames, unitNames } = this
      return {
        activeChampNames, activeRunnerNames, unitNames
      }
    },
    unitNamesOpen () {
      return this.unitNames.length > 0
    },
    betState () {
      return this.$store.state.betState
    }
  },
  watch: {
    playType (newValue) {
      console.log(newValue.length)
      if (newValue.length > 0) {
        this.hasMultiple = true
      } else {
        this.hasMultiple = false
      }
    },
    isChase (newValue) {
      if (newValue === false) {
        this.playType = []
      }
    },
    dropdownState: {
      handler: function (value) {
        // console.log(value)
        if (value.unitNames !== '' && (value.activeChampNames !== '' || value.activeRunnerNames !== '')) {
          setTimeout(() => {
            this.unitNames = ''
            this.outline = false
          }, 300)
        }
      },
      deep: true
    },
    activeIndex: {
      handler: function (value) {
        if (value === 0) {
          this.unitNames = ''
          this.outline = false
        }
      }
    }
  },
  mounted () {
    document.addEventListener('click', this.bodyCloseChampion)
    if (this.unitOption.length > 0) {
      document.addEventListener('mousedown', this.mousedownHandler)
    }
  },
  beforeDestroy () {
    document.removeEventListener('click', this.bodyCloseChampion)
    document.removeEventListener('mousedown', this.mousedownHandler)
  },
  methods: {
    stockResMsg,
    mousedownHandler (e) {
      if (this.unitOption.length === 0) return
      const clickedEl = e.target
      this.$nextTick(() => {
        const unit = this.$refs.locationunit
        if (unit && !unit.contains(clickedEl)) {
          this.unitNames = ''
        }
      })
    },
    updateAutoBuyIfStopAfterWinning (val) {
      if (val) {
        this.chaseDetail = {
          ...this.chaseDetail,
          autoBuyIfStopAfterWinning: val,
          isStopAfterWin: !val
        }
        this.autoBuyIfStopAfterWin = val
        this.hasWinStop = !val
      } else {
        this.chaseDetail = {
          ...this.chaseDetail,
          autoBuyIfStopAfterWinning: val
        }
        this.autoBuyIfStopAfterWin = val
      }
    },
    updateIsStopAfterWin (val) {
      if (val) {
        this.chaseDetail = {
          ...this.chaseDetail,
          isStopAfterWin: val,
          autoBuyIfStopAfterWinning: !val
        }
        this.hasWinStop = val
        this.autoBuyIfStopAfterWinning = !val
      } else {
        this.chaseDetail = {
          ...this.chaseDetail,
          isStopAfterWin: val
        }
        this.hasWinStop = val
      }
    },
    cancelBet () {
      this.$refs.parentBet && this.$refs.parentBet.closePopupHandler()
    },
    cancelChase () {
      this.$refs.chaseBet && this.$refs.chaseBet.closePopupHandler()
    },
    submitBet () {
      this.currentBet && this.currentBet()
    },
    async confirmBet () {
      this.curPlayName = '' // 关闭选项
      // 普通投注
      let xBasket
      let bettingCount
      let totalAmount
      // let betDetail
      const bettingIssue = this.currentBettingIssue
      if (this.isChase) {
        const totalInvestment = [...this.positionChampionActiveArray, ...this.positionRunnerActiveArray]
        bettingCount = totalInvestment.length // 注數
        const eachInfo = {
          bettingNumber: `${this.championNumber || '-'},${this.runnerNumber || '-'}`,
          bettingCount
        }
        this.$store.commit('setChaseBettingCount', bettingCount)
        this.$store.commit('setChaseEachInfo', [eachInfo])
        await this.$store.dispatch('actionGetChaseScheme', this.lCode)
        const scheme = this.currentStockScheme
        const isStopAfterWin = this.hasWinStop
        const startIssue = scheme[0].bettingIssue
        const autoBuyIfStopAfterWinning = this.autoBuyIfStopAfterWin
        this.chaseDetail = {
          startIssue,
          betDetail: `${playType[1]}`,
          bettingCount,
          championNumber: this.championNumber,
          runnerNumber: this.runnerNumber,
          bettingAmount: (this.currentStockScheme.reduce((prev, curr) => prev + +curr.bettingAmount, 0)).toFixed(2),
          chaseEachMultiple: this.chaseEachMultiple,
          chaseEach: this.chaseEach,
          lotteryName: this.currentStockData.lotteryName,
          lotteryCode: this.lCode,
          countryCode: this.currentStockData.country,
          isStopAfterWin,
          autoBuyIfStopAfterWinning,
          scheme,
          prizeTime: this.$store.state.stockIssueData.nowBettingEndTime,
          unitTime: this.$store.state.stockIssueData.unitTime,
          hasMultiple: this.hasMultiple
        }
        this.$refs.chaseBet && this.$refs.chaseBet.openPopupHandler()
        this.currentBet = () => {
          this.$store.dispatch('actionChase', { isStopAfterWin: this.hasWinStop, autoBuyIfStopAfterWinning: this.autoBuyIfStopAfterWin, startIssue, bettingCount, lotteryName: this.currentStockData.lotteryName, selectTypeShowText: this.$t('TRADE_POSITIONING'), playDetailCode: `${this.lCode}${this.mode}`, lotteryCode: this.lCode, initializationData1: this.initializationData1 })
          // setTimeout(() => {
          //   this.$refs.okDialog.openPopWindow()
          // }, 500)
          this.accountSelectType = 'type1'// 跳頁跳到追號使用
          this.$store.commit('SET_RECORD_BTN_ACTIVE', 1)
          this.hasStockInvest = false
          this.cancelChase()
        }
      } else {
        // 投资组合
        const totalInvestment = [...this.positionChampionActiveArray, ...this.positionRunnerActiveArray]
        if (totalInvestment.length) {
          bettingCount = totalInvestment.length // 注數
          totalAmount = this.totalOrderMoney
          // if (this.positionChampionActiveArray.length) {
          //   betDetail = this.positionChampionActiveArray.map(i => i.name).join(' ')
          // } else {
          //   betDetail = '-'
          // }

          // if (this.positionRunnerActiveArray.length) {
          //   betDetail += `,${this.positionRunnerActiveArray.map(i => i.name).join(' ')}`
          // } else {
          //   betDetail += ',-'
          // }

          xBasket = {
            lotteryCode: this.lCode,
            playDetailCode: `${this.lCode}${this.mode}`,
            bettingNumber: `${this.championNumber || '-'},${this.runnerNumber || '-'}`,
            bettingCount,
            bettingAmount: Number(this.totalOrderMoney),
            bettingPoint: this.$store.state.lt.Rebate.STOCK,
            bettingUnit: this.selectUnit,
            bettingIssue,
            graduationCount: 1
          }

          this.doubleDetail = {
            bettingIssue,
            betDetail: `${playType[1]}`,
            bettingCount,
            championNumber: this.championNumber,
            runnerNumber: this.runnerNumber,
            bettingAmount: this.totalOrderMoney,
            bettingSingle: Number(this.chasePower).toFixed(2),
            lotteryName: this.currentStockData.lotteryName,
            lotteryCode: this.lCode,
            countryCode: this.currentStockData.country
          }

          this.$refs.parentBet && this.$refs.parentBet.openPopupHandler()
        }

        // 保存分享数据
        // const sharedata = {
        //   ...xBasket,
        //   lotteryName: this.currentStockData.lotteryName,
        //   lotteryType: 'STOCK',
        //   betDetail: `[${playType[this.selectType]}] ${betDetail}`
        // }
        // this.$store.state.meelineState.shareData = {
        //   type: 1,
        //   username: this.userInfo && this.userInfo.userName,
        //   data: [sharedata]
        // }

        this.currentBet = () => {
          // this.$store.dispatch('actionSetHasLimitLoading', true)
          this.$store.dispatch('setInterfaceLoading', true)
          lotteryAPI.addBetting({ bettingData: JSON.stringify([xBasket]) }).then(ref => {
            this.$toast.clear()

            // 限制投注訊息取消
            if (ref.code !== -40) {
              this.$store.dispatch('setInterfaceLoading', false)
            // this.$store.dispatch('actionSetHasLimitLoading', false)
            }

            if (ref.code === 1) {
              this.$store.dispatch('actionSetUserBalance')
              const stock = `STOCK_${this.currentStockData.lotteryCode}`
              this.$store.dispatch('actionUpdateNotifyData', {
                // name: this.currentStockData.lotteryName,
                name: this.stockResMsg(stock, this.currentStockData.lotteryName),
                issue: bettingIssue.slice(4),
                playDetail: this.$t('PC_POSITION_DISC', { num: bettingCount, total: totalAmount }),
                amount: totalAmount
              })
              this.$store.dispatch('actionSetHasNotify', { show: true, type: 'notice' })

              setTimeout(() => {
                const select = this.$refs.okDialog
                select && select.openPopWindow()
              }, 500)
              this.$store.commit('SET_RECORD_BTN_ACTIVE', 0)
              this.accountSelectType = 'type2'// 跳頁跳到一般使用
              // 定位膽一般交易

              pubsub.publish('confirm-bet', 'doubletype')
            } else {
              this.$store.dispatch('actionUpdateNotifyData', { msg: apiResMsg2(ref) })
              this.$store.dispatch('actionSetHasNotify', { show: true, type: 'error', msg: apiResMsg2(ref) })
            }

            if (ref.code === -9) {
            // 清除rebate
              this.reRebate()
              this.$store.dispatch('actionGetRebate', true)
            } else if (ref.code === -33) {
              return false
            } else if (ref.code === -40) {
            // 限制投注
            } else {
              localStorage.removeItem('lotteryConfig')
              localStorage.removeItem('newLotteryConfig')
              localStorage.removeItem('newLotteryList')
              lotteryAPI.lotteryConfig().then(ref => {
                const lotteryArr = ref.data
                const LotteryConfig = setTimeStr(lotteryArr.lotteryConfig)
                const LotteryList = setTimeStr(lotteryArr.lotteryList)
                this.$store.dispatch('actionsSetNewLotteryList', LotteryList)
                this.$store.dispatch('actionsSetNewLotteryConfig', LotteryConfig)
                this.LotteryList = LotteryList
                this.LotteryConfig = LotteryConfig
                localStorage.setItem('newLotteryConfig', JSON.stringify(LotteryConfig))
                localStorage.setItem('newLotteryList', JSON.stringify(LotteryList))
              })
              if ((ref.msg).indexOf('期号错误') > -1) {
                localStorage.removeItem('newlotteryPlan' + this.lCode)
              } else if ((ref.msg).indexOf('余额不足') > -1) {
                this.reRebate()
                this.$store.dispatch('actionGetRebate', true)
              } else if ((ref.msg).indexOf('投资失败') > -1) {
                this.msgAlertBig(this.$t('TRADE_STOCK_CONFIRMBET_UNIT_ERROR_REFRESH'), this.$t('CONFIRM')).then(ref => {
                  location.reload()
                }).catch(() => { })
              }
            }

            this.initializationData1()
          })
          this.hasStockInvest = false
          this.cancelBet()
        }
      }
    },
    // 重置
    initializationData1 () {
      // const select = this.$refs.numberKeyboard
      // select.resetValue()
      this.championActiveArray = []
      this.positionChampionActiveArray = []
      this.runnerActiveArray = []
      this.positionRunnerActiveArray = []
      this.positionChampionData = this.positionChampionData.map(i => ({ name: i.name, isActive: false }))
      this.positionRunnerData = this.positionRunnerData.map(i => ({ name: i.name, isActive: false }))
      // chase
      this.chaseNumberNames = []
      this.isChase = false
      this.hasMultiple = false
      this.hasWinStop = false
      this.autoBuyIfStopAfterWin = false
      this.chaseIssues = '10'
      this.chasePower = ''
      this.chaseEach = 2
      this.chaseEachMultiple = 2
      // unit
      // this.inputPlaceholderText = '单位 : 元'
      // this.calcUnitText = '元'
      this.selectUnit = 1 // 預設元
      this.unitIndex = 0// 預設選單

      // 切換玩法清除unit
      // const select = this.$refs.selectType
      // select && select.onSelectFn1(0)
      // 清除input Placeholder
      //
    },
    activeChampionBtn (index, type, odds) {
      // 最多選擇 6 個
      if (this.positionChampionActiveArray.length >= 6 && !this.positionChampionData[index].isActive) {
        this.msgAlertBig(this.$t('TRADE_STOCK_ACTIVE_CHAMPION_STATE'), this.$t('CONFIRM'))
        return
      }

      this.positionChampionData[index].isActive = !this.positionChampionData[index].isActive
      this.positionChampionActiveArray = this.positionChampionData.filter(item => item.isActive)
    },
    activeRunnerBtn (index, type, odds) {
      if (this.positionRunnerActiveArray.length >= 6 && !this.positionRunnerData[index].isActive) {
        this.msgAlertBig(this.$t('TRADE_STOCK_ACTIVE_CHAMPION_STATE'), this.$t('CONFIRM'))
        return
      }
      this.positionRunnerData[index].isActive = !this.positionRunnerData[index].isActive
      this.positionRunnerActiveArray = this.positionRunnerData.filter(item => item.isActive)
    },
    bodyCloseChampion (e) {
      if (this.$refs.champion && !this.$refs.champion.contains(e.target)) {
        this.activeChampNames = ''
        // console.log('close champion')
      }
      if (this.$refs.runner && !this.$refs.runner.contains(e.target)) {
        this.activeRunnerNames = ''
        // console.log('close runner')
      }
    },
    selectUnitHandler (value) {
      // 單位
      this.selectUnit = value
    },
    activeUnitBtn (index, item) {
      this.unitNames = ''// 關閉選單
      console.log(index, item)
      this.unitIndex = index
      this.selectUnitHandler(item.value)
      this.outline = false
    }
    // unitAciveHandler () {
    //   this.outline = !this.outline
    // }
  }
}
</script>

<style lang="scss" scoped>
@import './Locations.scss';
</style>
