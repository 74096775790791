import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

const opts = {
  theme: {
    dark: true,
    themes: {
      dark: {
        primary: '#879AAF',
        secondary: '#FFFFFF',
        accent: '#0589D7'
      }
    }
  }
}

export default new Vuetify(opts)
