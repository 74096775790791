<template>
  <button
    class="btn"
    :style="[{'width':width+'px'},{'height':height+'px'}]"
    :class="{'btn-success':success,'disable':disabled}"
    @click="$emit('click')"
  >
    {{ text }}
  </button>
</template>
<script>
export default {
  props: {
    text: {
      type: String,
      default: ''
    },
    width: {
      type: Number,
      default: undefined
    },
    height: {
      type: Number,
      default: undefined
    },
    success: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
}
</script>
<style lang="scss" scoped>
.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: var(--baseBtnColor);
  border: var(--baseBtnBorder);
  border-radius: 4px;

  &:hover {
    background-color: var(--baseBtnHoverColor);
  }

  &-success {
    color: var(--baseBtnSuccessColor);
    background-color: var(--baseBtnSuccessBgc);
    border-color: transparent;

    &:hover {
      background-color: var(--baseBtnSuccessHoverBgc);
    }
  }
  &.disable{
    opacity: 0.35;
    pointer-events: none;
  }
}
</style>
