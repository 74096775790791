/* eslint-disable */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from '@/plugins/vuetify'
import '@/components/global/_globals'
import "echarts";
import ECharts from 'vue-echarts'
import { use } from 'echarts/core'
import Vant from 'vant';
import 'vant/lib/index.css';
import Util from '@/utils'
import validator from './plugins/validator'

import i18n from '@/utils/i18n'
// import '@/assets/scss/tailwind.css'; // 太多版面要修

// import ECharts modules manually to reduce bundle size
import { CanvasRenderer } from 'echarts/renderers'
import { BarChart } from 'echarts/charts'
import { GridComponent, TooltipComponent } from 'echarts/components'
import 'default-passive-events'
import lotteryApi from '@/api/lottery'
import { setTimeStr } from '@/utils'

Vue.use(Vant);
Vue.use(validator);

use([
  CanvasRenderer,
  BarChart,
  GridComponent,
  TooltipComponent,
])

// register globally (or you can do it locally)
Vue.component('v-chart', ECharts)
Vue.use(Util)
Vue.config.productionTip = false

lotteryApi.getHotLottery().then((res) => {
  // 防呆，避免热门没资料
  if (res.data&&res.data[0]) {
    store.replaceState({
      ...store.state,
      hasHot: true,
      defaultStock: res.data[0].lotteryCode,
      currentTab: res.data[0].lotteryCode,
      chartTabData: [
        { name: '', id: '01', code: res.data[0].lotteryCode }
      ],
    })
    new Vue({
      router,
      store,
      vuetify,
      i18n,
      render: h => h(App)
    }).$mount('#app')
  } else {
    lotteryApi.lotteryConfig().then(res => {
      if (res.code === 1) {
        const LotteryList = setTimeStr(res.data.lotteryList)
        const LotteryConfig = setTimeStr(res.data.lotteryConfig)
        store.dispatch('actionsSetNewLotteryList', LotteryList)
        store.dispatch('actionsSetNewLotteryConfig', LotteryConfig)
        const areaConfig = []
        res.data.lotteryList.forEach(i => {
          if (areaConfig.includes(i.area)) {
            return
          }

          areaConfig.push(i.area)
        })
        const chineseNameTable = {
          Asia: 'TRADE_ASIA',
          Europe: 'TRADE_EUROPE',
          'North America': 'TRADE_NORTH_AMERICA',
          'South America': 'TRADE_SOUTH_AMERICA',
          Oceania: 'TRADE_OCEANIA',
          VirtualCurrency: 'TRADE_VIRTUALCURRENCY'
        }

        const countryName = areaConfig.map((item) => ({ name: chineseNameTable[item], area: item }))
        const lotteryList = res.data.lotteryList || []
        let countryList = [{ name: 'INVEST_ALL', area: 'all' }].concat(countryName)

        // const obj = {}
        // obj[this.cacheDate.allLotteryDataFront] = res.data
        // localStorage.setItem('lotteryConfig', JSON.stringify(obj))

        store.replaceState({
          ...store.state,
          hasHot: false,
          countryList,
          tabActive: 'all',
          defaultStock: res.data.lotteryList[0].lotteryCode,
          currentTab: res.data.lotteryList[0].lotteryCode,
          chartTabData: [
            { name: '', id: '01', code: res.data.lotteryList[0].lotteryCode }
          ],
        })
        new Vue({
          router,
          store,
          vuetify,
          i18n,
          render: h => h(App)
        }).$mount('#app')
      } else {
        alert("资料错误，请联络前端专业技术人员")
      }
    })
  }
})
