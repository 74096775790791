<template>
  <div
    class="wallet__dropdownwrap"
  >
    <van-collapse
      :value="activeUserAccount"
      accordion
      class="wallet__dropdown"
    >
      <van-collapse-item
        class="wallet__vancollapseitem"
        name="0"
        title=" "
      >
        <div class="wallet__showwrap">
          <div class="userinfo">
            <div class="userinfo__icon">
              <img
                :src="checkInAvator"
                alt=""
              >
            </div>
            <div class="userinfo__text">
              <div class="userinfo__name">
                {{ selectUserName }}
              </div>
              <div class="userinfo__rank">
                <span class="userinfo__rankicon">
                  <img
                    src="/static/images/Layout/rank-icon.svg"
                    alt=""
                  >
                </span>
                <span class="userinfo__ranklevel">
                  VIP {{ selectGrade }}
                </span>
                <span class="userinfo__rankname">
                  {{ filterLevelTitle }}
                </span>
              </div>
            </div>
          </div>
          <div class="link__item">
            <!-- <router-link
              v-for="link in linkItem"
              :key="link.name"
              class="item"
              tag="div"
              :to="link.to"
            >
              {{ link.name }}
            </router-link> -->
          </div>
          <div
            class="logout"
            @click.stop="logout"
          >
            {{ $t('SING_OUT') }}
          </div>
        </div>
      </van-collapse-item>
    </van-collapse>
  </div>
</template>

<script>
import apiUser from '@/api/users'
import { imgAvator } from '@/filters/index'
export default {
  props: {
    activeUserAccount: {
      type: String,
      default: '',
      required: true
    }
  },
  data () {
    return {
      userInfo: null,
      LevelList: []
    }
  },
  computed: {
    linkItem () {
      return [
        {
          name: this.$t('ACCOUNT_PERSONAL_INFORMATION'),
          to: 'about'
        },
        {
          name: this.$t('LAOUT_SAFE_CONFIRM'),
          to: 'about'
        },
        {
          name: this.$t('SECURITY_BANK_CARD'),
          to: 'about'
        }
      ]
    },
    checkInAvator () {
      // this.userInfo.avatar 没图片则为-1
      const emptyShow = this.userInfo.avatar.lastIndexOf('.') !== -1
      return this.userInfo && emptyShow
        ? imgAvator(this.userInfo.avatar)
        : '/static/images/memberCenter/account.svg'
    },
    filterLevelTitle () {
      const grade = this.userInfo?.grade
      const filterLevel = this.LevelList && this.LevelList.filter(item => {
        return item.grade === grade
      }).map(item => {
        return item.title
      }).join('')
      return filterLevel
    },
    selectUserName () {
      return this.userInfo ? this.userInfo?.userName : 'NO_DATA'
    },
    selectGrade () {
      return this.userInfo ? this.userInfo?.grade : 'NO_DATA'
    }
  },
  created () {
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
    this.LevelList = JSON.parse(localStorage.getItem('upgradeConfig'))
  },
  methods: {
    logout () {
      apiUser.logout().then((res) => {
        localStorage.removeItem('unReadMessage')
        localStorage.removeItem('getUnReadNum')
        localStorage.removeItem('checkAnnouncement')
        localStorage.removeItem('userInfo')
        localStorage.setItem('siteConfig', 0)
        const siteConfig = Object.assign(JSON.parse(JSON.stringify(this.$store.state.siteConfig)), { logoMobile: '' })
        if (this.$store.state.isApp) {
          this.$store.dispatch('setSiteConfig', siteConfig)
        }
        this.$router.push({ name: 'UserLogin' })
        this.$store.dispatch('setUserInfo', { login: 0 })
        this.$store.dispatch('setSecurityList', null)
        this.$store.dispatch('actionLogout', this.$store.state.defaultStock)

        this.$dialog.close()
      })
    }
  }
}

</script>

<style lang="scss" scoped>
.wallet {
  &__dropdownwrap {
    width: 100%;
    height: 100%;
    // border: 1px solid #F00;

    &::v-deep {
      .van-hairline--top-bottom {
        &::after {
          display: none;
        }
      }
    }
  }

  &__dropdown {
    height: 100%;
  }

  &__vancollapseitem {
    position: relative;
    width: 100%;
    height: 100%;
    // display: none;
    // margin-right: 12px;
    // border: 1px solid #F00;

    &::v-deep {
      .van-collapse-item__wrapper {
        position: fixed;
        border-radius: 4px;
        z-index: 20;
        box-shadow: var(--dropdownBoxShadow);
        transform: translateX(-82.5%) translateY(-1px);
        transition-duration: 0.1s;
      }

      .van-cell {
        display: flex;
        align-items: center;
        padding: 0;
        height: 100%;
        color: var(--dropdownVancellColor);
        background-color: transparent;

        &::after {
          display: none;
        }
      }

      .van-collapse-item__content {
        padding: 0;
        width: 280px;
        background-color: var(--dropdownContentBgc);
        border: var(--dropdownBorder);
        border-radius: 4px;
      }

      .van-icon {
        position: relative;
        display: none;
        width: 12px;
        height: 12px;

        // &::before {
        //   display: none;
        // }

        // &::after {
        //   position: absolute;
        //   top: 0;
        //   left: 0;
        //   width: 100%;
        //   height: 100%;
        //   content: '';
        //   background-image: url('/public/static/images/Layout/triangle.svg');
        // }
      }

      // .van-collapse-item__title--expanded {
      //   color: #0589D7;

      //   .van-icon {
      //     &::after {
      //       position: absolute;
      //       top: 0;
      //       left: 0;
      //       width: 100%;
      //       height: 100%;
      //       content: '';
      //       background-image: url('/public/static/images/Layout/triangle-active.svg');
      //     }
      //   }
      // }
    }
  }
}

.show__wallet {
  display: flex;
  align-items: center;
  padding-right: 16px;
  padding-left: 16px;
  width: 100%;
  height: 60px;
  border-bottom: var(--dropdownTitleBorder);

  .type-set {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
}

.wallet {
  &__title {
    font-size: 12px;
    line-height: 17px;
    color: var(--dropdownInfoColor);
  }

  &__info {
    font-size: 16px;
    line-height: 22px;
    color: var(--dropdownItemColor);
  }
}

.link__item {
  .item {
    padding-left: 16px;
    height: 36px;
    font-size: 14px;
    color: var(--dropdownInfoColor);
    line-height: 20px;
    line-height: 36px;

    &:hover {
      background-color: var(--dropdownHoverItemColor);
    }
  }
}

.userinfo {
  display: flex;
  align-items: center;
  padding-left: 16px;
  height: 60px;
  // border: 1px solid #F00;

  &__icon {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    width: 32px;
    height: 32px;
    border-radius: 50%;

    >img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: fill;
    }
  }

  &__name {
    font-size: 16px;
    line-height: 22px;
    font-weight: 700;
    color: var(--dropdownMainTextColor);
  }

  &__text {
    margin-left: 12px;
    // border: 1px solid #F00;
  }

  &__rank {
    display: flex;
    align-items: center;
    font-size: 12px;
  }

  &__rankicon {
    margin-right: 4px;
    width: 16px;
    height: 16px;
  }

  &__ranklevel {
    margin-right: 8px;
    color: var(--dropdownRankLevelColor);
    line-height: 17px;
  }

  &__ranknam {
    line-height: 17px;
    color: var(--dropdownInfoColor);
  }
}

.logout {
  @include confirm_btn_1;

  height: 40px;
  font-size: 14px;
  text-align: center;
  line-height: 40px;
  border-radius: 0 0 4px 4px;
}
</style>
