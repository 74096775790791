import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import manageApi from '@/api/manage'
import userApi from '@/api/users'
import store from '@/store'
import { Dialog } from 'vant'
import i18n from '@/utils/i18n'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
  // scrollBehavior (to, from, savedPosition) {
  //   if (savedPosition) {
  //     return savedPosition
  //   } else {
  //     return { x: 0, y: 0 }
  //   }
  // }
})

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch((error) => {
    if (error.from.name === error.to.name) return
    console.log(error)
    Dialog.alert({
      showCancelButton: false,
      className: 'alert-big',
      message: error,
      title: i18n.tc('PRIVACY_HIT'),
      confirmButtonText: i18n.tc('CONFIRM')
    })
  })
}

let cacheTime = localStorage.getItem('cacheTime')

function getCacheTime (fun) {
  if (!cacheTime) {
    manageApi.getFrontCacheUpdatedAt().then((res) => {
      if (res.code === 1) {
        cacheTime = res.data.tenantConfig
        localStorage.setItem('cacheTime', JSON.stringify(res.data.tenantConfig))
        localStorage.setItem('pageUpdateTime', res.data.pageUpdateTimeMobile)
      }
      fun(res.data ? res.data.tenantConfig : 0)
    })
  } else {
    fun(JSON.parse(JSON.stringify(cacheTime)))
  }
}

let tenantName // 站名
const arr = [3, 4, 5, 6, 7, 8, 9, 10, 11]
const updateCol = arr[Math.floor(Math.random() * arr.length)] // 用来计数路由切换的次数，相同时候强制更新，避免并发
let num = 0

function getSiteConfig (fun, time) {
  const siteConfig = localStorage.getItem('siteConfig')
  if (!siteConfig || !JSON.parse(siteConfig)[time]) {
    userApi.getTenantConfig({}, () => {
      const siteInfo = Object.values(JSON.parse(localStorage.getItem('siteConfig')))[0]
      tenantName = siteInfo.tenantName || 'welcome'
      fun(siteInfo)
    }).then((res) => {
      if (res.code === 1) {
        store.dispatch('setSiteConfig', res.data)
        const obj = {}
        obj[time] = res.data
        localStorage.setItem('siteConfig', JSON.stringify(obj))
        tenantName = res.data && res.data.tenantName
      }

      if (res.code === -7 || res.code === -12) {
        tenantName = null
      }

      fun(res.data)
    })
  } else {
    const siteInfo = Object.values(JSON.parse(localStorage.getItem('siteConfig')))[0]
    tenantName = siteInfo.tenantName || 'welcome'
    fun(siteInfo)
  }
}

export function clear () {
  localStorage.removeItem('newRebateSTOCK')
}

router.beforeEach((to, from, next) => {
  const isUpdated = JSON.parse(localStorage.getItem('updated')) // 判断是否强制更新
  if (isUpdated) {
    ++num
    let paths = to.fullPath
    // 判断是否在投注页面
    paths = paths.match(/lottery\//)
    // console.log(num, updateCol)
    if (num === updateCol || paths) {
      localStorage.setItem('updated', false)
      // router.go(0)
      document.location.href = document.location.href + '?' + updateCol
    }
  }

  if (to.name === 'service') {
    next()
  } else if (to.name === '404') {
    document.title = to.meta.title
    next()
  } else {
    getCacheTime((time) => {
      getSiteConfig((siteInfo) => {
        // 網頁標題
        document.title = tenantName
        if (to.name === 'uuLive' || to.name === 'live') {
          if (!siteInfo.isHideNavLive) return
        }
        // const test = to.matched.some(record => record.meta.requiresAuth)
        // console.log('108', test)
        if (to.matched.some(record => record.meta.requiresAuth)) {
          const userInfo = localStorage.getItem('userInfo') && JSON.parse(localStorage.getItem('userInfo'))
          // console.log(userInfo)
          if (!userInfo) {
            clear()
            next({
              path: '/login',
              query: { redirect: to.fullPath }
            })
          } else {
            // if (strMatch(to.fullPath, 'lottery')) return
            if (!userInfo.login) {
              Dialog.confirm({
                showCancelButton: false,
                className: 'notice-big',
                title: i18n.tc('PRIVACY_HIT'),
                message: i18n.tc('AXIOS_STATE_LONG_TIME'),
                confirmButtonText: i18n.tc('CONFIRM')
              }).then(() => {
                localStorage.setItem('userInfo', 0)
                clear()
                next({
                  path: '/login',
                  query: { redirect: to.fullPath }
                })
              }).catch(() => {
                // on cancel
              })
            } else {
              if (to.name === 'login') {
                this.go(-1)
              }
              if (to.matched.some(record => record.meta.agent)) {
                if (userInfo.agent) {
                  next()
                } else {
                  next({
                    path: '/'
                  })
                }
              } else {
                next()
              }
            }
          }
        } else {
          next()
        }
      }, time)
    })
  }
})

export default router
