<template>
  <div class="trade__warp">
    <div class="tradeshow">
      <!-- 暫停交易狀態先移除了 -->
      <div class="stock__name">
        <div class="stock__icon">
          <img
            v-if="countryIconList.includes(lCode)"
            :src="getCountryicons(currentStockData.country)"
            alt=""
          >
          <img
            v-else
            :src="getSicons(lCode)"
            alt=""
          >
        </div>
        <div class="stock__title">
          <!-- {{ currentStockData.lotteryName }} -->
          {{ stockResMsg(`STOCK_${lCode}`,currentStockData.lotteryName) }}
        </div>
      </div>
      <div class="stock__time">
        <div class="order">
          {{ $t('CHATCELL_DATE',{msg:filterCurrentIssue}) }}
        </div>
        <div class="time">
          {{ Timebar }}
        </div>
      </div>
      <!-- <div class="gain-box">
        {{ currentStockData.currency || 'USD' }} : <span class="gain">{{ dynamicGain(currentStockData.gain) }}</span>
      </div> -->
      <div
        v-if="currentTimebar === $t('TRADE_SHIPPED')"
        class="loading-box"
      >
        <div class="outing">
          <p class="outing__title mb-0">
            {{ $t('CHATCELL_DATE',{msg:filterPreviousIssue}) }}
          </p>
          <div class="outing__coming ">
            {{ $t('TRADE_SHIPPED') }}
            <div class="d-flex align-center ml-2">
              <span class="circle mr-1" /><span class="circle mr-1" /><span class="circle" />
            </div>
          </div>
          <div class="outing__bar" />
        </div>
      </div>
      <!-- 跑馬燈 -->
      <van-notice-bar
        v-else-if="kTrendData.date"
        :scrollable="false"
        class="marquee"
      >
        <van-swipe
          vertical
          :autoplay="3000"
          :show-indicators="false"
          class="announce-card-bottom"
        >
          <van-swipe-item class="announce-card-text">
            <div class="marquee-item">
              <div class="in-item">
                <span class="number order-set"> {{ $t('CHATCELL_DATE',{msg:orderText(filterPreviousIssue)}) }}</span>
                <!-- <span class="marquee-title"></span> -->
                <span
                  class="opennumber"
                  :class="[checkUpDownClass(checkUpDown)]"
                >{{ latestResultList[0] && getTwodigit(latestResultList[0].openNumber) }}</span>
              </div>
              <div class="in-item">
                <span class="marquee-title">{{ $t('TRADE_OPEN_TODAY') }}</span>
                <span
                  class="number"
                >{{
                  getTwodigit(kTrendData.openingValue)
                }}</span>
              </div>
              <div class="in-item">
                <span class="marquee-title">{{ $t('TRADE_MAX') }}</span>
                <span class="number">{{ getTwodigit(kTrendData.maxPrice) }}</span>
              </div>
              <div class="in-item">
                <span class="marquee-title">{{ $t('TRADE_MIN') }}</span>
                <span class="number">{{ getTwodigit(kTrendData.minPrice) }}</span>
              </div>
              <!-- <div class="in-item">
                <span class="marquee-title">
                  <span>出仓: </span>
                </span>
                <span class="number">{{ latestResultList[0] && getTwodigit(latestResultList[0].openNumber) }}</span>
              </div> -->
            </div>
          </van-swipe-item>
          <van-swipe-item
            class="announce-card-text"
          >
            <div class="marquee-item set">
              <div
                v-show="getTwodigit(kTrendData.totalVolume)>0"
                class="in-item"
              >
                <span class="marquee-title">{{ $t('TRADE_VOLUME') }}</span>
                <span class="number">{{ getTwodigit(kTrendData.totalVolume) }}</span>
              </div>
              <div class="in-item">
                <span class="marquee-title">{{ $t('TRADE_PRICING') }}</span>
                <span
                  class="number"
                  :class="[checkUpDownClass(checkUpDown)]"
                >{{
                  UpsAndDownsNumber
                }}</span>
              </div>
              <div class="in-item">
                <span class="marquee-title">{{ $t('TRADE_PRICING2') }}</span>
                <span
                  class="number"
                  :class="[checkUpDownClass(checkUpDown)]"
                >{{
                  UpsAndDownsNumberPercent && getTwodigit(UpsAndDownsNumberPercent)
                }}</span>
              </div>
            </div>
          </van-swipe-item>
        </van-swipe>
      </van-notice-bar>
    </div>
  </div>
</template>

<script>
import lotteryAPI from '@/api/lottery'
import manageAPI from '@/api/manage'
import {
  getCountryicons,
  getSicons,
  countryIconList,
  specialIcons,
  stockResMsg
} from '@/utils'
import BaseLottery from '@/views/baseExtends/baseLottery'
import moment from 'moment'
import { mapState } from 'vuex'

const gainTable = (unit) => {
  return {
    1: unit.dollar,
    10: unit.dime,
    100: unit.cent,
    1000: '1/1K',
    10000: '1/10K',
    100000: '1/100K',
    1000000: '1/1M',
    10000000: '1/10M',
    100000000: '1/100M'
  }
}

export default {
  components: {
    // Action,
  },
  mixins: [BaseLottery],
  data () {
    return {
      countryIconList,
      specialIcons,
      // chase
      currentPlayOptionIndex: 0,
      // KData
      hasSetKtype: false,
      currentType: '1',
      selectType: 0, // 玩法active
      unitTime: 5,
      curPlayName: '4', // 折疊面板使用
      contentHeight: window.innerHeight - 240,
      // lottryData: {},
      // showNowOrder: '', // 顯示期數
      mainShowArea: 0,
      kTrendData: {},
      UpsAndDownsNumber: 0,
      UpsAndDownsNumberPercent: '',
      checkUpDown: null,
      showHide: null,
      // lottery chart1
      stockTrendData: {},
      chartOptions: {},
      lotteryTableData: [],
      hasChart: false,
      hasChartKData: false,
      chatKData: [],
      resultInterval: null,
      clockInterval: null,
      currentTime: '',
      getResultTimes: 0,
      lotteryType: 'STOCK',
      randomFeed: Math.floor(Math.random() * 4), // 获取开奖时间的随机数，用于错开请求
      leftIndex: ''
    }
  },
  computed: {
    ...mapState(['newLotteryList']),
    vuexNotify: {
      get () {
        return this.$store.state.prompt.showNotify
      }
    },
    vuexNotifyType: {
      get () {
        return this.$store.state.prompt.showNotifyType
      }
    },
    getStockConfig () {
      return this.newLotteryList
    },
    closeNotify () {
      const { vuexNotify, vuexNotifyType } = this
      return {
        vuexNotify,
        vuexNotifyType
      }
    },
    setArr () {
      const arr = this.awardArr
      return this.oneMode && this.mode === 'J11' ? arr.map(v => Math.floor(v)) : arr
    },
    officialStocks () {
      return this.$store.state.officialStocks
    },

    latestResult () {
      return this.$store.state.lt && this.$store.state.lt.LotteryResults[this.$store.state.lt.lottery.lotteryCode] && this.$store.state.lt.LotteryResults[this.$store.state.lt.lottery.lotteryCode][0]
        ? this.$store.state.lt.LotteryResults[this.$store.state.lt.lottery.lotteryCode][0].issue
        : ''
    },
    latestResultList () {
      return (this.$store.state.lt && this.$store.state.lt.LotteryResults[this.$store.state.lt.lottery.lotteryCode]) || []
    },
    countDown () {
      if (this.currentTime) {
        return Math.floor((moment(this.filterEndTime).valueOf() - this.currentTime) / 1000) // 转成以秒为单位
      } else {
        return 0
      }
    },
    countDownBasic () {
      return this.unitTime * 60
    },
    Timebar () {
      this.$store.commit('betStateUse', false)// def
      if (this.hasUpdating || !this.$store.state.lt || this.$store.state.lt.hasResultUpdating || !this.$store.state.Difftime || this.countDown === 0) {
        return '--:--:--'
      }

      const { specialType, isStop } = this.currentStockData
      const isStopStock = localStorage.getItem('newLotteryList') ? (specialType || isStop) : true // 該檔股票是否停售
      const latestResultIssue = this.$store.state.lt && this.$store.state.lt.LotteryResults && this.$store.state.lt.LotteryResults[this.lCode] && this.$store.state.lt.LotteryResults[this.lCode][0]
        ? this.$store.state.lt.LotteryResults[this.lCode][0].issue // 最新出倉期數
        : ''

      // ['期号有误', '暂停销售', '当期封单', '等待开局', '等待开奖'][type])
      if (!latestResultIssue || !this.filterEndTime) {
        return '--:--:--'
      }

      if ((Number(latestResultIssue) >= Number(this.filterCurrentIssue)) || this.countDown < 0) {
        console.log('期号有误')
        return '--:--:--'
      }

      if (isStopStock) {
        this.$store.commit('betStateUse', true)// 暫停交易
        return this.$t('TRADE_STOP_TRADING')
      }

      if (this.officialStocks.includes(this.lCode) && this.countDown > this.countDownBasic * 2) {
        return this.$t('TRADE_STOCK_TIMEBAR_STAES_CLOSED') // 如果 countDown 大于 10 分钟，则进入预售
      }

      if (!this.officialStocks.includes(this.lCode) && this.countDown > this.countDownBasic) {
        return this.$t('TRADE_STOCK_TIMEBAR_STAES_CLOSED') // 如果 countDown 大于 5 分钟，则进入预售
      }

      if (this.officialStocks.includes(this.lCode) && this.countDown <= this.countDownBasic * 2) {
        // 倒计时渲染
        let hh = Math.floor(this.countDown / 3600)
        let MM = Math.floor(this.countDown % 3600 / 60)
        let ss = Math.floor(this.countDown % 60)
        hh = hh > 9 ? hh : ('0' + hh)
        MM = MM > 9 ? MM : ('0' + MM)
        ss = ss > 9 ? ss : ('0' + ss)

        return `${hh}:${MM}:${ss}`
      }

      if (!this.officialStocks.includes(this.lCode) && this.countDown <= this.countDownBasic) {
        // 倒计时渲染
        let hh = Math.floor(this.countDown / 3600)
        let MM = Math.floor(this.countDown % 3600 / 60)
        let ss = Math.floor(this.countDown % 60)
        hh = hh > 9 ? hh : ('0' + hh)
        MM = MM > 9 ? MM : ('0' + MM)
        ss = ss > 9 ? ss : ('0' + ss)

        return `${hh}:${MM}:${ss}`
      }

      return this.defaultTimeBar
    },
    currentTimebar () {
      if (this.hasUpdating || !this.$store.state.lt || this.$store.state.lt.hasResultUpdating || !this.$store.state.Difftime || this.countDown === 0) {
        return '--:--:--'
      }

      const { specialType, isStop } = this.currentStockData
      const isStopStock = localStorage.getItem('newLotteryList') ? (specialType || isStop) : true // 該檔股票是否停售
      const latestResultIssue = this.$store.state.lt && this.$store.state.lt.LotteryResults && this.$store.state.lt.LotteryResults[this.lCode] && this.$store.state.lt.LotteryResults[this.lCode][0]
        ? this.$store.state.lt.LotteryResults[this.lCode][0].issue // 最新出倉期數
        : ''

      // ['期号有误', '暂停销售', '当期封单', '等待开局', '等待开奖'][type])
      if (!latestResultIssue || !this.filterEndTime) {
        return '--:--:--'
      }

      if ((Number(latestResultIssue) >= Number(this.filterCurrentIssue)) || this.countDown < 0) {
        return this.$t('TRADE_STOCK_TIMEBAR_ORDER_ERROR')
      }

      if (isStopStock) {
        return this.$t('TRADE_STOP_TRADING')
      }

      // 期號隔天的問題
      if (((Number(this.filterPreviousIssue) - Number(this.latestResult)) === 1 && Number(this.filterCurrentIssue) - Number(this.latestResult)) > 2) {
        if (this.officialStocks.includes(this.lCode) && this.countDown < this.countDownBasic * 2) {
          return this.$t('TRADE_SHIPPED') // 如果 countDown 大于 10 分钟，则进入预售
        }

        if (!this.officialStocks.includes(this.lCode) && this.countDown < this.countDownBasic) {
          return this.$t('TRADE_SHIPPED') // 如果 countDown 大于 5 分钟，则进入预售
        }
      }

      if (this.officialStocks.includes(this.lCode) && this.countDown > this.countDownBasic * 2) {
        return this.$t('TRADE_STOCK_TIMEBAR_STAES_CLOSED') // 如果 countDown 大于 10 分钟，则进入预售
      }

      if (!this.officialStocks.includes(this.lCode) && this.countDown > this.countDownBasic) {
        return this.$t('TRADE_STOCK_TIMEBAR_STAES_CLOSED') // 如果 countDown 大于 5 分钟，则进入预售
      }

      if ((Number(this.filterCurrentIssue) - Number(this.latestResult)) === 2) {
        return this.$t('TRADE_SHIPPED')
      }

      if (this.officialStocks.includes(this.lCode) && this.countDown <= this.countDownBasic * 2) {
        // 倒计时渲染
        let hh = Math.floor(this.countDown / 3600)
        let MM = Math.floor(this.countDown % 3600 / 60)
        let ss = Math.floor(this.countDown % 60)
        hh = hh > 9 ? hh : ('0' + hh)
        MM = MM > 9 ? MM : ('0' + MM)
        ss = ss > 9 ? ss : ('0' + ss)

        return `${hh}:${MM}:${ss}`
      }

      if (!this.officialStocks.includes(this.lCode) && this.countDown <= this.countDownBasic) {
        // 倒计时渲染
        let hh = Math.floor(this.countDown / 3600)
        let MM = Math.floor(this.countDown % 3600 / 60)
        let ss = Math.floor(this.countDown % 60)
        hh = hh > 9 ? hh : ('0' + hh)
        MM = MM > 9 ? MM : ('0' + MM)
        ss = ss > 9 ? ss : ('0' + ss)

        return `${hh}:${MM}:${ss}`
      }
      return this.defaultTimeBar
    }
  },
  watch: {
    closeNotify: {
      handler: function (value) {
        if (value.vuexNotify === true) {
          setTimeout(() => {
            this.$store.commit('switchNotify', {
              show: false,
              type: value.vuexNotifyType
            })
          }, 700)
        }
      },
      immediate: true
    },
    countDown (val) {
      if (val >= 0 || isNaN(val) || !val) {
        return
      }

      setTimeout(() => {
        this.hasReminder = true
        this.getLatestStockIssue()
      }, 1500)
    },
    latestResult (val) {
      // 開獎結果更新
      if (this.filterCurrentIssue && val && (((Number(this.filterCurrentIssue) - Number(val)) === 1) || ((Number(this.filterCurrentIssue) - Number(val)) >= 100 && this.filterPreviousIssue === val))) {
        clearInterval(this.resultInterval)
        this.resultInterval = null
        this.getResultTimes = 0
        if (this.hasReminder) {
          const notimsg = this.$t('TRADE_STOCK_LATESTRESULT_NOTIMSG1') + this.$t('TRADE_STOCK_LATESTRESULT_NOTIMSG2', { num: this.filterPreviousIssue }) + this.filterCurrentIssue
          this.$store.dispatch('actionSetHasNotify', { show: true, type: 'update', msg: notimsg })
          this.$store.dispatch('actionSetNotifyContent', notimsg)
          this.hasReminder = false
        }
      }
    },
    currentTimebar: {
      immediate: true,
      handler (val) {
        // 出倉更新開獎機制
        if (val === this.$t('TRADE_SHIPPED') && !this.resultInterval) {
          this.resultInterval = setInterval(() => {
            const wait4Results = Math.floor(60 - this.countDown % 60)
            let interval
            if (this.lotteryType === 'STOCK' && this.unitTime === 10 && this.getResultTimes >= 6) {
              // officialStocks 開獎時間10分鐘一期，前三分鐘每30秒取一次，之後每10秒取一次
              interval = 10
            } else if (this.lotteryType === 'STOCK' && this.unitTime === 5 && this.getResultTimes >= 2) {
              interval = 10
            } else if (this.lotteryType === 'STOCK' && this.unitTime === 1) {
              interval = 10
            } else {
              interval = 30
            }

            if ((wait4Results > (5 + this.randomFeed) || this.getResultTimes) && wait4Results % interval === this.randomFeed) {
              this.getResultTimes += 1
              this.$store.dispatch('actionGetResults', this.lCode)
            }
          }, 1000)
        }
      }
    },
    lCode (nv, ov) {
      if (ov && nv !== ov) {
        this.getLatestStockIssue()
        // this.getOneOpenLotteryData(this.lCode)
        // 跑馬燈
        this.getOneTrendData(this.lCode)
      }
    }
  },
  created () {
    if (!this.currentTime) {
      this.currentTime = this.getBaseTime()
    }

    this.clockInterval = setInterval(() => {
      this.currentTime = this.getBaseTime()
    }, 1000)
    // if (this.lCode) {

    // }
  },
  mounted () {
    if (this.lCode) {
      this.getOneOpenLotteryData(this.lCode)
      // 跑馬燈
      this.getOneTrendData(this.lCode)
    }
  },
  beforeDestroy () {
    clearInterval(this.clockInterval)
  },
  methods: {
    stockResMsg,
    dynamicGain (gain) {
      const unit = {
        dollar: this.$t('CHATCELL_DOLLAR'),
        dime: this.$t('TRADE_DIME'),
        cent: this.$t('TRADE_CENT')
      }
      // default 1
      return gainTable(unit)[gain || 1]
    },
    getTwodigit (val) {
      return (String(val) && String(val).replace(/(\d*\.\d{2})(\d*)/, '$1')) || ''
    },
    changePlayName (val) {
      this.curPlayName = ''
      setTimeout(() => {
        this.curPlayName = val
      }, 500)
    },
    chaseChange (e) {
      if (!e) {
        this.hasMultiple = false
        this.autoBuyIfStopAfterWin = false
        this.chasePower = ''
        this.chaseIssues = '10'
        this.chaseEach = ''
        this.chaseEachMultiple = ''
        this.curPlayName = ''
      }
    },
    switchMultiple (e) {
      this.hasMultiple = !this.hasMultiple
      if (this.hasMultiple) {
        this.$nextTick(() => {
          // this.$refs.tradeContent.scrollTo({ top: 999, behavior: 'smooth' })
        })
      }

      if (!this.hasMultiple) {
        this.chaseEach = ''
        this.chaseEachMultiple = ''
      }
    },
    reRebate () {
      const type = this.lType
      this.$store.state['newRebate' + type] = ''
      localStorage.removeItem(`newRebate${type}`)
      const userInfo = this.userInfo && this.userInfo.userName
      localStorage.removeItem(`rebateAll${userInfo}`)
    },
    getBaseTime () {
      const time = new Date()
      const utc = time.getTime() + time.getTimezoneOffset() * 60000 // 取GMT时间
      const offset = 8 // 北京为东8区
      const serverDiffTime = this.$store.state.Difftime
      if (serverDiffTime) {
        return (utc - serverDiffTime + (3600000 * offset)) // 北京时间
      } else {
        return ''
      }
    },
    getSicons,
    getCountryicons,
    pkTextShow (champion, runner) {
      if (champion > runner) {
        return this.$t('TRADE_BULL_MARKET')
      } else if (champion < runner) {
        return this.$t('TRADE_BEAR_MARKET')
      } else {
        return this.$t('TRADE_UNCHANGED')
      }
    },
    pkClassShow (champion, runner) {
      if (champion > runner) {
        return 'bear'
      } else if (champion < runner) {
        return 'cattle'
      } else {
        return 'def'
      }
    },
    orderText (value) {
      if (value.length > 0) {
        return value.substring(4)
      } else {
        return ''
      }
    },
    // 股指返點券取得期數以及投注結束時間
    // getOneLotteryData (value) {
    //   lotteryAPI.lotteryOpenData({ lotteryCode: `${value}` }).then((res) => {
    //     this.lottryData = res.data
    //     this.showNowOrder = res.data.nowIssue
    //   })
    // },
    // 获取开奖号码
    getOneOpenLotteryData (value) {
      // dataNum??
      lotteryAPI
        .getLotteryOpen({ lotteryCode: `${value}`, dataNum: 20 })
        .then((res) => {
          // console.log(res.data)
          const frontValue = res.data[0].openNumber
          const frontTwoValue = res.data[1].openNumber
          this.checkUpDown = Math.sign(frontValue - frontTwoValue)
          this.UpsAndDownsNumber = (frontValue - frontTwoValue).toFixed(2)
          this.UpsAndDownsNumberPercent = `${(
            (frontValue - frontTwoValue).toFixed(2) / 100
          ).toFixed(3)}%`
        })
    },
    // 获取前台缓存的最新时间
    getFrondTimeData () {
      manageAPI.getFrontCacheUpdatedAt().then((res) => {
        console.log(res)
      })
    },
    selectPlayType ({ selectType, mode }) {
      if (this.selectType === selectType) {
        return
      }

      this.selectType = selectType
      this.selectTypeShowText = this.selectTypeDataArr[selectType].name
      this.$store.commit('lt_changeMode', mode)
    },
    textColorClass (value) {
      if (value > 4) {
        return 'up'
      } else {
        return 'down'
      }
    },
    checkUpDownClass (value) {
      if (value === 1) {
        return 'up'
      } else if (value === -1) {
        return 'down'
      } else {
        return 'def'
      }
    },
    openListHandler () {
      const open = this.$refs.listUpDate
      open.openActionHandler()
      this.$nextTick(() => {
        const upDate = this.$refs.listUpDate
        upDate.accountDefault()
        upDate.selectData()
      })
    },
    closeListHandler () {
      const open = this.$refs.listUpDate
      open.closeActionHandler()
    },

    openUnitHandler () {
      const select = this.$refs.selectType
      select.onShowFn()
    },
    // 股票當天走勢
    getOneTrendData (value) {
      lotteryAPI
        .stockTodayTrendData({ lotteryCode: `${value}`, arrayData: true, dataCount: 10 })
        .then((res) => {
          this.kTrendData = res.data
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.trade__warp {
  display: flex;
  height: 100%;
  font-size: 12px;
  // border: 1px solid #F00;
}

.tradeshow {
  display: flex;
  align-items: center;
}

.marquee {
  padding: 0;
  width: 407px;
  height: 39px;
  background-color: transparent;
  pointer-events: none;

  &::v-deep {
    .van-ellipsis {
      width: 100%;
      // border: 1px solid #0FF;
    }
  }

  &-item {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    line-height: 12px;
    color: var(--indexRevolvingItemColor);
    // border: 1px solid #0F0;

    > .in-item {
      display: flex;
      justify-content: space-between;
      overflow: hidden;
      width: calc(100% / 4);
      height: 100%;
      // border: 1px solid #F00;
      flex-direction: column;

      >.marquee-title {
        margin-top: 2px;
      }

      >.opennumber {
        font-size: 16px;
        line-height: 22px;
      }

      .number {
        font-size: 16px;
        line-height: 22px;

        &.order-set {
          font-size: 12px;
          line-height: 17px;
        }

        &.up {
          color: var(--indexRevolvingUpColor);
        }

        &.down {
          color: var(--indexRevolvingDownColor);
        }
      }
    }
  }
}

.announce-card-bottom {
  width: 100%;
  height: 40px;
  font-size: 12px;
  color: var(--indexRevolvingCardBottomColor);
}

.outing {
  &__title {
    font-size: 12px;
    line-height: 17px;
    letter-spacing: 0.58px;
    color: #879AAF;
  }

  &__coming {
    display: flex;
    font-size: 16px;
    color: #0589D7;
    line-height: 22px;
  }

  &__bar {
    position: fixed;
    top: 59px;
    right: 0;
    left: 0;
    height: 2px;
    background-color: #0589D7;
    opacity: 0;
    animation: barShow 1.5s infinite linear;
  }
}
@keyframes barShow {
  0% {
    opacity: 0;
  }

  20% {
    opacity: 1;
  }

  40% {
    opacity: 1;
  }

  60% {
    opacity: 1;
  }

  80% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

.circle {
  display: inline-block;
  width: 4px;
  height: 4px;
  background-color: #0589D7;
  border-radius: 50%;
  opacity: 0;

  &:first-child {
    animation: circleShow1 1.5s infinite linear;
  }

  &:nth-child(2) {
    animation: circleShow2 1.5s infinite linear;
  }

  &:nth-child(3) {
    animation: circleShow3 1.5s infinite linear;
  }
}
@keyframes circleShow1 {
  0% {
    opacity: 0.2;
  }

  20% {
    opacity: 0.5;
  }

  40% {
    opacity: 0.2;
  }

  60% {
    opacity: 0;
  }

  80% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

@keyframes circleShow2 {
  0% {
    opacity: 0;
  }

  20% {
    opacity: 0.2;
  }

  40% {
    opacity: 0.5;
  }

  60% {
    opacity: 0.2;
  }

  80% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

@keyframes circleShow3 {
  0% {
    opacity: 0;
  }

  20% {
    opacity: 0;
  }

  40% {
    opacity: 0.2;
  }

  60% {
    opacity: 0.5;
  }

  80% {
    opacity: 0.2;
  }

  100% {
    opacity: 0;
  }
}

.stock {
  &__name {
    display: flex;
    align-items: center;
    margin-right: 40px;
    @media screen and(max-width: 1400px) {
      margin-right: 20px;
    }
    // width: 175px;
    // max-width: 200px;
  }

  &__icon {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
    width: 25px;
    height: 25px;

    >img {
      object-fit: fill;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
    }
  }

  &__title {
    font-size: 20px;
    color: var(--indexRevolvingStockTitleColor);
    @media screen and(max-width: 1400px) {
      font-size: 1.042vw;
    }
  }

  &__time {
    margin-right: 40px;
    // border: 1px solid #000;

    >.order {
      font-size: 12px;
      white-space: nowrap;
      color: var(--indexRevolvingStockOrderColor);
      line-height: 17px;
    }

    >.time {
      font-size: 16px;
      color: var(--indexRevolvingStockTimeColor);
      line-height: 22px;
    }
  }
}

.opennumber {
  &.up {
    color: var(--indexRevolvingOpenNumberUpColor);
  }

  &.down {
    color: var(--indexRevolvingOpenNumberDownColor);
  }
}
</style>
