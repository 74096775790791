export default {
  /**
   * Basic Setting
   */
  updatedUserBalance: state =>
    state.upadteBalance
      // 一般單
      ? Object.values(state.betOrderList).reduce((prev, curr) => {
        if (curr && curr.winMoney) {
          return curr.winMoney + prev
        }
        return prev
      }, 0) +
      // 追號單
      Object.values(state.chaseBetOrderList).reduce((prev, curr) => {
        if (curr && curr.allBonus) {
          return curr.allBonus + prev
        }
        return prev
      }, 0)
      : 0
}
