<template>
  <div class="bouble__wrap">
    <div
      v-if="!onlySecond"
      ref="champion"
      class="champion__title"
    >
      <div class="in-title">
        {{ $t('TRADE_CHAMPION') }}
      </div>
      <van-collapse
        v-model="activeChampNames"
        accordion
        class="champion-collapse"
        :class="[{'is-disable':championText===$t('PC_NOT_SELECTED')}]"
      >
        <van-collapse-item
          :title="championText"
          class="bet__inputarea"
          name="1"
        >
          <div class="champion__wrap">
            <div
              v-for="(item, index) in championData"
              :key="`champ-${index}`"
              class="champion__btn"
              :class="{'is-active': item.isActive}"
              @click="activeChampionBtn(index, item.type, award[index])"
            >
              <div class="item-title">
                {{ $t(item.name) }}
              </div>
              <div class="bottom-show">
                <div class="state">
                  {{ $t('TRADE_FALL') }}
                </div>
                <div class="number">
                  {{ award[index] }}
                </div>
              </div>
            </div>
          </div>
        </van-collapse-item>
      </van-collapse>
    </div>
    <div
      v-if="!onlyFirst"
      ref="runner"
      class="runner__title"
    >
      <div class="in-title">
        {{ $t('TRADE_RUNNER') }}
      </div>
      <van-collapse
        v-model="activeRunnerNames"
        class="runner-collapse"
        :class="[{'is-disable':runnerText===$t('PC_NOT_SELECTED')}]"
        accordion
      >
        <van-collapse-item
          :title="runnerText"
          class="bet__inputarea"
          name="1"
        >
          <div class="runner__title">
            <div
              v-for="(item, index) in runnerData"
              :key="`champ-${index}`"
              class="runner__btn"
              :class="{'is-active': item.isActive}"
              @click="activeRunnerBtn(index, item.type, award[index])"
            >
              <div class="item-title">
                {{ $t(item.name) }}
              </div>
              <div class="bottom-show">
                <div class="state">
                  {{ $t('TRADE_RUNNER') }}
                </div>
                <div class="number">
                  {{ award[index + 6] }}
                </div>
              </div>
            </div>
          </div>
        </van-collapse-item>
      </van-collapse>
    </div>
    <div class="stock__unit">
      <div class="in-title">
        {{ $t('TRADE_PAR_VALUE') }}
      </div>
      <div
        class="order__money"
        :class="{'is-active':outline}"
      >
        <input
          v-model="stepOrder"
          class="input-set"
          type="text"
          @input="checkInput"
          @focus="outline=true"
          @blur="outline=false"
        >
        <div
          v-if="stockCentEnable"
          ref="doubleunit"
          class="unit"
          :class="$i18n.locale"
        >
          <van-collapse
            v-model="unitNames"
            accordion
            @change="unitAciveHandler"
          >
            <!-- :class="{'is-show':filterRunnerData}" -->
            <van-collapse-item
              :title="unitText"
              name="1"
            >
              <div class="runner__title">
                <div
                  v-for="(item, index) in unitOption"
                  :key="`champ-${index}`"
                  :class="{'is-active':unitIndex===index}"
                  @click="activeUnitBtn(index, item)"
                >
                  {{ item.text }}
                </div>
              </div>
            </van-collapse-item>
          </van-collapse>
          <!-- <van-dropdown-menu
            class="tab__days"
            :overlay="false"
          >
            <van-dropdown-item
              v-model="unitValue"
              :options="unitOption"
              class="unit__items"
              @change="selectUnitHandler"
            />
          </van-dropdown-menu> -->
        </div>
      </div>
    </div>
    <div class="show__info bet-show">
      <div class="item">
        <div class="itme-name">
          {{ $t('PC_TOTAL_COUNT') }}
        </div>
        <div class="item-value">
          {{ totalOrder }}&nbsp;{{ $t('TRADE_STOCK') }}
        </div>
      </div>
      <div class="item">
        <div class="itme-name">
          {{ $t('PC_TOTAL_MATH') }}
        </div>
        <div class="item-value">
          ¥ {{ totalOrderMoney }}
        </div>
      </div>
    </div>
    <div
      class="bouble__btn"
      :class="[{hide: Number(totalOrderMoney) === 0||betState}]"
      @click="confirmBet"
    >
      {{ betState ? $t('TRADE_STOP_TRADING'): $t('TRADE_BUY') }}
    </div>
    <div class="item max-income">
      <div class="itme-name">
        {{ $t('TRADE_MAX_INCOME') }}
      </div>
      <div class="item-value">
        ¥ {{ posiiblePrize }}
      </div>
    </div>
    <!-- 投資確認外框 -->
    <PopupBase
      ref="parentBet"
      class="parentBet"
      :slide-item="'parentBet'"
      :has-overlay="true"
      :popup-title="$t('PC_BET_DETAILS')"
    >
      <BasePopup
        :one-item="doubleDetail"
        @cancel="cancelBet"
        @confirm="submitBet"
      />
    </PopupBase>
  </div>
</template>

<script>
import pubsub from 'pubsub-js'
import lotteryAPI from '@/api/lottery'
import { playType, apiResMsg2, stockResMsg } from '@/utils'
import { mapGetters, mapState } from 'vuex'
// 新共用組
import PopupBase from './PopupBase.vue'
// 一般
import BasePopup from './popup/Base.vue'
function setTimeStr (arr) {
  if (arr && arr.length) {
    const list = []
    arr.forEach(v => {
      v.startTime && (v.startTime = v.startTime.split(' ')[1])
      v.endTime && (v.endTime = v.endTime.split(' ')[1])
      list.push(v)
    })
    return list
  }
  return []
}
export default {
  components: {
    PopupBase,
    BasePopup
  },
  props: {
    activeIndex: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      // 每股單位
      unitValue: 1,
      // 冠军選單
      championActiveArray: [],
      activeChampNames: '',
      championData: [
        { name: 'TRADE_UP', type: 1, isActive: false, value: '涨' },
        { name: 'TRADE_DOWN', type: 1, isActive: false, value: '跌' },
        { name: 'TRADE_SINGLE_STRAND', type: 2, isActive: false, value: '单股' },
        { name: 'TRADE_DOUBLE_STRAND', type: 2, isActive: false, value: '双股' },
        { name: 'TRADE_BULL_MARKET', type: 3, isActive: false, value: '牛' },
        { name: 'TRADE_BEAR_MARKET', type: 3, isActive: false, value: '熊' }
      ],
      // 亚军選單
      activeRunnerNames: '',
      runnerActiveArray: [],
      runnerData: [
        { name: 'TRADE_UP', type: 1, isActive: false, value: '涨' },
        { name: 'TRADE_DOWN', type: 1, isActive: false, value: '跌' },
        { name: 'TRADE_SINGLE_STRAND', type: 2, isActive: false, value: '单股' },
        { name: 'TRADE_DOUBLE_STRAND', type: 2, isActive: false, value: '双股' }
      ],
      // 每股金額
      stepOrder: '', // 投資金額
      // 投资内容
      doubleDetail: {},
      currentBet: null,
      bankSwitch: false,
      hasStockInvest: false,
      isInvesting: false,
      // outline樣式
      outline: false,
      // 單位選單
      unitNames: '',
      // 單位index
      unitIndex: 0
    }
  },
  computed: {
    ...mapState(['newLotteryList']),
    ...mapGetters(['currentBettingIssue']),
    // 0全開 1冠軍 2亞軍
    // 顯示冠軍與否
    onlyFirst () {
      return this.siteConfig[`stockPlayType${this.mode}`] === 1
    },
    // 顯示亞軍與否
    onlySecond () {
      return this.siteConfig[`stockPlayType${this.mode}`] === 2
    },
    siteConfig () {
      return this.$store.state.siteConfig
    },
    stockCentEnable () {
      return this.$store.state.siteConfig.stockCentEnable
    },
    posiiblePrize () {
      let champion = 0
      let runner = 0
      const orderNumber = parseInt(this.stepOrder)
      if (isNaN(orderNumber)) {
        return '0.00'
      } else {
        if (this.championActiveArray.length > 0 && this.stepOrder !== '') {
          champion = this.championActiveArray
            .filter(i => i.odds)
            .reduce((prev, curr) => Number(curr.odds) + prev, 0) * orderNumber
        } else {
          champion = 0
        }

        if (this.runnerActiveArray.length > 0 && this.stepOrder !== '') {
          runner = this.runnerActiveArray
            .filter((item) => item.odds)
            .reduce((prev, curr) => Number(curr.odds) + prev, 0) * orderNumber
        } else {
          runner = 0
        }
        return ((champion + runner) * this.selectUnit).toFixed(2)
      }
    },
    lCode () {
      return this.$store.state.lt ? this.$store.state.lt.lCode : this.$store.state.defaultStock
    },
    mode () {
      return this.$store.state.lt && this.$store.state.lt.mode.mode
    },
    // 每股金額單位
    selectUnit: {
      get () {
        return this.$store.state.lt && this.$store.state.lt.chaseConf.bettingUnit
      },
      set (val) {
        this.$store.commit('lt_setChaseBettingUnit', val)
      }
    },
    championNumber () {
      return this.championActiveArray.map((item) => item.value).join(' ')
        ? this.championActiveArray.map((item) => item.value).join(' ')
        : ''
    },
    championText: function () {
      return this.championActiveArray.map((item) => this.$t(item.name)).join(',')
        ? this.championActiveArray.map((item) => this.$t(item.name)).join(',')
        : this.$t('PC_NOT_SELECTED')
    },
    runnerText: function () {
      return this.runnerActiveArray.map((item) => this.$t(item.name)).join(',')
        ? this.runnerActiveArray.map((item) => this.$t(item.name)).join(',')
        : this.$t('PC_NOT_SELECTED')
    },
    runnerNumber () {
      return this.runnerActiveArray.map((item) => item.value).join(' ')
        ? this.runnerActiveArray.map((item) => item.value).join(' ')
        : ''
    },
    totalOrder () {
      // 共X股
      const totalOrder = this.championActiveArray.concat(
        this.runnerActiveArray
      )
      return totalOrder.length ? totalOrder.length : 0
    },
    totalOrderMoney () {
      const bettingCount = this.championActiveArray.length + this.runnerActiveArray.length // 注數
      return ((Number(this.stepOrder) * bettingCount) * this.selectUnit).toFixed(2)
        ? ((Number(this.stepOrder) * bettingCount) * this.selectUnit).toFixed(2)
        : '0.00'
    },
    award () {
      // 玩法 雙面跟定位
      if (this.mode === 'A11') {
        return [this.$store.state.lt.award]
      }

      if (this.mode === 'D11') {
        let oddsList = []
        const order = [4, 2, 4]

        order.forEach((item, index) => {
          oddsList = [...oddsList, ...Array(item).fill(this.$store.state.lt.award[index])]
        })

        return oddsList
      }

      return []
    },
    currentStockData () {
      const data = this.newLotteryList
      return data ? data.filter(item => item.lotteryCode === this.lCode)[0] : {}
    },
    filterChampionData () {
      const filter = this.championData.filter(item => {
        return item.isActive === true
      })
      return filter.length
    },
    filterRunnerData () {
      const filter = this.runnerData.filter(item => {
        return item.isActive === true
      })
      return filter.length
    },
    // 參數更新
    unitOption () {
      return [
        { text: this.$t('TRADE_DALLAR'), value: 1, icon: '' },
        { text: this.$t('TRADE_DIME'), value: 0.1, icon: '' },
        { text: this.$t('TRADE_CENT'), value: 0.01, icon: '' }
      ]
    },
    unitText () {
      return this.unitOption[this.unitIndex].text
    },
    dropdownState () {
      const { activeChampNames, activeRunnerNames, unitNames } = this
      return {
        activeChampNames, activeRunnerNames, unitNames
      }
    },
    betState () {
      return this.$store.state.betState
    }
  },
  watch: {
    dropdownState: {
      handler: function (value) {
        // console.log(value)
        if (value.unitNames !== '' && (value.activeChampNames !== '' || value.activeRunnerNames !== '')) {
          setTimeout(() => {
            this.unitNames = ''
            this.outline = false
          }, 300)
        }
      },
      deep: true
    },
    activeIndex: {
      handler: function (value) {
        if (value === 1) {
          this.unitNames = ''
          this.outline = false
        }
      }
    }
  },
  mounted () {
    document.addEventListener('click', this.bodyCloseChampion)
    console.log(this.currentBettingIssue, 'double')
    document.addEventListener('mousedown', this.mousedownHandler)
  },
  beforeDestroy () {
    document.removeEventListener('click', this.bodyCloseChampion)
    document.removeEventListener('mousedown', this.mousedownHandler)
  },
  methods: {
    stockResMsg,
    mousedownHandler (e) {
      const clickedEl = e.target
      this.$nextTick(() => {
        const unit = this.$refs.doubleunit
        if (unit && !unit.contains(clickedEl)) {
          this.unitNames = ''
        }
      })
    },
    checkInput (e) {
      const v = e.target.value

      if (!(+v) || !/^\d*$/.test(v)) {
        this.stepOrder = ''
        return
      }

      if (v.match(/\./ig)) {
        this.stepOrder = v.replace(/\..*/ig, '')
      }

      const max = 10000000
      if (v) {
        if (v > max) {
          this.stepOrder = max
          this.msgAlertBig(this.$t('TRADE_STOCK_NUMERIC_KEYBOARD_ALERT_TEXT') + max + this.$t('TRADE_DALLAR'), this.$t('CONFIRM'))
        }
      }
    },
    activeChampionBtn (index, type, odds) {
      // 冠军選單選項
      const opposite = this.championData.findIndex(i => i.isActive && i.type === type)
      if (opposite !== -1 && opposite !== index) {
        this.championData[opposite].isActive = false
      }

      if (this.championData[index].isActive) {
        this.championData[index].odds = ''
      } else {
        this.championData[index].odds = odds
      }

      this.championData[index].isActive = !this.championData[index].isActive
      this.championActiveArray = this.championData.filter(item => item.isActive)
    },
    activeRunnerBtn (index, type, odds) {
      // 亚军過濾重複 && 互斥
      const opposite = this.runnerData.findIndex(i => i.isActive && i.type === type)
      if (opposite !== -1 && opposite !== index) {
        this.runnerData[opposite].isActive = false
      }

      if (this.runnerData[index].isActive) {
        this.runnerData[index].odds = ''
      } else {
        this.runnerData[index].odds = odds
      }

      this.runnerData[index].isActive = !this.runnerData[index].isActive
      this.runnerActiveArray = this.runnerData.filter(item => item.isActive)
    },
    bodyCloseChampion (e) {
      if (this.$refs.champion && !this.$refs.champion.contains(e.target)) {
        this.activeChampNames = ''
        // console.log('close champion')
      }
      if (this.$refs.runner && !this.$refs.runner.contains(e.target)) {
        this.activeRunnerNames = ''
        // console.log('close runner')
      }
    },
    selectUnitHandler (value) {
      // 單位
      this.selectUnit = value
    },
    initializationData () {
      // const select = this.$refs.numberKeyboard
      // select.resetValue()
      this.championActiveArray = []
      this.runnerActiveArray = []
      this.stepOrder = ''
      this.championData = this.championData.map(i => ({ ...i, odds: '', isActive: false }))
      this.runnerData = this.runnerData.map(i => ({ ...i, odds: '', isActive: false }))
      // unit
      this.selectUnit = 1 // 預設元
      this.unitIndex = 0// 預設選單
      this.cancelBet()
    },
    reRebate () {
      const type = 'STOCK'
      this.$store.state['newRebate' + type] = ''
      localStorage.removeItem(`newRebate${type}`)
      const userInfo = this.userInfo && this.userInfo.userName
      localStorage.removeItem(`rebateAll${userInfo}`)
    },
    cancelBet () {
      this.$refs.parentBet && this.$refs.parentBet.closePopupHandler()
    },
    submitBet () {
      if (this.isInvesting) {
        return
      }
      this.currentBet && this.currentBet()
    },
    async confirmBet () {
      // 普通投注
      let xBasket
      let bettingCount
      let totalAmount
      let betDetail
      const bettingIssue = this.currentBettingIssue

      // 投资组合
      const totalInvestment = [...this.championActiveArray, ...this.runnerActiveArray]
      this.$store.dispatch('actionSetHasLimitLoading', true)
      this.$store.dispatch('actionSetLimitMessage', this.$t('TRADE_INVESTING'))
      if (this.championActiveArray.length) {
        betDetail = this.championActiveArray.map(i => this.$t(i.name)).join(' ')
      } else {
        betDetail = '-'
      }

      if (this.runnerActiveArray.length) {
        betDetail += `,${this.runnerActiveArray.map(i => this.$t(i.name)).join(' ')}`
      } else {
        betDetail += ',-'
      }
      if (totalInvestment.length) {
        // console.log(this.$store.state.lt.Rebate[this.lType])
        bettingCount = totalInvestment.length // 注數
        totalAmount = this.totalOrderMoney
        xBasket = {
          lotteryCode: this.lCode,
          playDetailCode: `${this.lCode}${this.mode}`,
          bettingNumber: `${this.championNumber || '-'},${this.runnerNumber || '-'}`,
          bettingCount,
          bettingAmount: Number(this.totalOrderMoney),
          bettingPoint: this.$store.state.lt.Rebate.STOCK,
          bettingUnit: this.selectUnit,
          bettingIssue,
          graduationCount: 1
        }
      }

      this.doubleDetail = {
        bettingIssue,
        betDetail: `${playType[0]}`,
        bettingCount,
        championNumber: this.championNumber,
        runnerNumber: this.runnerNumber,
        bettingAmount: this.totalOrderMoney,
        bettingSingle: this.stepOrder,
        lotteryName: this.currentStockData.lotteryName,
        lotteryCode: this.lCode,
        countryCode: this.currentStockData.country
      }

      this.$refs.parentBet && this.$refs.parentBet.openPopupHandler()

      this.currentBet = () => {
        // 保存分享数据
        const sharedata = {
          ...xBasket,
          lotteryName: this.currentStockData.lotteryName,
          lotteryType: 'STOCK',
          betDetail: `[${playType[0]}] ${betDetail}`
        }
        const userName = this.$store.state.userInfo ? this.$store.state.userInfo.userName : 'empty'
        // // old this.userinfo??
        this.$store.state.meelineState.shareData = {
          type: 1,
          username: userName,
          data: [sharedata]
        }
        // isInvesting??
        this.isInvesting = true
        // this.$store.dispatch('actionSetHasLimitLoading', true)
        // this.$store.dispatch('setInterfaceLoading', true)
        lotteryAPI.addBetting({ bettingData: JSON.stringify([xBasket]) }).then(ref => {
          this.$toast.clear()
          this.isInvesting = false
          // 限制投注訊息取消
          if (ref.code !== -40) {
            this.$store.dispatch('setInterfaceLoading', false)
          // this.$store.dispatch('actionSetHasLimitLoading', false)
          }

          if (ref.code === 1) {
            this.$store.dispatch('actionSetUserBalance')
            const stock = `STOCK_${this.currentStockData.lotteryCode}`
            // console.log(this.stockResMsg(stock, this.currentStockData.lotteryName))
            this.$store.dispatch('actionUpdateNotifyData', {
              // name: this.currentStockData.lotteryName,
              name: this.stockResMsg(stock, this.currentStockData.lotteryName),
              issue: bettingIssue.slice(4),
              playDetail: this.$t('PC_DOUBLE_DISC', { num: bettingCount, total: totalAmount }),
              amount: totalAmount
            })
            this.$store.dispatch('actionSetHasNotify', { show: true, type: 'notice' })
            pubsub.publish('confirm-bet', 'doubletype')

            setTimeout(() => {
              const select = this.$refs.okDialog
              select && select.openPopWindow()
            }, 500)
            // 切換記錄按鈕
            this.$store.commit('SET_RECORD_BTN_ACTIVE', 0)
          // this.accountSelectType = 'type2'// 跳頁跳到一般使用
          } else {
            this.$store.dispatch('actionUpdateNotifyData', { msg: apiResMsg2(ref) })
            this.$store.dispatch('actionSetHasNotify', { show: true, type: 'error', msg: apiResMsg2(ref) })
          }

          if (ref.code === -9) {
          // 清除rebate
            this.reRebate()
            this.$store.dispatch('actionGetRebate', true)
          } else if (ref.code === -33) {
            return false
          } else if (ref.code === -40) {
          // 限制投注
          } else {
            localStorage.removeItem('lotteryConfig')
            localStorage.removeItem('newLotteryConfig')
            localStorage.removeItem('newLotteryList')
            lotteryAPI.lotteryConfig().then(ref => {
              const lotteryArr = ref.data
              const LotteryConfig = setTimeStr(lotteryArr.lotteryConfig)
              const LotteryList = setTimeStr(lotteryArr.lotteryList)
              this.$store.dispatch('actionsSetNewLotteryList', LotteryList)
              this.$store.dispatch('actionsSetNewLotteryConfig', LotteryConfig)
              this.LotteryList = LotteryList
              this.LotteryConfig = LotteryConfig
              localStorage.setItem('newLotteryConfig', JSON.stringify(LotteryConfig))
              localStorage.setItem('newLotteryList', JSON.stringify(LotteryList))
            })
            if ((ref.msg).indexOf('期号错误') > -1) {
              localStorage.removeItem('newlotteryPlan' + this.lCode)
            } else if ((ref.msg).indexOf('余额不足') > -1) {
              this.reRebate()
              this.$store.dispatch('actionGetRebate', true)
            } else if ((ref.msg).indexOf('投资失败') > -1) {
              this.msgAlertBig(this.$t('TRADE_STOCK_CONFIRMBET_UNIT_ERROR_REFRESH'), this.$t('CONFIRM')).then(ref => {
                location.reload()
              }).catch(() => { })
            }
          }

          this.initializationData()
        })
      }
    },
    activeUnitBtn (index, item) {
      this.unitNames = ''// 關閉選單
      console.log(index, item)
      this.unitIndex = index
      this.selectUnitHandler(item.value)
      this.outline = false
    },
    unitAciveHandler () {
      this.outline = !this.outline
    }
  }
}

</script>

<style lang="scss" scoped>
@import './Double.scss'
</style>
