import * as types from '../types'
import { userInfo } from '@/store/modules/helper'
export const user = {
  state: {
    // 用户登录信息
    userInfo: userInfo() || {},
    rebate: '',
    loading: false
  },
  getters: {
    loginStatus: state => state.userInfo,
    userInfo: state => state.userInfo
  },
  actions: {
    /**
     * 用户登录
     */
    setUserInfo ({ state, commit }, res) {
      commit(types.SET_USER_INFO, { ...state.userInfo, ...res })
    },
    actionUpdateUserInfo: ({ commit }, val) => {
      commit('updateUserInfo', val)
    },

    /**
     * 退出登录
     */
    setSignOut ({ commit }) {
      commit(types.SET_USER_INFO, {})
    },

    /**
     * 返点
     */
    setRebate ({ commit }, res) {
      commit(types.SET_REBATE, res)
    },
    /**
     * 用戶登入失敗
     */
    getLoading ({ commit }, val) {
      commit('SETLOADING', val)
    }
  },
  mutations: {
    [types.SET_USER_INFO] (state, res) {
      state.userInfo = res
      localStorage.setItem('userInfo', JSON.stringify(state.userInfo))
    },
    // 个人资料 userInfo
    updateUserInfo: (state, val) => {
      state.userInfo = {
        ...state.userInfo,
        ...val
      }
    },

    [types.SET_REBATE] (state, res) {
      state.rebate = res
    },
    // loadingBar 狀態
    SETLOADING (state, val) {
      state.loading = val
    }
  }
}
