import Vue from 'vue'
import Vuex from 'vuex'
/// /上面暫時假資料
import actions from './actions'
import getters from './getters'
import mutations from './mutations'

import { user } from './modules/user'
import { prompt } from './modules/prompt'
import { meelineState } from './modules/meelineState'
import { security } from './modules/security'
import { live } from './modules/live'
import { withdrawal } from './modules/withdrawal'
import { navigation } from './modules/navigation'
import { confirmState } from './modules/bet'
import theme from './modules/theme'
import { getStockTheme, getSiteConfig } from '@/utils'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isSafari: /^((?!chrome|android).)*safari/i.test(navigator.userAgent),
    hasHot: true, // 预设有热门资料
    countryList: [], // 股票种类清单
    messageShow: false, // 站内信
    securityNoticeEnd: false,
    isApp: false,
    hasError: false,
    hasProfitTable: false, // 盈虧列表開關
    hasProfitTableMore: false,
    errorMessage: {},
    // sourceId: '' || getSourceId(),
    version: null, // 版本号控制
    loading: false, // 页面接口加载
    siteConfig: getSiteConfig(),
    Difftime: 0,
    newLotteryList: '',
    newLotteryConfig: '',
    newRebateK3: '',
    newRebateSSC: '',
    newRebateKL8: '',
    newRebateLHC: '',
    newRebatePK10: '',
    newRebatePL35: '',
    newRebateSYX5: '',
    newRebateFC3D: '',
    newRebateBJ28: '',
    documentTitle: '', // 页面标题
    isFastShow: false, // 是否展示快捷弹窗
    yiDunCaptchaId: '0212e7e241ec4b49996f7525afcc6dcc',
    redEnvelopeNumber: false,
    // themeValue: getTheme(),
    hasNotify: {
      show: false
    },
    notifyData: {},
    notifyIconName: '',
    notifyContent: '',
    stockIssueData: {},
    // Lottery
    officialStocks: ['1811', '1812', '1813', '1814', '1815', '1816', '1817', '1818', '1819', '1821', '1822', '1823', '1824', '1825', '1826', '1827', '1828', '1829', '1831', '1832', '1833', '1834', '1835', '1836', '1837', '1838', '1839', '1840', '1861', '1862', '1863'], // 十分鐘一期
    stockDetailData: {},
    tabActive: 'hot',
    // 个人资料
    userInfo: {},
    userBalance: '',
    // 活動資料
    activityDetail: {},
    // 分享注單
    shareData: {},
    hasShareRoom: false,
    // trade search-bar
    hasSearch: false,
    // app setting
    isBgFixed: false,
    isBgMax: false,
    // stockTheme: getStockTheme(),
    hasMaintain: false, // 維護
    userProfit: {},
    graphType: 'mixed',
    trendData: {}, // 走勢資料
    chaseBetOrderList: {}, // 追号訂單資料
    betOrderList: {}, // 一般訂單資料
    themeWord: getStockTheme(),
    NowIssue: '', // 当前期
    NextIssue: '', // 下一期
    defaultStock: '',
    recordActive: 0, // 一般紀錄和追號紀錄active
    currentTag: 'parentpopup',
    optionSides: {
      stockSide: true,
      trendSide: true
    },
    currentTab: '', // 固定
    chartTabData: [
      { name: '', id: '01', code: '' }
    ],
    upadteBalance: false,
    selectOpenState: false,
    selectOpenBaseState: false,
    typeSelectDropDown: '',
    baseSelectDropDown: '',
    betState: false// true為暫停交易關閉
  },
  getters,
  actions,
  mutations,
  modules: {
    user,
    prompt,
    security,
    withdrawal,
    meelineState,
    // lt: Stock
    live,
    navigation,
    confirmState,
    theme
  }
})
