<template>
  <v-card>
    <v-navigation-drawer
      app
      clipped
      :right="true"
      class="theme__wrap"
      width="36"
      :permanent="true"
    >
      <div class="theme__content">
        <div
          class="theme__icon"
          :class="{active: optionSides.stockSide}"
          @click="$store.dispatch('actionSetOptionSides', {...optionSides, stockSide: !optionSides.stockSide})"
        >
          <img
            :src="`/static/images/Layout/stockList${optionSides.stockSide ? '-active' : ''}.svg`"
            alt=""
          >
        </div>
        <div
          class="theme__icon"
          :class="{active: optionSides.trendSide}"
          @click="$store.dispatch('actionSetOptionSides', {...optionSides, trendSide: !optionSides.trendSide})"
        >
          <img
            :src="`/static/images/Layout/trend${optionSides.trendSide ? '-active' : ''}.svg`"
            alt=""
          >
          <!-- {{ optionSides.trendSide }} -->
        </div>
        <!-- <div
          class="theme__icon"
          :class="{active: userTheme === 'lightclassic'}"
          @click="themeChangeHandler"
        >
          <img
            :src="`/static/images/Layout/theme-${userTheme}.svg`"
            alt=""
          >
        </div> -->
      </div>
    </v-navigation-drawer>
  </v-card>
</template>
<script>
import { mapState } from 'vuex'
// import apiUser from '@/api/users'
export default {
  data () {
    return {

    }
  },
  computed: {
    ...mapState({
      userTheme: state => state.themeWord,
      optionSides: state => state.optionSides
    })
  },
  methods: {
    themeChangeHandler () {
      return this.msgAlertBig('no theme change', 'confirm')
    //   const newTheme = this.userTheme === 'classic' ? 'lightclassic' : 'classic'
    //   // console.log(newTheme)
    //   const param = {
    //     theme: newTheme
    //   }
    //   apiUser.updateUserInfo(param).then((e) => {
    //     if (e.code === 1) {
    //       apiUser.getUserInfo().then((e) => {
    //         if (e.code === 1) {
    //           this.personalInfoData = e.data
    //           const addLoginState = { login: 1, ...e.data }
    //           localStorage.setItem('userInfo', JSON.stringify(addLoginState))
    //           this.$store.dispatch('setUserInfo', addLoginState)
    //           this.$store.commit('setTheWord', newTheme)
    //         }
    //       })
    //     }
    //   })
    }
  }
}
</script>
<style lang="scss" scoped>
.theme {
  &__wrap {
    top: 60px !important;
    // background-color: #1E2E48 !important;
    background-color: var(--homeBgcTransparent) !important;
    border-radius: 0 !important;

    ::v-deep {
      .v-navigation-drawer__border {
        width: 0 !important;
      }
    }
  }

  &__content {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 2px;
    height: 100%;
    flex-direction: column;

    &::after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: var(--dropdownHoverItemColor);
      opacity: 0.35;
      box-shadow: var(--dropdownBoxShadow);
      content: '';
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;

    &:hover {
      cursor: pointer;
    }

    &.active {
      background: var(--homeThemeIconActiveBgc);
    }
  }
}
</style>
