<template>
  <div
    class="content"
  >
    <div class="title__group">
      <div class="header">
        <div class="icon">
          <img
            v-if="countryIconList.includes(oneItem.lotteryCode)"
            :src="getCountryicons(oneItem.countryCode)"
            alt=""
          >
          <img
            v-else
            :src="getSicons(oneItem.lotteryCode)"
            alt=""
          >
        </div>
        <div class="title">
          <!-- {{ oneItem.lotteryName }} -->
          {{ stockResMsg(`STOCK_${oneItem.lotteryCode}`,oneItem.lotteryName) }}
        </div>
      </div>
      <div
        class="value recordcode-set pending"
      >
        ¥ {{ oneItem.bettingAmount }}
      </div>
    </div>
    <div class="item-content">
      <div class="item">
        <div class="name">
          {{ $t('TRADE_CHASE_START_ISSUE') }}
        </div>
        <div class="value">
          {{ $t('PC_START_DATE',{num:oneItem.startIssue}) }}
        </div>
      </div>
      <div class="item">
        <div class="name">
          {{ $t('INVEST_PLAN') }}
        </div>
        <div class="value">
          {{ filterOption(oneItem.betDetail) }}
        </div>
      </div>
      <div class="item">
        <div class="name">
          {{ $t('TRADE_CHASE_COUNT') }}
        </div>
        <div class="value">
          {{ oneItem.bettingCount }}
        </div>
      </div>
      <div class="item">
        <div class="name">
          {{ $t('TRADE_CHAMPION') }}
        </div>
        <div class="value">
          {{ oneItem.championNumber || '-' }}
        </div>
      </div>
      <div class="item">
        <div class="name">
          {{ $t('WAREHOUSE_RECORDCENTER_CHASENUMBER_BET_TITLE') }}
        </div>
        <div class="value">
          {{ oneItem.bettingAmount }}
        </div>
      </div>
      <div class="item">
        <div class="name">
          {{ $t('TRADE_RUNNER') }}
        </div>
        <div class="value">
          {{ oneItem.runnerNumber || '-' }}
        </div>
      </div>
      <div
        v-if="oneItem.hasMultiple"
        class="item"
      >
        <div class="name">
          {{ $t('TRADE_DOUBLED') }}
        </div>
        <div
          class="value"
        >
          {{ $t('PC_DATE_MULT',{chase:oneItem.chaseEach,mult:oneItem.chaseEachMultiple}) }}
        </div>
      </div>
    </div>
    <van-collapse
      v-model="activeNames"
      class="collapes__wrap"
    >
      <van-collapse-item name="1">
        <template#title>
          <div class="chase--stepwrap">
            <div
              class="step__info"
              :class="{show:activeNames.length===0}"
            >
              <div class="title">
                {{ $t('PC_BET_DETAILS') }}
              </div>
              <div class="order">
                {{ $t('WAREHOUSE_RECORDCENTER_CHASENUMBER_CHASE_END_TEXT',{num:filterScheme.length}) }}
              </div>
            </div>
          </div>
        </template#title>
        <div class="chase-step">
          <div class="chase-step__titlegroup">
            <div class="title order-set">
              {{ $t('PC_ISSUE') }}
            </div>
            <div class="title">
              {{ $t('TRADE_MULTIPLE') }}
            </div>
            <div class="title">
              {{ $t('ELEMENT_CASH') }}
            </div>
            <div class="title">
              {{ $t('TRADE_CHASE_EXPECTED_PRIZE_TIME') }}
            </div>
          </div>
          <div class="showScrollChaseNumber">
            <div
              v-for="(item) in filterScheme"
              :key="item.id"
              class="scroll__item"
            >
              <ChildItem :child-item="item" />
            </div>
          </div>
        </div>
      </van-collapse-item>
    </van-collapse>
    <div class="radio__group">
      <div class="radio-wrap">
        <div class="radio__item radio__item-set">
          <div class="item__title">
            {{ $t('TRADE_PROFIT_STOP') }}
          </div>
          <div class="item__info">
            <van-switch
              v-model="isStopAfterWinning"
            />
          </div>
        </div>
        <div class="radio__item">
          <div class="item__title">
            {{ $t('TRADE_PROFIT_CYCLE') }}
          </div>
          <div class="item__info">
            <van-switch
              v-model="autoBuyIfStopAfterWinning"
            />
          </div>
        </div>
      </div>
      <div class="rovoke__btns">
        <div
          class="btn cancel"
          :class="$i18n.locale"
          @click="cancelHandler"
        >
          {{ $t('CANCEL') }}
        </div>
        <div
          class="btn confirm"
          @click="submitBet"
        >
          {{ $t('CONFIRM') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getCountryicons,
  getSicons,
  countryIconList,
  specialIcons,
  stockResMsg
} from '@/utils'
import lotteryAPI from '@/api/lottery'
import ChildItem from './ChaseChildItem.vue'
import moment from 'moment'
export default {
  components: {
    ChildItem
  },
  props: {
    oneItem: {
      type: Object,
      required: true
    },
    slideItem: {
      type: String,
      default: () => ''
    }
  },
  data () {
    return {
      countryIconList,
      specialIcons,
      activeNames: ['1']
    }
  },
  computed: {
    isStopAfterWinning: {
      get () {
        return this.oneItem.isStopAfterWin
      },
      set (val) {
        this.$emit('changeStopAfterWinning', val)
      }
    },
    autoBuyIfStopAfterWinning: {
      get () {
        return this.oneItem.autoBuyIfStopAfterWinning
      },
      set (val) {
        this.$emit('changeAutoBuyIfStopAfterWinning', val)
      }
    },
    currentTag () {
      return this.$store.state.currentTag
    },
    filterScheme () {
      return this.oneItem.scheme.map((item, index) => ({
        ...item,
        prizeTime: moment(this.oneItem.prizeTime).add(this.oneItem.unitTime * index, 'minutes').format('YYYY-MM-DD hh:mm:ss')
      }))
    },
    getState () {
      if (this.oneItem.completeCount === this.oneItem.total) {
        if (this.oneItem.allBonus > 0) {
          return [this.$t('INVEST_PROFITABLE'), 'rise']
        } else {
          return [this.$t('INVEST_UNPROFITABLE'), 'fall']
        }
      } else {
        if (this.oneItem.allBonus > 0) {
          return [this.$t('INVEST_STOP_PROFIT'), 'stop']
        } else if (this.oneItem.state === 0) {
          return [this.$t('INVEST_WAITING_RELEASED'), 'pending']
        } else {
          return [this.$t('PROCESSING'), 'ing']
        }
      }
    },
    // 投資金額
    investmentAmount () {
      return this.oneItem.chaseMoney.toFixed(2)
    },
    // 派送盈利
    deliveryProfit () {
      return this.oneItem.allBonus.toFixed(2)
    },
    chaseMoney () {
      return Number(this.oneItem.completeMoney).toFixed(2)
    },
    champioAndRunner () {
      return this.oneItem.chaseScheme[0]
        ? this.oneItem.chaseScheme[0].betNum.split(',')
        : [0, 0]
    },
    stateText () {
      switch (this.oneItem.state) {
        case 0:
          return [this.$t('WAREHOUSE_CHASE_NUMBER_ITEM_WAIT'), 'wait']
        case 1:
          return [this.$t('WAREHOUSE_RECORDCENTER_CHASENUMBER_UNPROFITABLE'), 'unprofitable']
        case 2:
          return [this.$t('INVEST_PROFITABLE'), 'profitable']
        case 3:
          return [this.$t('INVEST_ORDER_CANCELLED'), 'cancelled']
        case 4:
          return [this.$t('INVEST_STOP_PROFIT'), 'stop']
        case 5:
          return [this.$t('INVEST_ORDER_CANCELLED'), 'cancelled']
        default:
          return [this.$t('IN_PROCESS'), 'ing']
      }
    }
  },
  methods: {
    stockResMsg,
    getSicons,
    getCountryicons,
    submitBet () {
      this.$emit('confirm')
    },
    cancelHandler () {
      this.$emit('cancel')
    },
    filterOption (val) {
      const optionTable = {
        定位胆: this.$t('TRADE_POSITIONING')
      }[val]
      return optionTable
    },
    async childItemAction (idValue, event) {
      this.childAction = true
      if (this.slideItem !== this.currentTag) {
        event.stopPropagation()
      } else {
        this.$emit('updateChild')
      }
      await lotteryAPI.getBetDetail({ id: idValue }).then((res) => {
        if (res.code === 1) {
          const apiData = res.data
          // this.checkChildempty = Object.keys(apiData).length !== 0 // 子曾資料不等於0
          // this.childChampionArray = res.data.betInfoList[0].betNum.split(',')// 冠亚军
          this.$emit('openChild', apiData, idValue)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.title__group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 20px;
  padding-left: 20px;
  margin-bottom: 15px;

  .icon {
    display: flex;
    justify-content: center;
    margin-right: 8px;
    width: 20px;
    height: 20px;

    >img {
      object-fit: fill;
    }
  }

  .title {
    font-size: 16px;
    color: var(--popupTitleColor);
    line-height: 22px;
  }

  .header {
    display: flex;
    align-items: center;
  }
}

.recordcode-set {
  color: var(--popupNameColor);
  -webkit-text-stroke: 0.3px;

  &.pending {
    color: #0589D7;
  }
}

.item-content {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding-right: 20px;
  padding-left: 20px;
  flex-wrap: wrap;
  margin-bottom: 12px;

  >.item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 48%;
    height: 24px;
    font-size: 12px;
    line-height: 17px;

    .name {
      color: var(--popupNameColor);
    }

    .value {
      color: var(--popupValueColor);
    }
  }
}

.rovoke__btns {
  display: flex;

  /* justify-content: flex-end; */

  >.btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 108px;
    height: 28px;
    font-size: 14px;
    border: 1px solid #879AAF;
    border-radius: 4px;
    line-height: 22px;
    box-sizing: border-box;

    &.ja {
      width: 84px;
    }

    &:hover {
      cursor: pointer;
    }

    &.cancel {
      @include confirm_btn_4;

      color: var(--popupRevokeBtnColor);
      border: var(--popupRevokeBtnBorder);
    }

    &.confirm {
      @include confirm_btn_5;

      margin-left: 8px;
      color: var(--popupRevokeBtnRevokeColor);
      border: 0;
    }
  }
}

.collapes__wrap {
  &::after {
    display: none;
  }

  &::v-deep {
    .van-cell {
      padding: 10px 20px;
      font-size: 12px;
      color: var(--popupChaseCollapseVanCellColor);
      background-color: transparent;

      &::after {
        display: none;
      }
    }

    .van-collapse-item__content {
      padding: 0;
      font-size: 12px;
      color: var(--popupChaseCollapseVanItemContentColor);
      background-color: transparent;
    }

    .van-collapse-item__title {
      position: relative;
      // border: 1px solid #F00;

      &::before {
        position: absolute;
        top: 0;
        left: 50%;
        width: calc(100%);
        height: 1px;
        background-color: var(--popupChaseCollapseVanTitleContentBgc);
        content: '';
        transform: translateX(-50%);
      }
    }

    .van-collapse-item__wrapper {
      display: block !important;
      transition-duration: 0.1s;
    }
  }
}

.step__info {
  position: relative;
  display: flex;
  justify-content: space-between;
  color: var(--popupChaseStepInfoColor);
  // border: 1px solid #F00;

  // &.show {
  //   &::after {
  //     position: absolute;
  //     bottom: -6px;
  //     left: 0;
  //     width: calc(100% + 20px);
  //     height: 1px;
  //     background-color: var(--popupChaseStepInfoShowBgc);
  //     content: '';
  //   }
  // }
  // border: 1px solid #000;
  .order {
    margin-right: 6px;
  }
}

.chase-step {
  background-color: var(--popupChaseChaseStepBgc);

  &__titlegroup {
    display: flex;
    align-items: center;
    overflow-x: hidden;
    padding-right: 20px;
    padding-left: 20px;
    height: 33px;
    font-size: 12px;

    >div {
      position: relative;
    }

    .order-set {
      position: relative;
      right: 0%;
      width: 25%;
    }

    .title {
      width: 20%;
      text-align: left;

      &:first-child,
      &:last-child {
        width: 30%;
      }
    }
  }
}

.scroll__item {
  display: flex;
  align-items: center;
  height: 24px;
  cursor: pointer;

  &:hover {
    background-color: #1E2E48;
  }

  &.complete {
    .issue {
      color: var(--popupChaseIssueColor);
    }
  }

  .opennum {
    &.haveValue {
      color: var(--popupChaseHaveValueColor);
    }
  }

  >div {
    flex: 1;
  }
}

.showScrollChaseNumber {
  overflow-y: scroll;
  height: 145px;
  // border: 1px solid #000;
}

.chase-state {
  color: var(--popupNameColor);
  -webkit-text-stroke: 0.3px;

  &.pending {
    color: var(--popupChaseStatePendingColor) !important;
  }

  &.fall {
    color: var(--popupChaseStateFallColor) !important;
  }

  &.rise {
    color: var(--popupChaseStateRiseColor) !important;
  }

  &.ing {
    color: var(--popupChaseStateIngColor) !important;
  }
}

.sned-bones {
  color: var(--popupNameColor);

  &.wait {
    color: var(--popupNameColor) !important;
  }
}

.radio__group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  height: 64px;
  font-size: 12px;
  border-top: var(--popupRadioBorder);

  .radio-wrap {
    display: flex;
    align-items: center;
  }
}

.radio__item {
  display: flex;
  align-items: center;

  &-set {
    margin-right: 16px;
  }

  .item__title {
    margin-right: 8px;
    color: var(--popupChaseRadioItemTitleColor);
  }

  &::v-deep {
    .van-switch {
      font-size: 17px;
      background-color: var(--popupChaseRadioItemVanSwitchBgc);
    }

    .van-switch--on {
      background-color: #0589D7;
    }

    .van-switch__node {
      top: 1px;
      // width: 0.9em;
      // height: 0.9em;
      width: 15px;
      height: 15px;;
    }
  }
}
</style>
