import { extend, ValidationProvider, ValidationObserver } from 'vee-validate'
import { min_value as minVal, max_value as maxVal, numeric, required, double } from 'vee-validate/dist/rules'

export default {
  install (Vue) {
    Vue.component('ValidationProvider', ValidationProvider)
    Vue.component('ValidationObserver', ValidationObserver)
    extend('min_value', {
      ...minVal,
      message: 'PC_RECHARGE_MIN_ERROR'
    })
    extend('max_value', {
      ...maxVal,
      message: 'PC_RECHARGE_MAX_ERROR'
    })
    extend('numeric', {
      ...numeric,
      message: 'PC_RECHARGE_NUMBER_ERROR'
    })
    extend('required', {
      ...required,
      message: 'PC_RECHARGE_NOT_EMPTY'
    })
    extend('double', {
      ...double,
      message: 'PC_RECHARGE_NUMBER_ERROR'
    })
    extend('mobile', {
      message: '長度格式錯誤',
      validate: value => value.length >= 10
    })
  }
}
