// 用户
export const SET_USER_INFO = 'SET_USER_INFO' // 设置用户信息
export const SET_REBATE = 'SET_REBATE' // 用户返点

// 安全中心
export const SET_SECURITY_LIST = 'SET_SECURITY_LIST'
// 交易记录
export const SET_DATE_TYPE = 'SET_DATE_TYPE'

// 直播
export const SET_ANCHOR_INFO = 'SET_ANCHOR_INFO'
export const SET_GAME_CONFIG = 'SET_GAME_CONFIG'
export const SET_BARRAGE_LIST = 'SET_BARRAGE_LIST'
export const SET_ACTIVE_GIFT = 'SET_ACTIVE_GIFT'
export const SET_BROAD_CAST_DATA = 'SET_BROAD_CAST_DATA'
export const SET_RANDOM_BARRAGE = 'SET_RANDOM_BARRAGE'

// 主題風格
export const SET_THEME_VALUE = 'SET_THEME_VALUE'
// 彩票開獎彈跳視窗開關設定
export const SET_HAS_NOTIFY = 'SET_HAS_NOTIFY'
// 彩票開獎彈跳視窗 icon 綁定（目前只有限定 iconfont）
export const SET_NOTIFY_ICON_NAME = 'SET_NOTIFY_ICON_NAME'
// 彩票開獎彈跳視窗內容
export const SET_NOTIFY_CONTENT = 'SET_NOTIFY_CONTENT'
