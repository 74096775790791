import { rest } from './index'
import md5 from 'js-md5/build/md5.min'
import { getUsername } from '@/utils'
const Base64 = require('js-base64').Base64
const Api = {
  // 登录用户
  isLogin: '/v1/users/isLogin',
  login: '/v1/users/login',
  logout: '/v1/users/logout',
  checkUser: '/v1/users/checkUser',
  checkInviteCode: '/v1/users/checkInviteCode', // 迁移
  checkOldNewUser: '/v1/users/checkOldNewUser', // 迁移
  getNchanKey: 'v1/users/getNchanKey', // ws token
  getTenantConfig: '/v1/management/tenant/getTenantConfig',
  userInfo: '/v1/users/info',
  register: '/v1/users/register',
  validateCode: '/v1/users/imageCode',
  userExperienceData: '/v1/users/userExperienceData',
  // 用户余额
  userBalance: '/v1/balance/queryBalanceFront',
  // 修改用户基本信息
  updateUserInfo: '/v1/users/infoUpdate',
  // 修改用戶基本訊息new
  updateUserInfo2: '/v1/users/infoUpdate2',
  defaultAvatar: '/v1/users/defaultAvatar',
  // 安全码中心
  destructionCheckBankCard: '/v1/users/destructionCheckBankCard', // 删除验证码
  isChangeSafetyPassword: '/v1/users/isChangeSafetyPassword', // 公告用的安全密码修改
  securityList: '/v1/users/securityList',
  safetyVerify: '/v1/users/verifySafetyPassword',
  setSafetyPassword: '/v1/users/setSafetyPassword',
  resetSecurityCode: '/v1/users/resetSafetyPassword',
  forgotPassword: '/v1/users/forgetPassword',
  forgotPasswordReset: '/v1/users/forgetPasswordReset',
  resetPassword: '/v1/users/resetPassword',
  phoneCode: '/v1/users/sendPhoneCode',
  setPhoneNum: '/v1/users/verifyPhoneCode',
  verifySafetyQuestion: '/v1/users/verifySafetyQuestion',
  setSafetyQuestion: '/v1/users/setSafetyQuestion',
  getQuestionList: '/v1/users/safeQuestionList',
  mailCode: '/v1/users/sendMailCode',
  verifyMailCode: '/v1/users/verifyMailCode',
  /**
   * 人工申述
   * */
  // 验证登录密码
  verifyPassword: '/v1/users/verifyPassword',
  getFirstLockBankCard: '/v1/users/getFirstLockBankCard',
  uploadImage: '/v1/users/uploadImage',
  // 校验银行卡
  safetyCheckBankCard: '/v1/users/safetyCheckBankCard',
  // 银行卡管理
  bankList: '/v1/users/getBankCardListFront',
  bankCardDetail: '/v1/users/findUsersBankCardDetailsById',
  checkBankCard: '/v1/users/checkBankCard',
  addBankCard: '/v1/users/addBankCard',
  updateBankCardFront: '/v1/users/updateBankCardFront',
  queryBankCardName: '/v1/users/queryBankCardName',
  queryBankInfo: '/v1/users/queryBankInfo', // 获取可设置银行卡列表
  /**
   * 报表
   * */
  userProfit: '/v1/report/userReport/userProfit',
  userAgentReport: '/v1/report/agentReport/userAgentReport',
  // 团队余额
  userAgentReport2: '/v1/report/agentReport/userAgentReport2',
  teamBalance: '/v1/report/agentReport/teamBalance',
  userProfitRanking: '/v1/report/userReport/userProfitRank',
  getNewestBounsList: '/v1/betting/getNewestBounsList',
  childReport: '/v1/report/agentReport/childReport',
  // 邀请码删除
  deleteInviteCode: '/v1/users/deleteInviteCode',
  // 个人简易信息
  playerInfo: '/v1/users/card',
  createInviteCode: '/v1/users/createInviteCode',
  rebate: '/v1/users/rebate',
  inviteCode: '/v1/users/inviteCode',
  // 会员管理
  userManage: '/v1/users/userManage',
  userManageList: '/v1/users/userManageList2',
  /**
   * 我的消息
   * */
  // 站内信，私信
  unReadMessage: '/v1/users/unReadMessage',
  messageList: '/v1/users/myMessageList',
  messageContent: '/v1/users/messageContent',
  setMessageRead: '/v1/users/setMessageRead',
  deleteMessage: '/v1/users/deleteMessage',
  longDragon: '/v1/lottery/longDragon',
  checkUnreadMessage: '/v1/chat/checkUnreadMessage',
  // 路由埋点，数据推送
  dataPush: '/v1/statistics/push',
  yyDataPush: '/v1/statistics/push/yy',
  // 注册验证初始化状态
  getRegisterVerifyFront: '/v1/management/tenant/getRegisterVerifyFront',
  getNewRegisterVerifyFront: '/v1/management/tenant/getNewRegisterVerifyFront',
  getRegisterTypeFront: '/v1/management/tenant/getRegisterTypeFront',
  detail: '/v1/report/agentReport/userAgentReport/detail',
  uploadImageFiles: '/v1/files/uploadImageFiles',
  // 上傳人工審核圖片
  uploadAuditImages: '/v1/files/uploadAuditImages',
  // 串号接口
  saveLogsTable: '/v1/management/content/saveLogsTable',
  /**
   * 每日簽到
   * */
  // 簽到所有資訊
  getDailySignStatus: '/v1/activity/getDailySignStatus',
  // 今日簽到
  signToday: '/v1/activity/signToday',
  // 盈利返点
  getProfitRebate: '/v1/users/getProfitRebate',
  setProfitRebate: '/v1/users/setProfitRebate',
  // 前台-本日盈虧-每小時刻度
  userProfit2: '/v1/report/userReport/userProfit2',
  // 前台-代理中心-每小時刻度
  newUserAgentReport: 'v1/report/agentReport/newUserAgentReport',
  // 前台-代理中心-查看更多
  newUserAgentReportDetail: '/v1/report/agentReport/newUserAgentReport/detail'
}
export function addMd5 (username, password) {
  return md5(username.toLowerCase() + md5(password))
}

function delRebate () {
  localStorage.removeItem('newRebateFC3D')
  localStorage.removeItem('newRebateK3')
  localStorage.removeItem('newRebateSSC')
  localStorage.removeItem('newRebatePK10')
  localStorage.removeItem('newRebatePL35')
  localStorage.removeItem('newRebateLHC')
  localStorage.removeItem('newRebateSYX5')
  localStorage.removeItem('newRebateKL8')
  localStorage.removeItem('newRebateBJ28')
}
export default {
  /*
  * 用户服务 --- 站点初始化 getTenantConfig
  * **/
  getTenantConfig () {
    return rest.get(Api.getTenantConfig)
  },
  isLogin () {
    return rest.get(Api.isLogin)
  },
  /*
  * 用户服务 ---用户登录
  * **/
  Login (params) {
    delRebate()
    const random = `fiami-${Math.random()}`
    const cfg = { random: Base64.encode(random) }
    const password = addMd5(params.userName, params.password)
    localStorage.setItem('temporaryPass', password) // 临时密码
    // params.password = password
    params.password = md5(password + random)
    params.random = cfg.random
    return rest.post(Api.login, params)
  },
  LoginQuickly (params) {
    const random = `fiami-${Math.random()}`
    const cfg = { random: Base64.encode(random) }
    params.password = md5(params.password + random)
    params.random = cfg.random
    return rest.post(Api.login, params)
  },
  logout () {
    delRebate()
    return rest.get(Api.logout)
  },
  Register (params) {
    params.password = addMd5(params.userName, params.password)
    return rest.post(Api.register, params)
  },
  checkUser (params) {
    return rest.get(Api.checkUser, params)
  },
  // 邀请码校验 迁移数据
  checkInviteCode (params) {
    return rest.get(Api.checkInviteCode, params)
  },
  // 校验用户 迁移数据
  checkOldNewUser (params) {
    return rest.get(Api.checkOldNewUser, params)
  },
  // get ws token
  getNchanKey (params) {
    return rest.get(Api.getNchanKey, params)
  },
  // 验证码
  getValidateCode (param) {
    return rest.get(Api.validateCode, param)
  },
  destructionCheckBankCard () {
    return rest.post(Api.destructionCheckBankCard)
  },
  // 用户信息
  getUserInfo () {
    return rest.get(Api.userInfo)
  },
  // 用户等级头衔
  userExperienceData () {
    return rest.get(Api.userExperienceData)
  },
  // 用户余额
  userBalance () {
    return rest.get(Api.userBalance)
  },
  // 修改用户信息
  updateUserInfo (param) {
    return rest.post(Api.updateUserInfo, param)
  },
  // 修改用户信息-new
  updateUserInfo2 (param) {
    return rest.create(Api.updateUserInfo2, param)
  },
  // 获取用户默认头像
  defaultAvatar () {
    return rest.get(Api.defaultAvatar)
  },
  // 公告
  isChangeSafetyPassword () {
    return rest.get(Api.isChangeSafetyPassword)
  },
  // 安全中心列表
  securityList () {
    return rest.get(Api.securityList)
  },
  // 安全码校验
  safetyVerify (param) {
    param.safetyPassword = addMd5(getUsername(), param.safetyPassword)
    return rest.post(Api.safetyVerify, param)
  },
  safetyVerify2 (param) {
    param.safetyPassword = addMd5(getUsername(), param.safetyPassword)
    return rest.post2(Api.safetyVerify, param)
  },
  setSafetyPassword (params) {
    params.safetyPassword = addMd5(getUsername(), params.safetyPassword)
    return rest.post(Api.setSafetyPassword, params)
  },
  // 重设安全码校验
  resetSafetyCode (param) {
    param.safetyPassword = addMd5(getUsername(), param.safetyPassword)
    return rest.post(Api.resetSecurityCode, param)
  },
  // 忘记登录密码
  forgotPassword (param) {
    return rest.post(Api.forgotPassword, param)
  },
  // 忘记密码 =》 密码重新设置
  forgotPasswordReset (params) {
    params.password = addMd5(params.userName, params.password)
    return rest.post(Api.forgotPasswordReset, { password: params.password })
  },
  // 重设密码
  resetPassword (params) {
    const username = getUsername()
    params.oldPassword = addMd5(username, params.oldPassword)
    params.password = addMd5(username, params.password)
    localStorage.setItem('temporaryPass', params.password) // 临时密码
    return rest.post(Api.resetPassword, params)
  },
  // 发送手机验证码
  getPhoneCode (params) {
    return rest.post(Api.phoneCode, params)
  },
  // 绑定手机号码
  setPhoneNumber (params) {
    return rest.post(Api.setPhoneNum, params)
  },
  // 验证密保问题
  verifySafetyQuestion (parame) {
    return rest.post(Api.verifySafetyQuestion, parame)
  },
  // 设置密保问题
  setSafetyQuestion (parame) {
    return rest.post(Api.setSafetyQuestion, parame)
  },
  // 获取所有密保问题
  getQuestionList () {
    return rest.get(Api.getQuestionList)
  },
  // 发送邮箱验证码
  sendMailCode (param) {
    return rest.post(Api.mailCode, param)
  },
  // 验证邮箱验证码
  verifyMailCode (params) {
    return rest.post(Api.verifyMailCode, params)
  },
  // 银行卡管理
  bankList () {
    return rest.get(Api.bankList)
  },
  // 验证锁定银行卡
  checkBankCard (params) {
    return rest.post(Api.checkBankCard, params)
  },
  // 修改银行卡
  updateBankCardFront (params) {
    return rest.post(Api.updateBankCardFront, params)
  },
  // 根据id 获取详细信息
  bankCardDetail (param) {
    return rest.get(Api.bankCardDetail, param)
  },
  // 添加银行卡
  addBankCard (params) {
    return rest.post(Api.addBankCard, params)
  },
  // 获取绑定的银行卡账户名称
  queryBankCardName (params) {
    return rest.get(Api.queryBankCardName, params)
  },
  // 获取可设置银行卡列表
  queryBankInfo (params) {
    return rest.get(Api.queryBankInfo, params)
  },
  /**
   * 人工申述
   * */
  // 验证登录密码
  verifyPassword (params) {
    const random = `dafacloud_${Math.random()}`
    const cfg = { random: Base64.encode(random) }
    const param = {
      password: md5(addMd5(getUsername(), params.password) + random),
      random: cfg.random
    }
    return rest.post(Api.verifyPassword, param)
  },
  uploadImageFiles (parame) {
    return rest.create(Api.uploadImageFiles, parame)
  },
  // 上傳人工審核圖片
  uploadAuditImages (parame) {
    return rest.create(Api.uploadAuditImages, parame)
  },
  uploadImage (params) {
    return rest.post(Api.uploadImage, params)
  },
  // 获取第一张银行卡
  getFirstLockBankCard () {
    return rest.get(Api.getFirstLockBankCard)
  },
  // 校验银行卡
  safetyCheckBankCard (params) {
    return rest.post(Api.safetyCheckBankCard, params)
  },
  /**
   *报表
   */
  userProfit () {
    return rest.get(Api.userProfit)
  },
  // 代理报表
  userAgentReport (param) {
    return rest.get(Api.userAgentReport, param)
  },
  // 代理报表-new
  userAgentReport2 (param) {
    return rest.get(Api.userAgentReport2, param)
  },
  // 团队余额
  teamBalance (param) {
    return rest.get(Api.teamBalance, param)
  },
  // 下级报表
  childReport (params) {
    return rest.get(Api.childReport, params)
  },
  // 昨日盈利版
  userProfitRanking () {
    return rest.get(Api.userProfitRanking)
  },
  // 中奖信息
  getNewestBounsList () {
    return rest.get(Api.getNewestBounsList)
  },
  // 玩家信息
  playerInfo (params) {
    return rest.get(Api.playerInfo, params)
  },
  // 下级开户，生成邀请码
  createInviteCode (params) {
    return rest.post(Api.createInviteCode, params)
  },
  // 获取个人返点
  rebate (params) {
    return rest.get(Api.rebate, params)
  },
  // 邀请码列表
  inviteCode (params) {
    return rest.get(Api.inviteCode, params)
  },
  deleteInviteCode (params) {
    return rest.post(Api.deleteInviteCode, params)
  },
  // 会员管理
  userManage (params) {
    return rest.get(Api.userManage, params)
  },
  userManageList (params) {
    return rest.get(Api.userManageList, params)
  },
  /**
   * 我的消息
   * */
  // 未读消息
  // 未读消息
  unReadMessage () {
    return rest.get(Api.unReadMessage)
  },
  // 站内信 =》 私信(含内容)
  messageList (params) {
    return rest.get(Api.messageList, params)
  },
  // 站内信内容
  messageContent (params) {
    return rest.get(Api.messageContent, params)
  },
  // 标记为已读
  setMessageRead (params) {
    return rest.post(Api.setMessageRead, params)
  },
  // 删除站内信
  deleteMessage (params) {
    return rest.post(Api.deleteMessage, params)
  },
  longDragon (params) {
    return rest.get(Api.longDragon, params)
  },
  checkUnreadMessage (params) {
    return rest.get(Api.checkUnreadMessage, params)
  },
  // 数据推送
  dataPush (params) {
    return rest.post(Api.dataPush, params)
  },
  // 数据推送
  yyDataPush (params) {
    return rest.post(Api.yyDataPush, params)
  },
  // 注册验证初始化显示
  getRegisterVerifyFront (params) {
    return rest.get(Api.getRegisterVerifyFront, params)
  },
  getNewRegisterVerifyFront (params) {
    return rest.get(Api.getNewRegisterVerifyFront, params)
  },
  getRegisterTypeFront (params) {
    return rest.get(Api.getRegisterTypeFront, params)
  },
  // 代理報表 細項
  detail (params) {
    return rest.get(Api.detail, params)
  },
  saveLogsTable (params) {
    return rest.post(Api.saveLogsTable, params)
  },
  /**
   * 每日簽到
   * */
  getDailySignStatus (params) {
    return rest.get(Api.getDailySignStatus, params)
  },
  signToday (params) {
    return rest.post(Api.signToday, params)
  },
  /**
   * 盈利返点
   * */
  getProfitRebate (params) {
    return rest.get(Api.getProfitRebate, params)
  },
  setProfitRebate (params) {
    return rest.post(Api.setProfitRebate, params)
  },
  getProfit2 () {
    return rest.get(Api.userProfit2)
  },
  getUserAgentReport () {
    return rest.get(Api.newUserAgentReport)
  },
  getUserAgentReportDetail (params) {
    return rest.get(Api.newUserAgentReportDetail, params)
  }
}
