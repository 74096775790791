<template>
  <div
    v-if="!checkEmpty"
    class="childitem__group"
    :class="$i18n.locale"
  >
    <div
      class="order childitem"
      :class="[{ing:stateText[1]==='wait'||stateText[1]==='ing'||stateText[1]==='stop'}]"
    >
      {{ childItem.issue }}
    </div>
    <div class="money childitem">
      {{ stateText[2] }}
    </div>
    <div class="opennumber childitem">
      {{ openNumberWait }}
    </div>
    <div
      class="bones childitem"
      :class="[stateText[1]]"
    >
      {{ stateText[3] }}
    </div>
    <div
      class="state childitem"
      :class="[stateText[1]]"
    >
      {{ stateText[0] }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    childItem: {
      type: Object,
      required: true
    }
  },
  computed: {
    checkEmpty () {
      const empty = Object.keys(this.childItem).length === 0
      return empty
    },
    stateText () {
      switch (this.childItem.state) {
        /**
         *@index0 -注单状态
         *@index1 -注单状态-class
         *@index2 -投资金额
         *@index3 -盈利金额
         */
        case 0:
          return [this.$t('INVEST_WAITING_RELEASED'), 'wait', '', '']
        case 1:
          return [this.$t('INVEST_UNPROFITABLE'), 'unprofitable', this.childItem.betMoney, 0]
        case 2:
          return [this.$t('INVEST_PROFITABLE'), 'profitable', this.childItem.betMoney, this.childItem.bonus]
        case 3:
          return [this.$t('INVEST_ORDER_CANCELLED'), 'cancelled', this.childItem.betMoney, 0]
        case 4:
          return [this.$t('INVEST_STOP_PROFIT'), 'stop', this.childItem.betMoney, this.childItem.bonus]
        case 5:
          return [this.$t('INVEST_ORDER_CANCELLED'), 'cancelled', this.childItem.betMoney, 0]
        default:
          return [this.$t('IN_PROCESS'), 'ing', '', '']
      }
    },
    openNumberWait () {
      return this.childItem.openNum === '-' ? '-- -- --' : this.childItem.openNum
    }
  }
}
</script>

<style lang="scss" scoped>
.childitem__group {
  display: flex;
  align-items: center;
  overflow-x: hidden;
  padding-right: 15px;
  padding-left: 20px;
  height: 24px;
  font-size: 12px;
  transition: 0.5s;

  &:hover {
    background-color: var(--popupChaseChildItemHoverBgc);
  }

  >div {
    position: relative;
    right: -6%;
    width: 18.75%;
    // flex: 1;
    color: var(--popupChaseChildItemDivColor);
  }

  .order {
    position: relative;
    right: 0%;
    width: 25%;
    color: var(--popupChaseChildItemOrderColor);

    &.ing {
      color: var(--popupChaseChildItemOrderIngColor);
    }
  }

  &.vie {
    >div {
      right: -4.5%;
    }

    .order {
      right: 0;
    }
  }
}

.bones {
  &.profitable {
    color: var(--popupChaseChildItemBonesColor);
  }
}

.state {
  &.wait {
    color: var(--popupChaseChildItemWaitColor);
  }

  &.unprofitable{

  }

  &.profitable {
    color: var(--popupChaseChildItemProfitableColor);
  }

  &.cancelled{

  }

  &.stop{

  }

  &.ing{

  }
}
</style>
