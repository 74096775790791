<template>
  <div
    id="user-ware"
    class="warehouseReceipt"
  >
    <van-tabs
      v-model="active"
      type="line"
      class="index__tabbtns"
      :class="[{ hasZero: (active === 0 && !loading && orderList.length === 0) || (active === 1 && !loading && accountList.length === 0) },tabWidth,$i18n.locale]"
      sticky
      line-height="0"
      @change="changeTab"
    >
      <van-tab
        v-for="text of recordText"
        :key="text.id"
        :title="text.name"
      >
        <!-- 選單 -->
        <div class="select__area">
          <!-- 含追號按鈕↓ -->
          <ChaseTabsGroup
            :ref="text.data"
            :num="active"
            @chaseSelectValue="chaseTypeHandler"
          />
        </div>
        <!-- 標題 -->
        <div class="record-list-title">
          <!-- 投資紀錄一般↓ -->
          <BaseTitle v-if="memoryGeneraChaseType===0" />
          <!-- 投資紀錄追號↓ -->
          <ChaseTitle v-if="memoryGeneraChaseType===1" />
        </div>
        <!-- 列表 -->
        <div
          class="record-list-wrap"
          @scroll="scrollEvent"
        >
          <van-list
            v-model="loading"
            class="record-list"
            :class="{full:!loading && orderList.length === 0}"
            :finished="finished"
            offset="60"
            :immediate-check="false"
            @load="onLoad"
          >
            <span slot="loading" />
            <div
              v-if="loading"
              class="d-flex align-center justify-center loading-wrap"
            >
              <Loading />
            </div>
            <div
              v-for="item in filterOrderedList"
              :key="item.id"
              class="item"
              @click="popWindInvestmentFn(item.id, item.state)"
            >
              <!-- 投資紀錄一般↓ -->
              <BaseItem
                v-if="memoryGeneraChaseType===0"
                :item-data="item"
              />
              <!-- 投資紀錄追號↓ -->
              <ChaseItem
                v-if="memoryGeneraChaseType===1"
                :item-data="item"
              />
            </div>
            <!-- <div
              v-if="!loading && orderList.length > 0"
              class="finished"
            >
              已显示{{ dayText(dateSelectValue) }}全部记录
            </div> -->
            <div
              v-if="!loading && filterOrderedList.length === 0"
              class="empty-reminder"
            >
              <img
                src="/static/images/userware/empty.svg"
                alt=""
              >
              {{ $t('PC_NO_DATA') }}
            </div>
          </van-list>
        </div>
      </van-tab>
    </van-tabs>
    <FadeSlot>
      <DefPop
        :father-method="accountClassColor"
        :props-show-hide="popDef"
        :props-one-def-data="popDefOne"
        @close-pop="closeDefPop"
      />
    </FadeSlot>
    <van-notify
      v-model="routerNotify"
      class="router-use"
      get-container="body"
    >
      <div class="main__show">
        <div class="text__show">
          <i18n
            path="PC_SUMBIT_ORDER"
            tag="p"
          >
            <br>{{ $t('PC_SUMBIT_ORDER2') }}
          </i18n>
        </div>
        <!-- <img
          src="/static/images/userInfo/notify-ok.svg"
          alt="ok"
        > -->
      </div>
    </van-notify>
    <!-- 父談窗外框 -->
    <PopupBase
      ref="basePopup"
      :popup-title="popupTitle"
      :slide-item="'parentpopup'"
      :class="[{'chase-set':memoryGeneraChaseType===1&&active===0}]"
      :has-overlay="true"
      @click="$store.dispatch('actionSetCurrentTag', 'parentpopup')"
      @closeAll="closeAll"
    >
      <!-- this.memoryGeneraChaseType === 0一般
      this.memoryGeneraChaseType === 1追號
      active==0 頭資 帳務1  -->
      <BasePopup
        v-if="memoryGeneraChaseType===0&&active===0"
        :one-item="oneItemData"
        :revoke-id="revokeId"
        :revoke-state="revokeState"
        :cancel-order-mode="cancelOrderMode"
        @cancelState="closePopup"
        @revokeHandler="revokePop"
      />
      <ChasePopup
        v-if="memoryGeneraChaseType===1&&active===1"
        :revoke-id="revokeId"
        :slide-item="'parentpopup'"
        :one-item="popChaseNumberOne"
        @openChild="childActionData"
        @updateChild="updateChild"
        @changePlayType="reNewPlayTypeHandler"
      />
    </PopupBase>
    <!-- 子談窗外框 -->
    <PopupBase
      ref="childPopup"
      class="childpopup"
      :slide-item="'childpopup'"
      :popup-title="$t('PC_BET_DETAILS')"
    >
      <BasePopup
        :one-item="childActiveData"
        :cancel-order-mode="childCancelOrderMode"
        :revoke-state="revokeState"
        :revoke-id="childActiveId"
        @cancelState="closeChildPopup"
        @revokeHandler="childRevokePop"
      />
    </PopupBase>
  </div>
</template>

<script>
import axios from 'axios'
import lotteryAPI from '@/api/lottery'
import apiManage from '@/api/manage'
import tradesAPI from '@/api/trades'
import DefPop from './def.vue'
import FadeSlot from './fadeslotindex.vue'
import Loading from '@/components/loader/GlobalLoading'
import {
  getCountryicons,
  getSicons,
  countryIconList,
  specialIcons,
  checkCatchTime
} from '@/utils'
import moment from 'moment'
import { mapState } from 'vuex'
// 新共用組
import PopupBase from '@/components/Popup/index.vue'
// 新組建
// import TabsGroup from './components/SelectBtns.vue'
import ChaseTabsGroup from './components/ChaseNumberBtns.vue'
// 一般選項
import BaseTitle from './list/Title/baseTitle.vue'
import BaseItem from './list/base.vue'
// 追號選項
import ChaseTitle from './list/Title/ChaseNumberTitle.vue'
import ChaseItem from './list/ChaseNumber.vue'
// 帳務紀錄全部
// 帳務紀錄出金
// 帳務紀錄入金
// popup
import BasePopup from './popup/Base.vue'
import ChasePopup from './popup/Chase.vue'

let activeStatus = false

export default {
  components: {
    // VantSelect,
    DefPop,
    Loading,
    FadeSlot,
    // UserWareSelect,
    // ChildAction,
    // TabsGroup,
    ChaseTabsGroup,
    BaseTitle,
    BaseItem,
    ChaseTitle,
    ChaseItem,
    PopupBase,
    BasePopup,
    ChasePopup
  },
  data () {
    return {
      cacheDate: null,
      hasAccountDetail: false, // 帳務詳細紀錄開關
      accountData: {},
      paymentState: '',
      isDisabled: false,
      hasUpdating: false,
      loading: false,
      finished: false, // 紀錄是否讀完全部資料
      pageNum: 1, // 投資紀錄頁數
      listPageSize: 10, // 投資紀錄數量/頁
      latestPageNum: 1,
      memoryGeneraChaseType: 0, // 預設0追號1
      typeSelectValue: '-1',
      accountTypeSelectValue: '1',
      dateSelectValue: 'today',
      hasChangeDateType: false,
      accountSelectValue: 'today',
      // active: 0,
      investmentPopshow: false,
      countryIconList,
      specialIcons,
      orderList: [],
      accountList: [],
      oneItemData: {},
      popPlayType: '',
      popChampio: '',
      popRunner: '',
      revokeId: '',
      revokeState: false, // 撤单用
      popBaseEmpty: {}, // 清空使用
      popDef: false,
      popDefOne: {}, // 全部單筆資料
      revoke: false,
      revokeText: '',
      popChaseNumberOne: {}, // 追號使用
      patchUser: '', // 跳轉用
      routerNotify: false, /// 入由用
      childActiveData: null, // 打開子向action
      childActiveId: 0,
      popupTitle: '', // popup標題
      popupState: '', // 記憶用更改營利即停or營利循環用
      pubId: null,
      childPopupState: false// 記憶是否雙開
    }
  },
  computed: {
    ...mapState(['hasProfitTable', 'isSafari', 'hasProfitTableMore', 'betOrderList', 'chaseBetOrderList', 'recordActive', 'optionSides']),
    active: {
      get () {
        return this.recordActive
      },
      set (val) {
        this.$store.commit('SET_RECORD_BTN_ACTIVE', val)
      }
    },
    recordText () {
      return [{
        id: 1,
        name: this.$t('TRADE_LIST_TAB_TITLE_BASE'),
        data: 'basebtnData'
      }, {
        id: 2,
        name: this.$t('TRADE_LIST_TAB_TITLE_CHASE'),
        data: 'chaseBtnData'
      }]
    },
    // 更新顯示列表
    filterOrderedList () {
      return this.orderList.map((item) => {
        if (this.betOrderList[item.id]) {
          return {
            ...item,
            ...this.betOrderList[item.id]
          }
        }

        if (this.chaseBetOrderList[item.id]) {
          return {
            ...item,
            ...this.chaseBetOrderList[item.id],
            completeCount: `${this.chaseBetOrderList[item.id].completeCount}/${item.completeCount.split('/')[1]}`,
            completeMoney: `${this.chaseBetOrderList[item.id].completeMoney}/${item.completeMoney.split('/')[1]}`
          }
        }
        return item
      })
      // return this.orderList
    },
    // 帳務紀錄
    filterAccountList () {
      return this.accountList
    },

    cancelOrderStockD11 () {
      return this.$store.state.siteConfig.cancelOrderStockD11
    },
    cancelOrderStockA11 () {
      return this.$store.state.siteConfig.cancelOrderStockA11
    },
    cancelOrderMode () {
      if (this.oneItemData && this.oneItemData.betInfoList && this.oneItemData.betInfoList[0]) {
        return this.oneItemData.betInfoList[0].playName === '小数,双面盘' ? this.cancelOrderStockD11 : this.cancelOrderStockA11
      }
      return 1
    },
    childCancelOrderMode () {
      if (this.childActiveData && this.childActiveData.betInfoList && this.childActiveData.betInfoList[0]) {
        return this.childActiveData.betInfoList[0].playName === '小数,双面盘' ? this.cancelOrderStockD11 : this.cancelOrderStockA11
      }
      return 1
    },
    siteConfig () {
      return this.$store.state.siteConfig
    },
    tabWidth () {
      const { stockSide, trendSide } = this.optionSides
      // true打開 false關閉
      const stateTure = stockSide === true && trendSide === true
      const stateFalse = stockSide === false && trendSide === false
      if (stateTure) return 'min'
      else if (stateFalse) return 'max'
      else return ''
    }
  },
  watch: {
    active () {
      activeStatus = true
    }
  },
  beforeDestroy () {
    this.$store.commit('SET_RECORD_BTN_ACTIVE', 0)
  },
  created () {
    // this.accountingAPI()
    apiManage.getFrontCacheUpdatedAt().then((res) => {
      if (res.code === 1) {
        checkCatchTime(res.data.pageUpdateTimeMobile)
        this.cacheDate = res.data
        localStorage.setItem('cacheTime', JSON.stringify(res.data.tenantConfig))

        const lottery = localStorage.getItem('lotteryConfig')
        if (!lottery) {
          lotteryAPI.lotteryConfig().then(ref => {
            if (ref.code === 1) {
              const obj = {}
              obj[this.cacheDate.allLotteryDataFront] = ref.data
              localStorage.setItem('lotteryConfig', JSON.stringify(obj))
            }
          })
        }
      }
    })
    this.investmentAPI()
  },
  methods: {
    getSicons,
    getCountryicons,
    closeAll () {
      this.$refs.childPopup && this.$refs.childPopup.closePopupHandler()
    },
    // pc-handler
    chaseTypeHandler (useType, selectValue, dayValue, add) {
      /**
       * 投資紀錄用
       * @useType 0 -> 一般, 1 -> 追號
       * @selectValue
       *  - 一般 - 0全部，1已盈利，2未盈利，3等待出倉
          - 追號 - 0全部，1未開始，2進行中，3已結束
       * @dayValue today, yesterday, sevenday
       */
      // setTimeout(() => {
      //   this.memoryGeneraChaseType = useType
      // }, 1000)
      // 關閉詳情紀錄
      const hockpopup = this.$refs.basePopup
      hockpopup.closePopupHandler()
      this.investDefault(add)
      this.dateSelectValue = dayValue
      if (useType === 1) {
        this.typeSelectValue = selectValue
        // 追號
        this.postChaseNumberInvestmentAPI()
      } else {
        // 一般
        const stateTable = {
          1: '-1',
          2: 2,
          3: 1,
          4: 0
        }
        this.typeSelectValue = stateTable[selectValue]
        this.postInvestmentAPI()
      }
      this.memoryGeneraChaseType = useType
    },
    // pc-handler
    getWithdrawTime (val) {
      return moment(val).format('YYYY-MM-DD hh:mm:ss')
    },
    saveQrCodeImg () {
      const picUrl = this.$refs.qrCodeUrl.src
      axios({
        url: picUrl,
        methods: 'GET',
        responseType: 'blob'
      }).then((res) => {
        const fileUrl = window.URL.createObjectURL(new Blob([res.data]))
        const fileLink = document.createElement('a')
        fileLink.href = fileUrl
        fileLink.setAttribute('download', 'image.jpg')
        document.body.appendChild(fileLink)
        fileLink.click()
      })
    },
    qrCodeShowHide (value) {
      if (value !== undefined && value.length > 0) {
        return true
      } else {
        return false
      }
    },
    copyRecordCodeFn (val) {
      this.$refs[val].select()
      document.execCommand('copy')
    },
    useClassColor (value) {
      return value === 0 ? 'rice' : value === 1 ? 'fall' : 'def'
    },
    closeAccount () {
      // 付款
      // if (this.accountData.state === 4) {
      //   this.checkInvest(this.accountData.recordCode)
      //   return
      // }
      this.hasAccountDetail = false
    },
    checkInvest (recordCode) {
      if (this.isDisabled) {
        return
      }

      if (this.accountData.state !== 4) {
        this.hasAccountDetail = false
        return
      }

      this.$store.dispatch('setInterfaceLoading', true)
      this.isDisabled = true

      tradesAPI.rechargeRecordPaid({ recordCode }).then((res) => {
        this.$store.dispatch('setInterfaceLoading', false)
        this.hasAccountDetail = false
        this.qrCode = ''
        if (res.code === 1) {
          this.msgConfirm(
            this.$t('WITHDRAW_NOTE'),
            this.$t('CONFIRM')
          ).then((ref) => {
            this.agencyDataLisi = this.agencyDataLisi.map(i => {
              if (i.recordCode === recordCode) {
                return {
                  ...i,
                  state: 0
                }
              }

              return i
            })
          })
        }

        this.isDisabled = false
      }).catch(() => {
        this.$store.dispatch('setInterfaceLoading', false)
      })
    },
    dayText (dateType) {
      switch (dateType) {
        case 'today':
          return this.$t('PROFIT_TODAY')
        case 'yesterday':
          return this.$t('REPORT_YESTERDAY')
        case 'currMonth':
          return this.$t('INVEST_SEVEN_DAYS')
        default:
          return this.$t('INVEST_SEVEN_DAYS')
      }
    },
    scrollEvent (e) {
      /**
      投資紀錄scroll頂
      */
      if (this.hasUpdating) {
        return
      }
      if (e.target.scrollTop === 0) {
        if (this.memoryGeneraChaseType === 0) {
          this.investmentLatestAPI()
        } else {
          this.chaseNumberInvestmentLatestAPI()
        }
      }
    },
    onLoad () {
      /**
      投資紀錄scroll頂
      */
      if (this.hasChangeDateType) return
      this.loading = true
      if (this.memoryGeneraChaseType === 0) {
        this.investmentAPI()
      } else {
        this.chaseNumberInvestmentAPI()
      }
      // this.loading = false
    },
    onAccountLoad () {
      if (this.hasChangeDateType) return
      this.loading = true
      if (this.accountTypeSelectValue === '1') {
        this.accountingAPI()
      } else if (this.accountTypeSelectValue === '2') {
        this.accountingWithdrawAPI()
      } else if (this.accountTypeSelectValue === '3') {
        this.accountingRechargeAPI()
      }
    },
    changeTab (val) {
      this.pageNum = 1
      this.latestPageNum = 1
      // 一般和追號點選重製
      const name = val === 0 ? 'basebtnData' : 'chaseBtnData'
      this.$nextTick(() => {
        this.$refs[name][0].selectTypeHandler(1, 0, name)
      })
    },
    // restore default
    investDefault (add) {
      this.pageNum = 1
      this.latestPageNum = 1
      // 判斷有無新增資料
      this.orderList = add === 'add' && !activeStatus ? JSON.parse(localStorage.getItem('orderList')) : []
    },
    // account default
    accountDefault () {
      // this.loading = true
      this.pageNum = 1
      this.latestPageNum = 1
      this.accountList = []
    },
    // 投資紀錄彈窗用
    popWindInvestmentFn (id, state) {
      // this.$store.dispatch('actionSetHasProfitTable', false)
      this.$store.dispatch('setInterfaceLoading', true)
      // console.log(state)
      this.revokeId = id
      this.popupState = state
      if (this.memoryGeneraChaseType === 0) {
        this.popupTitle = this.$t('PC_BET_DETAILS')
        if (state === '等待开奖' || state === '等待出仓' || state === 0) {
          this.revokeState = true
        } else {
          this.revokeState = false
        }
        // this.investmentPopshow = true
        const params = {
          id: id
        }
        lotteryAPI.getBetDetail(params).then((res) => {
          if (res.code === 1) {
            this.$store.dispatch('setInterfaceLoading', false)
            this.oneItemData = res.data
            const hockpopup = this.$refs.basePopup
            hockpopup.openPopupHandler()
          }
        })
      } else {
        const lottery = localStorage.getItem('lotteryConfig')
        this.popupTitle = this.$t('PC_TRACKING_DETAILS')
        if (lottery) {
          lotteryAPI.getChaseDetail2({ id: id }).then(res => {
            if (res.code === 1) {
              this.$store.dispatch('setInterfaceLoading', false)
              const countryCode = Object.values(JSON.parse(lottery))[0].lotteryList.filter(i => i.lotteryCode === res.data.lotteryCode)[0].countryCode
              // console.log(countryCode)
              this.popChaseNumberOne = { ...res.data, countryCode }
              const hockpopup = this.$refs.basePopup
              res.data && hockpopup && hockpopup.openPopupHandler()
            }
          })
        } else {
          lotteryAPI.lotteryConfig().then(ref => {
            lotteryAPI.getChaseDetail2({ id: id }).then(res => {
              if (res.code === 1) {
                this.$store.dispatch('setInterfaceLoading', false)
                const countryCode = ref.data.lotteryList.filter(i => i.lotteryCode === res.data.lotteryCode)[0].countryCode
                this.popChaseNumberOne = { ...res.data, countryCode }
                const hockpopup = this.$refs.basePopup
                res.data && hockpopup && hockpopup.openPopupHandler()
              }
            })
          })
        }
      }
    },
    reNewPlayTypeHandler () {
      // 更新狀態用
      this.popWindInvestmentFn(this.revokeId, this.popupState)
    },
    // 撤銷單用
    revokePop () {
      const msgValue = this.$t('ISSUE_NUMBER', { issue: this.oneItemData.issue })
      this.msgAlertBig(msgValue + this.$t('CANCEL_ORDER_REMINDER'), this.$t('CONFIRM'), this.$t('CANCEL'))
        .then(() => {
          lotteryAPI
            .setCancelBetting({ orderId: this.revokeId })
            .then(res => {
              if (res.code === 1) {
                if (this.memoryGeneraChaseType === 0) {
                // 追號
                  this.investDefault()
                  this.investmentAPI()
                }
              }

              // 限制撤单
              if (res.code !== -41) {
                // this.$store.dispatch('setInterfaceLoading', false)
              }
            })
            .then(() => {
              const hockpopup = this.$refs.basePopup
              hockpopup.closePopupHandler()
            })
        })
        .catch(() => {
        // this.$router.replace('/warehouseReceipt')
        })
    },
    childRevokePop () {
      const msgValue = this.$t('ISSUE_NUMBER', { issue: this.childActiveData.issue })
      // console.log(this.childActiveData)
      // this.msgAlert(msgValue, '确认', '取消', '撤单不可恢复，是否确认撤单')
      this.msgAlertBig(msgValue + this.$t('CANCEL_ORDER_REMINDER'), this.$t('CONFIRM'), this.$t('CANCEL'))
        .then(() => {
          lotteryAPI
            .setCancelBetting({ orderId: this.childActiveId })
            .then(res => {
              if (res.code === 1) {
                this.reNewPlayTypeHandler()
              }

              // 限制撤单
              if (res.code !== -41) {
                // this.$store.dispatch('setInterfaceLoading', false)
              }
            })
            .then(() => {
              const hockpopup = this.$refs.childPopup
              hockpopup.closePopupHandler()
            })
        })
        .catch(() => {
        // this.$router.replace('/warehouseReceipt')
        })
    },
    // 投資紀錄api
    investmentAPI () {
      this.hasUpdating = true
      const paramsInvestment = {
        dateType: this.dateSelectValue,
        pageNum: this.pageNum,
        pageSize: this.listPageSize
      }

      if (this.typeSelectValue !== '-1') {
        paramsInvestment.state = this.typeSelectValue
      }

      this.loading = true
      this.finished = true
      // if (this.orderList.length === 0) {
      //   this.$store.dispatch('setInterfaceLoading', true)
      // }
      lotteryAPI.getBetData2(paramsInvestment).then(res => {
        if (this.memoryGeneraChaseType === 1) return
        if (res.code === 1) {
          this.hasUpdating = false
          this.loading = false
          let filterList = []
          const resList = res.data || []
          // console.log('s', this.orderList, resList)
          this.orderList = [...this.orderList, ...resList].filter(item => {
            if (filterList.includes(item.id)) {
              return false
            } else {
              filterList = [...filterList, item.id]
              return true
            }
          })
          // console.log('api', this.orderList)
          localStorage.setItem('orderList', JSON.stringify(this.orderList))
          activeStatus = false
          this.pageNum += 1
          this.hasChangeDateType = false

          if (res.data.length < this.listPageSize) {
            this.finished = true
          } else {
            setTimeout(() => {
              this.finished = false
            }, 600)
          }
        }
      }).finally(() => {
        // this.$store.dispatch('setInterfaceLoading', false)
      })
    },
    // 新增資料
    postInvestmentAPI () {
      this.hasUpdating = true
      const paramsInvestment = {
        dateType: this.dateSelectValue,
        pageNum: this.pageNum,
        pageSize: this.listPageSize
      }

      if (this.typeSelectValue !== '-1') {
        paramsInvestment.state = this.typeSelectValue
      }

      this.loading = true
      this.finished = true
      // if (this.orderList.length === 0) {
      //   this.$store.dispatch('setInterfaceLoading', true)
      // }
      lotteryAPI.getBetData2(paramsInvestment).then(res => {
        if (this.memoryGeneraChaseType === 1) return
        if (res.code === 1) {
          this.hasUpdating = false
          this.loading = false
          let filterList = []
          const resList = res.data || []
          // console.log('s', this.orderList, resList)
          this.orderList = [...resList, ...this.orderList].filter(item => {
            if (filterList.includes(item.id)) {
              return false
            } else {
              filterList = [...filterList, item.id]
              return true
            }
          })
          // console.log('api', this.orderList)
          localStorage.setItem('orderList', JSON.stringify(this.orderList))
          activeStatus = false
          this.pageNum += 1
          this.hasChangeDateType = false

          if (res.data.length < this.listPageSize) {
            this.finished = true
          } else {
            setTimeout(() => {
              this.finished = false
            }, 600)
          }
        }
      }).finally(() => {
        // this.$store.dispatch('setInterfaceLoading', false)
      })
    },
    chaseNumberInvestmentAPI () {
      this.hasUpdating = true
      const paramsInvestment = {
        state: this.typeSelectValue,
        lotteryCode: -1,
        dateType: this.dateSelectValue,
        pageNum: this.pageNum,
        pageSize: this.listPageSize
      }
      this.loading = true
      this.finished = true
      // this.orderList = JSON.parse(localStorage.getItem('chaseList'))
      // if (this.orderList.length === 0) {
      //   this.$store.dispatch('setInterfaceLoading', true)
      // }
      lotteryAPI.getChaseBetData(paramsInvestment).then((res) => {
        // this.$store.dispatch('setInterfaceLoading', false)
        if (this.memoryGeneraChaseType === 0) return
        if (res.code === 1) {
          this.hasUpdating = false
          this.loading = false
          let filterList = []
          const resList = (res.data && res.data.chaseBetData) || []
          this.orderList = [...this.orderList, ...resList].filter((item) => {
            if (filterList.includes(item.id)) {
              return false
            } else {
              filterList = [...filterList, item.id]
              return true
            }
          })

          localStorage.setItem('orderList', JSON.stringify(this.orderList))
          activeStatus = false
          this.pageNum += 1
          this.hasChangeDateType = false

          if (res.data.chaseBetData.length < this.listPageSize) {
            this.finished = true
          } else {
            setTimeout(() => {
              this.finished = false
            }, 600)
          }
        }
      })
    },
    // 新增資料
    postChaseNumberInvestmentAPI () {
      this.hasUpdating = true
      const paramsInvestment = {
        state: this.typeSelectValue,
        lotteryCode: -1,
        dateType: this.dateSelectValue,
        pageNum: this.pageNum,
        pageSize: this.listPageSize
      }
      this.loading = true
      this.finished = true
      // this.orderList = JSON.parse(localStorage.getItem('chaseList'))
      // if (this.orderList.length === 0) {
      //   this.$store.dispatch('setInterfaceLoading', true)
      // }
      lotteryAPI.getChaseBetData(paramsInvestment).then((res) => {
        // this.$store.dispatch('setInterfaceLoading', false)
        if (this.memoryGeneraChaseType === 0) return
        if (res.code === 1) {
          this.hasUpdating = false
          this.loading = false
          let filterList = []
          const resList = (res.data && res.data.chaseBetData) || []
          this.orderList = [...resList, ...this.orderList].filter((item) => {
            if (filterList.includes(item.id)) {
              return false
            } else {
              filterList = [...filterList, item.id]
              return true
            }
          })

          localStorage.setItem('orderList', JSON.stringify(this.orderList))
          activeStatus = false
          this.pageNum += 1
          this.hasChangeDateType = false

          if (res.data.chaseBetData.length < this.listPageSize) {
            this.finished = true
          } else {
            setTimeout(() => {
              this.finished = false
            }, 600)
          }
        }
      })
    },
    investmentLatestAPI () {
      this.hasUpdating = true
      this.loading = true
      const paramsInvestment = {
        lotteryCode: -1,
        dateType: this.dateSelectValue,
        pageNum: this.latestPageNum,
        pageSize: this.listPageSize
      }

      if (this.typeSelectValue !== '-1') {
        paramsInvestment.state = this.typeSelectValue
      }
      lotteryAPI.getBetData2(paramsInvestment).then(res => {
        if (res.code === 1) {
          const resList = res.data || []
          const latestList = resList.filter(item =>
            this.orderList.some(subItem => !subItem.id === item.id)
          )
          this.orderList = [...latestList, ...this.orderList]
          this.loading = false
          this.hasUpdating = false
          this.hasChangeDateType = false

          if (latestList.length === this.listPageSize) {
            this.latestPageNum += 1
            this.investmentLatestAPI(this.listPageSize, this.latestPageNum)
          } else {
            this.latestPageNum = 1
          }
        }
      })
    },
    chaseNumberInvestmentLatestAPI () {
      this.hasUpdating = true
      this.loading = true
      const paramsInvestment = {
        state: this.typeSelectValue,
        lotteryCode: -1,
        dateType: this.dateSelectValue,
        pageNum: this.latestPageNum,
        pageSize: this.listPageSize
      }
      lotteryAPI.getChaseBetData(paramsInvestment).then((res) => {
        if (res.code === 1) {
          const resList = (res.data && res.data.chaseBetData) || []
          const latestList = resList.filter((item) => this.orderList.some(subItem => !subItem.id === item.id))
          this.orderList = [...latestList, ...this.orderList]
          this.loading = false
          this.hasUpdating = false
          this.hasChangeDateType = false

          if (latestList.length === this.listPageSize) {
            this.latestPageNum += 1
            this.investmentLatestAPI(this.listPageSize, this.latestPageNum)
          } else {
            this.latestPageNum = 1
          }
        }
      })
    },
    // 帳務紀錄api
    accountingAPI () {
      this.hasUpdating = true
      this.finished = true
      this.loading = true
      const paramsAccount = {
        dateType: this.accountSelectValue,
        pageNum: this.pageNum,
        pageSize: this.listPageSize,
        dictionId: '-1',
        self: true
      }
      // frontTradingWithdrawRecordList
      if (this.accountList.length === 0) {
        // this.$store.dispatch('setInterfaceLoading', true)
      }
      tradesAPI.transctionRecordList(paramsAccount).then(res => {
        // this.$store.dispatch('setInterfaceLoading', false)
        if (res.code === 1) {
          this.hasUpdating = false
          this.loading = false
          let filterList = []
          const resList = (res.data && res.data.rows) || []
          this.accountList = this.accountList.concat(resList).filter(item => {
            if (filterList.includes(item.id)) {
              return false
            } else {
              filterList = [...filterList, item.id]
              return true
            }
          })
          this.pageNum += 1
          this.hasChangeDateType = false

          if (res.data.rows.length < this.listPageSize) {
            this.finished = true
          } else {
            setTimeout(() => {
              this.finished = false
            }, 600)
          }
        }
      })
    },
    accountingLatestAPI () {
      this.hasUpdating = true
      this.loading = true
      const paramsAccount = {
        dateType: this.accountSelectValue,
        pageNum: this.latestPageNum,
        pageSize: this.listPageSize,
        dictionId: '-1',
        self: true
      }
      // frontTradingWithdrawRecordList
      tradesAPI.transctionRecordList(paramsAccount).then(res => {
        if (res.code === 1) {
          const resList = (res.data && res.data.rows) || []
          const latestList = resList.filter(item =>
            this.accountList.some(subItem => !subItem.id === item.id)
          )
          this.accountList = [...latestList, ...this.accountList]
          this.loading = false
          this.hasUpdating = false
          this.hasChangeDateType = false
          if (latestList.length === this.listPageSize) {
            this.latestPageNum += 1
            this.accountingLatestAPI(this.listPageSize, this.latestPageNum)
          } else {
            this.latestPageNum = 1
          }
        }
      })
    },
    // 帳務紀錄出金
    accountingWithdrawAPI () {
      this.hasUpdating = true
      this.finished = true
      this.loading = true
      const params = {
        dateType: this.accountSelectValue,
        pageNum: this.pageNum,
        pageSize: this.listPageSize,
        state: -1,
        self: true
      }
      // if (this.accountList.length === 0) {
      //   this.$store.dispatch('setInterfaceLoading', true)
      // }
      tradesAPI.withdrawRecordList(params).then(res => {
        // this.$store.dispatch('setInterfaceLoading', false)
        if (res.code === 1) {
          this.hasUpdating = false
          this.loading = false
          let filterList = []
          const resList = (res.data && res.data.rows) || []
          this.accountList = this.accountList.concat(resList).filter(item => {
            if (filterList.includes(item.id)) {
              return false
            } else {
              filterList = [...filterList, item.id]
              return true
            }
          })
          this.pageNum += 1
          this.hasChangeDateType = false

          if (res.data.rows.length < this.listPageSize) {
            this.finished = true
          } else {
            setTimeout(() => {
              this.finished = false
            }, 600)
          }
        }
      })
    },
    accountingWithdrawLatestAPI () {
      this.hasUpdating = true
      this.loading = true
      const paramsAccount = {
        dateType: this.accountSelectValue,
        pageNum: this.latestPageNum,
        pageSize: this.listPageSize,
        state: -1,
        self: true
      }
      // frontTradingWithdrawRecordList
      tradesAPI.withdrawRecordList(paramsAccount).then(res => {
        if (res.code === 1) {
          const resList = (res.data && res.data.rows) || []
          const latestList = resList.filter(item =>
            this.accountList.some(subItem => !subItem.id === item.id)
          )
          this.accountList = [...latestList, ...this.accountList]
          this.loading = false
          this.hasUpdating = false
          this.hasChangeDateType = false
          if (latestList.length === this.listPageSize) {
            this.latestPageNum += 1
            this.accountingWithdrawLatestAPI(
              this.listPageSize,
              this.latestPageNum
            )
          } else {
            this.latestPageNum = 1
          }
        }
      })
    },
    // 帳務紀錄入金
    accountingRechargeAPI () {
      this.hasUpdating = true
      this.finished = true
      this.loading = true
      const params = {
        dateType: this.accountSelectValue,
        pageNum: this.pageNum,
        pageSize: this.listPageSize,
        state: -1,
        self: true
      }
      // if (this.accountList.length === 0) {
      //   this.$store.dispatch('setInterfaceLoading', true)
      // }
      tradesAPI.rechargeRecordList(params).then(res => {
        // this.$store.dispatch('setInterfaceLoading', false)
        if (res.code === 1) {
          this.hasUpdating = false
          this.loading = false
          let filterList = []
          const resList = (res.data && res.data.rows) || []
          this.accountList = this.accountList.concat(resList).filter(item => {
            if (filterList.includes(item.recordCode)) {
              return false
            } else {
              filterList = [...filterList, item.recordCode]
              return true
            }
          })
          this.pageNum += 1
          this.hasChangeDateType = false

          if (res.data.rows.length < this.listPageSize) {
            this.finished = true
          } else {
            setTimeout(() => {
              this.finished = false
            }, 600)
          }
        }
      })
    },
    accountingRechargeLatestAPI () {
      this.hasUpdating = true
      this.loading = true
      const paramsAccount = {
        dateType: this.accountSelectValue,
        pageNum: this.latestPageNum,
        pageSize: this.listPageSize,
        state: -1,
        self: true
      }
      // frontTradingWithdrawRecordList
      tradesAPI.rechargeRecordList(paramsAccount).then(res => {
        if (res.code === 1) {
          const resList = (res.data && res.data.rows) || []
          const latestList = resList.filter(item =>
            this.accountList.some(
              subItem => !subItem.recordCode === item.recordCode
            )
          )
          this.accountList = [...latestList, ...this.accountList]
          this.loading = false
          this.hasUpdating = false
          this.hasChangeDateType = false
          if (latestList.length === this.listPageSize) {
            this.latestPageNum += 1
            this.accountingRechargeLatestAPI(
              this.listPageSize,
              this.latestPageNum
            )
          } else {
            this.latestPageNum = 1
          }
        }
      })
    },
    // 關閉def彈窗
    closeDefPop (value) {
      this.popDef = value
      this.popDefOne = Object.assign({}, this.popBaseEmpty)
    },
    // pc-handler..base談窗
    closePopup (value) {
      if (value === false) {
        const hockpopup = this.$refs.basePopup
        hockpopup.closePopupHandler()
      }

      // 關閉視窗一律先關閉撤單
      this.revokeState = false
    },
    // pc-handler..child談窗
    closeChildPopup (value) {
      if (value === false) {
        const hockpopup = this.$refs.childPopup
        hockpopup.closePopupHandler()
      }

      // 關閉視窗一律先關閉撤單
      this.revokeState = false
    },
    accountClassColor (value) {
      return value === 0 ? 'rise' : value === 1 ? 'fall' : 'def'
    },
    updateChild () {
      this.$refs.childPopup.parentUpdate()
    },
    childActionData (dataValue, idValue) {
      if (dataValue.state === '等待开奖' || dataValue.state === '等待出仓' || dataValue.state === 0) {
        this.revokeState = true
      } else {
        this.revokeState = false
      }

      this.childActiveData = dataValue
      this.childActiveId = idValue
      this.$store.dispatch('actionSetCurrentTag', 'childpopup')
      this.$nextTick(() => {
        const select = this.$refs.childPopup
        select.openPopupHandler()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import './pcuserware.scss';

.loading-wrap {
  position: absolute;
  width: 100%;
  height: 100%;
}
</style>
