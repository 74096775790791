import swiper, {
  Navigation,
  Pagination,
  Autoplay,
  EffectFade,
  EffectCoverflow
} from 'swiper'
import 'swiper/swiper-bundle.css'

export const swiperInit = () => {
  swiper.use([Navigation, Pagination, Autoplay, EffectFade, EffectCoverflow])
}

export const swiperInitDetail = () => {
  swiper.use([Navigation, Pagination, Autoplay])
}
