import { rest } from './index'
const Api = {
  getHotLottery: '/v1/management/content/getHotLottery', // 热门彩种
  lotteryConfig: '/v1/management/content/getAllLotteryDataFront', // 所有彩种列表配置
  lotteryPlan: '/v1/lottery/openTime', // 投注计划
  lotteryOpen: '/v1/lottery/openResult', // 开奖号码
  getServerTime: '/v1/betting/getServerTimeMillisecond', // 获取服务器时间
  getBetRebate: '/v1/betting/getBetRebate', // 获取各彩种玩法赔率
  getAllBetRebate: '/v1/betting/getAllBetRebate', // 获取所有彩种
  addBetting: '/v1/betting/addBetting', // 普通投注
  addChaseBetting: '/v1/betting/addChaseBetting', // 追号投注
  getBetting: '/v1/betting/getBetting', // 获取我的投注
  getChaseBetting: '/v1/betting/getChaseBetting', // 获取我的追号投注
  getBetData: '/v1/betting/getBetData', // 获取投注记录
  getBetData2: '/v1/betting/getBetData2',
  getChaseBetData: '/v1/betting/getChaseBetData', // 获取追号记录
  getBetDetail: '/v1/betting/getBetDetail', // 获取注单详情
  getChaseDetail: '/v1/betting/getChaseDetail', // 获取追号详情
  getChaseDetail2: '/v1/betting/getChaseDetail2', // 获取追号详情
  setCancelBetting: '/v1/betting/cancelBetting', // 撤单
  cancelAutoChaseBetting: '/v1/betting/cancelAutoChaseBetting', // 撤单
  getAgentBetData: '/v1/betting/getAgentBetData',
  findSelfLotteryIssue: '/v1/chat/getNowIssue', // 获取当前推送计划彩种期号
  todayTrendData: '/v1/stock/todayTrendData', // 股票當天走勢
  stockLastTrendData: '/v1/lottery/stockLastTrendData', // 股票近期走勢
  stockTrendChart: '/v1/stock/trendChart', // 股票走勢圖(K棒)
  getStockLotteryDataFront: '/v1/management/content/getStockLotteryDataFront', // 股票版當下再細分地區
  nextIssueOpenData: '/v1/betting/nextIssueOpenData', // 股種未來期號及投注結束時間資訊
  lastOpenAndTrendData: 'v1/lottery/lastOpenAndTrendData', // 最新出倉資料及當日走勢資料(max 5)
  lotteryOpenData: '/v1/betting/lotteryOpenData' // 股指返點券取得期數以及投注結束時間
}
export default {
  /**
   * 股票
   * */
  // 股票當天走勢
  stockTodayTrendData (params) {
    return rest.get(Api.todayTrendData, params)
  },
  stockLastTrendData (params) {
    return rest.get(Api.stockLastTrendData, params)
  },
  // 股票走勢圖(K棒)
  stockTrendChart (params) {
    return rest.get(Api.stockTrendChart, params)
  },
  // 股票版當下再細分地區
  getStockLotteryDataFront (params) {
    return rest.get(Api.getStockLotteryDataFront, params)
  },
  // 股種未來期號及投注結束時間資訊
  nextIssueOpenData (params) {
    return rest.get(Api.nextIssueOpenData, params)
  },
  // 股指返點券取得期數以及投注結束時間
  lotteryOpenData (params) {
    return rest.get(Api.lotteryOpenData, params)
  },
  // 最新出倉資料及當日走勢資料
  lastOpenAndTrendData (params) {
    return rest.get(Api.lastOpenAndTrendData, params)
  },
  /**
   *
   * 获取当前彩种可投注期号， 参数 ?lotteryCode=1008&issueNum=1
   */
  findSelfLotteryIssue (param) {
    return rest.get(Api.findSelfLotteryIssue, param)
  },
  /**
   * 一般投注
   */
  addBetting (param) {
    return rest.post(Api.addBetting, param)
  },
  /**
   * 追号投注
   */
  addChaseBetting (param) {
    return rest.post(Api.addChaseBetting, param)
  },
  /**
   * 投注记录-追号记录
   * */
  // 投注记录
  getBetData (params) {
    return rest.get(Api.getBetData, params)
  },
  getBetData2 (params) {
    return rest.get(Api.getBetData2, params)
  },
  // 追号记录
  getChaseBetData (params) {
    return rest.get(Api.getChaseBetData, params)
  },
  // 获取我的投注
  getBetting () {
    return rest.get(Api.getBetting, {})
  },
  // 获取我的追号
  getChaseBetting () {
    return rest.get(Api.getChaseBetting, {})
  },
  // 注单详情
  getBetDetail (params) {
    return rest.get(Api.getBetDetail, params)
  },
  // 追号详情
  getChaseDetail (params) {
    return rest.get(Api.getChaseDetail, params)
  },
  // 追号详情new
  getChaseDetail2 (params) {
    return rest.get(Api.getChaseDetail2, params)
  },
  // 前台代理中心投注明細接口
  getAgentBetData (params) {
    return rest.get(Api.getAgentBetData, params)
  },
  /**
   * 获取彩种配置与列表
   */
  lotteryConfig () {
    return rest.get(Api.lotteryConfig, {})
  },
  /**
   * 获取彩种投注计划
   */
  lotteryPlan (param) {
    return rest.get(Api.lotteryPlan, param)
  },
  /**
   * 获取开奖号码
   */
  getLotteryOpen (param) {
    return rest.get(Api.lotteryOpen, param)
  },
  /**
   * 获取服务器时间
   */
  getServerTime () {
    return rest.get(Api.getServerTime, {})
  },
  /**
   * 获取彩种玩法奖金/赔率
   */
  getBetRebate (param) {
    return rest.get(Api.getBetRebate, param)
  },
  /**
   * 获取热门彩种
   */
  getHotLottery () {
    return rest.get(Api.getHotLottery, {})
  },
  /** 撤单操作 */
  setCancelBetting (param) {
    return rest.post(Api.setCancelBetting, param)
  },
  cancelAutoChaseBetting (param) {
    return rest.post(Api.cancelAutoChaseBetting, param)
  },
  getAllBetRebate () {
    return rest.get(Api.getAllBetRebate)
  }
}
