<template>
  <div class="navbar__loginwrap">
    <!-- <div class="routerlink__wrap">
      <router-link
        to="memberCenter"
        tag="div"
        class="login__title router__link-set"
        :class="[{hide:hideState}]"
      >
        代理中心
      </router-link>
      <router-link
        to="about"
        tag="div"
        class="login__title router__link-set"
        :class="[{hide:hideState}]"
      >
        关于
      </router-link>
    </div> -->
    <div
      class="waller__outwrap"
      @mouseenter="dropdownLangBase = '0'"
      @mouseleave="dropdownLangBase = ''"
    >
      <SelectLang
        :active-user-account="dropdownLangBase"
        @close="dropdownLangBase = ''"
      />
    </div>
    <div
      class="waller__outwrap"
      @mouseenter="openBaseDropDown"
      @mouseleave="closeBaseDropDown"
    >
      <SelectItem :active-user-account="dropdownStateBase" />
    </div>
    <div
      class="login__avator icon-set"
      :class="{disable: $route.name === 'MemberCenter'}"
      @click="enterMemberCenter"
      @mouseenter="openDropDown"
      @mouseleave="closeDropDown"
    >
      <div class="login__iconset login__userlogin">
        <img
          v-if="checkDropdownState"
          src="/static/images/Layout/no-login.svg"
          alt=""
        >
        <img
          v-else
          src="/static/images/Layout/no-login-active.svg"
          alt=""
        >
        <canvas
          id="canvas"
          height="0"
          width="0"
        />
        <Dropdown :active-user-account="dropdownState" />
      </div>
    </div>
    <!-- <div class="login__remind icon-set">
      <div class="login__iconset">
        <img
          src="/static/images/Layout/remind.svg"
          alt=""
        >
      </div>
    </div> -->
    <div
      class="login__linkmobile icon-set"
      @mouseenter="openDropDown2"
      @mouseleave="closeDropDown2"
    >
      <div class="login__iconset login__mobile">
        <img
          v-if="checkDropdownState2"
          src="/static/images/Layout/mobile.svg"
          alt=""
        >
        <img
          v-else
          src="/static/images/Layout/mobile-active.svg"
          alt=""
        >
        <DropdownQrcode :active-user-account="dropdownState2">
          <template slot="qrcode">
            <img
              v-if="qrcodeSrc"
              :src="qrcodeSrc"
              alt=""
            >
          </template>
        </DropdownQrcode>
      </div>
    </div>
    <!-- <div class="login__qa icon-set">
      <div class="login__iconset">
        <img
          src="/static/images/Layout/qa.svg"
          alt=""
        >
      </div>
    </div> -->
  </div>
</template>

<script>
import SelectItem from './SelectBtns.vue'
import SelectLang from './SelectLang'
import Dropdown from './Dropdown.vue'
import DropdownQrcode from './DropdownQrcode.vue'
import QRCode from 'qrcode'
export default {
  name: 'Navbarbtngroup',
  components: {
    SelectItem,
    SelectLang,
    Dropdown,
    DropdownQrcode
  },
  data () {
    return {
      hideState: true,
      qrcodeSrc: '',
      dropdownStateBase: '',
      dropdownLangBase: '',
      dropdownState: '',
      dropdownState2: ''
    }
  },
  computed: {
    curLang () {
      return {
        cn: '简体中文',
        ja: '日本語'
      }[this.$i18n.locale] || '简体中文'
    },
    siteConfig () {
      return this.$store.state.siteConfig
    },
    checkDropdownState () {
      return this.dropdownState.length === 0
    },
    checkDropdownState2 () {
      return this.dropdownState2.length === 0
    }
  },
  methods: {
    qrcodeGeneral () {
      const canvas = document.getElementById('canvas')

      let hostname = location.hostname
      if (hostname.split('.').length === 3) {
        hostname = hostname.replace(hostname.split('.')[0] + '.', '')
      }
      let url = null
      // url = 'http://' + new Date().format('yyMMdd') + '.673557.com/index.html?m.' + hostname + '/AppDownload&type=' + this.siteConfig.lotteryShowType
      const protocolStr = document.location.protocol
      url = protocolStr + '//m.' + hostname + '/AppDownload&type=' + this.siteConfig.lotteryShowType
      // console.log('m.' + hostname + '/AppDownload')
      // url = 'm.' + hostname + '/AppDownload'
      // url= 'http://210419.673557.com/index.html?m.kaileijiaoyisuo9.com/AppDownload&type=2'
      QRCode.toCanvas(canvas, url, (error) => {
        if (error) console.error(error)
      })
      const mycanvas = document.getElementById('canvas')
      mycanvas.style.display = 'none'
      const img = this.convertCanvasToImage(mycanvas)
      this.qrcodeSrc = img.src
    },
    convertCanvasToImage (canvas) {
      const image = new Image()
      image.src = canvas.toDataURL('image/png')
      return image
    },
    enterMemberCenter () {
      if (this.$route.name === 'MemberCenter') return
      this.$router.push({ name: 'MemberCenter' })
    },
    openBaseDropDown () {
      this.dropdownStateBase = '0'
    },
    closeBaseDropDown () {
      this.dropdownStateBase = ''
    },
    openDropDown () {
      this.dropdownState = '0'
    },
    closeDropDown () {
      this.dropdownState = ''
    },
    openDropDown2 () {
      if (!this.qrcodeSrc) {
        this.qrcodeGeneral()
      }
      this.dropdownState2 = '0'
    },
    closeDropDown2 () {
      this.dropdownState2 = ''
    }
  }
}
</script>

<style lang="scss" scoped>
.navbar__loginwrap {
  display: flex;
  align-items: center;
  margin-left: auto;
  // border: 1px solid #0F0;
}

.login {
  &__iconset {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    // border: 1px solid #F00;
  }

  &__title {
    font-size: 14px;
    line-height: 20px;
    color: var(--layoutLoginTitleColor);
  }

  &__userlogin {
    position: relative;
    // margin-left: 12px;
    // border: 1px solid #F00;

    >img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__mobile {
    position: relative;

    >img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.select-set {
  width: 68px;
  // border: 1px solid #0FF;

  ::v-deep {
    .v-text-field__details {
      min-height: 0;
    }

    .v-input__slot {
      margin-bottom: 0;
    }
  }
}

.list__wrap {
  ::v-deep {
    .ivu-select-selection {
      height: 28px;
      background-color: var(--layoutIveSelectBgc);
      border: 0;
    }

    .ivu-select-dropdown-list {
      padding-left: 0;
      // border: 1px solid #000;
    }

    .ivu-select-dropdown {
      background-color: var(--layoutIveSelectDropdownBgc);
    }

    .ivu-select-item-focus {
      color: var(--layoutIveSelectItemFocusColor);
      background-color: var(--layoutIveSelectItemFocusBgc);
    }

    .ivu-select-item {
      &:hover {
        color: var(--layoutIveSelectItemHoverColor);
        background-color: var(--layoutIveSelectItemHoverBgc);
      }
    }
  }
}

.icon-set {
  transition: 0.5s;
  cursor: pointer;

  &.login__linkmobile {
    cursor: default;
  }

  &.disable {
    cursor: default;
  }
}

.routerlink__wrap {
  display: flex;

  .login__title {
    width: 80px;
    text-align: center;
    // border: 1px solid #F00;

    &.hide {
      color: var(--layoutRouterHide);
      pointer-events: none;
    }
  }

  .router__link-set {
    transition: 0.5s;

    &:hover {
      cursor: pointer;
      color: var(--layoutRouterHover);
    }
  }
}

.waller__outwrap {
  display: flex;
  align-items: center;
  height: 48px;
}

</style>
