<template>
  <div class="trend-rank">
    <div class="container trend">
      <div class="header">
        {{ $t('PC_TREND') }}
      </div>
      <div class="trend-table">
        <div
          class="trend-title"
          :class="$i18n.locale"
        >
          <span>{{ $t('PC_ISSUE') }}</span>
          <span class="cny__title">{{ $t('HOME_GETNAME_TEXT') }}(CNY)</span>
          <span class="champion__title">{{ $t('TRADE_CHAMPION') }}</span>
          <span class="runner__title">{{ $t('TRADE_RUNNER') }}</span>
        </div>
        <div class="info__wrap">
          <div
            v-for="(item, index) of latestResultList"
            :key="index"
            class="trend-info"
          >
            <span class="name">{{ item.data }}</span>

            <span
              class="txt cny__txt"
              :class="upDown(item.first)"
            >
              {{ item.openNumber }}
            </span>

            <span
              class="champion"
              :class="$i18n.locale"
            >
              <span
                :class="upDown(item.first)"
              >{{ item.first }}
              </span>
              <span class="center">{{ item.firstBoolean }}</span>
              <span
                :class="animalClass(item.animal)"
              >{{ item.animal }}
              </span>
            </span>

            <span class="runner">
              <span
                class="runner__number"
                :class="[upDown(item.second),$i18n.locale]"
              >{{ item.second }}</span>
              <span>{{ item.secondBoolean }}</span>
            </span>
          </div>
          <!-- <div
            v-if="isResultLoading"
            class="loading"
          >
            <v-progress-circular
              :size="20"
              color="#0589D7"
              indeterminate
            />
          </div> -->
          <div
            v-if="isResultLoading"
            class="d-flex align-center justify-center loading-wrap"
          >
            <Loading />
          </div>
        </div>
      </div>
    </div>
    <div class="border" />
    <div class="container rank">
      <div class="header header-set">
        {{ $t('PC_INSTANT_PROFIT') }}
      </div>
      <div
        class="rank-table"
        @mouseenter="stopSwiperHandler"
        @mouseleave="autoSwiperHandler"
      >
        <swiper
          ref="myRankSwiper"
          :options="rankSwiperOptions"
          class="rank__slider"
        >
          <swiper-slide
            v-for="(item, index) in getProfitRankListData"
            :key="index"
            class="list index-rank-list"
          >
            <div class="item">
              <div class="useid">
                {{ item.nickName || item.userName }}
              </div>
              <div class="username">
                {{ item.lotteryName }}
              </div>
              <div class="profit">
                +{{ item.bonus }}
              </div>
            </div>
          </swiper-slide>
        </swiper>
        <!-- <div
          v-if="isLoadingRank"
          class="loading"
        >
          <v-progress-circular
            :size="20"
            color="#0589D7"
            indeterminate
          />
        </div> -->
        <div
          v-if="isLoadingRank"
          class="d-flex align-center justify-center loading-wrap"
        >
          <Loading />
        </div>
      </div>
    </div>
  </div>

  <!-- </div> -->
</template>
<script>
import usersAPI from '@/api/users'
import Loading from '@/components/loader/GlobalLoading'
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import { swiperInit } from '@/utils/swiper'
swiperInit()
export default {
  name: 'TrendRank',
  directives: {
    swiper: directive
  },
  components: {
    Swiper,
    SwiperSlide,
    Loading
  },
  data () {
    return {
      isLoadingRank: false,
      getProfitRankListData: [],
      rankSwiperOptions: {
        direction: 'vertical',
        height: 168,
        slidesPerView: 6,
        loop: true,
        noSwiping: true,
        autoplay: {
          disableOnInteraction: false, // 使用者操作swiper之後，是否禁止autoplay
          delay: 3000 // 自動切換的時間間隔（單位ms）
        }
      }
    }
  },
  computed: {
    isResultLoading () {
      return !(this.$store.state.lt && this.$store.state.lt.LotteryResults[this.$store.state.lt.lottery.lotteryCode].length)
    },
    latestResultList () {
      return ((this.$store.state.lt && this.$store.state.lt.LotteryResults[this.$store.state.lt.lottery.lotteryCode]) || []).map(item => {
        const number = item.openNumber.substring(item.openNumber.lastIndexOf('.') + 1)
        const first = number.substring(0, 1) * 1
        const second = number.substring(1) * 1
        return {
          data: item.issue.substring(4),
          openNumber: item.openNumber,
          first: number.substring(0, 1) * 1,
          second: number.substring(1) * 1,
          animal: first - second > 0 ? this.$t('TRADE_BULL_MARKET') : first < second ? this.$t('TRADE_BEAR_MARKET') : this.$t('TRADE_UNCHANGED'),
          firstBoolean: first % 2 === 0 ? this.$t('TRADE_DOUBLE') : this.$t('TRADE_ODD'),
          secondBoolean: second % 2 === 0 ? this.$t('TRADE_DOUBLE') : this.$t('TRADE_ODD')
        }
      })
    },
    showSwiper () {
      return this.$refs.myRankSwiper.$swiper
    }
  },
  mounted () {
  },
  created () {
    this.getProfitRank()
  },
  methods: {
    upDown (value) {
      // 大於4為漲反之為降
      return value > 4 ? 'up' : 'down'
    },
    animalClass (name) {
      switch (name) {
        case this.$t('TRADE_BULL_MARKET'):
          return 'up'
        case this.$t('TRADE_BEAR_MARKET'):
          return 'down'
        default:
          return ''
      }
    },
    getProfitRank () {
      this.isLoadingRank = true
      usersAPI.getNewestBounsList().then((res) => {
        if (res.code === 1) {
          this.isLoadingRank = false
          this.getProfitRankListData = res.data || []
        }
      })
    },
    stopSwiperHandler () {
      this.showSwiper.autoplay.stop()
    },
    autoSwiperHandler () {
      this.showSwiper.autoplay.start()
    }
  }
}
</script>
<style lang="scss" scoped>
.container {
  &.trend {
    overflow: hidden;
    padding: 0;
    padding-bottom: 12px;
    height: calc(100% - 240px);
  }

  &.rank {
    overflow: hidden;
    padding: 0;
    height: 240px;
  }

  >.header {
    position: relative;
    padding-left: 16px;
    height: 44px;
    font-size: 14px;
    line-height: 44px;
    color: var(--trendRankHeaderColor);
    border-bottom: var(--trendRankHeaderBorder);
    // background-color: #17253D;
    // border: 1px solid #F00;
  }
}

.loading-wrap {
  position: absolute;
  width: 100%;
  height: 100%;
}

.side3.xl {
  .container {
    &.trend {
      height: 67.83vh;
    }
  }
}

.side3.cancel {
  .container {
    >.header {
      background-color: transparent;
      transition: 1s;
    }
  }
}

.trend-rank {
  position: relative;
  min-width: 305px;
  height: 100%;
}

.trend-table {
  position: relative;
  height: calc(100% - 44px);
  text-align: left;
  color: var(--trendRankTableColor);
  //border: 1px solid #F00;
  // background-color: transparent;

  .trend-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 11px;
    padding-left: 16px;
    height: 28px;
    font-size: 12px;
    color: #FAFAFA;

    >span {
      flex: 1;
      // border: 1px solid #000;
    }
  }

  .info__wrap {
    overflow-y: auto;
    overflow-y: overlay;
    height: calc(100% - 24px);
  }

  .trend-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 17px;
    padding-left: 16px;
    height: 28px;
    font-size: 12px;
    color: var(--trendRankTrendInfoColor);
    transition: 0.5s;

    >span {
      flex: 1;
    }

    &:hover {
      background-color: var(--trendRankTrendInfoHoverBgc);
    }

    .txt {
      &.up {
        color: var(--trendRankUpColor);
      }

      &.down {
        color: var(--trendRankDownColor);
      }
    }

    .name {
      color: var(--trendRankNameColor);
    }
  }

  .champion {
    display: flex;
    justify-content: flex-end;

    &.ja {
      transform: translateX(8px);
    }

    &.vie,
    &.en {
      justify-content: center;
      transform: translateX(12px);

      .center {
        width: 27px;
        text-align: center;
      }

      .up:last-child ,
      span:last-child {
        width: 25px;
        text-align: center;
      }
    }

    >span {
      &.up {
        color: var(--trendRankUpColor);
      }

      &.down {
        color: var(--trendRankDownColor);
      }
    }

    >.center {
      margin-right: 6px;
      margin-left: 6px;
    }
  }

  .runner {
    display: flex;
    justify-content: flex-end;

    >span {
      &.up {
        color: var(--trendRankUpColor);
      }

      &.down {
        color: var(--trendRankDownColor);
      }
    }

    .runner__number {
      margin-right: 6px;

      &.vie + span {
        width: 27px;
        text-align: center;
      }

      &.en + span {
        width: 27px;
        text-align: center;
      }

    }
  }

}

.rank-table {
  position: relative;
  overflow: hidden;
  // overflow-y: auto;
  // overflow-y: overlay;
  padding-top: 12px;
  padding-bottom: 12px;
  width: 100%;
  height: 180px;
  // border: 1px solid #F00;
  // background-color: #1E2E48;

  .index-rank-list {
    &:hover {
      background-color: var(--trendRankTrendInfoHoverBgc);
    }
  }

  table {
    width: 100%;
    font-size: 14px;
    line-height: 20px;
  }

  tr {
    transition: 0.5s;
    cursor: pointer;

    &:hover {
      background-color: var(--trendRankTrendInfoHoverBgc);
    }

  }

  .td {
    padding-top: 6px;
    padding-bottom: 6px;

    &:first-child {
      color: var(--trendRankTdFirstChild);
    }

    &:nth-child(2) {
      color: var(--trendRankTdNth2Child);
    }

    &.up {
      color: var(--trendRankTdUp);
    }

    &.down {
      color: var(--trendRankTdDown);
    }
  }
}

.side3.xl {
  .rank-table {
    height: calc(23.61vh - 57px);
  }
}

.slide {
  position: absolute;
  top: 50%;
  left: -30px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 12px;
  background-color: var(--trendRankSlideBgc);
  // border: 1px solid #F00;
  transform: rotate(90deg);
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;

  &:hover {
    cursor: pointer;
  }

  >img {
    transform: rotate(-180deg);
  }
}

.index-rank-list {
  >.item {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding-right: 11px;
    padding-left: 16px;
    height: 28px;
    font-size: 12px;

    >div {
      display: flex;
      align-items: center;
      // justify-content: center;
      // border: 1px solid #000;
    }

    >.useid {
      overflow: hidden;
      white-space: nowrap;
      color: var(--trendRankUseIdColor);
      flex: 0.8;
    }

    >.username {
      color: var(--trendRankUserNameColor);
      flex: 1;
    }

    >.profit {
      color: var(--trendRankProfit);
      flex: 0.6;
    }
  }
}

/* .loading {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
} */

.champion__title {
  position: relative;
  right: 14px;
  text-align: right;
}

.runner__title {
  position: relative;
  right: 4px;
  text-align: right;
}

.trend-title {
  &.vie {
    .champion__title {
      right: -6px;
    }

    .runner__title {
      right: 7px;
    }
  }

  &.en {
    .champion__title {
      right: 2px;
    }

    .runner__title {
      right: 0;
    }
  }

}

.header-set {
  font-size: 12px !important;
}

.cny__title,
.cny__txt {
  text-align: center;
}

.border {
  position: relative;
  height: 6px;
  background-color: var(--trendRankBorderBgc);
  border: 0;
  z-index: 2;
}
</style>
