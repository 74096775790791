<template>
  <input
    type="text"
    class="form-control"
    :style="{'height':height+'px'}"
    :value="value"
    @input="$emit('input',$event.target.value)"
  >
</template>
<script>
export default {
  props: {
    height: {
      type: Number,
      default: undefined
    },
    value: {
      type: String,
      default: undefined
    }
  }
}
</script>
<style lang="scss" scoped>
.form-control {
  padding: 10px 12px;
  width: 100%;
  color: var(--baseInputColor);
  border: var(--baseInputBorderColor);
  border-radius: 4px;

  &:focus {
    border-color: var(--baseInputFocusColor);
  }
}
</style>
