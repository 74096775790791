<template>
  <div
    :class="['notify-wrap', hasNotify.type,{index:$route.meta.alert}]"
  >
    <template v-if="hasNotify.type === 'notice'">
      <img
        src="/static/images/notify/notice.svg"
        class="icon"
        alt=""
      >
      <span v-if="hasNotify.msg ">{{ hasNotify.msg }}</span>
      <!-- 下單 -->
      <span v-else>
        <span class="type">{{ $t('PC_ORDER_SUCCESS') }}</span>
        {{ notifyData.name }} {{ $t('ISSUE_NUMBER',{issue:notifyData.issue }) }}  - {{ notifyData.playDetail }}
      </span>
    </template>
    <template v-if="hasNotify.type === 'update' && $route.meta.alert">
      <img
        src="/static/images/notify/notice.svg"
        class="icon"
        alt=""
      >
      <!-- 截止 -->
      <span>{{ hasNotify.msg }}</span>
    </template>
    <template v-if="hasNotify.type === 'def'">
      <img
        src="/static/images/notify/notice.svg"
        class="icon"
        alt=""
      >

      <span>{{ $t('NOTIFY_UPDATE_DONE') }}</span>
    </template>
    <!-- 註單更新 -->
    <template v-if="hasNotify.type === 'prizeShowUp' && $route.meta.alert">
      <!-- <img
        src="/static/images/notify/notice.svg"
        class="icon"
        alt=""
      > -->
      {{ $t('PC_OUTGOING_INDEX') }} {{ getLotteryName(notifyData.lotteryCode) }} {{ $t('ISSUE_NUMBER',{issue:notifyData.issue }) }} - {{ notifyData.openNumber }}
    </template>
    <template v-if="hasNotify.type === 'error'">
      <img
        src="/static/images/notify/error.svg"
        class="icon"
        alt=""
      >
      <span v-if="hasNotify.msg">{{ hasNotify.msg }}</span>
      <span
        v-else
        class="type"
      >
        {{ $t('PC_ORDER_FAIL') }}
      </span>
    </template>
    <!-- 流水號 -->
    <template v-if="hasNotify.type === 'copy'">
      <img
        src="/static/images/notify/copy.svg"
        class="icon"
        alt=""
      >
      <span class="copy_type">{{ $t('DEAL_COPY_SUCCESS') }}&nbsp;</span>
      <span class="copy_label">{{ hasNotify.msg.lable }}</span>
      <span class="copy_inputvalue">{{ hasNotify.msg.inputValue }}</span>
      <!-- {{ hasNotify.msg }} -->
    </template>
    <button
      class="icon-close"
      :class="[hasNotify.type,{index:$route.meta.alert}]"
      @click="closeNotify"
    >
      <img
        src="/static/images/components/btn_close.svg"
        alt=""
      >
    </button>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState(['hasNotify', 'newLotteryList']),
    MessagueTypeList () {
      return {
        def: {
          message: `${this.$t('NOTIFY_UPDATE_DONE')}!`,
          titleColor: '#FAFAFA',
          backgroudColor: '#0589D7',
          type: 'ok'
        },
        copySuccessRoom: {
          message: this.$t('DEAL_COPY_SUCCESS'),
          titleColor: '#FAFAFA',
          backgroudColor: '#0589D7',
          type: 'ok'
        },
        autoBuyCancel: {
          message: this.$t('NOTIFY_LOOP_DISABLED'),
          titleColor: '#FAFAFA',
          backgroudColor: '#0589D7',
          type: 'ok'
        },
        set: {
          message: `${this.$t('NOTIFY_SET_SUCCESS')}!`,
          titleColor: '#FAFAFA',
          backgroudColor: '#0589D7',
          type: 'ok'
        },
        ok: {
          message: this.$t('NOTIFY_SAFEPASSWORD_DONE'),
          titleColor: '#FAFAFA',
          backgroudColor: '#2BA66F',
          type: 'ok'
        },
        idCheck: {
          message: this.$t('BANKCARD_ID_SUCCESS'),
          titleColor: '#FAFAFA',
          backgroudColor: '#0589D7',
          type: 'ok'
        },
        addCardOk: {
          message: `${this.$t('NOTIFY_BANKCARD_ADD')}!!`,
          titleColor: '#FAFAFA',
          backgroudColor: '#0589D7'
        },
        record: {
          message: this.$t('NOTIFY_COPY_WATER'),
          titleColor: '#FAFAFA',
          backgroudColor: '#0589D7',
          type: 'ok'
        },
        account: {
          message: this.$t('NOTIFY_COPY_ACCOUNT'),
          titleColor: '#FAFAFA',
          backgroudColor: '#0589D7',
          type: 'ok'
        },
        nick: {
          message: this.$t('NOTIFY_COPY_NAME'),
          titleColor: '#FAFAFA',
          backgroudColor: '#0589D7',
          type: 'ok'
        },
        copySuccess: {
          message: this.$t('NOTIFY_COPY_SUCCESS'),
          titleColor: '#FAFAFA',
          backgroudColor: '#0589D7',
          type: 'ok'
        },
        bank: {
          message: this.$t('NOTIFY_COPY_BANK'),
          titleColor: '#FAFAFA',
          backgroudColor: '#0589D7',
          type: 'ok'
        },
        branch: {
          message: this.$t('NOTIFY_COPY_OPEN'),
          titleColor: '#FAFAFA',
          backgroudColor: '#0589D7',
          type: 'ok'
        },
        investSuccess: {
          message: '',
          titleColor: '#FAFAFA',
          backgroudColor: '#0589D7',
          type: 'ok'
        },
        updateCardOk: {
          message: `${this.$t('NOTIFY_UPDATE_SUCCESS')}!`,
          titleColor: '#FAFAFA',
          backgroudColor: '#0589D7',
          type: 'ok'
        },
        typeInvitation: {
          message: this.$t('NOTIFY_INVITE_DELETE'),
          titleColor: '#FAFAFA',
          backgroudColor: '#0589D7',
          type: 'ok'
        },
        password: {
          message: this.$t('NOTIFY_COPY_PASSWORD'),
          titleColor: '#FAFAFA',
          backgroudColor: '#0589D7',
          type: 'ok'
        },
        inMoneyOrder: {
          message: this.$t('TRSC_005'),
          titleColor: '#FAFAFA',
          backgroudColor: '#0589D7',
          type: 'ok'
        },
        rovoke: {
          message: this.$t('NOTIFY_CANCEL_ORDER'),
          titleColor: '#FAFAFA',
          backgroudColor: '#0589D7',
          type: 'ok'
        },
        mute: {
          message: this.$t('NOTIFY_MUTE_SUCCESS'),
          titleColor: '#FAFAFA',
          backgroudColor: '#0589D7',
          type: 'ok'
        },
        cancelmute: {
          message: this.$t('NOTIFY_MUTE_CANCEL'),
          titleColor: '#FAFAFA',
          backgroudColor: '#0589D7',
          type: 'ok'
        },
        // error
        error: {
          message: this.$t('NOTIFY_FIXED_BUG'),
          titleColor: '#FAFAFA',
          backgroudColor: '#E1533B',
          type: 'error'
        },
        idCheckError: {
          message: this.$t('NOTIFY_ID_ERROR'),
          titleColor: '#FAFAFA',
          backgroudColor: '#E1533B',
          type: 'error'
        },
        passwordMismatch: {
          message: this.$t('NOTIFY_PASSWORD_NOT_SAME'),
          titleColor: '#FAFAFA',
          backgroudColor: '#E1533B',
          type: 'error'
        },
        typePasswordTooEasy: {
          message: this.$t('ACTION_PASSWORD_NOT_SIMPLE'),
          titleColor: '#FAFAFA',
          backgroudColor: '#E1533B',
          type: 'error'
        },
        investError: {
          message: '',
          titleColor: '#FAFAFA',
          backgroudColor: '#E1533B',
          type: 'error'
        }
      }
    },
    notifyData () {
      return this.$store.state.notifyData
    }
  },
  methods: {
    getLotteryName () {
      // console.log(this.newLotteryList.filter(i => i.lotteryCode === this.notifyData.lotteryCode))
      if (this.newLotteryList) {
        const stock = this.newLotteryList.filter(i => i.lotteryCode === this.notifyData.lotteryCode)[0]
        return stock ? stock.lotteryName : ''
      } else {
        return ''
      }
    },
    closeNotify () {
      this.$store.dispatch('actionSetHasNotify', false)
    }
  }
}
</script>

<style lang="scss" scoped>
.notify-wrap {
  position: fixed;
  top: 60px;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 48px;
  font-size: 14px;
  color: var(--notifyWrapColor);
  z-index: 10000;

  &.notice,
  &.update.index,
  &.prizeShowUp.index,
  &.def {
    background: var(--notifyWrapNoticeColor);
    box-shadow: var(--notifyWrapNoticeShadow);
  }

  &.error {
    background: var(--notifyWrapErrorColor);
    box-shadow: var(--notifyWrapErrorShadow);
  }

  &.copy {
    background: var(--notifyWrapNoticeColor);
    box-shadow: var(--notifyWrapNoticeShadow);
  }

  .icon {
    margin-right: 12px;
  }

  .type {
    margin-right: 20px;
  }
}

.close-btn {
  position: absolute;
  right: 23px;
  cursor: pointer;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.copy_label {
  margin-right: 12px;
}

.icon-close {
  position: absolute;
  right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  border-radius: 2px;

  &.update,
  &.prizeShowUp, {
    opacity: 0;

    &.index {
      opacity: 1;
    }
  }

  &:hover {
    opacity: 0.6;
    cursor: pointer;
  }
}
</style>
