import Home from '../views/home/Home'
const router = [
  {
    path: '/',
    redirect: '/index'
  },
  {
    path: '/index',
    name: 'Home',
    meta: {
      requiresAuth: true,
      navbar: true,
      header: true,
      alert: true
    },
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "About" */'@/views/About')
  },
  {
    path: '/memberCenter',
    name: 'MemberCenter',
    meta: {
      requiresAuth: true,
      navbar: false,
      header: true
    },
    component: () => import(/* webpackChunkName: "memberCenter" */ '@/views/memberCenter/memberCenter')
  },
  {
    path: '/recharge',
    name: 'Recharge',
    meta: {
      requiresAuth: true,
      navbar: false,
      header: true
    },
    component: () => import(/* webpackChunkName: "memberCenter" */ '@/views/recharge/Recharge')
  },
  /*
    未登入系列-start
  */
  {
    path: '/login',
    name: 'UserLogin',
    component: () => import(/* webpackChunkName: "UserLogin" */'@/views/login/UserLogin'),
    meta: {
      navbar: false,
      header: false,
      notify: true,
      hasNoSocket: true
    }
  },
  {
    path: '/*',
    redirect: '/index'
  }
]

export default router
